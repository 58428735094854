import Highcharts from 'highcharts';
import PatternFill from 'highcharts/modules/pattern-fill';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import OfflineExporting from 'highcharts/modules/offline-exporting';
if (typeof Highcharts === 'object') {
	PatternFill(Highcharts);
	Exporting(Highcharts);
	ExportData(Highcharts);
	OfflineExporting(Highcharts);
}

import { getChartById, getKeyTime, colStat, getUnits } from '../../../utils';
import { update as evalUpdate, callbackGlobStat as evalCallbackGlobStat } from '../../../modules/evaluation';

import { Aeroval } from '../../../types/global';
import { RegStatData } from '../../../types/data';
declare var aeroval: Aeroval;

export function plotRegionalStatisticsSingleModel(data: RegStatData, updateTS: boolean, updateScat: boolean, id = 'regStat'): void {
	//decompose statistics: support obs/mod_trends
	if (aeroval.statistic.dir.includes('/')) {
		var firstUnderscore = aeroval.statistic.dir.indexOf('_');
		var stat1 = `${aeroval.statistic.dir.split('/')[0]}_${aeroval.statistic.dir.slice(firstUnderscore + 1)}`;
		var stat2 = aeroval.statistic.dir.split('/')[1];
		var stats = [stat1, stat2];
	} else {
		var stats = [aeroval.statistic.dir];
	}

	//time selection
	const keyTime = getKeyTime(aeroval.time, aeroval.season);

	//select right parameter, layer and model
	const modData = data[aeroval.model.dir][aeroval.model.var];
	// keep this into memory for easier further access
	//aeroval.data.sub_hm = modData;

	var series = [] as any;
	for (let stat of stats) {
		var seriesData = [];
		var regionalStatistics = {} as any;
		var x = 0;

		for (let region in aeroval.regions) {
			if (region != '' && modData?.[region]?.[keyTime]) {
				var statData = modData[region][keyTime][stat];
				if (statData && typeof statData === 'object') {
					const mapVar = statData.map_var;
					var value = statData[mapVar] as number;
				} else if (stat === 'nrms') {
					var value = (modData[region][keyTime]['rms'] as number) / (modData[region][keyTime]['refdata_mean'] as number);
				} else {
					var value = statData as number;
				}
				if (aeroval.statistic.unit === '%' && !stat.includes('trends')) {
					value *= 100;
				}

				regionalStatistics[region] = {
					statistic: value,
					nTotal: modData[region][keyTime]['num_coords_with_data'],
				};

				// set up color
				if (stats.length > 1 && stat.includes('mod')) {
					var color = '#fdaa56';
					var borderColor = '#ff9933';
				} else {
					if (aeroval.settings.regionalStatisticsSingleModelColor === 'theme') {
						color = '#d3d3d3';
						borderColor = '#808080';
					} else {
						//prepare the legend
						if (aeroval.statistic.scale == null) {
							var scale = aeroval.ranges[aeroval.parameter.dir].scale;
						} else {
							if (aeroval.statistics[aeroval.statistic.dir].unit == 'var') {
								// this is a relative scale: multiply by Max of Mean scale
								var statScale = aeroval.ranges[aeroval.parameter.dir].scale;
								scale = aeroval.statistic.scale.map((x) => x * Math.max(...statScale));
								// round down to two decimals
								scale = scale.map((x) => parseFloat(x.toFixed(2))) as number[];
							} else {
								// set scale as it is
								scale = aeroval.statistic.scale;
							}
						}
						color = colStat(value, scale);
						borderColor = color;
					}
				}

				if (aeroval.globalRegions.includes(region)) {
					var colorStyle = {
						pattern: {
							path: {
								d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
							},
							strokeWidth: 3,
							width: 10,
							height: 10,
							color,
							opacity: 1,
						},
					} as any;
					var borderWidth = 1;
				} else {
					colorStyle = color;
					borderWidth = 0;
				}

				var label: string | null = null;
				if ((aeroval.cfg.webdisp_opts.country_flag || aeroval.cfg.webdisp_opts.country_code) && !aeroval.globalRegions.includes(region)) {
					var country = region;
					if (country === 'Macedonia') {
						country = 'North Macedonia';
					}
					if (country === 'Holy See (Vatican City State)') {
						country = 'Vatican';
					}

					if (aeroval.world_flags && country in aeroval.world_flags) {
						if (aeroval.cfg.webdisp_opts.country_flag) {
							label = aeroval.world_flags[country].Emoji;
						} else if (aeroval.cfg.webdisp_opts.country_code) {
							label = aeroval.world_flags[country].ISO;
						}
					}
				}

				seriesData.push({
					x,
					y: regionalStatistics[region]['statistic'],
					label,
					color: colorStyle,
					stdv: 0,
					n: regionalStatistics[region]['nTotal'],
					borderWidth,
					borderColor,
					name: `${stat}-${region}`,
				});
			} else {
				regionalStatistics[region] = {
					statistic: null,
					nTotal: null,
				};
			}
			x++;
		}

		series.push({
			name: 'Average',
			type: 'column',
			yAxis: 0,
			data: seriesData,
			showInLegend: false,
			enableMouseTracking: true,
			tooltip: {
				enabled: true,
			},
			dataLabels: {
				enabled: true,
				allowOverlap: true,
				style: { fontSize: 8, fontWeight: 'bolder' },
				formatter: function (): string {
					return (this as any).point.label;
				},
				inside: false,
				y: 0,
				x: 0,
			},
		});
	}

	const unit = getUnits();
	const titleText = getTitle();

	Highcharts.chart(
		id,
		{
			chart: {
				renderTo: id,
				type: 'column',
				style: {
					cursor: 'pointer',
				},
				backgroundColor: 'transparent',
				events: {
					fullscreenOpen: function () {
						// fullscreen background color
						var fullScreenBackgroundColor = 'white';
						if (aeroval.settings.theme === 'dark') {
							var fullScreenBackgroundColor = '#282b34';
						}
						(this as any).update({
							chart: {
								backgroundColor: fullScreenBackgroundColor,
							},
						});
					},
					fullscreenClose: function () {
						(this as any).update({
							chart: {
								backgroundColor: 'transparent',
							},
						});
					},
				},
			},
			credits: {
				enabled: false,
			},
			title: {
				text: titleText,
			},
			subtitle: {
				text: `${aeroval.frequency} data - all stations`,
			},
			xAxis: {
				categories: Object.keys(regionalStatistics),
				crosshair: true,
				labels: {
					overflow: 'justify',
					rotation: -45,
				},
				cursor: 'pointer',
			} as Highcharts.XAxisOptions,
			yAxis: [
				{
					title: {
						text: `${aeroval.statistic.name} ${unit}`,
					},
				},
				{
					gridLineWidth: 0,
					title: {
						text: 'Nb. of Stations (◆)',
					},
					opposite: true,
					visible: false,
				},
			],
			plotOptions: {
				column: {
					borderWidth: 0,
					shadow: false,
				},
				series: {
					cursor: 'pointer',
					point: {
						events: {
							mouseOver: function () {
								if (aeroval.settings.presentationMode !== 'true') {
									const thisPoint = this as any;
									const mapRegion = getChartById('map')?.get(thisPoint.category.name) as Highcharts.Series;
									const hoverLoaderRegionalChart = document.querySelector('#hoverLoaderReg');
									if (hoverLoaderRegionalChart instanceof HTMLElement) {
										hoverLoaderRegionalChart.classList.remove('hide');
										hoverLoaderRegionalChart.classList.add('show');
									}
									aeroval.wait = false;
									aeroval.timeOver = setTimeout(function () {
										if (aeroval.wait === false && mapRegion) {
											mapRegion.show();
											evalUpdate('station', thisPoint.category.name);
											const hoverLoaderRegionalChart = document.querySelector('#hoverLoaderReg');
											if (hoverLoaderRegionalChart instanceof HTMLElement) {
												hoverLoaderRegionalChart.classList.remove('show');
												hoverLoaderRegionalChart.classList.add('hide');
											}
										}
									}, 1000);
								}
							},
							mouseOut: function () {
								const thisPoint = this as any;
								aeroval.wait = true;
								clearTimeout(aeroval.timeOver);
								const hoverLoaderRegionalChart = document.querySelector('#hoverLoaderReg');
								if (hoverLoaderRegionalChart instanceof HTMLElement) {
									hoverLoaderRegionalChart.classList.remove('show');
									hoverLoaderRegionalChart.classList.add('hide');
								}
								const mapRegion = getChartById('map')?.get(thisPoint.category.name) as Highcharts.Series;
								if (typeof mapRegion != 'undefined') {
									mapRegion.hide();
								}
							},
							click: function () {
								console.log('click')
								const thisPoint = this as any;
								aeroval.wait = false;
								clearTimeout(aeroval.timeOver);
								const mapRegion = getChartById('map')?.get(thisPoint.category.name) as Highcharts.Series;
								if (typeof mapRegion != 'undefined') {
									mapRegion.show();
								}
								evalUpdate('station', thisPoint.category.name);
								const hoverLoaderRegionalChart = document.querySelector('#hoverLoaderReg');
								if (hoverLoaderRegionalChart instanceof HTMLElement) {
									hoverLoaderRegionalChart.classList.remove('show');
									hoverLoaderRegionalChart.classList.add('hide');
								}
							},
						},
					},
				},
			},
			tooltip: {
				shared: true,
				formatter: function () {
					const thisPoint = this.points?.[0]?.point;
					var value: number | undefined = undefined;
					if (this.points?.[0] && this.points?.[1]) {
						value = (this.points[0].point.y as number) / (this.points[1].point.y as number);
					} else if (this.points?.[0]) {
						value = this.points[0].point.y;
					}
					if (value && this.points?.[0] && aeroval.statistic.name) {
						return `<b>${this.points[0].x}</b><br>${aeroval.statistic.name}: <b>${value.toFixed(
							aeroval.statistic.decimals
						)}</b><br> Nb. of  Stations: <b>${(thisPoint as any).n}</b>`;
					}
				},
			},
			series,
		},
		function () {
			// when the chart is rendered
			var h = `<table class='table table-hover table-condensed slideTable'>`;
			h += '<thead>';
			h += '<tr>';
			h += "<th rowspan='1'>Region</th>";
			h += "<th rowspan='1'>Nb. of Stations</th>";
			h += `<th seriesDatapan='1'>${aeroval.statistic.name}</th>`;
			h += '</tr>';
			h += '</thead>';
			h += '<tbody>';
			for (let region in regionalStatistics) {
				h += '<tr>';
				h += `<td>${region}</td>`;
				h += `<td>${regionalStatistics[region]['nTotal']}</td>`;
				if (regionalStatistics[region]['statistic'] != null) {
					h += `<td>${regionalStatistics[region]['statistic'].toFixed(2)}</td>`;
				} else {
					h += `<td>${regionalStatistics[region]['statistic']}</td>`;
				}
				h += '</tr>';
			}
			h += '</tbody>';
			h += '</table>';

			const regStatTable = document.querySelector('#regStatTable');
			if (regStatTable instanceof HTMLElement) {
				regStatTable.innerHTML = h;
			}

			//toggleRegionalStatisticsSingleModelColor(aeroval.settings.regionalStatisticsSingleModelColor);
			evalCallbackGlobStat(updateTS, updateScat);
			//chart.redraw();
		}
	);
}

export function toggleRegionalStatisticsSingleModelColor(value: string): void {
	enableMode(value, 'regStat');
	aeroval.settings.regionalStatisticsSingleModelColor = value;
}

export function enableMode(value: string, id: string): void {
	if (!aeroval.ranges) return;

	if (value === 'theme') {
		const chart = getChartById(id);
		if (chart) {
			for (let point of chart.series[0].data) {
				var color = '#d3d3d3';
				if (aeroval.globalRegions.includes((point.category as any).name)) {
					var colorStyle = {
						pattern: {
							path: {
								d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
							},
							strokeWidth: 3,
							width: 10,
							height: 10,
							color,
							opacity: 1,
						},
					} as any;
				} else {
					var colorStyle = color as any;
				}
				point.update(
					{
						color: colorStyle,
					} as any,
					false
				);
			}
			chart.redraw();
		}
	}
	if (value === 'value') {
		//prepare the legend
		if (aeroval.statistic.scale == null) {
			var scale = aeroval.ranges[aeroval.parameter.dir].scale;
		} else {
			if (aeroval.statistics[aeroval.statistic.dir].unit == 'var') {
				// this is a relative scale: multiply by Max of Mean scale
				var statScale = aeroval.ranges[aeroval.parameter.dir].scale;
				var scale = aeroval.statistic.scale.map((x) => x * Math.max(...statScale));
				// round down to two decimals
				var scale = scale.map((x) => parseFloat(x.toFixed(2))) as number[];
			} else {
				// set scale as it is
				var scale = aeroval.statistic.scale;
			}
		}

		if (!scale) return;
		const chart = getChartById(id);
		if (chart) {
			for (let point of chart.series[0].data) {
				var color = colStat((point as any).y, scale);
				if (aeroval.globalRegions.includes((point.category as any).name)) {
					var colorStyle = {
						pattern: {
							path: {
								d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
							},
							strokeWidth: 3,
							width: 10,
							height: 10,
							color,
							opacity: 1,
						},
					} as any;
				} else {
					var colorStyle = color as any;
				}
				point.update(
					{
						color: colorStyle,
					} as any,
					false
				);
			}
			chart.redraw();
		}
	}
	aeroval.settings.regionalStatisticsColor = value;
}

function getTitle(): string {
	var title = aeroval.parameter.name;
	if (aeroval.layer != 'Surface' && aeroval.layer != 'Column') {
		title += ` (${aeroval.layer})`;
	}
	title += ` - ${aeroval.time}`;
	if (aeroval.season != 'All') {
		title += ` (${aeroval.season})`;
	}
	return title;
}
