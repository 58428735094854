import Highcharts from 'highcharts';

/*import interfaces*/
import { Aeroval } from '../../types/global';
declare var aeroval: Aeroval;

import chartsStyle from '../../../public/config/charts-style.json'

export function updateLineWidth(value: string): void {
	aeroval.settings.lineWidthFactor = value;

	// redraw all charts
	Highcharts.charts.forEach(function (chart) {
		if (chart && ((chart.options.xAxis as any)[0]?.type === 'datetime' || chart.options.chart?.renderTo === 'frcst-ts' || chart.options.chart?.renderTo === 'evaluation-ts')) {
			const chartId = (chart as any).renderTo.id as keyof typeof chartsStyle
			if (chartId in chartsStyle) {
				// default lineWidth from chartsStyle
				for (const series of chart.series) {
					// check if style is defined for that series
					var useDefaultStyle = true
					var styleSeries = undefined
					for (const seriesKey in chartsStyle[chartId]) {
						if (series.userOptions.id?.startsWith(seriesKey.split('*')[0])) {
							useDefaultStyle = false
							styleSeries = (chartsStyle[chartId] as any)[seriesKey]
						}
					}

					var seriesWeight = (series.userOptions as any)?.weight ? (series.userOptions as any)?.weight : 1;
					// id can be defined as: mod-* and is supposed to define all ids starting with mod-
					if (useDefaultStyle) {
						(series as any).update({
							'lineWidth': chartsStyle["default"]["*"].lineWidth * eval(value) * seriesWeight,
							'marker': {
								'radius': chartsStyle["default"]["*"].markerSize * eval(value) * seriesWeight,
							}
						}, false)
					} else {
						(series as any).update({
							'lineWidth': styleSeries.lineWidth * eval(value) * seriesWeight,
							'marker': {
								'radius': styleSeries.markerSize * eval(value) * seriesWeight,
							}
						}, false)
					}
				}
			} else {
				// default lineWidth: 2
				for (const series of chart.series) {
					var seriesWeight = (series.userOptions as any)?.weight ? (series.userOptions as any)?.weight : 1;
					(series as any).update({
						'lineWidth': chartsStyle["default"]["*"].lineWidth * eval(value) * seriesWeight,
						'marker': {
							'radius': chartsStyle["default"]["*"].markerSize * eval(value) * seriesWeight,
						}
					}, false)
				}
			}
			chart?.redraw()
		}
	});
}
