import './style.css';

export function animateIcons(): void {
    // animate navbar
    var navLinkElements = document.querySelectorAll('a.nav-link > svg')
    if (!navLinkElements) return;
    navLinkElements.forEach(async (navLinkElements) => {
        navLinkElements.classList.add('fa-beat');
    })

    // animate counters
    var counterIconElements = document.querySelectorAll('.count_icon > svg')
    if (!counterIconElements) return;
    counterIconElements.forEach(async (counterIconElement) => {
        counterIconElement.classList.add('fa-bounce');
    })

    // animate gear
    var toggleSettingsElement = document.querySelector('#toggle-settings > svg');
    if (toggleSettingsElement) {
        toggleSettingsElement?.classList.add('fa-spin');
    }
}
