import Highcharts from 'highcharts';

/*import interfaces*/
import { Aeroval } from '../../types/global';
declare var aeroval: Aeroval;

export function toggleLineStyle(value: string): void {
	aeroval.settings.lineStyle = value;

	// redraw all charts
	Highcharts.charts.forEach(function (chart) {
		if (chart && ((chart.options.xAxis as any)[0]?.type === 'datetime' || chart.options.chart?.renderTo === 'frcst')) {
			if (['spline', 'line'].includes(value)) {
				chart.update({
					chart: {
						type: value,
					},
				});
			}
		}
	});
}
