import { Aeroval } from '../../types/global';
import './index.css';

declare var aeroval: Aeroval;

export function showPopup(): void {
    var popup = aeroval.cfg.exp_info?.popup;
    if (!popup || document.getElementById('popup')) return;

    // add alert element before the first element of the main-content class
    const firstTargetElement = document.querySelector('.main-content');
    if (firstTargetElement) {
        const popupElement = document.createElement('div');
        popupElement.id = 'popup';
        popupElement.classList.add('alert', `alert-${popup.type}`, 'alert-dismissible', 'fade', 'show', 'mx-3')
        popupElement.setAttribute('role', 'alert');
        popupElement.innerHTML = `
			${popup.text}
			<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
		`;
        firstTargetElement.parentNode?.insertBefore(popupElement, firstTargetElement);
    }
}