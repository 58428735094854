import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import Boost from 'highcharts/modules/boost';
if (typeof Highcharts === 'object') {
	Exporting(Highcharts);
	ExportData(Highcharts);
	OfflineExporting(Highcharts);
	Boost(Highcharts);
}

import * as utils from '../../../utils';
import { addGuidelines } from '../guidelines';
import chartsStyle from '../../../../public/config/charts-style.json'

import { Aeroval } from '../../../types/global';
import { TSData } from '../../../types/data';
declare var aeroval: Aeroval;

export function plotScatterPlotSingleModel(tsType: string = 'regular'): void {
	if (tsType === 'regular') {
		plotScatterPlot('scat');
	} else if (tsType === 'weekly') {
		plotScatterPlotWeekly('scat');
	}
}

function getDataFromChart(): TSData {
	// for avoiding global data, could extract data from ts chart
	/*var targetChart = utils.getChartById(id);
	if (targetChart){
		console.log(targetChart);
	}*/
	return aeroval.data.ts as TSData;
}

function plotScatterPlot(id: string) {
	const data = getDataFromChart();
	const modelData = data[aeroval.model.dir];
	const tsChart = utils.getChartById('evaluation-ts');
	if (!tsChart || !modelData) return;

	// set time boundaries: from zoom state or from time series data
	const [xMin, xMax] = getXBoundariesFromTimeSeries();
	if (!xMin || !xMax) return;

	// window
	const scatData: { [frequency: string]: [number, number][] } = {};

	var frequencies = utils.getOrderedFrequencies();
	// remove yearly if single year
	if (frequencies.includes('yearly') && (modelData['yearly_date']?.length ?? 0) <= 1) {
		frequencies = frequencies.filter((item) => item !== 'yearly');
	}
	frequencies = utils.filterFrequencies(frequencies, aeroval.frequency)
	const whichFrequencies = ['primary', 'secondary'];
	const minN = Math.min(frequencies.length, whichFrequencies.length);

	// Precompute months in season before the main loop since it doesn't change
	const monthsInSeason = utils.monthsInSeason(aeroval.season);

	// Main loop optimization
	for (let i = 0; i < minN; i++) {
		const freq = frequencies[i];
		const whichFreq = whichFrequencies[i];

		// Data arrays
		const xObs = [];
		const xMod = [];
		const yObs = [];
		const yMod = [];

		const keyDate = `${freq}_date` as keyof TSData[keyof TSData];
		const keyObs = `${freq}_obs` as keyof TSData[keyof TSData];
		const keyMod = `${freq}_mod` as keyof TSData[keyof TSData];

		const dateArray = modelData[keyDate] as number[];
		const obsArray = modelData[keyObs] as number[];
		const modArray = modelData[keyMod] as number[];

		if (!dateArray || !obsArray || !modArray) continue;

		// Loop over dateArray once to minimize overhead
		for (let j = 0; j < dateArray.length; j++) {
			const dateValue = dateArray[j];

			// Check date range
			if (dateValue > xMin && dateValue <= xMax) {
				const obsValue = obsArray[j];
				const modValue = modArray[j];
				const currentDate = new Date(dateValue); // Create Date object once
				const iMonth = currentDate.getMonth() + 1;
				let iYear = currentDate.getFullYear();

				if (aeroval.season === 'DJF' && iMonth === 12) {
					iYear++;
				}

				if (aeroval.season === 'All' || monthsInSeason.includes(iMonth)) {
					// If single year is selected, match year
					if (!aeroval.time.includes('-') && iYear !== parseInt(aeroval.time)) {
						continue;
					}

					// Push data in bulk
					xObs.push(dateValue);
					xMod.push(dateValue);
					yObs.push(obsValue);
					yMod.push(modValue);
				}
			}
		}

		// Minimize nested loops by matching directly using indexes
		scatData[whichFreq] = [];
		for (let j = 0; j < xObs.length; j++) {
			if (yObs[j] != null && yMod[j] != null) {
				scatData[whichFreq].push([yObs[j], yMod[j]]);
			}
		}
	}

	// calculates regression - from primary data
	const scatX = scatData['primary'].map(([x, _]) => x);
	const scatY = scatData['primary'].map(([_, y]) => y);

	// calculates min value
	const [valMin, valMax] = getYBoundariesFromTimeSeries();
	if (typeof valMin === 'undefined' || typeof valMax === 'undefined') return;

	var dataRegression: any[];
	var dashStyle: string | undefined = undefined;

	if (scatData['primary'].length > 1) {
		const lr = utils.linearRegression(scatY, scatX);
		if (!lr || !lr.intercept || !lr.slope || !lr.r2) return;

		// regression line
		dataRegression = [
			[0, lr.intercept],
			[valMax, valMax * lr.slope + lr.intercept],
		];
		var txtEquation = 'y = ' + (Math.round(lr.slope * 100) / 100).toString() + ' x + ' + (Math.round(lr.intercept * 100) / 100).toString();
		var txtNumber = 'n: ' + scatX.length.toString();
		var txtR2 = 'R2: ' + (Math.round(lr.r2 * 100) / 100).toString();

		//dashStyle depending on R2
		if (lr.r2 < 0.2) {
			dashStyle = 'Dash';
		} else {
			dashStyle = 'Solid';
		}
	} else {
		txtEquation = 'Data not available';
		txtNumber = '';
		txtR2 = '';
		dataRegression = [];
	}

	//get precomputed statistics for selected station
	var stats = {} as any;
	//if the site is a region, then take if from reg_stat data
	const keyTime = utils.getKeyTime(aeroval.time, aeroval.season);
	var xAxisUnit = '';
	var yAxisUnit = '';
	if (Object.keys(aeroval.regions).includes(aeroval.station)) {
		if (!aeroval.data.reg_stat) return;
		//get statistics
		var statistics = aeroval.data.reg_stat[aeroval.model.dir][aeroval.model.var];

		if (statistics[aeroval.station][keyTime]['nmb'] != null) {
			var nmb = statistics[aeroval.station][keyTime]['nmb'];
			stats['nmb'] = 100 * (nmb as number);
		} else {
			stats['nmb'] = null;
		}
		if (statistics[aeroval.station][keyTime]['R'] != null) {
			var r = statistics[aeroval.station][keyTime]['R'];
			stats['R'] = r;
		} else {
			stats['R'] = null;
		}
		stats['n'] = statistics[aeroval.station][keyTime]['num_valid'];
	}

	// get units
	var obsUnit = modelData.obs_unit;
	var modUnit = modelData.mod_unit;
	if (obsUnit != '1') {
		xAxisUnit = `(${obsUnit})`;
	}
	if (modUnit != '1') {
		yAxisUnit = `(${modUnit})`;
	}

	if (aeroval.axisType == 'linear') {
		var yAxis = {
			title: {
				text: `${aeroval.model.name} ${yAxisUnit}`,
			},
			min: 0,
			max: valMax,
			endOnTick: true,
			gridLineWidth: 0,
			type: aeroval.axisType,
		} as any;
		var xAxis = {
			title: {
				text: `${aeroval.observation} ${xAxisUnit}`,
			},
			min: 0,
			max: valMax,
			endOnTick: true,
			type: aeroval.axisType,
		} as any;
	} else if (aeroval.axisType == 'logarithmic') {
		var yAxis = {
			title: {
				text: `${aeroval.model.name} ${modUnit}`,
			},
			min: Math.max(0, utils.getLowerPowerOfTen(valMin) as number),
			max: valMax,
			endOnTick: true,
			gridLineWidth: 0,
			type: aeroval.axisType,
		} as any;
		var xAxis = {
			title: {
				text: `${aeroval.observation} ${obsUnit}`,
			},
			min: Math.max(0, utils.getLowerPowerOfTen(valMin) as number),
			max: valMax,
			endOnTick: true,
			type: aeroval.axisType,
		} as any;
	}

	//set title
	const titleText = getTitle();

	// set style
	const colorRegression = aeroval.settings.theme === 'dark' ? chartsStyle['evaluation-ts']['obs-secondary'].colorDarkMode.replace('0.4', '0.5') : chartsStyle['evaluation-ts']['obs-secondary'].color.replace('0.4', '0.5');
	const colorObsPrimary = aeroval.settings.theme === 'dark' ? chartsStyle['evaluation-ts']['obs-primary'].colorDarkMode : chartsStyle['evaluation-ts']['obs-primary'].color;
	const colorObsSecondary = aeroval.settings.theme === 'dark' ? chartsStyle['evaluation-ts']['obs-secondary'].colorDarkMode : chartsStyle['evaluation-ts']['obs-secondary'].color;

	// set visibility
	var visPrimary = true;
	if (typeof aeroval.seriesVisibility['Model (' + frequencies[0] + ')'] != 'undefined') {
		visPrimary = aeroval.seriesVisibility['Model (' + frequencies[0] + ')'];
	}
	var visSecondary = true;
	if (typeof aeroval.seriesVisibility['Model (' + frequencies[1] + ')'] != 'undefined') {
		visSecondary = aeroval.seriesVisibility['Model (' + frequencies[1] + ')'];
	}

	Highcharts.chart(
		id,
		{
			chart: {
				renderTo: id,
				zooming: {
					type: 'xy',
				},
				backgroundColor: 'transparent',
				events: {
					fullscreenOpen: function () {
						// fullscreen background color
						var fullScreenBackgroundColor = 'white';
						if (aeroval.settings.theme == 'dark') {
							fullScreenBackgroundColor = '#282b34';
						}
						(this as any).update({
							chart: {
								backgroundColor: fullScreenBackgroundColor,
							},
						});
					},
					fullscreenClose: function () {
						(this as any).update({
							chart: {
								backgroundColor: 'transparent',
							},
						});
					},
				},
			},
			boost: {
				useGPUTranslations: true,
				usePreallocated: true,
			},
			title: {
				text: titleText,
			},
			subtitle: {
				text: `${aeroval.observation} - ${aeroval.model.name}`,
			},
			tooltip: {
				shared: true,
				pointFormat: '({point.x:.2f}, {point.y:.2f})',
			},
			credits: {
				enabled: false,
			},
			yAxis: yAxis,
			xAxis: xAxis,
			legend: {
				layout: 'vertical',
				align: 'right',
				verticalAlign: 'top',
				floating: true,
				y: 50,
			},
			plotOptions: {
				series: {},
			},
			series: [
				{
					type: 'scatter',
					name: frequencies[1],
					id: `Model (${frequencies[1]})`,
					data: scatData['secondary'],
					color: colorObsSecondary,
					lineWidth: 0,
					marker: {
						enabled: true,
						radius: 2,
					},
					showInLegend: false,
					visible: visSecondary,
				},
				{
					type: 'scatter',
					name: frequencies[0],
					id: `Model (${frequencies[0]})`,
					data: scatData['primary'],
					lineWidth: 0,
					color: colorObsPrimary,
					showInLegend: false,
					visible: visPrimary,
				},
				{
					name: 'regression line',
					type: 'line',
					data: dataRegression,
					dashStyle: dashStyle as any,
					marker: {
						enabled: false,
					},
					color: colorRegression,
					showInLegend: false,
					tooltip: {
						valueDecimals: 3,
					},
				},
			],
		},
		function (chart) {
			//on complete
			chart.renderer
				.text('Linear regression (' + frequencies[0] + ')', chart.plotLeft + 20, 70)
				.css({
					fontSize: '12px',
					fontWeight: 'bold',
				})
				.attr({
					zIndex: 99,
				})
				.add();
			chart.renderer
				.text(txtNumber, chart.plotLeft + 20, 85)
				.css({
					fontSize: '12px',
				})
				.attr({
					zIndex: 99,
				})
				.add();
			chart.renderer
				.text(txtEquation, chart.plotLeft + 20, 100)
				.css({
					fontSize: '12px',
				})
				.attr({
					zIndex: 99,
				})
				.add();
			chart.renderer
				.text(txtR2, chart.plotLeft + 20, 115)
				.css({
					fontSize: '12px',
				})
				.attr({
					zIndex: 99,
				})
				.add();

			// add regional statistics from the map
			if (aeroval.station in aeroval.regions) {
				//add statistics from the map
				chart.renderer
					.text('Regional statistics ' + '(' + aeroval.frequency + ')', chart.plotLeft + 20, 130)
					.css({
						fontSize: '12px',
						fontWeight: 'bold',
					})
					.attr({
						zIndex: 99,
					})
					.add();
				chart.renderer
					.text('n: ' + stats['n'], chart.plotLeft + 20, 145)
					.css({
						fontSize: '12px',
					})
					.attr({
						zIndex: 99,
					})
					.add();
				chart.renderer
					.text(`NMB (%): ${stats['nmb'].toFixed(1)}`, chart.plotLeft + 20, 160)
					.css({
						fontSize: '12px',
					})
					.attr({
						zIndex: 99,
					})
					.add();
				chart.renderer
					.text(`R: ${stats['R'].toFixed(2)}`, chart.plotLeft + 20, 175)
					.css({
						fontSize: '12px',
					})
					.attr({
						zIndex: 99,
					})
					.add();
			}

			// add Guidelines
			addGuidelines(chart);
		}
	);
}

function plotScatterPlotWeekly(id: string) {
	const data = aeroval.data.ts_weekly;
	const modelData = aeroval.data.ts_weekly?.[aeroval.model.dir];
	const tsChart = utils.getChartById('evaluation-ts');
	if (!tsChart || !data || !modelData) return;

	// set time boundaries: from zoom state or from time series data
	const [xMin, xMax] = getXBoundariesFromTimeSeries();
	if (!xMin || !xMax) return;

	//hourly time series: the key can be All or Annual
	var gKey = 'seasonal' as 'yearly' | 'seasonal';
	var sKey = aeroval.season as string;
	if (aeroval.season == 'All') {
		gKey = 'yearly';
		sKey = utils.getDiurnalAllKey(Object.keys(modelData.yearly.obs[aeroval.time])) as string;
	}

	//set colors
	if (aeroval.settings.theme == 'dark') {
		var regColor = 'rgba(200,200,200,0.5)';
		var guideColor = 'rgba(220,220,220,0.3)';
		if (aeroval.granularity == 'year') {
			var colors = ['rgba(200,200,200,1)'];
		} else {
			colors = ['#6c809a', '#87d68d', '#db7f8e', '#613a3a'];
		}
	} else {
		regColor = 'rgba(128,128,128,0.5)';
		guideColor = 'rgba(128,128,128,0.3)';
		if (aeroval.granularity == 'year') {
			colors = ['rgba(128,128,128,1)'];
		} else {
			colors = ['#6c809a', '#87d68d', '#db7f8e', '#613a3a'];
		}
	}

	var series = [] as any;
	var i = 0;

	const scatData: [number, number][] = [];
	const scatX: number[] = [];
	const scatY: number[] = [];

	// overwrite visibility
	var vis = true;
	if (typeof aeroval.seriesVisibility['Model'] != 'undefined') {
		vis = aeroval.seriesVisibility['Model'];
	}

	if (!modelData.time || !sKey) return;
	for (let j = 0; j < modelData.time?.length; j++) {
		if (modelData.time[j] >= xMin && modelData.time[j] <= xMax) {
			scatX.push(modelData[gKey]['obs'][aeroval.time][sKey][j] as number);
			scatY.push(modelData[gKey]['mod'][aeroval.time][sKey][j] as number);
			scatData.push([modelData[gKey]['obs'][aeroval.time][sKey][j], modelData[gKey]['mod'][aeroval.time][sKey][j]]);
		}
	}

	// set maximum value
	const [valMin, valMax] = getYBoundariesFromTimeSeries();
	if (typeof valMin === 'undefined' || typeof valMax === 'undefined') return;

	series.push({
		type: 'scatter',
		name: 'Model',
		id: 'Model',
		data: scatData,
		color: colors[i],
		showInLegend: false,
		visible: vis,
	});

	// show regression only when granularity is year
	// calculates regression - from primary data
	var dataRegression: null[][] | number[][] = [];

	if (aeroval.granularity == 'year') {
		//get regression - from MONTHLY data
		if (scatX.length > 0) {
			const lr = utils.linearRegression(scatY, scatX);
			if (!lr || !lr.intercept || !lr.slope || !lr.r2) return;

			//regression line
			dataRegression = [
				[0, lr.intercept],
				[valMax, valMax * lr.slope + lr.intercept],
			];
			var txtEquation = 'y = ' + (Math.round(lr.slope * 100) / 100).toString() + ' x + ' + (Math.round(lr.intercept * 100) / 100).toString();
			var txtNumber = 'n: ' + scatX.length.toString();
			var txtR2 = 'R2: ' + (Math.round(lr.r2 * 100) / 100).toString();
		} else {
			txtEquation = 'Mod/Sat not available';
			txtNumber = '';
			txtR2 = '';
			dataRegression = [[]];
		}
	} else if (aeroval.granularity == 'season') {
		txtEquation = '';
		txtNumber = '';
		txtR2 = '';
		dataRegression = [[]];
	}

	//add regression line
	series.push({
		type: 'line',
		data: dataRegression,
		marker: {
			enabled: false,
		},
		color: regColor,
		showInLegend: false,
	});

	//add diagonal first
	series.unshift({
		name: '1:1',
		data: [
			[1e-6, 1e-6],
			[2 * valMax, 2 * valMax],
		],
		showInLegend: false,
		color: guideColor,
		lineWidth: 0.75,
		marker: {
			enabled: false,
		},
		enableMouseTracking: false,
	});

	/*unit*/
	if (aeroval.observationUnit != '1') {
		var obsUnit = ` (${aeroval.observationUnit})`;
	} else {
		obsUnit = '';
	}
	if (aeroval.modelUnit != '1') {
		var modUnit = ` (${aeroval.modelUnit})`;
	} else {
		modUnit = '';
	}

	if (aeroval.axisType == 'linear') {
		var yAxis = {
			title: {
				text: aeroval.model.name + modUnit,
			},
			min: 0,
			max: valMax,
			endOnTick: true,
			gridLineWidth: 0,
			type: aeroval.axisType,
		} as any;
		var xAxis = {
			title: {
				text: aeroval.observation + obsUnit,
			},
			min: 0,
			max: valMax,
			endOnTick: true,
			type: aeroval.axisType,
		} as any;
	} else if (aeroval.axisType == 'logarithmic') {
		var yAxis = {
			title: {
				text: aeroval.model.name + modUnit,
			},
			min: Math.max(0, utils.getLowerPowerOfTen(valMin) as number),
			max: valMax,
			endOnTick: true,
			gridLineWidth: 0,
			type: aeroval.axisType,
		} as any;
		var xAxis = {
			title: {
				text: aeroval.observation + obsUnit,
			},
			min: Math.max(0, utils.getLowerPowerOfTen(valMin) as number),
			max: valMax,
			endOnTick: true,
			type: aeroval.axisType,
		} as any;
	}

	//set title
	const titleText = getTitle();

	Highcharts.chart(
		{
			chart: {
				renderTo: id,
				backgroundColor: 'transparent',
				events: {
					fullscreenOpen: function () {
						// fullscreen background color
						var fullScreenBackgroundColor = 'white';
						if (aeroval.settings.theme == 'dark') {
							fullScreenBackgroundColor = '#282b34';
						}
						(this as any).update({
							chart: {
								backgroundColor: fullScreenBackgroundColor,
							},
						});
					},
					fullscreenClose: function () {
						(this as any).update({
							chart: {
								backgroundColor: 'transparent',
							},
						});
					},
				},
			},
			title: {
				text: titleText,
			},
			subtitle: {
				text: 'monthly means',
			},
			credits: {
				enabled: false,
			},
			yAxis,
			xAxis,
			tooltip: {
				shared: true,
				pointFormat: '({point.x:.2f}, {point.y:.2f})',
			},
			legend: {
				layout: 'vertical',
				align: 'right',
				verticalAlign: 'top',
				floating: true,
				y: 50,
			},
			plotOptions: {
				series: {
					boostThreshold: 1,
				},
			},
			series,
		},
		function (chart) {
			//on complete
			chart.renderer
				.text(txtEquation, chart.plotLeft + 20, 70)
				.css({
					fontSize: '12px',
					fontWeight: 'bold',
				})
				.attr({
					zIndex: 99,
				})
				.add();
			chart.renderer
				.text(txtNumber, chart.plotLeft + 20, 85)
				.css({
					fontSize: '12px',
					fontWeight: 'bold',
				})
				.attr({
					zIndex: 99,
				})
				.add();
			chart.renderer
				.text(txtR2, chart.plotLeft + 20, 100)
				.css({
					fontSize: '12px',
					fontWeight: 'bold',
				})
				.attr({
					zIndex: 99,
				})
				.add();
		}
	);
}

function getTitle(): string {
	let title = aeroval.parameter.name;
	if (aeroval.layer != 'Surface' && aeroval.layer != 'Column') {
		title += ` (${aeroval.layer})`;
	}
	// add station and time
	title += ` - ${aeroval.station} - ${aeroval.time}`;
	// add season
	if (aeroval.season != 'All') {
		title += ` (${aeroval.season})`;
	}
	return title;
}

function getXBoundariesFromTimeSeries(): [number | undefined, number | undefined] {
	const tsChart = utils.getChartById('evaluation-ts');
	var xMin: number | undefined = undefined;
	var xMax: number | undefined = undefined;

	if (tsChart) {
		xMin = (tsChart.axes[0] as any).min;
		if (aeroval.zoom?.min) {
			xMin = aeroval.zoom.min;
		}
		xMax = (tsChart.axes[0] as any).max;
		if (aeroval.zoom?.max) {
			xMax = aeroval.zoom.max;
		}
		if (aeroval.zoom && aeroval.zoom.state === false) {
			xMin = (tsChart.axes[0] as any).dataMin;
			xMax = (tsChart.axes[0] as any).dataMax;
		}
	}

	return [xMin, xMax];
}

function getYBoundariesFromTimeSeries(): [number | undefined, number | undefined] {
	const tsChart = utils.getChartById('evaluation-ts');
	var yMin: number | undefined = undefined;
	var yMax: number | undefined = undefined;

	if (tsChart) {
		if (tsChart.axes[1].userOptions.type === 'logarithmic') {
			yMin = Math.pow(10, (tsChart.axes[1] as any).min);
			yMax = Math.pow(10, (tsChart.axes[1] as any).max);
		} else {
			yMin = (tsChart.axes[1] as any).min;
			yMax = (tsChart.axes[1] as any).max;
		}
	}
	return [yMin, yMax];
}
