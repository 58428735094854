// from r173.5, with importmap (index.php)
import * as THREE from 'three';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { Water } from 'three/examples/jsm/objects/Water';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { globePoints } from '../../../public/geography/globe';

/*declare global types*/
declare global {
	interface globe {
		radius: number;
		width: number;
		height: number;
		auto_rotate: boolean;
		globe: boolean;
		elevation: boolean;
		clouds: boolean;
		oceans: boolean;
		atmosphere: boolean;
		grid: boolean;
		orbits: boolean;
		satellite: boolean;
		user_location: boolean;
		ambientLight: boolean;
		sun: boolean;
		scene: any;
		camera: any;
		controls: any;
	}
}

export function globeJS(): void {
	const globe: globe = {
		radius: 100,
		width: 4098 / 2,
		height: 1968 / 2,
		auto_rotate: true,
		globe: true,
		elevation: true,
		clouds: false,
		oceans: false,
		atmosphere: false,
		grid: false,
		orbits: false,
		satellite: false,
		user_location: true,
		ambientLight: false,
		sun: false,
		scene: null,
		camera: null,
		controls: null,
	};

	const container = document.getElementById('threejsglobe');
	if (container) {
		const canvas = container.getElementsByTagName('canvas')[0] as HTMLCanvasElement;
		if (canvas && hasWebGL(canvas)) {
			makeMagic(container, canvas, globePoints);
		}
	}

	function hasWebGL(canvas: HTMLCanvasElement) {
		const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
		if (gl && gl instanceof WebGLRenderingContext) {
			return true;
		} else {
			return false;
		}
	}

	function calcPosFromLatLonRad(lat: number, lon: number) {
		// http://jsfiddle.net/dvtm639m/38/
		const phi = (90 - lat) * (Math.PI / 180);
		const theta = (lon + 180) * (Math.PI / 180);
		const x = -(globe.radius * Math.sin(phi) * Math.cos(theta));
		const z = globe.radius * Math.sin(phi) * Math.sin(theta);
		const y = globe.radius * Math.cos(phi);
		return { x, y, z };
	}

	function makeMagic(container: HTMLElement, canvas: HTMLCanvasElement, points: any) {
		const { width, height } = container.getBoundingClientRect();

		// setup scene, camera and renderer
		const scene = new THREE.Scene();
		const camera = new THREE.PerspectiveCamera(45, width / height, 1, 10000);
		const renderer = new THREE.WebGLRenderer({
			canvas,
			context: canvas.getContext('webgl') as WebGLRenderingContext,
			alpha: true,
		});
		renderer.setSize(width, height);

		// make it responsive
		window.addEventListener('resize', function () {
			var { width, height } = container.getBoundingClientRect();
			renderer.setSize(width, height);
			camera.aspect = width / height;
			camera.updateProjectionMatrix();
		});

		// add Globe
		if (globe.globe) {
			addPoints(scene, points);
		}

		// add Sun
		if (globe.sun) {
			addSun(scene);
		}

		//add anbientLight
		if (globe.ambientLight) {
			addAmbientLight(scene);
		}

		// add grid
		if (globe.grid) {
			const size = 4 * globe.radius;
			const divisions = 20;
			const colorCenterLine = '#198754';
			const colorGrid = '#20c997';
			const gridHelper = new THREE.GridHelper(size, divisions, colorCenterLine, colorGrid);
			gridHelper.name = 'grid';
			scene.add(gridHelper);
		}

		// add clouds
		if (globe.clouds) {
			addClouds(scene);
		}

		// add clouds
		if (globe.oceans) {
			addOceans(scene);
		}

		// add atmosphere
		if (globe.atmosphere) {
			addAtmosphere(scene);
		}

		// add orbits
		if (globe.orbits) {
			addOrbits(scene);
		}

		// add satellite
		if (globe.satellite) {
			addSatellite(scene);
		}

		// add location
		if (globe.user_location) {
			addLocation(scene);
		}

		//store as global variable
		globe.scene = scene;
		globe.camera = camera;

		//add class for style editing
		container.classList.add('opathreejs');

		// Setup orbital controls
		const controls = new OrbitControls(camera, canvas);
		controls.enablePan = false;
		controls.enableZoom = false;
		controls.zoomSpeed = 0.1;
		controls.enableDamping = true;
		controls.dampingFactor = 0.2;
		controls.enableRotate = true;
		controls.rotateSpeed = 0.02;
		controls.autoRotate = false;
		controls.autoRotateSpeed = 0.06; //default is 2.0
		controls.zoom0 = 0.5;

		// initial rotation
		var screenWidth = document.documentElement.clientWidth;
		if (screenWidth > 767) {
			camera.position.set(152, 126, -10);
		} else {
			camera.position.set(152, 126, -70);
		}
		camera.zoom = 0.7;
		camera.updateProjectionMatrix();

		controls.update();
		globe.controls = controls;

		let iframe = 0;
		function animate() {
			// orbitControls.autoRotate is enabled so orbitControls.update
			// must be called inside animation loop.

			if (globe.auto_rotate) {
				//rotate globe
				const globe = scene.getObjectByName('globe');
				if (globe) {
					globe.rotation.y += 0.0005;
				}
				//rotate clouds
				const clouds = scene.getObjectByName('clouds');
				if (clouds) {
					clouds.rotation.y += 0.0005;
				}
				//rotate oceans
				const oceans = scene.getObjectByName('oceans');
				if (oceans) {
					oceans.rotation.y += 0.0005;
				}
				// rotate start_location
				const startLocation = scene.getObjectByName('startLocation');
				if (startLocation) {
					startLocation.rotation.y += 0.0005;
				}
				// rotate end_location
				const endLocation = scene.getObjectByName('endLocation');
				if (endLocation) {
					endLocation.rotation.y += 0.0005;
				}
				// user_link
				const userLink = scene.getObjectByName('userLink');
				if (userLink) {
					userLink.rotation.y += 0.0005;
				}
				// rotate orbits
				const orbit1 = scene.getObjectByName('orbit1');
				if (orbit1) {
					orbit1.rotation.y += 0.0005;
					orbit1.rotation.z += 0.0005;
				}
				const orbit2 = scene.getObjectByName('orbit2');
				if (orbit2) {
					orbit2.rotation.y += 0.0005;
					orbit2.rotation.z += 0.0005;
				}
			}

			// animate userLink
			const userLink = scene.getObjectByName('userLink');
			if (userLink) {
				// Check if the dash is out to stop animate it.
				if ((userLink as any).material.dashOffset < -2) {
					(userLink as any).material.dashOffset = 0;
				}
				// Decrement the dashOffset value to animate the path with the dash.
				(userLink as any).material.dashOffset -= 0.05;
			}

			// rotate satellite
			const rotSat = 400;
			const iniRotSat = Math.PI / 2;
			// rotate satellite around earth
			const satellite = scene.getObjectByName('satellite');
			if (satellite) {
				satellite.position.x = 150 * Math.cos(-iframe / rotSat);
				satellite.position.y = 50 * Math.cos(iframe / rotSat);
				satellite.position.z = 150 * Math.sin(-iframe / rotSat);
				//self rotate satellite
				satellite.rotation.y = iniRotSat + iframe / rotSat;
			}

			//rotate clouds
			const clouds = scene.getObjectByName('clouds');
			if (clouds) {
				clouds.rotation.y += 0.0005;
			}

			// initial zoom
			if (camera.zoom <= 1) {
				camera.zoom += 0.01;
				camera.updateProjectionMatrix();
			}

			// update
			controls.update();
			requestAnimationFrame(animate);
			renderer.render(scene, camera);

			iframe++;
		}
		animate();
	}

	function addPoints(scene: THREE.Scene, points: any): void {
		const pointMaterial = new THREE.MeshBasicMaterial({
			color: '#888',
		});
		// work with merged geometry for better performance
		const pointGeometries = [];
		for (const point of points) {
			const { x, y, z } = calcPosFromLatLonRad(point.lat, point.lon);
			if (x && y && z) {
				// project on sphere
				const pointGeometry = new THREE.SphereGeometry(0.3, 5, 5);
				if (globe.elevation) {
					// converts elevation (point.z) to scale factor
					var offset = point.elevation / 4000;
				} else {
					var offset = 0;
				}
				pointGeometry.translate(x + offset, y + offset, z + offset);
				pointGeometries.push(pointGeometry);
			}
		}

		// merge geometries
		const mergedGeometry = BufferGeometryUtils.mergeGeometries(pointGeometries);
		const globeShape = new THREE.Mesh(mergedGeometry, pointMaterial);
		globeShape.name = 'globe';
		scene.add(globeShape);
	}

	function addClouds(scene: THREE.Scene) {
		const geometry = new THREE.SphereGeometry(globe.radius, globe.width, globe.height);
		const texture = new THREE.TextureLoader().load('img/earth_clouds_1024.png');
		const material = new THREE.MeshBasicMaterial({
			map: texture,
			transparent: true,
			opacity: 0.25,
		});
		const meshClouds = new THREE.Mesh(geometry, material);
		meshClouds.scale.set(1.015, 1.015, 1.015);
		meshClouds.name = 'clouds';
		scene.add(meshClouds);
	}

	function addOceans(scene: THREE.Scene) {
		const waterGeometry = new THREE.SphereGeometry(globe.radius, globe.width, globe.height);
		const water = new Water(waterGeometry, {
			textureWidth: 3200,
			textureHeight: 3200,
			waterNormals: new THREE.TextureLoader().load('img/textures/waternormals.jpg', function (texture) {
				texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
			}),
			sunDirection: new THREE.Vector3(),
			sunColor: 0xffffff,
			waterColor: 0x001e0f,
		});
		water.rotation.x = -Math.PI / 2;
		water.name = 'oceans';
		scene.add(water);
	}

	function addAtmosphere(scene: THREE.Scene) {
		var geometry = new THREE.SphereGeometry(globe.radius, globe.width, globe.height);
		const material = new THREE.MeshBasicMaterial({
			color: '#e9ecef',
			transparent: true,
			opacity: 0.05,
			depthTest: false,
		});
		const meshAtmo = new THREE.Mesh(geometry, material);
		meshAtmo.scale.set(1.01, 1.01, 1.01);
		meshAtmo.name = 'atmosphere';
		scene.add(meshAtmo);
	}

	function addSun(scene: THREE.Scene) {
		const bulbGeometry = new THREE.SphereGeometry(20, 16, 8);
		const bulbLight = new THREE.PointLight(0xffee88, 1, 1000, 2);
		const bulbMat = new THREE.MeshStandardMaterial({
			emissive: 0xffffee,
			emissiveIntensity: 1,
			color: 0x000000,
		});
		bulbLight.add(new THREE.Mesh(bulbGeometry, bulbMat));
		bulbLight.position.set(-400, 0, 400);
		bulbLight.castShadow = true;
		bulbLight.name = 'sun';
		scene.add(bulbLight);
	}

	function addAmbientLight(scene: THREE.Scene) {
		const ambientLight = new THREE.AmbientLight('#495057');
		ambientLight.intensity = 2;
		scene.add(ambientLight);
	}

	function addOrbits(scene: THREE.Scene) {
		const orbit1Geometry = new THREE.CircleGeometry(150, 64);
		const orbit1Material = new THREE.LineBasicMaterial({
			color: '#FF69B4', //pink
			linewidth: 1,
		});
		const orbit2Geometry = new THREE.CircleGeometry(120, 64);
		const orbit2Material = new THREE.LineBasicMaterial({
			color: '#007bff', //blue
			linewidth: 1,
		});
		const orbit1 = new THREE.Line(orbit1Geometry, orbit1Material);
		orbit1.name = 'orbit1';
		const orbit2 = new THREE.Line(orbit2Geometry, orbit2Material);
		orbit2.name = 'orbit2';

		//add some angle
		orbit1.rotation.x = (0 * Math.PI) / 180;
		orbit2.rotation.y = (45 * Math.PI) / 180;

		//add orbites to scene
		scene.add(orbit1);
		scene.add(orbit2);
	}

	function addSatellite(scene: THREE.Scene) {
		/*new OBJLoader().load(
            // resource URL
            'public/models/Satellite_v1_L3.123c24489c18-f4e2-42f2-abc7-9a6d4abbeae2/10477_Satellite_v1_L3.obj',
            // called when resource is loaded
            function ( object ) {
                object.scale.set(0.005, 0.005, 0.005);
                object.position.set(180, 20, 180)
                object.name = 'satellite'
                scene.add( object );
            }
        );*/

		new GLTFLoader().load(
			// resource URL
			'public/models/CALIPSO/scene.gltf',
			// called when resource is loaded
			function (gltf) {
				var object = gltf.scene;

				object.scale.set(0.0025, 0.0025, 0.0025);
				object.position.set(180, 20, 180);
				object.name = 'satellite';
				scene.add(object);
			}
		);
	}

	function addLocation(scene: THREE.Scene) {
		// get Location
		fetch('https://api.ipdata.co/?api-key=152d283d4ec5548c3757b899cf749086563f6ab3b682a9a2ce354ad8')
			.then((response) => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then((response) => {
				const METNo = { latitude: 59.9446, longitude: 10.72032 };
				drawLine([METNo, response], 0.5, '#007bff', 'userLink');
			})
			.catch((error) => {
				console.error('Fetch error:', error);
			});

		function showLocation(position: { latitude: number; longitude: number }, radius: number, color: string, name: string) {
			// show Location
			const pointGeometry = new THREE.SphereGeometry(radius, 5, 5);
			const pointMaterial = new THREE.MeshBasicMaterial({
				color,
			});

			const { x, y, z } = calcPosFromLatLonRad(position.latitude, position.longitude);

			if (x && y && z) {
				pointGeometry.translate(x, y, z);
				const userLocation = new THREE.Mesh(pointGeometry, pointMaterial);
				userLocation.name = name;
				scene.add(userLocation);
			}
		}

		function drawLine(coos: any, linewidth: number, color: string, name: string) {
			const posStart = calcPosFromLatLonRad(coos[0].latitude, coos[0].longitude);
			showLocation(coos[0], 0.5, color, 'startLocation');

			const posEnd = calcPosFromLatLonRad(coos[1].latitude, coos[1].longitude);
			showLocation(coos[1], 0.5, color, 'endLocation');

			// distance
			const l = calcDistance(coos[0], coos[1]);

			// different heights depending on the distance
			if (l <= 500) {
				var h = 0.5;
			} else if (l <= 2000) {
				var h = 0.55;
			} else if (l <= 4000) {
				var h = 0.6;
			} else if (l <= 5000) {
				var h = 0.7;
			} else {
				var h = 0.75;
			}

			// midpoint
			const xMid = h * (posStart.x + posEnd.x);
			const yMid = h * (posStart.y + posEnd.y);
			const zMid = h * (posStart.z + posEnd.z);

			// show midPoint
			/*const pointGeometry = new THREE.SphereGeometry(1, 5, 5);
            const pointMaterial = new THREE.MeshBasicMaterial({
                color
            });
            pointGeometry.translate(xMid, yMid, zMid)
            const midPoint = new THREE.Mesh(pointGeometry, pointMaterial);
            scene.add(midPoint);*/

			// add Line
			const curve = new THREE.QuadraticBezierCurve3(
				new THREE.Vector3(posStart.x, posStart.y, posStart.z),
				new THREE.Vector3(xMid, yMid, zMid),
				new THREE.Vector3(posEnd.x, posEnd.y, posEnd.z)
			);
			const points = curve.getPoints(50);

			// linematerial requires positions
			var positions = [];
			for (let point of points) {
				positions.push(point.x, point.y, point.z);
			}
			const geometry = new LineGeometry();
			geometry.setPositions(positions); // [ x1, y1, z1,  x2, y2, z2, ... ] format
			const material = new LineMaterial({
				color,
				linewidth: linewidth * 0.002,
				dashSize: 1,
				gapSize: 1,
				dashed: true,
			});
			const line = new Line2(geometry, material);

			line.computeLineDistances();
			line.scale.set(1, 1, 1);
			line.name = name;
			scene.add(line);

			function calcDistance(point0: { latitude: number; longitude: number }, point1: { latitude: number; longitude: number }) {
				const earthRadius = 6370; //km
				// coordinates in rad
				const lat0 = (point0.latitude * Math.PI) / 180;
				const lon0 = (point0.longitude * Math.PI) / 180;
				const lat1 = (point1.latitude * Math.PI) / 180;
				const lon1 = (point1.longitude * Math.PI) / 180;
				// flat surface distance
				const d = earthRadius * Math.sqrt(Math.pow(lat0 - lat1, 2) + Math.pow(lon0 - lon1, 2));
				// arc distance
				const l = earthRadius * Math.atan(d / earthRadius);
				return l;
			}
		}
	}
}
