import Highcharts from 'highcharts';
import Heatmap from 'highcharts/modules/heatmap';
import GroupedCategories from 'highcharts-grouped-categories/grouped-categories';
import Boost from 'highcharts/modules/boost';
if (typeof Highcharts === 'object') {
	Heatmap(Highcharts);
	GroupedCategories(Highcharts);
	Boost(Highcharts);
}

import { loadScat } from '../../../modules/overall';
import { updateGauges } from '../../../components/charts/gauges';
import * as utils from '../../../utils';

import { Aeroval } from '../../../types/global';
import { HMData, TrendsStats } from '../../../types/data';

interface ExtendedGlobals extends Aeroval {
	sound: boolean;
	hide: string[];
}
declare var aeroval: ExtendedGlobals;

export function plotHeatmap(data: HMData): void {
	var dictModVar = {} as any;
	//prepare grid with proper number of pixels
	var xGrid: string[] = [];
	var yGrid: string[] = [];

	const currentSubPage = window.location.pathname.split('pages/')[1].split('/')[0]
	for (let param in data) {
		var skipVariable = false;
		if (aeroval.menu[param]?.only_use_in && !aeroval.menu[param]?.only_use_in?.includes(currentSubPage)) {
			var skipVariable = true
		}
		if (skipVariable) continue;

		if (typeof data[param] != 'undefined') {
			for (let obs in data[param]) {
				if ((aeroval.obsType != 'All' && aeroval.observationTypes[aeroval.obsType].includes(obs)) || aeroval.obsType == 'All') {
					for (let layer in data[param][obs]) {
						yGrid.push(`${obs}:${param}_${layer}`);
						for (let mod in data[param][obs][layer]) {
							const modVar = Object.keys(data[param][obs][layer][mod])[0];
							dictModVar[`${param}:${mod}`] = modVar;
							xGrid.push(mod);
						}
					}
				}
			}
		}
	}

	//get unique elements
	var xGrid = xGrid.filter((item, i, ar) => ar.indexOf(item) === i);
	var yGrid = yGrid.filter((item, i, ar) => ar.indexOf(item) === i);

	var xGridRecord = xGrid;
	var yGridRecord = yGrid;

	//exclude some models
	var include_models: string[] = [];
	for (let model of xGridRecord) {
		if (!aeroval.excludeModels.includes(model)) {
			include_models.push(model);
		}
	}
	var xGridRecord = include_models;

	//exclude some variables
	var include_obs: string[] = [];
	for (let obs of yGridRecord) {
		if (!aeroval.excludeObs.includes(obs)) {
			include_obs.push(obs);
		}
	}
	var yGridRecord = include_obs;

	//remove hidden elements from yGridRecord
	for (let element of aeroval.hide) {
		//if the hidden element has some :, then remove single element
		if (element && element.indexOf(':') > -1) {
			const index = yGridRecord.indexOf(element);
			if (index > -1) {
				yGridRecord.splice(index, 1);
			}
		} else {
			//if not, remove if the string includes the element
			var filtered_obs = [];
			for (let obs of yGridRecord) {
				if (!obs.includes(element)) {
					filtered_obs.push(obs);
				}
			}
			yGridRecord = filtered_obs;
		}
	}

	//remove hidden elements from xGridRecord
	for (let j in aeroval.hide) {
		var element = aeroval.hide[j];
		//if not, remove if the string includes the element
		var filtered_mods = [];
		for (let i in xGridRecord) {
			var mod = xGridRecord[i] + '@';
			if (!mod.includes(element + '@')) {
				filtered_mods.push(xGridRecord[i]);
			}
		}
		xGridRecord = filtered_mods;
	}

	//time selection
	const keyTime = utils.getKeyTime(aeroval.time, aeroval.season);

	const seriesData = [];
	for (let i: number = 0; i < xGridRecord.length; i++) {
		for (let j: number = 0; j < yGridRecord.length; j++) {
			//from yGridRecord, separate var from obs from layer
			const obs = yGridRecord[j].split(':');
			const net = obs[0];
			const par = obs[1].split('_')[0];
			const lay = obs[1].split('_')[1];
			const mod = xGridRecord[i];
			const modVar = dictModVar[`${par}:${mod}`];

			if (!modVar) continue;

			var value: number | null = null;
			const subData = data?.[par]?.[net]?.[lay]?.[mod]?.[modVar]?.[aeroval.region]?.[keyTime];
			if (subData) {
				if (aeroval.statistic.dir === 'nrms') {
					value = (subData['rms'] as number) / (subData['refdata_mean'] as number);
				} else if (subData[aeroval.statistic.dir]) {
					if (typeof subData[aeroval.statistic.dir] !== 'object') {
						value = subData[aeroval.statistic.dir] as number;
					} else {
						const trendStatistic = subData[aeroval.statistic.dir] as TrendsStats;
						const varMap = trendStatistic?.['map_var'] as string;
						if (varMap) {
							value = trendStatistic[varMap] as number;
						}
					}
				}

				// stats as percentages
				if (aeroval.statistic.unit == '%' && value != null) {
					value *= 100;
				}

				// fix decimal numbers
				if (value != null) {
					value = parseFloat(value.toFixed(aeroval.statistic.decimals));
				}
			} else {
				value = null
			}

			var pix = {
				x: i,
				y: j,
				value: value,
				name: `${mod}, ${modVar}`,
				lay: lay,
				obs: net,
				var: par,
				mod: mod,
				modVar: modVar,
			};
			seriesData.push(pix);
		}
	}

	//unit
	const statUnit = aeroval.statistic.unit == '1' ? '' : `(${aeroval.statistic.unit})`;

	//prepare colorbar with n stops
	if (aeroval.statistic.scale != null && aeroval.statistic.unit != 'var') {
		const stops = [];
		const nStops = 4;
		const min = aeroval.statistic.scale[0];
		const max = aeroval.statistic.scale[aeroval.statistic.scale.length - 1];

		for (let i: number = 0; i < nStops + 1; i++) {
			value = min + (i * (max - min)) / nStops;
			stops.push([i / nStops, utils.colStat(value, aeroval.statistic.scale)]);
		}

		//colorAxis
		var colorAxis = {
			stops,
			min,
			max,
			startOnTick: true,
			endOnTick: true,
			reversed: false,
		} as any;
		var legendTitle = `${aeroval.statistic.name} ${statUnit}`;
	} else {
		var colorAxis = {
			min: 0,
			max: 99,
			minColor: '#FFFFFF',
			maxColor: '#FFFFFF',
			visible: false,
		} as any;
		var legendTitle = '';
	}

	//height of the chart: longer if more lines
	const heightRow = 40;
	var height = heightRow * yGridRecord.length;

	/*minimum height: 400px*/
	const minHeight = 400;
	if (height < minHeight) {
		height = minHeight;
	}

	const maxHeight = 1000;
	if (height >= maxHeight) {
		height = maxHeight;
	}

	// prepare categories with variable in bold font
	var groups = [];
	var ids = [];
	const yCategories = [];
	for (let obs of yGridRecord) {
		const obs1 = obs.split(':')[1];
		const group = obs1.split('_')[0];
		groups.push(group);
		ids.push(obs1);
	}
	groups = groups.filter((x, i, a) => a.indexOf(x) == i);
	ids = ids.filter((x, i, a) => a.indexOf(x) == i);

	//initialize
	for (let group of groups) {
		yCategories.push({
			name: `<b class='bold ${group}' style='margin-right: 10px'>${aeroval.menu[group].name}</b>`,
			categories: [] as string[],
		});
	}

	for (let obs of yGridRecord) {
		const obs0 = obs.split(':')[0];
		const obs1 = obs.split(':')[1];
		const group = obs1.split('_')[0];
		const lay = obs1.split('_')[1];
		const cat = (lay === 'Surface' || lay === 'Column') ? obs0 : `${obs0}_${lay}`;
		const idx = groups.indexOf(group);
		yCategories[idx]['categories'].push(`<tspan class='${cat}:${obs1}'>${cat}</tspan>`);
	}

	// some parameters
	const marginLeft: number | undefined = aeroval.isMobile ? undefined : undefined;
	const marginRight: number | undefined = aeroval.isMobile ? undefined : undefined;
	const spacingLeft = aeroval.isMobile ? 0 : 0;
	const spacingRight = aeroval.isMobile ? 0 : 0;
	const layout: Highcharts.OptionsLayoutValue = aeroval.isMobile ? 'horizontal' : 'vertical';
	const verticalAlign: Highcharts.VerticalAlignValue = aeroval.isMobile ? 'bottom' : 'middle';
	const align: Highcharts.AlignValue = aeroval.isMobile ? 'center' : 'right';
	const y = aeroval.isMobile ? 20 : -50;
	const enabledDataLabels = true;
	const allowOverlap = true;

	// tooltips and title
	const tooltip = (aeroval.sound || aeroval.settings.presentationMode === 'true') ? false : true;
	const title = aeroval.season != 'All' ? `${aeroval.region} - ${aeroval.time} (${aeroval.season})` : `${aeroval.region} - ${aeroval.time}`;

	const series = [
		{
			name: `${aeroval.statistic.name}-${aeroval.region}`,
			borderWidth: 1,
			borderColor: '#cccccc',
			data: seriesData,
			dataLabels: {
				enabled: enabledDataLabels,
				color: '#000000',
				allowOverlap,
			},
		},
	] as any;

	// prepare matrice with nl and nc
	var chart = Highcharts.chart({
		chart: {
			renderTo: 'heatmap',
			type: 'heatmap',
			marginTop: 60,
			marginBottom: 120,
			marginLeft,
			marginRight,
			spacingLeft,
			spacingRight,
			plotBorderWidth: 1,
			animation: {
				duration: 0,
			},
			height,
			backgroundColor: 'transparent',
			events: {
				fullscreenOpen: function () {
					// fullscreen background color
					var backgroundColor = 'white';
					if (aeroval.settings.theme == 'dark') {
						backgroundColor = '#282b34';
					}
					(this as any).update({
						chart: {
							backgroundColor,
						},
					});
				},
				fullscreenClose: function () {
					(this as any).update({
						chart: {
							backgroundColor: 'transparent',
						},
					});
				},
			},
		},
		credits: {
			enabled: false,
		},
		boost: {
			allowForce: true,
			enabled: true,
		},
		title: {
			text: title,
			x: 50,
		},
		subtitle: {
			text: aeroval.frequency + ' data',
			x: 50,
		},
		xAxis: {
			categories: xGridRecord,
			labels: {
				useHTML: true,
			},
		},
		yAxis: {
			categories: yCategories,
			title: null,
			tickWidth: 0.5,
			reversed: true,
		} as any, // this axis is modified by the grouped categories module
		colorAxis,
		legend: {
			align,
			layout,
			margin: 0,
			verticalAlign,
			width: '100px',
			y: y,
			title: {
				text: legendTitle,
			},
			//symbolHeight: "100%"
		},
		exporting: {
			enabled: true,
			allowHTML: true,
			chartOptions: {
				yAxis: [{
					categories: yCategories,
					tickWidth: 0.,
					reversed: true,
					labels: {
						useHTML: true,
						style: {
							useHTML: true,
							fontSize: "16px",
						}
					}
				} as any]
			}
		},
		/*
		exporting: {
			scale: 2,
			sourceHeight: 650,
			sourceWidth: 900,
			allowHTML: true,
			chartOptions: {
				chart: {
					marginBottom: 150
				},
				title: {
					style: {
						fontSize: "20px"
					}
				},
				xAxis: [{
					categories: xGridRecord,
					labels: {
						//useHTML: true,
						style: {
							fontSize: "16px",
						},
						//y: 20,
						format: "<span style='display:inline-block; -webkit-transform: rotate(-45deg); -moz-transform: rotate(-45deg); -ms-transform: rotate(-45deg); -o-transform: rotate(-45deg); filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);'>{value}</span>",
					}
				}],
				yAxis: [{
					useHTML: true,
					categories: categories,
					title: null,
					tickWidth: 0.5,
					reversed: true,
					labels: {
						useHTML: true,
						style: {
							useHTML: true,
							fontSize: "16px",
						}
					}
				}],
				series: [{
					name: aeroval.statistic.name + "-" + aeroval.region,
					borderWidth: 1,
					borderColor: "#cccccc",
					data: hm,
					dataLabels: {
						enabled: true,
						style: {
							color: "#000000",
							fontSize: fs + "px"
						}
					}
				}]
			}
		},
		*/
		tooltip: {
			useHTML: true,
			enabled: tooltip,
			animation: false,
			formatter: function () {
				// for some reason, the first row of the heatmap does not show the tooltip when testing if this.point.y exists
				if (!aeroval.sound) {
					var formatter = `Mod: <b>${this.point.name}</b> <br> Obs: <b>${this.series.yAxis.categories[(this as any).point.y]}</b> <br> ${aeroval.statistic.name
						}: <b>${this.point.value}${statUnit}</b>`;
					if (window.screen.width > 360 && !aeroval.sound) {
						formatter += `<div id='preview_scatterplot' style='height: 200px; width: 200px; border: 1px solid lightgray'></div><span style='font-size: smaller'>Click to expand the scatterplot.</span>`;
					}
					return formatter;
				}
			},
		},
		plotOptions: {
			series: {
				point: {
					events: {
						click: function () {
							if (aeroval.sound !== true) {
								const selectedPixel = this as any;
								// Set cursor to waiting state
								document.body.style.cursor = 'progress';

								// Reduce heatmap and enlarge scatterplot
								const heatmap = document.getElementById('heatmap');
								if (heatmap) {
									heatmap.classList.remove('col-md-12');
									heatmap.classList.add('col-md-7');
								}

								const scatterplot = document.getElementById('scatterplot');
								if (scatterplot instanceof HTMLElement) {
									scatterplot.classList.remove('col-md-0');
									scatterplot.classList.add('col-md-5');
									scatterplot.style.display = 'initial';
								}

								const heatmapSpan = document.getElementById('heatmap-span');
								if (heatmapSpan instanceof HTMLElement) {
									heatmapSpan.style.display = 'none';
								}

								chart.reflow();

								aeroval.statsForSelectedPixel = {
									n: data[selectedPixel.var][selectedPixel.obs][selectedPixel.lay][selectedPixel.mod][selectedPixel.modVar][aeroval.region][keyTime][
										'num_valid'
									] as number,
									nmb: data[selectedPixel.var][selectedPixel.obs][selectedPixel.lay][selectedPixel.mod][selectedPixel.modVar][aeroval.region][
										keyTime
									]['nmb'] as number,
									r: data[selectedPixel.var][selectedPixel.obs][selectedPixel.lay][selectedPixel.mod][selectedPixel.modVar][aeroval.region][keyTime][
										'R'
									] as number,
								};

								//update global variables
								loadScat(selectedPixel, false);

								// select this pixel
								this.select();

								//hide click on heatmap text
								const scatTxt = document.querySelector('#scatTxt');
								if (scatTxt instanceof HTMLElement) {
									scatTxt.style.display = 'none';
								}
							} else {
								//soundBox.playSound(eval(this.value))
							}
						},
						mouseOver: function () {
							const selectedPixel = this as any;
							if (window.screen.width > 360 && !aeroval.sound) {
								aeroval.wait = false;
								aeroval.timeOver = setTimeout(function () {
									if (!aeroval.wait && aeroval.settings.presentationMode !== 'true') {
										loadScat(selectedPixel, true);
									}
								}, 50);
								updateGauges(aeroval.config.gauges, data, selectedPixel);
							}
						},
						mouseOut: function () {
							aeroval.wait = true;
							if (typeof aeroval.timeOver != 'undefined') {
								clearTimeout(aeroval.timeOver);
							}
						},
					},
				},
				cursor: 'pointer',
			},
		},
		series,
	});

	/*rotates categories*/
	document.querySelectorAll('g.highcharts-axis-labels>text, .highcharts-axis-labels>span').forEach((element) => {
		(element as any).style.cursor = 'pointer';
	});

	/* Add events */
	document.querySelectorAll('g.highcharts-axis-labels>text, .highcharts-axis-labels>span').forEach((element) => {
		element.addEventListener('click', () => {
			// the first element needs a special handling
			// the y axis has a child element due to double axis
			if (typeof element.children[0] != 'undefined') {
				var key = element.children[0].innerHTML;
				var value = (element.children[0].className as any).baseVal.replace('bold ', '') as string;
			} else {
				var key = element.innerHTML;
				var value = key;
			}
			aeroval.hide.push(value);
			listHidden();
		});
	});

	/*if (aeroval.sound) {
		soundBox.data2partition()
	}*/
}

function listHidden(): void {
	if (aeroval.hide.length > 0) {
		var h = '<h6>Hidden Elements</h6>';
		for (let element of aeroval.hide) {
			h += `<div class='form-check form-check-inline'>
			<input class='form-check-input' type='checkbox' value='${element}' id='hide-${element}' checked>
			<label class='form-check-label' for='hide-"${element}'>${element}</label>
			</div>`;
		}
	} else {
		var h = '';
	}
	const hiddenElements = document.querySelector('#hideElements');
	if (hiddenElements instanceof HTMLElement) {
		hiddenElements.innerHTML = h;
	}

	// Get all elements with the class 'form-check-input'
	const checkboxes = document.querySelectorAll('input.form-check-input');
	checkboxes.forEach(function (checkbox) {
		checkbox.addEventListener('click', function (e) {
			const target = e.target as HTMLInputElement;
			const index = aeroval.hide.indexOf(target.value);
			if (index > -1) {
				aeroval.hide.splice(index, 1);
			}
			listHidden();
		});
	});

	//redraw heatmap
	if (aeroval.data.hm) {
		plotHeatmap(aeroval.data.hm);
	}
}
