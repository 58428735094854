import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import Boost from 'highcharts/modules/boost';
if (typeof Highcharts === 'object') {
	Exporting(Highcharts);
	ExportData(Highcharts);
	OfflineExporting(Highcharts);
	Boost(Highcharts);
}

import * as utils from '../../../utils';

import { Aeroval } from '../../../types/global';
import { ProfileData } from '../../../types/data';
declare var aeroval: Aeroval;

export function plotProfile(data: ProfileData): void {
	if (window.location.pathname.includes('evaluation')) {
		plotEvalProfile(data);
	} else if (window.location.pathname.includes('intercomp')) {
		plotInterProfile(data);
	} else {
		utils.todo();
	}
}

function plotEvalProfile(data: ProfileData): void {
	//by default, plot the first month
	var keySeason: string | Aeroval['season'] = aeroval.season;
	if (aeroval.season == 'All') {
		keySeason = 'all';
	}
	var obs = [];
	var mod = [];
	for (let i in data[aeroval.model.dir].z) {
		const test = data[aeroval.model.dir].obs;
		test;
		obs.push([data[aeroval.model.dir].z[i], data[aeroval.model.dir].obs[aeroval.frequency][`${aeroval.time}-${keySeason}`][i]]);
		mod.push([data[aeroval.model.dir].z[i], data[aeroval.model.dir].mod[aeroval.frequency][`${aeroval.time}-${keySeason}`][i]]);
	}

	var series = [] as any;
	//push daily if exists
	series.push(
		{
			name: `Observation (${aeroval.frequency})`,
			data: obs,
			color: 'rgba(128,128,128,0.9)',
			showInLegend: false,
		},
		{
			name: `Model (${aeroval.frequency})`,
			data: mod,
			color: 'rgba(255,153,51,0.9)',
			showInLegend: false,
		}
	);

	// set title
	const titleText = getTitle();

	Highcharts.chart({
		title: {
			text: titleText,
		},
		subtitle: {
			text: `${aeroval.observation} - ${aeroval.model?.name}`,
		},
		chart: {
			renderTo: 'profiles',
			type: aeroval.settings.lineStyle,
			inverted: true,
			zooming: {
				type: 'x',
			},
			resetZoomButton: {
				position: {
					align: 'center', // by default
					verticalAlign: 'top', // by default
				},
			},
			backgroundColor: 'transparent',
			events: {
				fullscreenOpen: function () {
					// fullscreen background color
					const fullScreenBackgroundColor = aeroval.settings.theme == 'dark' ? '#282b34' : 'white';
					(this as any).update({
						chart: {
							backgroundColor: fullScreenBackgroundColor,
						},
					});
				},
				fullscreenClose: function () {
					(this as any).update({
						chart: {
							backgroundColor: 'transparent',
						},
					});
				},
			},
		},
		tooltip: {
			shared: true,
			valueDecimals: 3,
			headerFormat: `${data[aeroval.model.dir].metadata.z_description} : <b>{point.x} km</b><br>`,
		},
		credits: {
			enabled: false,
		},
		xAxis: {
			reversed: false,
			title: {
				text: `${data[aeroval.model.dir].metadata.z_description} (${data[aeroval.model.dir].metadata.z_unit})`,
			},
			gridLineWidth: 0,
			plotBands: [
				{
					color: '#F5F5F5',
					from: parseFloat(aeroval.layer.split('-')[0]),
					to: parseFloat(aeroval.layer.split('-')[1].split('km')[0]),
				},
			],
		},
		yAxis: {
			title: {
				text: `${aeroval.parameter.name} (${data[aeroval.model.dir].metadata.unit})`,
			},
			//lineWidth: 1,
			gridLineWidth: 1,
		},
		legend: {
			layout: 'vertical',
			align: 'right',
			verticalAlign: 'top',
			floating: true,
			y: 40,
		},
		series,
	});
}

function plotInterProfile(data: ProfileData) {
	var series = [] as any;
	var j = 0;
	for (let model in data) {
		console.log(model)
		//set model visibility
		var visible = true;
		if (typeof aeroval.seriesVisibility[model] != 'undefined') {
			visible = aeroval.seriesVisibility[model];
		}

		// defaults
		var color = utils.highchartsPalette(j);
		var lineWidth = 1.25;
		var markerSize = 2;

		//stylish different models according to predefinition
		const modelStyle = utils.getModelStyle(model);

		if (modelStyle?.color) {
			color = modelStyle.color;
		}
		if (modelStyle?.weight) {
			lineWidth = modelStyle.weight * 1.25;
			markerSize = modelStyle.weight * 2
		}

		var keySeason: string | Aeroval['season'] = aeroval.season;
		if (aeroval.season == 'All') {
			keySeason = 'all';
		}

		var obs = [];
		var mod = [];
		for (let i in data[model].z) {
			obs.push([data[model].z[i], data[model].obs[aeroval.frequency][`${aeroval.time}-${keySeason}`][i]]);
			mod.push([data[model].z[i], data[model].mod[aeroval.frequency][`${aeroval.time}-${keySeason}`][i]]);
		}

		//push observations
		if (j == 0) {
			series.push({
				name: `Observation (${aeroval.frequency})`,
				data: obs,
				color: 'rgba(128,128,128,0.9)',
				showInLegend: true,
				label: {
					enabled: false,
				},
			});
		}
		series.push({
			name: `${model}`,
			data: mod,
			color,
			showInLegend: true,
			visible,
			marker: {
				enabledThreshold: 1,
				radius: markerSize,
				symbol: 'circle',
			},
			lineWidth: lineWidth,
			label: {
				enabled: false,
			},
		});

		j++;
	}

	// set title
	const titleText = getTitle();

	Highcharts.chart({
		title: {
			text: titleText,
		},
		subtitle: {
			text: `${aeroval.observation} - intercomparison`,
		},
		chart: {
			renderTo: 'profiles',
			type: aeroval.settings.lineStyle,
			inverted: true,
			zooming: {
				type: 'x',
			},
			resetZoomButton: {
				position: {
					align: 'center', // by default
					verticalAlign: 'top', // by default
				},
			},
			backgroundColor: 'transparent',
			events: {
				fullscreenOpen: function () {
					// fullscreen background color
					const fullScreenBackgroundColor = aeroval.settings.theme == 'dark' ? '#282b34' : 'white';
					(this as any).update({
						chart: {
							backgroundColor: fullScreenBackgroundColor,
						},
					});
				},
				fullscreenClose: function () {
					(this as any).update({
						chart: {
							backgroundColor: 'transparent',
						},
					});
				},
			},
		},
		tooltip: {
			shared: true,
			valueDecimals: 3,
			headerFormat: `${data[aeroval.model.dir].metadata.z_description} : <b>{point.x} km</b><br>`,
		},
		credits: {
			enabled: false,
		},
		xAxis: {
			reversed: false,
			title: {
				text: `${data[aeroval.model.dir].metadata.z_description} (${data[aeroval.model.dir].metadata.z_unit})`,
			},
			gridLineWidth: 0,
			plotBands: [
				{
					color: '#F5F5F5',
					from: parseFloat(aeroval.layer.split('-')[0]),
					to: parseFloat(aeroval.layer.split('-')[1].split('km')[0]),
				},
			],
		},
		yAxis: {
			title: {
				text: `${aeroval.parameter.name} (${data[aeroval.model.dir].metadata.unit})`,
			},
			gridLineWidth: 1,
		},
		legend: {
			layout: 'vertical',
			align: 'right',
			verticalAlign: 'top',
			floating: true,
			y: 40,
		},
		series,
	});
}

function getTitle() {
	var title = `${aeroval.parameter.name} - ${aeroval.station} - ${aeroval.time}`;
	title += ` (${aeroval.season})`;
	return title;
}
