/*import interfaces*/
import { Aeroval } from '../../types/global';
declare var aeroval: Aeroval;

import { globeJS } from '../../components/globe';

import aerovalAnimation from '../../assets/images/logo/full_logo_animated.svg';
import aerovalAnimationWhite from '../../assets/images/logo/full_logo_animated-white.svg';

export function toggleAnimation(value: string): void {
    aeroval.settings.animation = value;
}

export function showAnimation(theme: Aeroval['theme'], delay: number, fade: number): void {

    if (window.screen.width <= 360) {
        var height = 50;
    } else {
        height = 100;
    }

    var h = `<div class="row d-flex justify-content-center align-items-center h-100 w-100" style="z-index: 10; position: absolute; margin: 0;">`;
    if (theme === 'dark') {
        h += `<object type="image/svg+xml" data=${aerovalAnimationWhite} style="height: ${height}px; display: block; margin: auto; transform: translateY(-60px);">`;
        h += `<img src="${aerovalAnimationWhite}"/>`;
    } else {
        h += `<object type="image/svg+xml" data=${aerovalAnimation} style="height: ${height}px; display: block; margin: auto; transform: translateY(-60px);">`;
        h += `<img src="${aerovalAnimation}"/>`;
    }
    h += '</object>';
    h += '</div>';

    const animationElement = document.querySelector('#animation');
    if (animationElement instanceof HTMLElement) {
        animationElement.innerHTML = h;

        setTimeout(function () {
            animationElement.classList.add('fadeOut');
        }, delay - fade);

        setTimeout(function () {
            globeJS();
        }, delay - 100);

        setTimeout(function () {
            const contentMainIndexElement = document.querySelector('#index');
            if (contentMainIndexElement instanceof HTMLElement) {
                contentMainIndexElement.setAttribute('style', 'min-height: calc(100% - 250px); visibility: visible');
            }

            animationElement.classList.remove('d-flex', 'justify-content-center', 'align-items-center');
            animationElement.style.display = 'none';
        }, delay + fade);
    }
}