import aerovalLogo from '../../assets/images/logo/logo.svg';
import camsLogo from '../../assets/images/cams.svg';
import aerocomLogo from '../../assets/images/logo-aerocom-22.webp';
import metnoLogo from '../../assets/images/logo-metno-22.webp';

import { GAInit } from '../analytics';

import './footer.css';

export function renderFooter(): HTMLElement {
	const footer = document.createElement('footer');
	footer.innerHTML = `
        <div id="footer-top">
          <img id="footer-logo" class="rounded mx-auto d-block simple-logo" src='${aerovalLogo}' style="height: 40px; width: 40px;" alt="logo_aeroval">
          <p style="text-align: center; font-size: 11px;">Evaluation of climate and air quality models data processed with pyaerocom</p>
        </div>
        <div id="footer-bottom">
          <div id="footer-logos">
            <div id="cams" class="logo"><a href="https://atmosphere.copernicus.eu/"><img class="img_logo" src='${camsLogo}' style="height: 22px; width: 86px" alt="logo_cams"></a></div>
            <div id="aerocom" class="logo"><a href="https://aerocom.met.no"><img class="img_logo" src='${aerocomLogo}' style="height: 22px; width: 45px" alt="logo_aerocom"></a></div>
            <div id="metno" class="logo"><a href="https://www.met.no/en"><img class="img_logo" src='${metnoLogo}' style="height: 22px; width: 76px" alt="logo_metno"></a></div>
          </div>
        </div>
      <div id="external-footer"></div>
    `;
	return footer;
}

document.addEventListener('DOMContentLoaded', function () {
	hideFooterInAerocom();
	applyCAMSTheme();
	GAInit();
});

function applyCAMSTheme() {
	if (window.location.host.includes('cams') || window.location.host.includes('atmosphere.copernicus')) {
		const externalHeader = document.getElementById('external-footer');
		if (externalHeader instanceof HTMLElement) {
			const xhr = new XMLHttpRequest();
			xhr.open('GET', '/custom-theme/cams/footer.html');
			xhr.onreadystatechange = function () {
				if (xhr.readyState === XMLHttpRequest.DONE) {
					if (xhr.status === 200) {
						externalHeader.innerHTML = xhr.responseText;
					} else {
						console.error('Failed to load external footer:', xhr.status);
					}
				}
			};
			xhr.send();
		}
	}
}

function hideFooterInAerocom() {
	if (window.location.host === 'aerocom.met.no') {
		const footer = document.getElementById('footer');
		if (footer) {
			footer.style.display = 'none';
		}
	}
}
