import Highcharts from '../../node_modules/highcharts/es-modules/masters/highcharts.src.js';

import * as main from './main';
import { updateAxisType, updateQueryString, highlightSeason } from '../utils';
import { showStation } from '../components/charts/map';

import { plotTimeSeriesMultiModel, addTrendMultiModel, cleanTrendMultiModel } from '../components/charts/time-series-multi-model';

/*import interfaces and extend window variable*/
import { Aeroval } from '../types/global';
declare var aeroval: Aeroval;

document.addEventListener('DOMContentLoaded', () => {
	if (window.location.pathname.includes('pages/intercomp')) {
		init();
	}
});

function init(): void {
	console.log('initialize intercomparison page');
	// set some defaults
	aeroval.goto = false;
	aeroval.tsType = 'regular'; //diurnal cycle
	aeroval.seriesVisibility = {};
	aeroval.menuButtons = ['parameter', 'observation', 'model', 'time', 'statistic'];
	aeroval.axisType = 'linear';

	// check if any queries
	main.getQueryStrings();

	// experiments
	main.getExperiments();

	// update header links with qs project
	main.updateURLs('project', aeroval.project);

	// scattering axis toggle
	const scatAxisToggle = document.getElementById('scatAxisToggle') as HTMLInputElement;
	if (scatAxisToggle) {
		scatAxisToggle.addEventListener('change', function () {
			// If switch off
			if (this.checked) {
				aeroval.axisType = 'logarithmic';
			} else {
				aeroval.axisType = 'linear';
			}
			updateAxisType();
		});
	}

	// apply other options
	Highcharts.setOptions({
		accessibility: {
			enabled: false,
		},
	});
}

export function callbackGlobStat(update_ts = true, update_scat = true) {
	document.querySelector('#map')?.classList.remove('loading');
	const iniLoader = document.querySelector('#iniLoader');
	if (iniLoader instanceof HTMLElement) {
		iniLoader.style.display = 'none';
	}
	main.ts(update_ts, update_scat);
}

export function callbackMenu(reg_stat = true, update_ts = true, update_scat = true) {
	main.map(reg_stat, update_ts, update_scat);
}

export function update(which: string, what: any, wait: boolean = false): void {
	console.info('update ', which, 'with ', what);
	if (which == 'station') {
		//for MODIS, station are tuples, and seen hre as array
		if (typeof what != 'string') {
			aeroval.station = `(${what[0].toFixed(1)}, ${what[1].toFixed(1)})`;
		} else {
			aeroval.station = what;
		}
		main.ts();
		showStation(aeroval.station);
		main.updateURLs(which, what);
	}
	if (which == 'parameter') {
		aeroval.parameter.dir = what;
		main.topMenu();
		updateQueryString(which, what);
		main.updateURLs(which, what);
	}
	if (which == 'experiment') {
		aeroval.experiment = aeroval.experiments[what];
		aeroval.experiment.name = what;
		main.getExpSettings();
		updateQueryString(which, what);
		main.updateURLs(which, what);
	}
	if (which == 'model') {
		aeroval.model.dir = what;
		//get modVar
		aeroval.model.var = aeroval.modVars[what];
		main.map(true, true);
		main.showMenu(which, what, what);
		updateQueryString(which, what);
		main.updateURLs(which, what);
	}
	if (which == 'observation') {
		aeroval.observation = what;
		main.map(true, true);
		main.showMenu(which, what, what);
		updateQueryString(which, what);
		main.updateURLs(which, what);
	}
	if (which == 'statistic') {
		aeroval.statistic = aeroval.statistics[what];
		aeroval.statistic.dir = what;
		main.map(true, false, false);
		if (!aeroval.statistic.dir.includes('trend')) {
			cleanTrendMultiModel('intercomp-ts');
		} else if (aeroval.data.ts) {
			plotTimeSeriesMultiModel(aeroval.data.ts, 'intercomp-ts', true);
		}
		main.showMenu(which, aeroval.statistic.name);
		updateQueryString(which, what);
		main.updateURLs(which, what);
	}
	if (which == 'layer') {
		updateQueryString('layer', what);
		if (!wait) {
			main.map(true, true);
			main.profile();
			main.showMenu(which, what);
		}
		updateQueryString(which, what);
	}
	if (which == 'frequency') {
		aeroval.frequency = what;
		main.checkSeasonMenu();
		main.topMenu();
		updateQueryString(which, what);
		main.updateURLs(which, what);
	}
	if (which == 'time') {
		aeroval.time = what;
		delete aeroval.dateMin;
		delete aeroval.dateMax;
		updateQueryString('time', what);
		main.map(true, false, false);
		main.showMenu(which, what);
		main.getExtremeDates(false, false);
		if (aeroval.data.ts) {
			plotTimeSeriesMultiModel(aeroval.data.ts, 'intercomp-ts', true);
		}
		main.updateURLs(which, what);
	}
	if (which == 'season') {
		aeroval.season = what;
		main.map(true, false, true);
		main.getExtremeDates(false, false);
		highlightSeason('intercomp-ts');
		if (aeroval.statistic.dir.includes('trend')) {
			addTrendMultiModel('intercomp-ts');
		}
		main.showMenu('time', aeroval.time);
		main.updateURLs(which, what);
	}
}
