import * as utils from '../../utils';
import { colormaps } from '../../../public/js-colormaps';

/*import interfaces*/
import { Aeroval } from '../../types/global';
declare var aeroval: Aeroval;

export function discreteColorscale(value: number, scale: number[]): string {
	//this export function works with discrete colorscale
	const nCol = scale.length - 1;
	var idCat = 0;
	for (let i = 0; i < scale.length - 1; i++) {
		if (value > scale[i] && value <= scale[i + 1]) {
			var idCat = i;
			break;
		}
	}
	//if value greater than upper limit
	if (value >= scale[scale.length - 1]) {
		idCat = scale.length - 2;
	}
	//if value lower than lower limit
	if (value <= scale[0]) {
		idCat = 0;
	}
	const val = idCat / (nCol - 1);

	// colorMap
	if (aeroval.statistic.colmap.startsWith('reverseColmap(')) {
		var colMapName = aeroval.statistic.colmap.split('(')[1].split(')')[0];
		var colMap = reverseColorscale(colormaps[colMapName] as Aeroval['colormap']);
	} else {
		colMapName = aeroval.statistic.colmap;
		colMap = colormaps[colMapName] as Aeroval['colormap'];
	}
	var color = utils.interpolateLinearly(val, colMap);
	//returns hex color
	return utils.rgbToHex(Math.round(color[0] * 255), Math.round(color[1] * 255), Math.round(color[2] * 255));
}

export function continuousColorscale(value: number, scale: number[]): string {
	const nCol = scale.length;
	var idCat = 0;

	for (let i: number = 0; i < scale.length; i++) {
		if (value > scale[i] && value <= scale[i + 1]) {
			var idCat = i;
			break;
		}
	}
	//if value greater than upper limit
	if (value >= scale[scale.length - 1]) {
		var idCat = scale.length - 2;
	}
	//if value lower than lower limit
	if (value <= scale[0]) {
		var idCat = 0;
	}
	var val = (1 + idCat) / (nCol - 1);

	// colorMap
	if (aeroval.statistic.colmap.startsWith('reverseColmap(')) {
		var colMapName = aeroval.statistic.colmap.split('(')[1].split(')')[0];
		var colMap = reverseColorscale(colormaps[colMapName] as Aeroval['colormap']);
	} else {
		colMapName = aeroval.statistic.colmap;
		colMap = colormaps[colMapName] as Aeroval['colormap'];
	}
	var color = utils.interpolateLinearly(val, colMap);

	//returns hex color
	return utils.rgbToHex(Math.round(color[0] * 255), Math.round(color[1] * 255), Math.round(color[2] * 255));
}

export function reverseColorscale(colorscale: Aeroval['colormap']): Aeroval['colormap'] {
	const reversedColorscale: Aeroval['colormap'] = [];
	for (let i = colorscale.length - 1; i >= 0; i--) {
		let rgb = colorscale[i][1];
		let x = 1 - i / colorscale.length;
		reversedColorscale.push([x, [rgb[0], rgb[1], rgb[2]]]);
	}
	return reversedColorscale;
}
