import aerovalFullLogo from '../../assets/images/logo/full_logo.svg';
import aerovalFullLogoWhite from '../../assets/images/logo/full_logo-white.svg';
import aerovalLogo from '../../assets/images/logo/logo.svg';
import aerovalLogoWhite from '../../assets/images/logo/logo-white.svg';

/*import interfaces*/
import { Aeroval } from '../../types/global';
declare var aeroval: Aeroval;

export function toggleTheme(value: string): void {
	if (value == 'dark') {
		enableDarkTheme();
	} else {
		disableDarkTheme();
	}
	aeroval.settings.theme = value;
}

function enableDarkTheme(): void {
	// logos
	document.querySelectorAll('.full-logo').forEach((element) => {
		(element as HTMLImageElement).src = aerovalFullLogoWhite;
	});
	document.querySelectorAll('.simple-logo').forEach((element) => {
		(element as HTMLImageElement).src = aerovalLogoWhite;
	});

	// classes
	document.querySelectorAll('.bg-light').forEach((element) => {
		element.classList.remove('bg-light');
		element.classList.add('bg-dark');
	});

	document.querySelectorAll('.btn-light').forEach((element) => {
		element.classList.remove('btn-light');
		element.classList.add('btn-dark');
	});

	document.documentElement.setAttribute('data-bs-theme', 'dark');
}

function disableDarkTheme(): void {
	// logos
	document.querySelectorAll('.full-logo').forEach((element) => {
		(element as HTMLImageElement).src = aerovalFullLogo;
	});
	document.querySelectorAll('.simple-logo').forEach((element) => {
		(element as HTMLImageElement).src = aerovalLogo;
	});

	// classes
	document.querySelectorAll('.bg-dark').forEach((element) => {
		element.classList.remove('bg-dark');
		element.classList.add('bg-light');
	});

	document.querySelectorAll('.btn-dark').forEach((element) => {
		element.classList.remove('btn-dark');
		element.classList.add('btn-light');
	});
	document.documentElement.setAttribute('data-bs-theme', 'light');
}
