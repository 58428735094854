import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

// Add the imported icons to the library
library.add(faFilePdf);

const filePdf = icon({ prefix: 'fas', iconName: 'file-pdf' });

import Highcharts from 'highcharts';

import * as main from './main';
import * as utils from '../utils';

import { plotTimeSeriesStatistics } from '../components/charts/time-series-statistics';

import camsFrontpageHeader from '../../public/reports/cams-template/CAMS-frontpage-header.png'
import camsFrontpageFooter from '../../public/reports/cams-template/CAMS-frontpage-footer.png'
import camsHeader from '../../public/reports/cams-template/CAMS-header.png'
import aerovalLogo from '../assets/images/logo/logo.png'

declare var htmlToPdfmake: any;
declare var pdfMake: any;
declare var MathJax: any;

/*import interfaces and extend window variable*/
import { Aeroval } from '../types/global';
// Extend the Globals interface
interface ExtendedGlobals extends Aeroval {
	report: any;
	stats_report: {
		nTables: number;
		nFigures: number;
		nSection: number;
		nSectionFigures: number;
		nSubsection: number;
		nSubsubsection: number;
	};
	startTime: Date;
	endTime: Date;
	formulaWidth: any;
}
declare var aeroval: ExtendedGlobals;

document.addEventListener('DOMContentLoaded', () => {
	if (window.location.pathname.includes('pages/reports')) {
		init();
	}
});

function init() {
	console.log('initialize report page');
	//check if any queries
	aeroval.seriesVisibility = {};
	if (window.location.href.includes('cams2-82')) {
		aeroval.menuButtons = ['model', 'region', 'time-no_seasons-no_freqs'];
	} else if (window.location.href.includes('cams2-83')) {
		aeroval.menuButtons = ['model', 'region', 'time-no_all-no_freqs-no_periods'];
	}

	main.getQueryStrings();

	/*update header links with qs project*/
	main.getExperiments(true, false, false);

	main.updateURLs('project', aeroval.project);

	Highcharts.setOptions({
		chart: {
			style: {
				fontSize: '1em',
			},
			backgroundColor: 'transparent',
		},
		accessibility: {
			enabled: false,
		},
		title: {
			style: {
				fontSize: '0.9em',
			},
		},
		subtitle: {
			style: {
				fontSize: '0.7em',
			},
		},
		legend: {
			itemStyle: {
				fontSize: '0.6em',
			},
		},
		yAxis: {
			title: {
				style: {
					fontSize: '0.7em',
				},
			},
		},
		xAxis: {
			title: {
				style: {
					fontSize: '0.7em',
				},
			},
		},
	});

	// allow image to data url
	//https://stackoverflow.com/questions/934012/get-image-data-url-in-javascript/42916772#42916772
	Object.defineProperty(HTMLImageElement.prototype, 'toDataURL', {
		enumerable: false,
		configurable: true,
		writable: false,
		value: function (m: any, q: any) {
			let c = document.createElement('canvas');
			c.width = this.naturalWidth;
			c.height = this.naturalHeight;
			c.getContext('2d')?.drawImage(this, 0, 0);
			return c.toDataURL(m, q);
		},
	});
}

export function callbackMenu() {
	//getParameters(aeroval.project, true)
	makeDivs();
}

function updateTime() {
	updateTimeSeries();
}

export function update(which: string, what: string) {
	console.info('update ', which, 'with ', what);
	// we set the following array to zero length as the export all gets all the charts from this array
	Highcharts.charts.length = 0;

	if (which == 'mean_and_statistic' || which == 'statistic' || which == 'forecast_statistic') {
		aeroval.statistic = aeroval.statistics[what];
		aeroval.statistic.dir = what;
		if (what == 'data_mean') {
			aeroval.statistic.name = 'Mean';
			aeroval.statistic.longname = 'Mean (Obs and Model)';
		}
		main.topMenu(true, true);
	}
	if (which == 'region') {
		aeroval[which] = what;
		main.topMenu(true, true);
	}
	if (which == 'experiment') {
		aeroval.experiment = aeroval.experiments[what];
		aeroval.experiment.name = what;
		main.getExperiments(true);
		utils.updateQueryString(which, what);
		main.updateURLs(which, what);
	}
	if (which == 'obsType') {
		aeroval[which] = what;
		main.topMenu(true, true);
	}
	if (which == 'frequency') {
		aeroval[which] = what as Aeroval['frequency'];
		utils.updateQueryString('stats', what);
		main.updateURLs('stats', what);
		main.topMenu(true, true);
	}
	if (which.includes('time')) {
		aeroval.time = what;
		main.topMenu(true, true);
		main.getExtremeDates();
	}
	if (which == 'year') {
		aeroval.time = what;
		main.showMenu(which, what);
		main.getExtremeDates();
		updateTime();
	}
	if (which == 'season') {
		aeroval[which] = what as Aeroval['season'];
		main.topMenu(true, true);
	}
	if (which == 'model') {
		// remove some data
		delete aeroval.data.hm;

		aeroval.model = {
			dir: what,
			name: what,
			var: aeroval.modVars[what],
		};
		utils.updateQueryString(which, what);
		main.topMenu(true, true);
	}
}

function makeDivs() {
	//create divs
	var h = '';
	if (aeroval.project == 'cams2-83' || aeroval.project == 'cams2-82') {
		h += "<div id='main-content'>";
		if (!aeroval.isMobile) {
			var h = '<div class="alert alert-dismissible alert-light fade show my-2" role="alert">';
			h += 'Download PDF document (this may take a few minutes).';
			h += `<button id="dwnld-pdf" type="button-sm" class="btn btn-primary" style="margin-left: 20px" title="Download page as pdf">${filePdf.html}</button>`;
			h += '</div>';
			h += '</div>';
			h += '</div>';
		}

		// add hidden images for report
		h += `<img id="cams-frontpage-header" src="${camsFrontpageHeader}" style="display: none">`;
		h += `<img id="cams-frontpage-footer" src="${camsFrontpageFooter}" style="display: none">`;
		h += `<img id="cams-header" src="${camsHeader}" style="display: none">`;
		h += `<img id="aeroval-logo" src="${aerovalLogo}" style="display: none">`;

		// content container
		h += '<div id="report-container"></div>';
		h += '</div>';
	} else {
		utils.todo();
	}
	const main = document.getElementById('main');
	if (main) {
		main.innerHTML = h;
	}

	loadReportContent();
	// set up button actions

	/*document.getElementById('dwnld-png').addEventListener('click', function () {
		dwnldAll();
	});*/

	const dwnldPDF = document.getElementById('dwnld-pdf');
	if (dwnldPDF) {
		dwnldPDF.addEventListener('click', function () {
			HTML2PDF();
		});
	}
}

function HTML2PDF() {
	aeroval.startTime = new Date();
	// set cursor as progress
	document.body.style.cursor = 'progress';

	// add loading indicator in button
	const dwnldPDF = document.getElementById('dwnld-pdf');
	if (dwnldPDF) {
		dwnldPDF.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';
		dwnldPDF.setAttribute('disabled', '');
	}

	// add printing class
	document.querySelector('.main-content')?.classList.add('printing');
	/*for (var img of document.getElementsByClassName('img-static')){
		img.classList.add("printing")
	}*/
	for (var chart of document.getElementsByClassName('highcharts-container')) {
		chart.classList.add('printing');
		const element = chart.firstChild as HTMLElement;
		const width = element.getAttribute('width');
		const height = element.getAttribute('height');
		if (width && height) {
			element.setAttribute('width', String(parseFloat(width) * 0.75));
			element.setAttribute('height', String(parseFloat(height) * 0.75));
		}
	}
	/*set height attribute of formula class*/
	aeroval.formulaWidth = {};

	const formulas = document.querySelectorAll('.formula');
	var nFormula = 0;
	for (const formula of formulas) {
		if (formula instanceof HTMLElement) {
			formula.getBoundingClientRect().width as number;
			const wFactor = (150 * aeroval.formulaWidth[nFormula]) / 250;
			formula.setAttribute('width', String(wFactor) + '%');
			nFormula++;
		}
	}

	/*set length of bar plot*/
	for (const chart of document.getElementsByClassName('bar-plot')) {
		const element = chart.firstChild as HTMLElement;
		element.setAttribute('width', '450');
	}

	// reflow highcharts
	utils.reflow();

	// make pdf asynchronously
	makePDF();
}

function makePDF() {
	const reportContainer = document.getElementById('report-container');
	if (!reportContainer) return;
	//html-to-pdfmake
	const docDefinition = htmlToPdfmake(reportContainer.innerHTML, {
		imagesByReference: true,
		defaultStyles: {
			img: { maxWidth: 500, maxHeight: 500, alignment: 'center' },
		},
		styles: {
			'formula-container': { height: 500, background: 'yellow' },
		},
	});

	// replace images url with dataurl
	var i = 1;
	for (const key in docDefinition.images) {
		docDefinition.images[key] = (document.querySelector(`[ref="img-ref-${i}"]`) as HTMLCanvasElement).toDataURL();
		i++;
	}

	// make table of contents
	if ('table-of-contents' in aeroval.report) {
		var addToTOC = false;
		var startAfter = 0;
		// add each section to toc
		for (var i = 0; i < docDefinition.content[0].stack.length; i++) {
			if (addToTOC) {
				// add toc to all substack
				for (var j in docDefinition.content[0].stack[i].stack) {
					if (docDefinition.content[0].stack[i].stack[j].nodeName.startsWith('H')) {
						docDefinition.content[0].stack[i].stack[j].tocItem = true;
						if (docDefinition.content[0].stack[i].stack[j].nodeName == 'H2') {
							docDefinition.content[0].stack[i].stack[j].tocMargin = [0, 0, 0, 0];
						} else if (docDefinition.content[0].stack[i].stack[j].nodeName == 'H3') {
							docDefinition.content[0].stack[i].stack[j].tocMargin = [20, 0, 0, 0];
						} else if (docDefinition.content[0].stack[i].stack[j].nodeName == 'H4') {
							docDefinition.content[0].stack[i].stack[j].tocMargin = [40, 0, 0, 0];
						}
					}
				}
			}
			if (docDefinition.content[0].stack[i].id == aeroval.report['table-of-contents'].after) {
				addToTOC = true;
				startAfter = i;
			}
		}
		// insert toc at right position
		var toc = {
			toc: {
				title: {
					text: aeroval.report['table-of-contents'].title,
					pageBreak: 'before',
					style: {
						fontSize: 20,
						bold: true,
						lineHeight: 1.5,
					},
				},
			},
		};
		docDefinition.content[0].stack.splice(startAfter + 1, 0, toc);
	}

	console.log(docDefinition);

	// process authors
	if ('contributors' in aeroval.report) {
		var html_authors = '<h2>Contributors</h2>';
		for (let contributor in aeroval.report.contributors) {
			html_authors += "<h6 class='affiliation'>" + contributor + '</h6>';
			var i = 0;
			for (let author of aeroval.report.contributors[contributor]) {
				i++;
				html_authors += author;
				if (i < aeroval.report.contributors[contributor].length) {
					html_authors += '; ';
				}
			}
		}
		var authors = htmlToPdfmake(html_authors);
	} else {
		var authors = {} as any;
	}

	// pdfmake
	var dd = {
		content: [
			{ image: (document.getElementById('cams-frontpage-header') as HTMLCanvasElement).toDataURL(), width: 596, margin: [-40, -100, -40, -40] },
			{ text: aeroval.report.title, margin: [0, 120, 0, 0], style: 'title' },
			{ text: aeroval.report.subtitle, margin: [0, 20, 0, 0], style: 'subtitle' },
			{ text: 'Issued by: ' + aeroval.report.issued_by, margin: [0, 50, 0, 0], alignment: 'center' },
			{ text: 'Date: ' + aeroval.report.date, margin: [0, 5, 0, 0], alignment: 'center', pageBreak: 'after' },
			//{ text: 'Ref: '+aeroval.report.ref, margin: [0, 5, 0, 0], alignment: 'center'},
			authors,
			docDefinition.content,
			//{ image: document.getElementById('cams-backpage').toDataURL(), width: 596, margin: [-40, -100, -40, -40], pageBreak: 'before'},
			{ qr: window.location.href, fit: '100', margin: [0, 300, 0, 0], alignment: 'center', pageBreak: 'before' },
		],
		images: docDefinition.images,
		header: function (currentPage: number) {
			// you can apply any logic and return any valid pdfmake element

			if (currentPage == 1) {
				return {
					columns: [{ image: (document.getElementById('cams-frontpage-header') as HTMLCanvasElement).toDataURL(), width: 596 }],
				};
			} else if (currentPage > 1) {
				return {
					margin: [0, 0, 0, 20],
					columns: [{ image: (document.getElementById('cams-header') as HTMLCanvasElement).toDataURL(), height: 60, width: 596 }],
				};
			}
		},
		footer: function (currentPage: number, pageCount: number) {
			if (currentPage == 1) {
				return {
					margin: [0, -95, 0, -40],
					columns: [{ image: (document.getElementById('cams-frontpage-footer') as HTMLCanvasElement).toDataURL(), width: 596 }],
				};
			} else if (currentPage > 1) {
				return {
					margin: [20, 5, 5, 20],
					columns: [
						{ image: (document.getElementById('aeroval-logo') as HTMLCanvasElement).toDataURL(), height: 25, width: 25 },
						{ text: currentPage.toString() + ' of ' + pageCount, alignment: 'right', margin: 10 },
					],
				};
			}
		},
		pageSize: 'A4',
		pageMargins: [40, 100, 40, 40],
		pageBreakBefore: function (currentNode: any) {
			return currentNode.style && currentNode.style.length > 0 && currentNode.style.indexOf('break-before') > -1;
		},
		defaultStyle: {
			fontSize: 12,
		},
		styles: {
			'html-p': {
				alignment: 'justify',
				margin: [0, 5, 0, 10],
			},
			affiliation: {
				marginTop: 10,
			},
			title: {
				fontSize: 22,
				bold: true,
				alignment: 'center',
			},
			subtitle: {
				fontsize: 20,
				color: '#46b3c7',
				alignment: 'center',
			},
			caption: {
				fontSize: 11,
				color: '#6c757d',
				alignment: 'justify',
				marginTop: 5,
				marginBottom: 20,
			},
			'formula-container': { fontSize: 0 },
		},
	};

	printPDF(dd, aeroval.report.ref);
}

async function printPDF(content: any, filename: string) {
	pdfMake.createPdf(content).download(filename, afterPrint);
	await postPDF();
}

function postPDF() {
	// remove printing class
	document.querySelector('.main-content')?.classList.remove('printing');
	for (const chart of document.getElementsByClassName('highcharts-container')) {
		chart.classList.remove('printing');
		const element = chart.firstChild as HTMLElement;
		const width = element.getAttribute('width');
		const height = element.getAttribute('height');
		if (width && height) {
			element.setAttribute('width', String(parseFloat(width) * 1.32999));
			element.setAttribute('height', String(parseFloat(height) * 1.32999));
		}
	}

	const formulas = document.querySelectorAll('.formula');
	var nFormula = 0;
	for (const formula of formulas) {
		if (formula instanceof HTMLElement) {
			formula.getBoundingClientRect().width as number;
			let width = aeroval.formulaWidth[nFormula];
			formula.setAttribute('width', String(width));
			nFormula++;
		}
	}

	/*set length of bar plot*/
	for (var chart of document.getElementsByClassName('bar-plot')) {
		let element = chart.firstChild as HTMLElement;
		element.setAttribute('width', '100%');
	}
}

function afterPrint() {
	//set cursor as normal
	document.body.style.cursor = 'default';

	// set pdf button back to normal
	const dwnldPDF = document.getElementById('dwnld-pdf');
	if (dwnldPDF) {
		dwnldPDF.innerHTML = '<i class="fa-solid fa-file-pdf"></i>';
		dwnldPDF.removeAttribute('disabled');
	}

	// reflow charts
	utils.reflow();

	// print elapsed time
	aeroval.endTime = new Date();
	let timeElapsed = aeroval.endTime.valueOf() - aeroval.startTime.valueOf();
	console.log(`The PDF was generated in ${timeElapsed / 1000} seconds`);
}

function loadReportContent() {
	var url: string | undefined = undefined;
	if (aeroval.project == 'cams2-83') {
		//url = `${window.API_ROOT}/reports/reports/${aeroval.project}/${aeroval.experiment.name}/${aeroval.model.dir}/${aeroval.season}${aeroval.time}/EQC-report_${aeroval.model.name}_${aeroval.season}${aeroval.time}.json`;
	} else if (aeroval.project == 'cams2-82') {
		//url = `${window.API_ROOT}/reports/reports/${aeroval.project}/${aeroval.experiment.name}/QL-report_${aeroval.model.dir}_${aeroval.time}.json`;
		var title = `QL-report_${aeroval.model.dir}_${aeroval.time}`
		url = `${window.API_ROOT}/reports/${aeroval.project}/${aeroval.experiment.name}/${title}?data_path=${window.DATA_PATH}`
	}
	if (!url) return;

	// load report
	fetch(url, {
		method: 'GET',
		headers: {
			'Accept': 'application/json'
		}
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then((data) => {
			// insert model name
			var strData = JSON.stringify(data);
			strData = strData.replaceAll('*model*', aeroval.model.name);
			// replace special characters
			const specialCharacters: { [key: string]: string } = {
				'↗': "<svg fill='#0d6efd' height='16' width='16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d='M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM377 271c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-87-87-87 87c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 167c9.4-9.4 24.6-9.4 33.9 0L377 271z'/></svg>",
				'↘': "<svg fill='#dc3545' height='16' width='16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d='M256 0C114.6 0 0 114.6 0 256S114.6 512 256 512s256-114.6 256-256S397.4 0 256 0zM135 241c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l87 87 87-87c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L273 345c-9.4 9.4-24.6 9.4-33.9 0L135 241z'/></svg>",
			};
			for (const specialCharacter in specialCharacters) {
				strData = strData.replaceAll(specialCharacter, specialCharacters[specialCharacter]);
			}
			data = JSON.parse(strData) as ExtendedGlobals['report'];
			makeLinks(data);
			// render
			//MathJax.typeset();
		})
		.catch((error) => {
			console.error('There was a problem with the fetch operation:', error);
			var h = '<div class="alert alert-warning" role="alert" style="margin: 50px auto" >No report is available for the current selection</div>';
			// send content to div
			const innerDiv = document.getElementById('report-container');
			if (innerDiv) {
				const contentDiv = document.createElement('div');
				contentDiv.className = 'report-container';
				contentDiv.innerHTML = h;
				innerDiv.appendChild(contentDiv);
			}
		});
}

function makeLinks(content: any) {
	aeroval.report = content;
	// loop through each section
	for (const i in content.sections) {
		// loop through content
		for (const j in content.sections[i].content) {
			// linkify all keys
			for (const key in content.sections[i].content[j]) {
				if (key != 'options') {
					content.sections[i].content[j][key] = utils.linkify(content.sections[i].content[j][key]);
				}
			}
		}
	}
	makeReport(content);
}

function makeReport(content: any) {
	// global count of figures for later reference
	aeroval.stats_report = {
		nTables: 0,
		nFigures: 0,
		nSection: 0,
		nSectionFigures: 0,
		nSubsection: 0,
		nSubsubsection: 0,
	};

	var h = '';
	for (const section of content.sections) {
		h += `<div id="${section.title}">`;
		// add title
		if (section.title.startsWith('# ')) {
			aeroval.stats_report.nSection++;
			aeroval.stats_report.nSectionFigures = 0;
			aeroval.stats_report.nSubsection = 0;
			aeroval.stats_report.nSubsubsection = 0;
			var numerotation = String(aeroval.stats_report.nSection);
			// default: break-before
			h += '<h2 class="break-before">' + section.title.replace('#', numerotation) + '</h2>';
		} else if (section.title.startsWith('## ')) {
			aeroval.stats_report.nSubsection++;
			aeroval.stats_report.nSubsubsection = 0;
			numerotation = `${aeroval.stats_report.nSection}.${aeroval.stats_report.nSubsection}`;
			if ('pageBreak' in section && section.pageBreak == 'before') {
				h += '<h3 class="break-before">' + section.title.replace('##', numerotation) + '</h3>';
			} else {
				// default: no-break-before
				h += '<h3 class="no-break-before">' + section.title.replace('##', numerotation) + '</h3>';
			}
		} else if (section.title.startsWith('### ')) {
			aeroval.stats_report.nSubsubsection++;
			numerotation = `${aeroval.stats_report.nSection}.${aeroval.stats_report.nSubsection}.${aeroval.stats_report.nSubsubsection}`;
			if ('pageBreak' in section && section.pageBreak == 'before') {
				h += '<h4 class="break-before">' + section.title.replace('###', numerotation) + '</h4>';
			} else {
				// default: no-break-before
				h += '<h4 class="no-break-before">' + section.title.replace('###', numerotation) + '</h4>';
			}
		} else {
			// if no number
			h += '<h2 class="break-before">' + section.title + '</h2>';
		}
		// add content
		if (section.content) {
			for (let content of section.content) {
				h += addContent(content);
			}
		}
		h += '</div>';
	}

	// add figures ids
	h = addFiguresRefById(h)

	// send content to div
	const innerDiv = document.getElementById('report-container');
	if (innerDiv) {
		const contentDiv = document.createElement('div');
		contentDiv.className = 'report-container';
		contentDiv.innerHTML = h;
		innerDiv.appendChild(contentDiv);
	}

	// load charts
	for (let section of content.sections) {
		if (section.content) {
			for (let content of section.content) {
				if (content.type === 'chart') {
					loadChart(content.id, content.options);
				}
			}
		}
	}
}

function addFiguresRefById(content: string): string {
	// check in the content for dif[#]
	var newContent = content
	var figRefInContent = true

	while (figRefInContent) {
		//work with substract of text
		const from = newContent.indexOf('fig[#')
		if (from == -1) break;

		var partText = newContent.substring(from)
		const to = from + partText.indexOf(']') + 1
		partText = newContent.substring(from, to)

		const figId = partText.split('[#')[1].split(']')[0]
		const figNum = newContent.split(`caption-${figId}`)[1]?.split('<b>')[1]?.split('</b>')[0]
		newContent = replaceBetween(newContent, from, to, `<a href="#${figId}">${figNum}</a>`)

		// check if needs to continue
		figRefInContent = newContent.includes('fig[#')
	}
	return newContent
}

function replaceBetween(origin: string, startIndex: number, endIndex: number, insertion: string): string {
	return origin.substring(0, startIndex) + insertion + origin.substring(endIndex);
}

function addContent(content: any): string | undefined {
	var html: string | undefined = undefined;
	if (content.type == 'chart') {
		html = `<div id="${content.id}"></div>`;
		if (content.caption.length > 0) {
			aeroval.stats_report.nSectionFigures++;
			if (content.title.endsWith('#')) {
				var numerotation = `${aeroval.stats_report.nSection}.${aeroval.stats_report.nSectionFigures}`;
				html += `<div class="caption"><b>${content.title.replace('#', numerotation)}: </b>${content.caption}</div>`;
			} else {
				html += `<div class="caption"><b>${content.title}: </b>${content.caption}</div>`;
			}
		}
	} else if (content.type == 'paragraph') {
		html = `<p>${content.text}</p>`;
	} else if (content.type == 'table') {
		html = `<div class="table-responsive">${content.text.replaceAll('<table', "<table class='table table-hover'")}</div>`;
		if (content.caption.length > 0) {
			aeroval.stats_report.nTables++;
			// add caption
			if (content.title.endsWith('#')) {
				var numerotation = String(aeroval.stats_report.nTables);
				html += `<div class="caption"><b>${content.title.replace('#', numerotation)} : </b>${content.caption}</div>`;
			} else {
				html += `<div class="caption"><b>${content.title}: </b>${content.caption}</div>`;
			}
		}
	} else if (content.type == 'figure') {
		aeroval.stats_report.nFigures++;
		const src = `${window.API_ROOT}/reports_images/${aeroval.project}/${aeroval.experiment.name}/${content.url}`;
		const id = content.id ? content.id : '';

		html = '<figure class="text-center" style="margin: 0px 0px 20px 0px;">';
		html += `<img crossorigin="anonymous" ref="img-ref-${aeroval.stats_report.nFigures}" id="${id}" class="img-static img-fluid mx-auto" src="${src}">`;
		if (content.caption.length > 0) {
			aeroval.stats_report.nSectionFigures++;
			html += `<figcaption `
			if (id) {
				html += `id="caption-${id}"`
			}
			if (content.title.endsWith('#')) {
				var numerotation = `${aeroval.stats_report.nSection}.${aeroval.stats_report.nSectionFigures}`;
				html += `class="caption"><b>${content.title.replace('#', numerotation)}</b>: ${content.caption}</figcaption>`;
			} else {
				html += `class="caption"><b>${content.title}</b>: ${content.caption}</figcaption>`;
			}
		}
		html += '</figure>';
	} else if (content.type == 'equation') {
		html = '<div class="formula-container">';
		var equation = MathJax.tex2svg(content.text.replace(/\\\\/g, '\\').replaceAll('$$', ''));
		equation.firstChild.setAttribute('height', '60px');
		equation.firstChild.setAttribute('width', '100%');
		//equation.firstChild.setAttribute('viewBox', '10000 -2000 15000 1500')
		html += equation.innerHTML;
		html += '</div>';
		html = html.replace('<svg', '<svg class="formula"');
		// add formula class to svg
	} else if (content.type == 'reference') {
		html = '<p>';
		html += `${content.authors}, `;
		html += `<b>${content.title}</b>, `;
		html += `<i>${content.journal}</i>, `;
		html += `${content.url}, `;
		html += `${content.date}.`;
		html += '</p>';
	} else {
		console.warn('unknown content type:', content.type);
	}

	return html;
}

function loadChart(id: string, options: any) {
	var type = id.split('_')[0];
	if (type.startsWith('ts')) {
		loadTimeSeries(id, options);
	} /* else if (type.startsWith('fcst')) {
		loadForecast(id, options, true);
	} else if (type.startsWith('trgt')) {
		loadMapData(id, options, true);
	} else if (type.startsWith('qrtr')) {
		loadHeatmapData(id, options, true);
	}*/ else {
		console.warn('unknown type chart:', type);
	}
}

function loadTimeSeries(div: string, options: any) {
	console.log(options);
	const type = div.split('_')[0];
	const obs = div.split('_')[1];
	const par = div.split('_')[2];
	const lay = div.split('_')[3];
	const stat = div.split('_')[4];

	// check if forecast or analysis
	const exp = type.includes('-an') ? aeroval.experiment.name.replace('report', 'analysis') : aeroval.experiment.name;
	const url = `${window.API_ROOT}/hm_ts/${aeroval.project}/${exp}/${aeroval.region}/${obs}/${par}/${lay}`;
	fetch(url, {
		method: 'GET',
		headers: {
			'Accept': 'application/json'
		}
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then((data) => {
			const orderedData: any = {};
			for (let menuItem in data) {
				orderedData[menuItem] = data[menuItem];
			}
			//aeroval.data.ts[aeroval.model.dir] = orderedData;
			aeroval.data.hm_ts = orderedData;
			aeroval.groupBy = 'None';
			const chartOptions = {
				height: 280,
				lineWidth: 2,
				markerRadius: 4,
			};
			plotTimeSeriesStatistics(orderedData, par, obs, lay, stat, div, [aeroval.model.dir], true, false, chartOptions);
		})
		.catch((error) => {
			console.error('There was a problem with the fetch operation:', error);
		});
}

function updateTimeSeries() {
	if (aeroval.time.indexOf('-') != -1) {
		var y1 = aeroval.time.split('-')[0];
		var y2 = aeroval.time.split('-')[1];
	} else {
		var y1 = aeroval.time;
		var y2 = aeroval.time;
	}

	var chart = utils.getChartById('ts');
	if (chart) {
		chart.xAxis[0].update({
			min: new Date(parseFloat(y1), 0, 1).valueOf(),
			max: new Date(parseFloat(y2), 11, 31).valueOf(),
		});
		chart.setTitle({ text: aeroval.parameter.name + ' - ' + aeroval.region + ' - ' + aeroval.time });
	}
}

/*function loadForecast(div, options, plot) {
	var type = div.split('_')[0];
	var obs = div.split('_')[1];
	var par = div.split('_')[2];
	var lay = div.split('_')[3];

	// check if forecast or analysis
	if (type.includes('-an')) {
		var exp = aeroval.experiment.name.replace('report', 'analysis');
	} else {
		var exp = aeroval.experiment.name;
	}

	var fn = `/data/${aeroval.project}/${exp}/forecast/${aeroval.region}_${obs}-${par}_${lay}.json`;
	$.getJSON(fn)
		.done(function (data) {
			aeroval.data.forecast = data;
			if (plot) {
				plotForecast(div, options, data);
			}
		})
		.fail(function () {
			console.log('error opening', fn);
		});
}*/

/*function loadMapData(div, options, plot) {
	var type = div.split('_')[0];
	var obs = div.split('_')[1];
	var par = div.split('_')[2];
	var lay = div.split('_')[3];
	var stats = div.split('_')[4];

	// check if forecast or analysis
	if (type.includes('-an')) {
		var exp = aeroval.experiment.name.replace('report', 'analysis');
	} else {
		var exp = aeroval.experiment.name;
	}

	//var mod_var = aeroval.modelVars[aeroval.model.dir]
	var fn = `../data/${aeroval.project}/${exp}/map/${obs}-${par}_${lay}_${aeroval.model.dir}-${par}.json`;
	$.getJSON(fn, function () {})
		.done(function (data) {
			if (plot) {
				makeTargetDivs(div, data);
			}
		})
		.fail(function () {
			console.log('error opening', fn);
		});
}*/

/*function makeTargetDivs(div, target_data) {
	var fn = '/includes/config/target.json';
	if (aeroval.config.target === undefined) {
		$.getJSON(fn, function (data) {})
			.done(function (data) {
				aeroval.config.target = data;
				callbackMakeTargetDivs(div, target_data);
			})
			.fail(function () {
				console.log('error opening', fn);
			});
	} else {
		callbackMakeTargetDivs(div, target_data);
	}
}*/

/*function callbackMakeTargetDivs(div, target_data) {
	var h = '';
	// work with table
	h += "<table class='table table-sm table-borderless' data-pdfmake='{&quot;layout&quot;:&quot;noBorders&quot;}'>";
	h += '<tr>';
	h += "<td style='width: 30px'></td>";
	if (aeroval.isMobile) {
		h += '<td>';
		h += "<div id='" + div + "-trgt' style='padding: 0; height: 360px; width: 320px; margin: auto;'></div>";
		h += '</td>';
	} else {
		h += '<td>';
		h += "<div id='" + div + "-trgt' style='padding: 0; height: 400px; width: 400px; margin: auto;'></div>";
		h += '</td>';
	}
	h += '<td>';
	h += "<div class='center target-dot' style='width: 30px;'>";
	h += "<div id='" + div + "-trgt-dot'><i class='fa-solid fa-circle' title='Unknown'></i></div>";
	h += '</div>';
	h += '</td>';
	h += '</tr>';
	h += '</table>';

	h += "<div id='" + div + "-bars'></div>";
	document.getElementById(div).innerHTML = h;

	plotTarget(div + '-trgt', target_data);
	makeBarsDivs(div, target_data);
}*/

/*function makeBarsDivs(div, target_data) {
	var fn = '/includes/config/bars.json';
	if (aeroval.config.bars === undefined) {
		$.getJSON(fn, function (data) {})
			.done(function (data) {
				aeroval.config.bars = data;
				callbackMakeBarsDivs(div, target_data);
			})
			.fail(function () {
				console.log('error opening', fn);
			});
	} else {
		callbackMakeBarsDivs(div, target_data);
	}
}*/

/*function callbackMakeBarsDivs(div, target_data) {
	var h = '';
	// work with table
	h += "<table class='table table-sm table-borderless' data-pdfmake='{&quot;layout&quot;:&quot;noBorders&quot;}'>";
	for (let category in aeroval.config.bars) {
		var i = 0;
		for (let chart in aeroval.config.bars[category].charts) {
			h += '<tr>';
			if (i == 0) {
				h +=
					"<td class='rotate' rowspan=" +
					Object.keys(aeroval.config.bars[category].charts).length +
					'><div>' +
					aeroval.config.bars[category].title +
					'</div></td>';
			}
			h += '<td>';
			h += "<div id='" + div + '-' + chart + "' style='min-height: 40px; padding: 0px'></div>";
			h += '</td>';
			h += '<td>';
			h += "<div class='center target-dot' style='width: 30px;'>";
			h += "<div id='" + div + '-' + chart.replace('bar', 'dot') + "'><i class='fa-solid fa-circle'></i></div>";
			h += '</div>';
			h += '</td>';
			h += '</tr>';
			i++;
		}
	}
	h += '</table>';
	document.getElementById(div + '-bars').innerHTML = h;

	$(function () {
		plotBars(div, target_data);
	});
}*/

// Returns a Promise that resolves after "ms" Milliseconds

/*
const timer = (ms: number) => new Promise((res) => setTimeout(res, ms));
async function dwnldAll() {
	const charts = Highcharts.charts;
	for (const chart of charts) {
		if (chart) {
			chart.exportChartLocal();
			await timer(100); // then the created Promise can be awaited
		}
	}
}
*/
