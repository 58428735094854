import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import Boost from 'highcharts/modules/boost';
if (typeof Highcharts === 'object') {
	HighchartsMore(Highcharts);
	Boost(Highcharts);
}

import { range, clickOnLegendItem, getModelStyle, getKeyTime, highchartsPalette } from '../../../utils';

import { Aeroval } from '../../../types/global';
import { HMData } from '../../../types/data';
declare var aeroval: Aeroval;

export function plotTaylor(data: HMData): void {
	const subData = data[aeroval.parameter.dir][aeroval.observation][aeroval.layer];
	const keyTime = getKeyTime(aeroval.time, aeroval.season);

	const series = [] as any;

	//add ref point
	if (aeroval.settings.theme == 'dark') {
		var ref_color = 'white';
	} else {
		ref_color = 'black';
	}
	var r = 1;
	var theta = 1;
	series.push({
		type: 'line',
		color: ref_color,
		marker: {
			symbol: 'circle',
			radius: 3,
		},
		name: 'Ref',
		data: [
			{
				x: (2 / Math.PI) * Math.asin(theta),
				y: r,
				customX: theta
			}
		],
		opacity: 1,
		showInLegend: false,
		lineWidth: 0,
		enableMouseTracking: false,
		dataLabels: {
			enabled: true,
			format: 'Ref',
		},
		animation: false,
	});

	var i = 0;
	for (let model in subData) {
		const modVar = aeroval.modVars[model];
		const stationStatistic = data[aeroval.parameter.dir][aeroval.observation][aeroval.layer][model][modVar][aeroval.region][keyTime];

		const highchartsColor = highchartsPalette(i);
		const modelStyle = getModelStyle(model);
		const colorModel = modelStyle?.color ? modelStyle.color : highchartsColor;

		//here we overwrite the visibility
		var vis = true;
		if (typeof aeroval.seriesVisibility[model] != 'undefined') {
			vis = aeroval.seriesVisibility[model];
		}

		let r = Math.abs(stationStatistic['data_std'] as number) / (stationStatistic['refdata_std'] as number);
		let theta = parseFloat((stationStatistic['R_spearman'] as number).toFixed(2));

		series.push({
			type: 'scatter',
			name: model,
			data: [
				{
					x: (2 / Math.PI) * Math.asin(theta),
					y: r,
					customX: theta
				}
			],
			color: colorModel,
			visible: vis,
		});
		i++;
	}

	//add rms isolines
	const rmss = [0.5, 1, 1.5, 2];
	const thetas = range(0, 1, 0.01);

	for (let rms of rmss) {
		var guide: [number | null, number | null][] = [];
		for (let theta of thetas) {
			var deg_theta = theta * 90;
			var rad_theta = (deg_theta * Math.PI) / 180;
			//https://math.stackexchange.com/questions/737302/plotting-polar-equations-of-circles-not-centered-at-0-0
			//position of circle
			const R = rms; // radius of the circle
			const a = 1; // radius of center
			const phi = 90 * Math.PI / 180; //angle of center
			const rRight = a * Math.cos(rad_theta - phi) + Math.sqrt(R ** 2 - ((a * Math.sin(rad_theta - phi)) ** 2));
			const rLeft = a * Math.cos(rad_theta - phi) - Math.sqrt(R ** 2 - ((a * Math.sin(rad_theta - phi)) ** 2));

			var xGuide: number | null = theta;
			var yGuideRight: number | null = rRight;
			var yGuideLeft: number | null = rLeft;

			//filter points outside the quarter
			if (xGuide < 0 || xGuide > 1) {
				xGuide = null;
				yGuideLeft = null;
				yGuideRight = null;
			}
			guide.push([xGuide, yGuideRight]);
			guide.push([xGuide, yGuideLeft]);

		}
		series.push({
			name: `guide - ${rms}`,
			data: guide,
			marker: {
				enabled: true,
				radius: 1,
				symbol: 'circle',
				lineColor: 'black'
			},
			lineWidth: 0,
			color: 'gray',
			opacity: .2,
			showInLegend: false,
			zIndex: 0,
			enableMouseTracking: false,
			animation: false
		})
	}

	//set title
	const titleText = getTitle();

	// set legend
	var yLegend = 0;
	if (window.screen.width <= 768) {
		yLegend = 50;
	}

	var xAxisLabels = [0, .1, .2, .3, .4, .5, .6, .7, .8, .9, .95, .99, 1]
	var tickPositions = xAxisLabels.map(value => (2 / Math.PI) * Math.asin(value));


	var i = -1
	Highcharts.chart({
		chart: {
			renderTo: 'tlrdgrm',
			polar: true,
			marginTop: 40,
			marginBottom: -200,
			marginLeft: -300,
			marginRight: 0,
			spacingLeft: 0,
			height: 500,
			backgroundColor: 'transparent',
			events: {
				fullscreenOpen: function () {
					// fullscreen background color
					const fullScreenBackgroundColor = aeroval.settings.theme == 'dark' ? '#282b34' : 'white';
					(this as any).update({
						chart: {
							backgroundColor: fullScreenBackgroundColor,
						},
					});
				},
				fullscreenClose: function () {
					(this as any).update({
						chart: {
							backgroundColor: 'transparent',
						},
					});
				},
			},
		},
		credits: {
			enabled: false,
		},
		title: {
			text: titleText,
		},
		subtitle: {
			text: `${aeroval.observation} - ${aeroval.frequency} data`,
		},
		pane: {
			startAngle: 0,
			endAngle: 90,
		},
		xAxis: [{
			min: 0,
			max: 1,
			tickInterval: 0.05,
			gridLineDashStyle: 'Dash',
			title: {
				text: 'linear',
				y: -100,
				x: 275,
				rotation: 45,
			},
			visible: false,
			labels: {
				style: {
					fontSize: '0.4em'
				}
			}
		}, {
			min: 0,
			max: 1,
			title: {
				text: 'Correlation',
				y: -90,
				x: 250,
				rotation: 45,
			},
			tickPositions,
			gridLineDashStyle: 'Dash',
			labels: {
				allowOverlap: true,
				formatter: function () {
					i++;
					if (this.pos === 0) {
						i = 0
					}
					return String(xAxisLabels[i])
				}
			}
		}],
		yAxis: {
			min: 0,
			max: 2,
			tickInterval: 0.25,
			gridLineDashStyle: 'Dash',
			title: {
				text: 'Norm Std Dev.',
				rotation: 270,
				x: -40,
			},
		},
		tooltip: {
			valueDecimals: 2,
			formatter: function () {
				return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + '<br />'
					+ 'Correlation: <b>' + (this.point as any).customX.toFixed(2) + '</b><br/>'
					+ 'Norm Std Dev.: <b>' + this.point.y?.toFixed(2) + '</b>'
			}
		},
		legend: {
			floating: false,
			verticalAlign: 'bottom',
			align: 'center',
			y: yLegend,
		},
		series,
		plotOptions: {
			series: {
				events: {
					legendItemClick: function () {
						clickOnLegendItem(this);
					},
				},
				states: {
					inactive: {
						enabled: false,
					},
				},
			},
		},
	});
}

function getTitle(): string {
	var title = `${aeroval.parameter.name} - ${aeroval.station} - ${aeroval.time}`;
	if (aeroval.season !== 'All') {
		title += ` (${aeroval.season})`;
	}
	return title;
}
