export const globePoints = [
	{ lat: -89, lon: -180.0, elevation: 0 },
	{ lat: -89, lon: -128.57, elevation: 0 },
	{ lat: -89, lon: -77.14, elevation: 0 },
	{ lat: -89, lon: -25.71, elevation: 0 },
	{ lat: -89, lon: 25.71, elevation: 0 },
	{ lat: -89, lon: 77.14, elevation: 0 },
	{ lat: -89, lon: 128.57, elevation: 0 },
	{ lat: -89, lon: 180.0, elevation: 0 },
	{ lat: -88, lon: -180.0, elevation: 0 },
	{ lat: -88, lon: -157.5, elevation: 0 },
	{ lat: -88, lon: -135.0, elevation: 0 },
	{ lat: -88, lon: -112.5, elevation: 0 },
	{ lat: -88, lon: -90.0, elevation: 0 },
	{ lat: -88, lon: -67.5, elevation: 0 },
	{ lat: -88, lon: -45.0, elevation: 0 },
	{ lat: -88, lon: -22.5, elevation: 0 },
	{ lat: -88, lon: 0.0, elevation: 0 },
	{ lat: -88, lon: 22.5, elevation: 0 },
	{ lat: -88, lon: 45.0, elevation: 0 },
	{ lat: -88, lon: 67.5, elevation: 0 },
	{ lat: -88, lon: 90.0, elevation: 0 },
	{ lat: -88, lon: 112.5, elevation: 0 },
	{ lat: -88, lon: 135.0, elevation: 0 },
	{ lat: -88, lon: 157.5, elevation: 0 },
	{ lat: -88, lon: 180.0, elevation: 0 },
	{ lat: -87, lon: -180.0, elevation: 0 },
	{ lat: -87, lon: -165.6, elevation: 0 },
	{ lat: -87, lon: -151.2, elevation: 0 },
	{ lat: -87, lon: -136.8, elevation: 0 },
	{ lat: -87, lon: -122.4, elevation: 0 },
	{ lat: -87, lon: -108.0, elevation: 0 },
	{ lat: -87, lon: -93.6, elevation: 0 },
	{ lat: -87, lon: -79.2, elevation: 0 },
	{ lat: -87, lon: -64.8, elevation: 0 },
	{ lat: -87, lon: -50.4, elevation: 0 },
	{ lat: -87, lon: -36.0, elevation: 0 },
	{ lat: -87, lon: -21.6, elevation: 0 },
	{ lat: -87, lon: -7.2, elevation: 0 },
	{ lat: -87, lon: 7.2, elevation: 0 },
	{ lat: -87, lon: 21.6, elevation: 0 },
	{ lat: -87, lon: 36.0, elevation: 0 },
	{ lat: -87, lon: 50.4, elevation: 0 },
	{ lat: -87, lon: 64.8, elevation: 0 },
	{ lat: -87, lon: 79.2, elevation: 0 },
	{ lat: -87, lon: 93.6, elevation: 0 },
	{ lat: -87, lon: 108.0, elevation: 0 },
	{ lat: -87, lon: 122.4, elevation: 0 },
	{ lat: -87, lon: 136.8, elevation: 0 },
	{ lat: -87, lon: 151.2, elevation: 0 },
	{ lat: -87, lon: 165.6, elevation: 0 },
	{ lat: -87, lon: 180.0, elevation: 0 },
	{ lat: -86, lon: -180.0, elevation: 0 },
	{ lat: -86, lon: -169.09, elevation: 0 },
	{ lat: -86, lon: -158.18, elevation: 0 },
	{ lat: -86, lon: -147.27, elevation: 0 },
	{ lat: -86, lon: -136.36, elevation: 0 },
	{ lat: -86, lon: -125.45, elevation: 0 },
	{ lat: -86, lon: -114.55, elevation: 0 },
	{ lat: -86, lon: -103.64, elevation: 0 },
	{ lat: -86, lon: -92.73, elevation: 0 },
	{ lat: -86, lon: -81.82, elevation: 0 },
	{ lat: -86, lon: -70.91, elevation: 0 },
	{ lat: -86, lon: -60.0, elevation: 0 },
	{ lat: -86, lon: -49.09, elevation: 0 },
	{ lat: -86, lon: -38.18, elevation: 0 },
	{ lat: -86, lon: -27.27, elevation: 0 },
	{ lat: -86, lon: -16.36, elevation: 0 },
	{ lat: -86, lon: -5.45, elevation: 0 },
	{ lat: -86, lon: 5.45, elevation: 0 },
	{ lat: -86, lon: 16.36, elevation: 0 },
	{ lat: -86, lon: 27.27, elevation: 0 },
	{ lat: -86, lon: 38.18, elevation: 0 },
	{ lat: -86, lon: 49.09, elevation: 0 },
	{ lat: -86, lon: 60.0, elevation: 0 },
	{ lat: -86, lon: 70.91, elevation: 0 },
	{ lat: -86, lon: 81.82, elevation: 0 },
	{ lat: -86, lon: 92.73, elevation: 0 },
	{ lat: -86, lon: 103.64, elevation: 0 },
	{ lat: -86, lon: 114.55, elevation: 0 },
	{ lat: -86, lon: 125.45, elevation: 0 },
	{ lat: -86, lon: 136.36, elevation: 0 },
	{ lat: -86, lon: 147.27, elevation: 0 },
	{ lat: -86, lon: 158.18, elevation: 0 },
	{ lat: -86, lon: 169.09, elevation: 0 },
	{ lat: -86, lon: 180.0, elevation: 0 },
	{ lat: -85, lon: -180.0, elevation: 0 },
	{ lat: -85, lon: -171.43, elevation: 0 },
	{ lat: -85, lon: -145.71, elevation: 0 },
	{ lat: -85, lon: -137.14, elevation: 0 },
	{ lat: -85, lon: -128.57, elevation: 0 },
	{ lat: -85, lon: -120.0, elevation: 0 },
	{ lat: -85, lon: -111.43, elevation: 0 },
	{ lat: -85, lon: -102.86, elevation: 0 },
	{ lat: -85, lon: -94.29, elevation: 0 },
	{ lat: -85, lon: -85.71, elevation: 0 },
	{ lat: -85, lon: -77.14, elevation: 0 },
	{ lat: -85, lon: -68.57, elevation: 0 },
	{ lat: -85, lon: -60.0, elevation: 0 },
	{ lat: -85, lon: -51.43, elevation: 0 },
	{ lat: -85, lon: -42.86, elevation: 0 },
	{ lat: -85, lon: -34.29, elevation: 0 },
	{ lat: -85, lon: -25.71, elevation: 0 },
	{ lat: -85, lon: -17.14, elevation: 0 },
	{ lat: -85, lon: -8.57, elevation: 0 },
	{ lat: -85, lon: 0.0, elevation: 0 },
	{ lat: -85, lon: 8.57, elevation: 0 },
	{ lat: -85, lon: 17.14, elevation: 0 },
	{ lat: -85, lon: 25.71, elevation: 0 },
	{ lat: -85, lon: 34.29, elevation: 0 },
	{ lat: -85, lon: 42.86, elevation: 0 },
	{ lat: -85, lon: 51.43, elevation: 0 },
	{ lat: -85, lon: 60.0, elevation: 0 },
	{ lat: -85, lon: 68.57, elevation: 0 },
	{ lat: -85, lon: 77.14, elevation: 0 },
	{ lat: -85, lon: 85.71, elevation: 0 },
	{ lat: -85, lon: 94.29, elevation: 0 },
	{ lat: -85, lon: 102.86, elevation: 0 },
	{ lat: -85, lon: 111.43, elevation: 0 },
	{ lat: -85, lon: 120.0, elevation: 0 },
	{ lat: -85, lon: 128.57, elevation: 0 },
	{ lat: -85, lon: 137.14, elevation: 0 },
	{ lat: -85, lon: 145.71, elevation: 0 },
	{ lat: -85, lon: 154.29, elevation: 0 },
	{ lat: -85, lon: 162.86, elevation: 0 },
	{ lat: -85, lon: 171.43, elevation: 0 },
	{ lat: -85, lon: 180.0, elevation: 0 },
	{ lat: -84, lon: -158.82, elevation: 0 },
	{ lat: -84, lon: -151.76, elevation: 0 },
	{ lat: -84, lon: -144.71, elevation: 0 },
	{ lat: -84, lon: -137.65, elevation: 0 },
	{ lat: -84, lon: -130.59, elevation: 0 },
	{ lat: -84, lon: -123.53, elevation: 0 },
	{ lat: -84, lon: -116.47, elevation: 0 },
	{ lat: -84, lon: -109.41, elevation: 0 },
	{ lat: -84, lon: -102.35, elevation: 0 },
	{ lat: -84, lon: -95.29, elevation: 0 },
	{ lat: -84, lon: -88.24, elevation: 0 },
	{ lat: -84, lon: -81.18, elevation: 0 },
	{ lat: -84, lon: -74.12, elevation: 0 },
	{ lat: -84, lon: -67.06, elevation: 0 },
	{ lat: -84, lon: -60.0, elevation: 0 },
	{ lat: -84, lon: -52.94, elevation: 0 },
	{ lat: -84, lon: -45.88, elevation: 0 },
	{ lat: -84, lon: -38.82, elevation: 0 },
	{ lat: -84, lon: -31.76, elevation: 0 },
	{ lat: -84, lon: -24.71, elevation: 0 },
	{ lat: -84, lon: -17.65, elevation: 0 },
	{ lat: -84, lon: -10.59, elevation: 0 },
	{ lat: -84, lon: -3.53, elevation: 0 },
	{ lat: -84, lon: 3.53, elevation: 0 },
	{ lat: -84, lon: 10.59, elevation: 0 },
	{ lat: -84, lon: 17.65, elevation: 0 },
	{ lat: -84, lon: 24.71, elevation: 0 },
	{ lat: -84, lon: 31.76, elevation: 0 },
	{ lat: -84, lon: 38.82, elevation: 0 },
	{ lat: -84, lon: 45.88, elevation: 0 },
	{ lat: -84, lon: 52.94, elevation: 0 },
	{ lat: -84, lon: 60.0, elevation: 0 },
	{ lat: -84, lon: 67.06, elevation: 0 },
	{ lat: -84, lon: 74.12, elevation: 0 },
	{ lat: -84, lon: 81.18, elevation: 0 },
	{ lat: -84, lon: 88.24, elevation: 0 },
	{ lat: -84, lon: 95.29, elevation: 0 },
	{ lat: -84, lon: 102.35, elevation: 0 },
	{ lat: -84, lon: 109.41, elevation: 0 },
	{ lat: -84, lon: 116.47, elevation: 0 },
	{ lat: -84, lon: 123.53, elevation: 0 },
	{ lat: -84, lon: 130.59, elevation: 0 },
	{ lat: -84, lon: 137.65, elevation: 0 },
	{ lat: -84, lon: 144.71, elevation: 0 },
	{ lat: -84, lon: 151.76, elevation: 0 },
	{ lat: -84, lon: 158.82, elevation: 0 },
	{ lat: -84, lon: 165.88, elevation: 0 },
	{ lat: -84, lon: 172.94, elevation: 0 },
	{ lat: -83, lon: -155.59, elevation: 0 },
	{ lat: -83, lon: -149.49, elevation: 0 },
	{ lat: -83, lon: -143.39, elevation: 0 },
	{ lat: -83, lon: -137.29, elevation: 0 },
	{ lat: -83, lon: -131.19, elevation: 0 },
	{ lat: -83, lon: -125.08, elevation: 0 },
	{ lat: -83, lon: -118.98, elevation: 0 },
	{ lat: -83, lon: -112.88, elevation: 0 },
	{ lat: -83, lon: -106.78, elevation: 0 },
	{ lat: -83, lon: -100.68, elevation: 0 },
	{ lat: -83, lon: -94.58, elevation: 0 },
	{ lat: -83, lon: -88.47, elevation: 0 },
	{ lat: -83, lon: -82.37, elevation: 0 },
	{ lat: -83, lon: -76.27, elevation: 0 },
	{ lat: -83, lon: -70.17, elevation: 0 },
	{ lat: -83, lon: -64.07, elevation: 0 },
	{ lat: -83, lon: -51.86, elevation: 0 },
	{ lat: -83, lon: -45.76, elevation: 0 },
	{ lat: -83, lon: -39.66, elevation: 0 },
	{ lat: -83, lon: -33.56, elevation: 0 },
	{ lat: -83, lon: -27.46, elevation: 0 },
	{ lat: -83, lon: -21.36, elevation: 0 },
	{ lat: -83, lon: -15.25, elevation: 0 },
	{ lat: -83, lon: -9.15, elevation: 0 },
	{ lat: -83, lon: -3.05, elevation: 0 },
	{ lat: -83, lon: 3.05, elevation: 0 },
	{ lat: -83, lon: 9.15, elevation: 0 },
	{ lat: -83, lon: 15.25, elevation: 0 },
	{ lat: -83, lon: 21.36, elevation: 0 },
	{ lat: -83, lon: 27.46, elevation: 0 },
	{ lat: -83, lon: 33.56, elevation: 0 },
	{ lat: -83, lon: 39.66, elevation: 0 },
	{ lat: -83, lon: 45.76, elevation: 0 },
	{ lat: -83, lon: 51.86, elevation: 0 },
	{ lat: -83, lon: 57.97, elevation: 0 },
	{ lat: -83, lon: 64.07, elevation: 0 },
	{ lat: -83, lon: 70.17, elevation: 0 },
	{ lat: -83, lon: 76.27, elevation: 0 },
	{ lat: -83, lon: 82.37, elevation: 0 },
	{ lat: -83, lon: 88.47, elevation: 0 },
	{ lat: -83, lon: 94.58, elevation: 0 },
	{ lat: -83, lon: 100.68, elevation: 0 },
	{ lat: -83, lon: 106.78, elevation: 0 },
	{ lat: -83, lon: 112.88, elevation: 0 },
	{ lat: -83, lon: 118.98, elevation: 0 },
	{ lat: -83, lon: 125.08, elevation: 0 },
	{ lat: -83, lon: 131.19, elevation: 0 },
	{ lat: -83, lon: 137.29, elevation: 0 },
	{ lat: -83, lon: 143.39, elevation: 0 },
	{ lat: -83, lon: 149.49, elevation: 0 },
	{ lat: -83, lon: 155.59, elevation: 0 },
	{ lat: -83, lon: 161.69, elevation: 0 },
	{ lat: -82, lon: -148.24, elevation: 0 },
	{ lat: -82, lon: -142.94, elevation: 0 },
	{ lat: -82, lon: -137.65, elevation: 0 },
	{ lat: -82, lon: -132.35, elevation: 0 },
	{ lat: -82, lon: -127.06, elevation: 0 },
	{ lat: -82, lon: -121.76, elevation: 0 },
	{ lat: -82, lon: -116.47, elevation: 0 },
	{ lat: -82, lon: -111.18, elevation: 0 },
	{ lat: -82, lon: -105.88, elevation: 0 },
	{ lat: -82, lon: -100.59, elevation: 0 },
	{ lat: -82, lon: -95.29, elevation: 0 },
	{ lat: -82, lon: -90.0, elevation: 0 },
	{ lat: -82, lon: -84.71, elevation: 0 },
	{ lat: -82, lon: -79.41, elevation: 0 },
	{ lat: -82, lon: -74.12, elevation: 0 },
	{ lat: -82, lon: -68.82, elevation: 0 },
	{ lat: -82, lon: -47.65, elevation: 0 },
	{ lat: -82, lon: -42.35, elevation: 0 },
	{ lat: -82, lon: -37.06, elevation: 0 },
	{ lat: -82, lon: -31.76, elevation: 0 },
	{ lat: -82, lon: -26.47, elevation: 0 },
	{ lat: -82, lon: -21.18, elevation: 0 },
	{ lat: -82, lon: -15.88, elevation: 0 },
	{ lat: -82, lon: -10.59, elevation: 0 },
	{ lat: -82, lon: -5.29, elevation: 0 },
	{ lat: -82, lon: 0.0, elevation: 0 },
	{ lat: -82, lon: 5.29, elevation: 0 },
	{ lat: -82, lon: 10.59, elevation: 0 },
	{ lat: -82, lon: 15.88, elevation: 0 },
	{ lat: -82, lon: 21.18, elevation: 0 },
	{ lat: -82, lon: 26.47, elevation: 0 },
	{ lat: -82, lon: 31.76, elevation: 0 },
	{ lat: -82, lon: 37.06, elevation: 0 },
	{ lat: -82, lon: 42.35, elevation: 0 },
	{ lat: -82, lon: 47.65, elevation: 0 },
	{ lat: -82, lon: 52.94, elevation: 0 },
	{ lat: -82, lon: 58.24, elevation: 0 },
	{ lat: -82, lon: 63.53, elevation: 0 },
	{ lat: -82, lon: 68.82, elevation: 0 },
	{ lat: -82, lon: 74.12, elevation: 0 },
	{ lat: -82, lon: 79.41, elevation: 0 },
	{ lat: -82, lon: 84.71, elevation: 0 },
	{ lat: -82, lon: 90.0, elevation: 0 },
	{ lat: -82, lon: 95.29, elevation: 0 },
	{ lat: -82, lon: 100.59, elevation: 0 },
	{ lat: -82, lon: 105.88, elevation: 0 },
	{ lat: -82, lon: 111.18, elevation: 0 },
	{ lat: -82, lon: 116.47, elevation: 0 },
	{ lat: -82, lon: 121.76, elevation: 0 },
	{ lat: -82, lon: 127.06, elevation: 0 },
	{ lat: -82, lon: 132.35, elevation: 0 },
	{ lat: -82, lon: 137.65, elevation: 0 },
	{ lat: -82, lon: 142.94, elevation: 0 },
	{ lat: -82, lon: 148.24, elevation: 0 },
	{ lat: -82, lon: 153.53, elevation: 0 },
	{ lat: -82, lon: 158.82, elevation: 0 },
	{ lat: -81, lon: -147.27, elevation: 0 },
	{ lat: -81, lon: -142.6, elevation: 0 },
	{ lat: -81, lon: -137.92, elevation: 0 },
	{ lat: -81, lon: -133.25, elevation: 0 },
	{ lat: -81, lon: -128.57, elevation: 0 },
	{ lat: -81, lon: -123.9, elevation: 0 },
	{ lat: -81, lon: -119.22, elevation: 0 },
	{ lat: -81, lon: -114.55, elevation: 0 },
	{ lat: -81, lon: -109.87, elevation: 0 },
	{ lat: -81, lon: -105.19, elevation: 0 },
	{ lat: -81, lon: -100.52, elevation: 0 },
	{ lat: -81, lon: -95.84, elevation: 0 },
	{ lat: -81, lon: -91.17, elevation: 0 },
	{ lat: -81, lon: -86.49, elevation: 0 },
	{ lat: -81, lon: -81.82, elevation: 0 },
	{ lat: -81, lon: -77.14, elevation: 0 },
	{ lat: -81, lon: -35.06, elevation: 0 },
	{ lat: -81, lon: -30.39, elevation: 0 },
	{ lat: -81, lon: -25.71, elevation: 0 },
	{ lat: -81, lon: -21.04, elevation: 0 },
	{ lat: -81, lon: -16.36, elevation: 0 },
	{ lat: -81, lon: -11.69, elevation: 0 },
	{ lat: -81, lon: -7.01, elevation: 0 },
	{ lat: -81, lon: -2.34, elevation: 0 },
	{ lat: -81, lon: 2.34, elevation: 0 },
	{ lat: -81, lon: 7.01, elevation: 0 },
	{ lat: -81, lon: 11.69, elevation: 0 },
	{ lat: -81, lon: 16.36, elevation: 0 },
	{ lat: -81, lon: 21.04, elevation: 0 },
	{ lat: -81, lon: 25.71, elevation: 0 },
	{ lat: -81, lon: 30.39, elevation: 0 },
	{ lat: -81, lon: 35.06, elevation: 0 },
	{ lat: -81, lon: 39.74, elevation: 0 },
	{ lat: -81, lon: 44.42, elevation: 0 },
	{ lat: -81, lon: 49.09, elevation: 0 },
	{ lat: -81, lon: 53.77, elevation: 0 },
	{ lat: -81, lon: 58.44, elevation: 0 },
	{ lat: -81, lon: 63.12, elevation: 0 },
	{ lat: -81, lon: 67.79, elevation: 0 },
	{ lat: -81, lon: 72.47, elevation: 0 },
	{ lat: -81, lon: 77.14, elevation: 0 },
	{ lat: -81, lon: 81.82, elevation: 0 },
	{ lat: -81, lon: 86.49, elevation: 0 },
	{ lat: -81, lon: 91.17, elevation: 0 },
	{ lat: -81, lon: 95.84, elevation: 0 },
	{ lat: -81, lon: 100.52, elevation: 0 },
	{ lat: -81, lon: 105.19, elevation: 0 },
	{ lat: -81, lon: 109.87, elevation: 0 },
	{ lat: -81, lon: 114.55, elevation: 0 },
	{ lat: -81, lon: 119.22, elevation: 0 },
	{ lat: -81, lon: 123.9, elevation: 0 },
	{ lat: -81, lon: 128.57, elevation: 0 },
	{ lat: -81, lon: 133.25, elevation: 0 },
	{ lat: -81, lon: 137.92, elevation: 0 },
	{ lat: -81, lon: 142.6, elevation: 0 },
	{ lat: -81, lon: 147.27, elevation: 0 },
	{ lat: -81, lon: 151.95, elevation: 0 },
	{ lat: -81, lon: 156.62, elevation: 0 },
	{ lat: -80, lon: -146.12, elevation: 0 },
	{ lat: -80, lon: -141.88, elevation: 0 },
	{ lat: -80, lon: -137.65, elevation: 0 },
	{ lat: -80, lon: -133.41, elevation: 0 },
	{ lat: -80, lon: -129.18, elevation: 0 },
	{ lat: -80, lon: -124.94, elevation: 0 },
	{ lat: -80, lon: -120.71, elevation: 0 },
	{ lat: -80, lon: -116.47, elevation: 0 },
	{ lat: -80, lon: -112.24, elevation: 0 },
	{ lat: -80, lon: -108.0, elevation: 0 },
	{ lat: -80, lon: -103.76, elevation: 0 },
	{ lat: -80, lon: -99.53, elevation: 0 },
	{ lat: -80, lon: -95.29, elevation: 0 },
	{ lat: -80, lon: -91.06, elevation: 0 },
	{ lat: -80, lon: -86.82, elevation: 0 },
	{ lat: -80, lon: -82.59, elevation: 0 },
	{ lat: -80, lon: -78.35, elevation: 0 },
	{ lat: -80, lon: -48.71, elevation: 0 },
	{ lat: -80, lon: -44.47, elevation: 0 },
	{ lat: -80, lon: -27.53, elevation: 0 },
	{ lat: -80, lon: -23.29, elevation: 0 },
	{ lat: -80, lon: -19.06, elevation: 0 },
	{ lat: -80, lon: -14.82, elevation: 0 },
	{ lat: -80, lon: -10.59, elevation: 0 },
	{ lat: -80, lon: -6.35, elevation: 0 },
	{ lat: -80, lon: -2.12, elevation: 0 },
	{ lat: -80, lon: 2.12, elevation: 0 },
	{ lat: -80, lon: 6.35, elevation: 0 },
	{ lat: -80, lon: 10.59, elevation: 0 },
	{ lat: -80, lon: 14.82, elevation: 0 },
	{ lat: -80, lon: 19.06, elevation: 0 },
	{ lat: -80, lon: 23.29, elevation: 0 },
	{ lat: -80, lon: 27.53, elevation: 0 },
	{ lat: -80, lon: 31.76, elevation: 0 },
	{ lat: -80, lon: 36.0, elevation: 0 },
	{ lat: -80, lon: 40.24, elevation: 0 },
	{ lat: -80, lon: 44.47, elevation: 0 },
	{ lat: -80, lon: 48.71, elevation: 0 },
	{ lat: -80, lon: 52.94, elevation: 0 },
	{ lat: -80, lon: 57.18, elevation: 0 },
	{ lat: -80, lon: 61.41, elevation: 0 },
	{ lat: -80, lon: 65.65, elevation: 0 },
	{ lat: -80, lon: 69.88, elevation: 0 },
	{ lat: -80, lon: 74.12, elevation: 0 },
	{ lat: -80, lon: 78.35, elevation: 0 },
	{ lat: -80, lon: 82.59, elevation: 0 },
	{ lat: -80, lon: 86.82, elevation: 0 },
	{ lat: -80, lon: 91.06, elevation: 0 },
	{ lat: -80, lon: 95.29, elevation: 0 },
	{ lat: -80, lon: 99.53, elevation: 0 },
	{ lat: -80, lon: 103.76, elevation: 0 },
	{ lat: -80, lon: 108.0, elevation: 0 },
	{ lat: -80, lon: 112.24, elevation: 0 },
	{ lat: -80, lon: 116.47, elevation: 0 },
	{ lat: -80, lon: 120.71, elevation: 0 },
	{ lat: -80, lon: 124.94, elevation: 0 },
	{ lat: -80, lon: 129.18, elevation: 0 },
	{ lat: -80, lon: 133.41, elevation: 0 },
	{ lat: -80, lon: 137.65, elevation: 0 },
	{ lat: -80, lon: 141.88, elevation: 0 },
	{ lat: -80, lon: 146.12, elevation: 0 },
	{ lat: -80, lon: 150.35, elevation: 0 },
	{ lat: -80, lon: 154.59, elevation: 0 },
	{ lat: -80, lon: 158.82, elevation: 0 },
	{ lat: -79, lon: -153.19, elevation: 0 },
	{ lat: -79, lon: -149.36, elevation: 0 },
	{ lat: -79, lon: -145.53, elevation: 0 },
	{ lat: -79, lon: -141.7, elevation: 0 },
	{ lat: -79, lon: -137.87, elevation: 0 },
	{ lat: -79, lon: -134.04, elevation: 0 },
	{ lat: -79, lon: -130.21, elevation: 0 },
	{ lat: -79, lon: -126.38, elevation: 0 },
	{ lat: -79, lon: -122.55, elevation: 0 },
	{ lat: -79, lon: -118.72, elevation: 0 },
	{ lat: -79, lon: -114.89, elevation: 0 },
	{ lat: -79, lon: -111.06, elevation: 0 },
	{ lat: -79, lon: -107.23, elevation: 0 },
	{ lat: -79, lon: -103.4, elevation: 0 },
	{ lat: -79, lon: -99.57, elevation: 0 },
	{ lat: -79, lon: -95.74, elevation: 0 },
	{ lat: -79, lon: -91.91, elevation: 0 },
	{ lat: -79, lon: -88.09, elevation: 0 },
	{ lat: -79, lon: -84.26, elevation: 0 },
	{ lat: -79, lon: -68.94, elevation: 0 },
	{ lat: -79, lon: -49.79, elevation: 0 },
	{ lat: -79, lon: -45.96, elevation: 0 },
	{ lat: -79, lon: -34.47, elevation: 0 },
	{ lat: -79, lon: -30.64, elevation: 0 },
	{ lat: -79, lon: -26.81, elevation: 0 },
	{ lat: -79, lon: -22.98, elevation: 0 },
	{ lat: -79, lon: -19.15, elevation: 0 },
	{ lat: -79, lon: -15.32, elevation: 0 },
	{ lat: -79, lon: -11.49, elevation: 0 },
	{ lat: -79, lon: -7.66, elevation: 0 },
	{ lat: -79, lon: -3.83, elevation: 0 },
	{ lat: -79, lon: 0.0, elevation: 0 },
	{ lat: -79, lon: 3.83, elevation: 0 },
	{ lat: -79, lon: 7.66, elevation: 0 },
	{ lat: -79, lon: 11.49, elevation: 0 },
	{ lat: -79, lon: 15.32, elevation: 0 },
	{ lat: -79, lon: 19.15, elevation: 0 },
	{ lat: -79, lon: 22.98, elevation: 0 },
	{ lat: -79, lon: 26.81, elevation: 0 },
	{ lat: -79, lon: 30.64, elevation: 0 },
	{ lat: -79, lon: 34.47, elevation: 0 },
	{ lat: -79, lon: 38.3, elevation: 0 },
	{ lat: -79, lon: 42.13, elevation: 0 },
	{ lat: -79, lon: 45.96, elevation: 0 },
	{ lat: -79, lon: 49.79, elevation: 0 },
	{ lat: -79, lon: 53.62, elevation: 0 },
	{ lat: -79, lon: 57.45, elevation: 0 },
	{ lat: -79, lon: 61.28, elevation: 0 },
	{ lat: -79, lon: 65.11, elevation: 0 },
	{ lat: -79, lon: 68.94, elevation: 0 },
	{ lat: -79, lon: 72.77, elevation: 0 },
	{ lat: -79, lon: 76.6, elevation: 0 },
	{ lat: -79, lon: 80.43, elevation: 0 },
	{ lat: -79, lon: 84.26, elevation: 0 },
	{ lat: -79, lon: 88.09, elevation: 0 },
	{ lat: -79, lon: 91.91, elevation: 0 },
	{ lat: -79, lon: 95.74, elevation: 0 },
	{ lat: -79, lon: 99.57, elevation: 0 },
	{ lat: -79, lon: 103.4, elevation: 0 },
	{ lat: -79, lon: 107.23, elevation: 0 },
	{ lat: -79, lon: 111.06, elevation: 0 },
	{ lat: -79, lon: 114.89, elevation: 0 },
	{ lat: -79, lon: 118.72, elevation: 0 },
	{ lat: -79, lon: 122.55, elevation: 0 },
	{ lat: -79, lon: 126.38, elevation: 0 },
	{ lat: -79, lon: 130.21, elevation: 0 },
	{ lat: -79, lon: 134.04, elevation: 0 },
	{ lat: -79, lon: 137.87, elevation: 0 },
	{ lat: -79, lon: 141.7, elevation: 0 },
	{ lat: -79, lon: 145.53, elevation: 0 },
	{ lat: -79, lon: 149.36, elevation: 0 },
	{ lat: -79, lon: 153.19, elevation: 0 },
	{ lat: -79, lon: 157.02, elevation: 0 },
	{ lat: -79, lon: 160.85, elevation: 0 },
	{ lat: -78, lon: -155.29, elevation: 0 },
	{ lat: -78, lon: -151.76, elevation: 0 },
	{ lat: -78, lon: -148.24, elevation: 0 },
	{ lat: -78, lon: -144.71, elevation: 0 },
	{ lat: -78, lon: -141.18, elevation: 0 },
	{ lat: -78, lon: -137.65, elevation: 0 },
	{ lat: -78, lon: -134.12, elevation: 0 },
	{ lat: -78, lon: -130.59, elevation: 0 },
	{ lat: -78, lon: -127.06, elevation: 0 },
	{ lat: -78, lon: -123.53, elevation: 0 },
	{ lat: -78, lon: -120.0, elevation: 0 },
	{ lat: -78, lon: -116.47, elevation: 0 },
	{ lat: -78, lon: -112.94, elevation: 0 },
	{ lat: -78, lon: -109.41, elevation: 0 },
	{ lat: -78, lon: -105.88, elevation: 0 },
	{ lat: -78, lon: -102.35, elevation: 0 },
	{ lat: -78, lon: -98.82, elevation: 0 },
	{ lat: -78, lon: -95.29, elevation: 0 },
	{ lat: -78, lon: -91.76, elevation: 0 },
	{ lat: -78, lon: -88.24, elevation: 0 },
	{ lat: -78, lon: -84.71, elevation: 0 },
	{ lat: -78, lon: -81.18, elevation: 0 },
	{ lat: -78, lon: -74.12, elevation: 0 },
	{ lat: -78, lon: -45.88, elevation: 0 },
	{ lat: -78, lon: -35.29, elevation: 0 },
	{ lat: -78, lon: -31.76, elevation: 0 },
	{ lat: -78, lon: -28.24, elevation: 0 },
	{ lat: -78, lon: -24.71, elevation: 0 },
	{ lat: -78, lon: -21.18, elevation: 0 },
	{ lat: -78, lon: -17.65, elevation: 0 },
	{ lat: -78, lon: -14.12, elevation: 0 },
	{ lat: -78, lon: -10.59, elevation: 0 },
	{ lat: -78, lon: -7.06, elevation: 0 },
	{ lat: -78, lon: -3.53, elevation: 0 },
	{ lat: -78, lon: 0.0, elevation: 0 },
	{ lat: -78, lon: 3.53, elevation: 0 },
	{ lat: -78, lon: 7.06, elevation: 0 },
	{ lat: -78, lon: 10.59, elevation: 0 },
	{ lat: -78, lon: 14.12, elevation: 0 },
	{ lat: -78, lon: 17.65, elevation: 0 },
	{ lat: -78, lon: 21.18, elevation: 0 },
	{ lat: -78, lon: 24.71, elevation: 0 },
	{ lat: -78, lon: 28.24, elevation: 0 },
	{ lat: -78, lon: 31.76, elevation: 0 },
	{ lat: -78, lon: 35.29, elevation: 0 },
	{ lat: -78, lon: 38.82, elevation: 0 },
	{ lat: -78, lon: 42.35, elevation: 0 },
	{ lat: -78, lon: 45.88, elevation: 0 },
	{ lat: -78, lon: 49.41, elevation: 0 },
	{ lat: -78, lon: 52.94, elevation: 0 },
	{ lat: -78, lon: 56.47, elevation: 0 },
	{ lat: -78, lon: 60.0, elevation: 0 },
	{ lat: -78, lon: 63.53, elevation: 0 },
	{ lat: -78, lon: 67.06, elevation: 0 },
	{ lat: -78, lon: 70.59, elevation: 0 },
	{ lat: -78, lon: 74.12, elevation: 0 },
	{ lat: -78, lon: 77.65, elevation: 0 },
	{ lat: -78, lon: 81.18, elevation: 0 },
	{ lat: -78, lon: 84.71, elevation: 0 },
	{ lat: -78, lon: 88.24, elevation: 0 },
	{ lat: -78, lon: 91.76, elevation: 0 },
	{ lat: -78, lon: 95.29, elevation: 0 },
	{ lat: -78, lon: 98.82, elevation: 0 },
	{ lat: -78, lon: 102.35, elevation: 0 },
	{ lat: -78, lon: 105.88, elevation: 0 },
	{ lat: -78, lon: 109.41, elevation: 0 },
	{ lat: -78, lon: 112.94, elevation: 0 },
	{ lat: -78, lon: 116.47, elevation: 0 },
	{ lat: -78, lon: 120.0, elevation: 0 },
	{ lat: -78, lon: 123.53, elevation: 0 },
	{ lat: -78, lon: 127.06, elevation: 0 },
	{ lat: -78, lon: 130.59, elevation: 0 },
	{ lat: -78, lon: 134.12, elevation: 0 },
	{ lat: -78, lon: 137.65, elevation: 0 },
	{ lat: -78, lon: 141.18, elevation: 0 },
	{ lat: -78, lon: 144.71, elevation: 0 },
	{ lat: -78, lon: 148.24, elevation: 0 },
	{ lat: -78, lon: 151.76, elevation: 0 },
	{ lat: -78, lon: 155.29, elevation: 0 },
	{ lat: -78, lon: 158.82, elevation: 0 },
	{ lat: -78, lon: 162.35, elevation: 0 },
	{ lat: -77, lon: -144.32, elevation: 0 },
	{ lat: -77, lon: -141.08, elevation: 0 },
	{ lat: -77, lon: -137.84, elevation: 0 },
	{ lat: -77, lon: -134.59, elevation: 0 },
	{ lat: -77, lon: -131.35, elevation: 0 },
	{ lat: -77, lon: -128.11, elevation: 0 },
	{ lat: -77, lon: -124.86, elevation: 0 },
	{ lat: -77, lon: -121.62, elevation: 0 },
	{ lat: -77, lon: -118.38, elevation: 0 },
	{ lat: -77, lon: -115.14, elevation: 0 },
	{ lat: -77, lon: -111.89, elevation: 0 },
	{ lat: -77, lon: -108.65, elevation: 0 },
	{ lat: -77, lon: -105.41, elevation: 0 },
	{ lat: -77, lon: -102.16, elevation: 0 },
	{ lat: -77, lon: -98.92, elevation: 0 },
	{ lat: -77, lon: -95.68, elevation: 0 },
	{ lat: -77, lon: -92.43, elevation: 0 },
	{ lat: -77, lon: -89.19, elevation: 0 },
	{ lat: -77, lon: -85.95, elevation: 0 },
	{ lat: -77, lon: -82.7, elevation: 0 },
	{ lat: -77, lon: -79.46, elevation: 0 },
	{ lat: -77, lon: -30.81, elevation: 0 },
	{ lat: -77, lon: -27.57, elevation: 0 },
	{ lat: -77, lon: -24.32, elevation: 0 },
	{ lat: -77, lon: -21.08, elevation: 0 },
	{ lat: -77, lon: -17.84, elevation: 0 },
	{ lat: -77, lon: -14.59, elevation: 0 },
	{ lat: -77, lon: -11.35, elevation: 0 },
	{ lat: -77, lon: -8.11, elevation: 0 },
	{ lat: -77, lon: -4.86, elevation: 0 },
	{ lat: -77, lon: -1.62, elevation: 0 },
	{ lat: -77, lon: 1.62, elevation: 0 },
	{ lat: -77, lon: 4.86, elevation: 0 },
	{ lat: -77, lon: 8.11, elevation: 0 },
	{ lat: -77, lon: 11.35, elevation: 0 },
	{ lat: -77, lon: 14.59, elevation: 0 },
	{ lat: -77, lon: 17.84, elevation: 0 },
	{ lat: -77, lon: 21.08, elevation: 0 },
	{ lat: -77, lon: 24.32, elevation: 0 },
	{ lat: -77, lon: 27.57, elevation: 0 },
	{ lat: -77, lon: 30.81, elevation: 0 },
	{ lat: -77, lon: 34.05, elevation: 0 },
	{ lat: -77, lon: 37.3, elevation: 0 },
	{ lat: -77, lon: 40.54, elevation: 0 },
	{ lat: -77, lon: 43.78, elevation: 0 },
	{ lat: -77, lon: 47.03, elevation: 0 },
	{ lat: -77, lon: 50.27, elevation: 0 },
	{ lat: -77, lon: 53.51, elevation: 0 },
	{ lat: -77, lon: 56.76, elevation: 0 },
	{ lat: -77, lon: 60.0, elevation: 0 },
	{ lat: -77, lon: 63.24, elevation: 0 },
	{ lat: -77, lon: 66.49, elevation: 0 },
	{ lat: -77, lon: 69.73, elevation: 0 },
	{ lat: -77, lon: 72.97, elevation: 0 },
	{ lat: -77, lon: 76.22, elevation: 0 },
	{ lat: -77, lon: 79.46, elevation: 0 },
	{ lat: -77, lon: 82.7, elevation: 0 },
	{ lat: -77, lon: 85.95, elevation: 0 },
	{ lat: -77, lon: 89.19, elevation: 0 },
	{ lat: -77, lon: 92.43, elevation: 0 },
	{ lat: -77, lon: 95.68, elevation: 0 },
	{ lat: -77, lon: 98.92, elevation: 0 },
	{ lat: -77, lon: 102.16, elevation: 0 },
	{ lat: -77, lon: 105.41, elevation: 0 },
	{ lat: -77, lon: 108.65, elevation: 0 },
	{ lat: -77, lon: 111.89, elevation: 0 },
	{ lat: -77, lon: 115.14, elevation: 0 },
	{ lat: -77, lon: 118.38, elevation: 0 },
	{ lat: -77, lon: 121.62, elevation: 0 },
	{ lat: -77, lon: 124.86, elevation: 0 },
	{ lat: -77, lon: 128.11, elevation: 0 },
	{ lat: -77, lon: 131.35, elevation: 0 },
	{ lat: -77, lon: 134.59, elevation: 0 },
	{ lat: -77, lon: 137.84, elevation: 0 },
	{ lat: -77, lon: 141.08, elevation: 0 },
	{ lat: -77, lon: 144.32, elevation: 0 },
	{ lat: -77, lon: 147.57, elevation: 0 },
	{ lat: -77, lon: 150.81, elevation: 0 },
	{ lat: -77, lon: 154.05, elevation: 0 },
	{ lat: -77, lon: 157.3, elevation: 0 },
	{ lat: -77, lon: 160.54, elevation: 0 },
	{ lat: -76, lon: -143.7, elevation: 0 },
	{ lat: -76, lon: -140.67, elevation: 0 },
	{ lat: -76, lon: -137.65, elevation: 0 },
	{ lat: -76, lon: -134.62, elevation: 0 },
	{ lat: -76, lon: -131.6, elevation: 0 },
	{ lat: -76, lon: -128.57, elevation: 0 },
	{ lat: -76, lon: -125.55, elevation: 0 },
	{ lat: -76, lon: -122.52, elevation: 0 },
	{ lat: -76, lon: -119.5, elevation: 0 },
	{ lat: -76, lon: -116.47, elevation: 0 },
	{ lat: -76, lon: -113.45, elevation: 0 },
	{ lat: -76, lon: -110.42, elevation: 0 },
	{ lat: -76, lon: -107.39, elevation: 0 },
	{ lat: -76, lon: -104.37, elevation: 0 },
	{ lat: -76, lon: -101.34, elevation: 0 },
	{ lat: -76, lon: -98.32, elevation: 0 },
	{ lat: -76, lon: -95.29, elevation: 0 },
	{ lat: -76, lon: -92.27, elevation: 0 },
	{ lat: -76, lon: -89.24, elevation: 0 },
	{ lat: -76, lon: -86.22, elevation: 0 },
	{ lat: -76, lon: -83.19, elevation: 0 },
	{ lat: -76, lon: -80.17, elevation: 0 },
	{ lat: -76, lon: -77.14, elevation: 0 },
	{ lat: -76, lon: -74.12, elevation: 0 },
	{ lat: -76, lon: -71.09, elevation: 0 },
	{ lat: -76, lon: -68.07, elevation: 0 },
	{ lat: -76, lon: -25.71, elevation: 0 },
	{ lat: -76, lon: -22.69, elevation: 0 },
	{ lat: -76, lon: -19.66, elevation: 0 },
	{ lat: -76, lon: -16.64, elevation: 0 },
	{ lat: -76, lon: -13.61, elevation: 0 },
	{ lat: -76, lon: -10.59, elevation: 0 },
	{ lat: -76, lon: -7.56, elevation: 0 },
	{ lat: -76, lon: -4.54, elevation: 0 },
	{ lat: -76, lon: -1.51, elevation: 0 },
	{ lat: -76, lon: 1.51, elevation: 0 },
	{ lat: -76, lon: 4.54, elevation: 0 },
	{ lat: -76, lon: 7.56, elevation: 0 },
	{ lat: -76, lon: 10.59, elevation: 0 },
	{ lat: -76, lon: 13.61, elevation: 0 },
	{ lat: -76, lon: 16.64, elevation: 0 },
	{ lat: -76, lon: 19.66, elevation: 0 },
	{ lat: -76, lon: 22.69, elevation: 0 },
	{ lat: -76, lon: 25.71, elevation: 0 },
	{ lat: -76, lon: 28.74, elevation: 0 },
	{ lat: -76, lon: 31.76, elevation: 0 },
	{ lat: -76, lon: 34.79, elevation: 0 },
	{ lat: -76, lon: 37.82, elevation: 0 },
	{ lat: -76, lon: 40.84, elevation: 0 },
	{ lat: -76, lon: 43.87, elevation: 0 },
	{ lat: -76, lon: 46.89, elevation: 0 },
	{ lat: -76, lon: 49.92, elevation: 0 },
	{ lat: -76, lon: 52.94, elevation: 0 },
	{ lat: -76, lon: 55.97, elevation: 0 },
	{ lat: -76, lon: 58.99, elevation: 0 },
	{ lat: -76, lon: 62.02, elevation: 0 },
	{ lat: -76, lon: 65.04, elevation: 0 },
	{ lat: -76, lon: 68.07, elevation: 0 },
	{ lat: -76, lon: 71.09, elevation: 0 },
	{ lat: -76, lon: 74.12, elevation: 0 },
	{ lat: -76, lon: 77.14, elevation: 0 },
	{ lat: -76, lon: 80.17, elevation: 0 },
	{ lat: -76, lon: 83.19, elevation: 0 },
	{ lat: -76, lon: 86.22, elevation: 0 },
	{ lat: -76, lon: 89.24, elevation: 0 },
	{ lat: -76, lon: 92.27, elevation: 0 },
	{ lat: -76, lon: 95.29, elevation: 0 },
	{ lat: -76, lon: 98.32, elevation: 0 },
	{ lat: -76, lon: 101.34, elevation: 0 },
	{ lat: -76, lon: 104.37, elevation: 0 },
	{ lat: -76, lon: 107.39, elevation: 0 },
	{ lat: -76, lon: 110.42, elevation: 0 },
	{ lat: -76, lon: 113.45, elevation: 0 },
	{ lat: -76, lon: 116.47, elevation: 0 },
	{ lat: -76, lon: 119.5, elevation: 0 },
	{ lat: -76, lon: 122.52, elevation: 0 },
	{ lat: -76, lon: 125.55, elevation: 0 },
	{ lat: -76, lon: 128.57, elevation: 0 },
	{ lat: -76, lon: 131.6, elevation: 0 },
	{ lat: -76, lon: 134.62, elevation: 0 },
	{ lat: -76, lon: 137.65, elevation: 0 },
	{ lat: -76, lon: 140.67, elevation: 0 },
	{ lat: -76, lon: 143.7, elevation: 0 },
	{ lat: -76, lon: 146.72, elevation: 0 },
	{ lat: -76, lon: 149.75, elevation: 0 },
	{ lat: -76, lon: 152.77, elevation: 0 },
	{ lat: -76, lon: 155.8, elevation: 0 },
	{ lat: -76, lon: 158.82, elevation: 0 },
	{ lat: -76, lon: 161.85, elevation: 0 },
	{ lat: -75, lon: -135.0, elevation: 0 },
	{ lat: -75, lon: -132.19, elevation: 0 },
	{ lat: -75, lon: -129.38, elevation: 0 },
	{ lat: -75, lon: -126.56, elevation: 0 },
	{ lat: -75, lon: -123.75, elevation: 0 },
	{ lat: -75, lon: -120.94, elevation: 0 },
	{ lat: -75, lon: -118.12, elevation: 0 },
	{ lat: -75, lon: -115.31, elevation: 0 },
	{ lat: -75, lon: -112.5, elevation: 0 },
	{ lat: -75, lon: -98.44, elevation: 0 },
	{ lat: -75, lon: -95.62, elevation: 0 },
	{ lat: -75, lon: -92.81, elevation: 0 },
	{ lat: -75, lon: -90.0, elevation: 0 },
	{ lat: -75, lon: -87.19, elevation: 0 },
	{ lat: -75, lon: -84.38, elevation: 0 },
	{ lat: -75, lon: -81.56, elevation: 0 },
	{ lat: -75, lon: -78.75, elevation: 0 },
	{ lat: -75, lon: -75.94, elevation: 0 },
	{ lat: -75, lon: -73.12, elevation: 0 },
	{ lat: -75, lon: -70.31, elevation: 0 },
	{ lat: -75, lon: -67.5, elevation: 0 },
	{ lat: -75, lon: -64.69, elevation: 0 },
	{ lat: -75, lon: -16.88, elevation: 0 },
	{ lat: -75, lon: -14.06, elevation: 0 },
	{ lat: -75, lon: -11.25, elevation: 0 },
	{ lat: -75, lon: -8.44, elevation: 0 },
	{ lat: -75, lon: -5.62, elevation: 0 },
	{ lat: -75, lon: -2.81, elevation: 0 },
	{ lat: -75, lon: 0.0, elevation: 0 },
	{ lat: -75, lon: 2.81, elevation: 0 },
	{ lat: -75, lon: 5.62, elevation: 0 },
	{ lat: -75, lon: 8.44, elevation: 0 },
	{ lat: -75, lon: 11.25, elevation: 0 },
	{ lat: -75, lon: 14.06, elevation: 0 },
	{ lat: -75, lon: 16.88, elevation: 0 },
	{ lat: -75, lon: 19.69, elevation: 0 },
	{ lat: -75, lon: 22.5, elevation: 0 },
	{ lat: -75, lon: 25.31, elevation: 0 },
	{ lat: -75, lon: 28.12, elevation: 0 },
	{ lat: -75, lon: 30.94, elevation: 0 },
	{ lat: -75, lon: 33.75, elevation: 0 },
	{ lat: -75, lon: 36.56, elevation: 0 },
	{ lat: -75, lon: 39.38, elevation: 0 },
	{ lat: -75, lon: 42.19, elevation: 0 },
	{ lat: -75, lon: 45.0, elevation: 0 },
	{ lat: -75, lon: 47.81, elevation: 0 },
	{ lat: -75, lon: 50.62, elevation: 0 },
	{ lat: -75, lon: 53.44, elevation: 0 },
	{ lat: -75, lon: 56.25, elevation: 0 },
	{ lat: -75, lon: 59.06, elevation: 0 },
	{ lat: -75, lon: 61.88, elevation: 0 },
	{ lat: -75, lon: 64.69, elevation: 0 },
	{ lat: -75, lon: 67.5, elevation: 0 },
	{ lat: -75, lon: 70.31, elevation: 0 },
	{ lat: -75, lon: 73.12, elevation: 0 },
	{ lat: -75, lon: 75.94, elevation: 0 },
	{ lat: -75, lon: 78.75, elevation: 0 },
	{ lat: -75, lon: 81.56, elevation: 0 },
	{ lat: -75, lon: 84.38, elevation: 0 },
	{ lat: -75, lon: 87.19, elevation: 0 },
	{ lat: -75, lon: 90.0, elevation: 0 },
	{ lat: -75, lon: 92.81, elevation: 0 },
	{ lat: -75, lon: 95.62, elevation: 0 },
	{ lat: -75, lon: 98.44, elevation: 0 },
	{ lat: -75, lon: 101.25, elevation: 0 },
	{ lat: -75, lon: 104.06, elevation: 0 },
	{ lat: -75, lon: 106.88, elevation: 0 },
	{ lat: -75, lon: 109.69, elevation: 0 },
	{ lat: -75, lon: 112.5, elevation: 0 },
	{ lat: -75, lon: 115.31, elevation: 0 },
	{ lat: -75, lon: 118.12, elevation: 0 },
	{ lat: -75, lon: 120.94, elevation: 0 },
	{ lat: -75, lon: 123.75, elevation: 0 },
	{ lat: -75, lon: 126.56, elevation: 0 },
	{ lat: -75, lon: 129.38, elevation: 0 },
	{ lat: -75, lon: 132.19, elevation: 0 },
	{ lat: -75, lon: 135.0, elevation: 0 },
	{ lat: -75, lon: 137.81, elevation: 0 },
	{ lat: -75, lon: 140.62, elevation: 0 },
	{ lat: -75, lon: 143.44, elevation: 0 },
	{ lat: -75, lon: 146.25, elevation: 0 },
	{ lat: -75, lon: 149.06, elevation: 0 },
	{ lat: -75, lon: 151.88, elevation: 0 },
	{ lat: -75, lon: 154.69, elevation: 0 },
	{ lat: -75, lon: 157.5, elevation: 0 },
	{ lat: -75, lon: 160.31, elevation: 0 },
	{ lat: -74, lon: -124.41, elevation: 0 },
	{ lat: -74, lon: -121.76, elevation: 0 },
	{ lat: -74, lon: -119.12, elevation: 0 },
	{ lat: -74, lon: -116.47, elevation: 0 },
	{ lat: -74, lon: -100.59, elevation: 0 },
	{ lat: -74, lon: -97.94, elevation: 0 },
	{ lat: -74, lon: -95.29, elevation: 0 },
	{ lat: -74, lon: -92.65, elevation: 0 },
	{ lat: -74, lon: -90.0, elevation: 0 },
	{ lat: -74, lon: -87.35, elevation: 0 },
	{ lat: -74, lon: -84.71, elevation: 0 },
	{ lat: -74, lon: -82.06, elevation: 0 },
	{ lat: -74, lon: -79.41, elevation: 0 },
	{ lat: -74, lon: -76.76, elevation: 0 },
	{ lat: -74, lon: -74.12, elevation: 0 },
	{ lat: -74, lon: -71.47, elevation: 0 },
	{ lat: -74, lon: -68.82, elevation: 0 },
	{ lat: -74, lon: -66.18, elevation: 0 },
	{ lat: -74, lon: -63.53, elevation: 0 },
	{ lat: -74, lon: -13.24, elevation: 0 },
	{ lat: -74, lon: -10.59, elevation: 0 },
	{ lat: -74, lon: -7.94, elevation: 0 },
	{ lat: -74, lon: -5.29, elevation: 0 },
	{ lat: -74, lon: -2.65, elevation: 0 },
	{ lat: -74, lon: 0.0, elevation: 0 },
	{ lat: -74, lon: 2.65, elevation: 0 },
	{ lat: -74, lon: 5.29, elevation: 0 },
	{ lat: -74, lon: 7.94, elevation: 0 },
	{ lat: -74, lon: 10.59, elevation: 0 },
	{ lat: -74, lon: 13.24, elevation: 0 },
	{ lat: -74, lon: 15.88, elevation: 0 },
	{ lat: -74, lon: 18.53, elevation: 0 },
	{ lat: -74, lon: 21.18, elevation: 0 },
	{ lat: -74, lon: 23.82, elevation: 0 },
	{ lat: -74, lon: 26.47, elevation: 0 },
	{ lat: -74, lon: 29.12, elevation: 0 },
	{ lat: -74, lon: 31.76, elevation: 0 },
	{ lat: -74, lon: 34.41, elevation: 0 },
	{ lat: -74, lon: 37.06, elevation: 0 },
	{ lat: -74, lon: 39.71, elevation: 0 },
	{ lat: -74, lon: 42.35, elevation: 0 },
	{ lat: -74, lon: 45.0, elevation: 0 },
	{ lat: -74, lon: 47.65, elevation: 0 },
	{ lat: -74, lon: 50.29, elevation: 0 },
	{ lat: -74, lon: 52.94, elevation: 0 },
	{ lat: -74, lon: 55.59, elevation: 0 },
	{ lat: -74, lon: 58.24, elevation: 0 },
	{ lat: -74, lon: 60.88, elevation: 0 },
	{ lat: -74, lon: 63.53, elevation: 0 },
	{ lat: -74, lon: 66.18, elevation: 0 },
	{ lat: -74, lon: 68.82, elevation: 0 },
	{ lat: -74, lon: 71.47, elevation: 0 },
	{ lat: -74, lon: 74.12, elevation: 0 },
	{ lat: -74, lon: 76.76, elevation: 0 },
	{ lat: -74, lon: 79.41, elevation: 0 },
	{ lat: -74, lon: 82.06, elevation: 0 },
	{ lat: -74, lon: 84.71, elevation: 0 },
	{ lat: -74, lon: 87.35, elevation: 0 },
	{ lat: -74, lon: 90.0, elevation: 0 },
	{ lat: -74, lon: 92.65, elevation: 0 },
	{ lat: -74, lon: 95.29, elevation: 0 },
	{ lat: -74, lon: 97.94, elevation: 0 },
	{ lat: -74, lon: 100.59, elevation: 0 },
	{ lat: -74, lon: 103.24, elevation: 0 },
	{ lat: -74, lon: 105.88, elevation: 0 },
	{ lat: -74, lon: 108.53, elevation: 0 },
	{ lat: -74, lon: 111.18, elevation: 0 },
	{ lat: -74, lon: 113.82, elevation: 0 },
	{ lat: -74, lon: 116.47, elevation: 0 },
	{ lat: -74, lon: 119.12, elevation: 0 },
	{ lat: -74, lon: 121.76, elevation: 0 },
	{ lat: -74, lon: 124.41, elevation: 0 },
	{ lat: -74, lon: 127.06, elevation: 0 },
	{ lat: -74, lon: 129.71, elevation: 0 },
	{ lat: -74, lon: 132.35, elevation: 0 },
	{ lat: -74, lon: 135.0, elevation: 0 },
	{ lat: -74, lon: 137.65, elevation: 0 },
	{ lat: -74, lon: 140.29, elevation: 0 },
	{ lat: -74, lon: 142.94, elevation: 0 },
	{ lat: -74, lon: 145.59, elevation: 0 },
	{ lat: -74, lon: 148.24, elevation: 0 },
	{ lat: -74, lon: 150.88, elevation: 0 },
	{ lat: -74, lon: 153.53, elevation: 0 },
	{ lat: -74, lon: 156.18, elevation: 0 },
	{ lat: -74, lon: 158.82, elevation: 0 },
	{ lat: -74, lon: 161.47, elevation: 0 },
	{ lat: -74, lon: 164.12, elevation: 0 },
	{ lat: -73, lon: -103.03, elevation: 0 },
	{ lat: -73, lon: -68.28, elevation: 0 },
	{ lat: -73, lon: -65.79, elevation: 0 },
	{ lat: -73, lon: -63.31, elevation: 0 },
	{ lat: -73, lon: -60.83, elevation: 0 },
	{ lat: -73, lon: -13.66, elevation: 0 },
	{ lat: -73, lon: -11.17, elevation: 0 },
	{ lat: -73, lon: -8.69, elevation: 0 },
	{ lat: -73, lon: -6.21, elevation: 0 },
	{ lat: -73, lon: -3.72, elevation: 0 },
	{ lat: -73, lon: -1.24, elevation: 0 },
	{ lat: -73, lon: 1.24, elevation: 0 },
	{ lat: -73, lon: 3.72, elevation: 0 },
	{ lat: -73, lon: 6.21, elevation: 0 },
	{ lat: -73, lon: 8.69, elevation: 0 },
	{ lat: -73, lon: 11.17, elevation: 0 },
	{ lat: -73, lon: 13.66, elevation: 0 },
	{ lat: -73, lon: 16.14, elevation: 0 },
	{ lat: -73, lon: 18.62, elevation: 0 },
	{ lat: -73, lon: 21.1, elevation: 0 },
	{ lat: -73, lon: 23.59, elevation: 0 },
	{ lat: -73, lon: 26.07, elevation: 0 },
	{ lat: -73, lon: 28.55, elevation: 0 },
	{ lat: -73, lon: 31.03, elevation: 0 },
	{ lat: -73, lon: 33.52, elevation: 0 },
	{ lat: -73, lon: 36.0, elevation: 0 },
	{ lat: -73, lon: 38.48, elevation: 0 },
	{ lat: -73, lon: 40.97, elevation: 0 },
	{ lat: -73, lon: 43.45, elevation: 0 },
	{ lat: -73, lon: 45.93, elevation: 0 },
	{ lat: -73, lon: 48.41, elevation: 0 },
	{ lat: -73, lon: 50.9, elevation: 0 },
	{ lat: -73, lon: 53.38, elevation: 0 },
	{ lat: -73, lon: 55.86, elevation: 0 },
	{ lat: -73, lon: 58.34, elevation: 0 },
	{ lat: -73, lon: 60.83, elevation: 0 },
	{ lat: -73, lon: 63.31, elevation: 0 },
	{ lat: -73, lon: 65.79, elevation: 0 },
	{ lat: -73, lon: 68.28, elevation: 0 },
	{ lat: -73, lon: 70.76, elevation: 0 },
	{ lat: -73, lon: 73.24, elevation: 0 },
	{ lat: -73, lon: 75.72, elevation: 0 },
	{ lat: -73, lon: 78.21, elevation: 0 },
	{ lat: -73, lon: 80.69, elevation: 0 },
	{ lat: -73, lon: 83.17, elevation: 0 },
	{ lat: -73, lon: 85.66, elevation: 0 },
	{ lat: -73, lon: 88.14, elevation: 0 },
	{ lat: -73, lon: 90.62, elevation: 0 },
	{ lat: -73, lon: 93.1, elevation: 0 },
	{ lat: -73, lon: 95.59, elevation: 0 },
	{ lat: -73, lon: 98.07, elevation: 0 },
	{ lat: -73, lon: 100.55, elevation: 0 },
	{ lat: -73, lon: 103.03, elevation: 0 },
	{ lat: -73, lon: 105.52, elevation: 0 },
	{ lat: -73, lon: 108.0, elevation: 0 },
	{ lat: -73, lon: 110.48, elevation: 0 },
	{ lat: -73, lon: 112.97, elevation: 0 },
	{ lat: -73, lon: 115.45, elevation: 0 },
	{ lat: -73, lon: 117.93, elevation: 0 },
	{ lat: -73, lon: 120.41, elevation: 0 },
	{ lat: -73, lon: 122.9, elevation: 0 },
	{ lat: -73, lon: 125.38, elevation: 0 },
	{ lat: -73, lon: 127.86, elevation: 0 },
	{ lat: -73, lon: 130.34, elevation: 0 },
	{ lat: -73, lon: 132.83, elevation: 0 },
	{ lat: -73, lon: 135.31, elevation: 0 },
	{ lat: -73, lon: 137.79, elevation: 0 },
	{ lat: -73, lon: 140.28, elevation: 0 },
	{ lat: -73, lon: 142.76, elevation: 0 },
	{ lat: -73, lon: 145.24, elevation: 0 },
	{ lat: -73, lon: 147.72, elevation: 0 },
	{ lat: -73, lon: 150.21, elevation: 0 },
	{ lat: -73, lon: 152.69, elevation: 0 },
	{ lat: -73, lon: 155.17, elevation: 0 },
	{ lat: -73, lon: 157.66, elevation: 0 },
	{ lat: -73, lon: 160.14, elevation: 0 },
	{ lat: -73, lon: 162.62, elevation: 0 },
	{ lat: -73, lon: 165.1, elevation: 0 },
	{ lat: -73, lon: 167.59, elevation: 0 },
	{ lat: -72, lon: -100.0, elevation: 0 },
	{ lat: -72, lon: -97.65, elevation: 0 },
	{ lat: -72, lon: -74.12, elevation: 0 },
	{ lat: -72, lon: -69.41, elevation: 0 },
	{ lat: -72, lon: -64.71, elevation: 0 },
	{ lat: -72, lon: -62.35, elevation: 0 },
	{ lat: -72, lon: -10.59, elevation: 0 },
	{ lat: -72, lon: -8.24, elevation: 0 },
	{ lat: -72, lon: -5.88, elevation: 0 },
	{ lat: -72, lon: -3.53, elevation: 0 },
	{ lat: -72, lon: -1.18, elevation: 0 },
	{ lat: -72, lon: 1.18, elevation: 0 },
	{ lat: -72, lon: 3.53, elevation: 0 },
	{ lat: -72, lon: 5.88, elevation: 0 },
	{ lat: -72, lon: 8.24, elevation: 0 },
	{ lat: -72, lon: 10.59, elevation: 0 },
	{ lat: -72, lon: 12.94, elevation: 0 },
	{ lat: -72, lon: 15.29, elevation: 0 },
	{ lat: -72, lon: 17.65, elevation: 0 },
	{ lat: -72, lon: 20.0, elevation: 0 },
	{ lat: -72, lon: 22.35, elevation: 0 },
	{ lat: -72, lon: 24.71, elevation: 0 },
	{ lat: -72, lon: 27.06, elevation: 0 },
	{ lat: -72, lon: 29.41, elevation: 0 },
	{ lat: -72, lon: 31.76, elevation: 0 },
	{ lat: -72, lon: 34.12, elevation: 0 },
	{ lat: -72, lon: 36.47, elevation: 0 },
	{ lat: -72, lon: 38.82, elevation: 0 },
	{ lat: -72, lon: 41.18, elevation: 0 },
	{ lat: -72, lon: 43.53, elevation: 0 },
	{ lat: -72, lon: 45.88, elevation: 0 },
	{ lat: -72, lon: 48.24, elevation: 0 },
	{ lat: -72, lon: 50.59, elevation: 0 },
	{ lat: -72, lon: 52.94, elevation: 0 },
	{ lat: -72, lon: 55.29, elevation: 0 },
	{ lat: -72, lon: 57.65, elevation: 0 },
	{ lat: -72, lon: 60.0, elevation: 0 },
	{ lat: -72, lon: 62.35, elevation: 0 },
	{ lat: -72, lon: 64.71, elevation: 0 },
	{ lat: -72, lon: 67.06, elevation: 0 },
	{ lat: -72, lon: 71.76, elevation: 0 },
	{ lat: -72, lon: 74.12, elevation: 0 },
	{ lat: -72, lon: 76.47, elevation: 0 },
	{ lat: -72, lon: 78.82, elevation: 0 },
	{ lat: -72, lon: 81.18, elevation: 0 },
	{ lat: -72, lon: 83.53, elevation: 0 },
	{ lat: -72, lon: 85.88, elevation: 0 },
	{ lat: -72, lon: 88.24, elevation: 0 },
	{ lat: -72, lon: 90.59, elevation: 0 },
	{ lat: -72, lon: 92.94, elevation: 0 },
	{ lat: -72, lon: 95.29, elevation: 0 },
	{ lat: -72, lon: 97.65, elevation: 0 },
	{ lat: -72, lon: 100.0, elevation: 0 },
	{ lat: -72, lon: 102.35, elevation: 0 },
	{ lat: -72, lon: 104.71, elevation: 0 },
	{ lat: -72, lon: 107.06, elevation: 0 },
	{ lat: -72, lon: 109.41, elevation: 0 },
	{ lat: -72, lon: 111.76, elevation: 0 },
	{ lat: -72, lon: 114.12, elevation: 0 },
	{ lat: -72, lon: 116.47, elevation: 0 },
	{ lat: -72, lon: 118.82, elevation: 0 },
	{ lat: -72, lon: 121.18, elevation: 0 },
	{ lat: -72, lon: 123.53, elevation: 0 },
	{ lat: -72, lon: 125.88, elevation: 0 },
	{ lat: -72, lon: 128.24, elevation: 0 },
	{ lat: -72, lon: 130.59, elevation: 0 },
	{ lat: -72, lon: 132.94, elevation: 0 },
	{ lat: -72, lon: 135.29, elevation: 0 },
	{ lat: -72, lon: 137.65, elevation: 0 },
	{ lat: -72, lon: 140.0, elevation: 0 },
	{ lat: -72, lon: 142.35, elevation: 0 },
	{ lat: -72, lon: 144.71, elevation: 0 },
	{ lat: -72, lon: 147.06, elevation: 0 },
	{ lat: -72, lon: 149.41, elevation: 0 },
	{ lat: -72, lon: 151.76, elevation: 0 },
	{ lat: -72, lon: 154.12, elevation: 0 },
	{ lat: -72, lon: 156.47, elevation: 0 },
	{ lat: -72, lon: 158.82, elevation: 0 },
	{ lat: -72, lon: 161.18, elevation: 0 },
	{ lat: -72, lon: 163.53, elevation: 0 },
	{ lat: -72, lon: 165.88, elevation: 0 },
	{ lat: -72, lon: 168.24, elevation: 0 },
	{ lat: -71, lon: -70.43, elevation: 0 },
	{ lat: -71, lon: -65.96, elevation: 0 },
	{ lat: -71, lon: -63.73, elevation: 0 },
	{ lat: -71, lon: -61.49, elevation: 0 },
	{ lat: -71, lon: -7.83, elevation: 0 },
	{ lat: -71, lon: 3.35, elevation: 0 },
	{ lat: -71, lon: 5.59, elevation: 0 },
	{ lat: -71, lon: 7.83, elevation: 0 },
	{ lat: -71, lon: 10.06, elevation: 0 },
	{ lat: -71, lon: 12.3, elevation: 0 },
	{ lat: -71, lon: 14.53, elevation: 0 },
	{ lat: -71, lon: 16.77, elevation: 0 },
	{ lat: -71, lon: 19.01, elevation: 0 },
	{ lat: -71, lon: 21.24, elevation: 0 },
	{ lat: -71, lon: 23.48, elevation: 0 },
	{ lat: -71, lon: 27.95, elevation: 0 },
	{ lat: -71, lon: 30.19, elevation: 0 },
	{ lat: -71, lon: 32.42, elevation: 0 },
	{ lat: -71, lon: 34.66, elevation: 0 },
	{ lat: -71, lon: 36.89, elevation: 0 },
	{ lat: -71, lon: 39.13, elevation: 0 },
	{ lat: -71, lon: 41.37, elevation: 0 },
	{ lat: -71, lon: 43.6, elevation: 0 },
	{ lat: -71, lon: 45.84, elevation: 0 },
	{ lat: -71, lon: 48.07, elevation: 0 },
	{ lat: -71, lon: 50.31, elevation: 0 },
	{ lat: -71, lon: 52.55, elevation: 0 },
	{ lat: -71, lon: 54.78, elevation: 0 },
	{ lat: -71, lon: 57.02, elevation: 0 },
	{ lat: -71, lon: 59.25, elevation: 0 },
	{ lat: -71, lon: 61.49, elevation: 0 },
	{ lat: -71, lon: 63.73, elevation: 0 },
	{ lat: -71, lon: 65.96, elevation: 0 },
	{ lat: -71, lon: 68.2, elevation: 0 },
	{ lat: -71, lon: 72.67, elevation: 0 },
	{ lat: -71, lon: 74.91, elevation: 0 },
	{ lat: -71, lon: 77.14, elevation: 0 },
	{ lat: -71, lon: 79.38, elevation: 0 },
	{ lat: -71, lon: 81.61, elevation: 0 },
	{ lat: -71, lon: 83.85, elevation: 0 },
	{ lat: -71, lon: 86.09, elevation: 0 },
	{ lat: -71, lon: 88.32, elevation: 0 },
	{ lat: -71, lon: 90.56, elevation: 0 },
	{ lat: -71, lon: 92.8, elevation: 0 },
	{ lat: -71, lon: 95.03, elevation: 0 },
	{ lat: -71, lon: 97.27, elevation: 0 },
	{ lat: -71, lon: 99.5, elevation: 0 },
	{ lat: -71, lon: 101.74, elevation: 0 },
	{ lat: -71, lon: 103.98, elevation: 0 },
	{ lat: -71, lon: 106.21, elevation: 0 },
	{ lat: -71, lon: 108.45, elevation: 0 },
	{ lat: -71, lon: 110.68, elevation: 0 },
	{ lat: -71, lon: 112.92, elevation: 0 },
	{ lat: -71, lon: 115.16, elevation: 0 },
	{ lat: -71, lon: 117.39, elevation: 0 },
	{ lat: -71, lon: 119.63, elevation: 0 },
	{ lat: -71, lon: 121.86, elevation: 0 },
	{ lat: -71, lon: 124.1, elevation: 0 },
	{ lat: -71, lon: 126.34, elevation: 0 },
	{ lat: -71, lon: 128.57, elevation: 0 },
	{ lat: -71, lon: 130.81, elevation: 0 },
	{ lat: -71, lon: 133.04, elevation: 0 },
	{ lat: -71, lon: 135.28, elevation: 0 },
	{ lat: -71, lon: 137.52, elevation: 0 },
	{ lat: -71, lon: 139.75, elevation: 0 },
	{ lat: -71, lon: 141.99, elevation: 0 },
	{ lat: -71, lon: 144.22, elevation: 0 },
	{ lat: -71, lon: 146.46, elevation: 0 },
	{ lat: -71, lon: 148.7, elevation: 0 },
	{ lat: -71, lon: 150.93, elevation: 0 },
	{ lat: -71, lon: 153.17, elevation: 0 },
	{ lat: -71, lon: 155.4, elevation: 0 },
	{ lat: -71, lon: 157.64, elevation: 0 },
	{ lat: -71, lon: 159.88, elevation: 0 },
	{ lat: -71, lon: 164.35, elevation: 0 },
	{ lat: -71, lon: 166.58, elevation: 0 },
	{ lat: -70, lon: -69.88, elevation: 0 },
	{ lat: -70, lon: -67.76, elevation: 0 },
	{ lat: -70, lon: -65.65, elevation: 0 },
	{ lat: -70, lon: -63.53, elevation: 0 },
	{ lat: -70, lon: 33.88, elevation: 0 },
	{ lat: -70, lon: 36.0, elevation: 0 },
	{ lat: -70, lon: 38.12, elevation: 0 },
	{ lat: -70, lon: 40.24, elevation: 0 },
	{ lat: -70, lon: 42.35, elevation: 0 },
	{ lat: -70, lon: 44.47, elevation: 0 },
	{ lat: -70, lon: 46.59, elevation: 0 },
	{ lat: -70, lon: 48.71, elevation: 0 },
	{ lat: -70, lon: 50.82, elevation: 0 },
	{ lat: -70, lon: 52.94, elevation: 0 },
	{ lat: -70, lon: 55.06, elevation: 0 },
	{ lat: -70, lon: 57.18, elevation: 0 },
	{ lat: -70, lon: 59.29, elevation: 0 },
	{ lat: -70, lon: 61.41, elevation: 0 },
	{ lat: -70, lon: 63.53, elevation: 0 },
	{ lat: -70, lon: 65.65, elevation: 0 },
	{ lat: -70, lon: 67.76, elevation: 0 },
	{ lat: -70, lon: 74.12, elevation: 0 },
	{ lat: -70, lon: 76.24, elevation: 0 },
	{ lat: -70, lon: 78.35, elevation: 0 },
	{ lat: -70, lon: 80.47, elevation: 0 },
	{ lat: -70, lon: 82.59, elevation: 0 },
	{ lat: -70, lon: 84.71, elevation: 0 },
	{ lat: -70, lon: 86.82, elevation: 0 },
	{ lat: -70, lon: 88.94, elevation: 0 },
	{ lat: -70, lon: 91.06, elevation: 0 },
	{ lat: -70, lon: 93.18, elevation: 0 },
	{ lat: -70, lon: 95.29, elevation: 0 },
	{ lat: -70, lon: 97.41, elevation: 0 },
	{ lat: -70, lon: 99.53, elevation: 0 },
	{ lat: -70, lon: 101.65, elevation: 0 },
	{ lat: -70, lon: 103.76, elevation: 0 },
	{ lat: -70, lon: 105.88, elevation: 0 },
	{ lat: -70, lon: 108.0, elevation: 0 },
	{ lat: -70, lon: 110.12, elevation: 0 },
	{ lat: -70, lon: 112.24, elevation: 0 },
	{ lat: -70, lon: 114.35, elevation: 0 },
	{ lat: -70, lon: 116.47, elevation: 0 },
	{ lat: -70, lon: 118.59, elevation: 0 },
	{ lat: -70, lon: 120.71, elevation: 0 },
	{ lat: -70, lon: 122.82, elevation: 0 },
	{ lat: -70, lon: 124.94, elevation: 0 },
	{ lat: -70, lon: 127.06, elevation: 0 },
	{ lat: -70, lon: 129.18, elevation: 0 },
	{ lat: -70, lon: 131.29, elevation: 0 },
	{ lat: -70, lon: 133.41, elevation: 0 },
	{ lat: -70, lon: 135.53, elevation: 0 },
	{ lat: -70, lon: 137.65, elevation: 0 },
	{ lat: -70, lon: 139.76, elevation: 0 },
	{ lat: -70, lon: 141.88, elevation: 0 },
	{ lat: -70, lon: 144.0, elevation: 0 },
	{ lat: -70, lon: 146.12, elevation: 0 },
	{ lat: -70, lon: 148.24, elevation: 0 },
	{ lat: -70, lon: 150.35, elevation: 0 },
	{ lat: -70, lon: 152.47, elevation: 0 },
	{ lat: -70, lon: 154.59, elevation: 0 },
	{ lat: -70, lon: 156.71, elevation: 0 },
	{ lat: -70, lon: 158.82, elevation: 0 },
	{ lat: -69, lon: -70.79, elevation: 0 },
	{ lat: -69, lon: -66.74, elevation: 0 },
	{ lat: -69, lon: -64.72, elevation: 0 },
	{ lat: -69, lon: 40.45, elevation: 0 },
	{ lat: -69, lon: 42.47, elevation: 0 },
	{ lat: -69, lon: 44.49, elevation: 0 },
	{ lat: -69, lon: 46.52, elevation: 0 },
	{ lat: -69, lon: 48.54, elevation: 0 },
	{ lat: -69, lon: 50.56, elevation: 0 },
	{ lat: -69, lon: 52.58, elevation: 0 },
	{ lat: -69, lon: 54.61, elevation: 0 },
	{ lat: -69, lon: 56.63, elevation: 0 },
	{ lat: -69, lon: 58.65, elevation: 0 },
	{ lat: -69, lon: 60.67, elevation: 0 },
	{ lat: -69, lon: 62.7, elevation: 0 },
	{ lat: -69, lon: 64.72, elevation: 0 },
	{ lat: -69, lon: 66.74, elevation: 0 },
	{ lat: -69, lon: 68.76, elevation: 0 },
	{ lat: -69, lon: 78.88, elevation: 0 },
	{ lat: -69, lon: 80.9, elevation: 0 },
	{ lat: -69, lon: 82.92, elevation: 0 },
	{ lat: -69, lon: 84.94, elevation: 0 },
	{ lat: -69, lon: 86.97, elevation: 0 },
	{ lat: -69, lon: 88.99, elevation: 0 },
	{ lat: -69, lon: 91.01, elevation: 0 },
	{ lat: -69, lon: 93.03, elevation: 0 },
	{ lat: -69, lon: 95.06, elevation: 0 },
	{ lat: -69, lon: 97.08, elevation: 0 },
	{ lat: -69, lon: 99.1, elevation: 0 },
	{ lat: -69, lon: 101.12, elevation: 0 },
	{ lat: -69, lon: 103.15, elevation: 0 },
	{ lat: -69, lon: 105.17, elevation: 0 },
	{ lat: -69, lon: 107.19, elevation: 0 },
	{ lat: -69, lon: 109.21, elevation: 0 },
	{ lat: -69, lon: 111.24, elevation: 0 },
	{ lat: -69, lon: 113.26, elevation: 0 },
	{ lat: -69, lon: 115.28, elevation: 0 },
	{ lat: -69, lon: 117.3, elevation: 0 },
	{ lat: -69, lon: 119.33, elevation: 0 },
	{ lat: -69, lon: 121.35, elevation: 0 },
	{ lat: -69, lon: 123.37, elevation: 0 },
	{ lat: -69, lon: 125.39, elevation: 0 },
	{ lat: -69, lon: 127.42, elevation: 0 },
	{ lat: -69, lon: 129.44, elevation: 0 },
	{ lat: -69, lon: 131.46, elevation: 0 },
	{ lat: -69, lon: 133.48, elevation: 0 },
	{ lat: -69, lon: 135.51, elevation: 0 },
	{ lat: -69, lon: 137.53, elevation: 0 },
	{ lat: -69, lon: 139.55, elevation: 0 },
	{ lat: -69, lon: 141.57, elevation: 0 },
	{ lat: -69, lon: 143.6, elevation: 0 },
	{ lat: -69, lon: 145.62, elevation: 0 },
	{ lat: -69, lon: 147.64, elevation: 0 },
	{ lat: -69, lon: 149.66, elevation: 0 },
	{ lat: -69, lon: 151.69, elevation: 0 },
	{ lat: -69, lon: 153.71, elevation: 0 },
	{ lat: -69, lon: 155.73, elevation: 0 },
	{ lat: -68, lon: -65.81, elevation: 0 },
	{ lat: -68, lon: 44.52, elevation: 0 },
	{ lat: -68, lon: 46.45, elevation: 0 },
	{ lat: -68, lon: 48.39, elevation: 0 },
	{ lat: -68, lon: 50.32, elevation: 0 },
	{ lat: -68, lon: 52.26, elevation: 0 },
	{ lat: -68, lon: 54.19, elevation: 0 },
	{ lat: -68, lon: 56.13, elevation: 0 },
	{ lat: -68, lon: 58.06, elevation: 0 },
	{ lat: -68, lon: 60.0, elevation: 0 },
	{ lat: -68, lon: 61.94, elevation: 0 },
	{ lat: -68, lon: 63.87, elevation: 0 },
	{ lat: -68, lon: 65.81, elevation: 0 },
	{ lat: -68, lon: 67.74, elevation: 0 },
	{ lat: -68, lon: 69.68, elevation: 0 },
	{ lat: -68, lon: 81.29, elevation: 0 },
	{ lat: -68, lon: 83.23, elevation: 0 },
	{ lat: -68, lon: 85.16, elevation: 0 },
	{ lat: -68, lon: 87.1, elevation: 0 },
	{ lat: -68, lon: 89.03, elevation: 0 },
	{ lat: -68, lon: 90.97, elevation: 0 },
	{ lat: -68, lon: 92.9, elevation: 0 },
	{ lat: -68, lon: 94.84, elevation: 0 },
	{ lat: -68, lon: 96.77, elevation: 0 },
	{ lat: -68, lon: 98.71, elevation: 0 },
	{ lat: -68, lon: 100.65, elevation: 0 },
	{ lat: -68, lon: 102.58, elevation: 0 },
	{ lat: -68, lon: 104.52, elevation: 0 },
	{ lat: -68, lon: 106.45, elevation: 0 },
	{ lat: -68, lon: 108.39, elevation: 0 },
	{ lat: -68, lon: 110.32, elevation: 0 },
	{ lat: -68, lon: 112.26, elevation: 0 },
	{ lat: -68, lon: 114.19, elevation: 0 },
	{ lat: -68, lon: 116.13, elevation: 0 },
	{ lat: -68, lon: 118.06, elevation: 0 },
	{ lat: -68, lon: 120.0, elevation: 0 },
	{ lat: -68, lon: 121.94, elevation: 0 },
	{ lat: -68, lon: 123.87, elevation: 0 },
	{ lat: -68, lon: 125.81, elevation: 0 },
	{ lat: -68, lon: 127.74, elevation: 0 },
	{ lat: -68, lon: 129.68, elevation: 0 },
	{ lat: -68, lon: 131.61, elevation: 0 },
	{ lat: -68, lon: 133.55, elevation: 0 },
	{ lat: -68, lon: 135.48, elevation: 0 },
	{ lat: -68, lon: 137.42, elevation: 0 },
	{ lat: -68, lon: 139.35, elevation: 0 },
	{ lat: -68, lon: 141.29, elevation: 0 },
	{ lat: -68, lon: 143.23, elevation: 0 },
	{ lat: -68, lon: 145.16, elevation: 0 },
	{ lat: -67, lon: -64.95, elevation: 0 },
	{ lat: -67, lon: 51.96, elevation: 0 },
	{ lat: -67, lon: 53.81, elevation: 0 },
	{ lat: -67, lon: 55.67, elevation: 0 },
	{ lat: -67, lon: 87.22, elevation: 0 },
	{ lat: -67, lon: 89.07, elevation: 0 },
	{ lat: -67, lon: 90.93, elevation: 0 },
	{ lat: -67, lon: 92.78, elevation: 0 },
	{ lat: -67, lon: 94.64, elevation: 0 },
	{ lat: -67, lon: 96.49, elevation: 0 },
	{ lat: -67, lon: 98.35, elevation: 0 },
	{ lat: -67, lon: 100.21, elevation: 0 },
	{ lat: -67, lon: 102.06, elevation: 0 },
	{ lat: -67, lon: 103.92, elevation: 0 },
	{ lat: -67, lon: 105.77, elevation: 0 },
	{ lat: -67, lon: 107.63, elevation: 0 },
	{ lat: -67, lon: 109.48, elevation: 0 },
	{ lat: -67, lon: 111.34, elevation: 0 },
	{ lat: -67, lon: 113.2, elevation: 0 },
	{ lat: -67, lon: 115.05, elevation: 0 },
	{ lat: -67, lon: 122.47, elevation: 0 },
	{ lat: -67, lon: 124.33, elevation: 0 },
	{ lat: -67, lon: 126.19, elevation: 0 },
	{ lat: -67, lon: 129.9, elevation: 0 },
	{ lat: -67, lon: 131.75, elevation: 0 },
	{ lat: -67, lon: 133.61, elevation: 0 },
	{ lat: -67, lon: 135.46, elevation: 0 },
	{ lat: -67, lon: 137.32, elevation: 0 },
	{ lat: -67, lon: 139.18, elevation: 0 },
	{ lat: -67, lon: 141.03, elevation: 0 },
	{ lat: -67, lon: 142.89, elevation: 0 },
	{ lat: -66, lon: -64.16, elevation: 0 },
	{ lat: -66, lon: -62.38, elevation: 0 },
	{ lat: -66, lon: -60.59, elevation: 0 },
	{ lat: -66, lon: 53.47, elevation: 0 },
	{ lat: -66, lon: 55.25, elevation: 0 },
	{ lat: -66, lon: 101.58, elevation: 0 },
	{ lat: -66, lon: 103.37, elevation: 0 },
	{ lat: -66, lon: 112.28, elevation: 0 },
	{ lat: -65, lon: -61.71, elevation: 0 },
	{ lat: -64, lon: -59.45, elevation: 0 },
	{ lat: -62, lon: -57.94, elevation: 0 },
	{ lat: -55, lon: -70.11, elevation: 469 },
	{ lat: -55, lon: -68.84, elevation: 453 },
	{ lat: -55, lon: -67.58, elevation: 649 },
	{ lat: -54, lon: -72.74, elevation: 150 },
	{ lat: -54, lon: -71.51, elevation: 116 },
	{ lat: -54, lon: -69.04, elevation: 120 },
	{ lat: -54, lon: -67.81, elevation: 123 },
	{ lat: -54, lon: -38.22, elevation: 0 },
	{ lat: -53, lon: -74.05, elevation: 41 },
	{ lat: -53, lon: -72.84, elevation: 508 },
	{ lat: -53, lon: -69.23, elevation: 234 },
	{ lat: -52, lon: -71.76, elevation: 158 },
	{ lat: -52, lon: -70.59, elevation: 185 },
	{ lat: -52, lon: -69.41, elevation: 96 },
	{ lat: -52, lon: -60.0, elevation: 158 },
	{ lat: -52, lon: -58.82, elevation: 33 },
	{ lat: -51, lon: -74.19, elevation: 399 },
	{ lat: -51, lon: -73.04, elevation: 1196 },
	{ lat: -51, lon: -71.88, elevation: 305 },
	{ lat: -51, lon: -70.73, elevation: 198 },
	{ lat: -51, lon: -69.58, elevation: 195 },
	{ lat: -50, lon: -74.25, elevation: 443 },
	{ lat: -50, lon: -73.12, elevation: 261 },
	{ lat: -50, lon: -72.0, elevation: 708 },
	{ lat: -50, lon: -70.88, elevation: 602 },
	{ lat: -50, lon: -69.75, elevation: 245 },
	{ lat: -50, lon: -68.62, elevation: 113 },
	{ lat: -49, lon: -75.41, elevation: 374 },
	{ lat: -49, lon: -74.31, elevation: 253 },
	{ lat: -49, lon: -73.21, elevation: 1357 },
	{ lat: -49, lon: -72.11, elevation: 1409 },
	{ lat: -49, lon: -71.01, elevation: 605 },
	{ lat: -49, lon: -69.91, elevation: 539 },
	{ lat: -49, lon: -68.81, elevation: 256 },
	{ lat: -49, lon: -67.71, elevation: 45 },
	{ lat: -49, lon: 68.81, elevation: 309 },
	{ lat: -48, lon: -75.14, elevation: 108 },
	{ lat: -48, lon: -74.05, elevation: 992 },
	{ lat: -48, lon: -72.97, elevation: 812 },
	{ lat: -48, lon: -71.89, elevation: 918 },
	{ lat: -48, lon: -70.81, elevation: 853 },
	{ lat: -48, lon: -69.73, elevation: 782 },
	{ lat: -48, lon: -68.65, elevation: 425 },
	{ lat: -48, lon: -67.57, elevation: 146 },
	{ lat: -48, lon: -66.49, elevation: 96 },
	{ lat: -47, lon: -73.81, elevation: 371 },
	{ lat: -47, lon: -72.74, elevation: 1005 },
	{ lat: -47, lon: -71.68, elevation: 2102 },
	{ lat: -47, lon: -70.62, elevation: 688 },
	{ lat: -47, lon: -69.56, elevation: 677 },
	{ lat: -47, lon: -68.5, elevation: 258 },
	{ lat: -47, lon: -67.43, elevation: 245 },
	{ lat: -46, lon: -74.91, elevation: 88 },
	{ lat: -46, lon: -73.87, elevation: 75 },
	{ lat: -46, lon: -72.83, elevation: 1038 },
	{ lat: -46, lon: -71.79, elevation: 883 },
	{ lat: -46, lon: -70.75, elevation: 711 },
	{ lat: -46, lon: -69.71, elevation: 471 },
	{ lat: -46, lon: -68.67, elevation: 574 },
	{ lat: -46, lon: -67.63, elevation: 46 },
	{ lat: -46, lon: 166.47, elevation: 0 },
	{ lat: -46, lon: 167.51, elevation: 210 },
	{ lat: -46, lon: 168.55, elevation: 454 },
	{ lat: -46, lon: 169.6, elevation: 158 },
	{ lat: -45, lon: -72.61, elevation: 1615 },
	{ lat: -45, lon: -71.59, elevation: 1379 },
	{ lat: -45, lon: -70.57, elevation: 615 },
	{ lat: -45, lon: -69.55, elevation: 1015 },
	{ lat: -45, lon: -68.52, elevation: 464 },
	{ lat: -45, lon: -67.5, elevation: 310 },
	{ lat: -45, lon: -66.48, elevation: 69 },
	{ lat: -45, lon: 167.73, elevation: 1277 },
	{ lat: -45, lon: 168.75, elevation: 388 },
	{ lat: -45, lon: 169.77, elevation: 417 },
	{ lat: -45, lon: 170.8, elevation: 103 },
	{ lat: -44, lon: -72.4, elevation: 584 },
	{ lat: -44, lon: -71.4, elevation: 1140 },
	{ lat: -44, lon: -70.39, elevation: 898 },
	{ lat: -44, lon: -69.39, elevation: 750 },
	{ lat: -44, lon: -68.38, elevation: 389 },
	{ lat: -44, lon: -67.37, elevation: 258 },
	{ lat: -44, lon: -66.37, elevation: 290 },
	{ lat: -44, lon: -65.36, elevation: 210 },
	{ lat: -44, lon: 168.94, elevation: 1080 },
	{ lat: -44, lon: 169.94, elevation: 612 },
	{ lat: -44, lon: 170.95, elevation: 489 },
	{ lat: -43, lon: -74.18, elevation: 95 },
	{ lat: -43, lon: -72.2, elevation: 1749 },
	{ lat: -43, lon: -71.21, elevation: 1222 },
	{ lat: -43, lon: -70.22, elevation: 733 },
	{ lat: -43, lon: -69.23, elevation: 760 },
	{ lat: -43, lon: -68.24, elevation: 1167 },
	{ lat: -43, lon: -67.25, elevation: 109 },
	{ lat: -43, lon: -66.26, elevation: 165 },
	{ lat: -43, lon: -65.27, elevation: 55 },
	{ lat: -43, lon: 146.37, elevation: 395 },
	{ lat: -43, lon: 171.1, elevation: 1707 },
	{ lat: -43, lon: 172.09, elevation: 950 },
	{ lat: -43, lon: 173.08, elevation: 156 },
	{ lat: -42, lon: -73.95, elevation: 118 },
	{ lat: -42, lon: -72.0, elevation: 549 },
	{ lat: -42, lon: -71.03, elevation: 1219 },
	{ lat: -42, lon: -70.05, elevation: 901 },
	{ lat: -42, lon: -69.08, elevation: 1292 },
	{ lat: -42, lon: -68.11, elevation: 1230 },
	{ lat: -42, lon: -67.14, elevation: 781 },
	{ lat: -42, lon: -66.16, elevation: 516 },
	{ lat: -42, lon: -65.19, elevation: 71 },
	{ lat: -42, lon: 145.95, elevation: 918 },
	{ lat: -42, lon: 146.92, elevation: 951 },
	{ lat: -42, lon: 147.89, elevation: 446 },
	{ lat: -42, lon: 172.22, elevation: 734 },
	{ lat: -42, lon: 173.19, elevation: 1178 },
	{ lat: -41, lon: -73.72, elevation: 664 },
	{ lat: -41, lon: -72.77, elevation: 89 },
	{ lat: -41, lon: -71.81, elevation: 1704 },
	{ lat: -41, lon: -70.85, elevation: 1033 },
	{ lat: -41, lon: -69.89, elevation: 1213 },
	{ lat: -41, lon: -68.94, elevation: 1272 },
	{ lat: -41, lon: -67.98, elevation: 915 },
	{ lat: -41, lon: -67.02, elevation: 841 },
	{ lat: -41, lon: -66.06, elevation: 396 },
	{ lat: -41, lon: -64.15, elevation: 8 },
	{ lat: -41, lon: -63.19, elevation: 52 },
	{ lat: -41, lon: 145.53, elevation: 327 },
	{ lat: -41, lon: 172.34, elevation: 823 },
	{ lat: -41, lon: 175.21, elevation: 527 },
	{ lat: -40, lon: -73.51, elevation: 77 },
	{ lat: -40, lon: -72.57, elevation: 216 },
	{ lat: -40, lon: -71.62, elevation: 1403 },
	{ lat: -40, lon: -70.68, elevation: 1117 },
	{ lat: -40, lon: -69.74, elevation: 778 },
	{ lat: -40, lon: -68.8, elevation: 768 },
	{ lat: -40, lon: -67.85, elevation: 630 },
	{ lat: -40, lon: -66.91, elevation: 180 },
	{ lat: -40, lon: -65.97, elevation: 202 },
	{ lat: -40, lon: -65.03, elevation: 122 },
	{ lat: -40, lon: -64.08, elevation: 80 },
	{ lat: -40, lon: -63.14, elevation: 38 },
	{ lat: -40, lon: -62.2, elevation: 0 },
	{ lat: -40, lon: 147.96, elevation: 69 },
	{ lat: -40, lon: 175.29, elevation: 58 },
	{ lat: -40, lon: 176.23, elevation: 375 },
	{ lat: -39, lon: -73.02, elevation: 194 },
	{ lat: -39, lon: -72.09, elevation: 347 },
	{ lat: -39, lon: -71.16, elevation: 1304 },
	{ lat: -39, lon: -70.23, elevation: 1350 },
	{ lat: -39, lon: -69.3, elevation: 734 },
	{ lat: -39, lon: -68.37, elevation: 285 },
	{ lat: -39, lon: -67.44, elevation: 298 },
	{ lat: -39, lon: -66.51, elevation: 292 },
	{ lat: -39, lon: -65.58, elevation: 193 },
	{ lat: -39, lon: -64.65, elevation: 165 },
	{ lat: -39, lon: -63.72, elevation: 20 },
	{ lat: -39, lon: -62.79, elevation: 34 },
	{ lat: -39, lon: 174.42, elevation: 63 },
	{ lat: -39, lon: 175.35, elevation: 526 },
	{ lat: -39, lon: 176.28, elevation: 705 },
	{ lat: -39, lon: 177.21, elevation: 233 },
	{ lat: -38, lon: -72.82, elevation: 145 },
	{ lat: -38, lon: -71.91, elevation: 962 },
	{ lat: -38, lon: -70.99, elevation: 1977 },
	{ lat: -38, lon: -70.08, elevation: 983 },
	{ lat: -38, lon: -69.16, elevation: 304 },
	{ lat: -38, lon: -68.24, elevation: 435 },
	{ lat: -38, lon: -67.33, elevation: 378 },
	{ lat: -38, lon: -66.41, elevation: 291 },
	{ lat: -38, lon: -65.5, elevation: 318 },
	{ lat: -38, lon: -64.58, elevation: 156 },
	{ lat: -38, lon: -63.66, elevation: 161 },
	{ lat: -38, lon: -62.75, elevation: 241 },
	{ lat: -38, lon: -61.83, elevation: 402 },
	{ lat: -38, lon: -60.92, elevation: 185 },
	{ lat: -38, lon: -60.0, elevation: 198 },
	{ lat: -38, lon: -59.08, elevation: 126 },
	{ lat: -38, lon: -58.17, elevation: 103 },
	{ lat: -38, lon: 140.61, elevation: 9 },
	{ lat: -38, lon: 141.53, elevation: 99 },
	{ lat: -38, lon: 142.44, elevation: 152 },
	{ lat: -38, lon: 143.36, elevation: 146 },
	{ lat: -38, lon: 144.27, elevation: 87 },
	{ lat: -38, lon: 145.19, elevation: 19 },
	{ lat: -38, lon: 146.11, elevation: 160 },
	{ lat: -38, lon: 147.02, elevation: 32 },
	{ lat: -38, lon: 175.42, elevation: 62 },
	{ lat: -38, lon: 176.34, elevation: 327 },
	{ lat: -38, lon: 177.25, elevation: 4 },
	{ lat: -38, lon: 178.17, elevation: 452 },
	{ lat: -37, lon: -72.36, elevation: 129 },
	{ lat: -37, lon: -71.46, elevation: 1541 },
	{ lat: -37, lon: -70.55, elevation: 2573 },
	{ lat: -37, lon: -69.65, elevation: 1163 },
	{ lat: -37, lon: -68.74, elevation: 1143 },
	{ lat: -37, lon: -67.84, elevation: 593 },
	{ lat: -37, lon: -66.93, elevation: 274 },
	{ lat: -37, lon: -66.03, elevation: 272 },
	{ lat: -37, lon: -65.13, elevation: 353 },
	{ lat: -37, lon: -64.22, elevation: 197 },
	{ lat: -37, lon: -63.32, elevation: 138 },
	{ lat: -37, lon: -62.41, elevation: 102 },
	{ lat: -37, lon: -61.51, elevation: 146 },
	{ lat: -37, lon: -60.6, elevation: 180 },
	{ lat: -37, lon: -59.7, elevation: 216 },
	{ lat: -37, lon: -58.79, elevation: 90 },
	{ lat: -37, lon: -57.89, elevation: 17 },
	{ lat: -37, lon: -56.98, elevation: 1 },
	{ lat: -37, lon: 140.2, elevation: 26 },
	{ lat: -37, lon: 141.11, elevation: 117 },
	{ lat: -37, lon: 142.01, elevation: 179 },
	{ lat: -37, lon: 142.91, elevation: 222 },
	{ lat: -37, lon: 143.82, elevation: 200 },
	{ lat: -37, lon: 144.72, elevation: 313 },
	{ lat: -37, lon: 145.63, elevation: 330 },
	{ lat: -37, lon: 146.53, elevation: 626 },
	{ lat: -37, lon: 147.44, elevation: 770 },
	{ lat: -37, lon: 148.34, elevation: 601 },
	{ lat: -37, lon: 149.25, elevation: 727 },
	{ lat: -37, lon: 174.57, elevation: 16 },
	{ lat: -36, lon: -71.91, elevation: 141 },
	{ lat: -36, lon: -71.02, elevation: 2137 },
	{ lat: -36, lon: -70.12, elevation: 2292 },
	{ lat: -36, lon: -69.23, elevation: 1444 },
	{ lat: -36, lon: -68.34, elevation: 1343 },
	{ lat: -36, lon: -67.44, elevation: 470 },
	{ lat: -36, lon: -66.55, elevation: 329 },
	{ lat: -36, lon: -65.66, elevation: 346 },
	{ lat: -36, lon: -64.76, elevation: 256 },
	{ lat: -36, lon: -63.87, elevation: 139 },
	{ lat: -36, lon: -62.98, elevation: 97 },
	{ lat: -36, lon: -62.08, elevation: 89 },
	{ lat: -36, lon: -61.19, elevation: 87 },
	{ lat: -36, lon: -60.3, elevation: 61 },
	{ lat: -36, lon: -59.4, elevation: 40 },
	{ lat: -36, lon: -58.51, elevation: 17 },
	{ lat: -36, lon: -57.62, elevation: 5 },
	{ lat: -36, lon: 137.12, elevation: 54 },
	{ lat: -36, lon: 139.8, elevation: 13 },
	{ lat: -36, lon: 140.69, elevation: 106 },
	{ lat: -36, lon: 141.59, elevation: 111 },
	{ lat: -36, lon: 142.48, elevation: 91 },
	{ lat: -36, lon: 143.37, elevation: 102 },
	{ lat: -36, lon: 144.27, elevation: 85 },
	{ lat: -36, lon: 145.16, elevation: 103 },
	{ lat: -36, lon: 146.05, elevation: 124 },
	{ lat: -36, lon: 146.95, elevation: 340 },
	{ lat: -36, lon: 147.84, elevation: 448 },
	{ lat: -36, lon: 148.73, elevation: 1122 },
	{ lat: -36, lon: 149.63, elevation: 686 },
	{ lat: -35, lon: -71.47, elevation: 386 },
	{ lat: -35, lon: -70.59, elevation: 1800 },
	{ lat: -35, lon: -69.71, elevation: 1792 },
	{ lat: -35, lon: -68.82, elevation: 1303 },
	{ lat: -35, lon: -67.94, elevation: 497 },
	{ lat: -35, lon: -67.06, elevation: 410 },
	{ lat: -35, lon: -66.18, elevation: 424 },
	{ lat: -35, lon: -65.29, elevation: 318 },
	{ lat: -35, lon: -64.41, elevation: 186 },
	{ lat: -35, lon: -63.53, elevation: 127 },
	{ lat: -35, lon: -62.65, elevation: 109 },
	{ lat: -35, lon: -61.76, elevation: 101 },
	{ lat: -35, lon: -60.88, elevation: 65 },
	{ lat: -35, lon: -60.0, elevation: 45 },
	{ lat: -35, lon: -59.12, elevation: 38 },
	{ lat: -35, lon: -58.24, elevation: 26 },
	{ lat: -35, lon: 116.47, elevation: 0 },
	{ lat: -35, lon: 137.65, elevation: 41 },
	{ lat: -35, lon: 138.53, elevation: 17 },
	{ lat: -35, lon: 139.41, elevation: 75 },
	{ lat: -35, lon: 140.29, elevation: 78 },
	{ lat: -35, lon: 141.18, elevation: 49 },
	{ lat: -35, lon: 142.06, elevation: 60 },
	{ lat: -35, lon: 142.94, elevation: 54 },
	{ lat: -35, lon: 143.82, elevation: 67 },
	{ lat: -35, lon: 144.71, elevation: 82 },
	{ lat: -35, lon: 145.59, elevation: 108 },
	{ lat: -35, lon: 146.47, elevation: 152 },
	{ lat: -35, lon: 147.35, elevation: 200 },
	{ lat: -35, lon: 148.24, elevation: 279 },
	{ lat: -35, lon: 149.12, elevation: 701 },
	{ lat: -35, lon: 150.0, elevation: 563 },
	{ lat: -34, lon: -71.04, elevation: 963 },
	{ lat: -34, lon: -70.17, elevation: 2885 },
	{ lat: -34, lon: -69.3, elevation: 2219 },
	{ lat: -34, lon: -68.43, elevation: 893 },
	{ lat: -34, lon: -67.55, elevation: 479 },
	{ lat: -34, lon: -66.68, elevation: 409 },
	{ lat: -34, lon: -65.81, elevation: 553 },
	{ lat: -34, lon: -64.94, elevation: 338 },
	{ lat: -34, lon: -64.07, elevation: 192 },
	{ lat: -34, lon: -63.2, elevation: 136 },
	{ lat: -34, lon: -62.32, elevation: 125 },
	{ lat: -34, lon: -61.45, elevation: 106 },
	{ lat: -34, lon: -60.58, elevation: 67 },
	{ lat: -34, lon: -59.71, elevation: 23 },
	{ lat: -34, lon: -58.84, elevation: 3 },
	{ lat: -34, lon: -57.97, elevation: 74 },
	{ lat: -34, lon: -57.09, elevation: 158 },
	{ lat: -34, lon: -56.22, elevation: 80 },
	{ lat: -34, lon: -55.35, elevation: 169 },
	{ lat: -34, lon: -54.48, elevation: 59 },
	{ lat: -34, lon: -53.61, elevation: 4 },
	{ lat: -34, lon: 18.74, elevation: 31 },
	{ lat: -34, lon: 19.61, elevation: 641 },
	{ lat: -34, lon: 20.48, elevation: 357 },
	{ lat: -34, lon: 21.36, elevation: 536 },
	{ lat: -34, lon: 22.23, elevation: 176 },
	{ lat: -34, lon: 23.1, elevation: 243 },
	{ lat: -34, lon: 23.97, elevation: 240 },
	{ lat: -34, lon: 24.84, elevation: 207 },
	{ lat: -34, lon: 115.5, elevation: 120 },
	{ lat: -34, lon: 116.37, elevation: 273 },
	{ lat: -34, lon: 117.24, elevation: 328 },
	{ lat: -34, lon: 118.11, elevation: 266 },
	{ lat: -34, lon: 118.98, elevation: 255 },
	{ lat: -34, lon: 135.54, elevation: 61 },
	{ lat: -34, lon: 136.42, elevation: 45 },
	{ lat: -34, lon: 138.16, elevation: 114 },
	{ lat: -34, lon: 139.03, elevation: 385 },
	{ lat: -34, lon: 139.9, elevation: 43 },
	{ lat: -34, lon: 140.77, elevation: 20 },
	{ lat: -34, lon: 141.65, elevation: 34 },
	{ lat: -34, lon: 142.52, elevation: 79 },
	{ lat: -34, lon: 143.39, elevation: 65 },
	{ lat: -34, lon: 144.26, elevation: 76 },
	{ lat: -34, lon: 145.13, elevation: 94 },
	{ lat: -34, lon: 146.0, elevation: 143 },
	{ lat: -34, lon: 146.88, elevation: 242 },
	{ lat: -34, lon: 147.75, elevation: 243 },
	{ lat: -34, lon: 148.62, elevation: 390 },
	{ lat: -34, lon: 149.49, elevation: 703 },
	{ lat: -34, lon: 150.36, elevation: 205 },
	{ lat: -34, lon: 151.23, elevation: 0 },
	{ lat: -33, lon: -71.48, elevation: 222 },
	{ lat: -33, lon: -70.62, elevation: 1050 },
	{ lat: -33, lon: -69.76, elevation: 3041 },
	{ lat: -33, lon: -68.9, elevation: 965 },
	{ lat: -33, lon: -68.04, elevation: 613 },
	{ lat: -33, lon: -67.18, elevation: 467 },
	{ lat: -33, lon: -66.32, elevation: 789 },
	{ lat: -33, lon: -65.45, elevation: 944 },
	{ lat: -33, lon: -64.59, elevation: 604 },
	{ lat: -33, lon: -63.73, elevation: 247 },
	{ lat: -33, lon: -62.87, elevation: 123 },
	{ lat: -33, lon: -62.01, elevation: 106 },
	{ lat: -33, lon: -61.15, elevation: 72 },
	{ lat: -33, lon: -60.29, elevation: 3 },
	{ lat: -33, lon: -59.43, elevation: 26 },
	{ lat: -33, lon: -58.56, elevation: 22 },
	{ lat: -33, lon: -57.7, elevation: 37 },
	{ lat: -33, lon: -56.84, elevation: 46 },
	{ lat: -33, lon: -55.98, elevation: 110 },
	{ lat: -33, lon: -55.12, elevation: 234 },
	{ lat: -33, lon: -54.26, elevation: 154 },
	{ lat: -33, lon: -53.4, elevation: 0 },
	{ lat: -33, lon: 18.09, elevation: 11 },
	{ lat: -33, lon: 18.95, elevation: 128 },
	{ lat: -33, lon: 19.81, elevation: 584 },
	{ lat: -33, lon: 20.67, elevation: 1063 },
	{ lat: -33, lon: 21.53, elevation: 530 },
	{ lat: -33, lon: 22.39, elevation: 1045 },
	{ lat: -33, lon: 23.25, elevation: 789 },
	{ lat: -33, lon: 24.11, elevation: 802 },
	{ lat: -33, lon: 24.98, elevation: 354 },
	{ lat: -33, lon: 25.84, elevation: 531 },
	{ lat: -33, lon: 26.7, elevation: 507 },
	{ lat: -33, lon: 27.56, elevation: 347 },
	{ lat: -33, lon: 116.27, elevation: 273 },
	{ lat: -33, lon: 117.13, elevation: 358 },
	{ lat: -33, lon: 117.99, elevation: 325 },
	{ lat: -33, lon: 118.85, elevation: 297 },
	{ lat: -33, lon: 119.71, elevation: 323 },
	{ lat: -33, lon: 120.57, elevation: 244 },
	{ lat: -33, lon: 121.44, elevation: 256 },
	{ lat: -33, lon: 122.3, elevation: 244 },
	{ lat: -33, lon: 123.16, elevation: 180 },
	{ lat: -33, lon: 124.02, elevation: 147 },
	{ lat: -33, lon: 134.35, elevation: 7 },
	{ lat: -33, lon: 135.22, elevation: 56 },
	{ lat: -33, lon: 136.08, elevation: 179 },
	{ lat: -33, lon: 136.94, elevation: 189 },
	{ lat: -33, lon: 138.66, elevation: 480 },
	{ lat: -33, lon: 139.52, elevation: 342 },
	{ lat: -33, lon: 140.38, elevation: 108 },
	{ lat: -33, lon: 141.24, elevation: 79 },
	{ lat: -33, lon: 142.11, elevation: 68 },
	{ lat: -33, lon: 142.97, elevation: 82 },
	{ lat: -33, lon: 143.83, elevation: 84 },
	{ lat: -33, lon: 144.69, elevation: 99 },
	{ lat: -33, lon: 145.55, elevation: 136 },
	{ lat: -33, lon: 146.41, elevation: 170 },
	{ lat: -33, lon: 147.27, elevation: 221 },
	{ lat: -33, lon: 148.13, elevation: 313 },
	{ lat: -33, lon: 149.0, elevation: 622 },
	{ lat: -33, lon: 149.86, elevation: 872 },
	{ lat: -33, lon: 150.72, elevation: 237 },
	{ lat: -33, lon: 151.58, elevation: 29 },
	{ lat: -32, lon: -71.06, elevation: 1159 },
	{ lat: -32, lon: -70.21, elevation: 4101 },
	{ lat: -32, lon: -69.36, elevation: 2082 },
	{ lat: -32, lon: -68.51, elevation: 579 },
	{ lat: -32, lon: -67.66, elevation: 538 },
	{ lat: -32, lon: -66.81, elevation: 397 },
	{ lat: -32, lon: -65.96, elevation: 387 },
	{ lat: -32, lon: -65.11, elevation: 640 },
	{ lat: -32, lon: -64.26, elevation: 502 },
	{ lat: -32, lon: -63.4, elevation: 231 },
	{ lat: -32, lon: -62.55, elevation: 121 },
	{ lat: -32, lon: -61.7, elevation: 79 },
	{ lat: -32, lon: -60.85, elevation: 5 },
	{ lat: -32, lon: -60.0, elevation: 97 },
	{ lat: -32, lon: -59.15, elevation: 42 },
	{ lat: -32, lon: -58.3, elevation: 37 },
	{ lat: -32, lon: -57.45, elevation: 49 },
	{ lat: -32, lon: -56.6, elevation: 184 },
	{ lat: -32, lon: -55.74, elevation: 115 },
	{ lat: -32, lon: -54.89, elevation: 124 },
	{ lat: -32, lon: -54.04, elevation: 110 },
	{ lat: -32, lon: -53.19, elevation: 184 },
	{ lat: -32, lon: -52.34, elevation: 6 },
	{ lat: -32, lon: 18.3, elevation: 18 },
	{ lat: -32, lon: 19.15, elevation: 349 },
	{ lat: -32, lon: 20.0, elevation: 1471 },
	{ lat: -32, lon: 20.85, elevation: 1221 },
	{ lat: -32, lon: 21.7, elevation: 1410 },
	{ lat: -32, lon: 22.55, elevation: 1515 },
	{ lat: -32, lon: 23.4, elevation: 1036 },
	{ lat: -32, lon: 24.26, elevation: 1602 },
	{ lat: -32, lon: 25.11, elevation: 1236 },
	{ lat: -32, lon: 25.96, elevation: 1128 },
	{ lat: -32, lon: 26.81, elevation: 1080 },
	{ lat: -32, lon: 27.66, elevation: 958 },
	{ lat: -32, lon: 28.51, elevation: 585 },
	{ lat: -32, lon: 116.17, elevation: 270 },
	{ lat: -32, lon: 117.02, elevation: 238 },
	{ lat: -32, lon: 117.87, elevation: 246 },
	{ lat: -32, lon: 118.72, elevation: 367 },
	{ lat: -32, lon: 119.57, elevation: 430 },
	{ lat: -32, lon: 120.43, elevation: 439 },
	{ lat: -32, lon: 121.28, elevation: 375 },
	{ lat: -32, lon: 122.13, elevation: 267 },
	{ lat: -32, lon: 122.98, elevation: 375 },
	{ lat: -32, lon: 123.83, elevation: 185 },
	{ lat: -32, lon: 124.68, elevation: 149 },
	{ lat: -32, lon: 125.53, elevation: 115 },
	{ lat: -32, lon: 126.38, elevation: 102 },
	{ lat: -32, lon: 127.23, elevation: 21 },
	{ lat: -32, lon: 128.09, elevation: 10 },
	{ lat: -32, lon: 132.34, elevation: 11 },
	{ lat: -32, lon: 133.19, elevation: 22 },
	{ lat: -32, lon: 134.04, elevation: 87 },
	{ lat: -32, lon: 134.89, elevation: 209 },
	{ lat: -32, lon: 135.74, elevation: 237 },
	{ lat: -32, lon: 136.6, elevation: 106 },
	{ lat: -32, lon: 137.45, elevation: 117 },
	{ lat: -32, lon: 138.3, elevation: 360 },
	{ lat: -32, lon: 139.15, elevation: 249 },
	{ lat: -32, lon: 140.0, elevation: 208 },
	{ lat: -32, lon: 140.85, elevation: 163 },
	{ lat: -32, lon: 141.7, elevation: 197 },
	{ lat: -32, lon: 142.55, elevation: 98 },
	{ lat: -32, lon: 143.4, elevation: 77 },
	{ lat: -32, lon: 144.26, elevation: 130 },
	{ lat: -32, lon: 145.11, elevation: 166 },
	{ lat: -32, lon: 145.96, elevation: 258 },
	{ lat: -32, lon: 146.81, elevation: 232 },
	{ lat: -32, lon: 147.66, elevation: 203 },
	{ lat: -32, lon: 148.51, elevation: 281 },
	{ lat: -32, lon: 149.36, elevation: 378 },
	{ lat: -32, lon: 150.21, elevation: 481 },
	{ lat: -32, lon: 151.06, elevation: 377 },
	{ lat: -32, lon: 151.91, elevation: 177 },
	{ lat: -31, lon: -71.24, elevation: 938 },
	{ lat: -31, lon: -70.4, elevation: 2687 },
	{ lat: -31, lon: -69.56, elevation: 1663 },
	{ lat: -31, lon: -68.71, elevation: 1224 },
	{ lat: -31, lon: -67.87, elevation: 648 },
	{ lat: -31, lon: -67.03, elevation: 553 },
	{ lat: -31, lon: -66.18, elevation: 472 },
	{ lat: -31, lon: -65.34, elevation: 1113 },
	{ lat: -31, lon: -64.5, elevation: 1137 },
	{ lat: -31, lon: -63.65, elevation: 209 },
	{ lat: -31, lon: -62.81, elevation: 90 },
	{ lat: -31, lon: -61.97, elevation: 105 },
	{ lat: -31, lon: -61.12, elevation: 55 },
	{ lat: -31, lon: -60.28, elevation: 19 },
	{ lat: -31, lon: -59.44, elevation: 61 },
	{ lat: -31, lon: -58.59, elevation: 68 },
	{ lat: -31, lon: -57.75, elevation: 82 },
	{ lat: -31, lon: -56.91, elevation: 129 },
	{ lat: -31, lon: -56.07, elevation: 270 },
	{ lat: -31, lon: -55.22, elevation: 148 },
	{ lat: -31, lon: -54.38, elevation: 273 },
	{ lat: -31, lon: -53.54, elevation: 277 },
	{ lat: -31, lon: -52.69, elevation: 83 },
	{ lat: -31, lon: -51.85, elevation: 20 },
	{ lat: -31, lon: 18.13, elevation: 296 },
	{ lat: -31, lon: 18.97, elevation: 340 },
	{ lat: -31, lon: 19.81, elevation: 1077 },
	{ lat: -31, lon: 20.66, elevation: 1010 },
	{ lat: -31, lon: 21.5, elevation: 1353 },
	{ lat: -31, lon: 22.34, elevation: 1455 },
	{ lat: -31, lon: 23.19, elevation: 1166 },
	{ lat: -31, lon: 24.03, elevation: 1347 },
	{ lat: -31, lon: 24.87, elevation: 1450 },
	{ lat: -31, lon: 25.71, elevation: 1629 },
	{ lat: -31, lon: 26.56, elevation: 1670 },
	{ lat: -31, lon: 27.4, elevation: 1748 },
	{ lat: -31, lon: 28.24, elevation: 1336 },
	{ lat: -31, lon: 29.09, elevation: 993 },
	{ lat: -31, lon: 29.93, elevation: 469 },
	{ lat: -31, lon: 115.93, elevation: 166 },
	{ lat: -31, lon: 116.77, elevation: 295 },
	{ lat: -31, lon: 117.61, elevation: 308 },
	{ lat: -31, lon: 118.45, elevation: 367 },
	{ lat: -31, lon: 119.3, elevation: 353 },
	{ lat: -31, lon: 120.14, elevation: 395 },
	{ lat: -31, lon: 120.98, elevation: 454 },
	{ lat: -31, lon: 121.83, elevation: 404 },
	{ lat: -31, lon: 122.67, elevation: 343 },
	{ lat: -31, lon: 123.51, elevation: 302 },
	{ lat: -31, lon: 124.36, elevation: 191 },
	{ lat: -31, lon: 125.2, elevation: 188 },
	{ lat: -31, lon: 126.04, elevation: 172 },
	{ lat: -31, lon: 126.89, elevation: 177 },
	{ lat: -31, lon: 127.73, elevation: 173 },
	{ lat: -31, lon: 128.57, elevation: 152 },
	{ lat: -31, lon: 129.41, elevation: 140 },
	{ lat: -31, lon: 130.26, elevation: 113 },
	{ lat: -31, lon: 131.1, elevation: 80 },
	{ lat: -31, lon: 131.94, elevation: 65 },
	{ lat: -31, lon: 132.79, elevation: 128 },
	{ lat: -31, lon: 133.63, elevation: 117 },
	{ lat: -31, lon: 134.47, elevation: 140 },
	{ lat: -31, lon: 135.32, elevation: 134 },
	{ lat: -31, lon: 136.16, elevation: 148 },
	{ lat: -31, lon: 137.0, elevation: 137 },
	{ lat: -31, lon: 137.85, elevation: 30 },
	{ lat: -31, lon: 138.69, elevation: 656 },
	{ lat: -31, lon: 139.53, elevation: 17 },
	{ lat: -31, lon: 140.37, elevation: 34 },
	{ lat: -31, lon: 141.22, elevation: 119 },
	{ lat: -31, lon: 142.06, elevation: 142 },
	{ lat: -31, lon: 142.9, elevation: 153 },
	{ lat: -31, lon: 143.75, elevation: 127 },
	{ lat: -31, lon: 144.59, elevation: 90 },
	{ lat: -31, lon: 145.43, elevation: 186 },
	{ lat: -31, lon: 146.28, elevation: 202 },
	{ lat: -31, lon: 147.12, elevation: 155 },
	{ lat: -31, lon: 147.96, elevation: 161 },
	{ lat: -31, lon: 148.81, elevation: 252 },
	{ lat: -31, lon: 149.65, elevation: 386 },
	{ lat: -31, lon: 150.49, elevation: 309 },
	{ lat: -31, lon: 151.33, elevation: 1057 },
	{ lat: -31, lon: 152.18, elevation: 721 },
	{ lat: -31, lon: 153.02, elevation: 13 },
	{ lat: -30, lon: -70.83, elevation: 466 },
	{ lat: -30, lon: -70.0, elevation: 3872 },
	{ lat: -30, lon: -69.17, elevation: 1708 },
	{ lat: -30, lon: -68.33, elevation: 924 },
	{ lat: -30, lon: -67.5, elevation: 1416 },
	{ lat: -30, lon: -66.67, elevation: 365 },
	{ lat: -30, lon: -65.83, elevation: 335 },
	{ lat: -30, lon: -65.0, elevation: 178 },
	{ lat: -30, lon: -64.17, elevation: 885 },
	{ lat: -30, lon: -63.33, elevation: 93 },
	{ lat: -30, lon: -62.5, elevation: 76 },
	{ lat: -30, lon: -61.67, elevation: 86 },
	{ lat: -30, lon: -60.83, elevation: 64 },
	{ lat: -30, lon: -60.0, elevation: 34 },
	{ lat: -30, lon: -59.17, elevation: 36 },
	{ lat: -30, lon: -58.33, elevation: 81 },
	{ lat: -30, lon: -57.5, elevation: 55 },
	{ lat: -30, lon: -56.67, elevation: 140 },
	{ lat: -30, lon: -55.83, elevation: 94 },
	{ lat: -30, lon: -55.0, elevation: 126 },
	{ lat: -30, lon: -54.17, elevation: 147 },
	{ lat: -30, lon: -53.33, elevation: 38 },
	{ lat: -30, lon: -52.5, elevation: 117 },
	{ lat: -30, lon: -51.67, elevation: 17 },
	{ lat: -30, lon: -50.83, elevation: 5 },
	{ lat: -30, lon: 17.5, elevation: 177 },
	{ lat: -30, lon: 18.33, elevation: 912 },
	{ lat: -30, lon: 19.17, elevation: 939 },
	{ lat: -30, lon: 20.0, elevation: 980 },
	{ lat: -30, lon: 20.83, elevation: 873 },
	{ lat: -30, lon: 21.67, elevation: 923 },
	{ lat: -30, lon: 22.5, elevation: 1142 },
	{ lat: -30, lon: 23.33, elevation: 1042 },
	{ lat: -30, lon: 24.17, elevation: 1206 },
	{ lat: -30, lon: 25.0, elevation: 1479 },
	{ lat: -30, lon: 25.83, elevation: 1393 },
	{ lat: -30, lon: 26.67, elevation: 1552 },
	{ lat: -30, lon: 27.5, elevation: 1468 },
	{ lat: -30, lon: 28.33, elevation: 1686 },
	{ lat: -30, lon: 29.17, elevation: 1717 },
	{ lat: -30, lon: 30.0, elevation: 1317 },
	{ lat: -30, lon: 30.83, elevation: 162 },
	{ lat: -30, lon: 115.0, elevation: 3 },
	{ lat: -30, lon: 115.83, elevation: 238 },
	{ lat: -30, lon: 116.67, elevation: 351 },
	{ lat: -30, lon: 117.5, elevation: 305 },
	{ lat: -30, lon: 118.33, elevation: 390 },
	{ lat: -30, lon: 119.17, elevation: 435 },
	{ lat: -30, lon: 120.0, elevation: 491 },
	{ lat: -30, lon: 120.83, elevation: 453 },
	{ lat: -30, lon: 121.67, elevation: 419 },
	{ lat: -30, lon: 122.5, elevation: 368 },
	{ lat: -30, lon: 123.33, elevation: 409 },
	{ lat: -30, lon: 124.17, elevation: 308 },
	{ lat: -30, lon: 125.0, elevation: 207 },
	{ lat: -30, lon: 125.83, elevation: 217 },
	{ lat: -30, lon: 126.67, elevation: 241 },
	{ lat: -30, lon: 127.5, elevation: 238 },
	{ lat: -30, lon: 128.33, elevation: 202 },
	{ lat: -30, lon: 129.17, elevation: 184 },
	{ lat: -30, lon: 130.0, elevation: 157 },
	{ lat: -30, lon: 130.83, elevation: 150 },
	{ lat: -30, lon: 131.67, elevation: 185 },
	{ lat: -30, lon: 132.5, elevation: 245 },
	{ lat: -30, lon: 133.33, elevation: 156 },
	{ lat: -30, lon: 134.17, elevation: 191 },
	{ lat: -30, lon: 135.0, elevation: 161 },
	{ lat: -30, lon: 135.83, elevation: 152 },
	{ lat: -30, lon: 136.67, elevation: 124 },
	{ lat: -30, lon: 137.5, elevation: 78 },
	{ lat: -30, lon: 138.33, elevation: 85 },
	{ lat: -30, lon: 139.17, elevation: 253 },
	{ lat: -30, lon: 140.0, elevation: 26 },
	{ lat: -30, lon: 140.83, elevation: 85 },
	{ lat: -30, lon: 141.67, elevation: 174 },
	{ lat: -30, lon: 142.5, elevation: 94 },
	{ lat: -30, lon: 143.33, elevation: 177 },
	{ lat: -30, lon: 144.17, elevation: 107 },
	{ lat: -30, lon: 145.0, elevation: 108 },
	{ lat: -30, lon: 145.83, elevation: 108 },
	{ lat: -30, lon: 146.67, elevation: 117 },
	{ lat: -30, lon: 147.5, elevation: 153 },
	{ lat: -30, lon: 148.33, elevation: 134 },
	{ lat: -30, lon: 149.17, elevation: 171 },
	{ lat: -30, lon: 150.0, elevation: 364 },
	{ lat: -30, lon: 150.83, elevation: 376 },
	{ lat: -30, lon: 151.67, elevation: 1351 },
	{ lat: -30, lon: 152.5, elevation: 774 },
	{ lat: -29, lon: -71.01, elevation: 783 },
	{ lat: -29, lon: -70.18, elevation: 2383 },
	{ lat: -29, lon: -69.36, elevation: 3612 },
	{ lat: -29, lon: -68.53, elevation: 3181 },
	{ lat: -29, lon: -67.71, elevation: 4111 },
	{ lat: -29, lon: -66.88, elevation: 1795 },
	{ lat: -29, lon: -66.06, elevation: 309 },
	{ lat: -29, lon: -65.23, elevation: 302 },
	{ lat: -29, lon: -64.4, elevation: 116 },
	{ lat: -29, lon: -63.58, elevation: 112 },
	{ lat: -29, lon: -62.75, elevation: 91 },
	{ lat: -29, lon: -61.93, elevation: 85 },
	{ lat: -29, lon: -61.1, elevation: 65 },
	{ lat: -29, lon: -60.28, elevation: 64 },
	{ lat: -29, lon: -59.45, elevation: 35 },
	{ lat: -29, lon: -58.62, elevation: 60 },
	{ lat: -29, lon: -57.8, elevation: 75 },
	{ lat: -29, lon: -56.97, elevation: 65 },
	{ lat: -29, lon: -56.15, elevation: 65 },
	{ lat: -29, lon: -55.32, elevation: 228 },
	{ lat: -29, lon: -54.5, elevation: 297 },
	{ lat: -29, lon: -53.67, elevation: 383 },
	{ lat: -29, lon: -52.84, elevation: 613 },
	{ lat: -29, lon: -52.02, elevation: 538 },
	{ lat: -29, lon: -51.19, elevation: 748 },
	{ lat: -29, lon: -50.37, elevation: 740 },
	{ lat: -29, lon: -49.54, elevation: 17 },
	{ lat: -29, lon: 17.34, elevation: 689 },
	{ lat: -29, lon: 18.17, elevation: 496 },
	{ lat: -29, lon: 18.99, elevation: 604 },
	{ lat: -29, lon: 19.82, elevation: 998 },
	{ lat: -29, lon: 20.64, elevation: 785 },
	{ lat: -29, lon: 21.47, elevation: 1030 },
	{ lat: -29, lon: 22.29, elevation: 1003 },
	{ lat: -29, lon: 23.12, elevation: 1214 },
	{ lat: -29, lon: 23.94, elevation: 1006 },
	{ lat: -29, lon: 24.77, elevation: 1133 },
	{ lat: -29, lon: 25.6, elevation: 1234 },
	{ lat: -29, lon: 26.42, elevation: 1327 },
	{ lat: -29, lon: 27.25, elevation: 1530 },
	{ lat: -29, lon: 28.07, elevation: 1788 },
	{ lat: -29, lon: 28.9, elevation: 2888 },
	{ lat: -29, lon: 29.72, elevation: 1193 },
	{ lat: -29, lon: 30.55, elevation: 1416 },
	{ lat: -29, lon: 31.38, elevation: 343 },
	{ lat: -29, lon: 114.77, elevation: 48 },
	{ lat: -29, lon: 115.6, elevation: 280 },
	{ lat: -29, lon: 116.42, elevation: 265 },
	{ lat: -29, lon: 117.25, elevation: 288 },
	{ lat: -29, lon: 118.07, elevation: 433 },
	{ lat: -29, lon: 118.9, elevation: 449 },
	{ lat: -29, lon: 119.72, elevation: 400 },
	{ lat: -29, lon: 120.55, elevation: 428 },
	{ lat: -29, lon: 121.38, elevation: 360 },
	{ lat: -29, lon: 122.2, elevation: 399 },
	{ lat: -29, lon: 123.03, elevation: 414 },
	{ lat: -29, lon: 123.85, elevation: 427 },
	{ lat: -29, lon: 124.68, elevation: 403 },
	{ lat: -29, lon: 125.5, elevation: 297 },
	{ lat: -29, lon: 126.33, elevation: 237 },
	{ lat: -29, lon: 127.16, elevation: 232 },
	{ lat: -29, lon: 127.98, elevation: 285 },
	{ lat: -29, lon: 128.81, elevation: 293 },
	{ lat: -29, lon: 129.63, elevation: 301 },
	{ lat: -29, lon: 130.46, elevation: 252 },
	{ lat: -29, lon: 131.28, elevation: 287 },
	{ lat: -29, lon: 132.11, elevation: 240 },
	{ lat: -29, lon: 132.94, elevation: 212 },
	{ lat: -29, lon: 133.76, elevation: 185 },
	{ lat: -29, lon: 134.59, elevation: 199 },
	{ lat: -29, lon: 135.41, elevation: 114 },
	{ lat: -29, lon: 136.24, elevation: 86 },
	{ lat: -29, lon: 137.06, elevation: -15 },
	{ lat: -29, lon: 137.89, elevation: -4 },
	{ lat: -29, lon: 138.72, elevation: 37 },
	{ lat: -29, lon: 139.54, elevation: 35 },
	{ lat: -29, lon: 140.37, elevation: 49 },
	{ lat: -29, lon: 141.19, elevation: 127 },
	{ lat: -29, lon: 142.02, elevation: 149 },
	{ lat: -29, lon: 142.84, elevation: 94 },
	{ lat: -29, lon: 143.67, elevation: 156 },
	{ lat: -29, lon: 144.5, elevation: 138 },
	{ lat: -29, lon: 145.32, elevation: 149 },
	{ lat: -29, lon: 146.15, elevation: 146 },
	{ lat: -29, lon: 146.97, elevation: 135 },
	{ lat: -29, lon: 147.8, elevation: 147 },
	{ lat: -29, lon: 148.62, elevation: 156 },
	{ lat: -29, lon: 149.45, elevation: 173 },
	{ lat: -29, lon: 150.28, elevation: 262 },
	{ lat: -29, lon: 151.1, elevation: 503 },
	{ lat: -29, lon: 151.93, elevation: 781 },
	{ lat: -29, lon: 152.75, elevation: 276 },
	{ lat: -28, lon: -70.36, elevation: 983 },
	{ lat: -28, lon: -69.55, elevation: 4262 },
	{ lat: -28, lon: -68.73, elevation: 5723 },
	{ lat: -28, lon: -67.91, elevation: 3367 },
	{ lat: -28, lon: -67.09, elevation: 1140 },
	{ lat: -28, lon: -66.27, elevation: 760 },
	{ lat: -28, lon: -65.45, elevation: 442 },
	{ lat: -28, lon: -64.64, elevation: 383 },
	{ lat: -28, lon: -63.82, elevation: 143 },
	{ lat: -28, lon: -63.0, elevation: 126 },
	{ lat: -28, lon: -62.18, elevation: 85 },
	{ lat: -28, lon: -61.36, elevation: 69 },
	{ lat: -28, lon: -60.55, elevation: 65 },
	{ lat: -28, lon: -59.73, elevation: 58 },
	{ lat: -28, lon: -58.91, elevation: 45 },
	{ lat: -28, lon: -58.09, elevation: 73 },
	{ lat: -28, lon: -57.27, elevation: 66 },
	{ lat: -28, lon: -56.45, elevation: 79 },
	{ lat: -28, lon: -55.64, elevation: 96 },
	{ lat: -28, lon: -54.82, elevation: 245 },
	{ lat: -28, lon: -54.0, elevation: 397 },
	{ lat: -28, lon: -53.18, elevation: 584 },
	{ lat: -28, lon: -52.36, elevation: 681 },
	{ lat: -28, lon: -51.55, elevation: 785 },
	{ lat: -28, lon: -50.73, elevation: 876 },
	{ lat: -28, lon: -49.91, elevation: 1306 },
	{ lat: -28, lon: -49.09, elevation: 470 },
	{ lat: -28, lon: 16.36, elevation: 429 },
	{ lat: -28, lon: 17.18, elevation: 578 },
	{ lat: -28, lon: 18.0, elevation: 718 },
	{ lat: -28, lon: 18.82, elevation: 987 },
	{ lat: -28, lon: 19.64, elevation: 848 },
	{ lat: -28, lon: 20.45, elevation: 842 },
	{ lat: -28, lon: 21.27, elevation: 929 },
	{ lat: -28, lon: 22.09, elevation: 1039 },
	{ lat: -28, lon: 22.91, elevation: 1309 },
	{ lat: -28, lon: 23.73, elevation: 1521 },
	{ lat: -28, lon: 24.55, elevation: 1082 },
	{ lat: -28, lon: 25.36, elevation: 1279 },
	{ lat: -28, lon: 26.18, elevation: 1256 },
	{ lat: -28, lon: 27.0, elevation: 1383 },
	{ lat: -28, lon: 27.82, elevation: 1552 },
	{ lat: -28, lon: 28.64, elevation: 1725 },
	{ lat: -28, lon: 29.45, elevation: 1795 },
	{ lat: -28, lon: 30.27, elevation: 1206 },
	{ lat: -28, lon: 31.09, elevation: 846 },
	{ lat: -28, lon: 31.91, elevation: 343 },
	{ lat: -28, lon: 114.55, elevation: 252 },
	{ lat: -28, lon: 115.36, elevation: 261 },
	{ lat: -28, lon: 116.18, elevation: 305 },
	{ lat: -28, lon: 117.0, elevation: 468 },
	{ lat: -28, lon: 117.82, elevation: 473 },
	{ lat: -28, lon: 118.64, elevation: 449 },
	{ lat: -28, lon: 119.45, elevation: 547 },
	{ lat: -28, lon: 120.27, elevation: 512 },
	{ lat: -28, lon: 121.09, elevation: 465 },
	{ lat: -28, lon: 121.91, elevation: 472 },
	{ lat: -28, lon: 122.73, elevation: 566 },
	{ lat: -28, lon: 123.55, elevation: 409 },
	{ lat: -28, lon: 124.36, elevation: 354 },
	{ lat: -28, lon: 125.18, elevation: 420 },
	{ lat: -28, lon: 126.0, elevation: 419 },
	{ lat: -28, lon: 126.82, elevation: 354 },
	{ lat: -28, lon: 127.64, elevation: 372 },
	{ lat: -28, lon: 128.45, elevation: 389 },
	{ lat: -28, lon: 129.27, elevation: 420 },
	{ lat: -28, lon: 130.09, elevation: 379 },
	{ lat: -28, lon: 130.91, elevation: 376 },
	{ lat: -28, lon: 131.73, elevation: 380 },
	{ lat: -28, lon: 132.55, elevation: 314 },
	{ lat: -28, lon: 133.36, elevation: 271 },
	{ lat: -28, lon: 134.18, elevation: 277 },
	{ lat: -28, lon: 135.0, elevation: 122 },
	{ lat: -28, lon: 135.82, elevation: 80 },
	{ lat: -28, lon: 136.64, elevation: 47 },
	{ lat: -28, lon: 137.45, elevation: -1 },
	{ lat: -28, lon: 138.27, elevation: 19 },
	{ lat: -28, lon: 139.09, elevation: 43 },
	{ lat: -28, lon: 139.91, elevation: 33 },
	{ lat: -28, lon: 140.73, elevation: 81 },
	{ lat: -28, lon: 141.55, elevation: 103 },
	{ lat: -28, lon: 142.36, elevation: 94 },
	{ lat: -28, lon: 143.18, elevation: 171 },
	{ lat: -28, lon: 144.0, elevation: 165 },
	{ lat: -28, lon: 144.82, elevation: 194 },
	{ lat: -28, lon: 145.64, elevation: 196 },
	{ lat: -28, lon: 146.45, elevation: 186 },
	{ lat: -28, lon: 147.27, elevation: 188 },
	{ lat: -28, lon: 148.09, elevation: 210 },
	{ lat: -28, lon: 148.91, elevation: 214 },
	{ lat: -28, lon: 149.73, elevation: 233 },
	{ lat: -28, lon: 150.55, elevation: 311 },
	{ lat: -28, lon: 151.36, elevation: 438 },
	{ lat: -28, lon: 152.18, elevation: 693 },
	{ lat: -28, lon: 153.0, elevation: 62 },
	{ lat: -27, lon: -70.54, elevation: 817 },
	{ lat: -27, lon: -69.73, elevation: 2866 },
	{ lat: -27, lon: -68.92, elevation: 4426 },
	{ lat: -27, lon: -68.11, elevation: 4183 },
	{ lat: -27, lon: -67.3, elevation: 4180 },
	{ lat: -27, lon: -66.49, elevation: 2248 },
	{ lat: -27, lon: -65.68, elevation: 1872 },
	{ lat: -27, lon: -64.86, elevation: 393 },
	{ lat: -27, lon: -64.05, elevation: 188 },
	{ lat: -27, lon: -63.24, elevation: 177 },
	{ lat: -27, lon: -62.43, elevation: 164 },
	{ lat: -27, lon: -61.62, elevation: 128 },
	{ lat: -27, lon: -60.81, elevation: 98 },
	{ lat: -27, lon: -60.0, elevation: 77 },
	{ lat: -27, lon: -59.19, elevation: 64 },
	{ lat: -27, lon: -58.38, elevation: 60 },
	{ lat: -27, lon: -57.57, elevation: 68 },
	{ lat: -27, lon: -56.76, elevation: 154 },
	{ lat: -27, lon: -55.95, elevation: 168 },
	{ lat: -27, lon: -55.14, elevation: 197 },
	{ lat: -27, lon: -54.32, elevation: 421 },
	{ lat: -27, lon: -53.51, elevation: 446 },
	{ lat: -27, lon: -52.7, elevation: 640 },
	{ lat: -27, lon: -51.89, elevation: 1131 },
	{ lat: -27, lon: -51.08, elevation: 858 },
	{ lat: -27, lon: -50.27, elevation: 852 },
	{ lat: -27, lon: -49.46, elevation: 396 },
	{ lat: -27, lon: -48.65, elevation: 8 },
	{ lat: -27, lon: 15.41, elevation: 241 },
	{ lat: -27, lon: 16.22, elevation: 1073 },
	{ lat: -27, lon: 17.03, elevation: 1152 },
	{ lat: -27, lon: 17.84, elevation: 698 },
	{ lat: -27, lon: 18.65, elevation: 1043 },
	{ lat: -27, lon: 19.46, elevation: 965 },
	{ lat: -27, lon: 20.27, elevation: 788 },
	{ lat: -27, lon: 21.08, elevation: 890 },
	{ lat: -27, lon: 21.89, elevation: 931 },
	{ lat: -27, lon: 22.7, elevation: 1034 },
	{ lat: -27, lon: 23.51, elevation: 1244 },
	{ lat: -27, lon: 24.32, elevation: 1316 },
	{ lat: -27, lon: 25.14, elevation: 1302 },
	{ lat: -27, lon: 25.95, elevation: 1480 },
	{ lat: -27, lon: 26.76, elevation: 1320 },
	{ lat: -27, lon: 27.57, elevation: 1501 },
	{ lat: -27, lon: 28.38, elevation: 1527 },
	{ lat: -27, lon: 29.19, elevation: 1539 },
	{ lat: -27, lon: 30.0, elevation: 1668 },
	{ lat: -27, lon: 30.81, elevation: 1260 },
	{ lat: -27, lon: 31.62, elevation: 412 },
	{ lat: -27, lon: 32.43, elevation: 83 },
	{ lat: -27, lon: 114.32, elevation: 124 },
	{ lat: -27, lon: 115.14, elevation: 249 },
	{ lat: -27, lon: 115.95, elevation: 276 },
	{ lat: -27, lon: 116.76, elevation: 399 },
	{ lat: -27, lon: 117.57, elevation: 476 },
	{ lat: -27, lon: 118.38, elevation: 452 },
	{ lat: -27, lon: 119.19, elevation: 537 },
	{ lat: -27, lon: 120.0, elevation: 549 },
	{ lat: -27, lon: 120.81, elevation: 494 },
	{ lat: -27, lon: 121.62, elevation: 579 },
	{ lat: -27, lon: 122.43, elevation: 494 },
	{ lat: -27, lon: 123.24, elevation: 454 },
	{ lat: -27, lon: 124.05, elevation: 462 },
	{ lat: -27, lon: 124.86, elevation: 458 },
	{ lat: -27, lon: 125.68, elevation: 436 },
	{ lat: -27, lon: 126.49, elevation: 501 },
	{ lat: -27, lon: 127.3, elevation: 463 },
	{ lat: -27, lon: 128.11, elevation: 536 },
	{ lat: -27, lon: 128.92, elevation: 440 },
	{ lat: -27, lon: 129.73, elevation: 560 },
	{ lat: -27, lon: 130.54, elevation: 499 },
	{ lat: -27, lon: 131.35, elevation: 522 },
	{ lat: -27, lon: 132.16, elevation: 497 },
	{ lat: -27, lon: 132.97, elevation: 466 },
	{ lat: -27, lon: 133.78, elevation: 328 },
	{ lat: -27, lon: 134.59, elevation: 212 },
	{ lat: -27, lon: 135.41, elevation: 122 },
	{ lat: -27, lon: 136.22, elevation: 56 },
	{ lat: -27, lon: 137.03, elevation: 29 },
	{ lat: -27, lon: 137.84, elevation: -4 },
	{ lat: -27, lon: 138.65, elevation: 19 },
	{ lat: -27, lon: 139.46, elevation: 51 },
	{ lat: -27, lon: 140.27, elevation: 34 },
	{ lat: -27, lon: 141.08, elevation: 94 },
	{ lat: -27, lon: 141.89, elevation: 84 },
	{ lat: -27, lon: 142.7, elevation: 208 },
	{ lat: -27, lon: 143.51, elevation: 191 },
	{ lat: -27, lon: 144.32, elevation: 178 },
	{ lat: -27, lon: 145.14, elevation: 226 },
	{ lat: -27, lon: 145.95, elevation: 252 },
	{ lat: -27, lon: 146.76, elevation: 337 },
	{ lat: -27, lon: 147.57, elevation: 354 },
	{ lat: -27, lon: 148.38, elevation: 350 },
	{ lat: -27, lon: 149.19, elevation: 248 },
	{ lat: -27, lon: 150.0, elevation: 275 },
	{ lat: -27, lon: 150.81, elevation: 351 },
	{ lat: -27, lon: 151.62, elevation: 496 },
	{ lat: -27, lon: 152.43, elevation: 361 },
	{ lat: -26, lon: -69.91, elevation: 1314 },
	{ lat: -26, lon: -69.11, elevation: 4006 },
	{ lat: -26, lon: -68.3, elevation: 4375 },
	{ lat: -26, lon: -67.5, elevation: 3644 },
	{ lat: -26, lon: -66.7, elevation: 4366 },
	{ lat: -26, lon: -65.89, elevation: 1559 },
	{ lat: -26, lon: -65.09, elevation: 999 },
	{ lat: -26, lon: -64.29, elevation: 374 },
	{ lat: -26, lon: -63.48, elevation: 249 },
	{ lat: -26, lon: -62.68, elevation: 208 },
	{ lat: -26, lon: -61.88, elevation: 167 },
	{ lat: -26, lon: -61.07, elevation: 131 },
	{ lat: -26, lon: -60.27, elevation: 108 },
	{ lat: -26, lon: -59.46, elevation: 89 },
	{ lat: -26, lon: -58.66, elevation: 76 },
	{ lat: -26, lon: -57.86, elevation: 55 },
	{ lat: -26, lon: -57.05, elevation: 146 },
	{ lat: -26, lon: -56.25, elevation: 148 },
	{ lat: -26, lon: -55.45, elevation: 315 },
	{ lat: -26, lon: -54.64, elevation: 213 },
	{ lat: -26, lon: -53.84, elevation: 511 },
	{ lat: -26, lon: -53.04, elevation: 553 },
	{ lat: -26, lon: -52.23, elevation: 906 },
	{ lat: -26, lon: -51.43, elevation: 1007 },
	{ lat: -26, lon: -50.62, elevation: 808 },
	{ lat: -26, lon: -49.82, elevation: 855 },
	{ lat: -26, lon: -49.02, elevation: 944 },
	{ lat: -26, lon: 15.27, elevation: 541 },
	{ lat: -26, lon: 16.07, elevation: 918 },
	{ lat: -26, lon: 16.88, elevation: 1315 },
	{ lat: -26, lon: 17.68, elevation: 974 },
	{ lat: -26, lon: 18.48, elevation: 1038 },
	{ lat: -26, lon: 19.29, elevation: 1003 },
	{ lat: -26, lon: 20.09, elevation: 962 },
	{ lat: -26, lon: 20.89, elevation: 926 },
	{ lat: -26, lon: 21.7, elevation: 982 },
	{ lat: -26, lon: 22.5, elevation: 982 },
	{ lat: -26, lon: 23.3, elevation: 1054 },
	{ lat: -26, lon: 24.11, elevation: 1110 },
	{ lat: -26, lon: 24.91, elevation: 1190 },
	{ lat: -26, lon: 25.71, elevation: 1371 },
	{ lat: -26, lon: 26.52, elevation: 1535 },
	{ lat: -26, lon: 27.32, elevation: 1605 },
	{ lat: -26, lon: 28.12, elevation: 1559 },
	{ lat: -26, lon: 28.93, elevation: 1519 },
	{ lat: -26, lon: 29.73, elevation: 1595 },
	{ lat: -26, lon: 30.54, elevation: 1269 },
	{ lat: -26, lon: 31.34, elevation: 962 },
	{ lat: -26, lon: 32.14, elevation: 124 },
	{ lat: -26, lon: 32.95, elevation: 7 },
	{ lat: -26, lon: 114.91, elevation: 137 },
	{ lat: -26, lon: 115.71, elevation: 281 },
	{ lat: -26, lon: 116.52, elevation: 361 },
	{ lat: -26, lon: 117.32, elevation: 477 },
	{ lat: -26, lon: 118.12, elevation: 472 },
	{ lat: -26, lon: 118.93, elevation: 536 },
	{ lat: -26, lon: 119.73, elevation: 650 },
	{ lat: -26, lon: 120.54, elevation: 576 },
	{ lat: -26, lon: 121.34, elevation: 556 },
	{ lat: -26, lon: 122.14, elevation: 452 },
	{ lat: -26, lon: 122.95, elevation: 473 },
	{ lat: -26, lon: 123.75, elevation: 468 },
	{ lat: -26, lon: 124.55, elevation: 446 },
	{ lat: -26, lon: 125.36, elevation: 411 },
	{ lat: -26, lon: 126.16, elevation: 475 },
	{ lat: -26, lon: 126.96, elevation: 516 },
	{ lat: -26, lon: 127.77, elevation: 504 },
	{ lat: -26, lon: 128.57, elevation: 665 },
	{ lat: -26, lon: 129.38, elevation: 696 },
	{ lat: -26, lon: 130.18, elevation: 917 },
	{ lat: -26, lon: 130.98, elevation: 713 },
	{ lat: -26, lon: 131.79, elevation: 642 },
	{ lat: -26, lon: 132.59, elevation: 579 },
	{ lat: -26, lon: 133.39, elevation: 475 },
	{ lat: -26, lon: 134.2, elevation: 358 },
	{ lat: -26, lon: 135.0, elevation: 193 },
	{ lat: -26, lon: 135.8, elevation: 122 },
	{ lat: -26, lon: 136.61, elevation: 81 },
	{ lat: -26, lon: 137.41, elevation: 56 },
	{ lat: -26, lon: 138.21, elevation: 37 },
	{ lat: -26, lon: 139.02, elevation: 60 },
	{ lat: -26, lon: 139.82, elevation: 51 },
	{ lat: -26, lon: 140.62, elevation: 105 },
	{ lat: -26, lon: 141.43, elevation: 128 },
	{ lat: -26, lon: 142.23, elevation: 110 },
	{ lat: -26, lon: 143.04, elevation: 146 },
	{ lat: -26, lon: 143.84, elevation: 224 },
	{ lat: -26, lon: 144.64, elevation: 244 },
	{ lat: -26, lon: 145.45, elevation: 329 },
	{ lat: -26, lon: 146.25, elevation: 386 },
	{ lat: -26, lon: 147.05, elevation: 472 },
	{ lat: -26, lon: 147.86, elevation: 401 },
	{ lat: -26, lon: 148.66, elevation: 399 },
	{ lat: -26, lon: 149.46, elevation: 251 },
	{ lat: -26, lon: 150.27, elevation: 313 },
	{ lat: -26, lon: 151.07, elevation: 353 },
	{ lat: -26, lon: 151.88, elevation: 266 },
	{ lat: -26, lon: 152.68, elevation: 81 },
	{ lat: -25, lon: -70.09, elevation: 2156 },
	{ lat: -25, lon: -69.29, elevation: 3108 },
	{ lat: -25, lon: -68.5, elevation: 4749 },
	{ lat: -25, lon: -67.7, elevation: 3469 },
	{ lat: -25, lon: -66.9, elevation: 4739 },
	{ lat: -25, lon: -66.11, elevation: 2505 },
	{ lat: -25, lon: -65.31, elevation: 1750 },
	{ lat: -25, lon: -64.51, elevation: 681 },
	{ lat: -25, lon: -63.72, elevation: 315 },
	{ lat: -25, lon: -62.92, elevation: 243 },
	{ lat: -25, lon: -62.12, elevation: 184 },
	{ lat: -25, lon: -61.33, elevation: 150 },
	{ lat: -25, lon: -60.53, elevation: 132 },
	{ lat: -25, lon: -59.73, elevation: 112 },
	{ lat: -25, lon: -58.94, elevation: 92 },
	{ lat: -25, lon: -58.14, elevation: 85 },
	{ lat: -25, lon: -57.35, elevation: 63 },
	{ lat: -25, lon: -56.55, elevation: 95 },
	{ lat: -25, lon: -55.75, elevation: 281 },
	{ lat: -25, lon: -54.96, elevation: 327 },
	{ lat: -25, lon: -54.16, elevation: 308 },
	{ lat: -25, lon: -53.36, elevation: 787 },
	{ lat: -25, lon: -52.57, elevation: 469 },
	{ lat: -25, lon: -51.77, elevation: 958 },
	{ lat: -25, lon: -50.97, elevation: 761 },
	{ lat: -25, lon: -50.18, elevation: 866 },
	{ lat: -25, lon: -49.38, elevation: 604 },
	{ lat: -25, lon: -48.58, elevation: 443 },
	{ lat: -25, lon: 15.13, elevation: 534 },
	{ lat: -25, lon: 15.93, elevation: 1035 },
	{ lat: -25, lon: 16.73, elevation: 1392 },
	{ lat: -25, lon: 17.52, elevation: 1186 },
	{ lat: -25, lon: 18.32, elevation: 1188 },
	{ lat: -25, lon: 19.12, elevation: 1096 },
	{ lat: -25, lon: 19.91, elevation: 1055 },
	{ lat: -25, lon: 20.71, elevation: 1026 },
	{ lat: -25, lon: 21.5, elevation: 1050 },
	{ lat: -25, lon: 22.3, elevation: 1047 },
	{ lat: -25, lon: 23.1, elevation: 1028 },
	{ lat: -25, lon: 23.89, elevation: 1062 },
	{ lat: -25, lon: 24.69, elevation: 1150 },
	{ lat: -25, lon: 25.49, elevation: 1147 },
	{ lat: -25, lon: 26.28, elevation: 1049 },
	{ lat: -25, lon: 27.08, elevation: 1022 },
	{ lat: -25, lon: 27.88, elevation: 1099 },
	{ lat: -25, lon: 28.67, elevation: 1019 },
	{ lat: -25, lon: 29.47, elevation: 1171 },
	{ lat: -25, lon: 30.27, elevation: 1653 },
	{ lat: -25, lon: 31.06, elevation: 803 },
	{ lat: -25, lon: 31.86, elevation: 225 },
	{ lat: -25, lon: 32.65, elevation: 41 },
	{ lat: -25, lon: 33.45, elevation: 54 },
	{ lat: -25, lon: 44.6, elevation: 177 },
	{ lat: -25, lon: 45.4, elevation: 173 },
	{ lat: -25, lon: 46.19, elevation: 175 },
	{ lat: -25, lon: 46.99, elevation: 0 },
	{ lat: -25, lon: 113.89, elevation: 24 },
	{ lat: -25, lon: 114.69, elevation: 106 },
	{ lat: -25, lon: 115.49, elevation: 188 },
	{ lat: -25, lon: 116.28, elevation: 353 },
	{ lat: -25, lon: 117.08, elevation: 400 },
	{ lat: -25, lon: 117.88, elevation: 441 },
	{ lat: -25, lon: 118.67, elevation: 533 },
	{ lat: -25, lon: 119.47, elevation: 561 },
	{ lat: -25, lon: 120.27, elevation: 583 },
	{ lat: -25, lon: 121.06, elevation: 590 },
	{ lat: -25, lon: 121.86, elevation: 550 },
	{ lat: -25, lon: 122.65, elevation: 516 },
	{ lat: -25, lon: 123.45, elevation: 433 },
	{ lat: -25, lon: 124.25, elevation: 442 },
	{ lat: -25, lon: 125.04, elevation: 502 },
	{ lat: -25, lon: 125.84, elevation: 444 },
	{ lat: -25, lon: 126.64, elevation: 472 },
	{ lat: -25, lon: 127.43, elevation: 441 },
	{ lat: -25, lon: 128.23, elevation: 581 },
	{ lat: -25, lon: 129.03, elevation: 710 },
	{ lat: -25, lon: 129.82, elevation: 601 },
	{ lat: -25, lon: 130.62, elevation: 509 },
	{ lat: -25, lon: 131.42, elevation: 472 },
	{ lat: -25, lon: 132.21, elevation: 522 },
	{ lat: -25, lon: 133.01, elevation: 497 },
	{ lat: -25, lon: 133.81, elevation: 372 },
	{ lat: -25, lon: 134.6, elevation: 302 },
	{ lat: -25, lon: 135.4, elevation: 192 },
	{ lat: -25, lon: 136.19, elevation: 158 },
	{ lat: -25, lon: 136.99, elevation: 124 },
	{ lat: -25, lon: 137.79, elevation: 82 },
	{ lat: -25, lon: 138.58, elevation: 59 },
	{ lat: -25, lon: 139.38, elevation: 94 },
	{ lat: -25, lon: 140.18, elevation: 70 },
	{ lat: -25, lon: 140.97, elevation: 111 },
	{ lat: -25, lon: 141.77, elevation: 153 },
	{ lat: -25, lon: 142.57, elevation: 173 },
	{ lat: -25, lon: 143.36, elevation: 190 },
	{ lat: -25, lon: 144.16, elevation: 238 },
	{ lat: -25, lon: 144.96, elevation: 328 },
	{ lat: -25, lon: 145.75, elevation: 426 },
	{ lat: -25, lon: 146.55, elevation: 479 },
	{ lat: -25, lon: 147.35, elevation: 583 },
	{ lat: -25, lon: 148.14, elevation: 659 },
	{ lat: -25, lon: 148.94, elevation: 395 },
	{ lat: -25, lon: 149.73, elevation: 342 },
	{ lat: -25, lon: 150.53, elevation: 421 },
	{ lat: -25, lon: 151.33, elevation: 315 },
	{ lat: -25, lon: 152.12, elevation: 58 },
	{ lat: -24, lon: -70.02, elevation: 1450 },
	{ lat: -24, lon: -69.23, elevation: 2174 },
	{ lat: -24, lon: -68.44, elevation: 3490 },
	{ lat: -24, lon: -67.65, elevation: 4520 },
	{ lat: -24, lon: -66.86, elevation: 3989 },
	{ lat: -24, lon: -66.07, elevation: 4129 },
	{ lat: -24, lon: -65.27, elevation: 1729 },
	{ lat: -24, lon: -64.48, elevation: 1547 },
	{ lat: -24, lon: -63.69, elevation: 270 },
	{ lat: -24, lon: -62.9, elevation: 212 },
	{ lat: -24, lon: -62.11, elevation: 190 },
	{ lat: -24, lon: -61.32, elevation: 168 },
	{ lat: -24, lon: -60.53, elevation: 144 },
	{ lat: -24, lon: -59.74, elevation: 121 },
	{ lat: -24, lon: -58.95, elevation: 100 },
	{ lat: -24, lon: -58.15, elevation: 89 },
	{ lat: -24, lon: -57.36, elevation: 68 },
	{ lat: -24, lon: -56.57, elevation: 126 },
	{ lat: -24, lon: -55.78, elevation: 181 },
	{ lat: -24, lon: -54.99, elevation: 367 },
	{ lat: -24, lon: -54.2, elevation: 223 },
	{ lat: -24, lon: -53.41, elevation: 436 },
	{ lat: -24, lon: -52.62, elevation: 572 },
	{ lat: -24, lon: -51.82, elevation: 482 },
	{ lat: -24, lon: -51.03, elevation: 859 },
	{ lat: -24, lon: -50.24, elevation: 659 },
	{ lat: -24, lon: -49.45, elevation: 542 },
	{ lat: -24, lon: -48.66, elevation: 712 },
	{ lat: -24, lon: -47.87, elevation: 823 },
	{ lat: -24, lon: -47.08, elevation: 756 },
	{ lat: -24, lon: -46.29, elevation: 3 },
	{ lat: -24, lon: 14.64, elevation: -19 },
	{ lat: -24, lon: 15.43, elevation: 676 },
	{ lat: -24, lon: 16.22, elevation: 1340 },
	{ lat: -24, lon: 17.01, elevation: 1420 },
	{ lat: -24, lon: 17.8, elevation: 1232 },
	{ lat: -24, lon: 18.59, elevation: 1221 },
	{ lat: -24, lon: 19.38, elevation: 1208 },
	{ lat: -24, lon: 20.18, elevation: 1193 },
	{ lat: -24, lon: 20.97, elevation: 1152 },
	{ lat: -24, lon: 21.76, elevation: 1133 },
	{ lat: -24, lon: 22.55, elevation: 1115 },
	{ lat: -24, lon: 23.34, elevation: 1114 },
	{ lat: -24, lon: 24.13, elevation: 1107 },
	{ lat: -24, lon: 24.92, elevation: 1123 },
	{ lat: -24, lon: 25.71, elevation: 1103 },
	{ lat: -24, lon: 26.51, elevation: 904 },
	{ lat: -24, lon: 27.3, elevation: 941 },
	{ lat: -24, lon: 28.09, elevation: 1366 },
	{ lat: -24, lon: 28.88, elevation: 1068 },
	{ lat: -24, lon: 29.67, elevation: 1279 },
	{ lat: -24, lon: 30.46, elevation: 598 },
	{ lat: -24, lon: 31.25, elevation: 348 },
	{ lat: -24, lon: 32.04, elevation: 239 },
	{ lat: -24, lon: 32.84, elevation: 77 },
	{ lat: -24, lon: 33.63, elevation: 49 },
	{ lat: -24, lon: 34.42, elevation: 72 },
	{ lat: -24, lon: 35.21, elevation: 83 },
	{ lat: -24, lon: 43.91, elevation: 99 },
	{ lat: -24, lon: 44.7, elevation: 419 },
	{ lat: -24, lon: 45.49, elevation: 538 },
	{ lat: -24, lon: 46.29, elevation: 388 },
	{ lat: -24, lon: 47.08, elevation: 1524 },
	{ lat: -24, lon: 113.54, elevation: 7 },
	{ lat: -24, lon: 114.33, elevation: 70 },
	{ lat: -24, lon: 115.12, elevation: 198 },
	{ lat: -24, lon: 115.91, elevation: 289 },
	{ lat: -24, lon: 116.7, elevation: 442 },
	{ lat: -24, lon: 117.49, elevation: 467 },
	{ lat: -24, lon: 118.29, elevation: 524 },
	{ lat: -24, lon: 119.08, elevation: 509 },
	{ lat: -24, lon: 119.87, elevation: 611 },
	{ lat: -24, lon: 120.66, elevation: 564 },
	{ lat: -24, lon: 121.45, elevation: 539 },
	{ lat: -24, lon: 122.24, elevation: 481 },
	{ lat: -24, lon: 123.03, elevation: 360 },
	{ lat: -24, lon: 123.82, elevation: 400 },
	{ lat: -24, lon: 124.62, elevation: 413 },
	{ lat: -24, lon: 125.41, elevation: 414 },
	{ lat: -24, lon: 126.2, elevation: 391 },
	{ lat: -24, lon: 126.99, elevation: 437 },
	{ lat: -24, lon: 127.78, elevation: 474 },
	{ lat: -24, lon: 128.57, elevation: 575 },
	{ lat: -24, lon: 129.36, elevation: 506 },
	{ lat: -24, lon: 130.15, elevation: 604 },
	{ lat: -24, lon: 130.95, elevation: 717 },
	{ lat: -24, lon: 131.74, elevation: 801 },
	{ lat: -24, lon: 132.53, elevation: 928 },
	{ lat: -24, lon: 133.32, elevation: 656 },
	{ lat: -24, lon: 134.11, elevation: 543 },
	{ lat: -24, lon: 134.9, elevation: 351 },
	{ lat: -24, lon: 135.69, elevation: 277 },
	{ lat: -24, lon: 136.48, elevation: 204 },
	{ lat: -24, lon: 137.27, elevation: 144 },
	{ lat: -24, lon: 138.07, elevation: 113 },
	{ lat: -24, lon: 138.86, elevation: 91 },
	{ lat: -24, lon: 139.65, elevation: 102 },
	{ lat: -24, lon: 140.44, elevation: 144 },
	{ lat: -24, lon: 141.23, elevation: 108 },
	{ lat: -24, lon: 142.02, elevation: 220 },
	{ lat: -24, lon: 142.81, elevation: 262 },
	{ lat: -24, lon: 143.6, elevation: 192 },
	{ lat: -24, lon: 144.4, elevation: 230 },
	{ lat: -24, lon: 145.19, elevation: 271 },
	{ lat: -24, lon: 145.98, elevation: 435 },
	{ lat: -24, lon: 146.77, elevation: 437 },
	{ lat: -24, lon: 147.56, elevation: 274 },
	{ lat: -24, lon: 148.35, elevation: 193 },
	{ lat: -24, lon: 149.14, elevation: 461 },
	{ lat: -24, lon: 149.93, elevation: 86 },
	{ lat: -24, lon: 150.73, elevation: 206 },
	{ lat: -23, lon: -70.2, elevation: 729 },
	{ lat: -23, lon: -69.41, elevation: 1483 },
	{ lat: -23, lon: -68.63, elevation: 3071 },
	{ lat: -23, lon: -67.84, elevation: 4422 },
	{ lat: -23, lon: -67.06, elevation: 4712 },
	{ lat: -23, lon: -66.27, elevation: 5002 },
	{ lat: -23, lon: -65.49, elevation: 3678 },
	{ lat: -23, lon: -64.71, elevation: 969 },
	{ lat: -23, lon: -63.92, elevation: 352 },
	{ lat: -23, lon: -63.14, elevation: 237 },
	{ lat: -23, lon: -62.35, elevation: 224 },
	{ lat: -23, lon: -61.57, elevation: 194 },
	{ lat: -23, lon: -60.78, elevation: 165 },
	{ lat: -23, lon: -60.0, elevation: 133 },
	{ lat: -23, lon: -59.22, elevation: 111 },
	{ lat: -23, lon: -58.43, elevation: 92 },
	{ lat: -23, lon: -57.65, elevation: 79 },
	{ lat: -23, lon: -56.86, elevation: 117 },
	{ lat: -23, lon: -56.08, elevation: 240 },
	{ lat: -23, lon: -55.29, elevation: 406 },
	{ lat: -23, lon: -54.51, elevation: 263 },
	{ lat: -23, lon: -53.73, elevation: 241 },
	{ lat: -23, lon: -52.94, elevation: 372 },
	{ lat: -23, lon: -52.16, elevation: 488 },
	{ lat: -23, lon: -51.37, elevation: 438 },
	{ lat: -23, lon: -50.59, elevation: 395 },
	{ lat: -23, lon: -49.8, elevation: 464 },
	{ lat: -23, lon: -49.02, elevation: 698 },
	{ lat: -23, lon: -48.24, elevation: 577 },
	{ lat: -23, lon: -47.45, elevation: 573 },
	{ lat: -23, lon: -46.67, elevation: 752 },
	{ lat: -23, lon: -45.88, elevation: 815 },
	{ lat: -23, lon: -45.1, elevation: 905 },
	{ lat: -23, lon: -44.31, elevation: 162 },
	{ lat: -23, lon: -43.53, elevation: 130 },
	{ lat: -23, lon: 14.51, elevation: 11 },
	{ lat: -23, lon: 15.29, elevation: 690 },
	{ lat: -23, lon: 16.08, elevation: 1185 },
	{ lat: -23, lon: 16.86, elevation: 1748 },
	{ lat: -23, lon: 17.65, elevation: 1479 },
	{ lat: -23, lon: 18.43, elevation: 1360 },
	{ lat: -23, lon: 19.22, elevation: 1427 },
	{ lat: -23, lon: 20.0, elevation: 1288 },
	{ lat: -23, lon: 20.78, elevation: 1259 },
	{ lat: -23, lon: 21.57, elevation: 1208 },
	{ lat: -23, lon: 22.35, elevation: 1137 },
	{ lat: -23, lon: 23.14, elevation: 1058 },
	{ lat: -23, lon: 23.92, elevation: 1010 },
	{ lat: -23, lon: 24.71, elevation: 1002 },
	{ lat: -23, lon: 25.49, elevation: 1084 },
	{ lat: -23, lon: 26.27, elevation: 1164 },
	{ lat: -23, lon: 27.06, elevation: 1014 },
	{ lat: -23, lon: 27.84, elevation: 846 },
	{ lat: -23, lon: 28.63, elevation: 822 },
	{ lat: -23, lon: 29.41, elevation: 1333 },
	{ lat: -23, lon: 30.2, elevation: 1075 },
	{ lat: -23, lon: 30.98, elevation: 392 },
	{ lat: -23, lon: 31.76, elevation: 352 },
	{ lat: -23, lon: 32.55, elevation: 141 },
	{ lat: -23, lon: 33.33, elevation: 76 },
	{ lat: -23, lon: 34.12, elevation: 124 },
	{ lat: -23, lon: 34.9, elevation: 107 },
	{ lat: -23, lon: 43.53, elevation: 25 },
	{ lat: -23, lon: 44.31, elevation: 441 },
	{ lat: -23, lon: 45.1, elevation: 713 },
	{ lat: -23, lon: 45.88, elevation: 851 },
	{ lat: -23, lon: 46.67, elevation: 952 },
	{ lat: -23, lon: 47.45, elevation: 144 },
	{ lat: -23, lon: 114.12, elevation: 108 },
	{ lat: -23, lon: 114.9, elevation: 97 },
	{ lat: -23, lon: 115.69, elevation: 171 },
	{ lat: -23, lon: 116.47, elevation: 196 },
	{ lat: -23, lon: 117.25, elevation: 470 },
	{ lat: -23, lon: 118.04, elevation: 552 },
	{ lat: -23, lon: 118.82, elevation: 699 },
	{ lat: -23, lon: 119.61, elevation: 641 },
	{ lat: -23, lon: 120.39, elevation: 443 },
	{ lat: -23, lon: 121.18, elevation: 537 },
	{ lat: -23, lon: 121.96, elevation: 410 },
	{ lat: -23, lon: 122.75, elevation: 402 },
	{ lat: -23, lon: 123.53, elevation: 319 },
	{ lat: -23, lon: 124.31, elevation: 380 },
	{ lat: -23, lon: 125.1, elevation: 340 },
	{ lat: -23, lon: 125.88, elevation: 387 },
	{ lat: -23, lon: 126.67, elevation: 370 },
	{ lat: -23, lon: 127.45, elevation: 424 },
	{ lat: -23, lon: 128.24, elevation: 444 },
	{ lat: -23, lon: 129.02, elevation: 419 },
	{ lat: -23, lon: 129.8, elevation: 464 },
	{ lat: -23, lon: 130.59, elevation: 528 },
	{ lat: -23, lon: 131.37, elevation: 562 },
	{ lat: -23, lon: 132.16, elevation: 579 },
	{ lat: -23, lon: 132.94, elevation: 580 },
	{ lat: -23, lon: 133.73, elevation: 676 },
	{ lat: -23, lon: 134.51, elevation: 640 },
	{ lat: -23, lon: 135.29, elevation: 515 },
	{ lat: -23, lon: 136.08, elevation: 340 },
	{ lat: -23, lon: 136.86, elevation: 237 },
	{ lat: -23, lon: 137.65, elevation: 200 },
	{ lat: -23, lon: 138.43, elevation: 200 },
	{ lat: -23, lon: 139.22, elevation: 142 },
	{ lat: -23, lon: 140.0, elevation: 151 },
	{ lat: -23, lon: 140.78, elevation: 203 },
	{ lat: -23, lon: 141.57, elevation: 133 },
	{ lat: -23, lon: 142.35, elevation: 201 },
	{ lat: -23, lon: 143.14, elevation: 267 },
	{ lat: -23, lon: 143.92, elevation: 237 },
	{ lat: -23, lon: 144.71, elevation: 216 },
	{ lat: -23, lon: 145.49, elevation: 272 },
	{ lat: -23, lon: 146.27, elevation: 406 },
	{ lat: -23, lon: 147.06, elevation: 411 },
	{ lat: -23, lon: 147.84, elevation: 241 },
	{ lat: -23, lon: 148.63, elevation: 158 },
	{ lat: -23, lon: 149.41, elevation: 96 },
	{ lat: -23, lon: 150.2, elevation: 79 },
	{ lat: -22, lon: -70.13, elevation: 1394 },
	{ lat: -22, lon: -69.35, elevation: 1278 },
	{ lat: -22, lon: -68.57, elevation: 3123 },
	{ lat: -22, lon: -67.79, elevation: 5161 },
	{ lat: -22, lon: -67.01, elevation: 4983 },
	{ lat: -22, lon: -66.23, elevation: 4164 },
	{ lat: -22, lon: -65.45, elevation: 3374 },
	{ lat: -22, lon: -64.68, elevation: 1980 },
	{ lat: -22, lon: -63.9, elevation: 1208 },
	{ lat: -22, lon: -63.12, elevation: 335 },
	{ lat: -22, lon: -62.34, elevation: 262 },
	{ lat: -22, lon: -61.56, elevation: 217 },
	{ lat: -22, lon: -60.78, elevation: 178 },
	{ lat: -22, lon: -60.0, elevation: 142 },
	{ lat: -22, lon: -59.22, elevation: 116 },
	{ lat: -22, lon: -58.44, elevation: 96 },
	{ lat: -22, lon: -57.66, elevation: 97 },
	{ lat: -22, lon: -56.88, elevation: 331 },
	{ lat: -22, lon: -56.1, elevation: 390 },
	{ lat: -22, lon: -55.32, elevation: 444 },
	{ lat: -22, lon: -54.55, elevation: 307 },
	{ lat: -22, lon: -53.77, elevation: 270 },
	{ lat: -22, lon: -52.99, elevation: 389 },
	{ lat: -22, lon: -52.21, elevation: 346 },
	{ lat: -22, lon: -51.43, elevation: 373 },
	{ lat: -22, lon: -50.65, elevation: 451 },
	{ lat: -22, lon: -49.87, elevation: 449 },
	{ lat: -22, lon: -49.09, elevation: 511 },
	{ lat: -22, lon: -48.31, elevation: 527 },
	{ lat: -22, lon: -47.53, elevation: 736 },
	{ lat: -22, lon: -46.75, elevation: 818 },
	{ lat: -22, lon: -45.97, elevation: 1333 },
	{ lat: -22, lon: -45.19, elevation: 1043 },
	{ lat: -22, lon: -44.42, elevation: 1262 },
	{ lat: -22, lon: -43.64, elevation: 1014 },
	{ lat: -22, lon: -42.86, elevation: 419 },
	{ lat: -22, lon: -42.08, elevation: 571 },
	{ lat: -22, lon: -41.3, elevation: 0 },
	{ lat: -22, lon: 14.81, elevation: 621 },
	{ lat: -22, lon: 15.58, elevation: 869 },
	{ lat: -22, lon: 16.36, elevation: 1378 },
	{ lat: -22, lon: 17.14, elevation: 1561 },
	{ lat: -22, lon: 17.92, elevation: 1672 },
	{ lat: -22, lon: 18.7, elevation: 1562 },
	{ lat: -22, lon: 19.48, elevation: 1476 },
	{ lat: -22, lon: 20.26, elevation: 1325 },
	{ lat: -22, lon: 21.04, elevation: 1194 },
	{ lat: -22, lon: 21.82, elevation: 1103 },
	{ lat: -22, lon: 22.6, elevation: 1040 },
	{ lat: -22, lon: 23.38, elevation: 1007 },
	{ lat: -22, lon: 24.16, elevation: 982 },
	{ lat: -22, lon: 24.94, elevation: 1034 },
	{ lat: -22, lon: 25.71, elevation: 1093 },
	{ lat: -22, lon: 26.49, elevation: 1165 },
	{ lat: -22, lon: 27.27, elevation: 981 },
	{ lat: -22, lon: 28.05, elevation: 846 },
	{ lat: -22, lon: 28.83, elevation: 682 },
	{ lat: -22, lon: 29.61, elevation: 581 },
	{ lat: -22, lon: 30.39, elevation: 594 },
	{ lat: -22, lon: 31.17, elevation: 562 },
	{ lat: -22, lon: 31.95, elevation: 389 },
	{ lat: -22, lon: 32.73, elevation: 162 },
	{ lat: -22, lon: 33.51, elevation: 151 },
	{ lat: -22, lon: 34.29, elevation: 179 },
	{ lat: -22, lon: 35.06, elevation: 32 },
	{ lat: -22, lon: 43.64, elevation: 51 },
	{ lat: -22, lon: 44.42, elevation: 234 },
	{ lat: -22, lon: 45.19, elevation: 311 },
	{ lat: -22, lon: 45.97, elevation: 677 },
	{ lat: -22, lon: 46.75, elevation: 802 },
	{ lat: -22, lon: 47.53, elevation: 182 },
	{ lat: -22, lon: 115.32, elevation: 37 },
	{ lat: -22, lon: 116.1, elevation: 162 },
	{ lat: -22, lon: 116.88, elevation: 429 },
	{ lat: -22, lon: 117.66, elevation: 432 },
	{ lat: -22, lon: 118.44, elevation: 470 },
	{ lat: -22, lon: 119.22, elevation: 409 },
	{ lat: -22, lon: 120.0, elevation: 455 },
	{ lat: -22, lon: 120.78, elevation: 400 },
	{ lat: -22, lon: 121.56, elevation: 364 },
	{ lat: -22, lon: 122.34, elevation: 289 },
	{ lat: -22, lon: 123.12, elevation: 244 },
	{ lat: -22, lon: 123.9, elevation: 281 },
	{ lat: -22, lon: 124.68, elevation: 354 },
	{ lat: -22, lon: 125.45, elevation: 346 },
	{ lat: -22, lon: 126.23, elevation: 310 },
	{ lat: -22, lon: 127.01, elevation: 425 },
	{ lat: -22, lon: 127.79, elevation: 431 },
	{ lat: -22, lon: 128.57, elevation: 376 },
	{ lat: -22, lon: 129.35, elevation: 381 },
	{ lat: -22, lon: 130.13, elevation: 527 },
	{ lat: -22, lon: 130.91, elevation: 586 },
	{ lat: -22, lon: 131.69, elevation: 614 },
	{ lat: -22, lon: 132.47, elevation: 596 },
	{ lat: -22, lon: 133.25, elevation: 558 },
	{ lat: -22, lon: 134.03, elevation: 506 },
	{ lat: -22, lon: 134.81, elevation: 452 },
	{ lat: -22, lon: 135.58, elevation: 379 },
	{ lat: -22, lon: 136.36, elevation: 373 },
	{ lat: -22, lon: 137.14, elevation: 252 },
	{ lat: -22, lon: 137.92, elevation: 210 },
	{ lat: -22, lon: 138.7, elevation: 176 },
	{ lat: -22, lon: 139.48, elevation: 290 },
	{ lat: -22, lon: 140.26, elevation: 251 },
	{ lat: -22, lon: 141.04, elevation: 272 },
	{ lat: -22, lon: 141.82, elevation: 265 },
	{ lat: -22, lon: 142.6, elevation: 197 },
	{ lat: -22, lon: 143.38, elevation: 238 },
	{ lat: -22, lon: 144.16, elevation: 263 },
	{ lat: -22, lon: 144.94, elevation: 277 },
	{ lat: -22, lon: 145.71, elevation: 405 },
	{ lat: -22, lon: 146.49, elevation: 221 },
	{ lat: -22, lon: 147.27, elevation: 241 },
	{ lat: -22, lon: 148.05, elevation: 260 },
	{ lat: -22, lon: 148.83, elevation: 153 },
	{ lat: -21, lon: -70.06, elevation: 819 },
	{ lat: -21, lon: -69.29, elevation: 1389 },
	{ lat: -21, lon: -68.52, elevation: 4214 },
	{ lat: -21, lon: -67.74, elevation: 3671 },
	{ lat: -21, lon: -66.97, elevation: 3700 },
	{ lat: -21, lon: -66.19, elevation: 3821 },
	{ lat: -21, lon: -65.42, elevation: 3924 },
	{ lat: -21, lon: -64.65, elevation: 1782 },
	{ lat: -21, lon: -63.87, elevation: 689 },
	{ lat: -21, lon: -63.1, elevation: 662 },
	{ lat: -21, lon: -62.32, elevation: 346 },
	{ lat: -21, lon: -61.55, elevation: 253 },
	{ lat: -21, lon: -60.77, elevation: 175 },
	{ lat: -21, lon: -60.0, elevation: 133 },
	{ lat: -21, lon: -59.23, elevation: 108 },
	{ lat: -21, lon: -58.45, elevation: 97 },
	{ lat: -21, lon: -57.68, elevation: 91 },
	{ lat: -21, lon: -56.9, elevation: 301 },
	{ lat: -21, lon: -56.13, elevation: 235 },
	{ lat: -21, lon: -55.35, elevation: 475 },
	{ lat: -21, lon: -54.58, elevation: 452 },
	{ lat: -21, lon: -53.81, elevation: 463 },
	{ lat: -21, lon: -53.03, elevation: 431 },
	{ lat: -21, lon: -52.26, elevation: 317 },
	{ lat: -21, lon: -51.48, elevation: 340 },
	{ lat: -21, lon: -50.71, elevation: 394 },
	{ lat: -21, lon: -49.94, elevation: 446 },
	{ lat: -21, lon: -49.16, elevation: 524 },
	{ lat: -21, lon: -48.39, elevation: 614 },
	{ lat: -21, lon: -47.61, elevation: 810 },
	{ lat: -21, lon: -46.84, elevation: 1063 },
	{ lat: -21, lon: -46.06, elevation: 793 },
	{ lat: -21, lon: -45.29, elevation: 798 },
	{ lat: -21, lon: -44.52, elevation: 908 },
	{ lat: -21, lon: -43.74, elevation: 1131 },
	{ lat: -21, lon: -42.97, elevation: 841 },
	{ lat: -21, lon: -42.19, elevation: 523 },
	{ lat: -21, lon: -41.42, elevation: 370 },
	{ lat: -21, lon: 13.55, elevation: -1 },
	{ lat: -21, lon: 14.32, elevation: 318 },
	{ lat: -21, lon: 15.1, elevation: 880 },
	{ lat: -21, lon: 15.87, elevation: 1411 },
	{ lat: -21, lon: 16.65, elevation: 1474 },
	{ lat: -21, lon: 17.42, elevation: 1344 },
	{ lat: -21, lon: 18.19, elevation: 1436 },
	{ lat: -21, lon: 18.97, elevation: 1419 },
	{ lat: -21, lon: 19.74, elevation: 1280 },
	{ lat: -21, lon: 20.52, elevation: 1139 },
	{ lat: -21, lon: 21.29, elevation: 1092 },
	{ lat: -21, lon: 22.06, elevation: 1090 },
	{ lat: -21, lon: 22.84, elevation: 1031 },
	{ lat: -21, lon: 23.61, elevation: 976 },
	{ lat: -21, lon: 24.39, elevation: 916 },
	{ lat: -21, lon: 25.16, elevation: 910 },
	{ lat: -21, lon: 25.94, elevation: 903 },
	{ lat: -21, lon: 26.71, elevation: 1038 },
	{ lat: -21, lon: 27.48, elevation: 1064 },
	{ lat: -21, lon: 28.26, elevation: 1008 },
	{ lat: -21, lon: 29.03, elevation: 997 },
	{ lat: -21, lon: 29.81, elevation: 969 },
	{ lat: -21, lon: 30.58, elevation: 672 },
	{ lat: -21, lon: 31.35, elevation: 431 },
	{ lat: -21, lon: 32.13, elevation: 394 },
	{ lat: -21, lon: 32.9, elevation: 311 },
	{ lat: -21, lon: 33.68, elevation: 146 },
	{ lat: -21, lon: 34.45, elevation: 82 },
	{ lat: -21, lon: 44.52, elevation: 415 },
	{ lat: -21, lon: 45.29, elevation: 469 },
	{ lat: -21, lon: 46.06, elevation: 574 },
	{ lat: -21, lon: 46.84, elevation: 995 },
	{ lat: -21, lon: 47.61, elevation: 659 },
	{ lat: -21, lon: 48.39, elevation: 13 },
	{ lat: -21, lon: 55.35, elevation: 846 },
	{ lat: -21, lon: 116.52, elevation: 94 },
	{ lat: -21, lon: 117.29, elevation: 62 },
	{ lat: -21, lon: 118.06, elevation: 89 },
	{ lat: -21, lon: 118.84, elevation: 165 },
	{ lat: -21, lon: 119.61, elevation: 262 },
	{ lat: -21, lon: 120.39, elevation: 216 },
	{ lat: -21, lon: 121.16, elevation: 253 },
	{ lat: -21, lon: 121.94, elevation: 263 },
	{ lat: -21, lon: 122.71, elevation: 286 },
	{ lat: -21, lon: 123.48, elevation: 262 },
	{ lat: -21, lon: 124.26, elevation: 338 },
	{ lat: -21, lon: 125.03, elevation: 293 },
	{ lat: -21, lon: 125.81, elevation: 359 },
	{ lat: -21, lon: 126.58, elevation: 307 },
	{ lat: -21, lon: 127.35, elevation: 410 },
	{ lat: -21, lon: 128.13, elevation: 423 },
	{ lat: -21, lon: 128.9, elevation: 349 },
	{ lat: -21, lon: 129.68, elevation: 376 },
	{ lat: -21, lon: 130.45, elevation: 394 },
	{ lat: -21, lon: 131.23, elevation: 421 },
	{ lat: -21, lon: 132.0, elevation: 466 },
	{ lat: -21, lon: 132.77, elevation: 474 },
	{ lat: -21, lon: 133.55, elevation: 394 },
	{ lat: -21, lon: 134.32, elevation: 382 },
	{ lat: -21, lon: 135.1, elevation: 474 },
	{ lat: -21, lon: 135.87, elevation: 303 },
	{ lat: -21, lon: 136.65, elevation: 251 },
	{ lat: -21, lon: 137.42, elevation: 219 },
	{ lat: -21, lon: 138.19, elevation: 229 },
	{ lat: -21, lon: 138.97, elevation: 285 },
	{ lat: -21, lon: 139.74, elevation: 416 },
	{ lat: -21, lon: 140.52, elevation: 292 },
	{ lat: -21, lon: 141.29, elevation: 147 },
	{ lat: -21, lon: 142.06, elevation: 157 },
	{ lat: -21, lon: 142.84, elevation: 184 },
	{ lat: -21, lon: 143.61, elevation: 257 },
	{ lat: -21, lon: 144.39, elevation: 363 },
	{ lat: -21, lon: 145.16, elevation: 398 },
	{ lat: -21, lon: 145.94, elevation: 254 },
	{ lat: -21, lon: 146.71, elevation: 243 },
	{ lat: -21, lon: 147.48, elevation: 344 },
	{ lat: -21, lon: 148.26, elevation: 563 },
	{ lat: -21, lon: 149.03, elevation: 6 },
	{ lat: -21, lon: 165.29, elevation: 202 },
	{ lat: -20, lon: -70.0, elevation: 992 },
	{ lat: -20, lon: -69.23, elevation: 2829 },
	{ lat: -20, lon: -68.46, elevation: 3900 },
	{ lat: -20, lon: -67.69, elevation: 3660 },
	{ lat: -20, lon: -66.92, elevation: 3762 },
	{ lat: -20, lon: -66.15, elevation: 3712 },
	{ lat: -20, lon: -65.38, elevation: 3465 },
	{ lat: -20, lon: -64.62, elevation: 1957 },
	{ lat: -20, lon: -63.85, elevation: 1507 },
	{ lat: -20, lon: -63.08, elevation: 581 },
	{ lat: -20, lon: -62.31, elevation: 423 },
	{ lat: -20, lon: -61.54, elevation: 353 },
	{ lat: -20, lon: -60.77, elevation: 209 },
	{ lat: -20, lon: -60.0, elevation: 156 },
	{ lat: -20, lon: -59.23, elevation: 110 },
	{ lat: -20, lon: -58.46, elevation: 90 },
	{ lat: -20, lon: -57.69, elevation: 83 },
	{ lat: -20, lon: -56.92, elevation: 274 },
	{ lat: -20, lon: -56.15, elevation: 122 },
	{ lat: -20, lon: -55.38, elevation: 212 },
	{ lat: -20, lon: -54.62, elevation: 308 },
	{ lat: -20, lon: -53.85, elevation: 547 },
	{ lat: -20, lon: -53.08, elevation: 447 },
	{ lat: -20, lon: -52.31, elevation: 416 },
	{ lat: -20, lon: -51.54, elevation: 428 },
	{ lat: -20, lon: -50.77, elevation: 340 },
	{ lat: -20, lon: -50.0, elevation: 379 },
	{ lat: -20, lon: -49.23, elevation: 419 },
	{ lat: -20, lon: -48.46, elevation: 552 },
	{ lat: -20, lon: -47.69, elevation: 551 },
	{ lat: -20, lon: -46.92, elevation: 1174 },
	{ lat: -20, lon: -46.15, elevation: 806 },
	{ lat: -20, lon: -45.38, elevation: 821 },
	{ lat: -20, lon: -44.62, elevation: 871 },
	{ lat: -20, lon: -43.85, elevation: 827 },
	{ lat: -20, lon: -43.08, elevation: 736 },
	{ lat: -20, lon: -42.31, elevation: 711 },
	{ lat: -20, lon: -41.54, elevation: 667 },
	{ lat: -20, lon: -40.77, elevation: 829 },
	{ lat: -20, lon: 13.08, elevation: 40 },
	{ lat: -20, lon: 13.85, elevation: 809 },
	{ lat: -20, lon: 14.62, elevation: 1055 },
	{ lat: -20, lon: 15.38, elevation: 1273 },
	{ lat: -20, lon: 16.15, elevation: 1357 },
	{ lat: -20, lon: 16.92, elevation: 1444 },
	{ lat: -20, lon: 17.69, elevation: 1340 },
	{ lat: -20, lon: 18.46, elevation: 1259 },
	{ lat: -20, lon: 19.23, elevation: 1253 },
	{ lat: -20, lon: 20.0, elevation: 1189 },
	{ lat: -20, lon: 20.77, elevation: 1158 },
	{ lat: -20, lon: 21.54, elevation: 1002 },
	{ lat: -20, lon: 22.31, elevation: 949 },
	{ lat: -20, lon: 23.08, elevation: 944 },
	{ lat: -20, lon: 23.85, elevation: 944 },
	{ lat: -20, lon: 24.62, elevation: 933 },
	{ lat: -20, lon: 25.38, elevation: 956 },
	{ lat: -20, lon: 26.15, elevation: 927 },
	{ lat: -20, lon: 26.92, elevation: 1003 },
	{ lat: -20, lon: 27.69, elevation: 1150 },
	{ lat: -20, lon: 28.46, elevation: 1268 },
	{ lat: -20, lon: 29.23, elevation: 1249 },
	{ lat: -20, lon: 30.0, elevation: 990 },
	{ lat: -20, lon: 30.77, elevation: 1132 },
	{ lat: -20, lon: 31.54, elevation: 1093 },
	{ lat: -20, lon: 32.31, elevation: 526 },
	{ lat: -20, lon: 33.08, elevation: 541 },
	{ lat: -20, lon: 33.85, elevation: 128 },
	{ lat: -20, lon: 34.62, elevation: 6 },
	{ lat: -20, lon: 44.62, elevation: 55 },
	{ lat: -20, lon: 45.38, elevation: 362 },
	{ lat: -20, lon: 46.15, elevation: 807 },
	{ lat: -20, lon: 46.92, elevation: 1823 },
	{ lat: -20, lon: 47.69, elevation: 1592 },
	{ lat: -20, lon: 48.46, elevation: 233 },
	{ lat: -20, lon: 119.23, elevation: 8 },
	{ lat: -20, lon: 120.0, elevation: 32 },
	{ lat: -20, lon: 120.77, elevation: 107 },
	{ lat: -20, lon: 121.54, elevation: 122 },
	{ lat: -20, lon: 122.31, elevation: 102 },
	{ lat: -20, lon: 123.08, elevation: 139 },
	{ lat: -20, lon: 123.85, elevation: 233 },
	{ lat: -20, lon: 124.62, elevation: 237 },
	{ lat: -20, lon: 125.38, elevation: 199 },
	{ lat: -20, lon: 126.15, elevation: 239 },
	{ lat: -20, lon: 126.92, elevation: 303 },
	{ lat: -20, lon: 127.69, elevation: 311 },
	{ lat: -20, lon: 128.46, elevation: 436 },
	{ lat: -20, lon: 129.23, elevation: 438 },
	{ lat: -20, lon: 130.0, elevation: 368 },
	{ lat: -20, lon: 130.77, elevation: 378 },
	{ lat: -20, lon: 131.54, elevation: 312 },
	{ lat: -20, lon: 132.31, elevation: 297 },
	{ lat: -20, lon: 133.08, elevation: 293 },
	{ lat: -20, lon: 133.85, elevation: 297 },
	{ lat: -20, lon: 134.62, elevation: 355 },
	{ lat: -20, lon: 135.38, elevation: 263 },
	{ lat: -20, lon: 136.15, elevation: 258 },
	{ lat: -20, lon: 136.92, elevation: 234 },
	{ lat: -20, lon: 137.69, elevation: 244 },
	{ lat: -20, lon: 138.46, elevation: 291 },
	{ lat: -20, lon: 139.23, elevation: 276 },
	{ lat: -20, lon: 140.0, elevation: 160 },
	{ lat: -20, lon: 140.77, elevation: 112 },
	{ lat: -20, lon: 141.54, elevation: 105 },
	{ lat: -20, lon: 142.31, elevation: 173 },
	{ lat: -20, lon: 143.08, elevation: 308 },
	{ lat: -20, lon: 143.85, elevation: 628 },
	{ lat: -20, lon: 144.62, elevation: 911 },
	{ lat: -20, lon: 145.38, elevation: 474 },
	{ lat: -20, lon: 146.15, elevation: 296 },
	{ lat: -20, lon: 146.92, elevation: 284 },
	{ lat: -20, lon: 147.69, elevation: 46 },
	{ lat: -20, lon: 148.46, elevation: 145 },
	{ lat: -19, lon: -69.94, elevation: 1456 },
	{ lat: -19, lon: -69.17, elevation: 4175 },
	{ lat: -19, lon: -68.41, elevation: 4167 },
	{ lat: -19, lon: -67.64, elevation: 3729 },
	{ lat: -19, lon: -66.88, elevation: 3694 },
	{ lat: -19, lon: -66.11, elevation: 3764 },
	{ lat: -19, lon: -65.35, elevation: 3236 },
	{ lat: -19, lon: -64.59, elevation: 2252 },
	{ lat: -19, lon: -63.82, elevation: 1031 },
	{ lat: -19, lon: -63.06, elevation: 450 },
	{ lat: -19, lon: -62.29, elevation: 345 },
	{ lat: -19, lon: -61.53, elevation: 392 },
	{ lat: -19, lon: -60.76, elevation: 387 },
	{ lat: -19, lon: -60.0, elevation: 243 },
	{ lat: -19, lon: -59.24, elevation: 256 },
	{ lat: -19, lon: -58.47, elevation: 110 },
	{ lat: -19, lon: -57.71, elevation: 112 },
	{ lat: -19, lon: -56.94, elevation: 93 },
	{ lat: -19, lon: -56.18, elevation: 122 },
	{ lat: -19, lon: -55.41, elevation: 151 },
	{ lat: -19, lon: -54.65, elevation: 513 },
	{ lat: -19, lon: -53.89, elevation: 426 },
	{ lat: -19, lon: -53.12, elevation: 562 },
	{ lat: -19, lon: -52.36, elevation: 567 },
	{ lat: -19, lon: -51.59, elevation: 530 },
	{ lat: -19, lon: -50.83, elevation: 463 },
	{ lat: -19, lon: -50.06, elevation: 527 },
	{ lat: -19, lon: -49.3, elevation: 653 },
	{ lat: -19, lon: -48.54, elevation: 772 },
	{ lat: -19, lon: -47.77, elevation: 998 },
	{ lat: -19, lon: -47.01, elevation: 951 },
	{ lat: -19, lon: -46.24, elevation: 1121 },
	{ lat: -19, lon: -45.48, elevation: 585 },
	{ lat: -19, lon: -44.71, elevation: 694 },
	{ lat: -19, lon: -43.95, elevation: 669 },
	{ lat: -19, lon: -43.18, elevation: 778 },
	{ lat: -19, lon: -42.42, elevation: 632 },
	{ lat: -19, lon: -41.66, elevation: 154 },
	{ lat: -19, lon: -40.89, elevation: 159 },
	{ lat: -19, lon: -40.13, elevation: 94 },
	{ lat: -19, lon: 12.61, elevation: 170 },
	{ lat: -19, lon: 13.38, elevation: 719 },
	{ lat: -19, lon: 14.14, elevation: 981 },
	{ lat: -19, lon: 14.9, elevation: 1167 },
	{ lat: -19, lon: 15.67, elevation: 1118 },
	{ lat: -19, lon: 16.43, elevation: 1101 },
	{ lat: -19, lon: 17.2, elevation: 1156 },
	{ lat: -19, lon: 17.96, elevation: 1186 },
	{ lat: -19, lon: 18.73, elevation: 1203 },
	{ lat: -19, lon: 19.49, elevation: 1202 },
	{ lat: -19, lon: 20.25, elevation: 1160 },
	{ lat: -19, lon: 21.02, elevation: 1060 },
	{ lat: -19, lon: 21.78, elevation: 1008 },
	{ lat: -19, lon: 22.55, elevation: 974 },
	{ lat: -19, lon: 23.31, elevation: 958 },
	{ lat: -19, lon: 24.08, elevation: 922 },
	{ lat: -19, lon: 24.84, elevation: 988 },
	{ lat: -19, lon: 25.61, elevation: 1040 },
	{ lat: -19, lon: 26.37, elevation: 1014 },
	{ lat: -19, lon: 27.13, elevation: 1030 },
	{ lat: -19, lon: 27.9, elevation: 978 },
	{ lat: -19, lon: 28.66, elevation: 1238 },
	{ lat: -19, lon: 29.43, elevation: 1244 },
	{ lat: -19, lon: 30.19, elevation: 1244 },
	{ lat: -19, lon: 30.96, elevation: 1479 },
	{ lat: -19, lon: 31.72, elevation: 933 },
	{ lat: -19, lon: 32.48, elevation: 995 },
	{ lat: -19, lon: 33.25, elevation: 618 },
	{ lat: -19, lon: 34.01, elevation: 271 },
	{ lat: -19, lon: 34.78, elevation: 194 },
	{ lat: -19, lon: 35.54, elevation: 17 },
	{ lat: -19, lon: 44.71, elevation: 63 },
	{ lat: -19, lon: 45.48, elevation: 614 },
	{ lat: -19, lon: 46.24, elevation: 804 },
	{ lat: -19, lon: 47.01, elevation: 1382 },
	{ lat: -19, lon: 47.77, elevation: 1395 },
	{ lat: -19, lon: 48.54, elevation: 993 },
	{ lat: -19, lon: 121.91, elevation: 90 },
	{ lat: -19, lon: 122.68, elevation: 180 },
	{ lat: -19, lon: 123.44, elevation: 209 },
	{ lat: -19, lon: 124.2, elevation: 192 },
	{ lat: -19, lon: 124.97, elevation: 163 },
	{ lat: -19, lon: 125.73, elevation: 155 },
	{ lat: -19, lon: 126.5, elevation: 334 },
	{ lat: -19, lon: 127.26, elevation: 440 },
	{ lat: -19, lon: 128.03, elevation: 342 },
	{ lat: -19, lon: 128.79, elevation: 376 },
	{ lat: -19, lon: 129.55, elevation: 404 },
	{ lat: -19, lon: 130.32, elevation: 401 },
	{ lat: -19, lon: 131.08, elevation: 280 },
	{ lat: -19, lon: 131.85, elevation: 281 },
	{ lat: -19, lon: 132.61, elevation: 276 },
	{ lat: -19, lon: 133.38, elevation: 279 },
	{ lat: -19, lon: 134.14, elevation: 299 },
	{ lat: -19, lon: 134.9, elevation: 235 },
	{ lat: -19, lon: 135.67, elevation: 208 },
	{ lat: -19, lon: 136.43, elevation: 230 },
	{ lat: -19, lon: 137.2, elevation: 265 },
	{ lat: -19, lon: 137.96, elevation: 262 },
	{ lat: -19, lon: 138.73, elevation: 171 },
	{ lat: -19, lon: 139.49, elevation: 166 },
	{ lat: -19, lon: 140.25, elevation: 62 },
	{ lat: -19, lon: 141.02, elevation: 49 },
	{ lat: -19, lon: 141.78, elevation: 89 },
	{ lat: -19, lon: 142.55, elevation: 177 },
	{ lat: -19, lon: 143.31, elevation: 429 },
	{ lat: -19, lon: 144.08, elevation: 618 },
	{ lat: -19, lon: 144.84, elevation: 520 },
	{ lat: -19, lon: 145.61, elevation: 385 },
	{ lat: -19, lon: 178.47, elevation: 12 },
	{ lat: -18, lon: -70.63, elevation: 207 },
	{ lat: -18, lon: -69.87, elevation: 3525 },
	{ lat: -18, lon: -69.11, elevation: 4914 },
	{ lat: -18, lon: -68.35, elevation: 4141 },
	{ lat: -18, lon: -67.59, elevation: 3725 },
	{ lat: -18, lon: -66.84, elevation: 4276 },
	{ lat: -18, lon: -66.08, elevation: 3065 },
	{ lat: -18, lon: -65.32, elevation: 2174 },
	{ lat: -18, lon: -64.56, elevation: 1817 },
	{ lat: -18, lon: -63.8, elevation: 1817 },
	{ lat: -18, lon: -63.04, elevation: 369 },
	{ lat: -18, lon: -62.28, elevation: 306 },
	{ lat: -18, lon: -61.52, elevation: 281 },
	{ lat: -18, lon: -60.76, elevation: 381 },
	{ lat: -18, lon: -60.0, elevation: 425 },
	{ lat: -18, lon: -59.24, elevation: 546 },
	{ lat: -18, lon: -58.48, elevation: 176 },
	{ lat: -18, lon: -57.72, elevation: 147 },
	{ lat: -18, lon: -56.96, elevation: 103 },
	{ lat: -18, lon: -56.2, elevation: 128 },
	{ lat: -18, lon: -55.44, elevation: 161 },
	{ lat: -18, lon: -54.68, elevation: 365 },
	{ lat: -18, lon: -53.92, elevation: 369 },
	{ lat: -18, lon: -53.16, elevation: 846 },
	{ lat: -18, lon: -52.41, elevation: 698 },
	{ lat: -18, lon: -51.65, elevation: 747 },
	{ lat: -18, lon: -50.89, elevation: 689 },
	{ lat: -18, lon: -50.13, elevation: 460 },
	{ lat: -18, lon: -49.37, elevation: 814 },
	{ lat: -18, lon: -48.61, elevation: 637 },
	{ lat: -18, lon: -47.85, elevation: 811 },
	{ lat: -18, lon: -47.09, elevation: 646 },
	{ lat: -18, lon: -46.33, elevation: 818 },
	{ lat: -18, lon: -45.57, elevation: 789 },
	{ lat: -18, lon: -44.81, elevation: 934 },
	{ lat: -18, lon: -44.05, elevation: 842 },
	{ lat: -18, lon: -43.29, elevation: 860 },
	{ lat: -18, lon: -42.53, elevation: 589 },
	{ lat: -18, lon: -41.77, elevation: 652 },
	{ lat: -18, lon: -41.01, elevation: 304 },
	{ lat: -18, lon: -40.25, elevation: 161 },
	{ lat: -18, lon: -39.49, elevation: 6 },
	{ lat: -18, lon: 12.15, elevation: 615 },
	{ lat: -18, lon: 12.91, elevation: 1239 },
	{ lat: -18, lon: 13.67, elevation: 981 },
	{ lat: -18, lon: 14.43, elevation: 1169 },
	{ lat: -18, lon: 15.19, elevation: 1106 },
	{ lat: -18, lon: 15.95, elevation: 1097 },
	{ lat: -18, lon: 16.71, elevation: 1126 },
	{ lat: -18, lon: 17.47, elevation: 1143 },
	{ lat: -18, lon: 18.23, elevation: 1156 },
	{ lat: -18, lon: 18.99, elevation: 1145 },
	{ lat: -18, lon: 19.75, elevation: 1094 },
	{ lat: -18, lon: 20.51, elevation: 1078 },
	{ lat: -18, lon: 21.27, elevation: 1056 },
	{ lat: -18, lon: 22.03, elevation: 1027 },
	{ lat: -18, lon: 22.78, elevation: 987 },
	{ lat: -18, lon: 23.54, elevation: 958 },
	{ lat: -18, lon: 24.3, elevation: 930 },
	{ lat: -18, lon: 25.06, elevation: 1040 },
	{ lat: -18, lon: 25.82, elevation: 998 },
	{ lat: -18, lon: 26.58, elevation: 615 },
	{ lat: -18, lon: 27.34, elevation: 784 },
	{ lat: -18, lon: 28.1, elevation: 853 },
	{ lat: -18, lon: 28.86, elevation: 947 },
	{ lat: -18, lon: 29.62, elevation: 975 },
	{ lat: -18, lon: 30.38, elevation: 1267 },
	{ lat: -18, lon: 31.14, elevation: 1447 },
	{ lat: -18, lon: 31.9, elevation: 1517 },
	{ lat: -18, lon: 32.66, elevation: 1197 },
	{ lat: -18, lon: 33.42, elevation: 631 },
	{ lat: -18, lon: 34.18, elevation: 352 },
	{ lat: -18, lon: 34.94, elevation: 61 },
	{ lat: -18, lon: 35.7, elevation: 19 },
	{ lat: -18, lon: 36.46, elevation: 6 },
	{ lat: -18, lon: 44.05, elevation: 19 },
	{ lat: -18, lon: 44.81, elevation: 206 },
	{ lat: -18, lon: 45.57, elevation: 624 },
	{ lat: -18, lon: 46.33, elevation: 874 },
	{ lat: -18, lon: 47.09, elevation: 1502 },
	{ lat: -18, lon: 47.85, elevation: 1183 },
	{ lat: -18, lon: 48.61, elevation: 1108 },
	{ lat: -18, lon: 49.37, elevation: 56 },
	{ lat: -18, lon: 123.04, elevation: 61 },
	{ lat: -18, lon: 123.8, elevation: 28 },
	{ lat: -18, lon: 124.56, elevation: 67 },
	{ lat: -18, lon: 125.32, elevation: 173 },
	{ lat: -18, lon: 126.08, elevation: 168 },
	{ lat: -18, lon: 126.84, elevation: 385 },
	{ lat: -18, lon: 127.59, elevation: 481 },
	{ lat: -18, lon: 128.35, elevation: 284 },
	{ lat: -18, lon: 129.11, elevation: 416 },
	{ lat: -18, lon: 129.87, elevation: 421 },
	{ lat: -18, lon: 130.63, elevation: 301 },
	{ lat: -18, lon: 131.39, elevation: 245 },
	{ lat: -18, lon: 132.15, elevation: 270 },
	{ lat: -18, lon: 132.91, elevation: 217 },
	{ lat: -18, lon: 133.67, elevation: 264 },
	{ lat: -18, lon: 134.43, elevation: 226 },
	{ lat: -18, lon: 135.19, elevation: 209 },
	{ lat: -18, lon: 135.95, elevation: 228 },
	{ lat: -18, lon: 136.71, elevation: 270 },
	{ lat: -18, lon: 137.47, elevation: 190 },
	{ lat: -18, lon: 138.23, elevation: 143 },
	{ lat: -18, lon: 138.99, elevation: 34 },
	{ lat: -18, lon: 139.75, elevation: 9 },
	{ lat: -18, lon: 140.51, elevation: 15 },
	{ lat: -18, lon: 141.27, elevation: 21 },
	{ lat: -18, lon: 142.03, elevation: 82 },
	{ lat: -18, lon: 142.78, elevation: 158 },
	{ lat: -18, lon: 143.54, elevation: 278 },
	{ lat: -18, lon: 144.3, elevation: 453 },
	{ lat: -18, lon: 145.06, elevation: 640 },
	{ lat: -18, lon: 145.82, elevation: 15 },
	{ lat: -18, lon: 177.72, elevation: 216 },
	{ lat: -18, lon: 178.48, elevation: 6 },
	{ lat: -17, lon: -72.08, elevation: 172 },
	{ lat: -17, lon: -71.32, elevation: 1708 },
	{ lat: -17, lon: -70.57, elevation: 4706 },
	{ lat: -17, lon: -69.81, elevation: 4821 },
	{ lat: -17, lon: -69.06, elevation: 3856 },
	{ lat: -17, lon: -68.3, elevation: 4386 },
	{ lat: -17, lon: -67.55, elevation: 3313 },
	{ lat: -17, lon: -66.79, elevation: 3129 },
	{ lat: -17, lon: -66.04, elevation: 2526 },
	{ lat: -17, lon: -65.28, elevation: 260 },
	{ lat: -17, lon: -64.53, elevation: 216 },
	{ lat: -17, lon: -63.77, elevation: 245 },
	{ lat: -17, lon: -63.02, elevation: 252 },
	{ lat: -17, lon: -62.26, elevation: 257 },
	{ lat: -17, lon: -61.51, elevation: 436 },
	{ lat: -17, lon: -60.75, elevation: 329 },
	{ lat: -17, lon: -60.0, elevation: 241 },
	{ lat: -17, lon: -59.25, elevation: 145 },
	{ lat: -17, lon: -58.49, elevation: 99 },
	{ lat: -17, lon: -57.74, elevation: 103 },
	{ lat: -17, lon: -56.98, elevation: 114 },
	{ lat: -17, lon: -56.23, elevation: 126 },
	{ lat: -17, lon: -55.47, elevation: 153 },
	{ lat: -17, lon: -54.72, elevation: 515 },
	{ lat: -17, lon: -53.96, elevation: 703 },
	{ lat: -17, lon: -53.21, elevation: 692 },
	{ lat: -17, lon: -52.45, elevation: 603 },
	{ lat: -17, lon: -51.7, elevation: 799 },
	{ lat: -17, lon: -50.94, elevation: 791 },
	{ lat: -17, lon: -50.19, elevation: 730 },
	{ lat: -17, lon: -49.43, elevation: 827 },
	{ lat: -17, lon: -48.68, elevation: 888 },
	{ lat: -17, lon: -47.92, elevation: 758 },
	{ lat: -17, lon: -47.17, elevation: 808 },
	{ lat: -17, lon: -46.42, elevation: 533 },
	{ lat: -17, lon: -45.66, elevation: 610 },
	{ lat: -17, lon: -44.91, elevation: 534 },
	{ lat: -17, lon: -44.15, elevation: 821 },
	{ lat: -17, lon: -43.4, elevation: 890 },
	{ lat: -17, lon: -42.64, elevation: 742 },
	{ lat: -17, lon: -41.89, elevation: 842 },
	{ lat: -17, lon: -41.13, elevation: 802 },
	{ lat: -17, lon: -40.38, elevation: 509 },
	{ lat: -17, lon: -39.62, elevation: 27 },
	{ lat: -17, lon: 12.45, elevation: 1018 },
	{ lat: -17, lon: 13.21, elevation: 669 },
	{ lat: -17, lon: 13.96, elevation: 936 },
	{ lat: -17, lon: 14.72, elevation: 1112 },
	{ lat: -17, lon: 15.47, elevation: 1118 },
	{ lat: -17, lon: 16.23, elevation: 1134 },
	{ lat: -17, lon: 16.98, elevation: 1162 },
	{ lat: -17, lon: 17.74, elevation: 1176 },
	{ lat: -17, lon: 18.49, elevation: 1163 },
	{ lat: -17, lon: 19.25, elevation: 1168 },
	{ lat: -17, lon: 20.0, elevation: 1154 },
	{ lat: -17, lon: 20.75, elevation: 1106 },
	{ lat: -17, lon: 21.51, elevation: 1035 },
	{ lat: -17, lon: 22.26, elevation: 1015 },
	{ lat: -17, lon: 23.02, elevation: 1007 },
	{ lat: -17, lon: 23.77, elevation: 1023 },
	{ lat: -17, lon: 24.53, elevation: 1022 },
	{ lat: -17, lon: 25.28, elevation: 973 },
	{ lat: -17, lon: 26.04, elevation: 1239 },
	{ lat: -17, lon: 26.79, elevation: 1330 },
	{ lat: -17, lon: 27.55, elevation: 757 },
	{ lat: -17, lon: 28.3, elevation: 554 },
	{ lat: -17, lon: 29.06, elevation: 911 },
	{ lat: -17, lon: 29.81, elevation: 1194 },
	{ lat: -17, lon: 30.57, elevation: 1125 },
	{ lat: -17, lon: 31.32, elevation: 1197 },
	{ lat: -17, lon: 32.08, elevation: 757 },
	{ lat: -17, lon: 32.83, elevation: 616 },
	{ lat: -17, lon: 33.58, elevation: 518 },
	{ lat: -17, lon: 34.34, elevation: 252 },
	{ lat: -17, lon: 35.09, elevation: 487 },
	{ lat: -17, lon: 35.85, elevation: 385 },
	{ lat: -17, lon: 36.6, elevation: 193 },
	{ lat: -17, lon: 37.36, elevation: 115 },
	{ lat: -17, lon: 38.11, elevation: 95 },
	{ lat: -17, lon: 38.87, elevation: 4 },
	{ lat: -17, lon: 44.91, elevation: 154 },
	{ lat: -17, lon: 45.66, elevation: 430 },
	{ lat: -17, lon: 46.42, elevation: 227 },
	{ lat: -17, lon: 47.17, elevation: 441 },
	{ lat: -17, lon: 47.92, elevation: 1166 },
	{ lat: -17, lon: 48.68, elevation: 1053 },
	{ lat: -17, lon: 49.43, elevation: 238 },
	{ lat: -17, lon: 122.64, elevation: 23 },
	{ lat: -17, lon: 124.15, elevation: 87 },
	{ lat: -17, lon: 124.91, elevation: 216 },
	{ lat: -17, lon: 125.66, elevation: 336 },
	{ lat: -17, lon: 126.42, elevation: 489 },
	{ lat: -17, lon: 127.17, elevation: 546 },
	{ lat: -17, lon: 127.92, elevation: 547 },
	{ lat: -17, lon: 128.68, elevation: 145 },
	{ lat: -17, lon: 129.43, elevation: 307 },
	{ lat: -17, lon: 130.19, elevation: 313 },
	{ lat: -17, lon: 130.94, elevation: 178 },
	{ lat: -17, lon: 131.7, elevation: 241 },
	{ lat: -17, lon: 132.45, elevation: 249 },
	{ lat: -17, lon: 133.21, elevation: 233 },
	{ lat: -17, lon: 133.96, elevation: 238 },
	{ lat: -17, lon: 134.72, elevation: 249 },
	{ lat: -17, lon: 135.47, elevation: 192 },
	{ lat: -17, lon: 136.23, elevation: 172 },
	{ lat: -17, lon: 136.98, elevation: 166 },
	{ lat: -17, lon: 137.74, elevation: 185 },
	{ lat: -17, lon: 138.49, elevation: 12 },
	{ lat: -17, lon: 141.51, elevation: 26 },
	{ lat: -17, lon: 142.26, elevation: 66 },
	{ lat: -17, lon: 143.02, elevation: 123 },
	{ lat: -17, lon: 143.77, elevation: 191 },
	{ lat: -17, lon: 144.53, elevation: 320 },
	{ lat: -17, lon: 145.28, elevation: 578 },
	{ lat: -16, lon: -140.17, elevation: 0 },
	{ lat: -16, lon: -73.28, elevation: 1392 },
	{ lat: -16, lon: -72.53, elevation: 969 },
	{ lat: -16, lon: -71.77, elevation: 3862 },
	{ lat: -16, lon: -71.02, elevation: 4549 },
	{ lat: -16, lon: -70.27, elevation: 4453 },
	{ lat: -16, lon: -69.52, elevation: 3820 },
	{ lat: -16, lon: -68.77, elevation: 3822 },
	{ lat: -16, lon: -68.02, elevation: 3119 },
	{ lat: -16, lon: -67.27, elevation: 1803 },
	{ lat: -16, lon: -66.51, elevation: 924 },
	{ lat: -16, lon: -65.76, elevation: 205 },
	{ lat: -16, lon: -65.01, elevation: 175 },
	{ lat: -16, lon: -64.26, elevation: 182 },
	{ lat: -16, lon: -63.51, elevation: 208 },
	{ lat: -16, lon: -62.76, elevation: 423 },
	{ lat: -16, lon: -62.0, elevation: 453 },
	{ lat: -16, lon: -61.25, elevation: 356 },
	{ lat: -16, lon: -60.5, elevation: 234 },
	{ lat: -16, lon: -59.75, elevation: 225 },
	{ lat: -16, lon: -59.0, elevation: 211 },
	{ lat: -16, lon: -58.25, elevation: 139 },
	{ lat: -16, lon: -57.49, elevation: 343 },
	{ lat: -16, lon: -56.74, elevation: 209 },
	{ lat: -16, lon: -55.99, elevation: 149 },
	{ lat: -16, lon: -55.24, elevation: 629 },
	{ lat: -16, lon: -54.49, elevation: 501 },
	{ lat: -16, lon: -53.74, elevation: 563 },
	{ lat: -16, lon: -52.99, elevation: 414 },
	{ lat: -16, lon: -52.23, elevation: 338 },
	{ lat: -16, lon: -51.48, elevation: 437 },
	{ lat: -16, lon: -50.73, elevation: 393 },
	{ lat: -16, lon: -49.98, elevation: 728 },
	{ lat: -16, lon: -49.23, elevation: 761 },
	{ lat: -16, lon: -48.48, elevation: 974 },
	{ lat: -16, lon: -47.72, elevation: 877 },
	{ lat: -16, lon: -46.97, elevation: 871 },
	{ lat: -16, lon: -46.22, elevation: 540 },
	{ lat: -16, lon: -45.47, elevation: 630 },
	{ lat: -16, lon: -44.72, elevation: 631 },
	{ lat: -16, lon: -43.97, elevation: 700 },
	{ lat: -16, lon: -43.22, elevation: 609 },
	{ lat: -16, lon: -42.46, elevation: 954 },
	{ lat: -16, lon: -41.71, elevation: 908 },
	{ lat: -16, lon: -40.96, elevation: 588 },
	{ lat: -16, lon: -40.21, elevation: 199 },
	{ lat: -16, lon: -39.46, elevation: 49 },
	{ lat: -16, lon: 12.4, elevation: 306 },
	{ lat: -16, lon: 13.15, elevation: 564 },
	{ lat: -16, lon: 13.9, elevation: 1305 },
	{ lat: -16, lon: 14.66, elevation: 1183 },
	{ lat: -16, lon: 15.41, elevation: 1165 },
	{ lat: -16, lon: 16.16, elevation: 1233 },
	{ lat: -16, lon: 16.91, elevation: 1233 },
	{ lat: -16, lon: 17.66, elevation: 1189 },
	{ lat: -16, lon: 18.41, elevation: 1204 },
	{ lat: -16, lon: 19.16, elevation: 1215 },
	{ lat: -16, lon: 19.92, elevation: 1228 },
	{ lat: -16, lon: 20.67, elevation: 1164 },
	{ lat: -16, lon: 21.42, elevation: 1081 },
	{ lat: -16, lon: 22.17, elevation: 1044 },
	{ lat: -16, lon: 22.92, elevation: 1037 },
	{ lat: -16, lon: 23.67, elevation: 1062 },
	{ lat: -16, lon: 24.43, elevation: 1129 },
	{ lat: -16, lon: 25.18, elevation: 1184 },
	{ lat: -16, lon: 25.93, elevation: 1043 },
	{ lat: -16, lon: 26.68, elevation: 1022 },
	{ lat: -16, lon: 27.43, elevation: 1010 },
	{ lat: -16, lon: 28.18, elevation: 1077 },
	{ lat: -16, lon: 28.94, elevation: 407 },
	{ lat: -16, lon: 29.69, elevation: 544 },
	{ lat: -16, lon: 30.44, elevation: 369 },
	{ lat: -16, lon: 31.19, elevation: 337 },
	{ lat: -16, lon: 31.94, elevation: 703 },
	{ lat: -16, lon: 32.69, elevation: 499 },
	{ lat: -16, lon: 33.44, elevation: 143 },
	{ lat: -16, lon: 34.2, elevation: 383 },
	{ lat: -16, lon: 34.95, elevation: 576 },
	{ lat: -16, lon: 35.7, elevation: 1135 },
	{ lat: -16, lon: 36.45, elevation: 699 },
	{ lat: -16, lon: 37.2, elevation: 575 },
	{ lat: -16, lon: 37.95, elevation: 353 },
	{ lat: -16, lon: 38.71, elevation: 176 },
	{ lat: -16, lon: 39.46, elevation: 66 },
	{ lat: -16, lon: 45.47, elevation: 10 },
	{ lat: -16, lon: 46.22, elevation: 118 },
	{ lat: -16, lon: 46.97, elevation: 107 },
	{ lat: -16, lon: 47.72, elevation: 82 },
	{ lat: -16, lon: 48.48, elevation: 338 },
	{ lat: -16, lon: 49.23, elevation: 821 },
	{ lat: -16, lon: 125.14, elevation: 264 },
	{ lat: -16, lon: 125.89, elevation: 502 },
	{ lat: -16, lon: 126.64, elevation: 431 },
	{ lat: -16, lon: 127.39, elevation: 296 },
	{ lat: -16, lon: 128.14, elevation: 281 },
	{ lat: -16, lon: 128.89, elevation: 113 },
	{ lat: -16, lon: 129.65, elevation: 48 },
	{ lat: -16, lon: 130.4, elevation: 177 },
	{ lat: -16, lon: 131.15, elevation: 64 },
	{ lat: -16, lon: 131.9, elevation: 222 },
	{ lat: -16, lon: 132.65, elevation: 219 },
	{ lat: -16, lon: 133.4, elevation: 191 },
	{ lat: -16, lon: 134.15, elevation: 212 },
	{ lat: -16, lon: 134.91, elevation: 153 },
	{ lat: -16, lon: 135.66, elevation: 150 },
	{ lat: -16, lon: 136.41, elevation: 12 },
	{ lat: -16, lon: 137.16, elevation: 6 },
	{ lat: -16, lon: 141.67, elevation: 9 },
	{ lat: -16, lon: 142.42, elevation: 52 },
	{ lat: -16, lon: 143.17, elevation: 119 },
	{ lat: -16, lon: 143.92, elevation: 202 },
	{ lat: -16, lon: 144.68, elevation: 414 },
	{ lat: -16, lon: 145.43, elevation: 41 },
	{ lat: -16, lon: 167.22, elevation: 392 },
	{ lat: -15, lon: -75.22, elevation: 1369 },
	{ lat: -15, lon: -74.47, elevation: 2277 },
	{ lat: -15, lon: -73.72, elevation: 4136 },
	{ lat: -15, lon: -72.97, elevation: 4858 },
	{ lat: -15, lon: -72.22, elevation: 4891 },
	{ lat: -15, lon: -71.48, elevation: 4427 },
	{ lat: -15, lon: -70.73, elevation: 4181 },
	{ lat: -15, lon: -69.98, elevation: 4340 },
	{ lat: -15, lon: -69.23, elevation: 4475 },
	{ lat: -15, lon: -68.48, elevation: 1392 },
	{ lat: -15, lon: -67.73, elevation: 357 },
	{ lat: -15, lon: -66.99, elevation: 243 },
	{ lat: -15, lon: -66.24, elevation: 191 },
	{ lat: -15, lon: -65.49, elevation: 154 },
	{ lat: -15, lon: -64.74, elevation: 163 },
	{ lat: -15, lon: -63.99, elevation: 187 },
	{ lat: -15, lon: -63.24, elevation: 204 },
	{ lat: -15, lon: -62.49, elevation: 275 },
	{ lat: -15, lon: -61.75, elevation: 259 },
	{ lat: -15, lon: -61.0, elevation: 207 },
	{ lat: -15, lon: -60.25, elevation: 255 },
	{ lat: -15, lon: -59.5, elevation: 256 },
	{ lat: -15, lon: -58.75, elevation: 459 },
	{ lat: -15, lon: -58.0, elevation: 232 },
	{ lat: -15, lon: -57.26, elevation: 175 },
	{ lat: -15, lon: -56.51, elevation: 224 },
	{ lat: -15, lon: -55.76, elevation: 309 },
	{ lat: -15, lon: -55.01, elevation: 755 },
	{ lat: -15, lon: -54.26, elevation: 637 },
	{ lat: -15, lon: -53.51, elevation: 600 },
	{ lat: -15, lon: -52.77, elevation: 353 },
	{ lat: -15, lon: -52.02, elevation: 274 },
	{ lat: -15, lon: -51.27, elevation: 268 },
	{ lat: -15, lon: -50.52, elevation: 296 },
	{ lat: -15, lon: -49.77, elevation: 798 },
	{ lat: -15, lon: -49.02, elevation: 749 },
	{ lat: -15, lon: -48.27, elevation: 772 },
	{ lat: -15, lon: -47.53, elevation: 668 },
	{ lat: -15, lon: -46.78, elevation: 1009 },
	{ lat: -15, lon: -46.03, elevation: 798 },
	{ lat: -15, lon: -45.28, elevation: 812 },
	{ lat: -15, lon: -44.53, elevation: 729 },
	{ lat: -15, lon: -43.78, elevation: 478 },
	{ lat: -15, lon: -43.04, elevation: 1100 },
	{ lat: -15, lon: -42.29, elevation: 818 },
	{ lat: -15, lon: -41.54, elevation: 601 },
	{ lat: -15, lon: -40.79, elevation: 857 },
	{ lat: -15, lon: -40.04, elevation: 296 },
	{ lat: -15, lon: -39.29, elevation: 549 },
	{ lat: -15, lon: 12.35, elevation: 260 },
	{ lat: -15, lon: 13.1, elevation: 1324 },
	{ lat: -15, lon: 13.85, elevation: 1464 },
	{ lat: -15, lon: 14.59, elevation: 1358 },
	{ lat: -15, lon: 15.34, elevation: 1238 },
	{ lat: -15, lon: 16.09, elevation: 1398 },
	{ lat: -15, lon: 16.84, elevation: 1381 },
	{ lat: -15, lon: 17.59, elevation: 1342 },
	{ lat: -15, lon: 18.34, elevation: 1382 },
	{ lat: -15, lon: 19.09, elevation: 1215 },
	{ lat: -15, lon: 19.83, elevation: 1219 },
	{ lat: -15, lon: 20.58, elevation: 1091 },
	{ lat: -15, lon: 21.33, elevation: 1152 },
	{ lat: -15, lon: 22.08, elevation: 1039 },
	{ lat: -15, lon: 22.83, elevation: 1020 },
	{ lat: -15, lon: 23.58, elevation: 1066 },
	{ lat: -15, lon: 24.32, elevation: 1145 },
	{ lat: -15, lon: 25.07, elevation: 1174 },
	{ lat: -15, lon: 25.82, elevation: 1092 },
	{ lat: -15, lon: 26.57, elevation: 1126 },
	{ lat: -15, lon: 27.32, elevation: 1178 },
	{ lat: -15, lon: 28.07, elevation: 1132 },
	{ lat: -15, lon: 28.81, elevation: 713 },
	{ lat: -15, lon: 29.56, elevation: 961 },
	{ lat: -15, lon: 30.31, elevation: 467 },
	{ lat: -15, lon: 31.06, elevation: 984 },
	{ lat: -15, lon: 31.81, elevation: 999 },
	{ lat: -15, lon: 32.56, elevation: 489 },
	{ lat: -15, lon: 33.31, elevation: 827 },
	{ lat: -15, lon: 34.05, elevation: 897 },
	{ lat: -15, lon: 34.8, elevation: 769 },
	{ lat: -15, lon: 35.55, elevation: 650 },
	{ lat: -15, lon: 36.3, elevation: 623 },
	{ lat: -15, lon: 37.05, elevation: 630 },
	{ lat: -15, lon: 37.8, elevation: 678 },
	{ lat: -15, lon: 38.54, elevation: 647 },
	{ lat: -15, lon: 39.29, elevation: 355 },
	{ lat: -15, lon: 40.04, elevation: 139 },
	{ lat: -15, lon: 48.27, elevation: 210 },
	{ lat: -15, lon: 49.02, elevation: 954 },
	{ lat: -15, lon: 49.77, elevation: 532 },
	{ lat: -15, lon: 125.36, elevation: 39 },
	{ lat: -15, lon: 126.11, elevation: 296 },
	{ lat: -15, lon: 126.86, elevation: 254 },
	{ lat: -15, lon: 127.61, elevation: 256 },
	{ lat: -15, lon: 129.11, elevation: 0 },
	{ lat: -15, lon: 129.85, elevation: 114 },
	{ lat: -15, lon: 130.6, elevation: 62 },
	{ lat: -15, lon: 131.35, elevation: 200 },
	{ lat: -15, lon: 132.1, elevation: 237 },
	{ lat: -15, lon: 132.85, elevation: 194 },
	{ lat: -15, lon: 133.6, elevation: 90 },
	{ lat: -15, lon: 134.35, elevation: 56 },
	{ lat: -15, lon: 135.09, elevation: 31 },
	{ lat: -15, lon: 141.83, elevation: 13 },
	{ lat: -15, lon: 142.58, elevation: 87 },
	{ lat: -15, lon: 143.33, elevation: 191 },
	{ lat: -15, lon: 144.07, elevation: 39 },
	{ lat: -15, lon: 144.82, elevation: 174 },
	{ lat: -14, lon: -171.82, elevation: 29 },
	{ lat: -14, lon: -75.87, elevation: 443 },
	{ lat: -14, lon: -75.12, elevation: 3364 },
	{ lat: -14, lon: -74.38, elevation: 3819 },
	{ lat: -14, lon: -73.64, elevation: 3094 },
	{ lat: -14, lon: -72.89, elevation: 3666 },
	{ lat: -14, lon: -72.15, elevation: 4071 },
	{ lat: -14, lon: -71.4, elevation: 4129 },
	{ lat: -14, lon: -70.66, elevation: 4490 },
	{ lat: -14, lon: -69.92, elevation: 4437 },
	{ lat: -14, lon: -69.17, elevation: 1492 },
	{ lat: -14, lon: -68.43, elevation: 1115 },
	{ lat: -14, lon: -67.69, elevation: 212 },
	{ lat: -14, lon: -66.94, elevation: 171 },
	{ lat: -14, lon: -66.2, elevation: 155 },
	{ lat: -14, lon: -65.45, elevation: 146 },
	{ lat: -14, lon: -64.71, elevation: 145 },
	{ lat: -14, lon: -63.97, elevation: 154 },
	{ lat: -14, lon: -63.22, elevation: 179 },
	{ lat: -14, lon: -62.48, elevation: 175 },
	{ lat: -14, lon: -61.74, elevation: 203 },
	{ lat: -14, lon: -60.99, elevation: 239 },
	{ lat: -14, lon: -60.25, elevation: 209 },
	{ lat: -14, lon: -59.5, elevation: 571 },
	{ lat: -14, lon: -58.76, elevation: 574 },
	{ lat: -14, lon: -58.02, elevation: 610 },
	{ lat: -14, lon: -57.27, elevation: 578 },
	{ lat: -14, lon: -56.53, elevation: 471 },
	{ lat: -14, lon: -55.79, elevation: 505 },
	{ lat: -14, lon: -55.04, elevation: 489 },
	{ lat: -14, lon: -54.3, elevation: 504 },
	{ lat: -14, lon: -53.55, elevation: 431 },
	{ lat: -14, lon: -52.81, elevation: 395 },
	{ lat: -14, lon: -52.07, elevation: 369 },
	{ lat: -14, lon: -51.32, elevation: 237 },
	{ lat: -14, lon: -50.58, elevation: 264 },
	{ lat: -14, lon: -49.83, elevation: 322 },
	{ lat: -14, lon: -49.09, elevation: 545 },
	{ lat: -14, lon: -48.35, elevation: 857 },
	{ lat: -14, lon: -47.6, elevation: 1480 },
	{ lat: -14, lon: -46.86, elevation: 425 },
	{ lat: -14, lon: -46.12, elevation: 939 },
	{ lat: -14, lon: -45.37, elevation: 783 },
	{ lat: -14, lon: -44.63, elevation: 625 },
	{ lat: -14, lon: -43.88, elevation: 464 },
	{ lat: -14, lon: -43.14, elevation: 461 },
	{ lat: -14, lon: -42.4, elevation: 972 },
	{ lat: -14, lon: -41.65, elevation: 421 },
	{ lat: -14, lon: -40.91, elevation: 300 },
	{ lat: -14, lon: -40.17, elevation: 235 },
	{ lat: -14, lon: -39.42, elevation: 161 },
	{ lat: -14, lon: 12.64, elevation: 410 },
	{ lat: -14, lon: 13.39, elevation: 821 },
	{ lat: -14, lon: 14.13, elevation: 980 },
	{ lat: -14, lon: 14.88, elevation: 1446 },
	{ lat: -14, lon: 15.62, elevation: 1420 },
	{ lat: -14, lon: 16.36, elevation: 1488 },
	{ lat: -14, lon: 17.11, elevation: 1551 },
	{ lat: -14, lon: 17.85, elevation: 1528 },
	{ lat: -14, lon: 18.6, elevation: 1525 },
	{ lat: -14, lon: 19.34, elevation: 1275 },
	{ lat: -14, lon: 20.08, elevation: 1331 },
	{ lat: -14, lon: 20.83, elevation: 1295 },
	{ lat: -14, lon: 21.57, elevation: 1156 },
	{ lat: -14, lon: 22.31, elevation: 1063 },
	{ lat: -14, lon: 23.06, elevation: 1056 },
	{ lat: -14, lon: 23.8, elevation: 1087 },
	{ lat: -14, lon: 24.55, elevation: 1143 },
	{ lat: -14, lon: 25.29, elevation: 1166 },
	{ lat: -14, lon: 26.03, elevation: 1147 },
	{ lat: -14, lon: 26.78, elevation: 1301 },
	{ lat: -14, lon: 27.52, elevation: 1168 },
	{ lat: -14, lon: 28.26, elevation: 1239 },
	{ lat: -14, lon: 29.01, elevation: 1188 },
	{ lat: -14, lon: 29.75, elevation: 1139 },
	{ lat: -14, lon: 30.5, elevation: 605 },
	{ lat: -14, lon: 31.24, elevation: 799 },
	{ lat: -14, lon: 31.98, elevation: 955 },
	{ lat: -14, lon: 32.73, elevation: 1190 },
	{ lat: -14, lon: 33.47, elevation: 1214 },
	{ lat: -14, lon: 34.21, elevation: 997 },
	{ lat: -14, lon: 34.96, elevation: 476 },
	{ lat: -14, lon: 35.7, elevation: 800 },
	{ lat: -14, lon: 36.45, elevation: 734 },
	{ lat: -14, lon: 37.19, elevation: 611 },
	{ lat: -14, lon: 37.93, elevation: 478 },
	{ lat: -14, lon: 38.68, elevation: 371 },
	{ lat: -14, lon: 39.42, elevation: 367 },
	{ lat: -14, lon: 40.17, elevation: 245 },
	{ lat: -14, lon: 48.35, elevation: 740 },
	{ lat: -14, lon: 49.09, elevation: 1704 },
	{ lat: -14, lon: 49.83, elevation: 392 },
	{ lat: -14, lon: 127.19, elevation: 121 },
	{ lat: -14, lon: 130.17, elevation: 71 },
	{ lat: -14, lon: 130.91, elevation: 32 },
	{ lat: -14, lon: 131.65, elevation: 102 },
	{ lat: -14, lon: 132.4, elevation: 325 },
	{ lat: -14, lon: 133.14, elevation: 327 },
	{ lat: -14, lon: 133.88, elevation: 241 },
	{ lat: -14, lon: 134.63, elevation: 169 },
	{ lat: -14, lon: 135.37, elevation: 41 },
	{ lat: -14, lon: 142.07, elevation: 53 },
	{ lat: -14, lon: 142.81, elevation: 183 },
	{ lat: -14, lon: 143.55, elevation: 18 },
	{ lat: -13, lon: -76.3, elevation: 399 },
	{ lat: -13, lon: -75.56, elevation: 4132 },
	{ lat: -13, lon: -74.81, elevation: 3801 },
	{ lat: -13, lon: -74.07, elevation: 4327 },
	{ lat: -13, lon: -73.33, elevation: 3898 },
	{ lat: -13, lon: -72.59, elevation: 1462 },
	{ lat: -13, lon: -71.85, elevation: 3121 },
	{ lat: -13, lon: -71.11, elevation: 908 },
	{ lat: -13, lon: -70.37, elevation: 349 },
	{ lat: -13, lon: -69.63, elevation: 224 },
	{ lat: -13, lon: -68.89, elevation: 207 },
	{ lat: -13, lon: -68.15, elevation: 197 },
	{ lat: -13, lon: -67.41, elevation: 177 },
	{ lat: -13, lon: -66.67, elevation: 170 },
	{ lat: -13, lon: -65.93, elevation: 146 },
	{ lat: -13, lon: -65.19, elevation: 136 },
	{ lat: -13, lon: -64.44, elevation: 145 },
	{ lat: -13, lon: -63.7, elevation: 167 },
	{ lat: -13, lon: -62.96, elevation: 163 },
	{ lat: -13, lon: -62.22, elevation: 151 },
	{ lat: -13, lon: -61.48, elevation: 168 },
	{ lat: -13, lon: -60.74, elevation: 375 },
	{ lat: -13, lon: -60.0, elevation: 576 },
	{ lat: -13, lon: -59.26, elevation: 419 },
	{ lat: -13, lon: -58.52, elevation: 413 },
	{ lat: -13, lon: -57.78, elevation: 398 },
	{ lat: -13, lon: -57.04, elevation: 393 },
	{ lat: -13, lon: -56.3, elevation: 407 },
	{ lat: -13, lon: -55.56, elevation: 438 },
	{ lat: -13, lon: -54.81, elevation: 357 },
	{ lat: -13, lon: -54.07, elevation: 357 },
	{ lat: -13, lon: -53.33, elevation: 348 },
	{ lat: -13, lon: -52.59, elevation: 330 },
	{ lat: -13, lon: -51.85, elevation: 394 },
	{ lat: -13, lon: -51.11, elevation: 209 },
	{ lat: -13, lon: -50.37, elevation: 211 },
	{ lat: -13, lon: -49.63, elevation: 257 },
	{ lat: -13, lon: -48.89, elevation: 317 },
	{ lat: -13, lon: -48.15, elevation: 290 },
	{ lat: -13, lon: -47.41, elevation: 372 },
	{ lat: -13, lon: -46.67, elevation: 638 },
	{ lat: -13, lon: -45.93, elevation: 838 },
	{ lat: -13, lon: -45.19, elevation: 754 },
	{ lat: -13, lon: -44.44, elevation: 755 },
	{ lat: -13, lon: -43.7, elevation: 462 },
	{ lat: -13, lon: -42.96, elevation: 831 },
	{ lat: -13, lon: -42.22, elevation: 623 },
	{ lat: -13, lon: -41.48, elevation: 1061 },
	{ lat: -13, lon: -40.74, elevation: 307 },
	{ lat: -13, lon: -40.0, elevation: 537 },
	{ lat: -13, lon: -39.26, elevation: 212 },
	{ lat: -13, lon: -38.52, elevation: 34 },
	{ lat: -13, lon: 13.33, elevation: 438 },
	{ lat: -13, lon: 14.07, elevation: 809 },
	{ lat: -13, lon: 14.81, elevation: 1282 },
	{ lat: -13, lon: 15.56, elevation: 1725 },
	{ lat: -13, lon: 16.3, elevation: 1723 },
	{ lat: -13, lon: 17.04, elevation: 1479 },
	{ lat: -13, lon: 17.78, elevation: 1463 },
	{ lat: -13, lon: 18.52, elevation: 1413 },
	{ lat: -13, lon: 19.26, elevation: 1409 },
	{ lat: -13, lon: 20.0, elevation: 1378 },
	{ lat: -13, lon: 20.74, elevation: 1233 },
	{ lat: -13, lon: 21.48, elevation: 1123 },
	{ lat: -13, lon: 22.22, elevation: 1087 },
	{ lat: -13, lon: 22.96, elevation: 1113 },
	{ lat: -13, lon: 23.7, elevation: 1161 },
	{ lat: -13, lon: 24.44, elevation: 1164 },
	{ lat: -13, lon: 25.19, elevation: 1145 },
	{ lat: -13, lon: 25.93, elevation: 1294 },
	{ lat: -13, lon: 26.67, elevation: 1210 },
	{ lat: -13, lon: 27.41, elevation: 1251 },
	{ lat: -13, lon: 28.15, elevation: 1196 },
	{ lat: -13, lon: 28.89, elevation: 1288 },
	{ lat: -13, lon: 29.63, elevation: 1361 },
	{ lat: -13, lon: 30.37, elevation: 1421 },
	{ lat: -13, lon: 31.11, elevation: 1548 },
	{ lat: -13, lon: 31.85, elevation: 533 },
	{ lat: -13, lon: 32.59, elevation: 1001 },
	{ lat: -13, lon: 33.33, elevation: 996 },
	{ lat: -13, lon: 34.07, elevation: 691 },
	{ lat: -13, lon: 34.81, elevation: 624 },
	{ lat: -13, lon: 35.56, elevation: 1032 },
	{ lat: -13, lon: 36.3, elevation: 738 },
	{ lat: -13, lon: 37.04, elevation: 510 },
	{ lat: -13, lon: 37.78, elevation: 513 },
	{ lat: -13, lon: 38.52, elevation: 418 },
	{ lat: -13, lon: 39.26, elevation: 471 },
	{ lat: -13, lon: 40.0, elevation: 241 },
	{ lat: -13, lon: 49.63, elevation: 59 },
	{ lat: -13, lon: 130.37, elevation: 6 },
	{ lat: -13, lon: 131.11, elevation: 62 },
	{ lat: -13, lon: 131.85, elevation: 55 },
	{ lat: -13, lon: 132.59, elevation: 3 },
	{ lat: -13, lon: 133.33, elevation: 220 },
	{ lat: -13, lon: 134.07, elevation: 250 },
	{ lat: -13, lon: 134.81, elevation: 68 },
	{ lat: -13, lon: 135.56, elevation: 107 },
	{ lat: -13, lon: 136.3, elevation: 6 },
	{ lat: -13, lon: 142.22, elevation: 63 },
	{ lat: -13, lon: 142.96, elevation: 134 },
	{ lat: -12, lon: -76.72, elevation: 1736 },
	{ lat: -12, lon: -75.98, elevation: 5382 },
	{ lat: -12, lon: -75.25, elevation: 3247 },
	{ lat: -12, lon: -74.51, elevation: 2164 },
	{ lat: -12, lon: -73.77, elevation: 1415 },
	{ lat: -12, lon: -73.03, elevation: 777 },
	{ lat: -12, lon: -72.3, elevation: 1298 },
	{ lat: -12, lon: -71.56, elevation: 380 },
	{ lat: -12, lon: -70.82, elevation: 369 },
	{ lat: -12, lon: -70.08, elevation: 330 },
	{ lat: -12, lon: -69.34, elevation: 272 },
	{ lat: -12, lon: -68.61, elevation: 251 },
	{ lat: -12, lon: -67.87, elevation: 212 },
	{ lat: -12, lon: -67.13, elevation: 201 },
	{ lat: -12, lon: -66.39, elevation: 161 },
	{ lat: -12, lon: -65.66, elevation: 131 },
	{ lat: -12, lon: -64.92, elevation: 130 },
	{ lat: -12, lon: -64.18, elevation: 184 },
	{ lat: -12, lon: -63.44, elevation: 174 },
	{ lat: -12, lon: -62.7, elevation: 233 },
	{ lat: -12, lon: -61.97, elevation: 420 },
	{ lat: -12, lon: -61.23, elevation: 213 },
	{ lat: -12, lon: -60.49, elevation: 353 },
	{ lat: -12, lon: -59.75, elevation: 401 },
	{ lat: -12, lon: -59.02, elevation: 334 },
	{ lat: -12, lon: -58.28, elevation: 303 },
	{ lat: -12, lon: -57.54, elevation: 380 },
	{ lat: -12, lon: -56.8, elevation: 336 },
	{ lat: -12, lon: -56.07, elevation: 359 },
	{ lat: -12, lon: -55.33, elevation: 373 },
	{ lat: -12, lon: -54.59, elevation: 343 },
	{ lat: -12, lon: -53.85, elevation: 312 },
	{ lat: -12, lon: -53.11, elevation: 319 },
	{ lat: -12, lon: -52.38, elevation: 305 },
	{ lat: -12, lon: -51.64, elevation: 318 },
	{ lat: -12, lon: -50.9, elevation: 202 },
	{ lat: -12, lon: -50.16, elevation: 197 },
	{ lat: -12, lon: -49.43, elevation: 241 },
	{ lat: -12, lon: -48.69, elevation: 252 },
	{ lat: -12, lon: -47.95, elevation: 381 },
	{ lat: -12, lon: -47.21, elevation: 329 },
	{ lat: -12, lon: -46.48, elevation: 456 },
	{ lat: -12, lon: -45.74, elevation: 766 },
	{ lat: -12, lon: -45.0, elevation: 456 },
	{ lat: -12, lon: -44.26, elevation: 465 },
	{ lat: -12, lon: -43.52, elevation: 464 },
	{ lat: -12, lon: -42.79, elevation: 854 },
	{ lat: -12, lon: -42.05, elevation: 938 },
	{ lat: -12, lon: -41.31, elevation: 997 },
	{ lat: -12, lon: -40.57, elevation: 662 },
	{ lat: -12, lon: -39.84, elevation: 282 },
	{ lat: -12, lon: -39.1, elevation: 223 },
	{ lat: -12, lon: -38.36, elevation: 234 },
	{ lat: -12, lon: 14.02, elevation: 289 },
	{ lat: -12, lon: 14.75, elevation: 1311 },
	{ lat: -12, lon: 15.49, elevation: 1432 },
	{ lat: -12, lon: 16.23, elevation: 1612 },
	{ lat: -12, lon: 16.97, elevation: 1728 },
	{ lat: -12, lon: 17.7, elevation: 1282 },
	{ lat: -12, lon: 18.44, elevation: 1456 },
	{ lat: -12, lon: 19.18, elevation: 1385 },
	{ lat: -12, lon: 19.92, elevation: 1337 },
	{ lat: -12, lon: 20.66, elevation: 1150 },
	{ lat: -12, lon: 21.39, elevation: 1104 },
	{ lat: -12, lon: 22.13, elevation: 1081 },
	{ lat: -12, lon: 22.87, elevation: 1162 },
	{ lat: -12, lon: 23.61, elevation: 1484 },
	{ lat: -12, lon: 24.34, elevation: 1284 },
	{ lat: -12, lon: 25.08, elevation: 1424 },
	{ lat: -12, lon: 25.82, elevation: 1335 },
	{ lat: -12, lon: 26.56, elevation: 1447 },
	{ lat: -12, lon: 27.3, elevation: 1276 },
	{ lat: -12, lon: 28.03, elevation: 1233 },
	{ lat: -12, lon: 28.77, elevation: 1055 },
	{ lat: -12, lon: 29.51, elevation: 1215 },
	{ lat: -12, lon: 30.25, elevation: 1171 },
	{ lat: -12, lon: 30.98, elevation: 1285 },
	{ lat: -12, lon: 31.72, elevation: 682 },
	{ lat: -12, lon: 32.46, elevation: 617 },
	{ lat: -12, lon: 33.2, elevation: 1144 },
	{ lat: -12, lon: 33.93, elevation: 1251 },
	{ lat: -12, lon: 34.67, elevation: 476 },
	{ lat: -12, lon: 35.41, elevation: 707 },
	{ lat: -12, lon: 36.15, elevation: 592 },
	{ lat: -12, lon: 36.89, elevation: 510 },
	{ lat: -12, lon: 37.62, elevation: 433 },
	{ lat: -12, lon: 38.36, elevation: 221 },
	{ lat: -12, lon: 39.1, elevation: 355 },
	{ lat: -12, lon: 39.84, elevation: 135 },
	{ lat: -12, lon: 132.79, elevation: 5 },
	{ lat: -12, lon: 133.52, elevation: 75 },
	{ lat: -12, lon: 134.26, elevation: 8 },
	{ lat: -12, lon: 142.38, elevation: 48 },
	{ lat: -12, lon: 143.11, elevation: 31 },
	{ lat: -11, lon: -77.67, elevation: 0 },
	{ lat: -11, lon: -76.93, elevation: 4850 },
	{ lat: -11, lon: -76.2, elevation: 4081 },
	{ lat: -11, lon: -75.46, elevation: 2462 },
	{ lat: -11, lon: -74.72, elevation: 897 },
	{ lat: -11, lon: -73.99, elevation: 1673 },
	{ lat: -11, lon: -73.25, elevation: 330 },
	{ lat: -11, lon: -72.52, elevation: 394 },
	{ lat: -11, lon: -71.78, elevation: 496 },
	{ lat: -11, lon: -71.04, elevation: 411 },
	{ lat: -11, lon: -70.31, elevation: 336 },
	{ lat: -11, lon: -69.57, elevation: 283 },
	{ lat: -11, lon: -68.83, elevation: 200 },
	{ lat: -11, lon: -68.1, elevation: 188 },
	{ lat: -11, lon: -67.36, elevation: 206 },
	{ lat: -11, lon: -66.63, elevation: 168 },
	{ lat: -11, lon: -65.89, elevation: 187 },
	{ lat: -11, lon: -65.15, elevation: 141 },
	{ lat: -11, lon: -64.42, elevation: 191 },
	{ lat: -11, lon: -63.68, elevation: 881 },
	{ lat: -11, lon: -62.94, elevation: 312 },
	{ lat: -11, lon: -62.21, elevation: 187 },
	{ lat: -11, lon: -61.47, elevation: 283 },
	{ lat: -11, lon: -60.74, elevation: 251 },
	{ lat: -11, lon: -60.0, elevation: 260 },
	{ lat: -11, lon: -59.26, elevation: 288 },
	{ lat: -11, lon: -58.53, elevation: 268 },
	{ lat: -11, lon: -57.79, elevation: 231 },
	{ lat: -11, lon: -57.06, elevation: 335 },
	{ lat: -11, lon: -56.32, elevation: 317 },
	{ lat: -11, lon: -55.58, elevation: 306 },
	{ lat: -11, lon: -54.85, elevation: 416 },
	{ lat: -11, lon: -54.11, elevation: 297 },
	{ lat: -11, lon: -53.37, elevation: 298 },
	{ lat: -11, lon: -52.64, elevation: 329 },
	{ lat: -11, lon: -51.9, elevation: 236 },
	{ lat: -11, lon: -51.17, elevation: 182 },
	{ lat: -11, lon: -50.43, elevation: 183 },
	{ lat: -11, lon: -49.69, elevation: 189 },
	{ lat: -11, lon: -48.96, elevation: 246 },
	{ lat: -11, lon: -48.22, elevation: 241 },
	{ lat: -11, lon: -47.48, elevation: 370 },
	{ lat: -11, lon: -46.75, elevation: 628 },
	{ lat: -11, lon: -46.01, elevation: 696 },
	{ lat: -11, lon: -45.28, elevation: 552 },
	{ lat: -11, lon: -44.54, elevation: 453 },
	{ lat: -11, lon: -43.8, elevation: 524 },
	{ lat: -11, lon: -43.07, elevation: 402 },
	{ lat: -11, lon: -42.33, elevation: 454 },
	{ lat: -11, lon: -41.6, elevation: 603 },
	{ lat: -11, lon: -40.86, elevation: 639 },
	{ lat: -11, lon: -40.12, elevation: 396 },
	{ lat: -11, lon: -39.39, elevation: 249 },
	{ lat: -11, lon: -38.65, elevation: 329 },
	{ lat: -11, lon: -37.91, elevation: 370 },
	{ lat: -11, lon: -37.18, elevation: 46 },
	{ lat: -11, lon: 14.36, elevation: 644 },
	{ lat: -11, lon: 15.09, elevation: 1287 },
	{ lat: -11, lon: 15.83, elevation: 1666 },
	{ lat: -11, lon: 16.56, elevation: 1317 },
	{ lat: -11, lon: 17.3, elevation: 1193 },
	{ lat: -11, lon: 18.04, elevation: 1286 },
	{ lat: -11, lon: 18.77, elevation: 1107 },
	{ lat: -11, lon: 19.51, elevation: 1406 },
	{ lat: -11, lon: 20.25, elevation: 1221 },
	{ lat: -11, lon: 20.98, elevation: 1158 },
	{ lat: -11, lon: 21.72, elevation: 1104 },
	{ lat: -11, lon: 22.45, elevation: 1111 },
	{ lat: -11, lon: 23.19, elevation: 1205 },
	{ lat: -11, lon: 23.93, elevation: 1206 },
	{ lat: -11, lon: 24.66, elevation: 1360 },
	{ lat: -11, lon: 25.4, elevation: 1475 },
	{ lat: -11, lon: 26.13, elevation: 1486 },
	{ lat: -11, lon: 26.87, elevation: 1191 },
	{ lat: -11, lon: 27.61, elevation: 1133 },
	{ lat: -11, lon: 28.34, elevation: 1094 },
	{ lat: -11, lon: 29.08, elevation: 1302 },
	{ lat: -11, lon: 29.82, elevation: 1163 },
	{ lat: -11, lon: 30.55, elevation: 1180 },
	{ lat: -11, lon: 31.29, elevation: 1318 },
	{ lat: -11, lon: 32.02, elevation: 1465 },
	{ lat: -11, lon: 32.76, elevation: 723 },
	{ lat: -11, lon: 33.5, elevation: 1190 },
	{ lat: -11, lon: 34.23, elevation: 476 },
	{ lat: -11, lon: 34.97, elevation: 1512 },
	{ lat: -11, lon: 35.71, elevation: 788 },
	{ lat: -11, lon: 36.44, elevation: 905 },
	{ lat: -11, lon: 37.18, elevation: 599 },
	{ lat: -11, lon: 37.91, elevation: 354 },
	{ lat: -11, lon: 38.65, elevation: 255 },
	{ lat: -11, lon: 39.39, elevation: 364 },
	{ lat: -11, lon: 40.12, elevation: 123 },
	{ lat: -11, lon: 142.45, elevation: 25 },
	{ lat: -10, lon: -78.09, elevation: 450 },
	{ lat: -10, lon: -77.35, elevation: 4173 },
	{ lat: -10, lon: -76.62, elevation: 3681 },
	{ lat: -10, lon: -75.89, elevation: 3434 },
	{ lat: -10, lon: -75.15, elevation: 288 },
	{ lat: -10, lon: -74.42, elevation: 1359 },
	{ lat: -10, lon: -73.69, elevation: 297 },
	{ lat: -10, lon: -72.95, elevation: 337 },
	{ lat: -10, lon: -72.22, elevation: 366 },
	{ lat: -10, lon: -71.49, elevation: 366 },
	{ lat: -10, lon: -70.75, elevation: 310 },
	{ lat: -10, lon: -70.02, elevation: 267 },
	{ lat: -10, lon: -69.29, elevation: 224 },
	{ lat: -10, lon: -68.55, elevation: 188 },
	{ lat: -10, lon: -67.82, elevation: 127 },
	{ lat: -10, lon: -67.09, elevation: 190 },
	{ lat: -10, lon: -66.35, elevation: 165 },
	{ lat: -10, lon: -65.62, elevation: 145 },
	{ lat: -10, lon: -64.89, elevation: 133 },
	{ lat: -10, lon: -64.15, elevation: 186 },
	{ lat: -10, lon: -63.42, elevation: 146 },
	{ lat: -10, lon: -62.69, elevation: 191 },
	{ lat: -10, lon: -61.96, elevation: 148 },
	{ lat: -10, lon: -61.22, elevation: 180 },
	{ lat: -10, lon: -60.49, elevation: 153 },
	{ lat: -10, lon: -59.76, elevation: 228 },
	{ lat: -10, lon: -59.02, elevation: 137 },
	{ lat: -10, lon: -58.29, elevation: 250 },
	{ lat: -10, lon: -57.56, elevation: 250 },
	{ lat: -10, lon: -56.82, elevation: 245 },
	{ lat: -10, lon: -56.09, elevation: 310 },
	{ lat: -10, lon: -55.36, elevation: 267 },
	{ lat: -10, lon: -54.62, elevation: 322 },
	{ lat: -10, lon: -53.89, elevation: 308 },
	{ lat: -10, lon: -53.16, elevation: 299 },
	{ lat: -10, lon: -52.42, elevation: 277 },
	{ lat: -10, lon: -51.69, elevation: 323 },
	{ lat: -10, lon: -50.96, elevation: 220 },
	{ lat: -10, lon: -50.22, elevation: 171 },
	{ lat: -10, lon: -49.49, elevation: 194 },
	{ lat: -10, lon: -48.76, elevation: 293 },
	{ lat: -10, lon: -48.02, elevation: 360 },
	{ lat: -10, lon: -47.29, elevation: 278 },
	{ lat: -10, lon: -46.56, elevation: 554 },
	{ lat: -10, lon: -45.82, elevation: 464 },
	{ lat: -10, lon: -45.09, elevation: 483 },
	{ lat: -10, lon: -44.36, elevation: 346 },
	{ lat: -10, lon: -43.63, elevation: 540 },
	{ lat: -10, lon: -42.89, elevation: 420 },
	{ lat: -10, lon: -42.16, elevation: 400 },
	{ lat: -10, lon: -41.43, elevation: 517 },
	{ lat: -10, lon: -40.69, elevation: 494 },
	{ lat: -10, lon: -39.96, elevation: 460 },
	{ lat: -10, lon: -39.23, elevation: 429 },
	{ lat: -10, lon: -38.49, elevation: 440 },
	{ lat: -10, lon: -37.76, elevation: 304 },
	{ lat: -10, lon: -37.03, elevation: 32 },
	{ lat: -10, lon: -36.29, elevation: 119 },
	{ lat: -10, lon: 13.56, elevation: 78 },
	{ lat: -10, lon: 14.3, elevation: 102 },
	{ lat: -10, lon: 15.03, elevation: 1189 },
	{ lat: -10, lon: 15.76, elevation: 1076 },
	{ lat: -10, lon: 16.5, elevation: 1070 },
	{ lat: -10, lon: 17.23, elevation: 1179 },
	{ lat: -10, lon: 17.96, elevation: 1222 },
	{ lat: -10, lon: 18.7, elevation: 917 },
	{ lat: -10, lon: 19.43, elevation: 1285 },
	{ lat: -10, lon: 20.16, elevation: 1087 },
	{ lat: -10, lon: 20.9, elevation: 1050 },
	{ lat: -10, lon: 21.63, elevation: 1077 },
	{ lat: -10, lon: 22.36, elevation: 1053 },
	{ lat: -10, lon: 23.1, elevation: 928 },
	{ lat: -10, lon: 23.83, elevation: 1029 },
	{ lat: -10, lon: 24.56, elevation: 981 },
	{ lat: -10, lon: 25.3, elevation: 907 },
	{ lat: -10, lon: 26.03, elevation: 1441 },
	{ lat: -10, lon: 26.76, elevation: 942 },
	{ lat: -10, lon: 27.49, elevation: 1286 },
	{ lat: -10, lon: 28.23, elevation: 1024 },
	{ lat: -10, lon: 28.96, elevation: 1261 },
	{ lat: -10, lon: 29.69, elevation: 1448 },
	{ lat: -10, lon: 30.43, elevation: 1402 },
	{ lat: -10, lon: 31.16, elevation: 1393 },
	{ lat: -10, lon: 31.89, elevation: 1212 },
	{ lat: -10, lon: 32.63, elevation: 1234 },
	{ lat: -10, lon: 33.36, elevation: 1993 },
	{ lat: -10, lon: 34.09, elevation: 476 },
	{ lat: -10, lon: 34.83, elevation: 1414 },
	{ lat: -10, lon: 35.56, elevation: 921 },
	{ lat: -10, lon: 36.29, elevation: 726 },
	{ lat: -10, lon: 37.03, elevation: 601 },
	{ lat: -10, lon: 37.76, elevation: 667 },
	{ lat: -10, lon: 38.49, elevation: 295 },
	{ lat: -10, lon: 39.23, elevation: 527 },
	{ lat: -10, lon: 120.61, elevation: 188 },
	{ lat: -10, lon: 124.28, elevation: 423 },
	{ lat: -10, lon: 147.74, elevation: 28 },
	{ lat: -10, lon: 148.47, elevation: 67 },
	{ lat: -10, lon: 149.21, elevation: 395 },
	{ lat: -9, lon: -78.29, elevation: 944 },
	{ lat: -9, lon: -77.56, elevation: 4067 },
	{ lat: -9, lon: -76.83, elevation: 3938 },
	{ lat: -9, lon: -76.1, elevation: 566 },
	{ lat: -9, lon: -75.37, elevation: 277 },
	{ lat: -9, lon: -74.63, elevation: 208 },
	{ lat: -9, lon: -73.9, elevation: 180 },
	{ lat: -9, lon: -73.17, elevation: 216 },
	{ lat: -9, lon: -72.44, elevation: 270 },
	{ lat: -9, lon: -71.71, elevation: 344 },
	{ lat: -9, lon: -70.98, elevation: 268 },
	{ lat: -9, lon: -70.24, elevation: 271 },
	{ lat: -9, lon: -69.51, elevation: 177 },
	{ lat: -9, lon: -68.78, elevation: 181 },
	{ lat: -9, lon: -68.05, elevation: 173 },
	{ lat: -9, lon: -67.32, elevation: 123 },
	{ lat: -9, lon: -66.59, elevation: 162 },
	{ lat: -9, lon: -65.85, elevation: 135 },
	{ lat: -9, lon: -65.12, elevation: 118 },
	{ lat: -9, lon: -64.39, elevation: 106 },
	{ lat: -9, lon: -63.66, elevation: 104 },
	{ lat: -9, lon: -62.93, elevation: 116 },
	{ lat: -9, lon: -62.2, elevation: 101 },
	{ lat: -9, lon: -61.46, elevation: 155 },
	{ lat: -9, lon: -60.73, elevation: 89 },
	{ lat: -9, lon: -60.0, elevation: 139 },
	{ lat: -9, lon: -59.27, elevation: 138 },
	{ lat: -9, lon: -58.54, elevation: 242 },
	{ lat: -9, lon: -57.8, elevation: 265 },
	{ lat: -9, lon: -57.07, elevation: 154 },
	{ lat: -9, lon: -56.34, elevation: 271 },
	{ lat: -9, lon: -55.61, elevation: 405 },
	{ lat: -9, lon: -54.88, elevation: 572 },
	{ lat: -9, lon: -54.15, elevation: 485 },
	{ lat: -9, lon: -53.41, elevation: 291 },
	{ lat: -9, lon: -52.68, elevation: 288 },
	{ lat: -9, lon: -51.95, elevation: 291 },
	{ lat: -9, lon: -51.22, elevation: 330 },
	{ lat: -9, lon: -50.49, elevation: 249 },
	{ lat: -9, lon: -49.76, elevation: 170 },
	{ lat: -9, lon: -49.02, elevation: 238 },
	{ lat: -9, lon: -48.29, elevation: 219 },
	{ lat: -9, lon: -47.56, elevation: 303 },
	{ lat: -9, lon: -46.83, elevation: 517 },
	{ lat: -9, lon: -46.1, elevation: 381 },
	{ lat: -9, lon: -45.37, elevation: 566 },
	{ lat: -9, lon: -44.63, elevation: 539 },
	{ lat: -9, lon: -43.9, elevation: 526 },
	{ lat: -9, lon: -43.17, elevation: 662 },
	{ lat: -9, lon: -42.44, elevation: 308 },
	{ lat: -9, lon: -41.71, elevation: 605 },
	{ lat: -9, lon: -40.98, elevation: 415 },
	{ lat: -9, lon: -40.24, elevation: 356 },
	{ lat: -9, lon: -39.51, elevation: 405 },
	{ lat: -9, lon: -38.78, elevation: 474 },
	{ lat: -9, lon: -38.05, elevation: 490 },
	{ lat: -9, lon: -37.32, elevation: 398 },
	{ lat: -9, lon: -36.59, elevation: 764 },
	{ lat: -9, lon: -35.85, elevation: 415 },
	{ lat: -9, lon: 13.17, elevation: 31 },
	{ lat: -9, lon: 13.9, elevation: 76 },
	{ lat: -9, lon: 14.63, elevation: 397 },
	{ lat: -9, lon: 15.37, elevation: 1139 },
	{ lat: -9, lon: 16.1, elevation: 1080 },
	{ lat: -9, lon: 16.83, elevation: 760 },
	{ lat: -9, lon: 17.56, elevation: 655 },
	{ lat: -9, lon: 18.29, elevation: 1029 },
	{ lat: -9, lon: 19.02, elevation: 1149 },
	{ lat: -9, lon: 19.76, elevation: 1099 },
	{ lat: -9, lon: 20.49, elevation: 900 },
	{ lat: -9, lon: 21.22, elevation: 998 },
	{ lat: -9, lon: 21.95, elevation: 947 },
	{ lat: -9, lon: 22.68, elevation: 944 },
	{ lat: -9, lon: 23.41, elevation: 1110 },
	{ lat: -9, lon: 24.15, elevation: 1092 },
	{ lat: -9, lon: 24.88, elevation: 1094 },
	{ lat: -9, lon: 25.61, elevation: 774 },
	{ lat: -9, lon: 26.34, elevation: 635 },
	{ lat: -9, lon: 27.07, elevation: 1770 },
	{ lat: -9, lon: 27.8, elevation: 1511 },
	{ lat: -9, lon: 28.54, elevation: 921 },
	{ lat: -9, lon: 29.27, elevation: 1059 },
	{ lat: -9, lon: 30.0, elevation: 1116 },
	{ lat: -9, lon: 30.73, elevation: 1443 },
	{ lat: -9, lon: 31.46, elevation: 1799 },
	{ lat: -9, lon: 32.2, elevation: 1457 },
	{ lat: -9, lon: 32.93, elevation: 1643 },
	{ lat: -9, lon: 33.66, elevation: 2346 },
	{ lat: -9, lon: 34.39, elevation: 1497 },
	{ lat: -9, lon: 35.12, elevation: 1234 },
	{ lat: -9, lon: 35.85, elevation: 303 },
	{ lat: -9, lon: 36.59, elevation: 717 },
	{ lat: -9, lon: 37.32, elevation: 286 },
	{ lat: -9, lon: 38.05, elevation: 404 },
	{ lat: -9, lon: 38.78, elevation: 190 },
	{ lat: -9, lon: 117.07, elevation: 401 },
	{ lat: -9, lon: 125.12, elevation: 153 },
	{ lat: -9, lon: 125.85, elevation: 182 },
	{ lat: -9, lon: 141.22, elevation: 6 },
	{ lat: -9, lon: 141.95, elevation: 12 },
	{ lat: -9, lon: 142.68, elevation: 25 },
	{ lat: -9, lon: 143.41, elevation: 22 },
	{ lat: -9, lon: 147.07, elevation: 186 },
	{ lat: -9, lon: 147.8, elevation: 1725 },
	{ lat: -9, lon: 160.98, elevation: 465 },
	{ lat: -8, lon: -78.7, elevation: 1631 },
	{ lat: -8, lon: -77.98, elevation: 4004 },
	{ lat: -8, lon: -77.25, elevation: 3666 },
	{ lat: -8, lon: -76.52, elevation: 1298 },
	{ lat: -8, lon: -75.79, elevation: 235 },
	{ lat: -8, lon: -75.06, elevation: 180 },
	{ lat: -8, lon: -74.33, elevation: 212 },
	{ lat: -8, lon: -73.6, elevation: 305 },
	{ lat: -8, lon: -72.87, elevation: 217 },
	{ lat: -8, lon: -72.15, elevation: 226 },
	{ lat: -8, lon: -71.42, elevation: 260 },
	{ lat: -8, lon: -70.69, elevation: 158 },
	{ lat: -8, lon: -69.96, elevation: 163 },
	{ lat: -8, lon: -69.23, elevation: 151 },
	{ lat: -8, lon: -68.5, elevation: 191 },
	{ lat: -8, lon: -67.77, elevation: 161 },
	{ lat: -8, lon: -67.04, elevation: 117 },
	{ lat: -8, lon: -66.32, elevation: 105 },
	{ lat: -8, lon: -65.59, elevation: 110 },
	{ lat: -8, lon: -64.86, elevation: 96 },
	{ lat: -8, lon: -64.13, elevation: 80 },
	{ lat: -8, lon: -63.4, elevation: 86 },
	{ lat: -8, lon: -62.67, elevation: 92 },
	{ lat: -8, lon: -61.94, elevation: 111 },
	{ lat: -8, lon: -61.21, elevation: 107 },
	{ lat: -8, lon: -60.49, elevation: 77 },
	{ lat: -8, lon: -59.76, elevation: 241 },
	{ lat: -8, lon: -59.03, elevation: 218 },
	{ lat: -8, lon: -58.3, elevation: 165 },
	{ lat: -8, lon: -57.57, elevation: 182 },
	{ lat: -8, lon: -56.84, elevation: 362 },
	{ lat: -8, lon: -56.11, elevation: 292 },
	{ lat: -8, lon: -55.38, elevation: 265 },
	{ lat: -8, lon: -54.66, elevation: 286 },
	{ lat: -8, lon: -53.93, elevation: 431 },
	{ lat: -8, lon: -53.2, elevation: 316 },
	{ lat: -8, lon: -52.47, elevation: 267 },
	{ lat: -8, lon: -51.74, elevation: 480 },
	{ lat: -8, lon: -51.01, elevation: 304 },
	{ lat: -8, lon: -50.28, elevation: 245 },
	{ lat: -8, lon: -49.55, elevation: 167 },
	{ lat: -8, lon: -48.83, elevation: 194 },
	{ lat: -8, lon: -48.1, elevation: 209 },
	{ lat: -8, lon: -47.37, elevation: 212 },
	{ lat: -8, lon: -46.64, elevation: 334 },
	{ lat: -8, lon: -45.91, elevation: 344 },
	{ lat: -8, lon: -45.18, elevation: 451 },
	{ lat: -8, lon: -44.45, elevation: 496 },
	{ lat: -8, lon: -43.72, elevation: 270 },
	{ lat: -8, lon: -43.0, elevation: 341 },
	{ lat: -8, lon: -42.27, elevation: 244 },
	{ lat: -8, lon: -41.54, elevation: 317 },
	{ lat: -8, lon: -40.81, elevation: 448 },
	{ lat: -8, lon: -40.08, elevation: 443 },
	{ lat: -8, lon: -39.35, elevation: 416 },
	{ lat: -8, lon: -38.62, elevation: 436 },
	{ lat: -8, lon: -37.89, elevation: 534 },
	{ lat: -8, lon: -37.17, elevation: 661 },
	{ lat: -8, lon: -36.44, elevation: 496 },
	{ lat: -8, lon: -35.71, elevation: 514 },
	{ lat: -8, lon: -34.98, elevation: 102 },
	{ lat: -8, lon: 13.85, elevation: 308 },
	{ lat: -8, lon: 14.57, elevation: 650 },
	{ lat: -8, lon: 15.3, elevation: 1196 },
	{ lat: -8, lon: 16.03, elevation: 1072 },
	{ lat: -8, lon: 16.76, elevation: 656 },
	{ lat: -8, lon: 17.49, elevation: 605 },
	{ lat: -8, lon: 18.22, elevation: 827 },
	{ lat: -8, lon: 18.95, elevation: 1112 },
	{ lat: -8, lon: 19.68, elevation: 1046 },
	{ lat: -8, lon: 20.4, elevation: 835 },
	{ lat: -8, lon: 21.13, elevation: 744 },
	{ lat: -8, lon: 21.86, elevation: 871 },
	{ lat: -8, lon: 22.59, elevation: 923 },
	{ lat: -8, lon: 23.32, elevation: 923 },
	{ lat: -8, lon: 24.05, elevation: 917 },
	{ lat: -8, lon: 24.78, elevation: 1088 },
	{ lat: -8, lon: 25.51, elevation: 769 },
	{ lat: -8, lon: 26.23, elevation: 764 },
	{ lat: -8, lon: 26.96, elevation: 562 },
	{ lat: -8, lon: 27.69, elevation: 1430 },
	{ lat: -8, lon: 28.42, elevation: 862 },
	{ lat: -8, lon: 29.15, elevation: 952 },
	{ lat: -8, lon: 29.88, elevation: 1455 },
	{ lat: -8, lon: 30.61, elevation: 767 },
	{ lat: -8, lon: 31.34, elevation: 1703 },
	{ lat: -8, lon: 32.06, elevation: 801 },
	{ lat: -8, lon: 32.79, elevation: 1158 },
	{ lat: -8, lon: 33.52, elevation: 1370 },
	{ lat: -8, lon: 34.25, elevation: 1354 },
	{ lat: -8, lon: 34.98, elevation: 1117 },
	{ lat: -8, lon: 35.71, elevation: 1856 },
	{ lat: -8, lon: 36.44, elevation: 473 },
	{ lat: -8, lon: 37.17, elevation: 339 },
	{ lat: -8, lon: 37.89, elevation: 192 },
	{ lat: -8, lon: 38.62, elevation: 36 },
	{ lat: -8, lon: 39.35, elevation: 8 },
	{ lat: -8, lon: 110.77, elevation: 513 },
	{ lat: -8, lon: 111.5, elevation: 167 },
	{ lat: -8, lon: 112.23, elevation: 445 },
	{ lat: -8, lon: 112.96, elevation: 2236 },
	{ lat: -8, lon: 113.68, elevation: 1691 },
	{ lat: -8, lon: 114.41, elevation: 17 },
	{ lat: -8, lon: 129.72, elevation: 305 },
	{ lat: -8, lon: 131.17, elevation: 0 },
	{ lat: -8, lon: 138.46, elevation: 6 },
	{ lat: -8, lon: 139.19, elevation: 11 },
	{ lat: -8, lon: 139.92, elevation: 5 },
	{ lat: -8, lon: 140.65, elevation: 32 },
	{ lat: -8, lon: 141.38, elevation: 41 },
	{ lat: -8, lon: 142.11, elevation: 19 },
	{ lat: -8, lon: 142.83, elevation: 12 },
	{ lat: -8, lon: 143.56, elevation: 0 },
	{ lat: -8, lon: 146.48, elevation: 60 },
	{ lat: -8, lon: 147.21, elevation: 967 },
	{ lat: -8, lon: 147.94, elevation: 13 },
	{ lat: -8, lon: 157.41, elevation: 27 },
	{ lat: -7, lon: -79.64, elevation: 34 },
	{ lat: -7, lon: -78.91, elevation: 3281 },
	{ lat: -7, lon: -78.18, elevation: 3146 },
	{ lat: -7, lon: -77.45, elevation: 2758 },
	{ lat: -7, lon: -76.73, elevation: 313 },
	{ lat: -7, lon: -76.0, elevation: 1083 },
	{ lat: -7, lon: -75.27, elevation: 167 },
	{ lat: -7, lon: -74.55, elevation: 227 },
	{ lat: -7, lon: -73.82, elevation: 256 },
	{ lat: -7, lon: -73.09, elevation: 255 },
	{ lat: -7, lon: -72.36, elevation: 228 },
	{ lat: -7, lon: -71.64, elevation: 183 },
	{ lat: -7, lon: -70.91, elevation: 175 },
	{ lat: -7, lon: -70.18, elevation: 192 },
	{ lat: -7, lon: -69.45, elevation: 166 },
	{ lat: -7, lon: -68.73, elevation: 159 },
	{ lat: -7, lon: -68.0, elevation: 155 },
	{ lat: -7, lon: -67.27, elevation: 142 },
	{ lat: -7, lon: -66.55, elevation: 119 },
	{ lat: -7, lon: -65.82, elevation: 106 },
	{ lat: -7, lon: -65.09, elevation: 82 },
	{ lat: -7, lon: -64.36, elevation: 88 },
	{ lat: -7, lon: -63.64, elevation: 85 },
	{ lat: -7, lon: -62.91, elevation: 66 },
	{ lat: -7, lon: -62.18, elevation: 72 },
	{ lat: -7, lon: -61.45, elevation: 74 },
	{ lat: -7, lon: -60.73, elevation: 67 },
	{ lat: -7, lon: -60.0, elevation: 141 },
	{ lat: -7, lon: -59.27, elevation: 153 },
	{ lat: -7, lon: -58.55, elevation: 174 },
	{ lat: -7, lon: -57.82, elevation: 239 },
	{ lat: -7, lon: -57.09, elevation: 270 },
	{ lat: -7, lon: -56.36, elevation: 261 },
	{ lat: -7, lon: -55.64, elevation: 230 },
	{ lat: -7, lon: -54.91, elevation: 257 },
	{ lat: -7, lon: -54.18, elevation: 243 },
	{ lat: -7, lon: -53.45, elevation: 309 },
	{ lat: -7, lon: -52.73, elevation: 359 },
	{ lat: -7, lon: -52.0, elevation: 237 },
	{ lat: -7, lon: -51.27, elevation: 263 },
	{ lat: -7, lon: -50.55, elevation: 339 },
	{ lat: -7, lon: -49.82, elevation: 220 },
	{ lat: -7, lon: -49.09, elevation: 187 },
	{ lat: -7, lon: -48.36, elevation: 223 },
	{ lat: -7, lon: -47.64, elevation: 217 },
	{ lat: -7, lon: -46.91, elevation: 302 },
	{ lat: -7, lon: -46.18, elevation: 508 },
	{ lat: -7, lon: -45.45, elevation: 282 },
	{ lat: -7, lon: -44.73, elevation: 298 },
	{ lat: -7, lon: -44.0, elevation: 262 },
	{ lat: -7, lon: -43.27, elevation: 220 },
	{ lat: -7, lon: -42.55, elevation: 185 },
	{ lat: -7, lon: -41.82, elevation: 480 },
	{ lat: -7, lon: -41.09, elevation: 285 },
	{ lat: -7, lon: -40.36, elevation: 558 },
	{ lat: -7, lon: -39.64, elevation: 409 },
	{ lat: -7, lon: -38.91, elevation: 321 },
	{ lat: -7, lon: -38.18, elevation: 314 },
	{ lat: -7, lon: -37.45, elevation: 301 },
	{ lat: -7, lon: -36.73, elevation: 600 },
	{ lat: -7, lon: -36.0, elevation: 508 },
	{ lat: -7, lon: -35.27, elevation: 136 },
	{ lat: -7, lon: 13.45, elevation: 352 },
	{ lat: -7, lon: 14.18, elevation: 472 },
	{ lat: -7, lon: 14.91, elevation: 749 },
	{ lat: -7, lon: 15.64, elevation: 892 },
	{ lat: -7, lon: 16.36, elevation: 711 },
	{ lat: -7, lon: 17.09, elevation: 644 },
	{ lat: -7, lon: 17.82, elevation: 947 },
	{ lat: -7, lon: 18.55, elevation: 1031 },
	{ lat: -7, lon: 19.27, elevation: 949 },
	{ lat: -7, lon: 20.0, elevation: 648 },
	{ lat: -7, lon: 20.73, elevation: 728 },
	{ lat: -7, lon: 21.45, elevation: 712 },
	{ lat: -7, lon: 22.18, elevation: 844 },
	{ lat: -7, lon: 22.91, elevation: 891 },
	{ lat: -7, lon: 23.64, elevation: 764 },
	{ lat: -7, lon: 24.36, elevation: 732 },
	{ lat: -7, lon: 25.09, elevation: 957 },
	{ lat: -7, lon: 25.82, elevation: 1008 },
	{ lat: -7, lon: 26.55, elevation: 712 },
	{ lat: -7, lon: 27.27, elevation: 764 },
	{ lat: -7, lon: 28.0, elevation: 652 },
	{ lat: -7, lon: 28.73, elevation: 1032 },
	{ lat: -7, lon: 29.45, elevation: 1347 },
	{ lat: -7, lon: 30.18, elevation: 767 },
	{ lat: -7, lon: 30.91, elevation: 1137 },
	{ lat: -7, lon: 31.64, elevation: 1104 },
	{ lat: -7, lon: 32.36, elevation: 1119 },
	{ lat: -7, lon: 33.09, elevation: 1199 },
	{ lat: -7, lon: 33.82, elevation: 1304 },
	{ lat: -7, lon: 34.55, elevation: 1138 },
	{ lat: -7, lon: 35.27, elevation: 958 },
	{ lat: -7, lon: 36.0, elevation: 795 },
	{ lat: -7, lon: 36.73, elevation: 1013 },
	{ lat: -7, lon: 37.45, elevation: 656 },
	{ lat: -7, lon: 38.18, elevation: 192 },
	{ lat: -7, lon: 38.91, elevation: 236 },
	{ lat: -7, lon: 52.73, elevation: 0 },
	{ lat: -7, lon: 106.55, elevation: 5 },
	{ lat: -7, lon: 107.27, elevation: 1028 },
	{ lat: -7, lon: 108.0, elevation: 780 },
	{ lat: -7, lon: 108.73, elevation: 182 },
	{ lat: -7, lon: 109.45, elevation: 53 },
	{ lat: -7, lon: 110.18, elevation: 15 },
	{ lat: -7, lon: 110.91, elevation: 70 },
	{ lat: -7, lon: 111.64, elevation: 234 },
	{ lat: -7, lon: 112.36, elevation: 7 },
	{ lat: -7, lon: 113.09, elevation: 93 },
	{ lat: -7, lon: 113.82, elevation: 116 },
	{ lat: -7, lon: 115.27, elevation: 0 },
	{ lat: -7, lon: 139.27, elevation: 9 },
	{ lat: -7, lon: 140.0, elevation: 44 },
	{ lat: -7, lon: 140.73, elevation: 45 },
	{ lat: -7, lon: 141.45, elevation: 12 },
	{ lat: -7, lon: 142.18, elevation: 57 },
	{ lat: -7, lon: 142.91, elevation: 76 },
	{ lat: -7, lon: 143.64, elevation: 294 },
	{ lat: -7, lon: 144.36, elevation: 68 },
	{ lat: -7, lon: 145.09, elevation: 101 },
	{ lat: -7, lon: 145.82, elevation: 1834 },
	{ lat: -7, lon: 146.55, elevation: 1864 },
	{ lat: -7, lon: 156.73, elevation: 0 },
	{ lat: -6, lon: -80.56, elevation: 4 },
	{ lat: -6, lon: -79.84, elevation: 570 },
	{ lat: -6, lon: -79.11, elevation: 1686 },
	{ lat: -6, lon: -78.39, elevation: 2071 },
	{ lat: -6, lon: -77.66, elevation: 3554 },
	{ lat: -6, lon: -76.94, elevation: 1239 },
	{ lat: -6, lon: -76.21, elevation: 191 },
	{ lat: -6, lon: -75.48, elevation: 182 },
	{ lat: -6, lon: -74.76, elevation: 130 },
	{ lat: -6, lon: -74.03, elevation: 143 },
	{ lat: -6, lon: -73.31, elevation: 173 },
	{ lat: -6, lon: -72.58, elevation: 128 },
	{ lat: -6, lon: -71.85, elevation: 182 },
	{ lat: -6, lon: -71.13, elevation: 157 },
	{ lat: -6, lon: -70.4, elevation: 175 },
	{ lat: -6, lon: -69.68, elevation: 128 },
	{ lat: -6, lon: -68.95, elevation: 154 },
	{ lat: -6, lon: -68.23, elevation: 152 },
	{ lat: -6, lon: -67.5, elevation: 133 },
	{ lat: -6, lon: -66.77, elevation: 121 },
	{ lat: -6, lon: -66.05, elevation: 112 },
	{ lat: -6, lon: -65.32, elevation: 97 },
	{ lat: -6, lon: -64.6, elevation: 63 },
	{ lat: -6, lon: -63.87, elevation: 71 },
	{ lat: -6, lon: -63.15, elevation: 76 },
	{ lat: -6, lon: -62.42, elevation: 67 },
	{ lat: -6, lon: -61.69, elevation: 48 },
	{ lat: -6, lon: -60.97, elevation: 60 },
	{ lat: -6, lon: -60.24, elevation: 64 },
	{ lat: -6, lon: -59.52, elevation: 66 },
	{ lat: -6, lon: -58.79, elevation: 122 },
	{ lat: -6, lon: -58.06, elevation: 122 },
	{ lat: -6, lon: -57.34, elevation: 154 },
	{ lat: -6, lon: -56.61, elevation: 83 },
	{ lat: -6, lon: -55.89, elevation: 223 },
	{ lat: -6, lon: -55.16, elevation: 264 },
	{ lat: -6, lon: -54.44, elevation: 227 },
	{ lat: -6, lon: -53.71, elevation: 251 },
	{ lat: -6, lon: -52.98, elevation: 194 },
	{ lat: -6, lon: -52.26, elevation: 210 },
	{ lat: -6, lon: -51.53, elevation: 355 },
	{ lat: -6, lon: -50.81, elevation: 252 },
	{ lat: -6, lon: -50.08, elevation: 441 },
	{ lat: -6, lon: -49.35, elevation: 137 },
	{ lat: -6, lon: -48.63, elevation: 189 },
	{ lat: -6, lon: -47.9, elevation: 202 },
	{ lat: -6, lon: -47.18, elevation: 173 },
	{ lat: -6, lon: -46.45, elevation: 322 },
	{ lat: -6, lon: -45.73, elevation: 280 },
	{ lat: -6, lon: -45.0, elevation: 293 },
	{ lat: -6, lon: -44.27, elevation: 257 },
	{ lat: -6, lon: -43.55, elevation: 257 },
	{ lat: -6, lon: -42.82, elevation: 288 },
	{ lat: -6, lon: -42.1, elevation: 138 },
	{ lat: -6, lon: -41.37, elevation: 287 },
	{ lat: -6, lon: -40.65, elevation: 536 },
	{ lat: -6, lon: -39.92, elevation: 509 },
	{ lat: -6, lon: -39.19, elevation: 300 },
	{ lat: -6, lon: -38.47, elevation: 544 },
	{ lat: -6, lon: -37.74, elevation: 163 },
	{ lat: -6, lon: -37.02, elevation: 70 },
	{ lat: -6, lon: -36.29, elevation: 350 },
	{ lat: -6, lon: -35.56, elevation: 102 },
	{ lat: -6, lon: 13.06, elevation: 54 },
	{ lat: -6, lon: 13.79, elevation: 321 },
	{ lat: -6, lon: 14.52, elevation: 488 },
	{ lat: -6, lon: 15.24, elevation: 818 },
	{ lat: -6, lon: 15.97, elevation: 610 },
	{ lat: -6, lon: 16.69, elevation: 545 },
	{ lat: -6, lon: 17.42, elevation: 808 },
	{ lat: -6, lon: 18.15, elevation: 719 },
	{ lat: -6, lon: 18.87, elevation: 828 },
	{ lat: -6, lon: 19.6, elevation: 781 },
	{ lat: -6, lon: 20.32, elevation: 713 },
	{ lat: -6, lon: 21.05, elevation: 595 },
	{ lat: -6, lon: 21.77, elevation: 616 },
	{ lat: -6, lon: 22.5, elevation: 676 },
	{ lat: -6, lon: 23.23, elevation: 569 },
	{ lat: -6, lon: 23.95, elevation: 680 },
	{ lat: -6, lon: 24.68, elevation: 715 },
	{ lat: -6, lon: 25.4, elevation: 752 },
	{ lat: -6, lon: 26.13, elevation: 754 },
	{ lat: -6, lon: 26.85, elevation: 561 },
	{ lat: -6, lon: 27.58, elevation: 629 },
	{ lat: -6, lon: 28.31, elevation: 839 },
	{ lat: -6, lon: 29.03, elevation: 1040 },
	{ lat: -6, lon: 29.76, elevation: 767 },
	{ lat: -6, lon: 30.48, elevation: 1871 },
	{ lat: -6, lon: 31.21, elevation: 1170 },
	{ lat: -6, lon: 31.94, elevation: 1120 },
	{ lat: -6, lon: 32.66, elevation: 1141 },
	{ lat: -6, lon: 33.39, elevation: 1288 },
	{ lat: -6, lon: 34.11, elevation: 1488 },
	{ lat: -6, lon: 34.84, elevation: 1234 },
	{ lat: -6, lon: 35.56, elevation: 1063 },
	{ lat: -6, lon: 36.29, elevation: 964 },
	{ lat: -6, lon: 37.02, elevation: 1181 },
	{ lat: -6, lon: 37.74, elevation: 448 },
	{ lat: -6, lon: 38.47, elevation: 254 },
	{ lat: -6, lon: 39.19, elevation: 37 },
	{ lat: -6, lon: 105.97, elevation: 0 },
	{ lat: -6, lon: 120.48, elevation: 105 },
	{ lat: -6, lon: 138.63, elevation: 29 },
	{ lat: -6, lon: 139.35, elevation: 42 },
	{ lat: -6, lon: 140.08, elevation: 51 },
	{ lat: -6, lon: 140.81, elevation: 58 },
	{ lat: -6, lon: 141.53, elevation: 69 },
	{ lat: -6, lon: 142.26, elevation: 211 },
	{ lat: -6, lon: 142.98, elevation: 1843 },
	{ lat: -6, lon: 143.71, elevation: 2201 },
	{ lat: -6, lon: 144.44, elevation: 3084 },
	{ lat: -6, lon: 145.16, elevation: 2668 },
	{ lat: -6, lon: 145.89, elevation: 399 },
	{ lat: -6, lon: 146.61, elevation: 2457 },
	{ lat: -6, lon: 147.34, elevation: 424 },
	{ lat: -6, lon: 149.52, elevation: 170 },
	{ lat: -6, lon: 150.24, elevation: 722 },
	{ lat: -6, lon: 150.97, elevation: 106 },
	{ lat: -6, lon: 155.32, elevation: 155 },
	{ lat: -5, lon: -80.76, elevation: 73 },
	{ lat: -5, lon: -80.04, elevation: 585 },
	{ lat: -5, lon: -79.32, elevation: 2013 },
	{ lat: -5, lon: -78.59, elevation: 1134 },
	{ lat: -5, lon: -77.87, elevation: 1040 },
	{ lat: -5, lon: -77.14, elevation: 155 },
	{ lat: -5, lon: -76.42, elevation: 138 },
	{ lat: -5, lon: -75.69, elevation: 126 },
	{ lat: -5, lon: -74.97, elevation: 114 },
	{ lat: -5, lon: -74.25, elevation: 104 },
	{ lat: -5, lon: -73.52, elevation: 150 },
	{ lat: -5, lon: -72.8, elevation: 153 },
	{ lat: -5, lon: -72.07, elevation: 129 },
	{ lat: -5, lon: -71.35, elevation: 155 },
	{ lat: -5, lon: -70.62, elevation: 120 },
	{ lat: -5, lon: -69.9, elevation: 134 },
	{ lat: -5, lon: -69.18, elevation: 144 },
	{ lat: -5, lon: -68.45, elevation: 149 },
	{ lat: -5, lon: -67.73, elevation: 126 },
	{ lat: -5, lon: -67.0, elevation: 102 },
	{ lat: -5, lon: -66.28, elevation: 90 },
	{ lat: -5, lon: -65.55, elevation: 95 },
	{ lat: -5, lon: -64.83, elevation: 61 },
	{ lat: -5, lon: -64.1, elevation: 53 },
	{ lat: -5, lon: -63.38, elevation: 66 },
	{ lat: -5, lon: -62.66, elevation: 20 },
	{ lat: -5, lon: -61.93, elevation: 61 },
	{ lat: -5, lon: -61.21, elevation: 54 },
	{ lat: -5, lon: -60.48, elevation: 44 },
	{ lat: -5, lon: -59.76, elevation: 51 },
	{ lat: -5, lon: -59.03, elevation: 109 },
	{ lat: -5, lon: -58.31, elevation: 84 },
	{ lat: -5, lon: -57.59, elevation: 236 },
	{ lat: -5, lon: -56.86, elevation: 232 },
	{ lat: -5, lon: -56.14, elevation: 148 },
	{ lat: -5, lon: -55.41, elevation: 200 },
	{ lat: -5, lon: -54.69, elevation: 214 },
	{ lat: -5, lon: -53.96, elevation: 174 },
	{ lat: -5, lon: -53.24, elevation: 213 },
	{ lat: -5, lon: -52.52, elevation: 215 },
	{ lat: -5, lon: -51.79, elevation: 202 },
	{ lat: -5, lon: -51.07, elevation: 230 },
	{ lat: -5, lon: -50.34, elevation: 187 },
	{ lat: -5, lon: -49.62, elevation: 170 },
	{ lat: -5, lon: -48.89, elevation: 158 },
	{ lat: -5, lon: -48.17, elevation: 268 },
	{ lat: -5, lon: -47.44, elevation: 280 },
	{ lat: -5, lon: -46.72, elevation: 286 },
	{ lat: -5, lon: -46.0, elevation: 217 },
	{ lat: -5, lon: -45.27, elevation: 273 },
	{ lat: -5, lon: -44.55, elevation: 76 },
	{ lat: -5, lon: -43.82, elevation: 142 },
	{ lat: -5, lon: -43.1, elevation: 170 },
	{ lat: -5, lon: -42.37, elevation: 154 },
	{ lat: -5, lon: -41.65, elevation: 255 },
	{ lat: -5, lon: -40.93, elevation: 442 },
	{ lat: -5, lon: -40.2, elevation: 497 },
	{ lat: -5, lon: -39.48, elevation: 316 },
	{ lat: -5, lon: -38.75, elevation: 163 },
	{ lat: -5, lon: -38.03, elevation: 22 },
	{ lat: -5, lon: -37.3, elevation: 28 },
	{ lat: -5, lon: 12.68, elevation: 143 },
	{ lat: -5, lon: 13.4, elevation: 558 },
	{ lat: -5, lon: 14.12, elevation: 173 },
	{ lat: -5, lon: 14.85, elevation: 570 },
	{ lat: -5, lon: 15.57, elevation: 537 },
	{ lat: -5, lon: 16.3, elevation: 669 },
	{ lat: -5, lon: 17.02, elevation: 432 },
	{ lat: -5, lon: 17.75, elevation: 416 },
	{ lat: -5, lon: 18.47, elevation: 484 },
	{ lat: -5, lon: 19.2, elevation: 702 },
	{ lat: -5, lon: 19.92, elevation: 486 },
	{ lat: -5, lon: 20.64, elevation: 542 },
	{ lat: -5, lon: 21.37, elevation: 537 },
	{ lat: -5, lon: 22.09, elevation: 613 },
	{ lat: -5, lon: 22.82, elevation: 563 },
	{ lat: -5, lon: 23.54, elevation: 522 },
	{ lat: -5, lon: 24.27, elevation: 604 },
	{ lat: -5, lon: 24.99, elevation: 575 },
	{ lat: -5, lon: 25.71, elevation: 560 },
	{ lat: -5, lon: 26.44, elevation: 559 },
	{ lat: -5, lon: 27.16, elevation: 568 },
	{ lat: -5, lon: 27.89, elevation: 802 },
	{ lat: -5, lon: 28.61, elevation: 742 },
	{ lat: -5, lon: 29.34, elevation: 767 },
	{ lat: -5, lon: 30.06, elevation: 1106 },
	{ lat: -5, lon: 30.78, elevation: 1103 },
	{ lat: -5, lon: 31.51, elevation: 1083 },
	{ lat: -5, lon: 32.23, elevation: 1125 },
	{ lat: -5, lon: 32.96, elevation: 1262 },
	{ lat: -5, lon: 33.68, elevation: 1212 },
	{ lat: -5, lon: 34.41, elevation: 1235 },
	{ lat: -5, lon: 35.13, elevation: 1367 },
	{ lat: -5, lon: 35.86, elevation: 1385 },
	{ lat: -5, lon: 36.58, elevation: 1241 },
	{ lat: -5, lon: 37.3, elevation: 975 },
	{ lat: -5, lon: 38.03, elevation: 553 },
	{ lat: -5, lon: 38.75, elevation: 160 },
	{ lat: -5, lon: 103.94, elevation: 1267 },
	{ lat: -5, lon: 104.67, elevation: 421 },
	{ lat: -5, lon: 105.39, elevation: 32 },
	{ lat: -5, lon: 119.88, elevation: 987 },
	{ lat: -5, lon: 122.78, elevation: 0 },
	{ lat: -5, lon: 137.26, elevation: 22 },
	{ lat: -5, lon: 137.99, elevation: 21 },
	{ lat: -5, lon: 138.71, elevation: 31 },
	{ lat: -5, lon: 139.44, elevation: 47 },
	{ lat: -5, lon: 140.16, elevation: 307 },
	{ lat: -5, lon: 140.89, elevation: 1368 },
	{ lat: -5, lon: 141.61, elevation: 1382 },
	{ lat: -5, lon: 142.33, elevation: 1725 },
	{ lat: -5, lon: 143.06, elevation: 1500 },
	{ lat: -5, lon: 143.78, elevation: 406 },
	{ lat: -5, lon: 144.51, elevation: 236 },
	{ lat: -5, lon: 145.23, elevation: 127 },
	{ lat: -5, lon: 151.75, elevation: 123 },
	{ lat: -4, lon: -80.76, elevation: 196 },
	{ lat: -4, lon: -80.04, elevation: 927 },
	{ lat: -4, lon: -79.32, elevation: 1881 },
	{ lat: -4, lon: -78.59, elevation: 1569 },
	{ lat: -4, lon: -77.87, elevation: 224 },
	{ lat: -4, lon: -77.14, elevation: 178 },
	{ lat: -4, lon: -76.42, elevation: 169 },
	{ lat: -4, lon: -75.69, elevation: 153 },
	{ lat: -4, lon: -74.97, elevation: 138 },
	{ lat: -4, lon: -74.25, elevation: 160 },
	{ lat: -4, lon: -73.52, elevation: 145 },
	{ lat: -4, lon: -72.8, elevation: 133 },
	{ lat: -4, lon: -72.07, elevation: 137 },
	{ lat: -4, lon: -71.35, elevation: 103 },
	{ lat: -4, lon: -70.62, elevation: 118 },
	{ lat: -4, lon: -69.9, elevation: 123 },
	{ lat: -4, lon: -69.18, elevation: 136 },
	{ lat: -4, lon: -68.45, elevation: 125 },
	{ lat: -4, lon: -67.73, elevation: 84 },
	{ lat: -4, lon: -67.0, elevation: 99 },
	{ lat: -4, lon: -66.28, elevation: 78 },
	{ lat: -4, lon: -65.55, elevation: 92 },
	{ lat: -4, lon: -64.83, elevation: 78 },
	{ lat: -4, lon: -64.1, elevation: 74 },
	{ lat: -4, lon: -63.38, elevation: 56 },
	{ lat: -4, lon: -62.66, elevation: 57 },
	{ lat: -4, lon: -61.93, elevation: 27 },
	{ lat: -4, lon: -61.21, elevation: 47 },
	{ lat: -4, lon: -60.48, elevation: 42 },
	{ lat: -4, lon: -59.76, elevation: 39 },
	{ lat: -4, lon: -59.03, elevation: 25 },
	{ lat: -4, lon: -58.31, elevation: 48 },
	{ lat: -4, lon: -57.59, elevation: 55 },
	{ lat: -4, lon: -56.86, elevation: 109 },
	{ lat: -4, lon: -56.14, elevation: 111 },
	{ lat: -4, lon: -55.41, elevation: 105 },
	{ lat: -4, lon: -54.69, elevation: 136 },
	{ lat: -4, lon: -53.96, elevation: 388 },
	{ lat: -4, lon: -53.24, elevation: 137 },
	{ lat: -4, lon: -52.52, elevation: 174 },
	{ lat: -4, lon: -51.79, elevation: 141 },
	{ lat: -4, lon: -51.07, elevation: 136 },
	{ lat: -4, lon: -50.34, elevation: 101 },
	{ lat: -4, lon: -49.62, elevation: 70 },
	{ lat: -4, lon: -48.89, elevation: 123 },
	{ lat: -4, lon: -48.17, elevation: 176 },
	{ lat: -4, lon: -47.44, elevation: 174 },
	{ lat: -4, lon: -46.72, elevation: 290 },
	{ lat: -4, lon: -46.0, elevation: 204 },
	{ lat: -4, lon: -45.27, elevation: 42 },
	{ lat: -4, lon: -44.55, elevation: 20 },
	{ lat: -4, lon: -43.82, elevation: 102 },
	{ lat: -4, lon: -43.1, elevation: 119 },
	{ lat: -4, lon: -42.37, elevation: 96 },
	{ lat: -4, lon: -41.65, elevation: 71 },
	{ lat: -4, lon: -40.93, elevation: 875 },
	{ lat: -4, lon: -40.2, elevation: 154 },
	{ lat: -4, lon: -39.48, elevation: 140 },
	{ lat: -4, lon: -38.75, elevation: 139 },
	{ lat: -4, lon: 11.23, elevation: 21 },
	{ lat: -4, lon: 11.95, elevation: 187 },
	{ lat: -4, lon: 12.68, elevation: 284 },
	{ lat: -4, lon: 13.4, elevation: 195 },
	{ lat: -4, lon: 14.12, elevation: 289 },
	{ lat: -4, lon: 14.85, elevation: 509 },
	{ lat: -4, lon: 15.57, elevation: 303 },
	{ lat: -4, lon: 16.3, elevation: 593 },
	{ lat: -4, lon: 17.02, elevation: 396 },
	{ lat: -4, lon: 17.75, elevation: 401 },
	{ lat: -4, lon: 18.47, elevation: 399 },
	{ lat: -4, lon: 19.2, elevation: 386 },
	{ lat: -4, lon: 19.92, elevation: 443 },
	{ lat: -4, lon: 20.64, elevation: 455 },
	{ lat: -4, lon: 21.37, elevation: 414 },
	{ lat: -4, lon: 22.09, elevation: 382 },
	{ lat: -4, lon: 22.82, elevation: 517 },
	{ lat: -4, lon: 23.54, elevation: 525 },
	{ lat: -4, lon: 24.27, elevation: 548 },
	{ lat: -4, lon: 24.99, elevation: 512 },
	{ lat: -4, lon: 25.71, elevation: 534 },
	{ lat: -4, lon: 26.44, elevation: 654 },
	{ lat: -4, lon: 27.16, elevation: 685 },
	{ lat: -4, lon: 27.89, elevation: 1407 },
	{ lat: -4, lon: 28.61, elevation: 1525 },
	{ lat: -4, lon: 29.34, elevation: 767 },
	{ lat: -4, lon: 30.06, elevation: 1268 },
	{ lat: -4, lon: 30.78, elevation: 1182 },
	{ lat: -4, lon: 31.51, elevation: 1086 },
	{ lat: -4, lon: 32.23, elevation: 1215 },
	{ lat: -4, lon: 32.96, elevation: 1131 },
	{ lat: -4, lon: 33.68, elevation: 1100 },
	{ lat: -4, lon: 34.41, elevation: 1045 },
	{ lat: -4, lon: 35.13, elevation: 1397 },
	{ lat: -4, lon: 35.86, elevation: 1400 },
	{ lat: -4, lon: 36.58, elevation: 1456 },
	{ lat: -4, lon: 37.3, elevation: 966 },
	{ lat: -4, lon: 38.03, elevation: 804 },
	{ lat: -4, lon: 38.75, elevation: 521 },
	{ lat: -4, lon: 39.48, elevation: 146 },
	{ lat: -4, lon: 102.49, elevation: 32 },
	{ lat: -4, lon: 103.22, elevation: 798 },
	{ lat: -4, lon: 103.94, elevation: 167 },
	{ lat: -4, lon: 104.67, elevation: 48 },
	{ lat: -4, lon: 105.39, elevation: 21 },
	{ lat: -4, lon: 114.81, elevation: 19 },
	{ lat: -4, lon: 119.88, elevation: 8 },
	{ lat: -4, lon: 122.05, elevation: 65 },
	{ lat: -4, lon: 132.92, elevation: 846 },
	{ lat: -4, lon: 135.09, elevation: 39 },
	{ lat: -4, lon: 135.81, elevation: 1682 },
	{ lat: -4, lon: 136.54, elevation: 2497 },
	{ lat: -4, lon: 137.26, elevation: 3466 },
	{ lat: -4, lon: 137.99, elevation: 2822 },
	{ lat: -4, lon: 138.71, elevation: 2688 },
	{ lat: -4, lon: 139.44, elevation: 1250 },
	{ lat: -4, lon: 140.16, elevation: 641 },
	{ lat: -4, lon: 140.89, elevation: 208 },
	{ lat: -4, lon: 141.61, elevation: 50 },
	{ lat: -4, lon: 142.33, elevation: 74 },
	{ lat: -4, lon: 143.06, elevation: 41 },
	{ lat: -4, lon: 143.78, elevation: 52 },
	{ lat: -4, lon: 144.51, elevation: 15 },
	{ lat: -3, lon: -80.24, elevation: 0 },
	{ lat: -3, lon: -79.52, elevation: 1688 },
	{ lat: -3, lon: -78.8, elevation: 2600 },
	{ lat: -3, lon: -78.07, elevation: 549 },
	{ lat: -3, lon: -77.35, elevation: 226 },
	{ lat: -3, lon: -76.63, elevation: 227 },
	{ lat: -3, lon: -75.9, elevation: 203 },
	{ lat: -3, lon: -75.18, elevation: 165 },
	{ lat: -3, lon: -74.46, elevation: 193 },
	{ lat: -3, lon: -73.73, elevation: 120 },
	{ lat: -3, lon: -73.01, elevation: 144 },
	{ lat: -3, lon: -72.29, elevation: 130 },
	{ lat: -3, lon: -71.57, elevation: 129 },
	{ lat: -3, lon: -70.84, elevation: 115 },
	{ lat: -3, lon: -70.12, elevation: 116 },
	{ lat: -3, lon: -69.4, elevation: 106 },
	{ lat: -3, lon: -68.67, elevation: 68 },
	{ lat: -3, lon: -67.95, elevation: 104 },
	{ lat: -3, lon: -67.23, elevation: 58 },
	{ lat: -3, lon: -66.51, elevation: 86 },
	{ lat: -3, lon: -65.78, elevation: 80 },
	{ lat: -3, lon: -65.06, elevation: 31 },
	{ lat: -3, lon: -64.34, elevation: 48 },
	{ lat: -3, lon: -63.61, elevation: 58 },
	{ lat: -3, lon: -62.89, elevation: 60 },
	{ lat: -3, lon: -62.17, elevation: 58 },
	{ lat: -3, lon: -61.45, elevation: 46 },
	{ lat: -3, lon: -60.72, elevation: 81 },
	{ lat: -3, lon: -60.0, elevation: 76 },
	{ lat: -3, lon: -59.28, elevation: 47 },
	{ lat: -3, lon: -58.55, elevation: 34 },
	{ lat: -3, lon: -57.83, elevation: 10 },
	{ lat: -3, lon: -57.11, elevation: 44 },
	{ lat: -3, lon: -56.39, elevation: 88 },
	{ lat: -3, lon: -55.66, elevation: 107 },
	{ lat: -3, lon: -54.94, elevation: 165 },
	{ lat: -3, lon: -54.22, elevation: 191 },
	{ lat: -3, lon: -53.49, elevation: 215 },
	{ lat: -3, lon: -52.77, elevation: 140 },
	{ lat: -3, lon: -52.05, elevation: 86 },
	{ lat: -3, lon: -51.33, elevation: 92 },
	{ lat: -3, lon: -50.6, elevation: 78 },
	{ lat: -3, lon: -49.88, elevation: 16 },
	{ lat: -3, lon: -49.16, elevation: 65 },
	{ lat: -3, lon: -48.43, elevation: 82 },
	{ lat: -3, lon: -47.71, elevation: 44 },
	{ lat: -3, lon: -46.99, elevation: 73 },
	{ lat: -3, lon: -46.27, elevation: 111 },
	{ lat: -3, lon: -45.54, elevation: 60 },
	{ lat: -3, lon: -44.82, elevation: 10 },
	{ lat: -3, lon: -44.1, elevation: 39 },
	{ lat: -3, lon: -43.37, elevation: 91 },
	{ lat: -3, lon: -42.65, elevation: 69 },
	{ lat: -3, lon: -41.93, elevation: 43 },
	{ lat: -3, lon: -41.2, elevation: 27 },
	{ lat: -3, lon: -40.48, elevation: 42 },
	{ lat: -3, lon: -39.76, elevation: 23 },
	{ lat: -3, lon: 10.84, elevation: 88 },
	{ lat: -3, lon: 11.57, elevation: 190 },
	{ lat: -3, lon: 12.29, elevation: 173 },
	{ lat: -3, lon: 13.01, elevation: 464 },
	{ lat: -3, lon: 13.73, elevation: 612 },
	{ lat: -3, lon: 14.46, elevation: 564 },
	{ lat: -3, lon: 15.18, elevation: 339 },
	{ lat: -3, lon: 15.9, elevation: 601 },
	{ lat: -3, lon: 16.63, elevation: 303 },
	{ lat: -3, lon: 17.35, elevation: 331 },
	{ lat: -3, lon: 18.07, elevation: 351 },
	{ lat: -3, lon: 18.8, elevation: 356 },
	{ lat: -3, lon: 19.52, elevation: 407 },
	{ lat: -3, lon: 20.24, elevation: 474 },
	{ lat: -3, lon: 20.96, elevation: 411 },
	{ lat: -3, lon: 21.69, elevation: 598 },
	{ lat: -3, lon: 22.41, elevation: 482 },
	{ lat: -3, lon: 23.13, elevation: 575 },
	{ lat: -3, lon: 23.86, elevation: 493 },
	{ lat: -3, lon: 24.58, elevation: 505 },
	{ lat: -3, lon: 25.3, elevation: 496 },
	{ lat: -3, lon: 26.02, elevation: 494 },
	{ lat: -3, lon: 26.75, elevation: 554 },
	{ lat: -3, lon: 27.47, elevation: 692 },
	{ lat: -3, lon: 28.19, elevation: 1263 },
	{ lat: -3, lon: 28.92, elevation: 2116 },
	{ lat: -3, lon: 29.64, elevation: 1785 },
	{ lat: -3, lon: 30.36, elevation: 1456 },
	{ lat: -3, lon: 31.08, elevation: 1392 },
	{ lat: -3, lon: 31.81, elevation: 1203 },
	{ lat: -3, lon: 32.53, elevation: 1191 },
	{ lat: -3, lon: 33.25, elevation: 1207 },
	{ lat: -3, lon: 33.98, elevation: 1328 },
	{ lat: -3, lon: 34.7, elevation: 1614 },
	{ lat: -3, lon: 35.42, elevation: 1362 },
	{ lat: -3, lon: 36.14, elevation: 817 },
	{ lat: -3, lon: 36.87, elevation: 1197 },
	{ lat: -3, lon: 37.59, elevation: 1544 },
	{ lat: -3, lon: 38.31, elevation: 630 },
	{ lat: -3, lon: 39.04, elevation: 241 },
	{ lat: -3, lon: 39.76, elevation: 185 },
	{ lat: -3, lon: 101.93, elevation: 337 },
	{ lat: -3, lon: 102.65, elevation: 171 },
	{ lat: -3, lon: 103.37, elevation: 49 },
	{ lat: -3, lon: 104.1, elevation: 16 },
	{ lat: -3, lon: 104.82, elevation: 12 },
	{ lat: -3, lon: 105.54, elevation: 7 },
	{ lat: -3, lon: 107.71, elevation: 214 },
	{ lat: -3, lon: 112.05, elevation: 29 },
	{ lat: -3, lon: 112.77, elevation: 11 },
	{ lat: -3, lon: 113.49, elevation: 10 },
	{ lat: -3, lon: 114.22, elevation: 8 },
	{ lat: -3, lon: 114.94, elevation: 7 },
	{ lat: -3, lon: 115.66, elevation: 600 },
	{ lat: -3, lon: 119.28, elevation: 1252 },
	{ lat: -3, lon: 120.0, elevation: 1022 },
	{ lat: -3, lon: 121.45, elevation: 912 },
	{ lat: -3, lon: 122.17, elevation: 408 },
	{ lat: -3, lon: 128.67, elevation: 545 },
	{ lat: -3, lon: 129.4, elevation: 157 },
	{ lat: -3, lon: 130.12, elevation: 20 },
	{ lat: -3, lon: 132.29, elevation: 0 },
	{ lat: -3, lon: 133.01, elevation: 69 },
	{ lat: -3, lon: 133.73, elevation: 31 },
	{ lat: -3, lon: 134.46, elevation: 392 },
	{ lat: -3, lon: 135.9, elevation: 16 },
	{ lat: -3, lon: 136.63, elevation: 528 },
	{ lat: -3, lon: 137.35, elevation: 107 },
	{ lat: -3, lon: 138.07, elevation: 58 },
	{ lat: -3, lon: 138.8, elevation: 80 },
	{ lat: -3, lon: 139.52, elevation: 181 },
	{ lat: -3, lon: 140.24, elevation: 520 },
	{ lat: -3, lon: 140.96, elevation: 116 },
	{ lat: -3, lon: 141.69, elevation: 224 },
	{ lat: -2, lon: -80.24, elevation: 135 },
	{ lat: -2, lon: -79.52, elevation: 23 },
	{ lat: -2, lon: -78.8, elevation: 3805 },
	{ lat: -2, lon: -78.07, elevation: 1272 },
	{ lat: -2, lon: -77.35, elevation: 468 },
	{ lat: -2, lon: -76.63, elevation: 234 },
	{ lat: -2, lon: -75.9, elevation: 243 },
	{ lat: -2, lon: -75.18, elevation: 186 },
	{ lat: -2, lon: -74.46, elevation: 131 },
	{ lat: -2, lon: -73.73, elevation: 172 },
	{ lat: -2, lon: -73.01, elevation: 175 },
	{ lat: -2, lon: -72.29, elevation: 112 },
	{ lat: -2, lon: -71.57, elevation: 126 },
	{ lat: -2, lon: -70.84, elevation: 124 },
	{ lat: -2, lon: -70.12, elevation: 121 },
	{ lat: -2, lon: -69.4, elevation: 127 },
	{ lat: -2, lon: -68.67, elevation: 118 },
	{ lat: -2, lon: -67.95, elevation: 97 },
	{ lat: -2, lon: -67.23, elevation: 58 },
	{ lat: -2, lon: -66.51, elevation: 71 },
	{ lat: -2, lon: -65.78, elevation: 50 },
	{ lat: -2, lon: -65.06, elevation: 79 },
	{ lat: -2, lon: -64.34, elevation: 72 },
	{ lat: -2, lon: -63.61, elevation: 64 },
	{ lat: -2, lon: -62.89, elevation: 60 },
	{ lat: -2, lon: -62.17, elevation: 41 },
	{ lat: -2, lon: -61.45, elevation: 49 },
	{ lat: -2, lon: -60.72, elevation: 67 },
	{ lat: -2, lon: -60.0, elevation: 142 },
	{ lat: -2, lon: -59.28, elevation: 87 },
	{ lat: -2, lon: -58.55, elevation: 123 },
	{ lat: -2, lon: -57.83, elevation: 131 },
	{ lat: -2, lon: -57.11, elevation: 31 },
	{ lat: -2, lon: -56.39, elevation: 9 },
	{ lat: -2, lon: -55.66, elevation: 11 },
	{ lat: -2, lon: -54.94, elevation: 7 },
	{ lat: -2, lon: -54.22, elevation: 64 },
	{ lat: -2, lon: -53.49, elevation: 4 },
	{ lat: -2, lon: -52.77, elevation: 55 },
	{ lat: -2, lon: -52.05, elevation: 47 },
	{ lat: -2, lon: -51.33, elevation: 32 },
	{ lat: -2, lon: -50.6, elevation: 25 },
	{ lat: -2, lon: -49.88, elevation: 22 },
	{ lat: -2, lon: -49.16, elevation: 18 },
	{ lat: -2, lon: -48.43, elevation: 35 },
	{ lat: -2, lon: -47.71, elevation: 47 },
	{ lat: -2, lon: -46.99, elevation: 64 },
	{ lat: -2, lon: -46.27, elevation: 17 },
	{ lat: -2, lon: -45.54, elevation: 52 },
	{ lat: -2, lon: -44.82, elevation: 38 },
	{ lat: -2, lon: 9.4, elevation: 21 },
	{ lat: -2, lon: 10.12, elevation: 64 },
	{ lat: -2, lon: 10.84, elevation: 293 },
	{ lat: -2, lon: 11.57, elevation: 594 },
	{ lat: -2, lon: 12.29, elevation: 795 },
	{ lat: -2, lon: 13.01, elevation: 680 },
	{ lat: -2, lon: 13.73, elevation: 468 },
	{ lat: -2, lon: 14.46, elevation: 694 },
	{ lat: -2, lon: 15.18, elevation: 532 },
	{ lat: -2, lon: 15.9, elevation: 407 },
	{ lat: -2, lon: 16.63, elevation: 291 },
	{ lat: -2, lon: 17.35, elevation: 336 },
	{ lat: -2, lon: 18.07, elevation: 323 },
	{ lat: -2, lon: 18.8, elevation: 309 },
	{ lat: -2, lon: 19.52, elevation: 333 },
	{ lat: -2, lon: 20.24, elevation: 382 },
	{ lat: -2, lon: 20.96, elevation: 372 },
	{ lat: -2, lon: 21.69, elevation: 430 },
	{ lat: -2, lon: 22.41, elevation: 435 },
	{ lat: -2, lon: 23.13, elevation: 444 },
	{ lat: -2, lon: 23.86, elevation: 471 },
	{ lat: -2, lon: 24.58, elevation: 613 },
	{ lat: -2, lon: 25.3, elevation: 466 },
	{ lat: -2, lon: 26.02, elevation: 529 },
	{ lat: -2, lon: 26.75, elevation: 754 },
	{ lat: -2, lon: 27.47, elevation: 1217 },
	{ lat: -2, lon: 28.19, elevation: 1020 },
	{ lat: -2, lon: 28.92, elevation: 1461 },
	{ lat: -2, lon: 29.64, elevation: 1552 },
	{ lat: -2, lon: 30.36, elevation: 1529 },
	{ lat: -2, lon: 31.08, elevation: 1606 },
	{ lat: -2, lon: 31.81, elevation: 1134 },
	{ lat: -2, lon: 32.53, elevation: 1134 },
	{ lat: -2, lon: 33.25, elevation: 1134 },
	{ lat: -2, lon: 33.98, elevation: 1274 },
	{ lat: -2, lon: 34.7, elevation: 1388 },
	{ lat: -2, lon: 35.42, elevation: 1958 },
	{ lat: -2, lon: 36.14, elevation: 624 },
	{ lat: -2, lon: 36.87, elevation: 1586 },
	{ lat: -2, lon: 37.59, elevation: 1039 },
	{ lat: -2, lon: 38.31, elevation: 670 },
	{ lat: -2, lon: 39.04, elevation: 268 },
	{ lat: -2, lon: 39.76, elevation: 97 },
	{ lat: -2, lon: 40.48, elevation: 53 },
	{ lat: -2, lon: 101.2, elevation: 1169 },
	{ lat: -2, lon: 101.93, elevation: 581 },
	{ lat: -2, lon: 102.65, elevation: 42 },
	{ lat: -2, lon: 103.37, elevation: 30 },
	{ lat: -2, lon: 104.1, elevation: 30 },
	{ lat: -2, lon: 105.54, elevation: 23 },
	{ lat: -2, lon: 110.6, elevation: 252 },
	{ lat: -2, lon: 111.33, elevation: 69 },
	{ lat: -2, lon: 112.05, elevation: 41 },
	{ lat: -2, lon: 112.77, elevation: 22 },
	{ lat: -2, lon: 113.49, elevation: 34 },
	{ lat: -2, lon: 114.22, elevation: 40 },
	{ lat: -2, lon: 114.94, elevation: 12 },
	{ lat: -2, lon: 115.66, elevation: 412 },
	{ lat: -2, lon: 116.39, elevation: 36 },
	{ lat: -2, lon: 119.28, elevation: 4 },
	{ lat: -2, lon: 120.0, elevation: 1779 },
	{ lat: -2, lon: 120.72, elevation: 713 },
	{ lat: -2, lon: 121.45, elevation: 151 },
	{ lat: -2, lon: 124.34, elevation: 199 },
	{ lat: -2, lon: 130.12, elevation: 150 },
	{ lat: -2, lon: 132.29, elevation: 20 },
	{ lat: -2, lon: 133.01, elevation: 32 },
	{ lat: -2, lon: 133.73, elevation: 63 },
	{ lat: -2, lon: 137.35, elevation: 19 },
	{ lat: -2, lon: 138.07, elevation: 100 },
	{ lat: -2, lon: 138.8, elevation: 22 },
	{ lat: -2, lon: 146.75, elevation: 88 },
	{ lat: -1, lon: -91.08, elevation: 12 },
	{ lat: -1, lon: -80.24, elevation: 440 },
	{ lat: -1, lon: -79.52, elevation: 70 },
	{ lat: -1, lon: -78.8, elevation: 4115 },
	{ lat: -1, lon: -78.07, elevation: 2036 },
	{ lat: -1, lon: -77.35, elevation: 406 },
	{ lat: -1, lon: -76.63, elevation: 299 },
	{ lat: -1, lon: -75.9, elevation: 228 },
	{ lat: -1, lon: -75.18, elevation: 190 },
	{ lat: -1, lon: -74.46, elevation: 178 },
	{ lat: -1, lon: -73.73, elevation: 168 },
	{ lat: -1, lon: -73.01, elevation: 160 },
	{ lat: -1, lon: -72.29, elevation: 179 },
	{ lat: -1, lon: -71.57, elevation: 133 },
	{ lat: -1, lon: -70.84, elevation: 141 },
	{ lat: -1, lon: -70.12, elevation: 98 },
	{ lat: -1, lon: -69.4, elevation: 84 },
	{ lat: -1, lon: -68.67, elevation: 129 },
	{ lat: -1, lon: -67.95, elevation: 69 },
	{ lat: -1, lon: -67.23, elevation: 76 },
	{ lat: -1, lon: -66.51, elevation: 81 },
	{ lat: -1, lon: -65.78, elevation: 74 },
	{ lat: -1, lon: -65.06, elevation: 71 },
	{ lat: -1, lon: -64.34, elevation: 41 },
	{ lat: -1, lon: -63.61, elevation: 55 },
	{ lat: -1, lon: -62.89, elevation: 37 },
	{ lat: -1, lon: -62.17, elevation: 12 },
	{ lat: -1, lon: -61.45, elevation: 53 },
	{ lat: -1, lon: -60.72, elevation: 93 },
	{ lat: -1, lon: -60.0, elevation: 181 },
	{ lat: -1, lon: -59.28, elevation: 150 },
	{ lat: -1, lon: -58.55, elevation: 260 },
	{ lat: -1, lon: -57.83, elevation: 271 },
	{ lat: -1, lon: -57.11, elevation: 37 },
	{ lat: -1, lon: -56.39, elevation: 223 },
	{ lat: -1, lon: -55.66, elevation: 370 },
	{ lat: -1, lon: -54.94, elevation: 320 },
	{ lat: -1, lon: -54.22, elevation: 299 },
	{ lat: -1, lon: -53.49, elevation: 242 },
	{ lat: -1, lon: -52.77, elevation: 48 },
	{ lat: -1, lon: -52.05, elevation: 69 },
	{ lat: -1, lon: -51.33, elevation: 0 },
	{ lat: -1, lon: -50.6, elevation: 28 },
	{ lat: -1, lon: -49.88, elevation: 22 },
	{ lat: -1, lon: -49.16, elevation: 6 },
	{ lat: -1, lon: -47.71, elevation: 34 },
	{ lat: -1, lon: -46.99, elevation: 5 },
	{ lat: -1, lon: -46.27, elevation: 10 },
	{ lat: -1, lon: 9.4, elevation: 5 },
	{ lat: -1, lon: 10.12, elevation: 16 },
	{ lat: -1, lon: 10.84, elevation: 534 },
	{ lat: -1, lon: 11.57, elevation: 598 },
	{ lat: -1, lon: 12.29, elevation: 397 },
	{ lat: -1, lon: 13.01, elevation: 412 },
	{ lat: -1, lon: 13.73, elevation: 384 },
	{ lat: -1, lon: 14.46, elevation: 542 },
	{ lat: -1, lon: 15.18, elevation: 388 },
	{ lat: -1, lon: 15.9, elevation: 325 },
	{ lat: -1, lon: 16.63, elevation: 315 },
	{ lat: -1, lon: 17.35, elevation: 294 },
	{ lat: -1, lon: 18.07, elevation: 326 },
	{ lat: -1, lon: 18.8, elevation: 353 },
	{ lat: -1, lon: 19.52, elevation: 365 },
	{ lat: -1, lon: 20.24, elevation: 344 },
	{ lat: -1, lon: 20.96, elevation: 388 },
	{ lat: -1, lon: 21.69, elevation: 361 },
	{ lat: -1, lon: 22.41, elevation: 424 },
	{ lat: -1, lon: 23.13, elevation: 412 },
	{ lat: -1, lon: 23.86, elevation: 462 },
	{ lat: -1, lon: 24.58, elevation: 438 },
	{ lat: -1, lon: 25.3, elevation: 478 },
	{ lat: -1, lon: 26.02, elevation: 502 },
	{ lat: -1, lon: 26.75, elevation: 577 },
	{ lat: -1, lon: 27.47, elevation: 619 },
	{ lat: -1, lon: 28.19, elevation: 963 },
	{ lat: -1, lon: 28.92, elevation: 1459 },
	{ lat: -1, lon: 29.64, elevation: 1867 },
	{ lat: -1, lon: 30.36, elevation: 1556 },
	{ lat: -1, lon: 31.08, elevation: 1277 },
	{ lat: -1, lon: 31.81, elevation: 1134 },
	{ lat: -1, lon: 32.53, elevation: 1134 },
	{ lat: -1, lon: 33.25, elevation: 1134 },
	{ lat: -1, lon: 33.98, elevation: 1134 },
	{ lat: -1, lon: 34.7, elevation: 1678 },
	{ lat: -1, lon: 35.42, elevation: 1861 },
	{ lat: -1, lon: 36.14, elevation: 2326 },
	{ lat: -1, lon: 36.87, elevation: 1715 },
	{ lat: -1, lon: 37.59, elevation: 1132 },
	{ lat: -1, lon: 38.31, elevation: 720 },
	{ lat: -1, lon: 39.04, elevation: 317 },
	{ lat: -1, lon: 39.76, elevation: 124 },
	{ lat: -1, lon: 40.48, elevation: 97 },
	{ lat: -1, lon: 41.2, elevation: 67 },
	{ lat: -1, lon: 41.93, elevation: 9 },
	{ lat: -1, lon: 100.48, elevation: 1130 },
	{ lat: -1, lon: 101.2, elevation: 265 },
	{ lat: -1, lon: 101.93, elevation: 107 },
	{ lat: -1, lon: 102.65, elevation: 186 },
	{ lat: -1, lon: 103.37, elevation: 5 },
	{ lat: -1, lon: 109.88, elevation: 3 },
	{ lat: -1, lon: 110.6, elevation: 57 },
	{ lat: -1, lon: 111.33, elevation: 395 },
	{ lat: -1, lon: 112.05, elevation: 154 },
	{ lat: -1, lon: 112.77, elevation: 122 },
	{ lat: -1, lon: 113.49, elevation: 132 },
	{ lat: -1, lon: 114.22, elevation: 193 },
	{ lat: -1, lon: 114.94, elevation: 46 },
	{ lat: -1, lon: 115.66, elevation: 172 },
	{ lat: -1, lon: 116.39, elevation: 325 },
	{ lat: -1, lon: 117.11, elevation: 5 },
	{ lat: -1, lon: 120.0, elevation: 594 },
	{ lat: -1, lon: 121.45, elevation: 245 },
	{ lat: -1, lon: 122.17, elevation: 488 },
	{ lat: -1, lon: 130.84, elevation: 295 },
	{ lat: -1, lon: 131.57, elevation: 59 },
	{ lat: -1, lon: 132.29, elevation: 361 },
	{ lat: -1, lon: 133.01, elevation: 906 },
	{ lat: -1, lon: 133.73, elevation: 948 },
	{ lat: -1, lon: 135.9, elevation: 88 },
	{ lat: 0, lon: -91.26, elevation: 473 },
	{ lat: 0, lon: -79.72, elevation: 253 },
	{ lat: 0, lon: -79.0, elevation: 771 },
	{ lat: 0, lon: -78.28, elevation: 2414 },
	{ lat: 0, lon: -77.56, elevation: 1401 },
	{ lat: 0, lon: -76.83, elevation: 326 },
	{ lat: 0, lon: -76.11, elevation: 245 },
	{ lat: 0, lon: -75.39, elevation: 212 },
	{ lat: 0, lon: -74.67, elevation: 161 },
	{ lat: 0, lon: -73.95, elevation: 204 },
	{ lat: 0, lon: -73.23, elevation: 206 },
	{ lat: 0, lon: -72.51, elevation: 205 },
	{ lat: 0, lon: -71.78, elevation: 190 },
	{ lat: 0, lon: -71.06, elevation: 164 },
	{ lat: 0, lon: -70.34, elevation: 156 },
	{ lat: 0, lon: -69.62, elevation: 157 },
	{ lat: 0, lon: -68.9, elevation: 116 },
	{ lat: 0, lon: -68.18, elevation: 104 },
	{ lat: 0, lon: -67.45, elevation: 84 },
	{ lat: 0, lon: -66.73, elevation: 118 },
	{ lat: 0, lon: -66.01, elevation: 91 },
	{ lat: 0, lon: -65.29, elevation: 69 },
	{ lat: 0, lon: -64.57, elevation: 63 },
	{ lat: 0, lon: -63.85, elevation: 64 },
	{ lat: 0, lon: -63.13, elevation: 37 },
	{ lat: 0, lon: -62.4, elevation: 37 },
	{ lat: 0, lon: -61.68, elevation: 47 },
	{ lat: 0, lon: -60.96, elevation: 63 },
	{ lat: 0, lon: -60.24, elevation: 97 },
	{ lat: 0, lon: -59.52, elevation: 111 },
	{ lat: 0, lon: -58.8, elevation: 302 },
	{ lat: 0, lon: -58.08, elevation: 248 },
	{ lat: 0, lon: -57.35, elevation: 159 },
	{ lat: 0, lon: -56.63, elevation: 212 },
	{ lat: 0, lon: -55.91, elevation: 248 },
	{ lat: 0, lon: -55.19, elevation: 437 },
	{ lat: 0, lon: -54.47, elevation: 439 },
	{ lat: 0, lon: -53.75, elevation: 286 },
	{ lat: 0, lon: -53.03, elevation: 132 },
	{ lat: 0, lon: -52.3, elevation: 269 },
	{ lat: 0, lon: -51.58, elevation: 32 },
	{ lat: 0, lon: -50.86, elevation: 20 },
	{ lat: 0, lon: -49.42, elevation: 15 },
	{ lat: 0, lon: 9.74, elevation: 36 },
	{ lat: 0, lon: 10.46, elevation: 122 },
	{ lat: 0, lon: 11.18, elevation: 206 },
	{ lat: 0, lon: 11.9, elevation: 291 },
	{ lat: 0, lon: 12.63, elevation: 475 },
	{ lat: 0, lon: 13.35, elevation: 591 },
	{ lat: 0, lon: 14.07, elevation: 481 },
	{ lat: 0, lon: 14.79, elevation: 410 },
	{ lat: 0, lon: 15.51, elevation: 357 },
	{ lat: 0, lon: 16.23, elevation: 327 },
	{ lat: 0, lon: 16.95, elevation: 321 },
	{ lat: 0, lon: 17.68, elevation: 322 },
	{ lat: 0, lon: 18.4, elevation: 316 },
	{ lat: 0, lon: 19.12, elevation: 336 },
	{ lat: 0, lon: 19.84, elevation: 368 },
	{ lat: 0, lon: 20.56, elevation: 375 },
	{ lat: 0, lon: 21.28, elevation: 391 },
	{ lat: 0, lon: 22.0, elevation: 435 },
	{ lat: 0, lon: 22.73, elevation: 492 },
	{ lat: 0, lon: 23.45, elevation: 490 },
	{ lat: 0, lon: 24.17, elevation: 453 },
	{ lat: 0, lon: 24.89, elevation: 457 },
	{ lat: 0, lon: 25.61, elevation: 444 },
	{ lat: 0, lon: 26.33, elevation: 522 },
	{ lat: 0, lon: 27.05, elevation: 778 },
	{ lat: 0, lon: 27.78, elevation: 692 },
	{ lat: 0, lon: 28.5, elevation: 945 },
	{ lat: 0, lon: 29.22, elevation: 1793 },
	{ lat: 0, lon: 29.94, elevation: 1149 },
	{ lat: 0, lon: 30.66, elevation: 1292 },
	{ lat: 0, lon: 31.38, elevation: 1256 },
	{ lat: 0, lon: 32.1, elevation: 1162 },
	{ lat: 0, lon: 32.83, elevation: 1134 },
	{ lat: 0, lon: 33.55, elevation: 1134 },
	{ lat: 0, lon: 34.27, elevation: 1246 },
	{ lat: 0, lon: 34.99, elevation: 1624 },
	{ lat: 0, lon: 35.71, elevation: 2282 },
	{ lat: 0, lon: 36.43, elevation: 2356 },
	{ lat: 0, lon: 37.15, elevation: 2138 },
	{ lat: 0, lon: 37.88, elevation: 884 },
	{ lat: 0, lon: 38.6, elevation: 361 },
	{ lat: 0, lon: 39.32, elevation: 312 },
	{ lat: 0, lon: 40.04, elevation: 165 },
	{ lat: 0, lon: 40.76, elevation: 123 },
	{ lat: 0, lon: 41.48, elevation: 43 },
	{ lat: 0, lon: 42.2, elevation: 16 },
	{ lat: 0, lon: 99.92, elevation: 356 },
	{ lat: 0, lon: 100.64, elevation: 1247 },
	{ lat: 0, lon: 101.36, elevation: 39 },
	{ lat: 0, lon: 102.08, elevation: 31 },
	{ lat: 0, lon: 102.81, elevation: 25 },
	{ lat: 0, lon: 103.53, elevation: 4 },
	{ lat: 0, lon: 109.3, elevation: 0 },
	{ lat: 0, lon: 110.02, elevation: 35 },
	{ lat: 0, lon: 110.74, elevation: 35 },
	{ lat: 0, lon: 111.46, elevation: 42 },
	{ lat: 0, lon: 112.18, elevation: 232 },
	{ lat: 0, lon: 112.91, elevation: 542 },
	{ lat: 0, lon: 113.63, elevation: 195 },
	{ lat: 0, lon: 114.35, elevation: 190 },
	{ lat: 0, lon: 115.07, elevation: 113 },
	{ lat: 0, lon: 115.79, elevation: 53 },
	{ lat: 0, lon: 116.51, elevation: 15 },
	{ lat: 0, lon: 117.23, elevation: 61 },
	{ lat: 1, lon: -79.52, elevation: 154 },
	{ lat: 1, lon: -78.8, elevation: 70 },
	{ lat: 1, lon: -78.07, elevation: 1857 },
	{ lat: 1, lon: -77.35, elevation: 3743 },
	{ lat: 1, lon: -76.63, elevation: 400 },
	{ lat: 1, lon: -75.9, elevation: 244 },
	{ lat: 1, lon: -75.18, elevation: 208 },
	{ lat: 1, lon: -74.46, elevation: 263 },
	{ lat: 1, lon: -73.73, elevation: 230 },
	{ lat: 1, lon: -73.01, elevation: 238 },
	{ lat: 1, lon: -72.29, elevation: 252 },
	{ lat: 1, lon: -71.57, elevation: 224 },
	{ lat: 1, lon: -70.84, elevation: 211 },
	{ lat: 1, lon: -70.12, elevation: 215 },
	{ lat: 1, lon: -69.4, elevation: 180 },
	{ lat: 1, lon: -68.67, elevation: 146 },
	{ lat: 1, lon: -67.95, elevation: 106 },
	{ lat: 1, lon: -67.23, elevation: 102 },
	{ lat: 1, lon: -66.51, elevation: 110 },
	{ lat: 1, lon: -65.78, elevation: 560 },
	{ lat: 1, lon: -65.06, elevation: 110 },
	{ lat: 1, lon: -64.34, elevation: 91 },
	{ lat: 1, lon: -63.61, elevation: 787 },
	{ lat: 1, lon: -62.89, elevation: 98 },
	{ lat: 1, lon: -62.17, elevation: 71 },
	{ lat: 1, lon: -61.45, elevation: 47 },
	{ lat: 1, lon: -60.72, elevation: 59 },
	{ lat: 1, lon: -60.0, elevation: 157 },
	{ lat: 1, lon: -59.28, elevation: 160 },
	{ lat: 1, lon: -58.55, elevation: 237 },
	{ lat: 1, lon: -57.83, elevation: 539 },
	{ lat: 1, lon: -57.11, elevation: 235 },
	{ lat: 1, lon: -56.39, elevation: 334 },
	{ lat: 1, lon: -55.66, elevation: 364 },
	{ lat: 1, lon: -54.94, elevation: 513 },
	{ lat: 1, lon: -54.22, elevation: 270 },
	{ lat: 1, lon: -53.49, elevation: 277 },
	{ lat: 1, lon: -52.77, elevation: 215 },
	{ lat: 1, lon: -52.05, elevation: 116 },
	{ lat: 1, lon: -51.33, elevation: 91 },
	{ lat: 1, lon: -50.6, elevation: 5 },
	{ lat: 1, lon: 10.12, elevation: 318 },
	{ lat: 1, lon: 10.84, elevation: 502 },
	{ lat: 1, lon: 11.57, elevation: 560 },
	{ lat: 1, lon: 12.29, elevation: 546 },
	{ lat: 1, lon: 13.01, elevation: 496 },
	{ lat: 1, lon: 13.73, elevation: 515 },
	{ lat: 1, lon: 14.46, elevation: 583 },
	{ lat: 1, lon: 15.18, elevation: 433 },
	{ lat: 1, lon: 15.9, elevation: 437 },
	{ lat: 1, lon: 16.63, elevation: 357 },
	{ lat: 1, lon: 17.35, elevation: 329 },
	{ lat: 1, lon: 18.07, elevation: 331 },
	{ lat: 1, lon: 18.8, elevation: 328 },
	{ lat: 1, lon: 19.52, elevation: 343 },
	{ lat: 1, lon: 20.24, elevation: 328 },
	{ lat: 1, lon: 20.96, elevation: 401 },
	{ lat: 1, lon: 21.69, elevation: 383 },
	{ lat: 1, lon: 22.41, elevation: 442 },
	{ lat: 1, lon: 23.13, elevation: 481 },
	{ lat: 1, lon: 23.86, elevation: 392 },
	{ lat: 1, lon: 24.58, elevation: 508 },
	{ lat: 1, lon: 25.3, elevation: 443 },
	{ lat: 1, lon: 26.02, elevation: 505 },
	{ lat: 1, lon: 26.75, elevation: 660 },
	{ lat: 1, lon: 27.47, elevation: 623 },
	{ lat: 1, lon: 28.19, elevation: 642 },
	{ lat: 1, lon: 28.92, elevation: 864 },
	{ lat: 1, lon: 29.64, elevation: 980 },
	{ lat: 1, lon: 30.36, elevation: 669 },
	{ lat: 1, lon: 31.08, elevation: 1136 },
	{ lat: 1, lon: 31.81, elevation: 1118 },
	{ lat: 1, lon: 32.53, elevation: 1095 },
	{ lat: 1, lon: 33.25, elevation: 1084 },
	{ lat: 1, lon: 33.98, elevation: 1132 },
	{ lat: 1, lon: 34.7, elevation: 2632 },
	{ lat: 1, lon: 35.42, elevation: 2723 },
	{ lat: 1, lon: 36.14, elevation: 883 },
	{ lat: 1, lon: 36.87, elevation: 1995 },
	{ lat: 1, lon: 37.59, elevation: 843 },
	{ lat: 1, lon: 38.31, elevation: 483 },
	{ lat: 1, lon: 39.04, elevation: 240 },
	{ lat: 1, lon: 39.76, elevation: 243 },
	{ lat: 1, lon: 40.48, elevation: 154 },
	{ lat: 1, lon: 41.2, elevation: 75 },
	{ lat: 1, lon: 41.93, elevation: 41 },
	{ lat: 1, lon: 42.65, elevation: 47 },
	{ lat: 1, lon: 43.37, elevation: 37 },
	{ lat: 1, lon: 97.59, elevation: 202 },
	{ lat: 1, lon: 99.04, elevation: 61 },
	{ lat: 1, lon: 99.76, elevation: 229 },
	{ lat: 1, lon: 100.48, elevation: 33 },
	{ lat: 1, lon: 101.2, elevation: 23 },
	{ lat: 1, lon: 101.93, elevation: 34 },
	{ lat: 1, lon: 103.37, elevation: 0 },
	{ lat: 1, lon: 104.1, elevation: 0 },
	{ lat: 1, lon: 104.82, elevation: 11 },
	{ lat: 1, lon: 109.16, elevation: 19 },
	{ lat: 1, lon: 109.88, elevation: 826 },
	{ lat: 1, lon: 110.6, elevation: 44 },
	{ lat: 1, lon: 111.33, elevation: 67 },
	{ lat: 1, lon: 112.05, elevation: 53 },
	{ lat: 1, lon: 112.77, elevation: 55 },
	{ lat: 1, lon: 113.49, elevation: 457 },
	{ lat: 1, lon: 114.22, elevation: 681 },
	{ lat: 1, lon: 114.94, elevation: 293 },
	{ lat: 1, lon: 115.66, elevation: 617 },
	{ lat: 1, lon: 116.39, elevation: 313 },
	{ lat: 1, lon: 117.11, elevation: 130 },
	{ lat: 1, lon: 117.83, elevation: 55 },
	{ lat: 1, lon: 118.55, elevation: 63 },
	{ lat: 1, lon: 120.72, elevation: 58 },
	{ lat: 1, lon: 121.45, elevation: 65 },
	{ lat: 1, lon: 122.17, elevation: 37 },
	{ lat: 1, lon: 124.34, elevation: 106 },
	{ lat: 1, lon: 127.95, elevation: 181 },
	{ lat: 2, lon: -78.07, elevation: 74 },
	{ lat: 2, lon: -77.35, elevation: 1551 },
	{ lat: 2, lon: -76.63, elevation: 3159 },
	{ lat: 2, lon: -75.9, elevation: 945 },
	{ lat: 2, lon: -75.18, elevation: 1392 },
	{ lat: 2, lon: -74.46, elevation: 345 },
	{ lat: 2, lon: -73.73, elevation: 302 },
	{ lat: 2, lon: -73.01, elevation: 307 },
	{ lat: 2, lon: -72.29, elevation: 200 },
	{ lat: 2, lon: -71.57, elevation: 214 },
	{ lat: 2, lon: -70.84, elevation: 190 },
	{ lat: 2, lon: -70.12, elevation: 182 },
	{ lat: 2, lon: -69.4, elevation: 196 },
	{ lat: 2, lon: -68.67, elevation: 147 },
	{ lat: 2, lon: -67.95, elevation: 149 },
	{ lat: 2, lon: -67.23, elevation: 120 },
	{ lat: 2, lon: -66.51, elevation: 93 },
	{ lat: 2, lon: -65.78, elevation: 111 },
	{ lat: 2, lon: -65.06, elevation: 172 },
	{ lat: 2, lon: -64.34, elevation: 620 },
	{ lat: 2, lon: -63.61, elevation: 163 },
	{ lat: 2, lon: -62.89, elevation: 284 },
	{ lat: 2, lon: -62.17, elevation: 153 },
	{ lat: 2, lon: -61.45, elevation: 67 },
	{ lat: 2, lon: -60.72, elevation: 309 },
	{ lat: 2, lon: -60.0, elevation: 182 },
	{ lat: 2, lon: -59.28, elevation: 276 },
	{ lat: 2, lon: -58.55, elevation: 260 },
	{ lat: 2, lon: -57.83, elevation: 228 },
	{ lat: 2, lon: -57.11, elevation: 272 },
	{ lat: 2, lon: -56.39, elevation: 326 },
	{ lat: 2, lon: -55.66, elevation: 485 },
	{ lat: 2, lon: -54.94, elevation: 378 },
	{ lat: 2, lon: -54.22, elevation: 289 },
	{ lat: 2, lon: -53.49, elevation: 273 },
	{ lat: 2, lon: -52.77, elevation: 199 },
	{ lat: 2, lon: -52.05, elevation: 175 },
	{ lat: 2, lon: -51.33, elevation: 101 },
	{ lat: 2, lon: -50.6, elevation: 7 },
	{ lat: 2, lon: 10.12, elevation: 340 },
	{ lat: 2, lon: 10.84, elevation: 551 },
	{ lat: 2, lon: 11.57, elevation: 609 },
	{ lat: 2, lon: 12.29, elevation: 599 },
	{ lat: 2, lon: 13.01, elevation: 569 },
	{ lat: 2, lon: 13.73, elevation: 533 },
	{ lat: 2, lon: 14.46, elevation: 549 },
	{ lat: 2, lon: 15.18, elevation: 375 },
	{ lat: 2, lon: 15.9, elevation: 414 },
	{ lat: 2, lon: 16.63, elevation: 393 },
	{ lat: 2, lon: 17.35, elevation: 345 },
	{ lat: 2, lon: 18.07, elevation: 331 },
	{ lat: 2, lon: 18.8, elevation: 326 },
	{ lat: 2, lon: 19.52, elevation: 346 },
	{ lat: 2, lon: 20.24, elevation: 343 },
	{ lat: 2, lon: 20.96, elevation: 332 },
	{ lat: 2, lon: 21.69, elevation: 375 },
	{ lat: 2, lon: 22.41, elevation: 371 },
	{ lat: 2, lon: 23.13, elevation: 408 },
	{ lat: 2, lon: 23.86, elevation: 527 },
	{ lat: 2, lon: 24.58, elevation: 521 },
	{ lat: 2, lon: 25.3, elevation: 466 },
	{ lat: 2, lon: 26.02, elevation: 483 },
	{ lat: 2, lon: 26.75, elevation: 526 },
	{ lat: 2, lon: 27.47, elevation: 790 },
	{ lat: 2, lon: 28.19, elevation: 860 },
	{ lat: 2, lon: 28.92, elevation: 916 },
	{ lat: 2, lon: 29.64, elevation: 1044 },
	{ lat: 2, lon: 30.36, elevation: 1500 },
	{ lat: 2, lon: 31.08, elevation: 615 },
	{ lat: 2, lon: 31.81, elevation: 939 },
	{ lat: 2, lon: 32.53, elevation: 1043 },
	{ lat: 2, lon: 33.25, elevation: 1154 },
	{ lat: 2, lon: 33.98, elevation: 1095 },
	{ lat: 2, lon: 34.7, elevation: 1279 },
	{ lat: 2, lon: 35.42, elevation: 748 },
	{ lat: 2, lon: 36.14, elevation: 559 },
	{ lat: 2, lon: 36.87, elevation: 1207 },
	{ lat: 2, lon: 37.59, elevation: 504 },
	{ lat: 2, lon: 38.31, elevation: 366 },
	{ lat: 2, lon: 39.04, elevation: 331 },
	{ lat: 2, lon: 39.76, elevation: 309 },
	{ lat: 2, lon: 40.48, elevation: 326 },
	{ lat: 2, lon: 41.2, elevation: 198 },
	{ lat: 2, lon: 41.93, elevation: 221 },
	{ lat: 2, lon: 42.65, elevation: 208 },
	{ lat: 2, lon: 43.37, elevation: 156 },
	{ lat: 2, lon: 44.1, elevation: 78 },
	{ lat: 2, lon: 44.82, elevation: 76 },
	{ lat: 2, lon: 99.04, elevation: 1238 },
	{ lat: 2, lon: 99.76, elevation: 119 },
	{ lat: 2, lon: 100.48, elevation: 34 },
	{ lat: 2, lon: 101.2, elevation: 23 },
	{ lat: 2, lon: 102.65, elevation: 12 },
	{ lat: 2, lon: 103.37, elevation: 84 },
	{ lat: 2, lon: 104.1, elevation: 0 },
	{ lat: 2, lon: 111.33, elevation: 40 },
	{ lat: 2, lon: 112.05, elevation: 74 },
	{ lat: 2, lon: 112.77, elevation: 38 },
	{ lat: 2, lon: 113.49, elevation: 313 },
	{ lat: 2, lon: 114.22, elevation: 279 },
	{ lat: 2, lon: 114.94, elevation: 703 },
	{ lat: 2, lon: 115.66, elevation: 697 },
	{ lat: 2, lon: 116.39, elevation: 629 },
	{ lat: 2, lon: 117.11, elevation: 92 },
	{ lat: 2, lon: 117.83, elevation: 20 },
	{ lat: 3, lon: -77.35, elevation: 46 },
	{ lat: 3, lon: -76.63, elevation: 1115 },
	{ lat: 3, lon: -75.9, elevation: 3151 },
	{ lat: 3, lon: -75.18, elevation: 559 },
	{ lat: 3, lon: -74.46, elevation: 555 },
	{ lat: 3, lon: -73.73, elevation: 239 },
	{ lat: 3, lon: -73.01, elevation: 220 },
	{ lat: 3, lon: -72.29, elevation: 201 },
	{ lat: 3, lon: -71.57, elevation: 192 },
	{ lat: 3, lon: -70.84, elevation: 198 },
	{ lat: 3, lon: -70.12, elevation: 213 },
	{ lat: 3, lon: -69.4, elevation: 162 },
	{ lat: 3, lon: -68.67, elevation: 137 },
	{ lat: 3, lon: -67.95, elevation: 115 },
	{ lat: 3, lon: -67.23, elevation: 117 },
	{ lat: 3, lon: -66.51, elevation: 141 },
	{ lat: 3, lon: -65.78, elevation: 139 },
	{ lat: 3, lon: -65.06, elevation: 154 },
	{ lat: 3, lon: -64.34, elevation: 1146 },
	{ lat: 3, lon: -63.61, elevation: 712 },
	{ lat: 3, lon: -62.89, elevation: 340 },
	{ lat: 3, lon: -62.17, elevation: 329 },
	{ lat: 3, lon: -61.45, elevation: 114 },
	{ lat: 3, lon: -60.72, elevation: 84 },
	{ lat: 3, lon: -60.0, elevation: 106 },
	{ lat: 3, lon: -59.28, elevation: 287 },
	{ lat: 3, lon: -58.55, elevation: 177 },
	{ lat: 3, lon: -57.83, elevation: 297 },
	{ lat: 3, lon: -57.11, elevation: 198 },
	{ lat: 3, lon: -56.39, elevation: 268 },
	{ lat: 3, lon: -55.66, elevation: 198 },
	{ lat: 3, lon: -54.94, elevation: 214 },
	{ lat: 3, lon: -54.22, elevation: 232 },
	{ lat: 3, lon: -53.49, elevation: 204 },
	{ lat: 3, lon: -52.77, elevation: 148 },
	{ lat: 3, lon: -52.05, elevation: 168 },
	{ lat: 3, lon: -51.33, elevation: 54 },
	{ lat: 3, lon: 10.12, elevation: 190 },
	{ lat: 3, lon: 10.84, elevation: 719 },
	{ lat: 3, lon: 11.57, elevation: 709 },
	{ lat: 3, lon: 12.29, elevation: 674 },
	{ lat: 3, lon: 13.01, elevation: 599 },
	{ lat: 3, lon: 13.73, elevation: 610 },
	{ lat: 3, lon: 14.46, elevation: 625 },
	{ lat: 3, lon: 15.18, elevation: 488 },
	{ lat: 3, lon: 15.9, elevation: 444 },
	{ lat: 3, lon: 16.63, elevation: 449 },
	{ lat: 3, lon: 17.35, elevation: 456 },
	{ lat: 3, lon: 18.07, elevation: 396 },
	{ lat: 3, lon: 18.8, elevation: 357 },
	{ lat: 3, lon: 19.52, elevation: 390 },
	{ lat: 3, lon: 20.24, elevation: 398 },
	{ lat: 3, lon: 20.96, elevation: 437 },
	{ lat: 3, lon: 21.69, elevation: 399 },
	{ lat: 3, lon: 22.41, elevation: 403 },
	{ lat: 3, lon: 23.13, elevation: 530 },
	{ lat: 3, lon: 23.86, elevation: 473 },
	{ lat: 3, lon: 24.58, elevation: 448 },
	{ lat: 3, lon: 25.3, elevation: 627 },
	{ lat: 3, lon: 26.02, elevation: 639 },
	{ lat: 3, lon: 26.75, elevation: 678 },
	{ lat: 3, lon: 27.47, elevation: 732 },
	{ lat: 3, lon: 28.19, elevation: 810 },
	{ lat: 3, lon: 28.92, elevation: 814 },
	{ lat: 3, lon: 29.64, elevation: 1037 },
	{ lat: 3, lon: 30.36, elevation: 1090 },
	{ lat: 3, lon: 31.08, elevation: 838 },
	{ lat: 3, lon: 31.81, elevation: 1035 },
	{ lat: 3, lon: 32.53, elevation: 939 },
	{ lat: 3, lon: 33.25, elevation: 1083 },
	{ lat: 3, lon: 33.98, elevation: 1239 },
	{ lat: 3, lon: 34.7, elevation: 945 },
	{ lat: 3, lon: 35.42, elevation: 546 },
	{ lat: 3, lon: 36.14, elevation: 361 },
	{ lat: 3, lon: 36.87, elevation: 534 },
	{ lat: 3, lon: 37.59, elevation: 453 },
	{ lat: 3, lon: 38.31, elevation: 563 },
	{ lat: 3, lon: 39.04, elevation: 584 },
	{ lat: 3, lon: 39.76, elevation: 654 },
	{ lat: 3, lon: 40.48, elevation: 482 },
	{ lat: 3, lon: 41.2, elevation: 371 },
	{ lat: 3, lon: 41.93, elevation: 437 },
	{ lat: 3, lon: 42.65, elevation: 282 },
	{ lat: 3, lon: 43.37, elevation: 485 },
	{ lat: 3, lon: 44.1, elevation: 240 },
	{ lat: 3, lon: 44.82, elevation: 141 },
	{ lat: 3, lon: 45.54, elevation: 113 },
	{ lat: 3, lon: 46.27, elevation: 125 },
	{ lat: 3, lon: 97.59, elevation: 142 },
	{ lat: 3, lon: 98.31, elevation: 1049 },
	{ lat: 3, lon: 99.04, elevation: 377 },
	{ lat: 3, lon: 99.76, elevation: 11 },
	{ lat: 3, lon: 101.93, elevation: 800 },
	{ lat: 3, lon: 102.65, elevation: 46 },
	{ lat: 3, lon: 103.37, elevation: 20 },
	{ lat: 3, lon: 112.77, elevation: 11 },
	{ lat: 3, lon: 113.49, elevation: 41 },
	{ lat: 3, lon: 114.22, elevation: 202 },
	{ lat: 3, lon: 114.94, elevation: 323 },
	{ lat: 3, lon: 115.66, elevation: 884 },
	{ lat: 3, lon: 116.39, elevation: 183 },
	{ lat: 3, lon: 117.11, elevation: 216 },
	{ lat: 4, lon: -77.14, elevation: 70 },
	{ lat: 4, lon: -76.42, elevation: 1701 },
	{ lat: 4, lon: -75.69, elevation: 3576 },
	{ lat: 4, lon: -74.97, elevation: 315 },
	{ lat: 4, lon: -74.25, elevation: 3859 },
	{ lat: 4, lon: -73.52, elevation: 334 },
	{ lat: 4, lon: -72.8, elevation: 252 },
	{ lat: 4, lon: -72.07, elevation: 205 },
	{ lat: 4, lon: -71.35, elevation: 167 },
	{ lat: 4, lon: -70.62, elevation: 180 },
	{ lat: 4, lon: -69.9, elevation: 166 },
	{ lat: 4, lon: -69.18, elevation: 148 },
	{ lat: 4, lon: -68.45, elevation: 120 },
	{ lat: 4, lon: -67.73, elevation: 99 },
	{ lat: 4, lon: -67.0, elevation: 87 },
	{ lat: 4, lon: -66.28, elevation: 137 },
	{ lat: 4, lon: -65.55, elevation: 528 },
	{ lat: 4, lon: -64.83, elevation: 1427 },
	{ lat: 4, lon: -64.1, elevation: 718 },
	{ lat: 4, lon: -63.38, elevation: 813 },
	{ lat: 4, lon: -62.66, elevation: 602 },
	{ lat: 4, lon: -61.93, elevation: 573 },
	{ lat: 4, lon: -61.21, elevation: 125 },
	{ lat: 4, lon: -60.48, elevation: 100 },
	{ lat: 4, lon: -59.76, elevation: 487 },
	{ lat: 4, lon: -59.03, elevation: 96 },
	{ lat: 4, lon: -58.31, elevation: 119 },
	{ lat: 4, lon: -57.59, elevation: 126 },
	{ lat: 4, lon: -56.86, elevation: 288 },
	{ lat: 4, lon: -56.14, elevation: 269 },
	{ lat: 4, lon: -55.41, elevation: 112 },
	{ lat: 4, lon: -54.69, elevation: 123 },
	{ lat: 4, lon: -53.96, elevation: 149 },
	{ lat: 4, lon: -53.24, elevation: 202 },
	{ lat: 4, lon: -52.52, elevation: 111 },
	{ lat: 4, lon: -51.79, elevation: 49 },
	{ lat: 4, lon: 9.78, elevation: 18 },
	{ lat: 4, lon: 10.5, elevation: 257 },
	{ lat: 4, lon: 11.23, elevation: 673 },
	{ lat: 4, lon: 11.95, elevation: 785 },
	{ lat: 4, lon: 12.68, elevation: 669 },
	{ lat: 4, lon: 13.4, elevation: 734 },
	{ lat: 4, lon: 14.12, elevation: 632 },
	{ lat: 4, lon: 14.85, elevation: 632 },
	{ lat: 4, lon: 15.57, elevation: 487 },
	{ lat: 4, lon: 16.3, elevation: 575 },
	{ lat: 4, lon: 17.02, elevation: 618 },
	{ lat: 4, lon: 17.75, elevation: 559 },
	{ lat: 4, lon: 18.47, elevation: 372 },
	{ lat: 4, lon: 19.2, elevation: 376 },
	{ lat: 4, lon: 19.92, elevation: 492 },
	{ lat: 4, lon: 20.64, elevation: 376 },
	{ lat: 4, lon: 21.37, elevation: 500 },
	{ lat: 4, lon: 22.09, elevation: 434 },
	{ lat: 4, lon: 22.82, elevation: 511 },
	{ lat: 4, lon: 23.54, elevation: 544 },
	{ lat: 4, lon: 24.27, elevation: 544 },
	{ lat: 4, lon: 24.99, elevation: 612 },
	{ lat: 4, lon: 25.71, elevation: 609 },
	{ lat: 4, lon: 26.44, elevation: 625 },
	{ lat: 4, lon: 27.16, elevation: 690 },
	{ lat: 4, lon: 27.89, elevation: 690 },
	{ lat: 4, lon: 28.61, elevation: 716 },
	{ lat: 4, lon: 29.34, elevation: 745 },
	{ lat: 4, lon: 30.06, elevation: 831 },
	{ lat: 4, lon: 30.78, elevation: 906 },
	{ lat: 4, lon: 31.51, elevation: 966 },
	{ lat: 4, lon: 32.23, elevation: 893 },
	{ lat: 4, lon: 32.96, elevation: 2386 },
	{ lat: 4, lon: 33.68, elevation: 1030 },
	{ lat: 4, lon: 34.41, elevation: 868 },
	{ lat: 4, lon: 35.13, elevation: 530 },
	{ lat: 4, lon: 35.86, elevation: 377 },
	{ lat: 4, lon: 36.58, elevation: 630 },
	{ lat: 4, lon: 37.3, elevation: 693 },
	{ lat: 4, lon: 38.03, elevation: 850 },
	{ lat: 4, lon: 38.75, elevation: 1366 },
	{ lat: 4, lon: 39.48, elevation: 1075 },
	{ lat: 4, lon: 40.2, elevation: 1003 },
	{ lat: 4, lon: 40.93, elevation: 549 },
	{ lat: 4, lon: 41.65, elevation: 240 },
	{ lat: 4, lon: 42.37, elevation: 172 },
	{ lat: 4, lon: 43.1, elevation: 358 },
	{ lat: 4, lon: 43.82, elevation: 530 },
	{ lat: 4, lon: 44.55, elevation: 403 },
	{ lat: 4, lon: 45.27, elevation: 200 },
	{ lat: 4, lon: 46.0, elevation: 252 },
	{ lat: 4, lon: 46.72, elevation: 104 },
	{ lat: 4, lon: 47.44, elevation: 26 },
	{ lat: 4, lon: 96.7, elevation: 132 },
	{ lat: 4, lon: 97.42, elevation: 1371 },
	{ lat: 4, lon: 98.15, elevation: 40 },
	{ lat: 4, lon: 101.05, elevation: 5 },
	{ lat: 4, lon: 101.77, elevation: 236 },
	{ lat: 4, lon: 102.49, elevation: 503 },
	{ lat: 4, lon: 103.22, elevation: 114 },
	{ lat: 4, lon: 108.29, elevation: 51 },
	{ lat: 4, lon: 114.08, elevation: 9 },
	{ lat: 4, lon: 114.81, elevation: 98 },
	{ lat: 4, lon: 115.53, elevation: 1303 },
	{ lat: 4, lon: 116.26, elevation: 614 },
	{ lat: 4, lon: 116.98, elevation: 21 },
	{ lat: 4, lon: 117.71, elevation: 32 },
	{ lat: 5, lon: -77.14, elevation: 27 },
	{ lat: 5, lon: -76.42, elevation: 183 },
	{ lat: 5, lon: -75.69, elevation: 1199 },
	{ lat: 5, lon: -74.97, elevation: 1063 },
	{ lat: 5, lon: -74.25, elevation: 2125 },
	{ lat: 5, lon: -73.52, elevation: 1451 },
	{ lat: 5, lon: -72.8, elevation: 663 },
	{ lat: 5, lon: -72.07, elevation: 174 },
	{ lat: 5, lon: -71.35, elevation: 142 },
	{ lat: 5, lon: -70.62, elevation: 140 },
	{ lat: 5, lon: -69.9, elevation: 106 },
	{ lat: 5, lon: -69.18, elevation: 138 },
	{ lat: 5, lon: -68.45, elevation: 79 },
	{ lat: 5, lon: -67.73, elevation: 229 },
	{ lat: 5, lon: -67.0, elevation: 1240 },
	{ lat: 5, lon: -66.28, elevation: 137 },
	{ lat: 5, lon: -65.55, elevation: 203 },
	{ lat: 5, lon: -64.83, elevation: 481 },
	{ lat: 5, lon: -64.1, elevation: 457 },
	{ lat: 5, lon: -63.38, elevation: 329 },
	{ lat: 5, lon: -62.66, elevation: 522 },
	{ lat: 5, lon: -61.93, elevation: 826 },
	{ lat: 5, lon: -61.21, elevation: 852 },
	{ lat: 5, lon: -60.48, elevation: 825 },
	{ lat: 5, lon: -59.76, elevation: 677 },
	{ lat: 5, lon: -59.03, elevation: 114 },
	{ lat: 5, lon: -58.31, elevation: 69 },
	{ lat: 5, lon: -57.59, elevation: 14 },
	{ lat: 5, lon: -56.86, elevation: 80 },
	{ lat: 5, lon: -56.14, elevation: 29 },
	{ lat: 5, lon: -55.41, elevation: 38 },
	{ lat: 5, lon: -54.69, elevation: 74 },
	{ lat: 5, lon: -53.96, elevation: 81 },
	{ lat: 5, lon: -53.24, elevation: 96 },
	{ lat: 5, lon: -52.52, elevation: 5 },
	{ lat: 5, lon: -8.33, elevation: 141 },
	{ lat: 5, lon: -7.61, elevation: 102 },
	{ lat: 5, lon: -6.88, elevation: 68 },
	{ lat: 5, lon: -6.16, elevation: 22 },
	{ lat: 5, lon: -2.54, elevation: 12 },
	{ lat: 5, lon: -1.81, elevation: 31 },
	{ lat: 5, lon: 6.16, elevation: 24 },
	{ lat: 5, lon: 6.88, elevation: 29 },
	{ lat: 5, lon: 7.61, elevation: 69 },
	{ lat: 5, lon: 8.33, elevation: 46 },
	{ lat: 5, lon: 9.05, elevation: 828 },
	{ lat: 5, lon: 9.78, elevation: 1395 },
	{ lat: 5, lon: 10.5, elevation: 1009 },
	{ lat: 5, lon: 11.23, elevation: 615 },
	{ lat: 5, lon: 11.95, elevation: 626 },
	{ lat: 5, lon: 12.68, elevation: 627 },
	{ lat: 5, lon: 13.4, elevation: 719 },
	{ lat: 5, lon: 14.12, elevation: 710 },
	{ lat: 5, lon: 14.85, elevation: 758 },
	{ lat: 5, lon: 15.57, elevation: 519 },
	{ lat: 5, lon: 16.3, elevation: 706 },
	{ lat: 5, lon: 17.02, elevation: 727 },
	{ lat: 5, lon: 17.75, elevation: 736 },
	{ lat: 5, lon: 18.47, elevation: 392 },
	{ lat: 5, lon: 19.2, elevation: 369 },
	{ lat: 5, lon: 19.92, elevation: 367 },
	{ lat: 5, lon: 20.64, elevation: 536 },
	{ lat: 5, lon: 21.37, elevation: 467 },
	{ lat: 5, lon: 22.09, elevation: 664 },
	{ lat: 5, lon: 22.82, elevation: 534 },
	{ lat: 5, lon: 23.54, elevation: 583 },
	{ lat: 5, lon: 24.27, elevation: 579 },
	{ lat: 5, lon: 24.99, elevation: 634 },
	{ lat: 5, lon: 25.71, elevation: 628 },
	{ lat: 5, lon: 26.44, elevation: 662 },
	{ lat: 5, lon: 27.16, elevation: 662 },
	{ lat: 5, lon: 27.89, elevation: 650 },
	{ lat: 5, lon: 28.61, elevation: 625 },
	{ lat: 5, lon: 29.34, elevation: 710 },
	{ lat: 5, lon: 30.06, elevation: 834 },
	{ lat: 5, lon: 30.78, elevation: 634 },
	{ lat: 5, lon: 31.51, elevation: 486 },
	{ lat: 5, lon: 32.23, elevation: 484 },
	{ lat: 5, lon: 32.96, elevation: 502 },
	{ lat: 5, lon: 33.68, elevation: 586 },
	{ lat: 5, lon: 34.41, elevation: 478 },
	{ lat: 5, lon: 35.13, elevation: 459 },
	{ lat: 5, lon: 35.86, elevation: 380 },
	{ lat: 5, lon: 36.58, elevation: 1039 },
	{ lat: 5, lon: 37.3, elevation: 1329 },
	{ lat: 5, lon: 38.03, elevation: 1597 },
	{ lat: 5, lon: 38.75, elevation: 1354 },
	{ lat: 5, lon: 39.48, elevation: 947 },
	{ lat: 5, lon: 40.2, elevation: 981 },
	{ lat: 5, lon: 40.93, elevation: 1015 },
	{ lat: 5, lon: 41.65, elevation: 474 },
	{ lat: 5, lon: 42.37, elevation: 321 },
	{ lat: 5, lon: 43.1, elevation: 432 },
	{ lat: 5, lon: 43.82, elevation: 474 },
	{ lat: 5, lon: 44.55, elevation: 306 },
	{ lat: 5, lon: 45.27, elevation: 323 },
	{ lat: 5, lon: 46.0, elevation: 255 },
	{ lat: 5, lon: 46.72, elevation: 183 },
	{ lat: 5, lon: 47.44, elevation: 140 },
	{ lat: 5, lon: 48.17, elevation: 129 },
	{ lat: 5, lon: 95.98, elevation: 760 },
	{ lat: 5, lon: 96.7, elevation: 407 },
	{ lat: 5, lon: 97.42, elevation: 30 },
	{ lat: 5, lon: 101.05, elevation: 557 },
	{ lat: 5, lon: 101.77, elevation: 201 },
	{ lat: 5, lon: 102.49, elevation: 385 },
	{ lat: 5, lon: 103.22, elevation: 52 },
	{ lat: 5, lon: 115.53, elevation: 16 },
	{ lat: 5, lon: 116.26, elevation: 549 },
	{ lat: 5, lon: 116.98, elevation: 438 },
	{ lat: 5, lon: 117.71, elevation: 212 },
	{ lat: 5, lon: 118.43, elevation: 0 },
	{ lat: 6, lon: -76.94, elevation: 95 },
	{ lat: 6, lon: -76.21, elevation: 2157 },
	{ lat: 6, lon: -75.48, elevation: 2313 },
	{ lat: 6, lon: -74.76, elevation: 429 },
	{ lat: 6, lon: -74.03, elevation: 1009 },
	{ lat: 6, lon: -73.31, elevation: 2156 },
	{ lat: 6, lon: -72.58, elevation: 3343 },
	{ lat: 6, lon: -71.85, elevation: 303 },
	{ lat: 6, lon: -71.13, elevation: 141 },
	{ lat: 6, lon: -70.4, elevation: 108 },
	{ lat: 6, lon: -69.68, elevation: 86 },
	{ lat: 6, lon: -68.95, elevation: 95 },
	{ lat: 6, lon: -68.23, elevation: 89 },
	{ lat: 6, lon: -67.5, elevation: 64 },
	{ lat: 6, lon: -66.77, elevation: 478 },
	{ lat: 6, lon: -66.05, elevation: 507 },
	{ lat: 6, lon: -65.32, elevation: 1314 },
	{ lat: 6, lon: -64.6, elevation: 293 },
	{ lat: 6, lon: -63.87, elevation: 432 },
	{ lat: 6, lon: -63.15, elevation: 409 },
	{ lat: 6, lon: -62.42, elevation: 1724 },
	{ lat: 6, lon: -61.69, elevation: 1037 },
	{ lat: 6, lon: -60.97, elevation: 995 },
	{ lat: 6, lon: -60.24, elevation: 781 },
	{ lat: 6, lon: -59.52, elevation: 145 },
	{ lat: 6, lon: -58.79, elevation: 125 },
	{ lat: 6, lon: -58.06, elevation: 47 },
	{ lat: 6, lon: -57.34, elevation: 4 },
	{ lat: 6, lon: -10.16, elevation: 8 },
	{ lat: 6, lon: -9.44, elevation: 142 },
	{ lat: 6, lon: -8.71, elevation: 199 },
	{ lat: 6, lon: -7.98, elevation: 254 },
	{ lat: 6, lon: -7.26, elevation: 223 },
	{ lat: 6, lon: -6.53, elevation: 210 },
	{ lat: 6, lon: -5.81, elevation: 274 },
	{ lat: 6, lon: -5.08, elevation: 117 },
	{ lat: 6, lon: -4.35, elevation: 92 },
	{ lat: 6, lon: -3.63, elevation: 99 },
	{ lat: 6, lon: -2.9, elevation: 163 },
	{ lat: 6, lon: -2.18, elevation: 79 },
	{ lat: 6, lon: -1.45, elevation: 148 },
	{ lat: 6, lon: -0.73, elevation: 178 },
	{ lat: 6, lon: 0.0, elevation: 76 },
	{ lat: 6, lon: 0.73, elevation: 1 },
	{ lat: 6, lon: 5.08, elevation: 11 },
	{ lat: 6, lon: 5.81, elevation: 34 },
	{ lat: 6, lon: 6.53, elevation: 23 },
	{ lat: 6, lon: 7.26, elevation: 112 },
	{ lat: 6, lon: 7.98, elevation: 40 },
	{ lat: 6, lon: 8.71, elevation: 49 },
	{ lat: 6, lon: 9.44, elevation: 160 },
	{ lat: 6, lon: 10.16, elevation: 1265 },
	{ lat: 6, lon: 10.89, elevation: 793 },
	{ lat: 6, lon: 11.61, elevation: 885 },
	{ lat: 6, lon: 12.34, elevation: 930 },
	{ lat: 6, lon: 13.06, elevation: 823 },
	{ lat: 6, lon: 13.79, elevation: 828 },
	{ lat: 6, lon: 14.52, elevation: 923 },
	{ lat: 6, lon: 15.24, elevation: 882 },
	{ lat: 6, lon: 15.97, elevation: 785 },
	{ lat: 6, lon: 16.69, elevation: 717 },
	{ lat: 6, lon: 17.42, elevation: 669 },
	{ lat: 6, lon: 18.15, elevation: 563 },
	{ lat: 6, lon: 18.87, elevation: 640 },
	{ lat: 6, lon: 19.6, elevation: 574 },
	{ lat: 6, lon: 20.32, elevation: 544 },
	{ lat: 6, lon: 21.05, elevation: 521 },
	{ lat: 6, lon: 21.77, elevation: 589 },
	{ lat: 6, lon: 22.5, elevation: 623 },
	{ lat: 6, lon: 23.23, elevation: 565 },
	{ lat: 6, lon: 23.95, elevation: 631 },
	{ lat: 6, lon: 24.68, elevation: 610 },
	{ lat: 6, lon: 25.4, elevation: 687 },
	{ lat: 6, lon: 26.13, elevation: 703 },
	{ lat: 6, lon: 26.85, elevation: 653 },
	{ lat: 6, lon: 27.58, elevation: 542 },
	{ lat: 6, lon: 28.31, elevation: 560 },
	{ lat: 6, lon: 29.03, elevation: 513 },
	{ lat: 6, lon: 29.76, elevation: 490 },
	{ lat: 6, lon: 30.48, elevation: 471 },
	{ lat: 6, lon: 31.21, elevation: 433 },
	{ lat: 6, lon: 31.94, elevation: 435 },
	{ lat: 6, lon: 32.66, elevation: 435 },
	{ lat: 6, lon: 33.39, elevation: 427 },
	{ lat: 6, lon: 34.11, elevation: 451 },
	{ lat: 6, lon: 34.84, elevation: 909 },
	{ lat: 6, lon: 35.56, elevation: 1328 },
	{ lat: 6, lon: 36.29, elevation: 708 },
	{ lat: 6, lon: 37.02, elevation: 985 },
	{ lat: 6, lon: 37.74, elevation: 1416 },
	{ lat: 6, lon: 38.47, elevation: 2260 },
	{ lat: 6, lon: 39.19, elevation: 1695 },
	{ lat: 6, lon: 39.92, elevation: 1395 },
	{ lat: 6, lon: 40.65, elevation: 1081 },
	{ lat: 6, lon: 41.37, elevation: 733 },
	{ lat: 6, lon: 42.1, elevation: 1135 },
	{ lat: 6, lon: 42.82, elevation: 366 },
	{ lat: 6, lon: 43.55, elevation: 310 },
	{ lat: 6, lon: 44.27, elevation: 409 },
	{ lat: 6, lon: 45.0, elevation: 544 },
	{ lat: 6, lon: 45.73, elevation: 386 },
	{ lat: 6, lon: 46.45, elevation: 315 },
	{ lat: 6, lon: 47.18, elevation: 222 },
	{ lat: 6, lon: 47.9, elevation: 127 },
	{ lat: 6, lon: 48.63, elevation: 87 },
	{ lat: 6, lon: 80.56, elevation: 13 },
	{ lat: 6, lon: 100.89, elevation: 254 },
	{ lat: 6, lon: 101.61, elevation: 98 },
	{ lat: 6, lon: 102.34, elevation: 12 },
	{ lat: 6, lon: 116.13, elevation: 6 },
	{ lat: 6, lon: 116.85, elevation: 720 },
	{ lat: 6, lon: 117.58, elevation: 12 },
	{ lat: 6, lon: 124.84, elevation: 1294 },
	{ lat: 6, lon: 125.56, elevation: 703 },
	{ lat: 7, lon: -77.45, elevation: 244 },
	{ lat: 7, lon: -76.73, elevation: 20 },
	{ lat: 7, lon: -76.0, elevation: 2595 },
	{ lat: 7, lon: -75.27, elevation: 1148 },
	{ lat: 7, lon: -74.55, elevation: 562 },
	{ lat: 7, lon: -73.82, elevation: 108 },
	{ lat: 7, lon: -73.09, elevation: 1074 },
	{ lat: 7, lon: -72.36, elevation: 1130 },
	{ lat: 7, lon: -71.64, elevation: 175 },
	{ lat: 7, lon: -70.91, elevation: 134 },
	{ lat: 7, lon: -70.18, elevation: 108 },
	{ lat: 7, lon: -69.45, elevation: 89 },
	{ lat: 7, lon: -68.73, elevation: 70 },
	{ lat: 7, lon: -68.0, elevation: 62 },
	{ lat: 7, lon: -67.27, elevation: 47 },
	{ lat: 7, lon: -66.55, elevation: 43 },
	{ lat: 7, lon: -65.82, elevation: 47 },
	{ lat: 7, lon: -65.09, elevation: 76 },
	{ lat: 7, lon: -64.36, elevation: 318 },
	{ lat: 7, lon: -63.64, elevation: 273 },
	{ lat: 7, lon: -62.91, elevation: 270 },
	{ lat: 7, lon: -62.18, elevation: 176 },
	{ lat: 7, lon: -61.45, elevation: 183 },
	{ lat: 7, lon: -60.73, elevation: 149 },
	{ lat: 7, lon: -60.0, elevation: 169 },
	{ lat: 7, lon: -59.27, elevation: 116 },
	{ lat: 7, lon: -58.55, elevation: 30 },
	{ lat: 7, lon: -11.27, elevation: 32 },
	{ lat: 7, lon: -10.55, elevation: 174 },
	{ lat: 7, lon: -9.82, elevation: 212 },
	{ lat: 7, lon: -9.09, elevation: 265 },
	{ lat: 7, lon: -8.36, elevation: 269 },
	{ lat: 7, lon: -7.64, elevation: 273 },
	{ lat: 7, lon: -6.91, elevation: 288 },
	{ lat: 7, lon: -6.18, elevation: 285 },
	{ lat: 7, lon: -5.45, elevation: 450 },
	{ lat: 7, lon: -4.73, elevation: 163 },
	{ lat: 7, lon: -4.0, elevation: 204 },
	{ lat: 7, lon: -3.27, elevation: 266 },
	{ lat: 7, lon: -2.55, elevation: 260 },
	{ lat: 7, lon: -1.82, elevation: 276 },
	{ lat: 7, lon: -1.09, elevation: 186 },
	{ lat: 7, lon: -0.36, elevation: 168 },
	{ lat: 7, lon: 0.36, elevation: 118 },
	{ lat: 7, lon: 1.09, elevation: 163 },
	{ lat: 7, lon: 1.82, elevation: 170 },
	{ lat: 7, lon: 2.55, elevation: 31 },
	{ lat: 7, lon: 3.27, elevation: 64 },
	{ lat: 7, lon: 4.0, elevation: 89 },
	{ lat: 7, lon: 4.73, elevation: 158 },
	{ lat: 7, lon: 5.45, elevation: 209 },
	{ lat: 7, lon: 6.18, elevation: 127 },
	{ lat: 7, lon: 6.91, elevation: 34 },
	{ lat: 7, lon: 7.64, elevation: 291 },
	{ lat: 7, lon: 8.36, elevation: 87 },
	{ lat: 7, lon: 9.09, elevation: 168 },
	{ lat: 7, lon: 9.82, elevation: 530 },
	{ lat: 7, lon: 10.55, elevation: 358 },
	{ lat: 7, lon: 11.27, elevation: 1666 },
	{ lat: 7, lon: 12.0, elevation: 1039 },
	{ lat: 7, lon: 12.73, elevation: 1054 },
	{ lat: 7, lon: 13.45, elevation: 1134 },
	{ lat: 7, lon: 14.18, elevation: 1253 },
	{ lat: 7, lon: 14.91, elevation: 986 },
	{ lat: 7, lon: 15.64, elevation: 1074 },
	{ lat: 7, lon: 16.36, elevation: 633 },
	{ lat: 7, lon: 17.09, elevation: 531 },
	{ lat: 7, lon: 17.82, elevation: 493 },
	{ lat: 7, lon: 18.55, elevation: 459 },
	{ lat: 7, lon: 19.27, elevation: 453 },
	{ lat: 7, lon: 20.0, elevation: 522 },
	{ lat: 7, lon: 20.73, elevation: 534 },
	{ lat: 7, lon: 21.45, elevation: 613 },
	{ lat: 7, lon: 22.18, elevation: 679 },
	{ lat: 7, lon: 22.91, elevation: 725 },
	{ lat: 7, lon: 23.64, elevation: 685 },
	{ lat: 7, lon: 24.36, elevation: 835 },
	{ lat: 7, lon: 25.09, elevation: 706 },
	{ lat: 7, lon: 25.82, elevation: 765 },
	{ lat: 7, lon: 26.55, elevation: 619 },
	{ lat: 7, lon: 27.27, elevation: 499 },
	{ lat: 7, lon: 28.0, elevation: 466 },
	{ lat: 7, lon: 28.73, elevation: 482 },
	{ lat: 7, lon: 29.45, elevation: 416 },
	{ lat: 7, lon: 30.18, elevation: 417 },
	{ lat: 7, lon: 30.91, elevation: 416 },
	{ lat: 7, lon: 31.64, elevation: 420 },
	{ lat: 7, lon: 32.36, elevation: 421 },
	{ lat: 7, lon: 33.09, elevation: 418 },
	{ lat: 7, lon: 33.82, elevation: 426 },
	{ lat: 7, lon: 34.55, elevation: 543 },
	{ lat: 7, lon: 35.27, elevation: 1185 },
	{ lat: 7, lon: 36.0, elevation: 856 },
	{ lat: 7, lon: 36.73, elevation: 1144 },
	{ lat: 7, lon: 37.45, elevation: 1129 },
	{ lat: 7, lon: 38.18, elevation: 1661 },
	{ lat: 7, lon: 38.91, elevation: 2557 },
	{ lat: 7, lon: 39.64, elevation: 3813 },
	{ lat: 7, lon: 40.36, elevation: 2126 },
	{ lat: 7, lon: 41.09, elevation: 1223 },
	{ lat: 7, lon: 41.82, elevation: 652 },
	{ lat: 7, lon: 42.55, elevation: 1078 },
	{ lat: 7, lon: 43.27, elevation: 627 },
	{ lat: 7, lon: 44.0, elevation: 885 },
	{ lat: 7, lon: 44.73, elevation: 806 },
	{ lat: 7, lon: 45.45, elevation: 551 },
	{ lat: 7, lon: 46.18, elevation: 469 },
	{ lat: 7, lon: 46.91, elevation: 379 },
	{ lat: 7, lon: 47.64, elevation: 314 },
	{ lat: 7, lon: 48.36, elevation: 207 },
	{ lat: 7, lon: 49.09, elevation: 76 },
	{ lat: 7, lon: 80.36, elevation: 121 },
	{ lat: 7, lon: 81.09, elevation: 843 },
	{ lat: 7, lon: 81.82, elevation: 53 },
	{ lat: 7, lon: 100.0, elevation: 163 },
	{ lat: 7, lon: 100.73, elevation: 9 },
	{ lat: 7, lon: 116.73, elevation: 0 },
	{ lat: 7, lon: 124.0, elevation: 112 },
	{ lat: 7, lon: 124.73, elevation: 11 },
	{ lat: 7, lon: 125.45, elevation: 223 },
	{ lat: 7, lon: 126.18, elevation: 315 },
	{ lat: 8, lon: -81.62, elevation: 19 },
	{ lat: 8, lon: -80.89, elevation: 169 },
	{ lat: 8, lon: -77.98, elevation: 170 },
	{ lat: 8, lon: -77.25, elevation: 900 },
	{ lat: 8, lon: -76.52, elevation: 354 },
	{ lat: 8, lon: -75.79, elevation: 117 },
	{ lat: 8, lon: -75.06, elevation: 41 },
	{ lat: 8, lon: -74.33, elevation: 748 },
	{ lat: 8, lon: -73.6, elevation: 68 },
	{ lat: 8, lon: -72.87, elevation: 938 },
	{ lat: 8, lon: -72.15, elevation: 2593 },
	{ lat: 8, lon: -71.42, elevation: 1208 },
	{ lat: 8, lon: -70.69, elevation: 132 },
	{ lat: 8, lon: -69.96, elevation: 107 },
	{ lat: 8, lon: -69.23, elevation: 77 },
	{ lat: 8, lon: -68.5, elevation: 71 },
	{ lat: 8, lon: -67.77, elevation: 52 },
	{ lat: 8, lon: -67.04, elevation: 47 },
	{ lat: 8, lon: -66.32, elevation: 48 },
	{ lat: 8, lon: -65.59, elevation: 60 },
	{ lat: 8, lon: -64.86, elevation: 109 },
	{ lat: 8, lon: -64.13, elevation: 17 },
	{ lat: 8, lon: -63.4, elevation: 125 },
	{ lat: 8, lon: -62.67, elevation: 444 },
	{ lat: 8, lon: -61.94, elevation: 328 },
	{ lat: 8, lon: -61.21, elevation: 62 },
	{ lat: 8, lon: -60.49, elevation: 199 },
	{ lat: 8, lon: -59.76, elevation: 20 },
	{ lat: 8, lon: -12.39, elevation: 61 },
	{ lat: 8, lon: -11.66, elevation: 103 },
	{ lat: 8, lon: -10.93, elevation: 189 },
	{ lat: 8, lon: -10.2, elevation: 463 },
	{ lat: 8, lon: -9.47, elevation: 470 },
	{ lat: 8, lon: -8.74, elevation: 447 },
	{ lat: 8, lon: -8.02, elevation: 468 },
	{ lat: 8, lon: -7.29, elevation: 498 },
	{ lat: 8, lon: -6.56, elevation: 309 },
	{ lat: 8, lon: -5.83, elevation: 280 },
	{ lat: 8, lon: -5.1, elevation: 276 },
	{ lat: 8, lon: -4.37, elevation: 208 },
	{ lat: 8, lon: -3.64, elevation: 224 },
	{ lat: 8, lon: -2.91, elevation: 322 },
	{ lat: 8, lon: -2.19, elevation: 184 },
	{ lat: 8, lon: -1.46, elevation: 305 },
	{ lat: 8, lon: -0.73, elevation: 114 },
	{ lat: 8, lon: 0.0, elevation: 150 },
	{ lat: 8, lon: 0.73, elevation: 593 },
	{ lat: 8, lon: 1.46, elevation: 211 },
	{ lat: 8, lon: 2.19, elevation: 175 },
	{ lat: 8, lon: 2.91, elevation: 219 },
	{ lat: 8, lon: 3.64, elevation: 279 },
	{ lat: 8, lon: 4.37, elevation: 377 },
	{ lat: 8, lon: 5.1, elevation: 542 },
	{ lat: 8, lon: 5.83, elevation: 331 },
	{ lat: 8, lon: 6.56, elevation: 240 },
	{ lat: 8, lon: 7.29, elevation: 46 },
	{ lat: 8, lon: 8.02, elevation: 67 },
	{ lat: 8, lon: 8.74, elevation: 101 },
	{ lat: 8, lon: 9.47, elevation: 122 },
	{ lat: 8, lon: 10.2, elevation: 166 },
	{ lat: 8, lon: 10.93, elevation: 176 },
	{ lat: 8, lon: 11.66, elevation: 711 },
	{ lat: 8, lon: 12.39, elevation: 468 },
	{ lat: 8, lon: 13.12, elevation: 382 },
	{ lat: 8, lon: 13.85, elevation: 484 },
	{ lat: 8, lon: 14.57, elevation: 714 },
	{ lat: 8, lon: 15.3, elevation: 548 },
	{ lat: 8, lon: 16.03, elevation: 489 },
	{ lat: 8, lon: 16.76, elevation: 460 },
	{ lat: 8, lon: 17.49, elevation: 409 },
	{ lat: 8, lon: 18.22, elevation: 403 },
	{ lat: 8, lon: 18.95, elevation: 407 },
	{ lat: 8, lon: 19.68, elevation: 429 },
	{ lat: 8, lon: 20.4, elevation: 436 },
	{ lat: 8, lon: 21.13, elevation: 735 },
	{ lat: 8, lon: 21.86, elevation: 762 },
	{ lat: 8, lon: 22.59, elevation: 830 },
	{ lat: 8, lon: 23.32, elevation: 776 },
	{ lat: 8, lon: 24.05, elevation: 892 },
	{ lat: 8, lon: 24.78, elevation: 739 },
	{ lat: 8, lon: 25.51, elevation: 711 },
	{ lat: 8, lon: 26.23, elevation: 588 },
	{ lat: 8, lon: 26.96, elevation: 578 },
	{ lat: 8, lon: 27.69, elevation: 462 },
	{ lat: 8, lon: 28.42, elevation: 412 },
	{ lat: 8, lon: 29.15, elevation: 407 },
	{ lat: 8, lon: 29.88, elevation: 396 },
	{ lat: 8, lon: 30.61, elevation: 403 },
	{ lat: 8, lon: 31.34, elevation: 408 },
	{ lat: 8, lon: 32.06, elevation: 409 },
	{ lat: 8, lon: 32.79, elevation: 408 },
	{ lat: 8, lon: 33.52, elevation: 415 },
	{ lat: 8, lon: 34.25, elevation: 434 },
	{ lat: 8, lon: 34.98, elevation: 1227 },
	{ lat: 8, lon: 35.71, elevation: 2032 },
	{ lat: 8, lon: 36.44, elevation: 1899 },
	{ lat: 8, lon: 37.17, elevation: 2380 },
	{ lat: 8, lon: 37.89, elevation: 2368 },
	{ lat: 8, lon: 38.62, elevation: 1764 },
	{ lat: 8, lon: 39.35, elevation: 2966 },
	{ lat: 8, lon: 40.08, elevation: 1371 },
	{ lat: 8, lon: 40.81, elevation: 1757 },
	{ lat: 8, lon: 41.54, elevation: 1002 },
	{ lat: 8, lon: 42.27, elevation: 1087 },
	{ lat: 8, lon: 43.0, elevation: 1021 },
	{ lat: 8, lon: 43.72, elevation: 954 },
	{ lat: 8, lon: 44.45, elevation: 972 },
	{ lat: 8, lon: 45.18, elevation: 759 },
	{ lat: 8, lon: 45.91, elevation: 709 },
	{ lat: 8, lon: 46.64, elevation: 599 },
	{ lat: 8, lon: 47.37, elevation: 652 },
	{ lat: 8, lon: 48.1, elevation: 724 },
	{ lat: 8, lon: 48.83, elevation: 627 },
	{ lat: 8, lon: 49.55, elevation: 285 },
	{ lat: 8, lon: 80.16, elevation: 67 },
	{ lat: 8, lon: 80.89, elevation: 111 },
	{ lat: 8, lon: 98.38, elevation: 22 },
	{ lat: 8, lon: 99.11, elevation: 31 },
	{ lat: 8, lon: 99.84, elevation: 36 },
	{ lat: 8, lon: 122.43, elevation: 123 },
	{ lat: 8, lon: 123.16, elevation: 556 },
	{ lat: 8, lon: 123.89, elevation: 107 },
	{ lat: 8, lon: 124.62, elevation: 1030 },
	{ lat: 8, lon: 125.34, elevation: 628 },
	{ lat: 8, lon: 126.07, elevation: 53 },
	{ lat: 9, lon: -83.41, elevation: 735 },
	{ lat: 9, lon: -82.68, elevation: 1451 },
	{ lat: 9, lon: -80.49, elevation: 157 },
	{ lat: 9, lon: -79.76, elevation: 138 },
	{ lat: 9, lon: -79.02, elevation: 39 },
	{ lat: 9, lon: -78.29, elevation: 86 },
	{ lat: 9, lon: -76.1, elevation: 62 },
	{ lat: 9, lon: -75.37, elevation: 81 },
	{ lat: 9, lon: -74.63, elevation: 11 },
	{ lat: 9, lon: -73.9, elevation: 22 },
	{ lat: 9, lon: -73.17, elevation: 589 },
	{ lat: 9, lon: -72.44, elevation: 13 },
	{ lat: 9, lon: -71.71, elevation: 4 },
	{ lat: 9, lon: -70.98, elevation: 1465 },
	{ lat: 9, lon: -70.24, elevation: 860 },
	{ lat: 9, lon: -69.51, elevation: 117 },
	{ lat: 9, lon: -68.78, elevation: 78 },
	{ lat: 9, lon: -68.05, elevation: 77 },
	{ lat: 9, lon: -67.32, elevation: 114 },
	{ lat: 9, lon: -66.59, elevation: 130 },
	{ lat: 9, lon: -65.85, elevation: 118 },
	{ lat: 9, lon: -65.12, elevation: 100 },
	{ lat: 9, lon: -64.39, elevation: 301 },
	{ lat: 9, lon: -63.66, elevation: 154 },
	{ lat: 9, lon: -62.93, elevation: 57 },
	{ lat: 9, lon: -62.2, elevation: 3 },
	{ lat: 9, lon: -61.46, elevation: 4 },
	{ lat: 9, lon: -13.17, elevation: 11 },
	{ lat: 9, lon: -12.44, elevation: 51 },
	{ lat: 9, lon: -11.71, elevation: 399 },
	{ lat: 9, lon: -10.98, elevation: 423 },
	{ lat: 9, lon: -10.24, elevation: 500 },
	{ lat: 9, lon: -9.51, elevation: 822 },
	{ lat: 9, lon: -8.78, elevation: 619 },
	{ lat: 9, lon: -8.05, elevation: 599 },
	{ lat: 9, lon: -7.32, elevation: 397 },
	{ lat: 9, lon: -6.59, elevation: 356 },
	{ lat: 9, lon: -5.85, elevation: 392 },
	{ lat: 9, lon: -5.12, elevation: 337 },
	{ lat: 9, lon: -4.39, elevation: 319 },
	{ lat: 9, lon: -3.66, elevation: 267 },
	{ lat: 9, lon: -2.93, elevation: 283 },
	{ lat: 9, lon: -2.2, elevation: 269 },
	{ lat: 9, lon: -1.46, elevation: 144 },
	{ lat: 9, lon: -0.73, elevation: 99 },
	{ lat: 9, lon: 0.0, elevation: 208 },
	{ lat: 9, lon: 0.73, elevation: 196 },
	{ lat: 9, lon: 1.46, elevation: 333 },
	{ lat: 9, lon: 2.2, elevation: 251 },
	{ lat: 9, lon: 2.93, elevation: 321 },
	{ lat: 9, lon: 3.66, elevation: 377 },
	{ lat: 9, lon: 4.39, elevation: 296 },
	{ lat: 9, lon: 5.12, elevation: 138 },
	{ lat: 9, lon: 5.85, elevation: 107 },
	{ lat: 9, lon: 6.59, elevation: 131 },
	{ lat: 9, lon: 7.32, elevation: 361 },
	{ lat: 9, lon: 8.05, elevation: 376 },
	{ lat: 9, lon: 8.78, elevation: 246 },
	{ lat: 9, lon: 9.51, elevation: 267 },
	{ lat: 9, lon: 10.24, elevation: 178 },
	{ lat: 9, lon: 10.98, elevation: 120 },
	{ lat: 9, lon: 11.71, elevation: 574 },
	{ lat: 9, lon: 12.44, elevation: 528 },
	{ lat: 9, lon: 13.17, elevation: 217 },
	{ lat: 9, lon: 13.9, elevation: 224 },
	{ lat: 9, lon: 14.63, elevation: 477 },
	{ lat: 9, lon: 15.37, elevation: 421 },
	{ lat: 9, lon: 16.1, elevation: 450 },
	{ lat: 9, lon: 16.83, elevation: 427 },
	{ lat: 9, lon: 17.56, elevation: 538 },
	{ lat: 9, lon: 18.29, elevation: 364 },
	{ lat: 9, lon: 19.02, elevation: 377 },
	{ lat: 9, lon: 19.76, elevation: 387 },
	{ lat: 9, lon: 20.49, elevation: 441 },
	{ lat: 9, lon: 21.22, elevation: 439 },
	{ lat: 9, lon: 21.95, elevation: 499 },
	{ lat: 9, lon: 22.68, elevation: 571 },
	{ lat: 9, lon: 23.41, elevation: 904 },
	{ lat: 9, lon: 24.15, elevation: 674 },
	{ lat: 9, lon: 24.88, elevation: 651 },
	{ lat: 9, lon: 25.61, elevation: 554 },
	{ lat: 9, lon: 26.34, elevation: 479 },
	{ lat: 9, lon: 27.07, elevation: 462 },
	{ lat: 9, lon: 27.8, elevation: 413 },
	{ lat: 9, lon: 28.54, elevation: 404 },
	{ lat: 9, lon: 29.27, elevation: 391 },
	{ lat: 9, lon: 30.0, elevation: 396 },
	{ lat: 9, lon: 30.73, elevation: 400 },
	{ lat: 9, lon: 31.46, elevation: 407 },
	{ lat: 9, lon: 32.2, elevation: 402 },
	{ lat: 9, lon: 32.93, elevation: 400 },
	{ lat: 9, lon: 33.66, elevation: 421 },
	{ lat: 9, lon: 34.39, elevation: 1146 },
	{ lat: 9, lon: 35.12, elevation: 1847 },
	{ lat: 9, lon: 35.85, elevation: 1867 },
	{ lat: 9, lon: 36.59, elevation: 1933 },
	{ lat: 9, lon: 37.32, elevation: 1716 },
	{ lat: 9, lon: 38.05, elevation: 2384 },
	{ lat: 9, lon: 38.78, elevation: 2330 },
	{ lat: 9, lon: 39.51, elevation: 1735 },
	{ lat: 9, lon: 40.24, elevation: 978 },
	{ lat: 9, lon: 40.98, elevation: 2041 },
	{ lat: 9, lon: 41.71, elevation: 1836 },
	{ lat: 9, lon: 42.44, elevation: 1534 },
	{ lat: 9, lon: 43.17, elevation: 1536 },
	{ lat: 9, lon: 43.9, elevation: 1380 },
	{ lat: 9, lon: 44.63, elevation: 1112 },
	{ lat: 9, lon: 45.37, elevation: 867 },
	{ lat: 9, lon: 46.1, elevation: 931 },
	{ lat: 9, lon: 46.83, elevation: 630 },
	{ lat: 9, lon: 47.56, elevation: 560 },
	{ lat: 9, lon: 48.29, elevation: 604 },
	{ lat: 9, lon: 49.02, elevation: 762 },
	{ lat: 9, lon: 49.76, elevation: 465 },
	{ lat: 9, lon: 50.49, elevation: 166 },
	{ lat: 9, lon: 76.83, elevation: 54 },
	{ lat: 9, lon: 77.56, elevation: 133 },
	{ lat: 9, lon: 80.49, elevation: 83 },
	{ lat: 9, lon: 98.78, elevation: 140 },
	{ lat: 9, lon: 99.51, elevation: 40 },
	{ lat: 9, lon: 105.37, elevation: 2 },
	{ lat: 9, lon: 117.8, elevation: 381 },
	{ lat: 9, lon: 125.85, elevation: 345 },
	{ lat: 10, lon: -85.42, elevation: 475 },
	{ lat: 10, lon: -84.68, elevation: 131 },
	{ lat: 10, lon: -83.95, elevation: 1825 },
	{ lat: 10, lon: -83.22, elevation: 82 },
	{ lat: 10, lon: -75.15, elevation: 277 },
	{ lat: 10, lon: -74.42, elevation: 125 },
	{ lat: 10, lon: -73.69, elevation: 75 },
	{ lat: 10, lon: -72.95, elevation: 2927 },
	{ lat: 10, lon: -72.22, elevation: 30 },
	{ lat: 10, lon: -70.75, elevation: 429 },
	{ lat: 10, lon: -70.02, elevation: 998 },
	{ lat: 10, lon: -69.29, elevation: 693 },
	{ lat: 10, lon: -68.55, elevation: 997 },
	{ lat: 10, lon: -67.82, elevation: 916 },
	{ lat: 10, lon: -67.09, elevation: 751 },
	{ lat: 10, lon: -66.35, elevation: 1208 },
	{ lat: 10, lon: -65.62, elevation: 306 },
	{ lat: 10, lon: -64.89, elevation: 66 },
	{ lat: 10, lon: -64.15, elevation: 1137 },
	{ lat: 10, lon: -63.42, elevation: 359 },
	{ lat: 10, lon: -62.69, elevation: 4 },
	{ lat: 10, lon: -13.56, elevation: 259 },
	{ lat: 10, lon: -12.83, elevation: 338 },
	{ lat: 10, lon: -12.1, elevation: 266 },
	{ lat: 10, lon: -11.36, elevation: 551 },
	{ lat: 10, lon: -10.63, elevation: 451 },
	{ lat: 10, lon: -9.9, elevation: 487 },
	{ lat: 10, lon: -9.16, elevation: 406 },
	{ lat: 10, lon: -8.43, elevation: 442 },
	{ lat: 10, lon: -7.7, elevation: 430 },
	{ lat: 10, lon: -6.97, elevation: 399 },
	{ lat: 10, lon: -6.23, elevation: 371 },
	{ lat: 10, lon: -5.5, elevation: 374 },
	{ lat: 10, lon: -4.77, elevation: 288 },
	{ lat: 10, lon: -4.03, elevation: 348 },
	{ lat: 10, lon: -3.3, elevation: 327 },
	{ lat: 10, lon: -2.57, elevation: 281 },
	{ lat: 10, lon: -1.83, elevation: 195 },
	{ lat: 10, lon: -1.1, elevation: 134 },
	{ lat: 10, lon: -0.37, elevation: 166 },
	{ lat: 10, lon: 0.37, elevation: 113 },
	{ lat: 10, lon: 1.1, elevation: 192 },
	{ lat: 10, lon: 1.83, elevation: 387 },
	{ lat: 10, lon: 2.57, elevation: 400 },
	{ lat: 10, lon: 3.3, elevation: 400 },
	{ lat: 10, lon: 4.03, elevation: 290 },
	{ lat: 10, lon: 4.77, elevation: 255 },
	{ lat: 10, lon: 5.5, elevation: 336 },
	{ lat: 10, lon: 6.23, elevation: 162 },
	{ lat: 10, lon: 6.97, elevation: 425 },
	{ lat: 10, lon: 7.7, elevation: 654 },
	{ lat: 10, lon: 8.43, elevation: 746 },
	{ lat: 10, lon: 9.16, elevation: 1389 },
	{ lat: 10, lon: 9.9, elevation: 546 },
	{ lat: 10, lon: 10.63, elevation: 258 },
	{ lat: 10, lon: 11.36, elevation: 328 },
	{ lat: 10, lon: 12.1, elevation: 314 },
	{ lat: 10, lon: 12.83, elevation: 454 },
	{ lat: 10, lon: 13.56, elevation: 566 },
	{ lat: 10, lon: 14.3, elevation: 365 },
	{ lat: 10, lon: 15.03, elevation: 349 },
	{ lat: 10, lon: 15.76, elevation: 339 },
	{ lat: 10, lon: 16.5, elevation: 334 },
	{ lat: 10, lon: 17.23, elevation: 346 },
	{ lat: 10, lon: 17.96, elevation: 365 },
	{ lat: 10, lon: 18.7, elevation: 382 },
	{ lat: 10, lon: 19.43, elevation: 400 },
	{ lat: 10, lon: 20.16, elevation: 406 },
	{ lat: 10, lon: 20.9, elevation: 416 },
	{ lat: 10, lon: 21.63, elevation: 422 },
	{ lat: 10, lon: 22.36, elevation: 482 },
	{ lat: 10, lon: 23.1, elevation: 501 },
	{ lat: 10, lon: 23.83, elevation: 533 },
	{ lat: 10, lon: 24.56, elevation: 547 },
	{ lat: 10, lon: 25.3, elevation: 491 },
	{ lat: 10, lon: 26.03, elevation: 444 },
	{ lat: 10, lon: 26.76, elevation: 423 },
	{ lat: 10, lon: 27.49, elevation: 416 },
	{ lat: 10, lon: 28.23, elevation: 410 },
	{ lat: 10, lon: 28.96, elevation: 402 },
	{ lat: 10, lon: 29.69, elevation: 407 },
	{ lat: 10, lon: 30.43, elevation: 417 },
	{ lat: 10, lon: 31.16, elevation: 409 },
	{ lat: 10, lon: 31.89, elevation: 397 },
	{ lat: 10, lon: 32.63, elevation: 394 },
	{ lat: 10, lon: 33.36, elevation: 416 },
	{ lat: 10, lon: 34.09, elevation: 546 },
	{ lat: 10, lon: 34.83, elevation: 1216 },
	{ lat: 10, lon: 35.56, elevation: 861 },
	{ lat: 10, lon: 36.29, elevation: 1812 },
	{ lat: 10, lon: 37.03, elevation: 2375 },
	{ lat: 10, lon: 37.76, elevation: 1752 },
	{ lat: 10, lon: 38.49, elevation: 2098 },
	{ lat: 10, lon: 39.23, elevation: 2648 },
	{ lat: 10, lon: 39.96, elevation: 1185 },
	{ lat: 10, lon: 40.69, elevation: 815 },
	{ lat: 10, lon: 41.43, elevation: 751 },
	{ lat: 10, lon: 42.16, elevation: 842 },
	{ lat: 10, lon: 42.89, elevation: 1304 },
	{ lat: 10, lon: 43.63, elevation: 1284 },
	{ lat: 10, lon: 44.36, elevation: 661 },
	{ lat: 10, lon: 45.09, elevation: 1024 },
	{ lat: 10, lon: 45.82, elevation: 641 },
	{ lat: 10, lon: 46.56, elevation: 746 },
	{ lat: 10, lon: 47.29, elevation: 1182 },
	{ lat: 10, lon: 48.02, elevation: 1015 },
	{ lat: 10, lon: 48.76, elevation: 1032 },
	{ lat: 10, lon: 49.49, elevation: 762 },
	{ lat: 10, lon: 50.22, elevation: 511 },
	{ lat: 10, lon: 76.62, elevation: 16 },
	{ lat: 10, lon: 77.35, elevation: 350 },
	{ lat: 10, lon: 78.09, elevation: 159 },
	{ lat: 10, lon: 78.82, elevation: 61 },
	{ lat: 10, lon: 105.21, elevation: 2 },
	{ lat: 10, lon: 105.95, elevation: 6 },
	{ lat: 10, lon: 122.81, elevation: 8 },
	{ lat: 10, lon: 123.54, elevation: 247 },
	{ lat: 10, lon: 124.28, elevation: 127 },
	{ lat: 11, lon: -85.03, elevation: 39 },
	{ lat: 11, lon: -84.29, elevation: 115 },
	{ lat: 11, lon: -74.72, elevation: 5 },
	{ lat: 11, lon: -73.99, elevation: 2286 },
	{ lat: 11, lon: -73.25, elevation: 1807 },
	{ lat: 11, lon: -72.52, elevation: 821 },
	{ lat: 11, lon: -71.04, elevation: 6 },
	{ lat: 11, lon: -70.31, elevation: 205 },
	{ lat: 11, lon: -69.57, elevation: 449 },
	{ lat: 11, lon: -68.83, elevation: 142 },
	{ lat: 11, lon: -15.09, elevation: 1 },
	{ lat: 11, lon: -14.36, elevation: 60 },
	{ lat: 11, lon: -13.62, elevation: 333 },
	{ lat: 11, lon: -12.88, elevation: 283 },
	{ lat: 11, lon: -12.15, elevation: 700 },
	{ lat: 11, lon: -11.41, elevation: 623 },
	{ lat: 11, lon: -10.67, elevation: 398 },
	{ lat: 11, lon: -9.94, elevation: 449 },
	{ lat: 11, lon: -9.2, elevation: 351 },
	{ lat: 11, lon: -8.47, elevation: 398 },
	{ lat: 11, lon: -7.73, elevation: 360 },
	{ lat: 11, lon: -6.99, elevation: 343 },
	{ lat: 11, lon: -6.26, elevation: 323 },
	{ lat: 11, lon: -5.52, elevation: 456 },
	{ lat: 11, lon: -4.79, elevation: 469 },
	{ lat: 11, lon: -4.05, elevation: 320 },
	{ lat: 11, lon: -3.31, elevation: 305 },
	{ lat: 11, lon: -2.58, elevation: 305 },
	{ lat: 11, lon: -1.84, elevation: 332 },
	{ lat: 11, lon: -1.1, elevation: 209 },
	{ lat: 11, lon: -0.37, elevation: 199 },
	{ lat: 11, lon: 0.37, elevation: 225 },
	{ lat: 11, lon: 1.1, elevation: 153 },
	{ lat: 11, lon: 1.84, elevation: 450 },
	{ lat: 11, lon: 2.58, elevation: 295 },
	{ lat: 11, lon: 3.31, elevation: 286 },
	{ lat: 11, lon: 4.05, elevation: 310 },
	{ lat: 11, lon: 4.79, elevation: 170 },
	{ lat: 11, lon: 5.52, elevation: 314 },
	{ lat: 11, lon: 6.26, elevation: 431 },
	{ lat: 11, lon: 6.99, elevation: 634 },
	{ lat: 11, lon: 7.73, elevation: 636 },
	{ lat: 11, lon: 8.47, elevation: 670 },
	{ lat: 11, lon: 9.2, elevation: 678 },
	{ lat: 11, lon: 9.94, elevation: 519 },
	{ lat: 11, lon: 10.67, elevation: 333 },
	{ lat: 11, lon: 11.41, elevation: 295 },
	{ lat: 11, lon: 12.15, elevation: 525 },
	{ lat: 11, lon: 12.88, elevation: 424 },
	{ lat: 11, lon: 13.62, elevation: 433 },
	{ lat: 11, lon: 14.36, elevation: 348 },
	{ lat: 11, lon: 15.09, elevation: 309 },
	{ lat: 11, lon: 15.83, elevation: 324 },
	{ lat: 11, lon: 16.56, elevation: 332 },
	{ lat: 11, lon: 17.3, elevation: 342 },
	{ lat: 11, lon: 18.04, elevation: 378 },
	{ lat: 11, lon: 18.77, elevation: 443 },
	{ lat: 11, lon: 19.51, elevation: 428 },
	{ lat: 11, lon: 20.25, elevation: 435 },
	{ lat: 11, lon: 20.98, elevation: 452 },
	{ lat: 11, lon: 21.72, elevation: 455 },
	{ lat: 11, lon: 22.45, elevation: 480 },
	{ lat: 11, lon: 23.19, elevation: 565 },
	{ lat: 11, lon: 23.93, elevation: 537 },
	{ lat: 11, lon: 24.66, elevation: 513 },
	{ lat: 11, lon: 25.4, elevation: 462 },
	{ lat: 11, lon: 26.13, elevation: 452 },
	{ lat: 11, lon: 26.87, elevation: 438 },
	{ lat: 11, lon: 27.61, elevation: 427 },
	{ lat: 11, lon: 28.34, elevation: 482 },
	{ lat: 11, lon: 29.08, elevation: 498 },
	{ lat: 11, lon: 29.82, elevation: 491 },
	{ lat: 11, lon: 30.55, elevation: 663 },
	{ lat: 11, lon: 31.29, elevation: 536 },
	{ lat: 11, lon: 32.02, elevation: 410 },
	{ lat: 11, lon: 32.76, elevation: 393 },
	{ lat: 11, lon: 33.5, elevation: 484 },
	{ lat: 11, lon: 34.23, elevation: 646 },
	{ lat: 11, lon: 34.97, elevation: 737 },
	{ lat: 11, lon: 35.71, elevation: 846 },
	{ lat: 11, lon: 36.44, elevation: 1740 },
	{ lat: 11, lon: 37.18, elevation: 2634 },
	{ lat: 11, lon: 37.91, elevation: 2451 },
	{ lat: 11, lon: 38.65, elevation: 1809 },
	{ lat: 11, lon: 39.39, elevation: 3328 },
	{ lat: 11, lon: 40.12, elevation: 1092 },
	{ lat: 11, lon: 40.86, elevation: 449 },
	{ lat: 11, lon: 41.6, elevation: 433 },
	{ lat: 11, lon: 42.33, elevation: 514 },
	{ lat: 11, lon: 43.07, elevation: 302 },
	{ lat: 11, lon: 47.48, elevation: 338 },
	{ lat: 11, lon: 48.22, elevation: 919 },
	{ lat: 11, lon: 48.96, elevation: 1329 },
	{ lat: 11, lon: 49.69, elevation: 1468 },
	{ lat: 11, lon: 50.43, elevation: 648 },
	{ lat: 11, lon: 76.2, elevation: 84 },
	{ lat: 11, lon: 76.93, elevation: 421 },
	{ lat: 11, lon: 77.67, elevation: 254 },
	{ lat: 11, lon: 78.4, elevation: 97 },
	{ lat: 11, lon: 79.14, elevation: 55 },
	{ lat: 11, lon: 99.02, elevation: 165 },
	{ lat: 11, lon: 104.17, elevation: 77 },
	{ lat: 11, lon: 104.91, elevation: 0 },
	{ lat: 11, lon: 105.64, elevation: 5 },
	{ lat: 11, lon: 106.38, elevation: 7 },
	{ lat: 11, lon: 107.12, elevation: 105 },
	{ lat: 11, lon: 107.85, elevation: 96 },
	{ lat: 11, lon: 122.58, elevation: 45 },
	{ lat: 11, lon: 124.79, elevation: 735 },
	{ lat: 12, lon: -86.31, elevation: 809 },
	{ lat: 12, lon: -85.57, elevation: 31 },
	{ lat: 12, lon: -84.84, elevation: 323 },
	{ lat: 12, lon: -84.1, elevation: 14 },
	{ lat: 12, lon: -71.56, elevation: 116 },
	{ lat: 12, lon: -70.08, elevation: 65 },
	{ lat: 12, lon: -16.23, elevation: 5 },
	{ lat: 12, lon: -15.49, elevation: 27 },
	{ lat: 12, lon: -14.75, elevation: 42 },
	{ lat: 12, lon: -14.02, elevation: 88 },
	{ lat: 12, lon: -13.28, elevation: 85 },
	{ lat: 12, lon: -12.54, elevation: 465 },
	{ lat: 12, lon: -11.8, elevation: 250 },
	{ lat: 12, lon: -11.07, elevation: 586 },
	{ lat: 12, lon: -10.33, elevation: 330 },
	{ lat: 12, lon: -9.59, elevation: 537 },
	{ lat: 12, lon: -8.85, elevation: 354 },
	{ lat: 12, lon: -8.11, elevation: 364 },
	{ lat: 12, lon: -7.38, elevation: 357 },
	{ lat: 12, lon: -6.64, elevation: 344 },
	{ lat: 12, lon: -5.9, elevation: 333 },
	{ lat: 12, lon: -5.16, elevation: 310 },
	{ lat: 12, lon: -4.43, elevation: 346 },
	{ lat: 12, lon: -3.69, elevation: 374 },
	{ lat: 12, lon: -2.95, elevation: 265 },
	{ lat: 12, lon: -2.21, elevation: 324 },
	{ lat: 12, lon: -1.48, elevation: 340 },
	{ lat: 12, lon: -0.74, elevation: 280 },
	{ lat: 12, lon: 0.0, elevation: 310 },
	{ lat: 12, lon: 0.74, elevation: 267 },
	{ lat: 12, lon: 1.48, elevation: 281 },
	{ lat: 12, lon: 2.21, elevation: 255 },
	{ lat: 12, lon: 2.95, elevation: 224 },
	{ lat: 12, lon: 3.69, elevation: 248 },
	{ lat: 12, lon: 4.43, elevation: 268 },
	{ lat: 12, lon: 5.16, elevation: 292 },
	{ lat: 12, lon: 5.9, elevation: 398 },
	{ lat: 12, lon: 6.64, elevation: 515 },
	{ lat: 12, lon: 7.38, elevation: 551 },
	{ lat: 12, lon: 8.11, elevation: 535 },
	{ lat: 12, lon: 8.85, elevation: 411 },
	{ lat: 12, lon: 9.59, elevation: 391 },
	{ lat: 12, lon: 10.33, elevation: 374 },
	{ lat: 12, lon: 11.07, elevation: 376 },
	{ lat: 12, lon: 11.8, elevation: 364 },
	{ lat: 12, lon: 12.54, elevation: 350 },
	{ lat: 12, lon: 13.28, elevation: 305 },
	{ lat: 12, lon: 14.02, elevation: 295 },
	{ lat: 12, lon: 14.75, elevation: 292 },
	{ lat: 12, lon: 15.49, elevation: 301 },
	{ lat: 12, lon: 16.23, elevation: 302 },
	{ lat: 12, lon: 16.97, elevation: 318 },
	{ lat: 12, lon: 17.7, elevation: 347 },
	{ lat: 12, lon: 18.44, elevation: 466 },
	{ lat: 12, lon: 19.18, elevation: 488 },
	{ lat: 12, lon: 19.92, elevation: 432 },
	{ lat: 12, lon: 20.66, elevation: 473 },
	{ lat: 12, lon: 21.39, elevation: 513 },
	{ lat: 12, lon: 22.13, elevation: 561 },
	{ lat: 12, lon: 22.87, elevation: 617 },
	{ lat: 12, lon: 23.61, elevation: 839 },
	{ lat: 12, lon: 24.34, elevation: 740 },
	{ lat: 12, lon: 25.08, elevation: 602 },
	{ lat: 12, lon: 25.82, elevation: 523 },
	{ lat: 12, lon: 26.56, elevation: 505 },
	{ lat: 12, lon: 27.3, elevation: 491 },
	{ lat: 12, lon: 28.03, elevation: 485 },
	{ lat: 12, lon: 28.77, elevation: 537 },
	{ lat: 12, lon: 29.51, elevation: 824 },
	{ lat: 12, lon: 30.25, elevation: 646 },
	{ lat: 12, lon: 30.98, elevation: 736 },
	{ lat: 12, lon: 31.72, elevation: 531 },
	{ lat: 12, lon: 32.46, elevation: 393 },
	{ lat: 12, lon: 33.2, elevation: 441 },
	{ lat: 12, lon: 33.93, elevation: 499 },
	{ lat: 12, lon: 34.67, elevation: 501 },
	{ lat: 12, lon: 35.41, elevation: 567 },
	{ lat: 12, lon: 36.15, elevation: 737 },
	{ lat: 12, lon: 36.89, elevation: 2271 },
	{ lat: 12, lon: 37.62, elevation: 1790 },
	{ lat: 12, lon: 38.36, elevation: 1855 },
	{ lat: 12, lon: 39.1, elevation: 2237 },
	{ lat: 12, lon: 39.84, elevation: 1345 },
	{ lat: 12, lon: 40.57, elevation: 695 },
	{ lat: 12, lon: 41.31, elevation: 510 },
	{ lat: 12, lon: 42.05, elevation: 724 },
	{ lat: 12, lon: 42.79, elevation: 1085 },
	{ lat: 12, lon: 75.98, elevation: 863 },
	{ lat: 12, lon: 76.72, elevation: 709 },
	{ lat: 12, lon: 77.46, elevation: 506 },
	{ lat: 12, lon: 78.2, elevation: 652 },
	{ lat: 12, lon: 78.93, elevation: 167 },
	{ lat: 12, lon: 79.67, elevation: 18 },
	{ lat: 12, lon: 98.85, elevation: 48 },
	{ lat: 12, lon: 99.59, elevation: 688 },
	{ lat: 12, lon: 103.28, elevation: 582 },
	{ lat: 12, lon: 104.02, elevation: 189 },
	{ lat: 12, lon: 104.75, elevation: 17 },
	{ lat: 12, lon: 105.49, elevation: 15 },
	{ lat: 12, lon: 106.23, elevation: 49 },
	{ lat: 12, lon: 106.97, elevation: 130 },
	{ lat: 12, lon: 107.7, elevation: 646 },
	{ lat: 12, lon: 108.44, elevation: 1508 },
	{ lat: 12, lon: 109.18, elevation: 13 },
	{ lat: 12, lon: 120.25, elevation: 5 },
	{ lat: 12, lon: 123.2, elevation: 23 },
	{ lat: 12, lon: 123.93, elevation: 109 },
	{ lat: 12, lon: 125.41, elevation: 54 },
	{ lat: 13, lon: -86.67, elevation: 331 },
	{ lat: 13, lon: -85.93, elevation: 1242 },
	{ lat: 13, lon: -85.19, elevation: 300 },
	{ lat: 13, lon: -84.44, elevation: 72 },
	{ lat: 13, lon: -83.7, elevation: 34 },
	{ lat: 13, lon: -16.3, elevation: 19 },
	{ lat: 13, lon: -15.56, elevation: 45 },
	{ lat: 13, lon: -14.81, elevation: 45 },
	{ lat: 13, lon: -14.07, elevation: 36 },
	{ lat: 13, lon: -13.33, elevation: 75 },
	{ lat: 13, lon: -12.59, elevation: 152 },
	{ lat: 13, lon: -11.85, elevation: 139 },
	{ lat: 13, lon: -11.11, elevation: 454 },
	{ lat: 13, lon: -10.37, elevation: 207 },
	{ lat: 13, lon: -9.63, elevation: 329 },
	{ lat: 13, lon: -8.89, elevation: 457 },
	{ lat: 13, lon: -8.15, elevation: 417 },
	{ lat: 13, lon: -7.41, elevation: 300 },
	{ lat: 13, lon: -6.67, elevation: 328 },
	{ lat: 13, lon: -5.93, elevation: 313 },
	{ lat: 13, lon: -5.19, elevation: 289 },
	{ lat: 13, lon: -4.44, elevation: 319 },
	{ lat: 13, lon: -3.7, elevation: 262 },
	{ lat: 13, lon: -2.96, elevation: 309 },
	{ lat: 13, lon: -2.22, elevation: 322 },
	{ lat: 13, lon: -1.48, elevation: 288 },
	{ lat: 13, lon: -0.74, elevation: 321 },
	{ lat: 13, lon: 0.0, elevation: 295 },
	{ lat: 13, lon: 0.74, elevation: 250 },
	{ lat: 13, lon: 1.48, elevation: 246 },
	{ lat: 13, lon: 2.22, elevation: 242 },
	{ lat: 13, lon: 2.96, elevation: 209 },
	{ lat: 13, lon: 3.7, elevation: 245 },
	{ lat: 13, lon: 4.44, elevation: 251 },
	{ lat: 13, lon: 5.19, elevation: 287 },
	{ lat: 13, lon: 5.93, elevation: 303 },
	{ lat: 13, lon: 6.67, elevation: 380 },
	{ lat: 13, lon: 7.41, elevation: 461 },
	{ lat: 13, lon: 8.15, elevation: 464 },
	{ lat: 13, lon: 8.89, elevation: 402 },
	{ lat: 13, lon: 9.63, elevation: 365 },
	{ lat: 13, lon: 10.37, elevation: 345 },
	{ lat: 13, lon: 11.11, elevation: 339 },
	{ lat: 13, lon: 11.85, elevation: 330 },
	{ lat: 13, lon: 12.59, elevation: 309 },
	{ lat: 13, lon: 13.33, elevation: 293 },
	{ lat: 13, lon: 14.07, elevation: 280 },
	{ lat: 13, lon: 14.81, elevation: 281 },
	{ lat: 13, lon: 15.56, elevation: 289 },
	{ lat: 13, lon: 16.3, elevation: 291 },
	{ lat: 13, lon: 17.04, elevation: 288 },
	{ lat: 13, lon: 17.78, elevation: 300 },
	{ lat: 13, lon: 18.52, elevation: 339 },
	{ lat: 13, lon: 19.26, elevation: 369 },
	{ lat: 13, lon: 20.0, elevation: 429 },
	{ lat: 13, lon: 20.74, elevation: 475 },
	{ lat: 13, lon: 21.48, elevation: 592 },
	{ lat: 13, lon: 22.22, elevation: 903 },
	{ lat: 13, lon: 22.96, elevation: 736 },
	{ lat: 13, lon: 23.7, elevation: 953 },
	{ lat: 13, lon: 24.44, elevation: 1482 },
	{ lat: 13, lon: 25.19, elevation: 723 },
	{ lat: 13, lon: 25.93, elevation: 644 },
	{ lat: 13, lon: 26.67, elevation: 609 },
	{ lat: 13, lon: 27.41, elevation: 541 },
	{ lat: 13, lon: 28.15, elevation: 541 },
	{ lat: 13, lon: 28.89, elevation: 629 },
	{ lat: 13, lon: 29.63, elevation: 690 },
	{ lat: 13, lon: 30.37, elevation: 537 },
	{ lat: 13, lon: 31.11, elevation: 461 },
	{ lat: 13, lon: 31.85, elevation: 423 },
	{ lat: 13, lon: 32.59, elevation: 390 },
	{ lat: 13, lon: 33.33, elevation: 424 },
	{ lat: 13, lon: 34.07, elevation: 441 },
	{ lat: 13, lon: 34.81, elevation: 453 },
	{ lat: 13, lon: 35.56, elevation: 507 },
	{ lat: 13, lon: 36.3, elevation: 733 },
	{ lat: 13, lon: 37.04, elevation: 1051 },
	{ lat: 13, lon: 37.78, elevation: 2627 },
	{ lat: 13, lon: 38.52, elevation: 1566 },
	{ lat: 13, lon: 39.26, elevation: 1778 },
	{ lat: 13, lon: 40.0, elevation: 1109 },
	{ lat: 13, lon: 40.74, elevation: 184 },
	{ lat: 13, lon: 41.48, elevation: 6 },
	{ lat: 13, lon: 42.22, elevation: 406 },
	{ lat: 13, lon: 43.7, elevation: 357 },
	{ lat: 13, lon: 44.44, elevation: 432 },
	{ lat: 13, lon: 45.19, elevation: 0 },
	{ lat: 13, lon: 74.81, elevation: 26 },
	{ lat: 13, lon: 75.56, elevation: 803 },
	{ lat: 13, lon: 76.3, elevation: 937 },
	{ lat: 13, lon: 77.04, elevation: 778 },
	{ lat: 13, lon: 77.78, elevation: 859 },
	{ lat: 13, lon: 78.52, elevation: 695 },
	{ lat: 13, lon: 79.26, elevation: 197 },
	{ lat: 13, lon: 80.0, elevation: 37 },
	{ lat: 13, lon: 99.26, elevation: 506 },
	{ lat: 13, lon: 100.0, elevation: 7 },
	{ lat: 13, lon: 101.48, elevation: 42 },
	{ lat: 13, lon: 102.22, elevation: 565 },
	{ lat: 13, lon: 102.96, elevation: 29 },
	{ lat: 13, lon: 103.7, elevation: 6 },
	{ lat: 13, lon: 104.44, elevation: 9 },
	{ lat: 13, lon: 105.19, elevation: 30 },
	{ lat: 13, lon: 105.93, elevation: 65 },
	{ lat: 13, lon: 106.67, elevation: 125 },
	{ lat: 13, lon: 107.41, elevation: 221 },
	{ lat: 13, lon: 108.15, elevation: 613 },
	{ lat: 13, lon: 108.89, elevation: 176 },
	{ lat: 13, lon: 121.48, elevation: 8 },
	{ lat: 13, lon: 123.7, elevation: 28 },
	{ lat: 14, lon: -90.74, elevation: 15 },
	{ lat: 14, lon: -90.0, elevation: 611 },
	{ lat: 14, lon: -89.26, elevation: 447 },
	{ lat: 14, lon: -88.51, elevation: 456 },
	{ lat: 14, lon: -87.77, elevation: 1126 },
	{ lat: 14, lon: -87.02, elevation: 833 },
	{ lat: 14, lon: -86.28, elevation: 703 },
	{ lat: 14, lon: -85.54, elevation: 596 },
	{ lat: 14, lon: -84.79, elevation: 554 },
	{ lat: 14, lon: -84.05, elevation: 39 },
	{ lat: 14, lon: -60.99, elevation: 157 },
	{ lat: 14, lon: -16.36, elevation: 6 },
	{ lat: 14, lon: -15.62, elevation: 30 },
	{ lat: 14, lon: -14.88, elevation: 50 },
	{ lat: 14, lon: -14.13, elevation: 50 },
	{ lat: 14, lon: -13.39, elevation: 55 },
	{ lat: 14, lon: -12.64, elevation: 78 },
	{ lat: 14, lon: -11.9, elevation: 98 },
	{ lat: 14, lon: -11.16, elevation: 84 },
	{ lat: 14, lon: -10.41, elevation: 354 },
	{ lat: 14, lon: -9.67, elevation: 232 },
	{ lat: 14, lon: -8.93, elevation: 233 },
	{ lat: 14, lon: -8.18, elevation: 378 },
	{ lat: 14, lon: -7.44, elevation: 376 },
	{ lat: 14, lon: -6.69, elevation: 289 },
	{ lat: 14, lon: -5.95, elevation: 282 },
	{ lat: 14, lon: -5.21, elevation: 275 },
	{ lat: 14, lon: -4.46, elevation: 272 },
	{ lat: 14, lon: -3.72, elevation: 331 },
	{ lat: 14, lon: -2.98, elevation: 264 },
	{ lat: 14, lon: -2.23, elevation: 333 },
	{ lat: 14, lon: -1.49, elevation: 307 },
	{ lat: 14, lon: -0.74, elevation: 296 },
	{ lat: 14, lon: 0.0, elevation: 282 },
	{ lat: 14, lon: 0.74, elevation: 258 },
	{ lat: 14, lon: 1.49, elevation: 204 },
	{ lat: 14, lon: 2.23, elevation: 230 },
	{ lat: 14, lon: 2.98, elevation: 274 },
	{ lat: 14, lon: 3.72, elevation: 300 },
	{ lat: 14, lon: 4.46, elevation: 280 },
	{ lat: 14, lon: 5.21, elevation: 321 },
	{ lat: 14, lon: 5.95, elevation: 305 },
	{ lat: 14, lon: 6.69, elevation: 333 },
	{ lat: 14, lon: 7.44, elevation: 386 },
	{ lat: 14, lon: 8.18, elevation: 407 },
	{ lat: 14, lon: 8.93, elevation: 482 },
	{ lat: 14, lon: 9.67, elevation: 433 },
	{ lat: 14, lon: 10.41, elevation: 398 },
	{ lat: 14, lon: 11.16, elevation: 358 },
	{ lat: 14, lon: 11.9, elevation: 342 },
	{ lat: 14, lon: 12.64, elevation: 326 },
	{ lat: 14, lon: 13.39, elevation: 284 },
	{ lat: 14, lon: 14.13, elevation: 285 },
	{ lat: 14, lon: 14.88, elevation: 327 },
	{ lat: 14, lon: 15.62, elevation: 295 },
	{ lat: 14, lon: 16.36, elevation: 296 },
	{ lat: 14, lon: 17.11, elevation: 297 },
	{ lat: 14, lon: 17.85, elevation: 304 },
	{ lat: 14, lon: 18.6, elevation: 341 },
	{ lat: 14, lon: 19.34, elevation: 364 },
	{ lat: 14, lon: 20.08, elevation: 409 },
	{ lat: 14, lon: 20.83, elevation: 548 },
	{ lat: 14, lon: 21.57, elevation: 832 },
	{ lat: 14, lon: 22.31, elevation: 1151 },
	{ lat: 14, lon: 23.06, elevation: 866 },
	{ lat: 14, lon: 23.8, elevation: 947 },
	{ lat: 14, lon: 24.55, elevation: 1108 },
	{ lat: 14, lon: 25.29, elevation: 872 },
	{ lat: 14, lon: 26.03, elevation: 758 },
	{ lat: 14, lon: 26.78, elevation: 689 },
	{ lat: 14, lon: 27.52, elevation: 637 },
	{ lat: 14, lon: 28.26, elevation: 559 },
	{ lat: 14, lon: 29.01, elevation: 567 },
	{ lat: 14, lon: 29.75, elevation: 514 },
	{ lat: 14, lon: 30.5, elevation: 475 },
	{ lat: 14, lon: 31.24, elevation: 421 },
	{ lat: 14, lon: 31.98, elevation: 415 },
	{ lat: 14, lon: 32.73, elevation: 400 },
	{ lat: 14, lon: 33.47, elevation: 416 },
	{ lat: 14, lon: 34.21, elevation: 423 },
	{ lat: 14, lon: 34.96, elevation: 493 },
	{ lat: 14, lon: 35.7, elevation: 562 },
	{ lat: 14, lon: 36.45, elevation: 666 },
	{ lat: 14, lon: 37.19, elevation: 1007 },
	{ lat: 14, lon: 37.93, elevation: 1002 },
	{ lat: 14, lon: 38.68, elevation: 1924 },
	{ lat: 14, lon: 39.42, elevation: 2193 },
	{ lat: 14, lon: 40.17, elevation: 166 },
	{ lat: 14, lon: 40.91, elevation: 239 },
	{ lat: 14, lon: 41.65, elevation: 6 },
	{ lat: 14, lon: 43.88, elevation: 1335 },
	{ lat: 14, lon: 44.63, elevation: 2450 },
	{ lat: 14, lon: 45.37, elevation: 2004 },
	{ lat: 14, lon: 46.12, elevation: 1396 },
	{ lat: 14, lon: 46.86, elevation: 843 },
	{ lat: 14, lon: 47.6, elevation: 133 },
	{ lat: 14, lon: 75.12, elevation: 604 },
	{ lat: 14, lon: 75.87, elevation: 696 },
	{ lat: 14, lon: 76.61, elevation: 642 },
	{ lat: 14, lon: 77.36, elevation: 613 },
	{ lat: 14, lon: 78.1, elevation: 633 },
	{ lat: 14, lon: 78.84, elevation: 367 },
	{ lat: 14, lon: 79.59, elevation: 57 },
	{ lat: 14, lon: 98.18, elevation: 9 },
	{ lat: 14, lon: 98.93, elevation: 171 },
	{ lat: 14, lon: 99.67, elevation: 23 },
	{ lat: 14, lon: 100.41, elevation: 3 },
	{ lat: 14, lon: 101.16, elevation: 4 },
	{ lat: 14, lon: 101.9, elevation: 46 },
	{ lat: 14, lon: 102.64, elevation: 136 },
	{ lat: 14, lon: 103.39, elevation: 31 },
	{ lat: 14, lon: 104.13, elevation: 102 },
	{ lat: 14, lon: 104.88, elevation: 67 },
	{ lat: 14, lon: 105.62, elevation: 121 },
	{ lat: 14, lon: 106.36, elevation: 89 },
	{ lat: 14, lon: 107.11, elevation: 120 },
	{ lat: 14, lon: 107.85, elevation: 606 },
	{ lat: 14, lon: 108.6, elevation: 508 },
	{ lat: 14, lon: 121.24, elevation: 469 },
	{ lat: 14, lon: 122.73, elevation: 390 },
	{ lat: 14, lon: 124.21, elevation: 230 },
	{ lat: 15, lon: -92.43, elevation: 17 },
	{ lat: 15, lon: -91.68, elevation: 2695 },
	{ lat: 15, lon: -90.94, elevation: 1696 },
	{ lat: 15, lon: -90.19, elevation: 918 },
	{ lat: 15, lon: -89.44, elevation: 832 },
	{ lat: 15, lon: -88.69, elevation: 1186 },
	{ lat: 15, lon: -87.94, elevation: 279 },
	{ lat: 15, lon: -87.19, elevation: 1317 },
	{ lat: 15, lon: -86.44, elevation: 1083 },
	{ lat: 15, lon: -85.7, elevation: 717 },
	{ lat: 15, lon: -84.95, elevation: 104 },
	{ lat: 15, lon: -84.2, elevation: 77 },
	{ lat: 15, lon: -83.45, elevation: 7 },
	{ lat: 15, lon: -24.32, elevation: 867 },
	{ lat: 15, lon: -23.58, elevation: 496 },
	{ lat: 15, lon: -16.84, elevation: 64 },
	{ lat: 15, lon: -16.09, elevation: 45 },
	{ lat: 15, lon: -15.34, elevation: 56 },
	{ lat: 15, lon: -14.59, elevation: 54 },
	{ lat: 15, lon: -13.85, elevation: 59 },
	{ lat: 15, lon: -13.1, elevation: 86 },
	{ lat: 15, lon: -12.35, elevation: 54 },
	{ lat: 15, lon: -11.6, elevation: 78 },
	{ lat: 15, lon: -10.85, elevation: 87 },
	{ lat: 15, lon: -10.1, elevation: 241 },
	{ lat: 15, lon: -9.36, elevation: 296 },
	{ lat: 15, lon: -8.61, elevation: 280 },
	{ lat: 15, lon: -7.86, elevation: 283 },
	{ lat: 15, lon: -7.11, elevation: 274 },
	{ lat: 15, lon: -6.36, elevation: 319 },
	{ lat: 15, lon: -5.61, elevation: 288 },
	{ lat: 15, lon: -4.86, elevation: 272 },
	{ lat: 15, lon: -4.12, elevation: 266 },
	{ lat: 15, lon: -3.37, elevation: 357 },
	{ lat: 15, lon: -2.62, elevation: 282 },
	{ lat: 15, lon: -1.87, elevation: 271 },
	{ lat: 15, lon: -1.12, elevation: 261 },
	{ lat: 15, lon: -0.37, elevation: 255 },
	{ lat: 15, lon: 0.37, elevation: 273 },
	{ lat: 15, lon: 1.12, elevation: 273 },
	{ lat: 15, lon: 1.87, elevation: 280 },
	{ lat: 15, lon: 2.62, elevation: 269 },
	{ lat: 15, lon: 3.37, elevation: 245 },
	{ lat: 15, lon: 4.12, elevation: 281 },
	{ lat: 15, lon: 4.86, elevation: 363 },
	{ lat: 15, lon: 5.61, elevation: 418 },
	{ lat: 15, lon: 6.36, elevation: 515 },
	{ lat: 15, lon: 7.11, elevation: 453 },
	{ lat: 15, lon: 7.86, elevation: 444 },
	{ lat: 15, lon: 8.61, elevation: 475 },
	{ lat: 15, lon: 9.36, elevation: 483 },
	{ lat: 15, lon: 10.1, elevation: 447 },
	{ lat: 15, lon: 10.85, elevation: 432 },
	{ lat: 15, lon: 11.6, elevation: 377 },
	{ lat: 15, lon: 12.35, elevation: 352 },
	{ lat: 15, lon: 13.1, elevation: 357 },
	{ lat: 15, lon: 13.85, elevation: 343 },
	{ lat: 15, lon: 14.59, elevation: 366 },
	{ lat: 15, lon: 15.34, elevation: 365 },
	{ lat: 15, lon: 16.09, elevation: 327 },
	{ lat: 15, lon: 16.84, elevation: 294 },
	{ lat: 15, lon: 17.59, elevation: 280 },
	{ lat: 15, lon: 18.34, elevation: 299 },
	{ lat: 15, lon: 19.09, elevation: 345 },
	{ lat: 15, lon: 19.83, elevation: 382 },
	{ lat: 15, lon: 20.58, elevation: 445 },
	{ lat: 15, lon: 21.33, elevation: 575 },
	{ lat: 15, lon: 22.08, elevation: 972 },
	{ lat: 15, lon: 22.83, elevation: 868 },
	{ lat: 15, lon: 23.58, elevation: 882 },
	{ lat: 15, lon: 24.32, elevation: 858 },
	{ lat: 15, lon: 25.07, elevation: 921 },
	{ lat: 15, lon: 25.82, elevation: 977 },
	{ lat: 15, lon: 26.57, elevation: 773 },
	{ lat: 15, lon: 27.32, elevation: 815 },
	{ lat: 15, lon: 28.07, elevation: 662 },
	{ lat: 15, lon: 28.81, elevation: 539 },
	{ lat: 15, lon: 29.56, elevation: 594 },
	{ lat: 15, lon: 30.31, elevation: 663 },
	{ lat: 15, lon: 31.06, elevation: 493 },
	{ lat: 15, lon: 31.81, elevation: 468 },
	{ lat: 15, lon: 32.56, elevation: 392 },
	{ lat: 15, lon: 33.31, elevation: 396 },
	{ lat: 15, lon: 34.05, elevation: 435 },
	{ lat: 15, lon: 34.8, elevation: 530 },
	{ lat: 15, lon: 35.55, elevation: 484 },
	{ lat: 15, lon: 36.3, elevation: 519 },
	{ lat: 15, lon: 37.05, elevation: 804 },
	{ lat: 15, lon: 37.8, elevation: 946 },
	{ lat: 15, lon: 38.54, elevation: 1983 },
	{ lat: 15, lon: 39.29, elevation: 2328 },
	{ lat: 15, lon: 40.04, elevation: 109 },
	{ lat: 15, lon: 43.04, elevation: 48 },
	{ lat: 15, lon: 43.78, elevation: 1330 },
	{ lat: 15, lon: 44.53, elevation: 2316 },
	{ lat: 15, lon: 45.28, elevation: 1652 },
	{ lat: 15, lon: 46.03, elevation: 1012 },
	{ lat: 15, lon: 46.78, elevation: 894 },
	{ lat: 15, lon: 47.53, elevation: 1413 },
	{ lat: 15, lon: 48.27, elevation: 1365 },
	{ lat: 15, lon: 49.02, elevation: 1295 },
	{ lat: 15, lon: 49.77, elevation: 359 },
	{ lat: 15, lon: 74.47, elevation: 485 },
	{ lat: 15, lon: 75.22, elevation: 616 },
	{ lat: 15, lon: 75.97, elevation: 553 },
	{ lat: 15, lon: 76.72, elevation: 630 },
	{ lat: 15, lon: 77.46, elevation: 394 },
	{ lat: 15, lon: 78.21, elevation: 238 },
	{ lat: 15, lon: 78.96, elevation: 166 },
	{ lat: 15, lon: 79.71, elevation: 48 },
	{ lat: 15, lon: 98.42, elevation: 292 },
	{ lat: 15, lon: 99.17, elevation: 298 },
	{ lat: 15, lon: 99.92, elevation: 33 },
	{ lat: 15, lon: 100.67, elevation: 28 },
	{ lat: 15, lon: 101.41, elevation: 198 },
	{ lat: 15, lon: 102.16, elevation: 172 },
	{ lat: 15, lon: 102.91, elevation: 185 },
	{ lat: 15, lon: 103.66, elevation: 158 },
	{ lat: 15, lon: 104.41, elevation: 142 },
	{ lat: 15, lon: 105.16, elevation: 143 },
	{ lat: 15, lon: 105.9, elevation: 125 },
	{ lat: 15, lon: 106.65, elevation: 855 },
	{ lat: 15, lon: 107.4, elevation: 986 },
	{ lat: 15, lon: 108.15, elevation: 1117 },
	{ lat: 15, lon: 108.9, elevation: 30 },
	{ lat: 15, lon: 120.12, elevation: 27 },
	{ lat: 15, lon: 120.87, elevation: 13 },
	{ lat: 16, lon: -97.33, elevation: 159 },
	{ lat: 16, lon: -96.58, elevation: 1833 },
	{ lat: 16, lon: -95.82, elevation: 453 },
	{ lat: 16, lon: -93.57, elevation: 895 },
	{ lat: 16, lon: -92.82, elevation: 568 },
	{ lat: 16, lon: -92.07, elevation: 708 },
	{ lat: 16, lon: -91.32, elevation: 1337 },
	{ lat: 16, lon: -90.56, elevation: 139 },
	{ lat: 16, lon: -89.81, elevation: 147 },
	{ lat: 16, lon: -89.06, elevation: 25 },
	{ lat: 16, lon: -61.25, elevation: 0 },
	{ lat: 16, lon: -22.92, elevation: 0 },
	{ lat: 16, lon: -16.16, elevation: 14 },
	{ lat: 16, lon: -15.41, elevation: 38 },
	{ lat: 16, lon: -14.66, elevation: 52 },
	{ lat: 16, lon: -13.9, elevation: 39 },
	{ lat: 16, lon: -13.15, elevation: 59 },
	{ lat: 16, lon: -12.4, elevation: 76 },
	{ lat: 16, lon: -11.65, elevation: 208 },
	{ lat: 16, lon: -10.9, elevation: 188 },
	{ lat: 16, lon: -10.15, elevation: 161 },
	{ lat: 16, lon: -9.39, elevation: 179 },
	{ lat: 16, lon: -8.64, elevation: 227 },
	{ lat: 16, lon: -7.89, elevation: 241 },
	{ lat: 16, lon: -7.14, elevation: 289 },
	{ lat: 16, lon: -6.39, elevation: 324 },
	{ lat: 16, lon: -5.64, elevation: 320 },
	{ lat: 16, lon: -4.89, elevation: 319 },
	{ lat: 16, lon: -4.13, elevation: 258 },
	{ lat: 16, lon: -3.38, elevation: 265 },
	{ lat: 16, lon: -2.63, elevation: 292 },
	{ lat: 16, lon: -1.88, elevation: 293 },
	{ lat: 16, lon: -1.13, elevation: 327 },
	{ lat: 16, lon: -0.38, elevation: 311 },
	{ lat: 16, lon: 0.38, elevation: 276 },
	{ lat: 16, lon: 1.13, elevation: 318 },
	{ lat: 16, lon: 1.88, elevation: 328 },
	{ lat: 16, lon: 2.63, elevation: 296 },
	{ lat: 16, lon: 3.38, elevation: 265 },
	{ lat: 16, lon: 4.13, elevation: 320 },
	{ lat: 16, lon: 4.89, elevation: 427 },
	{ lat: 16, lon: 5.64, elevation: 437 },
	{ lat: 16, lon: 6.39, elevation: 466 },
	{ lat: 16, lon: 7.14, elevation: 528 },
	{ lat: 16, lon: 7.89, elevation: 533 },
	{ lat: 16, lon: 8.64, elevation: 510 },
	{ lat: 16, lon: 9.39, elevation: 503 },
	{ lat: 16, lon: 10.15, elevation: 439 },
	{ lat: 16, lon: 10.9, elevation: 420 },
	{ lat: 16, lon: 11.65, elevation: 444 },
	{ lat: 16, lon: 12.4, elevation: 418 },
	{ lat: 16, lon: 13.15, elevation: 354 },
	{ lat: 16, lon: 13.9, elevation: 360 },
	{ lat: 16, lon: 14.66, elevation: 313 },
	{ lat: 16, lon: 15.41, elevation: 293 },
	{ lat: 16, lon: 16.16, elevation: 252 },
	{ lat: 16, lon: 16.91, elevation: 285 },
	{ lat: 16, lon: 17.66, elevation: 262 },
	{ lat: 16, lon: 18.41, elevation: 274 },
	{ lat: 16, lon: 19.16, elevation: 352 },
	{ lat: 16, lon: 19.92, elevation: 399 },
	{ lat: 16, lon: 20.67, elevation: 450 },
	{ lat: 16, lon: 21.42, elevation: 542 },
	{ lat: 16, lon: 22.17, elevation: 661 },
	{ lat: 16, lon: 22.92, elevation: 893 },
	{ lat: 16, lon: 23.67, elevation: 865 },
	{ lat: 16, lon: 24.43, elevation: 698 },
	{ lat: 16, lon: 25.18, elevation: 778 },
	{ lat: 16, lon: 25.93, elevation: 890 },
	{ lat: 16, lon: 26.68, elevation: 773 },
	{ lat: 16, lon: 27.43, elevation: 752 },
	{ lat: 16, lon: 28.18, elevation: 499 },
	{ lat: 16, lon: 28.94, elevation: 440 },
	{ lat: 16, lon: 29.69, elevation: 407 },
	{ lat: 16, lon: 30.44, elevation: 536 },
	{ lat: 16, lon: 31.19, elevation: 541 },
	{ lat: 16, lon: 31.94, elevation: 380 },
	{ lat: 16, lon: 32.69, elevation: 410 },
	{ lat: 16, lon: 33.44, elevation: 532 },
	{ lat: 16, lon: 34.2, elevation: 465 },
	{ lat: 16, lon: 34.95, elevation: 401 },
	{ lat: 16, lon: 35.7, elevation: 421 },
	{ lat: 16, lon: 36.45, elevation: 460 },
	{ lat: 16, lon: 37.2, elevation: 458 },
	{ lat: 16, lon: 37.95, elevation: 805 },
	{ lat: 16, lon: 38.71, elevation: 1250 },
	{ lat: 16, lon: 43.22, elevation: 253 },
	{ lat: 16, lon: 43.97, elevation: 2417 },
	{ lat: 16, lon: 44.72, elevation: 1362 },
	{ lat: 16, lon: 45.47, elevation: 1030 },
	{ lat: 16, lon: 46.22, elevation: 912 },
	{ lat: 16, lon: 46.97, elevation: 867 },
	{ lat: 16, lon: 47.72, elevation: 934 },
	{ lat: 16, lon: 48.48, elevation: 854 },
	{ lat: 16, lon: 49.23, elevation: 693 },
	{ lat: 16, lon: 49.98, elevation: 540 },
	{ lat: 16, lon: 50.73, elevation: 868 },
	{ lat: 16, lon: 51.48, elevation: 710 },
	{ lat: 16, lon: 74.03, elevation: 701 },
	{ lat: 16, lon: 74.78, elevation: 721 },
	{ lat: 16, lon: 75.53, elevation: 661 },
	{ lat: 16, lon: 76.28, elevation: 556 },
	{ lat: 16, lon: 77.04, elevation: 382 },
	{ lat: 16, lon: 77.79, elevation: 324 },
	{ lat: 16, lon: 78.54, elevation: 515 },
	{ lat: 16, lon: 79.29, elevation: 447 },
	{ lat: 16, lon: 80.04, elevation: 59 },
	{ lat: 16, lon: 80.79, elevation: 4 },
	{ lat: 16, lon: 94.32, elevation: 5 },
	{ lat: 16, lon: 98.08, elevation: 16 },
	{ lat: 16, lon: 98.83, elevation: 652 },
	{ lat: 16, lon: 99.58, elevation: 93 },
	{ lat: 16, lon: 100.33, elevation: 29 },
	{ lat: 16, lon: 101.09, elevation: 95 },
	{ lat: 16, lon: 101.84, elevation: 257 },
	{ lat: 16, lon: 102.59, elevation: 174 },
	{ lat: 16, lon: 103.34, elevation: 181 },
	{ lat: 16, lon: 104.09, elevation: 141 },
	{ lat: 16, lon: 104.84, elevation: 189 },
	{ lat: 16, lon: 105.59, elevation: 185 },
	{ lat: 16, lon: 106.35, elevation: 610 },
	{ lat: 16, lon: 107.1, elevation: 975 },
	{ lat: 16, lon: 107.85, elevation: 730 },
	{ lat: 16, lon: 119.87, elevation: 14 },
	{ lat: 16, lon: 120.63, elevation: 34 },
	{ lat: 16, lon: 121.38, elevation: 674 },
	{ lat: 17, lon: -100.0, elevation: 50 },
	{ lat: 17, lon: -99.25, elevation: 441 },
	{ lat: 17, lon: -98.49, elevation: 922 },
	{ lat: 17, lon: -97.74, elevation: 1845 },
	{ lat: 17, lon: -96.98, elevation: 2630 },
	{ lat: 17, lon: -96.23, elevation: 2201 },
	{ lat: 17, lon: -95.47, elevation: 457 },
	{ lat: 17, lon: -94.72, elevation: 279 },
	{ lat: 17, lon: -93.96, elevation: 1023 },
	{ lat: 17, lon: -93.21, elevation: 979 },
	{ lat: 17, lon: -92.45, elevation: 1205 },
	{ lat: 17, lon: -91.7, elevation: 717 },
	{ lat: 17, lon: -90.94, elevation: 248 },
	{ lat: 17, lon: -90.19, elevation: 94 },
	{ lat: 17, lon: -89.43, elevation: 222 },
	{ lat: 17, lon: -88.68, elevation: 587 },
	{ lat: 17, lon: -25.28, elevation: 1078 },
	{ lat: 17, lon: -16.23, elevation: 2 },
	{ lat: 17, lon: -15.47, elevation: 30 },
	{ lat: 17, lon: -14.72, elevation: 57 },
	{ lat: 17, lon: -13.96, elevation: 45 },
	{ lat: 17, lon: -13.21, elevation: 82 },
	{ lat: 17, lon: -12.45, elevation: 97 },
	{ lat: 17, lon: -11.7, elevation: 253 },
	{ lat: 17, lon: -10.94, elevation: 212 },
	{ lat: 17, lon: -10.19, elevation: 304 },
	{ lat: 17, lon: -9.43, elevation: 199 },
	{ lat: 17, lon: -8.68, elevation: 198 },
	{ lat: 17, lon: -7.92, elevation: 238 },
	{ lat: 17, lon: -7.17, elevation: 346 },
	{ lat: 17, lon: -6.42, elevation: 306 },
	{ lat: 17, lon: -5.66, elevation: 278 },
	{ lat: 17, lon: -4.91, elevation: 275 },
	{ lat: 17, lon: -4.15, elevation: 277 },
	{ lat: 17, lon: -3.4, elevation: 284 },
	{ lat: 17, lon: -2.64, elevation: 265 },
	{ lat: 17, lon: -1.89, elevation: 268 },
	{ lat: 17, lon: -1.13, elevation: 266 },
	{ lat: 17, lon: -0.38, elevation: 291 },
	{ lat: 17, lon: 0.38, elevation: 296 },
	{ lat: 17, lon: 1.13, elevation: 341 },
	{ lat: 17, lon: 1.89, elevation: 363 },
	{ lat: 17, lon: 2.64, elevation: 458 },
	{ lat: 17, lon: 3.4, elevation: 349 },
	{ lat: 17, lon: 4.15, elevation: 292 },
	{ lat: 17, lon: 4.91, elevation: 363 },
	{ lat: 17, lon: 5.66, elevation: 391 },
	{ lat: 17, lon: 6.42, elevation: 404 },
	{ lat: 17, lon: 7.17, elevation: 412 },
	{ lat: 17, lon: 7.92, elevation: 488 },
	{ lat: 17, lon: 8.68, elevation: 575 },
	{ lat: 17, lon: 9.43, elevation: 556 },
	{ lat: 17, lon: 10.19, elevation: 473 },
	{ lat: 17, lon: 10.94, elevation: 415 },
	{ lat: 17, lon: 11.7, elevation: 391 },
	{ lat: 17, lon: 12.45, elevation: 423 },
	{ lat: 17, lon: 13.21, elevation: 442 },
	{ lat: 17, lon: 13.96, elevation: 421 },
	{ lat: 17, lon: 14.72, elevation: 365 },
	{ lat: 17, lon: 15.47, elevation: 274 },
	{ lat: 17, lon: 16.23, elevation: 242 },
	{ lat: 17, lon: 16.98, elevation: 192 },
	{ lat: 17, lon: 17.74, elevation: 168 },
	{ lat: 17, lon: 18.49, elevation: 170 },
	{ lat: 17, lon: 19.25, elevation: 274 },
	{ lat: 17, lon: 20.0, elevation: 372 },
	{ lat: 17, lon: 20.75, elevation: 441 },
	{ lat: 17, lon: 21.51, elevation: 635 },
	{ lat: 17, lon: 22.26, elevation: 1044 },
	{ lat: 17, lon: 23.02, elevation: 1107 },
	{ lat: 17, lon: 23.77, elevation: 813 },
	{ lat: 17, lon: 24.53, elevation: 653 },
	{ lat: 17, lon: 25.28, elevation: 619 },
	{ lat: 17, lon: 26.04, elevation: 595 },
	{ lat: 17, lon: 26.79, elevation: 589 },
	{ lat: 17, lon: 27.55, elevation: 515 },
	{ lat: 17, lon: 28.3, elevation: 438 },
	{ lat: 17, lon: 29.06, elevation: 390 },
	{ lat: 17, lon: 29.81, elevation: 525 },
	{ lat: 17, lon: 30.57, elevation: 366 },
	{ lat: 17, lon: 31.32, elevation: 376 },
	{ lat: 17, lon: 32.08, elevation: 444 },
	{ lat: 17, lon: 32.83, elevation: 489 },
	{ lat: 17, lon: 33.58, elevation: 375 },
	{ lat: 17, lon: 34.34, elevation: 371 },
	{ lat: 17, lon: 35.09, elevation: 384 },
	{ lat: 17, lon: 35.85, elevation: 413 },
	{ lat: 17, lon: 36.6, elevation: 363 },
	{ lat: 17, lon: 37.36, elevation: 280 },
	{ lat: 17, lon: 38.11, elevation: 655 },
	{ lat: 17, lon: 38.87, elevation: 137 },
	{ lat: 17, lon: 41.89, elevation: 0 },
	{ lat: 17, lon: 42.64, elevation: 29 },
	{ lat: 17, lon: 43.4, elevation: 1856 },
	{ lat: 17, lon: 44.15, elevation: 1935 },
	{ lat: 17, lon: 44.91, elevation: 1218 },
	{ lat: 17, lon: 45.66, elevation: 940 },
	{ lat: 17, lon: 46.42, elevation: 876 },
	{ lat: 17, lon: 47.17, elevation: 858 },
	{ lat: 17, lon: 47.92, elevation: 837 },
	{ lat: 17, lon: 48.68, elevation: 845 },
	{ lat: 17, lon: 49.43, elevation: 871 },
	{ lat: 17, lon: 50.19, elevation: 852 },
	{ lat: 17, lon: 50.94, elevation: 943 },
	{ lat: 17, lon: 51.7, elevation: 851 },
	{ lat: 17, lon: 52.45, elevation: 895 },
	{ lat: 17, lon: 53.21, elevation: 904 },
	{ lat: 17, lon: 53.96, elevation: 53 },
	{ lat: 17, lon: 54.72, elevation: 29 },
	{ lat: 17, lon: 73.58, elevation: 186 },
	{ lat: 17, lon: 74.34, elevation: 563 },
	{ lat: 17, lon: 75.09, elevation: 656 },
	{ lat: 17, lon: 75.85, elevation: 511 },
	{ lat: 17, lon: 76.6, elevation: 404 },
	{ lat: 17, lon: 77.36, elevation: 510 },
	{ lat: 17, lon: 78.11, elevation: 590 },
	{ lat: 17, lon: 78.87, elevation: 353 },
	{ lat: 17, lon: 79.62, elevation: 137 },
	{ lat: 17, lon: 80.38, elevation: 81 },
	{ lat: 17, lon: 81.13, elevation: 89 },
	{ lat: 17, lon: 81.89, elevation: 85 },
	{ lat: 17, lon: 94.72, elevation: 16 },
	{ lat: 17, lon: 95.47, elevation: 5 },
	{ lat: 17, lon: 96.23, elevation: 7 },
	{ lat: 17, lon: 97.74, elevation: 14 },
	{ lat: 17, lon: 98.49, elevation: 298 },
	{ lat: 17, lon: 99.25, elevation: 178 },
	{ lat: 17, lon: 100.0, elevation: 43 },
	{ lat: 17, lon: 100.75, elevation: 299 },
	{ lat: 17, lon: 101.51, elevation: 703 },
	{ lat: 17, lon: 102.26, elevation: 202 },
	{ lat: 17, lon: 103.02, elevation: 177 },
	{ lat: 17, lon: 103.77, elevation: 276 },
	{ lat: 17, lon: 104.53, elevation: 151 },
	{ lat: 17, lon: 105.28, elevation: 231 },
	{ lat: 17, lon: 106.04, elevation: 228 },
	{ lat: 17, lon: 106.79, elevation: 121 },
	{ lat: 17, lon: 121.13, elevation: 2357 },
	{ lat: 17, lon: 121.89, elevation: 63 },
	{ lat: 18, lon: -101.77, elevation: 147 },
	{ lat: 18, lon: -101.01, elevation: 1124 },
	{ lat: 18, lon: -100.25, elevation: 1466 },
	{ lat: 18, lon: -99.49, elevation: 777 },
	{ lat: 18, lon: -98.73, elevation: 963 },
	{ lat: 18, lon: -97.97, elevation: 1465 },
	{ lat: 18, lon: -97.22, elevation: 1442 },
	{ lat: 18, lon: -96.46, elevation: 82 },
	{ lat: 18, lon: -95.7, elevation: 22 },
	{ lat: 18, lon: -94.94, elevation: 107 },
	{ lat: 18, lon: -94.18, elevation: 33 },
	{ lat: 18, lon: -93.42, elevation: 19 },
	{ lat: 18, lon: -92.66, elevation: 0 },
	{ lat: 18, lon: -91.9, elevation: 10 },
	{ lat: 18, lon: -91.14, elevation: 53 },
	{ lat: 18, lon: -90.38, elevation: 158 },
	{ lat: 18, lon: -89.62, elevation: 294 },
	{ lat: 18, lon: -88.86, elevation: 118 },
	{ lat: 18, lon: -77.47, elevation: 625 },
	{ lat: 18, lon: -76.71, elevation: 469 },
	{ lat: 18, lon: -71.39, elevation: 503 },
	{ lat: 18, lon: -66.84, elevation: 18 },
	{ lat: 18, lon: -66.08, elevation: 241 },
	{ lat: 18, lon: -15.95, elevation: 2 },
	{ lat: 18, lon: -15.19, elevation: 28 },
	{ lat: 18, lon: -14.43, elevation: 62 },
	{ lat: 18, lon: -13.67, elevation: 51 },
	{ lat: 18, lon: -12.91, elevation: 86 },
	{ lat: 18, lon: -12.15, elevation: 180 },
	{ lat: 18, lon: -11.39, elevation: 253 },
	{ lat: 18, lon: -10.63, elevation: 254 },
	{ lat: 18, lon: -9.87, elevation: 259 },
	{ lat: 18, lon: -9.11, elevation: 260 },
	{ lat: 18, lon: -8.35, elevation: 233 },
	{ lat: 18, lon: -7.59, elevation: 336 },
	{ lat: 18, lon: -6.84, elevation: 314 },
	{ lat: 18, lon: -6.08, elevation: 308 },
	{ lat: 18, lon: -5.32, elevation: 303 },
	{ lat: 18, lon: -4.56, elevation: 307 },
	{ lat: 18, lon: -3.8, elevation: 288 },
	{ lat: 18, lon: -3.04, elevation: 278 },
	{ lat: 18, lon: -2.28, elevation: 284 },
	{ lat: 18, lon: -1.52, elevation: 300 },
	{ lat: 18, lon: -0.76, elevation: 325 },
	{ lat: 18, lon: 0.0, elevation: 372 },
	{ lat: 18, lon: 0.76, elevation: 337 },
	{ lat: 18, lon: 1.52, elevation: 446 },
	{ lat: 18, lon: 2.28, elevation: 459 },
	{ lat: 18, lon: 3.04, elevation: 381 },
	{ lat: 18, lon: 3.8, elevation: 338 },
	{ lat: 18, lon: 4.56, elevation: 347 },
	{ lat: 18, lon: 5.32, elevation: 332 },
	{ lat: 18, lon: 6.08, elevation: 367 },
	{ lat: 18, lon: 6.84, elevation: 386 },
	{ lat: 18, lon: 7.59, elevation: 473 },
	{ lat: 18, lon: 8.35, elevation: 775 },
	{ lat: 18, lon: 9.11, elevation: 869 },
	{ lat: 18, lon: 9.87, elevation: 516 },
	{ lat: 18, lon: 10.63, elevation: 426 },
	{ lat: 18, lon: 11.39, elevation: 417 },
	{ lat: 18, lon: 12.15, elevation: 398 },
	{ lat: 18, lon: 12.91, elevation: 452 },
	{ lat: 18, lon: 13.67, elevation: 468 },
	{ lat: 18, lon: 14.43, elevation: 447 },
	{ lat: 18, lon: 15.19, elevation: 430 },
	{ lat: 18, lon: 15.95, elevation: 345 },
	{ lat: 18, lon: 16.71, elevation: 365 },
	{ lat: 18, lon: 17.47, elevation: 351 },
	{ lat: 18, lon: 18.23, elevation: 321 },
	{ lat: 18, lon: 18.99, elevation: 283 },
	{ lat: 18, lon: 19.75, elevation: 375 },
	{ lat: 18, lon: 20.51, elevation: 420 },
	{ lat: 18, lon: 21.27, elevation: 459 },
	{ lat: 18, lon: 22.03, elevation: 500 },
	{ lat: 18, lon: 22.78, elevation: 553 },
	{ lat: 18, lon: 23.54, elevation: 764 },
	{ lat: 18, lon: 24.3, elevation: 677 },
	{ lat: 18, lon: 25.06, elevation: 587 },
	{ lat: 18, lon: 25.82, elevation: 557 },
	{ lat: 18, lon: 26.58, elevation: 560 },
	{ lat: 18, lon: 27.34, elevation: 608 },
	{ lat: 18, lon: 28.1, elevation: 459 },
	{ lat: 18, lon: 28.86, elevation: 435 },
	{ lat: 18, lon: 29.62, elevation: 357 },
	{ lat: 18, lon: 30.38, elevation: 262 },
	{ lat: 18, lon: 31.14, elevation: 237 },
	{ lat: 18, lon: 31.9, elevation: 303 },
	{ lat: 18, lon: 32.66, elevation: 474 },
	{ lat: 18, lon: 33.42, elevation: 468 },
	{ lat: 18, lon: 34.18, elevation: 368 },
	{ lat: 18, lon: 34.94, elevation: 407 },
	{ lat: 18, lon: 35.7, elevation: 472 },
	{ lat: 18, lon: 36.46, elevation: 680 },
	{ lat: 18, lon: 37.22, elevation: 407 },
	{ lat: 18, lon: 37.97, elevation: 342 },
	{ lat: 18, lon: 41.77, elevation: 96 },
	{ lat: 18, lon: 42.53, elevation: 1311 },
	{ lat: 18, lon: 43.29, elevation: 2628 },
	{ lat: 18, lon: 44.05, elevation: 1384 },
	{ lat: 18, lon: 44.81, elevation: 1054 },
	{ lat: 18, lon: 45.57, elevation: 879 },
	{ lat: 18, lon: 46.33, elevation: 786 },
	{ lat: 18, lon: 47.09, elevation: 649 },
	{ lat: 18, lon: 47.85, elevation: 598 },
	{ lat: 18, lon: 48.61, elevation: 510 },
	{ lat: 18, lon: 49.37, elevation: 455 },
	{ lat: 18, lon: 50.13, elevation: 435 },
	{ lat: 18, lon: 50.89, elevation: 529 },
	{ lat: 18, lon: 51.65, elevation: 513 },
	{ lat: 18, lon: 52.41, elevation: 532 },
	{ lat: 18, lon: 53.16, elevation: 391 },
	{ lat: 18, lon: 53.92, elevation: 327 },
	{ lat: 18, lon: 54.68, elevation: 345 },
	{ lat: 18, lon: 55.44, elevation: 335 },
	{ lat: 18, lon: 56.2, elevation: 141 },
	{ lat: 18, lon: 73.67, elevation: 1412 },
	{ lat: 18, lon: 74.43, elevation: 546 },
	{ lat: 18, lon: 75.19, elevation: 506 },
	{ lat: 18, lon: 75.95, elevation: 522 },
	{ lat: 18, lon: 76.71, elevation: 610 },
	{ lat: 18, lon: 77.47, elevation: 542 },
	{ lat: 18, lon: 78.23, elevation: 460 },
	{ lat: 18, lon: 78.99, elevation: 457 },
	{ lat: 18, lon: 79.75, elevation: 252 },
	{ lat: 18, lon: 80.51, elevation: 288 },
	{ lat: 18, lon: 81.27, elevation: 216 },
	{ lat: 18, lon: 82.03, elevation: 413 },
	{ lat: 18, lon: 82.78, elevation: 600 },
	{ lat: 18, lon: 83.54, elevation: 25 },
	{ lat: 18, lon: 94.94, elevation: 402 },
	{ lat: 18, lon: 95.7, elevation: 24 },
	{ lat: 18, lon: 96.46, elevation: 128 },
	{ lat: 18, lon: 97.22, elevation: 251 },
	{ lat: 18, lon: 97.97, elevation: 574 },
	{ lat: 18, lon: 98.73, elevation: 515 },
	{ lat: 18, lon: 99.49, elevation: 301 },
	{ lat: 18, lon: 100.25, elevation: 693 },
	{ lat: 18, lon: 101.01, elevation: 490 },
	{ lat: 18, lon: 101.77, elevation: 226 },
	{ lat: 18, lon: 102.53, elevation: 173 },
	{ lat: 18, lon: 103.29, elevation: 176 },
	{ lat: 18, lon: 104.05, elevation: 185 },
	{ lat: 18, lon: 104.81, elevation: 438 },
	{ lat: 18, lon: 105.57, elevation: 827 },
	{ lat: 18, lon: 106.33, elevation: 568 },
	{ lat: 18, lon: 120.76, elevation: 283 },
	{ lat: 18, lon: 121.52, elevation: 211 },
	{ lat: 19, lon: -103.57, elevation: 736 },
	{ lat: 19, lon: -102.8, elevation: 444 },
	{ lat: 19, lon: -102.04, elevation: 350 },
	{ lat: 19, lon: -101.27, elevation: 756 },
	{ lat: 19, lon: -100.51, elevation: 665 },
	{ lat: 19, lon: -99.75, elevation: 2587 },
	{ lat: 19, lon: -98.98, elevation: 1890 },
	{ lat: 19, lon: -98.22, elevation: 2130 },
	{ lat: 19, lon: -97.45, elevation: 2545 },
	{ lat: 19, lon: -96.69, elevation: 466 },
	{ lat: 19, lon: -90.57, elevation: 75 },
	{ lat: 19, lon: -89.81, elevation: 165 },
	{ lat: 19, lon: -89.04, elevation: 94 },
	{ lat: 19, lon: -88.28, elevation: 23 },
	{ lat: 19, lon: -72.23, elevation: 542 },
	{ lat: 19, lon: -71.46, elevation: 571 },
	{ lat: 19, lon: -70.7, elevation: 1578 },
	{ lat: 19, lon: -69.94, elevation: 43 },
	{ lat: 19, lon: -69.17, elevation: 5 },
	{ lat: 19, lon: -15.67, elevation: -1 },
	{ lat: 19, lon: -14.9, elevation: 33 },
	{ lat: 19, lon: -14.14, elevation: 58 },
	{ lat: 19, lon: -13.38, elevation: 48 },
	{ lat: 19, lon: -12.61, elevation: 142 },
	{ lat: 19, lon: -11.85, elevation: 199 },
	{ lat: 19, lon: -11.08, elevation: 423 },
	{ lat: 19, lon: -10.32, elevation: 426 },
	{ lat: 19, lon: -9.55, elevation: 374 },
	{ lat: 19, lon: -8.79, elevation: 377 },
	{ lat: 19, lon: -8.03, elevation: 350 },
	{ lat: 19, lon: -7.26, elevation: 343 },
	{ lat: 19, lon: -6.5, elevation: 334 },
	{ lat: 19, lon: -5.73, elevation: 320 },
	{ lat: 19, lon: -4.97, elevation: 310 },
	{ lat: 19, lon: -4.2, elevation: 286 },
	{ lat: 19, lon: -3.44, elevation: 272 },
	{ lat: 19, lon: -2.68, elevation: 272 },
	{ lat: 19, lon: -1.91, elevation: 286 },
	{ lat: 19, lon: -1.15, elevation: 332 },
	{ lat: 19, lon: -0.38, elevation: 409 },
	{ lat: 19, lon: 0.38, elevation: 382 },
	{ lat: 19, lon: 1.15, elevation: 529 },
	{ lat: 19, lon: 1.91, elevation: 575 },
	{ lat: 19, lon: 2.68, elevation: 552 },
	{ lat: 19, lon: 3.44, elevation: 478 },
	{ lat: 19, lon: 4.2, elevation: 415 },
	{ lat: 19, lon: 4.97, elevation: 379 },
	{ lat: 19, lon: 5.73, elevation: 371 },
	{ lat: 19, lon: 6.5, elevation: 400 },
	{ lat: 19, lon: 7.26, elevation: 428 },
	{ lat: 19, lon: 8.03, elevation: 593 },
	{ lat: 19, lon: 8.79, elevation: 1364 },
	{ lat: 19, lon: 9.55, elevation: 715 },
	{ lat: 19, lon: 10.32, elevation: 472 },
	{ lat: 19, lon: 11.08, elevation: 414 },
	{ lat: 19, lon: 11.85, elevation: 402 },
	{ lat: 19, lon: 12.61, elevation: 432 },
	{ lat: 19, lon: 13.38, elevation: 444 },
	{ lat: 19, lon: 14.14, elevation: 487 },
	{ lat: 19, lon: 14.9, elevation: 502 },
	{ lat: 19, lon: 15.67, elevation: 465 },
	{ lat: 19, lon: 16.43, elevation: 465 },
	{ lat: 19, lon: 17.2, elevation: 535 },
	{ lat: 19, lon: 17.96, elevation: 512 },
	{ lat: 19, lon: 18.73, elevation: 389 },
	{ lat: 19, lon: 19.49, elevation: 416 },
	{ lat: 19, lon: 20.25, elevation: 460 },
	{ lat: 19, lon: 21.02, elevation: 539 },
	{ lat: 19, lon: 21.78, elevation: 513 },
	{ lat: 19, lon: 22.55, elevation: 612 },
	{ lat: 19, lon: 23.31, elevation: 862 },
	{ lat: 19, lon: 24.08, elevation: 745 },
	{ lat: 19, lon: 24.84, elevation: 586 },
	{ lat: 19, lon: 25.61, elevation: 556 },
	{ lat: 19, lon: 26.37, elevation: 513 },
	{ lat: 19, lon: 27.13, elevation: 631 },
	{ lat: 19, lon: 27.9, elevation: 440 },
	{ lat: 19, lon: 28.66, elevation: 396 },
	{ lat: 19, lon: 29.43, elevation: 270 },
	{ lat: 19, lon: 30.19, elevation: 245 },
	{ lat: 19, lon: 30.96, elevation: 255 },
	{ lat: 19, lon: 31.72, elevation: 331 },
	{ lat: 19, lon: 32.48, elevation: 308 },
	{ lat: 19, lon: 33.25, elevation: 397 },
	{ lat: 19, lon: 34.01, elevation: 353 },
	{ lat: 19, lon: 34.78, elevation: 397 },
	{ lat: 19, lon: 35.54, elevation: 528 },
	{ lat: 19, lon: 36.31, elevation: 861 },
	{ lat: 19, lon: 37.07, elevation: 387 },
	{ lat: 19, lon: 41.66, elevation: 393 },
	{ lat: 19, lon: 42.42, elevation: 1816 },
	{ lat: 19, lon: 43.18, elevation: 1461 },
	{ lat: 19, lon: 43.95, elevation: 1484 },
	{ lat: 19, lon: 44.71, elevation: 1033 },
	{ lat: 19, lon: 45.48, elevation: 867 },
	{ lat: 19, lon: 46.24, elevation: 753 },
	{ lat: 19, lon: 47.01, elevation: 624 },
	{ lat: 19, lon: 47.77, elevation: 491 },
	{ lat: 19, lon: 48.54, elevation: 401 },
	{ lat: 19, lon: 49.3, elevation: 342 },
	{ lat: 19, lon: 50.06, elevation: 282 },
	{ lat: 19, lon: 50.83, elevation: 303 },
	{ lat: 19, lon: 51.59, elevation: 270 },
	{ lat: 19, lon: 52.36, elevation: 318 },
	{ lat: 19, lon: 53.12, elevation: 213 },
	{ lat: 19, lon: 53.89, elevation: 187 },
	{ lat: 19, lon: 54.65, elevation: 188 },
	{ lat: 19, lon: 55.41, elevation: 213 },
	{ lat: 19, lon: 56.18, elevation: 183 },
	{ lat: 19, lon: 56.94, elevation: 91 },
	{ lat: 19, lon: 57.71, elevation: 100 },
	{ lat: 19, lon: 73.76, elevation: 737 },
	{ lat: 19, lon: 74.52, elevation: 787 },
	{ lat: 19, lon: 75.29, elevation: 668 },
	{ lat: 19, lon: 76.05, elevation: 490 },
	{ lat: 19, lon: 76.82, elevation: 387 },
	{ lat: 19, lon: 77.58, elevation: 354 },
	{ lat: 19, lon: 78.34, elevation: 311 },
	{ lat: 19, lon: 79.11, elevation: 171 },
	{ lat: 19, lon: 79.87, elevation: 142 },
	{ lat: 19, lon: 80.64, elevation: 317 },
	{ lat: 19, lon: 81.4, elevation: 407 },
	{ lat: 19, lon: 82.17, elevation: 552 },
	{ lat: 19, lon: 82.93, elevation: 860 },
	{ lat: 19, lon: 83.69, elevation: 206 },
	{ lat: 19, lon: 84.46, elevation: 55 },
	{ lat: 19, lon: 94.39, elevation: 155 },
	{ lat: 19, lon: 95.16, elevation: 58 },
	{ lat: 19, lon: 95.92, elevation: 391 },
	{ lat: 19, lon: 96.69, elevation: 521 },
	{ lat: 19, lon: 97.45, elevation: 264 },
	{ lat: 19, lon: 98.22, elevation: 1162 },
	{ lat: 19, lon: 98.98, elevation: 326 },
	{ lat: 19, lon: 99.75, elevation: 775 },
	{ lat: 19, lon: 100.51, elevation: 998 },
	{ lat: 19, lon: 101.27, elevation: 1087 },
	{ lat: 19, lon: 102.04, elevation: 628 },
	{ lat: 19, lon: 102.8, elevation: 744 },
	{ lat: 19, lon: 103.57, elevation: 651 },
	{ lat: 19, lon: 104.33, elevation: 670 },
	{ lat: 19, lon: 105.1, elevation: 78 },
	{ lat: 19, lon: 108.92, elevation: 132 },
	{ lat: 19, lon: 109.68, elevation: 506 },
	{ lat: 19, lon: 110.45, elevation: 20 },
	{ lat: 20, lon: -155.38, elevation: 795 },
	{ lat: 20, lon: -105.38, elevation: 37 },
	{ lat: 20, lon: -104.62, elevation: 2153 },
	{ lat: 20, lon: -103.85, elevation: 2015 },
	{ lat: 20, lon: -103.08, elevation: 1972 },
	{ lat: 20, lon: -102.31, elevation: 1562 },
	{ lat: 20, lon: -101.54, elevation: 2253 },
	{ lat: 20, lon: -100.77, elevation: 1885 },
	{ lat: 20, lon: -100.0, elevation: 2939 },
	{ lat: 20, lon: -99.23, elevation: 2211 },
	{ lat: 20, lon: -98.46, elevation: 2832 },
	{ lat: 20, lon: -97.69, elevation: 638 },
	{ lat: 20, lon: -96.92, elevation: 309 },
	{ lat: 20, lon: -90.0, elevation: 93 },
	{ lat: 20, lon: -89.23, elevation: 163 },
	{ lat: 20, lon: -88.46, elevation: 30 },
	{ lat: 20, lon: -87.69, elevation: 9 },
	{ lat: 20, lon: -76.92, elevation: 868 },
	{ lat: 20, lon: -76.15, elevation: 48 },
	{ lat: 20, lon: -75.38, elevation: 132 },
	{ lat: 20, lon: -16.15, elevation: 9 },
	{ lat: 20, lon: -15.38, elevation: 65 },
	{ lat: 20, lon: -14.62, elevation: 127 },
	{ lat: 20, lon: -13.85, elevation: 106 },
	{ lat: 20, lon: -13.08, elevation: 219 },
	{ lat: 20, lon: -12.31, elevation: 437 },
	{ lat: 20, lon: -11.54, elevation: 309 },
	{ lat: 20, lon: -10.77, elevation: 382 },
	{ lat: 20, lon: -10.0, elevation: 402 },
	{ lat: 20, lon: -9.23, elevation: 409 },
	{ lat: 20, lon: -8.46, elevation: 351 },
	{ lat: 20, lon: -7.69, elevation: 364 },
	{ lat: 20, lon: -6.92, elevation: 327 },
	{ lat: 20, lon: -6.15, elevation: 315 },
	{ lat: 20, lon: -5.38, elevation: 302 },
	{ lat: 20, lon: -4.62, elevation: 294 },
	{ lat: 20, lon: -3.85, elevation: 283 },
	{ lat: 20, lon: -3.08, elevation: 285 },
	{ lat: 20, lon: -2.31, elevation: 295 },
	{ lat: 20, lon: -1.54, elevation: 355 },
	{ lat: 20, lon: -0.77, elevation: 330 },
	{ lat: 20, lon: 0.0, elevation: 399 },
	{ lat: 20, lon: 0.77, elevation: 477 },
	{ lat: 20, lon: 1.54, elevation: 584 },
	{ lat: 20, lon: 2.31, elevation: 736 },
	{ lat: 20, lon: 3.08, elevation: 623 },
	{ lat: 20, lon: 3.85, elevation: 581 },
	{ lat: 20, lon: 4.62, elevation: 467 },
	{ lat: 20, lon: 5.38, elevation: 481 },
	{ lat: 20, lon: 6.15, elevation: 442 },
	{ lat: 20, lon: 6.92, elevation: 473 },
	{ lat: 20, lon: 7.69, elevation: 548 },
	{ lat: 20, lon: 8.46, elevation: 1039 },
	{ lat: 20, lon: 9.23, elevation: 731 },
	{ lat: 20, lon: 10.0, elevation: 538 },
	{ lat: 20, lon: 10.77, elevation: 438 },
	{ lat: 20, lon: 11.54, elevation: 417 },
	{ lat: 20, lon: 12.31, elevation: 439 },
	{ lat: 20, lon: 13.08, elevation: 507 },
	{ lat: 20, lon: 13.85, elevation: 455 },
	{ lat: 20, lon: 14.62, elevation: 476 },
	{ lat: 20, lon: 15.38, elevation: 550 },
	{ lat: 20, lon: 16.15, elevation: 659 },
	{ lat: 20, lon: 16.92, elevation: 658 },
	{ lat: 20, lon: 17.69, elevation: 1643 },
	{ lat: 20, lon: 18.46, elevation: 2422 },
	{ lat: 20, lon: 19.23, elevation: 637 },
	{ lat: 20, lon: 20.0, elevation: 545 },
	{ lat: 20, lon: 20.77, elevation: 524 },
	{ lat: 20, lon: 21.54, elevation: 503 },
	{ lat: 20, lon: 22.31, elevation: 619 },
	{ lat: 20, lon: 23.08, elevation: 727 },
	{ lat: 20, lon: 23.85, elevation: 703 },
	{ lat: 20, lon: 24.62, elevation: 773 },
	{ lat: 20, lon: 25.38, elevation: 621 },
	{ lat: 20, lon: 26.15, elevation: 570 },
	{ lat: 20, lon: 26.92, elevation: 593 },
	{ lat: 20, lon: 27.69, elevation: 502 },
	{ lat: 20, lon: 28.46, elevation: 411 },
	{ lat: 20, lon: 29.23, elevation: 292 },
	{ lat: 20, lon: 30.0, elevation: 296 },
	{ lat: 20, lon: 30.77, elevation: 263 },
	{ lat: 20, lon: 31.54, elevation: 349 },
	{ lat: 20, lon: 32.31, elevation: 395 },
	{ lat: 20, lon: 33.08, elevation: 353 },
	{ lat: 20, lon: 33.85, elevation: 388 },
	{ lat: 20, lon: 34.62, elevation: 428 },
	{ lat: 20, lon: 35.38, elevation: 554 },
	{ lat: 20, lon: 36.15, elevation: 680 },
	{ lat: 20, lon: 36.92, elevation: 335 },
	{ lat: 20, lon: 40.77, elevation: 82 },
	{ lat: 20, lon: 41.54, elevation: 2144 },
	{ lat: 20, lon: 42.31, elevation: 1254 },
	{ lat: 20, lon: 43.08, elevation: 1203 },
	{ lat: 20, lon: 43.85, elevation: 951 },
	{ lat: 20, lon: 44.62, elevation: 841 },
	{ lat: 20, lon: 45.38, elevation: 682 },
	{ lat: 20, lon: 46.15, elevation: 644 },
	{ lat: 20, lon: 46.92, elevation: 509 },
	{ lat: 20, lon: 47.69, elevation: 449 },
	{ lat: 20, lon: 48.46, elevation: 375 },
	{ lat: 20, lon: 49.23, elevation: 320 },
	{ lat: 20, lon: 50.0, elevation: 257 },
	{ lat: 20, lon: 50.77, elevation: 233 },
	{ lat: 20, lon: 51.54, elevation: 192 },
	{ lat: 20, lon: 52.31, elevation: 166 },
	{ lat: 20, lon: 53.08, elevation: 158 },
	{ lat: 20, lon: 53.85, elevation: 95 },
	{ lat: 20, lon: 54.62, elevation: 113 },
	{ lat: 20, lon: 55.38, elevation: 94 },
	{ lat: 20, lon: 56.15, elevation: 150 },
	{ lat: 20, lon: 56.92, elevation: 158 },
	{ lat: 20, lon: 57.69, elevation: 115 },
	{ lat: 20, lon: 73.08, elevation: 213 },
	{ lat: 20, lon: 73.85, elevation: 573 },
	{ lat: 20, lon: 74.62, elevation: 558 },
	{ lat: 20, lon: 75.38, elevation: 725 },
	{ lat: 20, lon: 76.15, elevation: 526 },
	{ lat: 20, lon: 76.92, elevation: 519 },
	{ lat: 20, lon: 77.69, elevation: 469 },
	{ lat: 20, lon: 78.46, elevation: 285 },
	{ lat: 20, lon: 79.23, elevation: 198 },
	{ lat: 20, lon: 80.0, elevation: 204 },
	{ lat: 20, lon: 80.77, elevation: 350 },
	{ lat: 20, lon: 81.54, elevation: 665 },
	{ lat: 20, lon: 82.31, elevation: 446 },
	{ lat: 20, lon: 83.08, elevation: 586 },
	{ lat: 20, lon: 83.85, elevation: 558 },
	{ lat: 20, lon: 84.62, elevation: 109 },
	{ lat: 20, lon: 85.38, elevation: 57 },
	{ lat: 20, lon: 86.15, elevation: 7 },
	{ lat: 20, lon: 93.08, elevation: -1 },
	{ lat: 20, lon: 93.85, elevation: 19 },
	{ lat: 20, lon: 94.62, elevation: 176 },
	{ lat: 20, lon: 95.38, elevation: 107 },
	{ lat: 20, lon: 96.15, elevation: 182 },
	{ lat: 20, lon: 96.92, elevation: 959 },
	{ lat: 20, lon: 97.69, elevation: 660 },
	{ lat: 20, lon: 98.46, elevation: 1169 },
	{ lat: 20, lon: 99.23, elevation: 569 },
	{ lat: 20, lon: 100.0, elevation: 383 },
	{ lat: 20, lon: 100.77, elevation: 599 },
	{ lat: 20, lon: 101.54, elevation: 911 },
	{ lat: 20, lon: 102.31, elevation: 435 },
	{ lat: 20, lon: 103.08, elevation: 653 },
	{ lat: 20, lon: 103.85, elevation: 643 },
	{ lat: 20, lon: 104.62, elevation: 403 },
	{ lat: 20, lon: 105.38, elevation: 139 },
	{ lat: 20, lon: 106.15, elevation: 3 },
	{ lat: 20, lon: 110.77, elevation: 19 },
	{ lat: 21, lon: -104.9, elevation: 1208 },
	{ lat: 21, lon: -104.13, elevation: 1104 },
	{ lat: 21, lon: -103.35, elevation: 1408 },
	{ lat: 21, lon: -102.58, elevation: 1910 },
	{ lat: 21, lon: -101.81, elevation: 1756 },
	{ lat: 21, lon: -101.03, elevation: 2192 },
	{ lat: 21, lon: -100.26, elevation: 2546 },
	{ lat: 21, lon: -99.48, elevation: 1496 },
	{ lat: 21, lon: -98.71, elevation: 956 },
	{ lat: 21, lon: -97.94, elevation: 104 },
	{ lat: 21, lon: -90.19, elevation: 5 },
	{ lat: 21, lon: -89.42, elevation: 11 },
	{ lat: 21, lon: -88.65, elevation: 19 },
	{ lat: 21, lon: -87.87, elevation: 21 },
	{ lat: 21, lon: -87.1, elevation: 19 },
	{ lat: 21, lon: -77.81, elevation: 125 },
	{ lat: 21, lon: -77.03, elevation: 107 },
	{ lat: 21, lon: -76.26, elevation: 79 },
	{ lat: 21, lon: -73.16, elevation: 5 },
	{ lat: 21, lon: -16.65, elevation: 12 },
	{ lat: 21, lon: -15.87, elevation: 100 },
	{ lat: 21, lon: -15.1, elevation: 134 },
	{ lat: 21, lon: -14.32, elevation: 183 },
	{ lat: 21, lon: -13.55, elevation: 179 },
	{ lat: 21, lon: -12.77, elevation: 395 },
	{ lat: 21, lon: -12.0, elevation: 561 },
	{ lat: 21, lon: -11.23, elevation: 370 },
	{ lat: 21, lon: -10.45, elevation: 388 },
	{ lat: 21, lon: -9.68, elevation: 390 },
	{ lat: 21, lon: -8.9, elevation: 367 },
	{ lat: 21, lon: -8.13, elevation: 381 },
	{ lat: 21, lon: -7.35, elevation: 352 },
	{ lat: 21, lon: -6.58, elevation: 317 },
	{ lat: 21, lon: -5.81, elevation: 294 },
	{ lat: 21, lon: -5.03, elevation: 275 },
	{ lat: 21, lon: -4.26, elevation: 276 },
	{ lat: 21, lon: -3.48, elevation: 303 },
	{ lat: 21, lon: -2.71, elevation: 315 },
	{ lat: 21, lon: -1.94, elevation: 302 },
	{ lat: 21, lon: -1.16, elevation: 300 },
	{ lat: 21, lon: -0.39, elevation: 346 },
	{ lat: 21, lon: 0.39, elevation: 444 },
	{ lat: 21, lon: 1.16, elevation: 417 },
	{ lat: 21, lon: 1.94, elevation: 513 },
	{ lat: 21, lon: 2.71, elevation: 606 },
	{ lat: 21, lon: 3.48, elevation: 593 },
	{ lat: 21, lon: 4.26, elevation: 620 },
	{ lat: 21, lon: 5.03, elevation: 581 },
	{ lat: 21, lon: 5.81, elevation: 591 },
	{ lat: 21, lon: 6.58, elevation: 582 },
	{ lat: 21, lon: 7.35, elevation: 569 },
	{ lat: 21, lon: 8.13, elevation: 623 },
	{ lat: 21, lon: 8.9, elevation: 634 },
	{ lat: 21, lon: 9.68, elevation: 577 },
	{ lat: 21, lon: 10.45, elevation: 503 },
	{ lat: 21, lon: 11.23, elevation: 464 },
	{ lat: 21, lon: 12.0, elevation: 478 },
	{ lat: 21, lon: 12.77, elevation: 597 },
	{ lat: 21, lon: 13.55, elevation: 615 },
	{ lat: 21, lon: 14.32, elevation: 461 },
	{ lat: 21, lon: 15.1, elevation: 529 },
	{ lat: 21, lon: 15.87, elevation: 682 },
	{ lat: 21, lon: 16.65, elevation: 2467 },
	{ lat: 21, lon: 17.42, elevation: 1827 },
	{ lat: 21, lon: 18.19, elevation: 1718 },
	{ lat: 21, lon: 18.97, elevation: 1029 },
	{ lat: 21, lon: 19.74, elevation: 664 },
	{ lat: 21, lon: 20.52, elevation: 603 },
	{ lat: 21, lon: 21.29, elevation: 557 },
	{ lat: 21, lon: 22.06, elevation: 588 },
	{ lat: 21, lon: 22.84, elevation: 654 },
	{ lat: 21, lon: 23.61, elevation: 615 },
	{ lat: 21, lon: 24.39, elevation: 687 },
	{ lat: 21, lon: 25.16, elevation: 687 },
	{ lat: 21, lon: 25.94, elevation: 644 },
	{ lat: 21, lon: 26.71, elevation: 571 },
	{ lat: 21, lon: 27.48, elevation: 412 },
	{ lat: 21, lon: 28.26, elevation: 378 },
	{ lat: 21, lon: 29.03, elevation: 325 },
	{ lat: 21, lon: 29.81, elevation: 343 },
	{ lat: 21, lon: 30.58, elevation: 186 },
	{ lat: 21, lon: 31.35, elevation: 352 },
	{ lat: 21, lon: 32.13, elevation: 496 },
	{ lat: 21, lon: 32.9, elevation: 480 },
	{ lat: 21, lon: 33.68, elevation: 355 },
	{ lat: 21, lon: 34.45, elevation: 538 },
	{ lat: 21, lon: 35.23, elevation: 539 },
	{ lat: 21, lon: 36.0, elevation: 430 },
	{ lat: 21, lon: 36.77, elevation: 404 },
	{ lat: 21, lon: 39.87, elevation: 244 },
	{ lat: 21, lon: 40.65, elevation: 1630 },
	{ lat: 21, lon: 41.42, elevation: 1243 },
	{ lat: 21, lon: 42.19, elevation: 1310 },
	{ lat: 21, lon: 42.97, elevation: 987 },
	{ lat: 21, lon: 43.74, elevation: 825 },
	{ lat: 21, lon: 44.52, elevation: 861 },
	{ lat: 21, lon: 45.29, elevation: 651 },
	{ lat: 21, lon: 46.06, elevation: 703 },
	{ lat: 21, lon: 46.84, elevation: 514 },
	{ lat: 21, lon: 47.61, elevation: 385 },
	{ lat: 21, lon: 48.39, elevation: 324 },
	{ lat: 21, lon: 49.16, elevation: 276 },
	{ lat: 21, lon: 49.94, elevation: 235 },
	{ lat: 21, lon: 50.71, elevation: 198 },
	{ lat: 21, lon: 51.48, elevation: 157 },
	{ lat: 21, lon: 52.26, elevation: 130 },
	{ lat: 21, lon: 53.03, elevation: 131 },
	{ lat: 21, lon: 53.81, elevation: 161 },
	{ lat: 21, lon: 54.58, elevation: 72 },
	{ lat: 21, lon: 55.35, elevation: 87 },
	{ lat: 21, lon: 56.13, elevation: 77 },
	{ lat: 21, lon: 56.9, elevation: 128 },
	{ lat: 21, lon: 57.68, elevation: 37 },
	{ lat: 21, lon: 58.45, elevation: 38 },
	{ lat: 21, lon: 70.84, elevation: 124 },
	{ lat: 21, lon: 71.61, elevation: 4 },
	{ lat: 21, lon: 73.16, elevation: 34 },
	{ lat: 21, lon: 73.94, elevation: 563 },
	{ lat: 21, lon: 74.71, elevation: 315 },
	{ lat: 21, lon: 75.48, elevation: 229 },
	{ lat: 21, lon: 76.26, elevation: 222 },
	{ lat: 21, lon: 77.03, elevation: 274 },
	{ lat: 21, lon: 77.81, elevation: 333 },
	{ lat: 21, lon: 78.58, elevation: 420 },
	{ lat: 21, lon: 79.35, elevation: 271 },
	{ lat: 21, lon: 80.13, elevation: 270 },
	{ lat: 21, lon: 80.9, elevation: 300 },
	{ lat: 21, lon: 81.68, elevation: 294 },
	{ lat: 21, lon: 82.45, elevation: 339 },
	{ lat: 21, lon: 83.23, elevation: 175 },
	{ lat: 21, lon: 84.0, elevation: 126 },
	{ lat: 21, lon: 84.77, elevation: 280 },
	{ lat: 21, lon: 85.55, elevation: 106 },
	{ lat: 21, lon: 86.32, elevation: 22 },
	{ lat: 21, lon: 92.52, elevation: 3 },
	{ lat: 21, lon: 93.29, elevation: 259 },
	{ lat: 21, lon: 94.06, elevation: 482 },
	{ lat: 21, lon: 94.84, elevation: 45 },
	{ lat: 21, lon: 95.61, elevation: 329 },
	{ lat: 21, lon: 96.39, elevation: 618 },
	{ lat: 21, lon: 97.16, elevation: 912 },
	{ lat: 21, lon: 97.94, elevation: 907 },
	{ lat: 21, lon: 98.71, elevation: 591 },
	{ lat: 21, lon: 99.48, elevation: 1315 },
	{ lat: 21, lon: 100.26, elevation: 1057 },
	{ lat: 21, lon: 101.03, elevation: 1195 },
	{ lat: 21, lon: 101.81, elevation: 783 },
	{ lat: 21, lon: 102.58, elevation: 827 },
	{ lat: 21, lon: 103.35, elevation: 1223 },
	{ lat: 21, lon: 104.13, elevation: 1104 },
	{ lat: 21, lon: 104.9, elevation: 534 },
	{ lat: 21, lon: 105.68, elevation: 15 },
	{ lat: 21, lon: 106.45, elevation: 2 },
	{ lat: 21, lon: 107.23, elevation: 104 },
	{ lat: 21, lon: 110.32, elevation: 5 },
	{ lat: 22, lon: -159.74, elevation: 10 },
	{ lat: 22, lon: -105.19, elevation: 21 },
	{ lat: 22, lon: -104.42, elevation: 1740 },
	{ lat: 22, lon: -103.64, elevation: 1742 },
	{ lat: 22, lon: -102.86, elevation: 1613 },
	{ lat: 22, lon: -102.08, elevation: 2039 },
	{ lat: 22, lon: -101.3, elevation: 2120 },
	{ lat: 22, lon: -100.52, elevation: 1548 },
	{ lat: 22, lon: -99.74, elevation: 1144 },
	{ lat: 22, lon: -98.96, elevation: 152 },
	{ lat: 22, lon: -98.18, elevation: 11 },
	{ lat: 22, lon: -84.16, elevation: 6 },
	{ lat: 22, lon: -80.26, elevation: 290 },
	{ lat: 22, lon: -79.48, elevation: 83 },
	{ lat: 22, lon: -78.7, elevation: 44 },
	{ lat: 22, lon: -16.36, elevation: 55 },
	{ lat: 22, lon: -15.58, elevation: 203 },
	{ lat: 22, lon: -14.81, elevation: 203 },
	{ lat: 22, lon: -14.03, elevation: 232 },
	{ lat: 22, lon: -13.25, elevation: 253 },
	{ lat: 22, lon: -12.47, elevation: 294 },
	{ lat: 22, lon: -11.69, elevation: 337 },
	{ lat: 22, lon: -10.91, elevation: 363 },
	{ lat: 22, lon: -10.13, elevation: 338 },
	{ lat: 22, lon: -9.35, elevation: 334 },
	{ lat: 22, lon: -8.57, elevation: 339 },
	{ lat: 22, lon: -7.79, elevation: 366 },
	{ lat: 22, lon: -7.01, elevation: 333 },
	{ lat: 22, lon: -6.23, elevation: 330 },
	{ lat: 22, lon: -5.45, elevation: 239 },
	{ lat: 22, lon: -4.68, elevation: 319 },
	{ lat: 22, lon: -3.9, elevation: 271 },
	{ lat: 22, lon: -3.12, elevation: 210 },
	{ lat: 22, lon: -2.34, elevation: 351 },
	{ lat: 22, lon: -1.56, elevation: 324 },
	{ lat: 22, lon: -0.78, elevation: 356 },
	{ lat: 22, lon: 0.0, elevation: 346 },
	{ lat: 22, lon: 0.78, elevation: 396 },
	{ lat: 22, lon: 1.56, elevation: 412 },
	{ lat: 22, lon: 2.34, elevation: 454 },
	{ lat: 22, lon: 3.12, elevation: 509 },
	{ lat: 22, lon: 3.9, elevation: 568 },
	{ lat: 22, lon: 4.68, elevation: 701 },
	{ lat: 22, lon: 5.45, elevation: 765 },
	{ lat: 22, lon: 6.23, elevation: 781 },
	{ lat: 22, lon: 7.01, elevation: 705 },
	{ lat: 22, lon: 7.79, elevation: 681 },
	{ lat: 22, lon: 8.57, elevation: 754 },
	{ lat: 22, lon: 9.35, elevation: 688 },
	{ lat: 22, lon: 10.13, elevation: 625 },
	{ lat: 22, lon: 10.91, elevation: 578 },
	{ lat: 22, lon: 11.69, elevation: 810 },
	{ lat: 22, lon: 12.47, elevation: 668 },
	{ lat: 22, lon: 13.25, elevation: 573 },
	{ lat: 22, lon: 14.03, elevation: 541 },
	{ lat: 22, lon: 14.81, elevation: 523 },
	{ lat: 22, lon: 15.58, elevation: 781 },
	{ lat: 22, lon: 16.36, elevation: 893 },
	{ lat: 22, lon: 17.14, elevation: 1076 },
	{ lat: 22, lon: 17.92, elevation: 871 },
	{ lat: 22, lon: 18.7, elevation: 1142 },
	{ lat: 22, lon: 19.48, elevation: 843 },
	{ lat: 22, lon: 20.26, elevation: 597 },
	{ lat: 22, lon: 21.04, elevation: 560 },
	{ lat: 22, lon: 21.82, elevation: 520 },
	{ lat: 22, lon: 22.6, elevation: 576 },
	{ lat: 22, lon: 23.38, elevation: 570 },
	{ lat: 22, lon: 24.16, elevation: 539 },
	{ lat: 22, lon: 24.94, elevation: 826 },
	{ lat: 22, lon: 25.71, elevation: 680 },
	{ lat: 22, lon: 26.49, elevation: 627 },
	{ lat: 22, lon: 27.27, elevation: 403 },
	{ lat: 22, lon: 28.05, elevation: 330 },
	{ lat: 22, lon: 28.83, elevation: 285 },
	{ lat: 22, lon: 29.61, elevation: 265 },
	{ lat: 22, lon: 30.39, elevation: 338 },
	{ lat: 22, lon: 31.17, elevation: 253 },
	{ lat: 22, lon: 31.95, elevation: 358 },
	{ lat: 22, lon: 32.73, elevation: 417 },
	{ lat: 22, lon: 33.51, elevation: 393 },
	{ lat: 22, lon: 34.29, elevation: 362 },
	{ lat: 22, lon: 35.06, elevation: 625 },
	{ lat: 22, lon: 35.84, elevation: 400 },
	{ lat: 22, lon: 36.62, elevation: 264 },
	{ lat: 22, lon: 38.96, elevation: 0 },
	{ lat: 22, lon: 39.74, elevation: 368 },
	{ lat: 22, lon: 40.52, elevation: 1064 },
	{ lat: 22, lon: 41.3, elevation: 1020 },
	{ lat: 22, lon: 42.08, elevation: 1003 },
	{ lat: 22, lon: 42.86, elevation: 884 },
	{ lat: 22, lon: 43.64, elevation: 968 },
	{ lat: 22, lon: 44.42, elevation: 986 },
	{ lat: 22, lon: 45.19, elevation: 759 },
	{ lat: 22, lon: 45.97, elevation: 700 },
	{ lat: 22, lon: 46.75, elevation: 516 },
	{ lat: 22, lon: 47.53, elevation: 422 },
	{ lat: 22, lon: 48.31, elevation: 272 },
	{ lat: 22, lon: 49.09, elevation: 239 },
	{ lat: 22, lon: 49.87, elevation: 189 },
	{ lat: 22, lon: 50.65, elevation: 164 },
	{ lat: 22, lon: 51.43, elevation: 111 },
	{ lat: 22, lon: 52.21, elevation: 108 },
	{ lat: 22, lon: 52.99, elevation: 96 },
	{ lat: 22, lon: 53.77, elevation: 119 },
	{ lat: 22, lon: 54.55, elevation: 69 },
	{ lat: 22, lon: 55.32, elevation: 86 },
	{ lat: 22, lon: 56.1, elevation: 87 },
	{ lat: 22, lon: 56.88, elevation: 187 },
	{ lat: 22, lon: 57.66, elevation: 203 },
	{ lat: 22, lon: 58.44, elevation: 192 },
	{ lat: 22, lon: 59.22, elevation: 171 },
	{ lat: 22, lon: 69.35, elevation: 29 },
	{ lat: 22, lon: 70.13, elevation: 122 },
	{ lat: 22, lon: 70.91, elevation: 169 },
	{ lat: 22, lon: 71.69, elevation: 61 },
	{ lat: 22, lon: 73.25, elevation: 34 },
	{ lat: 22, lon: 74.03, elevation: 384 },
	{ lat: 22, lon: 74.81, elevation: 190 },
	{ lat: 22, lon: 75.58, elevation: 262 },
	{ lat: 22, lon: 76.36, elevation: 300 },
	{ lat: 22, lon: 77.14, elevation: 436 },
	{ lat: 22, lon: 77.92, elevation: 618 },
	{ lat: 22, lon: 78.7, elevation: 707 },
	{ lat: 22, lon: 79.48, elevation: 622 },
	{ lat: 22, lon: 80.26, elevation: 595 },
	{ lat: 22, lon: 81.04, elevation: 778 },
	{ lat: 22, lon: 81.82, elevation: 271 },
	{ lat: 22, lon: 82.6, elevation: 267 },
	{ lat: 22, lon: 83.38, elevation: 252 },
	{ lat: 22, lon: 84.16, elevation: 223 },
	{ lat: 22, lon: 84.94, elevation: 192 },
	{ lat: 22, lon: 85.71, elevation: 376 },
	{ lat: 22, lon: 86.49, elevation: 334 },
	{ lat: 22, lon: 87.27, elevation: 27 },
	{ lat: 22, lon: 88.05, elevation: 0 },
	{ lat: 22, lon: 88.83, elevation: 5 },
	{ lat: 22, lon: 89.61, elevation: 7 },
	{ lat: 22, lon: 91.95, elevation: 6 },
	{ lat: 22, lon: 92.73, elevation: 111 },
	{ lat: 22, lon: 93.51, elevation: 2247 },
	{ lat: 22, lon: 94.29, elevation: 631 },
	{ lat: 22, lon: 95.06, elevation: 119 },
	{ lat: 22, lon: 95.84, elevation: 134 },
	{ lat: 22, lon: 96.62, elevation: 1384 },
	{ lat: 22, lon: 97.4, elevation: 689 },
	{ lat: 22, lon: 98.18, elevation: 817 },
	{ lat: 22, lon: 98.96, elevation: 1217 },
	{ lat: 22, lon: 99.74, elevation: 1354 },
	{ lat: 22, lon: 100.52, elevation: 1313 },
	{ lat: 22, lon: 101.3, elevation: 960 },
	{ lat: 22, lon: 102.08, elevation: 1268 },
	{ lat: 22, lon: 102.86, elevation: 439 },
	{ lat: 22, lon: 103.64, elevation: 860 },
	{ lat: 22, lon: 104.42, elevation: 564 },
	{ lat: 22, lon: 105.19, elevation: 103 },
	{ lat: 22, lon: 105.97, elevation: 348 },
	{ lat: 22, lon: 106.75, elevation: 556 },
	{ lat: 22, lon: 107.53, elevation: 569 },
	{ lat: 22, lon: 108.31, elevation: 23 },
	{ lat: 22, lon: 109.09, elevation: 60 },
	{ lat: 22, lon: 109.87, elevation: 67 },
	{ lat: 22, lon: 110.65, elevation: 56 },
	{ lat: 22, lon: 111.43, elevation: 131 },
	{ lat: 22, lon: 112.21, elevation: 22 },
	{ lat: 22, lon: 112.99, elevation: 0 },
	{ lat: 22, lon: 120.78, elevation: 88 },
	{ lat: 23, lon: -105.49, elevation: 413 },
	{ lat: 23, lon: -104.71, elevation: 2419 },
	{ lat: 23, lon: -103.92, elevation: 2255 },
	{ lat: 23, lon: -103.14, elevation: 2267 },
	{ lat: 23, lon: -102.35, elevation: 2020 },
	{ lat: 23, lon: -101.57, elevation: 2041 },
	{ lat: 23, lon: -100.78, elevation: 1868 },
	{ lat: 23, lon: -100.0, elevation: 1406 },
	{ lat: 23, lon: -99.22, elevation: 1032 },
	{ lat: 23, lon: -98.43, elevation: 286 },
	{ lat: 23, lon: -82.75, elevation: 26 },
	{ lat: 23, lon: -81.96, elevation: 107 },
	{ lat: 23, lon: -81.18, elevation: 7 },
	{ lat: 23, lon: -74.9, elevation: 3 },
	{ lat: 23, lon: -16.08, elevation: 66 },
	{ lat: 23, lon: -15.29, elevation: 208 },
	{ lat: 23, lon: -14.51, elevation: 307 },
	{ lat: 23, lon: -13.73, elevation: 305 },
	{ lat: 23, lon: -12.94, elevation: 330 },
	{ lat: 23, lon: -12.16, elevation: 323 },
	{ lat: 23, lon: -11.37, elevation: 268 },
	{ lat: 23, lon: -10.59, elevation: 252 },
	{ lat: 23, lon: -9.8, elevation: 230 },
	{ lat: 23, lon: -9.02, elevation: 265 },
	{ lat: 23, lon: -8.24, elevation: 286 },
	{ lat: 23, lon: -7.45, elevation: 307 },
	{ lat: 23, lon: -6.67, elevation: 315 },
	{ lat: 23, lon: -5.88, elevation: 289 },
	{ lat: 23, lon: -5.1, elevation: 278 },
	{ lat: 23, lon: -4.31, elevation: 309 },
	{ lat: 23, lon: -3.53, elevation: 298 },
	{ lat: 23, lon: -2.75, elevation: 242 },
	{ lat: 23, lon: -1.96, elevation: 346 },
	{ lat: 23, lon: -1.18, elevation: 317 },
	{ lat: 23, lon: -0.39, elevation: 340 },
	{ lat: 23, lon: 0.39, elevation: 345 },
	{ lat: 23, lon: 1.18, elevation: 380 },
	{ lat: 23, lon: 1.96, elevation: 400 },
	{ lat: 23, lon: 2.75, elevation: 492 },
	{ lat: 23, lon: 3.53, elevation: 584 },
	{ lat: 23, lon: 4.31, elevation: 683 },
	{ lat: 23, lon: 5.1, elevation: 1048 },
	{ lat: 23, lon: 5.88, elevation: 1567 },
	{ lat: 23, lon: 6.67, elevation: 1140 },
	{ lat: 23, lon: 7.45, elevation: 913 },
	{ lat: 23, lon: 8.24, elevation: 1218 },
	{ lat: 23, lon: 9.02, elevation: 876 },
	{ lat: 23, lon: 9.8, elevation: 765 },
	{ lat: 23, lon: 10.59, elevation: 778 },
	{ lat: 23, lon: 11.37, elevation: 827 },
	{ lat: 23, lon: 12.16, elevation: 802 },
	{ lat: 23, lon: 12.94, elevation: 753 },
	{ lat: 23, lon: 13.73, elevation: 761 },
	{ lat: 23, lon: 14.51, elevation: 585 },
	{ lat: 23, lon: 15.29, elevation: 994 },
	{ lat: 23, lon: 16.08, elevation: 715 },
	{ lat: 23, lon: 16.86, elevation: 608 },
	{ lat: 23, lon: 17.65, elevation: 568 },
	{ lat: 23, lon: 18.43, elevation: 581 },
	{ lat: 23, lon: 19.22, elevation: 831 },
	{ lat: 23, lon: 20.0, elevation: 682 },
	{ lat: 23, lon: 20.78, elevation: 546 },
	{ lat: 23, lon: 21.57, elevation: 513 },
	{ lat: 23, lon: 22.35, elevation: 467 },
	{ lat: 23, lon: 23.14, elevation: 509 },
	{ lat: 23, lon: 23.92, elevation: 476 },
	{ lat: 23, lon: 24.71, elevation: 613 },
	{ lat: 23, lon: 25.49, elevation: 655 },
	{ lat: 23, lon: 26.27, elevation: 828 },
	{ lat: 23, lon: 27.06, elevation: 457 },
	{ lat: 23, lon: 27.84, elevation: 328 },
	{ lat: 23, lon: 28.63, elevation: 257 },
	{ lat: 23, lon: 29.41, elevation: 222 },
	{ lat: 23, lon: 30.2, elevation: 226 },
	{ lat: 23, lon: 30.98, elevation: 161 },
	{ lat: 23, lon: 31.76, elevation: 267 },
	{ lat: 23, lon: 32.55, elevation: 239 },
	{ lat: 23, lon: 33.33, elevation: 299 },
	{ lat: 23, lon: 34.12, elevation: 585 },
	{ lat: 23, lon: 34.9, elevation: 377 },
	{ lat: 23, lon: 38.82, elevation: 5 },
	{ lat: 23, lon: 39.61, elevation: 453 },
	{ lat: 23, lon: 40.39, elevation: 978 },
	{ lat: 23, lon: 41.18, elevation: 1029 },
	{ lat: 23, lon: 41.96, elevation: 931 },
	{ lat: 23, lon: 42.75, elevation: 975 },
	{ lat: 23, lon: 43.53, elevation: 1002 },
	{ lat: 23, lon: 44.31, elevation: 826 },
	{ lat: 23, lon: 45.1, elevation: 712 },
	{ lat: 23, lon: 45.88, elevation: 617 },
	{ lat: 23, lon: 46.67, elevation: 729 },
	{ lat: 23, lon: 47.45, elevation: 531 },
	{ lat: 23, lon: 48.24, elevation: 334 },
	{ lat: 23, lon: 49.02, elevation: 192 },
	{ lat: 23, lon: 49.8, elevation: 153 },
	{ lat: 23, lon: 50.59, elevation: 129 },
	{ lat: 23, lon: 51.37, elevation: 75 },
	{ lat: 23, lon: 52.16, elevation: 66 },
	{ lat: 23, lon: 52.94, elevation: 141 },
	{ lat: 23, lon: 53.73, elevation: 89 },
	{ lat: 23, lon: 54.51, elevation: 116 },
	{ lat: 23, lon: 55.29, elevation: 112 },
	{ lat: 23, lon: 56.08, elevation: 210 },
	{ lat: 23, lon: 56.86, elevation: 540 },
	{ lat: 23, lon: 57.65, elevation: 1396 },
	{ lat: 23, lon: 58.43, elevation: 1052 },
	{ lat: 23, lon: 69.41, elevation: 91 },
	{ lat: 23, lon: 70.2, elevation: 5 },
	{ lat: 23, lon: 70.98, elevation: 39 },
	{ lat: 23, lon: 71.76, elevation: 32 },
	{ lat: 23, lon: 72.55, elevation: 46 },
	{ lat: 23, lon: 73.33, elevation: 115 },
	{ lat: 23, lon: 74.12, elevation: 282 },
	{ lat: 23, lon: 74.9, elevation: 432 },
	{ lat: 23, lon: 75.69, elevation: 509 },
	{ lat: 23, lon: 76.47, elevation: 498 },
	{ lat: 23, lon: 77.25, elevation: 500 },
	{ lat: 23, lon: 78.04, elevation: 494 },
	{ lat: 23, lon: 78.82, elevation: 339 },
	{ lat: 23, lon: 79.61, elevation: 388 },
	{ lat: 23, lon: 80.39, elevation: 694 },
	{ lat: 23, lon: 81.18, elevation: 826 },
	{ lat: 23, lon: 81.96, elevation: 539 },
	{ lat: 23, lon: 82.75, elevation: 524 },
	{ lat: 23, lon: 83.53, elevation: 715 },
	{ lat: 23, lon: 84.31, elevation: 741 },
	{ lat: 23, lon: 85.1, elevation: 539 },
	{ lat: 23, lon: 85.88, elevation: 220 },
	{ lat: 23, lon: 86.67, elevation: 133 },
	{ lat: 23, lon: 87.45, elevation: 68 },
	{ lat: 23, lon: 88.24, elevation: 16 },
	{ lat: 23, lon: 89.02, elevation: 12 },
	{ lat: 23, lon: 89.8, elevation: 7 },
	{ lat: 23, lon: 90.59, elevation: 0 },
	{ lat: 23, lon: 91.37, elevation: 7 },
	{ lat: 23, lon: 92.16, elevation: 51 },
	{ lat: 23, lon: 92.94, elevation: 516 },
	{ lat: 23, lon: 93.73, elevation: 964 },
	{ lat: 23, lon: 94.51, elevation: 221 },
	{ lat: 23, lon: 95.29, elevation: 151 },
	{ lat: 23, lon: 96.08, elevation: 185 },
	{ lat: 23, lon: 96.86, elevation: 788 },
	{ lat: 23, lon: 97.65, elevation: 744 },
	{ lat: 23, lon: 98.43, elevation: 1511 },
	{ lat: 23, lon: 99.22, elevation: 1274 },
	{ lat: 23, lon: 100.0, elevation: 1671 },
	{ lat: 23, lon: 100.78, elevation: 1443 },
	{ lat: 23, lon: 101.57, elevation: 1616 },
	{ lat: 23, lon: 102.35, elevation: 1399 },
	{ lat: 23, lon: 103.14, elevation: 1215 },
	{ lat: 23, lon: 103.92, elevation: 1225 },
	{ lat: 23, lon: 104.71, elevation: 758 },
	{ lat: 23, lon: 105.49, elevation: 343 },
	{ lat: 23, lon: 106.27, elevation: 881 },
	{ lat: 23, lon: 107.06, elevation: 539 },
	{ lat: 23, lon: 107.84, elevation: 93 },
	{ lat: 23, lon: 108.63, elevation: 173 },
	{ lat: 23, lon: 109.41, elevation: 52 },
	{ lat: 23, lon: 110.2, elevation: 198 },
	{ lat: 23, lon: 110.98, elevation: 350 },
	{ lat: 23, lon: 111.76, elevation: 180 },
	{ lat: 23, lon: 112.55, elevation: 2 },
	{ lat: 23, lon: 113.33, elevation: 20 },
	{ lat: 23, lon: 114.12, elevation: 7 },
	{ lat: 23, lon: 114.9, elevation: 61 },
	{ lat: 23, lon: 115.69, elevation: 32 },
	{ lat: 23, lon: 116.47, elevation: 4 },
	{ lat: 23, lon: 120.39, elevation: 113 },
	{ lat: 23, lon: 121.18, elevation: 235 },
	{ lat: 24, lon: -110.37, elevation: 56 },
	{ lat: 24, lon: -106.42, elevation: 277 },
	{ lat: 24, lon: -105.63, elevation: 2371 },
	{ lat: 24, lon: -104.84, elevation: 2338 },
	{ lat: 24, lon: -104.04, elevation: 1913 },
	{ lat: 24, lon: -103.25, elevation: 2077 },
	{ lat: 24, lon: -102.46, elevation: 1945 },
	{ lat: 24, lon: -101.67, elevation: 2076 },
	{ lat: 24, lon: -100.88, elevation: 1749 },
	{ lat: 24, lon: -100.09, elevation: 1577 },
	{ lat: 24, lon: -99.3, elevation: 270 },
	{ lat: 24, lon: -98.51, elevation: 129 },
	{ lat: 24, lon: -15.43, elevation: 61 },
	{ lat: 24, lon: -14.64, elevation: 192 },
	{ lat: 24, lon: -13.85, elevation: 343 },
	{ lat: 24, lon: -13.05, elevation: 542 },
	{ lat: 24, lon: -12.26, elevation: 242 },
	{ lat: 24, lon: -11.47, elevation: 204 },
	{ lat: 24, lon: -10.68, elevation: 222 },
	{ lat: 24, lon: -9.89, elevation: 247 },
	{ lat: 24, lon: -9.1, elevation: 245 },
	{ lat: 24, lon: -8.31, elevation: 313 },
	{ lat: 24, lon: -7.52, elevation: 353 },
	{ lat: 24, lon: -6.73, elevation: 283 },
	{ lat: 24, lon: -5.93, elevation: 319 },
	{ lat: 24, lon: -5.14, elevation: 327 },
	{ lat: 24, lon: -4.35, elevation: 327 },
	{ lat: 24, lon: -3.56, elevation: 282 },
	{ lat: 24, lon: -2.77, elevation: 288 },
	{ lat: 24, lon: -1.98, elevation: 309 },
	{ lat: 24, lon: -1.19, elevation: 293 },
	{ lat: 24, lon: -0.4, elevation: 303 },
	{ lat: 24, lon: 0.4, elevation: 330 },
	{ lat: 24, lon: 1.19, elevation: 368 },
	{ lat: 24, lon: 1.98, elevation: 427 },
	{ lat: 24, lon: 2.77, elevation: 539 },
	{ lat: 24, lon: 3.56, elevation: 575 },
	{ lat: 24, lon: 4.35, elevation: 720 },
	{ lat: 24, lon: 5.14, elevation: 1041 },
	{ lat: 24, lon: 5.93, elevation: 1152 },
	{ lat: 24, lon: 6.73, elevation: 1241 },
	{ lat: 24, lon: 7.52, elevation: 1221 },
	{ lat: 24, lon: 8.31, elevation: 1085 },
	{ lat: 24, lon: 9.1, elevation: 916 },
	{ lat: 24, lon: 9.89, elevation: 998 },
	{ lat: 24, lon: 10.68, elevation: 941 },
	{ lat: 24, lon: 11.47, elevation: 745 },
	{ lat: 24, lon: 12.26, elevation: 849 },
	{ lat: 24, lon: 13.05, elevation: 677 },
	{ lat: 24, lon: 13.85, elevation: 567 },
	{ lat: 24, lon: 14.64, elevation: 530 },
	{ lat: 24, lon: 15.43, elevation: 617 },
	{ lat: 24, lon: 16.22, elevation: 596 },
	{ lat: 24, lon: 17.01, elevation: 527 },
	{ lat: 24, lon: 17.8, elevation: 474 },
	{ lat: 24, lon: 18.59, elevation: 466 },
	{ lat: 24, lon: 19.38, elevation: 611 },
	{ lat: 24, lon: 20.18, elevation: 561 },
	{ lat: 24, lon: 20.97, elevation: 455 },
	{ lat: 24, lon: 21.76, elevation: 470 },
	{ lat: 24, lon: 22.55, elevation: 530 },
	{ lat: 24, lon: 23.34, elevation: 388 },
	{ lat: 24, lon: 24.13, elevation: 495 },
	{ lat: 24, lon: 24.92, elevation: 509 },
	{ lat: 24, lon: 25.71, elevation: 842 },
	{ lat: 24, lon: 26.51, elevation: 727 },
	{ lat: 24, lon: 27.3, elevation: 558 },
	{ lat: 24, lon: 28.09, elevation: 373 },
	{ lat: 24, lon: 28.88, elevation: 343 },
	{ lat: 24, lon: 29.67, elevation: 295 },
	{ lat: 24, lon: 30.46, elevation: 122 },
	{ lat: 24, lon: 31.25, elevation: 343 },
	{ lat: 24, lon: 32.04, elevation: 424 },
	{ lat: 24, lon: 32.84, elevation: 171 },
	{ lat: 24, lon: 33.63, elevation: 341 },
	{ lat: 24, lon: 34.42, elevation: 375 },
	{ lat: 24, lon: 35.21, elevation: 374 },
	{ lat: 24, lon: 38.37, elevation: 19 },
	{ lat: 24, lon: 39.16, elevation: 996 },
	{ lat: 24, lon: 39.96, elevation: 1005 },
	{ lat: 24, lon: 40.75, elevation: 830 },
	{ lat: 24, lon: 41.54, elevation: 904 },
	{ lat: 24, lon: 42.33, elevation: 964 },
	{ lat: 24, lon: 43.12, elevation: 994 },
	{ lat: 24, lon: 43.91, elevation: 983 },
	{ lat: 24, lon: 44.7, elevation: 1034 },
	{ lat: 24, lon: 45.49, elevation: 716 },
	{ lat: 24, lon: 46.29, elevation: 856 },
	{ lat: 24, lon: 47.08, elevation: 494 },
	{ lat: 24, lon: 47.87, elevation: 487 },
	{ lat: 24, lon: 48.66, elevation: 330 },
	{ lat: 24, lon: 49.45, elevation: 237 },
	{ lat: 24, lon: 50.24, elevation: 162 },
	{ lat: 24, lon: 51.03, elevation: 44 },
	{ lat: 24, lon: 52.62, elevation: 34 },
	{ lat: 24, lon: 53.41, elevation: 19 },
	{ lat: 24, lon: 54.2, elevation: 16 },
	{ lat: 24, lon: 54.99, elevation: 108 },
	{ lat: 24, lon: 55.78, elevation: 315 },
	{ lat: 24, lon: 56.57, elevation: 496 },
	{ lat: 24, lon: 67.65, elevation: 2 },
	{ lat: 24, lon: 68.44, elevation: 4 },
	{ lat: 24, lon: 69.23, elevation: 1 },
	{ lat: 24, lon: 70.02, elevation: 5 },
	{ lat: 24, lon: 70.81, elevation: 1 },
	{ lat: 24, lon: 71.6, elevation: 44 },
	{ lat: 24, lon: 72.4, elevation: 155 },
	{ lat: 24, lon: 73.19, elevation: 253 },
	{ lat: 24, lon: 73.98, elevation: 225 },
	{ lat: 24, lon: 74.77, elevation: 513 },
	{ lat: 24, lon: 75.56, elevation: 413 },
	{ lat: 24, lon: 76.35, elevation: 390 },
	{ lat: 24, lon: 77.14, elevation: 429 },
	{ lat: 24, lon: 77.93, elevation: 409 },
	{ lat: 24, lon: 78.73, elevation: 482 },
	{ lat: 24, lon: 79.52, elevation: 344 },
	{ lat: 24, lon: 80.31, elevation: 364 },
	{ lat: 24, lon: 81.1, elevation: 353 },
	{ lat: 24, lon: 81.89, elevation: 421 },
	{ lat: 24, lon: 82.68, elevation: 277 },
	{ lat: 24, lon: 83.47, elevation: 398 },
	{ lat: 24, lon: 84.26, elevation: 315 },
	{ lat: 24, lon: 85.05, elevation: 611 },
	{ lat: 24, lon: 85.85, elevation: 365 },
	{ lat: 24, lon: 86.64, elevation: 217 },
	{ lat: 24, lon: 87.43, elevation: 74 },
	{ lat: 24, lon: 88.22, elevation: 15 },
	{ lat: 24, lon: 89.01, elevation: 16 },
	{ lat: 24, lon: 89.8, elevation: 12 },
	{ lat: 24, lon: 90.59, elevation: 8 },
	{ lat: 24, lon: 91.38, elevation: 30 },
	{ lat: 24, lon: 92.18, elevation: 100 },
	{ lat: 24, lon: 92.97, elevation: 629 },
	{ lat: 24, lon: 93.76, elevation: 869 },
	{ lat: 24, lon: 94.55, elevation: 119 },
	{ lat: 24, lon: 95.34, elevation: 244 },
	{ lat: 24, lon: 96.13, elevation: 164 },
	{ lat: 24, lon: 96.92, elevation: 418 },
	{ lat: 24, lon: 97.71, elevation: 1093 },
	{ lat: 24, lon: 98.51, elevation: 1761 },
	{ lat: 24, lon: 99.3, elevation: 1669 },
	{ lat: 24, lon: 100.09, elevation: 1538 },
	{ lat: 24, lon: 100.88, elevation: 1759 },
	{ lat: 24, lon: 101.67, elevation: 1084 },
	{ lat: 24, lon: 102.46, elevation: 1969 },
	{ lat: 24, lon: 103.25, elevation: 1852 },
	{ lat: 24, lon: 104.04, elevation: 1983 },
	{ lat: 24, lon: 104.84, elevation: 1290 },
	{ lat: 24, lon: 105.63, elevation: 1021 },
	{ lat: 24, lon: 106.42, elevation: 188 },
	{ lat: 24, lon: 107.21, elevation: 425 },
	{ lat: 24, lon: 108.0, elevation: 210 },
	{ lat: 24, lon: 108.79, elevation: 407 },
	{ lat: 24, lon: 109.58, elevation: 117 },
	{ lat: 24, lon: 110.37, elevation: 351 },
	{ lat: 24, lon: 111.16, elevation: 341 },
	{ lat: 24, lon: 111.96, elevation: 78 },
	{ lat: 24, lon: 112.75, elevation: 373 },
	{ lat: 24, lon: 113.54, elevation: 369 },
	{ lat: 24, lon: 114.33, elevation: 683 },
	{ lat: 24, lon: 115.12, elevation: 94 },
	{ lat: 24, lon: 115.91, elevation: 132 },
	{ lat: 24, lon: 116.7, elevation: 652 },
	{ lat: 24, lon: 117.49, elevation: 613 },
	{ lat: 24, lon: 120.66, elevation: 79 },
	{ lat: 24, lon: 121.45, elevation: 1106 },
	{ lat: 25, lon: -111.5, elevation: 87 },
	{ lat: 25, lon: -107.52, elevation: 170 },
	{ lat: 25, lon: -106.73, elevation: 1096 },
	{ lat: 25, lon: -105.93, elevation: 2485 },
	{ lat: 25, lon: -105.13, elevation: 1984 },
	{ lat: 25, lon: -104.34, elevation: 1960 },
	{ lat: 25, lon: -103.54, elevation: 1568 },
	{ lat: 25, lon: -102.74, elevation: 1436 },
	{ lat: 25, lon: -101.95, elevation: 1714 },
	{ lat: 25, lon: -101.15, elevation: 2089 },
	{ lat: 25, lon: -100.35, elevation: 2644 },
	{ lat: 25, lon: -99.56, elevation: 331 },
	{ lat: 25, lon: -98.76, elevation: 139 },
	{ lat: 25, lon: -97.96, elevation: 8 },
	{ lat: 25, lon: -78.05, elevation: 14 },
	{ lat: 25, lon: -14.34, elevation: 59 },
	{ lat: 25, lon: -13.54, elevation: 217 },
	{ lat: 25, lon: -12.74, elevation: 357 },
	{ lat: 25, lon: -11.95, elevation: 345 },
	{ lat: 25, lon: -11.15, elevation: 289 },
	{ lat: 25, lon: -10.35, elevation: 287 },
	{ lat: 25, lon: -9.56, elevation: 290 },
	{ lat: 25, lon: -8.76, elevation: 334 },
	{ lat: 25, lon: -7.96, elevation: 391 },
	{ lat: 25, lon: -7.17, elevation: 345 },
	{ lat: 25, lon: -6.37, elevation: 358 },
	{ lat: 25, lon: -5.58, elevation: 320 },
	{ lat: 25, lon: -4.78, elevation: 216 },
	{ lat: 25, lon: -3.98, elevation: 234 },
	{ lat: 25, lon: -3.19, elevation: 334 },
	{ lat: 25, lon: -2.39, elevation: 277 },
	{ lat: 25, lon: -1.59, elevation: 257 },
	{ lat: 25, lon: -0.8, elevation: 277 },
	{ lat: 25, lon: 0.0, elevation: 298 },
	{ lat: 25, lon: 0.8, elevation: 324 },
	{ lat: 25, lon: 1.59, elevation: 412 },
	{ lat: 25, lon: 2.39, elevation: 357 },
	{ lat: 25, lon: 3.19, elevation: 477 },
	{ lat: 25, lon: 3.98, elevation: 805 },
	{ lat: 25, lon: 4.78, elevation: 852 },
	{ lat: 25, lon: 5.58, elevation: 869 },
	{ lat: 25, lon: 6.37, elevation: 1025 },
	{ lat: 25, lon: 7.17, elevation: 1317 },
	{ lat: 25, lon: 7.96, elevation: 1616 },
	{ lat: 25, lon: 8.76, elevation: 1300 },
	{ lat: 25, lon: 9.56, elevation: 1322 },
	{ lat: 25, lon: 10.35, elevation: 1141 },
	{ lat: 25, lon: 11.15, elevation: 673 },
	{ lat: 25, lon: 11.95, elevation: 823 },
	{ lat: 25, lon: 12.74, elevation: 789 },
	{ lat: 25, lon: 13.54, elevation: 595 },
	{ lat: 25, lon: 14.34, elevation: 522 },
	{ lat: 25, lon: 15.13, elevation: 579 },
	{ lat: 25, lon: 15.93, elevation: 496 },
	{ lat: 25, lon: 16.73, elevation: 455 },
	{ lat: 25, lon: 17.52, elevation: 417 },
	{ lat: 25, lon: 18.32, elevation: 401 },
	{ lat: 25, lon: 19.12, elevation: 398 },
	{ lat: 25, lon: 19.91, elevation: 383 },
	{ lat: 25, lon: 20.71, elevation: 336 },
	{ lat: 25, lon: 21.5, elevation: 316 },
	{ lat: 25, lon: 22.3, elevation: 323 },
	{ lat: 25, lon: 23.1, elevation: 433 },
	{ lat: 25, lon: 23.89, elevation: 396 },
	{ lat: 25, lon: 24.69, elevation: 470 },
	{ lat: 25, lon: 25.49, elevation: 550 },
	{ lat: 25, lon: 26.28, elevation: 538 },
	{ lat: 25, lon: 27.08, elevation: 501 },
	{ lat: 25, lon: 27.88, elevation: 357 },
	{ lat: 25, lon: 28.67, elevation: 320 },
	{ lat: 25, lon: 29.47, elevation: 332 },
	{ lat: 25, lon: 30.27, elevation: 207 },
	{ lat: 25, lon: 31.06, elevation: 398 },
	{ lat: 25, lon: 31.86, elevation: 479 },
	{ lat: 25, lon: 32.65, elevation: 135 },
	{ lat: 25, lon: 33.45, elevation: 218 },
	{ lat: 25, lon: 34.25, elevation: 506 },
	{ lat: 25, lon: 37.43, elevation: 231 },
	{ lat: 25, lon: 38.23, elevation: 1044 },
	{ lat: 25, lon: 39.03, elevation: 506 },
	{ lat: 25, lon: 39.82, elevation: 1062 },
	{ lat: 25, lon: 40.62, elevation: 904 },
	{ lat: 25, lon: 41.42, elevation: 958 },
	{ lat: 25, lon: 42.21, elevation: 834 },
	{ lat: 25, lon: 43.01, elevation: 855 },
	{ lat: 25, lon: 43.81, elevation: 841 },
	{ lat: 25, lon: 44.6, elevation: 779 },
	{ lat: 25, lon: 45.4, elevation: 719 },
	{ lat: 25, lon: 46.19, elevation: 798 },
	{ lat: 25, lon: 46.99, elevation: 704 },
	{ lat: 25, lon: 47.79, elevation: 500 },
	{ lat: 25, lon: 48.58, elevation: 366 },
	{ lat: 25, lon: 49.38, elevation: 228 },
	{ lat: 25, lon: 50.18, elevation: 135 },
	{ lat: 25, lon: 50.97, elevation: 64 },
	{ lat: 25, lon: 55.75, elevation: 178 },
	{ lat: 25, lon: 66.9, elevation: 28 },
	{ lat: 25, lon: 67.7, elevation: 139 },
	{ lat: 25, lon: 68.5, elevation: 15 },
	{ lat: 25, lon: 69.29, elevation: 9 },
	{ lat: 25, lon: 70.09, elevation: 94 },
	{ lat: 25, lon: 70.88, elevation: 110 },
	{ lat: 25, lon: 71.68, elevation: 35 },
	{ lat: 25, lon: 72.48, elevation: 208 },
	{ lat: 25, lon: 73.27, elevation: 379 },
	{ lat: 25, lon: 74.07, elevation: 507 },
	{ lat: 25, lon: 74.87, elevation: 527 },
	{ lat: 25, lon: 75.66, elevation: 485 },
	{ lat: 25, lon: 76.46, elevation: 277 },
	{ lat: 25, lon: 77.26, elevation: 363 },
	{ lat: 25, lon: 78.05, elevation: 425 },
	{ lat: 25, lon: 78.85, elevation: 333 },
	{ lat: 25, lon: 79.65, elevation: 274 },
	{ lat: 25, lon: 80.44, elevation: 160 },
	{ lat: 25, lon: 81.24, elevation: 174 },
	{ lat: 25, lon: 82.04, elevation: 119 },
	{ lat: 25, lon: 82.83, elevation: 121 },
	{ lat: 25, lon: 83.63, elevation: 87 },
	{ lat: 25, lon: 84.42, elevation: 93 },
	{ lat: 25, lon: 85.22, elevation: 79 },
	{ lat: 25, lon: 86.02, elevation: 55 },
	{ lat: 25, lon: 86.81, elevation: 68 },
	{ lat: 25, lon: 87.61, elevation: 64 },
	{ lat: 25, lon: 88.41, elevation: 33 },
	{ lat: 25, lon: 89.2, elevation: 21 },
	{ lat: 25, lon: 90.0, elevation: 22 },
	{ lat: 25, lon: 90.8, elevation: 13 },
	{ lat: 25, lon: 91.59, elevation: 10 },
	{ lat: 25, lon: 92.39, elevation: 17 },
	{ lat: 25, lon: 93.19, elevation: 109 },
	{ lat: 25, lon: 93.98, elevation: 1213 },
	{ lat: 25, lon: 94.78, elevation: 1023 },
	{ lat: 25, lon: 95.58, elevation: 199 },
	{ lat: 25, lon: 96.37, elevation: 218 },
	{ lat: 25, lon: 97.17, elevation: 135 },
	{ lat: 25, lon: 97.96, elevation: 2367 },
	{ lat: 25, lon: 98.76, elevation: 2467 },
	{ lat: 25, lon: 99.56, elevation: 2309 },
	{ lat: 25, lon: 100.35, elevation: 2060 },
	{ lat: 25, lon: 101.15, elevation: 1920 },
	{ lat: 25, lon: 101.95, elevation: 1533 },
	{ lat: 25, lon: 102.74, elevation: 1896 },
	{ lat: 25, lon: 103.54, elevation: 1888 },
	{ lat: 25, lon: 104.34, elevation: 1677 },
	{ lat: 25, lon: 105.13, elevation: 1327 },
	{ lat: 25, lon: 105.93, elevation: 623 },
	{ lat: 25, lon: 106.73, elevation: 1086 },
	{ lat: 25, lon: 107.52, elevation: 849 },
	{ lat: 25, lon: 108.32, elevation: 261 },
	{ lat: 25, lon: 109.12, elevation: 133 },
	{ lat: 25, lon: 109.91, elevation: 546 },
	{ lat: 25, lon: 110.71, elevation: 280 },
	{ lat: 25, lon: 111.5, elevation: 261 },
	{ lat: 25, lon: 112.3, elevation: 151 },
	{ lat: 25, lon: 113.1, elevation: 724 },
	{ lat: 25, lon: 113.89, elevation: 188 },
	{ lat: 25, lon: 114.69, elevation: 448 },
	{ lat: 25, lon: 115.49, elevation: 929 },
	{ lat: 25, lon: 116.28, elevation: 868 },
	{ lat: 25, lon: 117.08, elevation: 596 },
	{ lat: 25, lon: 117.88, elevation: 646 },
	{ lat: 25, lon: 118.67, elevation: 78 },
	{ lat: 25, lon: 121.06, elevation: 38 },
	{ lat: 25, lon: 121.86, elevation: 293 },
	{ lat: 26, lon: -111.7, elevation: 592 },
	{ lat: 26, lon: -109.29, elevation: 4 },
	{ lat: 26, lon: -108.48, elevation: 119 },
	{ lat: 26, lon: -107.68, elevation: 1475 },
	{ lat: 26, lon: -106.88, elevation: 2347 },
	{ lat: 26, lon: -106.07, elevation: 2797 },
	{ lat: 26, lon: -105.27, elevation: 1978 },
	{ lat: 26, lon: -104.46, elevation: 1662 },
	{ lat: 26, lon: -103.66, elevation: 1099 },
	{ lat: 26, lon: -102.86, elevation: 1095 },
	{ lat: 26, lon: -102.05, elevation: 1570 },
	{ lat: 26, lon: -101.25, elevation: 913 },
	{ lat: 26, lon: -100.45, elevation: 633 },
	{ lat: 26, lon: -99.64, elevation: 292 },
	{ lat: 26, lon: -98.84, elevation: 123 },
	{ lat: 26, lon: -98.04, elevation: 25 },
	{ lat: 26, lon: -97.23, elevation: 8 },
	{ lat: 26, lon: -81.16, elevation: 7 },
	{ lat: 26, lon: -80.36, elevation: 6 },
	{ lat: 26, lon: -14.46, elevation: 67 },
	{ lat: 26, lon: -13.66, elevation: 123 },
	{ lat: 26, lon: -12.86, elevation: 257 },
	{ lat: 26, lon: -12.05, elevation: 353 },
	{ lat: 26, lon: -11.25, elevation: 504 },
	{ lat: 26, lon: -10.45, elevation: 476 },
	{ lat: 26, lon: -9.64, elevation: 427 },
	{ lat: 26, lon: -8.84, elevation: 388 },
	{ lat: 26, lon: -8.04, elevation: 418 },
	{ lat: 26, lon: -7.23, elevation: 409 },
	{ lat: 26, lon: -6.43, elevation: 409 },
	{ lat: 26, lon: -5.62, elevation: 380 },
	{ lat: 26, lon: -4.82, elevation: 426 },
	{ lat: 26, lon: -4.02, elevation: 370 },
	{ lat: 26, lon: -3.21, elevation: 370 },
	{ lat: 26, lon: -2.41, elevation: 303 },
	{ lat: 26, lon: -1.61, elevation: 347 },
	{ lat: 26, lon: -0.8, elevation: 255 },
	{ lat: 26, lon: 0.0, elevation: 246 },
	{ lat: 26, lon: 0.8, elevation: 243 },
	{ lat: 26, lon: 1.61, elevation: 189 },
	{ lat: 26, lon: 2.41, elevation: 260 },
	{ lat: 26, lon: 3.21, elevation: 317 },
	{ lat: 26, lon: 4.02, elevation: 398 },
	{ lat: 26, lon: 4.82, elevation: 1386 },
	{ lat: 26, lon: 5.62, elevation: 775 },
	{ lat: 26, lon: 6.43, elevation: 1068 },
	{ lat: 26, lon: 7.23, elevation: 1087 },
	{ lat: 26, lon: 8.04, elevation: 1233 },
	{ lat: 26, lon: 8.84, elevation: 717 },
	{ lat: 26, lon: 9.64, elevation: 799 },
	{ lat: 26, lon: 10.45, elevation: 613 },
	{ lat: 26, lon: 11.25, elevation: 632 },
	{ lat: 26, lon: 12.05, elevation: 778 },
	{ lat: 26, lon: 12.86, elevation: 552 },
	{ lat: 26, lon: 13.66, elevation: 459 },
	{ lat: 26, lon: 14.46, elevation: 469 },
	{ lat: 26, lon: 15.27, elevation: 431 },
	{ lat: 26, lon: 16.07, elevation: 543 },
	{ lat: 26, lon: 16.88, elevation: 513 },
	{ lat: 26, lon: 17.68, elevation: 428 },
	{ lat: 26, lon: 18.48, elevation: 369 },
	{ lat: 26, lon: 19.29, elevation: 306 },
	{ lat: 26, lon: 20.09, elevation: 280 },
	{ lat: 26, lon: 20.89, elevation: 236 },
	{ lat: 26, lon: 21.7, elevation: 232 },
	{ lat: 26, lon: 22.5, elevation: 234 },
	{ lat: 26, lon: 23.3, elevation: 386 },
	{ lat: 26, lon: 24.11, elevation: 294 },
	{ lat: 26, lon: 24.91, elevation: 315 },
	{ lat: 26, lon: 25.71, elevation: 327 },
	{ lat: 26, lon: 26.52, elevation: 352 },
	{ lat: 26, lon: 27.32, elevation: 302 },
	{ lat: 26, lon: 28.12, elevation: 340 },
	{ lat: 26, lon: 28.93, elevation: 293 },
	{ lat: 26, lon: 29.73, elevation: 371 },
	{ lat: 26, lon: 30.54, elevation: 184 },
	{ lat: 26, lon: 31.34, elevation: 352 },
	{ lat: 26, lon: 32.14, elevation: 87 },
	{ lat: 26, lon: 32.95, elevation: 122 },
	{ lat: 26, lon: 33.75, elevation: 494 },
	{ lat: 26, lon: 36.96, elevation: 77 },
	{ lat: 26, lon: 37.77, elevation: 962 },
	{ lat: 26, lon: 38.57, elevation: 779 },
	{ lat: 26, lon: 39.38, elevation: 853 },
	{ lat: 26, lon: 40.18, elevation: 1229 },
	{ lat: 26, lon: 40.98, elevation: 933 },
	{ lat: 26, lon: 41.79, elevation: 833 },
	{ lat: 26, lon: 42.59, elevation: 801 },
	{ lat: 26, lon: 43.39, elevation: 675 },
	{ lat: 26, lon: 44.2, elevation: 616 },
	{ lat: 26, lon: 45.0, elevation: 751 },
	{ lat: 26, lon: 45.8, elevation: 657 },
	{ lat: 26, lon: 46.61, elevation: 596 },
	{ lat: 26, lon: 47.41, elevation: 459 },
	{ lat: 26, lon: 48.21, elevation: 340 },
	{ lat: 26, lon: 49.02, elevation: 161 },
	{ lat: 26, lon: 49.82, elevation: 28 },
	{ lat: 26, lon: 50.62, elevation: 0 },
	{ lat: 26, lon: 56.25, elevation: 1268 },
	{ lat: 26, lon: 57.86, elevation: 225 },
	{ lat: 26, lon: 58.66, elevation: 363 },
	{ lat: 26, lon: 59.46, elevation: 181 },
	{ lat: 26, lon: 60.27, elevation: 235 },
	{ lat: 26, lon: 61.07, elevation: 232 },
	{ lat: 26, lon: 61.88, elevation: 264 },
	{ lat: 26, lon: 62.68, elevation: 88 },
	{ lat: 26, lon: 63.48, elevation: 370 },
	{ lat: 26, lon: 64.29, elevation: 588 },
	{ lat: 26, lon: 65.09, elevation: 335 },
	{ lat: 26, lon: 65.89, elevation: 358 },
	{ lat: 26, lon: 66.7, elevation: 274 },
	{ lat: 26, lon: 67.5, elevation: 518 },
	{ lat: 26, lon: 68.3, elevation: 30 },
	{ lat: 26, lon: 69.11, elevation: 15 },
	{ lat: 26, lon: 69.91, elevation: 53 },
	{ lat: 26, lon: 70.71, elevation: 177 },
	{ lat: 26, lon: 71.52, elevation: 186 },
	{ lat: 26, lon: 72.32, elevation: 120 },
	{ lat: 26, lon: 73.12, elevation: 195 },
	{ lat: 26, lon: 73.93, elevation: 317 },
	{ lat: 26, lon: 74.73, elevation: 415 },
	{ lat: 26, lon: 75.54, elevation: 300 },
	{ lat: 26, lon: 76.34, elevation: 273 },
	{ lat: 26, lon: 77.14, elevation: 370 },
	{ lat: 26, lon: 77.95, elevation: 336 },
	{ lat: 26, lon: 78.75, elevation: 169 },
	{ lat: 26, lon: 79.55, elevation: 139 },
	{ lat: 26, lon: 80.36, elevation: 119 },
	{ lat: 26, lon: 81.16, elevation: 116 },
	{ lat: 26, lon: 81.96, elevation: 102 },
	{ lat: 26, lon: 82.77, elevation: 84 },
	{ lat: 26, lon: 83.57, elevation: 76 },
	{ lat: 26, lon: 84.38, elevation: 57 },
	{ lat: 26, lon: 85.18, elevation: 58 },
	{ lat: 26, lon: 85.98, elevation: 50 },
	{ lat: 26, lon: 86.79, elevation: 50 },
	{ lat: 26, lon: 87.59, elevation: 46 },
	{ lat: 26, lon: 88.39, elevation: 52 },
	{ lat: 26, lon: 89.2, elevation: 41 },
	{ lat: 26, lon: 90.0, elevation: 36 },
	{ lat: 26, lon: 90.8, elevation: 51 },
	{ lat: 26, lon: 91.61, elevation: 234 },
	{ lat: 26, lon: 92.41, elevation: 650 },
	{ lat: 26, lon: 93.21, elevation: 139 },
	{ lat: 26, lon: 94.02, elevation: 410 },
	{ lat: 26, lon: 94.82, elevation: 1473 },
	{ lat: 26, lon: 95.62, elevation: 253 },
	{ lat: 26, lon: 96.43, elevation: 1129 },
	{ lat: 26, lon: 97.23, elevation: 388 },
	{ lat: 26, lon: 98.04, elevation: 2214 },
	{ lat: 26, lon: 98.84, elevation: 1260 },
	{ lat: 26, lon: 99.64, elevation: 2539 },
	{ lat: 26, lon: 100.45, elevation: 1586 },
	{ lat: 26, lon: 101.25, elevation: 2499 },
	{ lat: 26, lon: 102.05, elevation: 1430 },
	{ lat: 26, lon: 102.86, elevation: 2309 },
	{ lat: 26, lon: 103.66, elevation: 2052 },
	{ lat: 26, lon: 104.46, elevation: 1962 },
	{ lat: 26, lon: 105.27, elevation: 1086 },
	{ lat: 26, lon: 106.07, elevation: 1153 },
	{ lat: 26, lon: 106.88, elevation: 1358 },
	{ lat: 26, lon: 107.68, elevation: 1144 },
	{ lat: 26, lon: 108.48, elevation: 627 },
	{ lat: 26, lon: 109.29, elevation: 751 },
	{ lat: 26, lon: 110.09, elevation: 981 },
	{ lat: 26, lon: 110.89, elevation: 467 },
	{ lat: 26, lon: 111.7, elevation: 502 },
	{ lat: 26, lon: 112.5, elevation: 383 },
	{ lat: 26, lon: 113.3, elevation: 507 },
	{ lat: 26, lon: 114.11, elevation: 1299 },
	{ lat: 26, lon: 114.91, elevation: 153 },
	{ lat: 26, lon: 115.71, elevation: 275 },
	{ lat: 26, lon: 116.52, elevation: 1041 },
	{ lat: 26, lon: 117.32, elevation: 222 },
	{ lat: 26, lon: 118.12, elevation: 431 },
	{ lat: 26, lon: 118.93, elevation: 312 },
	{ lat: 27, lon: -113.51, elevation: 150 },
	{ lat: 27, lon: -112.7, elevation: 501 },
	{ lat: 27, lon: -109.46, elevation: 34 },
	{ lat: 27, lon: -108.65, elevation: 782 },
	{ lat: 27, lon: -107.84, elevation: 719 },
	{ lat: 27, lon: -107.03, elevation: 2297 },
	{ lat: 27, lon: -106.22, elevation: 1822 },
	{ lat: 27, lon: -105.41, elevation: 1624 },
	{ lat: 27, lon: -104.59, elevation: 1545 },
	{ lat: 27, lon: -103.78, elevation: 1148 },
	{ lat: 27, lon: -102.97, elevation: 1257 },
	{ lat: 27, lon: -102.16, elevation: 1676 },
	{ lat: 27, lon: -101.35, elevation: 502 },
	{ lat: 27, lon: -100.54, elevation: 308 },
	{ lat: 27, lon: -99.73, elevation: 151 },
	{ lat: 27, lon: -98.92, elevation: 160 },
	{ lat: 27, lon: -98.11, elevation: 43 },
	{ lat: 27, lon: -81.89, elevation: 9 },
	{ lat: 27, lon: -81.08, elevation: 16 },
	{ lat: 27, lon: -80.27, elevation: 10 },
	{ lat: 27, lon: -12.97, elevation: 116 },
	{ lat: 27, lon: -12.16, elevation: 284 },
	{ lat: 27, lon: -11.35, elevation: 178 },
	{ lat: 27, lon: -10.54, elevation: 302 },
	{ lat: 27, lon: -9.73, elevation: 531 },
	{ lat: 27, lon: -8.92, elevation: 451 },
	{ lat: 27, lon: -8.11, elevation: 415 },
	{ lat: 27, lon: -7.3, elevation: 341 },
	{ lat: 27, lon: -6.49, elevation: 363 },
	{ lat: 27, lon: -5.68, elevation: 379 },
	{ lat: 27, lon: -4.86, elevation: 445 },
	{ lat: 27, lon: -4.05, elevation: 515 },
	{ lat: 27, lon: -3.24, elevation: 448 },
	{ lat: 27, lon: -2.43, elevation: 418 },
	{ lat: 27, lon: -1.62, elevation: 297 },
	{ lat: 27, lon: -0.81, elevation: 264 },
	{ lat: 27, lon: 0.0, elevation: 273 },
	{ lat: 27, lon: 0.81, elevation: 294 },
	{ lat: 27, lon: 1.62, elevation: 263 },
	{ lat: 27, lon: 2.43, elevation: 282 },
	{ lat: 27, lon: 3.24, elevation: 284 },
	{ lat: 27, lon: 4.05, elevation: 462 },
	{ lat: 27, lon: 4.86, elevation: 521 },
	{ lat: 27, lon: 5.68, elevation: 518 },
	{ lat: 27, lon: 6.49, elevation: 641 },
	{ lat: 27, lon: 7.3, elevation: 533 },
	{ lat: 27, lon: 8.11, elevation: 480 },
	{ lat: 27, lon: 8.92, elevation: 539 },
	{ lat: 27, lon: 9.73, elevation: 570 },
	{ lat: 27, lon: 10.54, elevation: 536 },
	{ lat: 27, lon: 11.35, elevation: 565 },
	{ lat: 27, lon: 12.16, elevation: 518 },
	{ lat: 27, lon: 12.97, elevation: 474 },
	{ lat: 27, lon: 13.78, elevation: 435 },
	{ lat: 27, lon: 14.59, elevation: 454 },
	{ lat: 27, lon: 15.41, elevation: 465 },
	{ lat: 27, lon: 16.22, elevation: 581 },
	{ lat: 27, lon: 17.03, elevation: 734 },
	{ lat: 27, lon: 17.84, elevation: 574 },
	{ lat: 27, lon: 18.65, elevation: 312 },
	{ lat: 27, lon: 19.46, elevation: 236 },
	{ lat: 27, lon: 20.27, elevation: 229 },
	{ lat: 27, lon: 21.08, elevation: 186 },
	{ lat: 27, lon: 21.89, elevation: 154 },
	{ lat: 27, lon: 22.7, elevation: 198 },
	{ lat: 27, lon: 23.51, elevation: 219 },
	{ lat: 27, lon: 24.32, elevation: 225 },
	{ lat: 27, lon: 25.14, elevation: 200 },
	{ lat: 27, lon: 25.95, elevation: 247 },
	{ lat: 27, lon: 26.76, elevation: 221 },
	{ lat: 27, lon: 27.57, elevation: 341 },
	{ lat: 27, lon: 28.38, elevation: 132 },
	{ lat: 27, lon: 29.19, elevation: 242 },
	{ lat: 27, lon: 30.0, elevation: 196 },
	{ lat: 27, lon: 30.81, elevation: 211 },
	{ lat: 27, lon: 31.62, elevation: 369 },
	{ lat: 27, lon: 32.43, elevation: 605 },
	{ lat: 27, lon: 33.24, elevation: 926 },
	{ lat: 27, lon: 36.49, elevation: 260 },
	{ lat: 27, lon: 37.3, elevation: 1119 },
	{ lat: 27, lon: 38.11, elevation: 945 },
	{ lat: 27, lon: 38.92, elevation: 1023 },
	{ lat: 27, lon: 39.73, elevation: 1274 },
	{ lat: 27, lon: 40.54, elevation: 1097 },
	{ lat: 27, lon: 41.35, elevation: 1216 },
	{ lat: 27, lon: 42.16, elevation: 1053 },
	{ lat: 27, lon: 42.97, elevation: 810 },
	{ lat: 27, lon: 43.78, elevation: 660 },
	{ lat: 27, lon: 44.59, elevation: 549 },
	{ lat: 27, lon: 45.41, elevation: 559 },
	{ lat: 27, lon: 46.22, elevation: 428 },
	{ lat: 27, lon: 47.03, elevation: 350 },
	{ lat: 27, lon: 47.84, elevation: 249 },
	{ lat: 27, lon: 48.65, elevation: 81 },
	{ lat: 27, lon: 49.46, elevation: 16 },
	{ lat: 27, lon: 53.51, elevation: 933 },
	{ lat: 27, lon: 54.32, elevation: 375 },
	{ lat: 27, lon: 55.14, elevation: 469 },
	{ lat: 27, lon: 57.57, elevation: 581 },
	{ lat: 27, lon: 58.38, elevation: 674 },
	{ lat: 27, lon: 59.19, elevation: 417 },
	{ lat: 27, lon: 60.0, elevation: 581 },
	{ lat: 27, lon: 60.81, elevation: 835 },
	{ lat: 27, lon: 61.62, elevation: 1345 },
	{ lat: 27, lon: 62.43, elevation: 1115 },
	{ lat: 27, lon: 63.24, elevation: 902 },
	{ lat: 27, lon: 64.05, elevation: 978 },
	{ lat: 27, lon: 64.86, elevation: 1146 },
	{ lat: 27, lon: 65.68, elevation: 1549 },
	{ lat: 27, lon: 66.49, elevation: 872 },
	{ lat: 27, lon: 67.3, elevation: 198 },
	{ lat: 27, lon: 68.11, elevation: 46 },
	{ lat: 27, lon: 68.92, elevation: 47 },
	{ lat: 27, lon: 69.73, elevation: 60 },
	{ lat: 27, lon: 70.54, elevation: 207 },
	{ lat: 27, lon: 71.35, elevation: 194 },
	{ lat: 27, lon: 72.16, elevation: 247 },
	{ lat: 27, lon: 72.97, elevation: 297 },
	{ lat: 27, lon: 73.78, elevation: 329 },
	{ lat: 27, lon: 74.59, elevation: 392 },
	{ lat: 27, lon: 75.41, elevation: 403 },
	{ lat: 27, lon: 76.22, elevation: 338 },
	{ lat: 27, lon: 77.03, elevation: 233 },
	{ lat: 27, lon: 77.84, elevation: 167 },
	{ lat: 27, lon: 78.65, elevation: 154 },
	{ lat: 27, lon: 79.46, elevation: 148 },
	{ lat: 27, lon: 80.27, elevation: 138 },
	{ lat: 27, lon: 81.08, elevation: 124 },
	{ lat: 27, lon: 81.89, elevation: 102 },
	{ lat: 27, lon: 82.7, elevation: 88 },
	{ lat: 27, lon: 83.51, elevation: 84 },
	{ lat: 27, lon: 84.32, elevation: 84 },
	{ lat: 27, lon: 85.14, elevation: 99 },
	{ lat: 27, lon: 85.95, elevation: 305 },
	{ lat: 27, lon: 86.76, elevation: 389 },
	{ lat: 27, lon: 87.57, elevation: 795 },
	{ lat: 27, lon: 88.38, elevation: 680 },
	{ lat: 27, lon: 89.19, elevation: 1162 },
	{ lat: 27, lon: 90.0, elevation: 2022 },
	{ lat: 27, lon: 90.81, elevation: 599 },
	{ lat: 27, lon: 91.62, elevation: 2364 },
	{ lat: 27, lon: 92.43, elevation: 726 },
	{ lat: 27, lon: 93.24, elevation: 460 },
	{ lat: 27, lon: 94.05, elevation: 83 },
	{ lat: 27, lon: 94.86, elevation: 100 },
	{ lat: 27, lon: 95.68, elevation: 994 },
	{ lat: 27, lon: 96.49, elevation: 1128 },
	{ lat: 27, lon: 97.3, elevation: 2072 },
	{ lat: 27, lon: 98.11, elevation: 2541 },
	{ lat: 27, lon: 98.92, elevation: 2199 },
	{ lat: 27, lon: 99.73, elevation: 2683 },
	{ lat: 27, lon: 100.54, elevation: 3359 },
	{ lat: 27, lon: 101.35, elevation: 2387 },
	{ lat: 27, lon: 102.16, elevation: 1120 },
	{ lat: 27, lon: 102.97, elevation: 2635 },
	{ lat: 27, lon: 103.78, elevation: 2223 },
	{ lat: 27, lon: 104.59, elevation: 2113 },
	{ lat: 27, lon: 105.41, elevation: 1923 },
	{ lat: 27, lon: 106.22, elevation: 1257 },
	{ lat: 27, lon: 107.03, elevation: 1231 },
	{ lat: 27, lon: 107.84, elevation: 813 },
	{ lat: 27, lon: 108.65, elevation: 718 },
	{ lat: 27, lon: 109.46, elevation: 380 },
	{ lat: 27, lon: 110.27, elevation: 884 },
	{ lat: 27, lon: 111.08, elevation: 272 },
	{ lat: 27, lon: 111.89, elevation: 286 },
	{ lat: 27, lon: 112.7, elevation: 50 },
	{ lat: 27, lon: 113.51, elevation: 637 },
	{ lat: 27, lon: 114.32, elevation: 111 },
	{ lat: 27, lon: 115.14, elevation: 79 },
	{ lat: 27, lon: 115.95, elevation: 941 },
	{ lat: 27, lon: 116.76, elevation: 639 },
	{ lat: 27, lon: 117.57, elevation: 336 },
	{ lat: 27, lon: 118.38, elevation: 639 },
	{ lat: 27, lon: 119.19, elevation: 670 },
	{ lat: 27, lon: 120.0, elevation: 643 },
	{ lat: 28, lon: -113.73, elevation: 114 },
	{ lat: 28, lon: -112.91, elevation: 461 },
	{ lat: 28, lon: -110.45, elevation: 47 },
	{ lat: 28, lon: -109.64, elevation: 328 },
	{ lat: 28, lon: -108.82, elevation: 726 },
	{ lat: 28, lon: -108.0, elevation: 2305 },
	{ lat: 28, lon: -107.18, elevation: 2203 },
	{ lat: 28, lon: -106.36, elevation: 1961 },
	{ lat: 28, lon: -105.55, elevation: 1279 },
	{ lat: 28, lon: -104.73, elevation: 1603 },
	{ lat: 28, lon: -103.91, elevation: 1251 },
	{ lat: 28, lon: -103.09, elevation: 1232 },
	{ lat: 28, lon: -102.27, elevation: 1348 },
	{ lat: 28, lon: -101.45, elevation: 412 },
	{ lat: 28, lon: -100.64, elevation: 445 },
	{ lat: 28, lon: -99.82, elevation: 219 },
	{ lat: 28, lon: -99.0, elevation: 113 },
	{ lat: 28, lon: -98.18, elevation: 100 },
	{ lat: 28, lon: -97.36, elevation: 15 },
	{ lat: 28, lon: -82.64, elevation: 0 },
	{ lat: 28, lon: -81.82, elevation: 40 },
	{ lat: 28, lon: -81.0, elevation: 18 },
	{ lat: 28, lon: -15.55, elevation: 1016 },
	{ lat: 28, lon: -12.27, elevation: -1 },
	{ lat: 28, lon: -11.45, elevation: 87 },
	{ lat: 28, lon: -10.64, elevation: 481 },
	{ lat: 28, lon: -9.82, elevation: 377 },
	{ lat: 28, lon: -9.0, elevation: 507 },
	{ lat: 28, lon: -8.18, elevation: 472 },
	{ lat: 28, lon: -7.36, elevation: 412 },
	{ lat: 28, lon: -6.55, elevation: 428 },
	{ lat: 28, lon: -5.73, elevation: 447 },
	{ lat: 28, lon: -4.91, elevation: 429 },
	{ lat: 28, lon: -4.09, elevation: 392 },
	{ lat: 28, lon: -3.27, elevation: 373 },
	{ lat: 28, lon: -2.45, elevation: 354 },
	{ lat: 28, lon: -1.64, elevation: 359 },
	{ lat: 28, lon: -0.82, elevation: 252 },
	{ lat: 28, lon: 0.0, elevation: 296 },
	{ lat: 28, lon: 0.82, elevation: 413 },
	{ lat: 28, lon: 1.64, elevation: 549 },
	{ lat: 28, lon: 2.45, elevation: 681 },
	{ lat: 28, lon: 3.27, elevation: 722 },
	{ lat: 28, lon: 4.09, elevation: 486 },
	{ lat: 28, lon: 4.91, elevation: 530 },
	{ lat: 28, lon: 5.73, elevation: 412 },
	{ lat: 28, lon: 6.55, elevation: 383 },
	{ lat: 28, lon: 7.36, elevation: 430 },
	{ lat: 28, lon: 8.18, elevation: 501 },
	{ lat: 28, lon: 9.0, elevation: 518 },
	{ lat: 28, lon: 9.82, elevation: 581 },
	{ lat: 28, lon: 10.64, elevation: 572 },
	{ lat: 28, lon: 11.45, elevation: 566 },
	{ lat: 28, lon: 12.27, elevation: 504 },
	{ lat: 28, lon: 13.09, elevation: 555 },
	{ lat: 28, lon: 13.91, elevation: 635 },
	{ lat: 28, lon: 14.73, elevation: 496 },
	{ lat: 28, lon: 15.55, elevation: 462 },
	{ lat: 28, lon: 16.36, elevation: 530 },
	{ lat: 28, lon: 17.18, elevation: 471 },
	{ lat: 28, lon: 18.0, elevation: 377 },
	{ lat: 28, lon: 18.82, elevation: 243 },
	{ lat: 28, lon: 19.64, elevation: 205 },
	{ lat: 28, lon: 20.45, elevation: 221 },
	{ lat: 28, lon: 21.27, elevation: 150 },
	{ lat: 28, lon: 22.09, elevation: 98 },
	{ lat: 28, lon: 22.91, elevation: 130 },
	{ lat: 28, lon: 23.73, elevation: 144 },
	{ lat: 28, lon: 24.55, elevation: 153 },
	{ lat: 28, lon: 25.36, elevation: 144 },
	{ lat: 28, lon: 26.18, elevation: 135 },
	{ lat: 28, lon: 27.0, elevation: 144 },
	{ lat: 28, lon: 27.82, elevation: 185 },
	{ lat: 28, lon: 28.64, elevation: 136 },
	{ lat: 28, lon: 29.45, elevation: 153 },
	{ lat: 28, lon: 30.27, elevation: 117 },
	{ lat: 28, lon: 31.09, elevation: 226 },
	{ lat: 28, lon: 31.91, elevation: 532 },
	{ lat: 28, lon: 32.73, elevation: 848 },
	{ lat: 28, lon: 34.36, elevation: 114 },
	{ lat: 28, lon: 35.18, elevation: 15 },
	{ lat: 28, lon: 36.0, elevation: 1121 },
	{ lat: 28, lon: 36.82, elevation: 1086 },
	{ lat: 28, lon: 37.64, elevation: 1136 },
	{ lat: 28, lon: 38.45, elevation: 857 },
	{ lat: 28, lon: 39.27, elevation: 952 },
	{ lat: 28, lon: 40.09, elevation: 977 },
	{ lat: 28, lon: 40.91, elevation: 1009 },
	{ lat: 28, lon: 41.73, elevation: 791 },
	{ lat: 28, lon: 42.55, elevation: 749 },
	{ lat: 28, lon: 43.36, elevation: 668 },
	{ lat: 28, lon: 44.18, elevation: 552 },
	{ lat: 28, lon: 45.0, elevation: 457 },
	{ lat: 28, lon: 45.82, elevation: 393 },
	{ lat: 28, lon: 46.64, elevation: 328 },
	{ lat: 28, lon: 47.45, elevation: 243 },
	{ lat: 28, lon: 48.27, elevation: 74 },
	{ lat: 28, lon: 51.55, elevation: 138 },
	{ lat: 28, lon: 52.36, elevation: 375 },
	{ lat: 28, lon: 53.18, elevation: 728 },
	{ lat: 28, lon: 54.0, elevation: 926 },
	{ lat: 28, lon: 54.82, elevation: 1103 },
	{ lat: 28, lon: 55.64, elevation: 666 },
	{ lat: 28, lon: 56.45, elevation: 1206 },
	{ lat: 28, lon: 57.27, elevation: 700 },
	{ lat: 28, lon: 58.09, elevation: 486 },
	{ lat: 28, lon: 58.91, elevation: 812 },
	{ lat: 28, lon: 59.73, elevation: 1103 },
	{ lat: 28, lon: 60.55, elevation: 1684 },
	{ lat: 28, lon: 61.36, elevation: 1419 },
	{ lat: 28, lon: 62.18, elevation: 1294 },
	{ lat: 28, lon: 63.0, elevation: 496 },
	{ lat: 28, lon: 63.82, elevation: 562 },
	{ lat: 28, lon: 64.64, elevation: 620 },
	{ lat: 28, lon: 65.45, elevation: 1108 },
	{ lat: 28, lon: 66.27, elevation: 1572 },
	{ lat: 28, lon: 67.09, elevation: 712 },
	{ lat: 28, lon: 67.91, elevation: 48 },
	{ lat: 28, lon: 68.73, elevation: 72 },
	{ lat: 28, lon: 69.55, elevation: 70 },
	{ lat: 28, lon: 70.36, elevation: 79 },
	{ lat: 28, lon: 71.18, elevation: 114 },
	{ lat: 28, lon: 72.0, elevation: 140 },
	{ lat: 28, lon: 72.82, elevation: 195 },
	{ lat: 28, lon: 73.64, elevation: 239 },
	{ lat: 28, lon: 74.45, elevation: 316 },
	{ lat: 28, lon: 75.27, elevation: 355 },
	{ lat: 28, lon: 76.09, elevation: 324 },
	{ lat: 28, lon: 76.91, elevation: 329 },
	{ lat: 28, lon: 77.73, elevation: 192 },
	{ lat: 28, lon: 78.55, elevation: 179 },
	{ lat: 28, lon: 79.36, elevation: 156 },
	{ lat: 28, lon: 80.18, elevation: 158 },
	{ lat: 28, lon: 81.0, elevation: 136 },
	{ lat: 28, lon: 81.82, elevation: 378 },
	{ lat: 28, lon: 82.64, elevation: 1447 },
	{ lat: 28, lon: 83.45, elevation: 598 },
	{ lat: 28, lon: 84.27, elevation: 506 },
	{ lat: 28, lon: 85.09, elevation: 1981 },
	{ lat: 28, lon: 85.91, elevation: 3658 },
	{ lat: 28, lon: 86.73, elevation: 5313 },
	{ lat: 28, lon: 87.55, elevation: 4811 },
	{ lat: 28, lon: 88.36, elevation: 5538 },
	{ lat: 28, lon: 89.18, elevation: 4653 },
	{ lat: 28, lon: 90.0, elevation: 4023 },
	{ lat: 28, lon: 90.82, elevation: 5138 },
	{ lat: 28, lon: 91.64, elevation: 5531 },
	{ lat: 28, lon: 92.45, elevation: 4722 },
	{ lat: 28, lon: 93.27, elevation: 2489 },
	{ lat: 28, lon: 94.09, elevation: 1370 },
	{ lat: 28, lon: 94.91, elevation: 693 },
	{ lat: 28, lon: 95.73, elevation: 159 },
	{ lat: 28, lon: 96.55, elevation: 1780 },
	{ lat: 28, lon: 97.36, elevation: 4243 },
	{ lat: 28, lon: 98.18, elevation: 2932 },
	{ lat: 28, lon: 99.0, elevation: 4111 },
	{ lat: 28, lon: 99.82, elevation: 3721 },
	{ lat: 28, lon: 100.64, elevation: 3572 },
	{ lat: 28, lon: 101.45, elevation: 3264 },
	{ lat: 28, lon: 102.27, elevation: 2313 },
	{ lat: 28, lon: 103.09, elevation: 2503 },
	{ lat: 28, lon: 103.91, elevation: 1483 },
	{ lat: 28, lon: 104.73, elevation: 606 },
	{ lat: 28, lon: 105.55, elevation: 1344 },
	{ lat: 28, lon: 106.36, elevation: 806 },
	{ lat: 28, lon: 107.18, elevation: 1103 },
	{ lat: 28, lon: 108.0, elevation: 721 },
	{ lat: 28, lon: 108.82, elevation: 1089 },
	{ lat: 28, lon: 109.64, elevation: 464 },
	{ lat: 28, lon: 110.45, elevation: 372 },
	{ lat: 28, lon: 111.27, elevation: 260 },
	{ lat: 28, lon: 112.09, elevation: 149 },
	{ lat: 28, lon: 112.91, elevation: 65 },
	{ lat: 28, lon: 113.73, elevation: 132 },
	{ lat: 28, lon: 114.55, elevation: 135 },
	{ lat: 28, lon: 115.36, elevation: 32 },
	{ lat: 28, lon: 116.18, elevation: 177 },
	{ lat: 28, lon: 117.0, elevation: 165 },
	{ lat: 28, lon: 117.82, elevation: 265 },
	{ lat: 28, lon: 118.64, elevation: 366 },
	{ lat: 28, lon: 119.45, elevation: 1217 },
	{ lat: 28, lon: 120.27, elevation: 545 },
	{ lat: 29, lon: -113.94, elevation: 367 },
	{ lat: 29, lon: -113.12, elevation: 66 },
	{ lat: 29, lon: -112.29, elevation: 204 },
	{ lat: 29, lon: -111.47, elevation: 84 },
	{ lat: 29, lon: -110.64, elevation: 369 },
	{ lat: 29, lon: -109.82, elevation: 886 },
	{ lat: 29, lon: -108.99, elevation: 1465 },
	{ lat: 29, lon: -108.17, elevation: 1759 },
	{ lat: 29, lon: -107.34, elevation: 2022 },
	{ lat: 29, lon: -106.51, elevation: 2057 },
	{ lat: 29, lon: -105.69, elevation: 1320 },
	{ lat: 29, lon: -104.86, elevation: 1186 },
	{ lat: 29, lon: -104.04, elevation: 1528 },
	{ lat: 29, lon: -103.21, elevation: 616 },
	{ lat: 29, lon: -102.39, elevation: 1223 },
	{ lat: 29, lon: -101.56, elevation: 699 },
	{ lat: 29, lon: -100.73, elevation: 278 },
	{ lat: 29, lon: -99.91, elevation: 243 },
	{ lat: 29, lon: -99.08, elevation: 206 },
	{ lat: 29, lon: -98.26, elevation: 120 },
	{ lat: 29, lon: -97.43, elevation: 93 },
	{ lat: 29, lon: -96.61, elevation: 22 },
	{ lat: 29, lon: -95.78, elevation: 13 },
	{ lat: 29, lon: -82.57, elevation: 16 },
	{ lat: 29, lon: -81.74, elevation: 33 },
	{ lat: 29, lon: -80.92, elevation: 11 },
	{ lat: 29, lon: -9.91, elevation: 519 },
	{ lat: 29, lon: -9.08, elevation: 639 },
	{ lat: 29, lon: -8.26, elevation: 314 },
	{ lat: 29, lon: -7.43, elevation: 475 },
	{ lat: 29, lon: -6.61, elevation: 668 },
	{ lat: 29, lon: -5.78, elevation: 601 },
	{ lat: 29, lon: -4.95, elevation: 513 },
	{ lat: 29, lon: -4.13, elevation: 500 },
	{ lat: 29, lon: -3.3, elevation: 437 },
	{ lat: 29, lon: -2.48, elevation: 516 },
	{ lat: 29, lon: -1.65, elevation: 381 },
	{ lat: 29, lon: -0.83, elevation: 323 },
	{ lat: 29, lon: 0.0, elevation: 177 },
	{ lat: 29, lon: 0.83, elevation: 329 },
	{ lat: 29, lon: 1.65, elevation: 468 },
	{ lat: 29, lon: 2.48, elevation: 586 },
	{ lat: 29, lon: 3.3, elevation: 461 },
	{ lat: 29, lon: 4.13, elevation: 431 },
	{ lat: 29, lon: 4.95, elevation: 410 },
	{ lat: 29, lon: 5.78, elevation: 307 },
	{ lat: 29, lon: 6.61, elevation: 353 },
	{ lat: 29, lon: 7.43, elevation: 337 },
	{ lat: 29, lon: 8.26, elevation: 459 },
	{ lat: 29, lon: 9.08, elevation: 534 },
	{ lat: 29, lon: 9.91, elevation: 654 },
	{ lat: 29, lon: 10.73, elevation: 668 },
	{ lat: 29, lon: 11.56, elevation: 679 },
	{ lat: 29, lon: 12.39, elevation: 655 },
	{ lat: 29, lon: 13.21, elevation: 605 },
	{ lat: 29, lon: 14.04, elevation: 549 },
	{ lat: 29, lon: 14.86, elevation: 476 },
	{ lat: 29, lon: 15.69, elevation: 316 },
	{ lat: 29, lon: 16.51, elevation: 304 },
	{ lat: 29, lon: 17.34, elevation: 325 },
	{ lat: 29, lon: 18.17, elevation: 305 },
	{ lat: 29, lon: 18.99, elevation: 126 },
	{ lat: 29, lon: 19.82, elevation: 100 },
	{ lat: 29, lon: 20.64, elevation: 107 },
	{ lat: 29, lon: 21.47, elevation: 69 },
	{ lat: 29, lon: 22.29, elevation: 96 },
	{ lat: 29, lon: 23.12, elevation: 85 },
	{ lat: 29, lon: 23.94, elevation: 92 },
	{ lat: 29, lon: 24.77, elevation: 112 },
	{ lat: 29, lon: 25.6, elevation: 30 },
	{ lat: 29, lon: 26.42, elevation: -23 },
	{ lat: 29, lon: 27.25, elevation: 6 },
	{ lat: 29, lon: 28.07, elevation: 120 },
	{ lat: 29, lon: 28.9, elevation: 139 },
	{ lat: 29, lon: 29.72, elevation: 198 },
	{ lat: 29, lon: 30.55, elevation: 22 },
	{ lat: 29, lon: 31.38, elevation: 284 },
	{ lat: 29, lon: 32.2, elevation: 229 },
	{ lat: 29, lon: 33.85, elevation: 1380 },
	{ lat: 29, lon: 34.68, elevation: 12 },
	{ lat: 29, lon: 35.5, elevation: 1128 },
	{ lat: 29, lon: 36.33, elevation: 737 },
	{ lat: 29, lon: 37.16, elevation: 1139 },
	{ lat: 29, lon: 37.98, elevation: 774 },
	{ lat: 29, lon: 38.81, elevation: 745 },
	{ lat: 29, lon: 39.63, elevation: 894 },
	{ lat: 29, lon: 40.46, elevation: 809 },
	{ lat: 29, lon: 41.28, elevation: 734 },
	{ lat: 29, lon: 42.11, elevation: 699 },
	{ lat: 29, lon: 42.94, elevation: 604 },
	{ lat: 29, lon: 43.76, elevation: 531 },
	{ lat: 29, lon: 44.59, elevation: 408 },
	{ lat: 29, lon: 45.41, elevation: 346 },
	{ lat: 29, lon: 46.24, elevation: 313 },
	{ lat: 29, lon: 47.06, elevation: 263 },
	{ lat: 29, lon: 47.89, elevation: 84 },
	{ lat: 29, lon: 51.19, elevation: 22 },
	{ lat: 29, lon: 52.02, elevation: 787 },
	{ lat: 29, lon: 52.84, elevation: 1716 },
	{ lat: 29, lon: 53.67, elevation: 1531 },
	{ lat: 29, lon: 54.5, elevation: 1902 },
	{ lat: 29, lon: 55.32, elevation: 1663 },
	{ lat: 29, lon: 56.15, elevation: 2020 },
	{ lat: 29, lon: 56.97, elevation: 1970 },
	{ lat: 29, lon: 57.8, elevation: 1801 },
	{ lat: 29, lon: 58.62, elevation: 812 },
	{ lat: 29, lon: 59.45, elevation: 572 },
	{ lat: 29, lon: 60.28, elevation: 1458 },
	{ lat: 29, lon: 61.1, elevation: 1537 },
	{ lat: 29, lon: 61.93, elevation: 845 },
	{ lat: 29, lon: 62.75, elevation: 1066 },
	{ lat: 29, lon: 63.58, elevation: 915 },
	{ lat: 29, lon: 64.4, elevation: 916 },
	{ lat: 29, lon: 65.23, elevation: 839 },
	{ lat: 29, lon: 66.06, elevation: 1775 },
	{ lat: 29, lon: 66.88, elevation: 2176 },
	{ lat: 29, lon: 67.71, elevation: 94 },
	{ lat: 29, lon: 68.53, elevation: 217 },
	{ lat: 29, lon: 69.36, elevation: 605 },
	{ lat: 29, lon: 70.18, elevation: 92 },
	{ lat: 29, lon: 71.01, elevation: 96 },
	{ lat: 29, lon: 71.83, elevation: 116 },
	{ lat: 29, lon: 72.66, elevation: 142 },
	{ lat: 29, lon: 73.49, elevation: 176 },
	{ lat: 29, lon: 74.31, elevation: 183 },
	{ lat: 29, lon: 75.14, elevation: 200 },
	{ lat: 29, lon: 75.96, elevation: 214 },
	{ lat: 29, lon: 76.79, elevation: 227 },
	{ lat: 29, lon: 77.61, elevation: 225 },
	{ lat: 29, lon: 78.44, elevation: 222 },
	{ lat: 29, lon: 79.27, elevation: 205 },
	{ lat: 29, lon: 80.09, elevation: 237 },
	{ lat: 29, lon: 80.92, elevation: 1926 },
	{ lat: 29, lon: 81.74, elevation: 2720 },
	{ lat: 29, lon: 82.57, elevation: 2289 },
	{ lat: 29, lon: 83.39, elevation: 5552 },
	{ lat: 29, lon: 84.22, elevation: 5308 },
	{ lat: 29, lon: 85.05, elevation: 5486 },
	{ lat: 29, lon: 85.87, elevation: 4839 },
	{ lat: 29, lon: 86.7, elevation: 5175 },
	{ lat: 29, lon: 87.52, elevation: 5306 },
	{ lat: 29, lon: 88.35, elevation: 4316 },
	{ lat: 29, lon: 89.17, elevation: 4194 },
	{ lat: 29, lon: 90.0, elevation: 5512 },
	{ lat: 29, lon: 90.83, elevation: 4693 },
	{ lat: 29, lon: 91.65, elevation: 3807 },
	{ lat: 29, lon: 92.48, elevation: 4517 },
	{ lat: 29, lon: 93.3, elevation: 3162 },
	{ lat: 29, lon: 94.13, elevation: 3680 },
	{ lat: 29, lon: 94.95, elevation: 1674 },
	{ lat: 29, lon: 95.78, elevation: 2616 },
	{ lat: 29, lon: 96.61, elevation: 2482 },
	{ lat: 29, lon: 97.43, elevation: 4415 },
	{ lat: 29, lon: 98.26, elevation: 3956 },
	{ lat: 29, lon: 99.08, elevation: 3612 },
	{ lat: 29, lon: 99.91, elevation: 3374 },
	{ lat: 29, lon: 100.73, elevation: 4335 },
	{ lat: 29, lon: 101.56, elevation: 3532 },
	{ lat: 29, lon: 102.39, elevation: 2831 },
	{ lat: 29, lon: 103.21, elevation: 1886 },
	{ lat: 29, lon: 104.04, elevation: 493 },
	{ lat: 29, lon: 104.86, elevation: 392 },
	{ lat: 29, lon: 105.69, elevation: 360 },
	{ lat: 29, lon: 106.51, elevation: 244 },
	{ lat: 29, lon: 107.34, elevation: 994 },
	{ lat: 29, lon: 108.17, elevation: 955 },
	{ lat: 29, lon: 108.99, elevation: 486 },
	{ lat: 29, lon: 109.82, elevation: 288 },
	{ lat: 29, lon: 110.64, elevation: 541 },
	{ lat: 29, lon: 111.47, elevation: 35 },
	{ lat: 29, lon: 112.29, elevation: 57 },
	{ lat: 29, lon: 113.12, elevation: 62 },
	{ lat: 29, lon: 113.94, elevation: 517 },
	{ lat: 29, lon: 114.77, elevation: 705 },
	{ lat: 29, lon: 115.6, elevation: 51 },
	{ lat: 29, lon: 116.42, elevation: 11 },
	{ lat: 29, lon: 117.25, elevation: 28 },
	{ lat: 29, lon: 118.07, elevation: 324 },
	{ lat: 29, lon: 118.9, elevation: 80 },
	{ lat: 29, lon: 119.72, elevation: 73 },
	{ lat: 29, lon: 120.55, elevation: 545 },
	{ lat: 29, lon: 121.38, elevation: 378 },
	{ lat: 30, lon: -115.0, elevation: 584 },
	{ lat: 30, lon: -112.5, elevation: 448 },
	{ lat: 30, lon: -111.67, elevation: 930 },
	{ lat: 30, lon: -110.83, elevation: 867 },
	{ lat: 30, lon: -110.0, elevation: 1082 },
	{ lat: 30, lon: -109.17, elevation: 1151 },
	{ lat: 30, lon: -108.33, elevation: 2149 },
	{ lat: 30, lon: -107.5, elevation: 1478 },
	{ lat: 30, lon: -106.67, elevation: 1890 },
	{ lat: 30, lon: -105.83, elevation: 1298 },
	{ lat: 30, lon: -105.0, elevation: 1215 },
	{ lat: 30, lon: -104.17, elevation: 1515 },
	{ lat: 30, lon: -103.33, elevation: 1062 },
	{ lat: 30, lon: -102.5, elevation: 730 },
	{ lat: 30, lon: -101.67, elevation: 567 },
	{ lat: 30, lon: -100.83, elevation: 609 },
	{ lat: 30, lon: -100.0, elevation: 701 },
	{ lat: 30, lon: -99.17, elevation: 559 },
	{ lat: 30, lon: -98.33, elevation: 405 },
	{ lat: 30, lon: -97.5, elevation: 148 },
	{ lat: 30, lon: -96.67, elevation: 100 },
	{ lat: 30, lon: -95.83, elevation: 63 },
	{ lat: 30, lon: -95.0, elevation: 22 },
	{ lat: 30, lon: -94.17, elevation: 16 },
	{ lat: 30, lon: -93.33, elevation: 1 },
	{ lat: 30, lon: -92.5, elevation: 2 },
	{ lat: 30, lon: -91.67, elevation: 11 },
	{ lat: 30, lon: -90.83, elevation: 4 },
	{ lat: 30, lon: -90.0, elevation: 7 },
	{ lat: 30, lon: -85.0, elevation: 18 },
	{ lat: 30, lon: -83.33, elevation: 28 },
	{ lat: 30, lon: -82.5, elevation: 45 },
	{ lat: 30, lon: -81.67, elevation: 2 },
	{ lat: 30, lon: -9.17, elevation: 624 },
	{ lat: 30, lon: -8.33, elevation: 1608 },
	{ lat: 30, lon: -7.5, elevation: 756 },
	{ lat: 30, lon: -6.67, elevation: 1160 },
	{ lat: 30, lon: -5.83, elevation: 648 },
	{ lat: 30, lon: -5.0, elevation: 686 },
	{ lat: 30, lon: -4.17, elevation: 552 },
	{ lat: 30, lon: -3.33, elevation: 612 },
	{ lat: 30, lon: -2.5, elevation: 580 },
	{ lat: 30, lon: -1.67, elevation: 515 },
	{ lat: 30, lon: -0.83, elevation: 488 },
	{ lat: 30, lon: 0.0, elevation: 464 },
	{ lat: 30, lon: 0.83, elevation: 422 },
	{ lat: 30, lon: 1.67, elevation: 394 },
	{ lat: 30, lon: 2.5, elevation: 490 },
	{ lat: 30, lon: 3.33, elevation: 391 },
	{ lat: 30, lon: 4.17, elevation: 294 },
	{ lat: 30, lon: 5.0, elevation: 297 },
	{ lat: 30, lon: 5.83, elevation: 325 },
	{ lat: 30, lon: 6.67, elevation: 254 },
	{ lat: 30, lon: 7.5, elevation: 281 },
	{ lat: 30, lon: 8.33, elevation: 287 },
	{ lat: 30, lon: 9.17, elevation: 345 },
	{ lat: 30, lon: 10.0, elevation: 323 },
	{ lat: 30, lon: 10.83, elevation: 587 },
	{ lat: 30, lon: 11.67, elevation: 622 },
	{ lat: 30, lon: 12.5, elevation: 630 },
	{ lat: 30, lon: 13.33, elevation: 587 },
	{ lat: 30, lon: 14.17, elevation: 442 },
	{ lat: 30, lon: 15.0, elevation: 356 },
	{ lat: 30, lon: 15.83, elevation: 287 },
	{ lat: 30, lon: 16.67, elevation: 339 },
	{ lat: 30, lon: 17.5, elevation: 246 },
	{ lat: 30, lon: 18.33, elevation: 205 },
	{ lat: 30, lon: 19.17, elevation: 5 },
	{ lat: 30, lon: 20.0, elevation: 7 },
	{ lat: 30, lon: 20.83, elevation: -16 },
	{ lat: 30, lon: 21.67, elevation: 47 },
	{ lat: 30, lon: 22.5, elevation: 58 },
	{ lat: 30, lon: 23.33, elevation: 46 },
	{ lat: 30, lon: 24.17, elevation: 96 },
	{ lat: 30, lon: 25.0, elevation: 133 },
	{ lat: 30, lon: 25.83, elevation: 201 },
	{ lat: 30, lon: 26.67, elevation: 105 },
	{ lat: 30, lon: 27.5, elevation: -77 },
	{ lat: 30, lon: 28.33, elevation: -19 },
	{ lat: 30, lon: 29.17, elevation: 120 },
	{ lat: 30, lon: 30.0, elevation: 135 },
	{ lat: 30, lon: 30.83, elevation: 150 },
	{ lat: 30, lon: 31.67, elevation: 330 },
	{ lat: 30, lon: 32.5, elevation: 25 },
	{ lat: 30, lon: 33.33, elevation: 428 },
	{ lat: 30, lon: 34.17, elevation: 463 },
	{ lat: 30, lon: 35.0, elevation: 448 },
	{ lat: 30, lon: 35.83, elevation: 1050 },
	{ lat: 30, lon: 36.67, elevation: 894 },
	{ lat: 30, lon: 37.5, elevation: 795 },
	{ lat: 30, lon: 38.33, elevation: 615 },
	{ lat: 30, lon: 39.17, elevation: 645 },
	{ lat: 30, lon: 40.0, elevation: 645 },
	{ lat: 30, lon: 40.83, elevation: 660 },
	{ lat: 30, lon: 41.67, elevation: 542 },
	{ lat: 30, lon: 42.5, elevation: 467 },
	{ lat: 30, lon: 43.33, elevation: 403 },
	{ lat: 30, lon: 44.17, elevation: 384 },
	{ lat: 30, lon: 45.0, elevation: 292 },
	{ lat: 30, lon: 45.83, elevation: 208 },
	{ lat: 30, lon: 46.67, elevation: 136 },
	{ lat: 30, lon: 47.5, elevation: 70 },
	{ lat: 30, lon: 48.33, elevation: 3 },
	{ lat: 30, lon: 50.83, elevation: 479 },
	{ lat: 30, lon: 51.67, elevation: 2131 },
	{ lat: 30, lon: 52.5, elevation: 1598 },
	{ lat: 30, lon: 53.33, elevation: 2016 },
	{ lat: 30, lon: 54.17, elevation: 2252 },
	{ lat: 30, lon: 55.0, elevation: 1724 },
	{ lat: 30, lon: 55.83, elevation: 2618 },
	{ lat: 30, lon: 56.67, elevation: 2194 },
	{ lat: 30, lon: 57.5, elevation: 3208 },
	{ lat: 30, lon: 58.33, elevation: 254 },
	{ lat: 30, lon: 59.17, elevation: 461 },
	{ lat: 30, lon: 60.0, elevation: 1492 },
	{ lat: 30, lon: 60.83, elevation: 826 },
	{ lat: 30, lon: 61.67, elevation: 501 },
	{ lat: 30, lon: 62.5, elevation: 503 },
	{ lat: 30, lon: 63.33, elevation: 871 },
	{ lat: 30, lon: 64.17, elevation: 1073 },
	{ lat: 30, lon: 65.0, elevation: 957 },
	{ lat: 30, lon: 65.83, elevation: 944 },
	{ lat: 30, lon: 66.67, elevation: 1879 },
	{ lat: 30, lon: 67.5, elevation: 1216 },
	{ lat: 30, lon: 68.33, elevation: 1315 },
	{ lat: 30, lon: 69.17, elevation: 1238 },
	{ lat: 30, lon: 70.0, elevation: 1718 },
	{ lat: 30, lon: 70.83, elevation: 115 },
	{ lat: 30, lon: 71.67, elevation: 120 },
	{ lat: 30, lon: 72.5, elevation: 141 },
	{ lat: 30, lon: 73.33, elevation: 158 },
	{ lat: 30, lon: 74.17, elevation: 185 },
	{ lat: 30, lon: 75.0, elevation: 207 },
	{ lat: 30, lon: 75.83, elevation: 227 },
	{ lat: 30, lon: 76.67, elevation: 247 },
	{ lat: 30, lon: 77.5, elevation: 282 },
	{ lat: 30, lon: 78.33, elevation: 638 },
	{ lat: 30, lon: 79.17, elevation: 2084 },
	{ lat: 30, lon: 80.0, elevation: 1986 },
	{ lat: 30, lon: 80.83, elevation: 4087 },
	{ lat: 30, lon: 81.67, elevation: 4837 },
	{ lat: 30, lon: 82.5, elevation: 4995 },
	{ lat: 30, lon: 83.33, elevation: 4580 },
	{ lat: 30, lon: 84.17, elevation: 4857 },
	{ lat: 30, lon: 85.0, elevation: 5687 },
	{ lat: 30, lon: 85.83, elevation: 5678 },
	{ lat: 30, lon: 86.67, elevation: 5367 },
	{ lat: 30, lon: 87.5, elevation: 5231 },
	{ lat: 30, lon: 88.33, elevation: 4803 },
	{ lat: 30, lon: 89.17, elevation: 5273 },
	{ lat: 30, lon: 90.0, elevation: 5613 },
	{ lat: 30, lon: 90.83, elevation: 5275 },
	{ lat: 30, lon: 91.67, elevation: 4599 },
	{ lat: 30, lon: 92.5, elevation: 5184 },
	{ lat: 30, lon: 93.33, elevation: 5106 },
	{ lat: 30, lon: 94.17, elevation: 4613 },
	{ lat: 30, lon: 95.0, elevation: 3222 },
	{ lat: 30, lon: 95.83, elevation: 4899 },
	{ lat: 30, lon: 96.67, elevation: 4343 },
	{ lat: 30, lon: 97.5, elevation: 4375 },
	{ lat: 30, lon: 98.33, elevation: 3808 },
	{ lat: 30, lon: 99.17, elevation: 4393 },
	{ lat: 30, lon: 100.0, elevation: 4823 },
	{ lat: 30, lon: 100.83, elevation: 4613 },
	{ lat: 30, lon: 101.67, elevation: 4027 },
	{ lat: 30, lon: 102.5, elevation: 1244 },
	{ lat: 30, lon: 103.33, elevation: 500 },
	{ lat: 30, lon: 104.17, elevation: 402 },
	{ lat: 30, lon: 105.0, elevation: 385 },
	{ lat: 30, lon: 105.83, elevation: 285 },
	{ lat: 30, lon: 106.67, elevation: 553 },
	{ lat: 30, lon: 107.5, elevation: 405 },
	{ lat: 30, lon: 108.33, elevation: 1090 },
	{ lat: 30, lon: 109.17, elevation: 1007 },
	{ lat: 30, lon: 110.0, elevation: 804 },
	{ lat: 30, lon: 110.83, elevation: 542 },
	{ lat: 30, lon: 111.67, elevation: 55 },
	{ lat: 30, lon: 112.5, elevation: 28 },
	{ lat: 30, lon: 113.33, elevation: 25 },
	{ lat: 30, lon: 114.17, elevation: 23 },
	{ lat: 30, lon: 115.0, elevation: 95 },
	{ lat: 30, lon: 115.83, elevation: 15 },
	{ lat: 30, lon: 116.67, elevation: 104 },
	{ lat: 30, lon: 117.5, elevation: 259 },
	{ lat: 30, lon: 118.33, elevation: 270 },
	{ lat: 30, lon: 119.17, elevation: 480 },
	{ lat: 30, lon: 120.0, elevation: 23 },
	{ lat: 30, lon: 120.83, elevation: 9 },
	{ lat: 31, lon: -115.93, elevation: 408 },
	{ lat: 31, lon: -115.08, elevation: 665 },
	{ lat: 31, lon: -112.55, elevation: 304 },
	{ lat: 31, lon: -111.71, elevation: 732 },
	{ lat: 31, lon: -110.87, elevation: 1042 },
	{ lat: 31, lon: -110.02, elevation: 1670 },
	{ lat: 31, lon: -109.18, elevation: 1128 },
	{ lat: 31, lon: -108.34, elevation: 1383 },
	{ lat: 31, lon: -107.49, elevation: 1213 },
	{ lat: 31, lon: -106.65, elevation: 1267 },
	{ lat: 31, lon: -105.81, elevation: 1395 },
	{ lat: 31, lon: -104.96, elevation: 1331 },
	{ lat: 31, lon: -104.12, elevation: 1414 },
	{ lat: 31, lon: -103.28, elevation: 892 },
	{ lat: 31, lon: -102.44, elevation: 771 },
	{ lat: 31, lon: -101.59, elevation: 840 },
	{ lat: 31, lon: -100.75, elevation: 769 },
	{ lat: 31, lon: -99.91, elevation: 689 },
	{ lat: 31, lon: -99.06, elevation: 476 },
	{ lat: 31, lon: -98.22, elevation: 377 },
	{ lat: 31, lon: -97.38, elevation: 158 },
	{ lat: 31, lon: -96.53, elevation: 116 },
	{ lat: 31, lon: -95.69, elevation: 53 },
	{ lat: 31, lon: -94.85, elevation: 84 },
	{ lat: 31, lon: -94.0, elevation: 131 },
	{ lat: 31, lon: -93.16, elevation: 101 },
	{ lat: 31, lon: -92.32, elevation: 20 },
	{ lat: 31, lon: -91.48, elevation: 88 },
	{ lat: 31, lon: -90.63, elevation: 100 },
	{ lat: 31, lon: -89.79, elevation: 36 },
	{ lat: 31, lon: -88.95, elevation: 63 },
	{ lat: 31, lon: -88.1, elevation: 60 },
	{ lat: 31, lon: -87.26, elevation: 27 },
	{ lat: 31, lon: -86.42, elevation: 87 },
	{ lat: 31, lon: -85.57, elevation: 64 },
	{ lat: 31, lon: -84.73, elevation: 47 },
	{ lat: 31, lon: -83.89, elevation: 73 },
	{ lat: 31, lon: -83.04, elevation: 61 },
	{ lat: 31, lon: -82.2, elevation: 44 },
	{ lat: 31, lon: -9.7, elevation: 299 },
	{ lat: 31, lon: -8.85, elevation: 2252 },
	{ lat: 31, lon: -8.01, elevation: 2337 },
	{ lat: 31, lon: -7.17, elevation: 1257 },
	{ lat: 31, lon: -6.32, elevation: 1424 },
	{ lat: 31, lon: -5.48, elevation: 1529 },
	{ lat: 31, lon: -4.64, elevation: 710 },
	{ lat: 31, lon: -3.79, elevation: 720 },
	{ lat: 31, lon: -2.95, elevation: 683 },
	{ lat: 31, lon: -2.11, elevation: 664 },
	{ lat: 31, lon: -1.26, elevation: 712 },
	{ lat: 31, lon: -0.42, elevation: 627 },
	{ lat: 31, lon: 0.42, elevation: 578 },
	{ lat: 31, lon: 1.26, elevation: 537 },
	{ lat: 31, lon: 2.11, elevation: 499 },
	{ lat: 31, lon: 2.95, elevation: 479 },
	{ lat: 31, lon: 3.79, elevation: 323 },
	{ lat: 31, lon: 4.64, elevation: 234 },
	{ lat: 31, lon: 5.48, elevation: 183 },
	{ lat: 31, lon: 6.32, elevation: 153 },
	{ lat: 31, lon: 7.17, elevation: 176 },
	{ lat: 31, lon: 8.01, elevation: 188 },
	{ lat: 31, lon: 8.85, elevation: 230 },
	{ lat: 31, lon: 9.7, elevation: 319 },
	{ lat: 31, lon: 10.54, elevation: 427 },
	{ lat: 31, lon: 11.38, elevation: 578 },
	{ lat: 31, lon: 12.22, elevation: 594 },
	{ lat: 31, lon: 13.07, elevation: 697 },
	{ lat: 31, lon: 13.91, elevation: 259 },
	{ lat: 31, lon: 14.75, elevation: 115 },
	{ lat: 31, lon: 15.6, elevation: 35 },
	{ lat: 31, lon: 16.44, elevation: 71 },
	{ lat: 31, lon: 17.28, elevation: 34 },
	{ lat: 31, lon: 20.66, elevation: 77 },
	{ lat: 31, lon: 21.5, elevation: 104 },
	{ lat: 31, lon: 22.34, elevation: 121 },
	{ lat: 31, lon: 23.19, elevation: 108 },
	{ lat: 31, lon: 24.03, elevation: 165 },
	{ lat: 31, lon: 24.87, elevation: 190 },
	{ lat: 31, lon: 25.71, elevation: 222 },
	{ lat: 31, lon: 26.56, elevation: 223 },
	{ lat: 31, lon: 27.4, elevation: 179 },
	{ lat: 31, lon: 28.24, elevation: 69 },
	{ lat: 31, lon: 29.93, elevation: -4 },
	{ lat: 31, lon: 30.77, elevation: 8 },
	{ lat: 31, lon: 31.62, elevation: 2 },
	{ lat: 31, lon: 32.46, elevation: 1 },
	{ lat: 31, lon: 33.3, elevation: 7 },
	{ lat: 31, lon: 34.15, elevation: 115 },
	{ lat: 31, lon: 34.99, elevation: 562 },
	{ lat: 31, lon: 35.83, elevation: 1017 },
	{ lat: 31, lon: 36.67, elevation: 864 },
	{ lat: 31, lon: 37.52, elevation: 570 },
	{ lat: 31, lon: 38.36, elevation: 727 },
	{ lat: 31, lon: 39.2, elevation: 890 },
	{ lat: 31, lon: 40.05, elevation: 812 },
	{ lat: 31, lon: 40.89, elevation: 579 },
	{ lat: 31, lon: 41.73, elevation: 398 },
	{ lat: 31, lon: 42.58, elevation: 331 },
	{ lat: 31, lon: 43.42, elevation: 342 },
	{ lat: 31, lon: 44.26, elevation: 217 },
	{ lat: 31, lon: 45.11, elevation: 84 },
	{ lat: 31, lon: 45.95, elevation: 8 },
	{ lat: 31, lon: 46.79, elevation: 4 },
	{ lat: 31, lon: 47.63, elevation: 5 },
	{ lat: 31, lon: 48.48, elevation: 8 },
	{ lat: 31, lon: 49.32, elevation: 26 },
	{ lat: 31, lon: 50.16, elevation: 1399 },
	{ lat: 31, lon: 51.01, elevation: 2304 },
	{ lat: 31, lon: 51.85, elevation: 2615 },
	{ lat: 31, lon: 52.69, elevation: 2185 },
	{ lat: 31, lon: 53.54, elevation: 1460 },
	{ lat: 31, lon: 54.38, elevation: 1968 },
	{ lat: 31, lon: 55.22, elevation: 1381 },
	{ lat: 31, lon: 56.07, elevation: 1428 },
	{ lat: 31, lon: 56.91, elevation: 1521 },
	{ lat: 31, lon: 57.75, elevation: 270 },
	{ lat: 31, lon: 58.59, elevation: 594 },
	{ lat: 31, lon: 59.44, elevation: 732 },
	{ lat: 31, lon: 60.28, elevation: 1105 },
	{ lat: 31, lon: 61.12, elevation: 472 },
	{ lat: 31, lon: 61.97, elevation: 482 },
	{ lat: 31, lon: 62.81, elevation: 585 },
	{ lat: 31, lon: 63.65, elevation: 768 },
	{ lat: 31, lon: 64.5, elevation: 994 },
	{ lat: 31, lon: 65.34, elevation: 1169 },
	{ lat: 31, lon: 66.18, elevation: 1126 },
	{ lat: 31, lon: 67.03, elevation: 2381 },
	{ lat: 31, lon: 67.87, elevation: 2413 },
	{ lat: 31, lon: 68.71, elevation: 1759 },
	{ lat: 31, lon: 69.56, elevation: 1802 },
	{ lat: 31, lon: 70.4, elevation: 449 },
	{ lat: 31, lon: 71.24, elevation: 153 },
	{ lat: 31, lon: 72.08, elevation: 143 },
	{ lat: 31, lon: 72.93, elevation: 168 },
	{ lat: 31, lon: 73.77, elevation: 192 },
	{ lat: 31, lon: 74.61, elevation: 198 },
	{ lat: 31, lon: 75.46, elevation: 222 },
	{ lat: 31, lon: 76.3, elevation: 256 },
	{ lat: 31, lon: 77.14, elevation: 1406 },
	{ lat: 31, lon: 77.99, elevation: 1476 },
	{ lat: 31, lon: 78.83, elevation: 4340 },
	{ lat: 31, lon: 79.67, elevation: 6057 },
	{ lat: 31, lon: 80.52, elevation: 4722 },
	{ lat: 31, lon: 81.36, elevation: 4892 },
	{ lat: 31, lon: 82.2, elevation: 5116 },
	{ lat: 31, lon: 83.04, elevation: 5674 },
	{ lat: 31, lon: 83.89, elevation: 5222 },
	{ lat: 31, lon: 84.73, elevation: 5500 },
	{ lat: 31, lon: 85.57, elevation: 4612 },
	{ lat: 31, lon: 86.42, elevation: 5055 },
	{ lat: 31, lon: 87.26, elevation: 4694 },
	{ lat: 31, lon: 88.1, elevation: 5255 },
	{ lat: 31, lon: 88.95, elevation: 4744 },
	{ lat: 31, lon: 89.79, elevation: 4805 },
	{ lat: 31, lon: 90.63, elevation: 5163 },
	{ lat: 31, lon: 91.48, elevation: 4981 },
	{ lat: 31, lon: 92.32, elevation: 5272 },
	{ lat: 31, lon: 93.16, elevation: 5242 },
	{ lat: 31, lon: 94.0, elevation: 5469 },
	{ lat: 31, lon: 94.85, elevation: 5002 },
	{ lat: 31, lon: 95.69, elevation: 4328 },
	{ lat: 31, lon: 96.53, elevation: 4901 },
	{ lat: 31, lon: 97.38, elevation: 3818 },
	{ lat: 31, lon: 98.22, elevation: 3830 },
	{ lat: 31, lon: 99.06, elevation: 4028 },
	{ lat: 31, lon: 99.91, elevation: 4699 },
	{ lat: 31, lon: 100.75, elevation: 4111 },
	{ lat: 31, lon: 101.59, elevation: 3905 },
	{ lat: 31, lon: 102.44, elevation: 2885 },
	{ lat: 31, lon: 103.28, elevation: 2263 },
	{ lat: 31, lon: 104.12, elevation: 511 },
	{ lat: 31, lon: 104.96, elevation: 400 },
	{ lat: 31, lon: 105.81, elevation: 400 },
	{ lat: 31, lon: 106.65, elevation: 640 },
	{ lat: 31, lon: 107.49, elevation: 429 },
	{ lat: 31, lon: 108.34, elevation: 224 },
	{ lat: 31, lon: 109.18, elevation: 338 },
	{ lat: 31, lon: 110.02, elevation: 597 },
	{ lat: 31, lon: 110.87, elevation: 929 },
	{ lat: 31, lon: 111.71, elevation: 192 },
	{ lat: 31, lon: 112.55, elevation: 44 },
	{ lat: 31, lon: 113.4, elevation: 68 },
	{ lat: 31, lon: 114.24, elevation: 53 },
	{ lat: 31, lon: 115.08, elevation: 144 },
	{ lat: 31, lon: 115.93, elevation: 228 },
	{ lat: 31, lon: 116.77, elevation: 544 },
	{ lat: 31, lon: 117.61, elevation: 57 },
	{ lat: 31, lon: 118.45, elevation: 8 },
	{ lat: 31, lon: 119.3, elevation: 83 },
	{ lat: 31, lon: 120.14, elevation: 0 },
	{ lat: 31, lon: 120.98, elevation: 5 },
	{ lat: 31, lon: 121.83, elevation: 4 },
	{ lat: 32, lon: -116.17, elevation: 962 },
	{ lat: 32, lon: -115.32, elevation: 51 },
	{ lat: 32, lon: -114.47, elevation: 75 },
	{ lat: 32, lon: -113.62, elevation: 213 },
	{ lat: 32, lon: -112.77, elevation: 580 },
	{ lat: 32, lon: -111.91, elevation: 771 },
	{ lat: 32, lon: -111.06, elevation: 731 },
	{ lat: 32, lon: -110.21, elevation: 1222 },
	{ lat: 32, lon: -109.36, elevation: 1702 },
	{ lat: 32, lon: -108.51, elevation: 1421 },
	{ lat: 32, lon: -107.66, elevation: 1288 },
	{ lat: 32, lon: -106.81, elevation: 1264 },
	{ lat: 32, lon: -105.96, elevation: 1643 },
	{ lat: 32, lon: -105.11, elevation: 1107 },
	{ lat: 32, lon: -104.26, elevation: 1012 },
	{ lat: 32, lon: -103.4, elevation: 974 },
	{ lat: 32, lon: -102.55, elevation: 950 },
	{ lat: 32, lon: -101.7, elevation: 796 },
	{ lat: 32, lon: -100.85, elevation: 652 },
	{ lat: 32, lon: -100.0, elevation: 581 },
	{ lat: 32, lon: -99.15, elevation: 464 },
	{ lat: 32, lon: -98.3, elevation: 442 },
	{ lat: 32, lon: -97.45, elevation: 160 },
	{ lat: 32, lon: -96.6, elevation: 129 },
	{ lat: 32, lon: -95.74, elevation: 131 },
	{ lat: 32, lon: -94.89, elevation: 113 },
	{ lat: 32, lon: -94.04, elevation: 62 },
	{ lat: 32, lon: -93.19, elevation: 42 },
	{ lat: 32, lon: -92.34, elevation: 34 },
	{ lat: 32, lon: -91.49, elevation: 19 },
	{ lat: 32, lon: -90.64, elevation: 117 },
	{ lat: 32, lon: -89.79, elevation: 108 },
	{ lat: 32, lon: -88.94, elevation: 109 },
	{ lat: 32, lon: -88.09, elevation: 16 },
	{ lat: 32, lon: -87.23, elevation: 82 },
	{ lat: 32, lon: -86.38, elevation: 129 },
	{ lat: 32, lon: -85.53, elevation: 150 },
	{ lat: 32, lon: -84.68, elevation: 155 },
	{ lat: 32, lon: -83.83, elevation: 93 },
	{ lat: 32, lon: -82.98, elevation: 84 },
	{ lat: 32, lon: -82.13, elevation: 34 },
	{ lat: 32, lon: -81.28, elevation: 8 },
	{ lat: 32, lon: -8.94, elevation: 546 },
	{ lat: 32, lon: -8.09, elevation: 461 },
	{ lat: 32, lon: -7.23, elevation: 500 },
	{ lat: 32, lon: -6.38, elevation: 1177 },
	{ lat: 32, lon: -5.53, elevation: 2516 },
	{ lat: 32, lon: -4.68, elevation: 1910 },
	{ lat: 32, lon: -3.83, elevation: 1077 },
	{ lat: 32, lon: -2.98, elevation: 830 },
	{ lat: 32, lon: -2.13, elevation: 1035 },
	{ lat: 32, lon: -1.28, elevation: 832 },
	{ lat: 32, lon: -0.43, elevation: 842 },
	{ lat: 32, lon: 0.43, elevation: 758 },
	{ lat: 32, lon: 1.28, elevation: 620 },
	{ lat: 32, lon: 2.13, elevation: 582 },
	{ lat: 32, lon: 2.98, elevation: 548 },
	{ lat: 32, lon: 3.83, elevation: 428 },
	{ lat: 32, lon: 4.68, elevation: 253 },
	{ lat: 32, lon: 5.53, elevation: 150 },
	{ lat: 32, lon: 6.38, elevation: 138 },
	{ lat: 32, lon: 7.23, elevation: 131 },
	{ lat: 32, lon: 8.09, elevation: 153 },
	{ lat: 32, lon: 8.94, elevation: 214 },
	{ lat: 32, lon: 9.79, elevation: 308 },
	{ lat: 32, lon: 10.64, elevation: 363 },
	{ lat: 32, lon: 11.49, elevation: 213 },
	{ lat: 32, lon: 12.34, elevation: 612 },
	{ lat: 32, lon: 13.19, elevation: 819 },
	{ lat: 32, lon: 14.04, elevation: 274 },
	{ lat: 32, lon: 14.89, elevation: 48 },
	{ lat: 32, lon: 20.0, elevation: -1 },
	{ lat: 32, lon: 20.85, elevation: 372 },
	{ lat: 32, lon: 21.7, elevation: 163 },
	{ lat: 32, lon: 22.55, elevation: 167 },
	{ lat: 32, lon: 23.4, elevation: 190 },
	{ lat: 32, lon: 24.26, elevation: 3 },
	{ lat: 32, lon: 35.32, elevation: 712 },
	{ lat: 32, lon: 36.17, elevation: 727 },
	{ lat: 32, lon: 37.02, elevation: 626 },
	{ lat: 32, lon: 37.87, elevation: 716 },
	{ lat: 32, lon: 38.72, elevation: 884 },
	{ lat: 32, lon: 39.57, elevation: 888 },
	{ lat: 32, lon: 40.43, elevation: 700 },
	{ lat: 32, lon: 41.28, elevation: 520 },
	{ lat: 32, lon: 42.13, elevation: 325 },
	{ lat: 32, lon: 42.98, elevation: 252 },
	{ lat: 32, lon: 43.83, elevation: 94 },
	{ lat: 32, lon: 44.68, elevation: 21 },
	{ lat: 32, lon: 45.53, elevation: 19 },
	{ lat: 32, lon: 46.38, elevation: 12 },
	{ lat: 32, lon: 47.23, elevation: 6 },
	{ lat: 32, lon: 48.09, elevation: 89 },
	{ lat: 32, lon: 48.94, elevation: 69 },
	{ lat: 32, lon: 49.79, elevation: 1376 },
	{ lat: 32, lon: 50.64, elevation: 1855 },
	{ lat: 32, lon: 51.49, elevation: 2295 },
	{ lat: 32, lon: 52.34, elevation: 1689 },
	{ lat: 32, lon: 53.19, elevation: 1871 },
	{ lat: 32, lon: 54.04, elevation: 1175 },
	{ lat: 32, lon: 54.89, elevation: 2025 },
	{ lat: 32, lon: 55.74, elevation: 1664 },
	{ lat: 32, lon: 56.6, elevation: 952 },
	{ lat: 32, lon: 57.45, elevation: 771 },
	{ lat: 32, lon: 58.3, elevation: 755 },
	{ lat: 32, lon: 59.15, elevation: 1450 },
	{ lat: 32, lon: 60.0, elevation: 1347 },
	{ lat: 32, lon: 60.85, elevation: 779 },
	{ lat: 32, lon: 61.7, elevation: 571 },
	{ lat: 32, lon: 62.55, elevation: 653 },
	{ lat: 32, lon: 63.4, elevation: 802 },
	{ lat: 32, lon: 64.26, elevation: 943 },
	{ lat: 32, lon: 65.11, elevation: 1148 },
	{ lat: 32, lon: 65.96, elevation: 1248 },
	{ lat: 32, lon: 66.81, elevation: 1504 },
	{ lat: 32, lon: 67.66, elevation: 2086 },
	{ lat: 32, lon: 68.51, elevation: 2159 },
	{ lat: 32, lon: 69.36, elevation: 1131 },
	{ lat: 32, lon: 70.21, elevation: 289 },
	{ lat: 32, lon: 71.06, elevation: 174 },
	{ lat: 32, lon: 71.91, elevation: 190 },
	{ lat: 32, lon: 72.77, elevation: 186 },
	{ lat: 32, lon: 73.62, elevation: 202 },
	{ lat: 32, lon: 74.47, elevation: 221 },
	{ lat: 32, lon: 75.32, elevation: 254 },
	{ lat: 32, lon: 76.17, elevation: 571 },
	{ lat: 32, lon: 77.02, elevation: 1861 },
	{ lat: 32, lon: 77.87, elevation: 4798 },
	{ lat: 32, lon: 78.72, elevation: 5738 },
	{ lat: 32, lon: 79.57, elevation: 4408 },
	{ lat: 32, lon: 80.43, elevation: 5282 },
	{ lat: 32, lon: 81.28, elevation: 5139 },
	{ lat: 32, lon: 82.13, elevation: 4620 },
	{ lat: 32, lon: 82.98, elevation: 4931 },
	{ lat: 32, lon: 83.83, elevation: 5148 },
	{ lat: 32, lon: 84.68, elevation: 4921 },
	{ lat: 32, lon: 85.53, elevation: 5026 },
	{ lat: 32, lon: 86.38, elevation: 4790 },
	{ lat: 32, lon: 87.23, elevation: 4611 },
	{ lat: 32, lon: 88.09, elevation: 4550 },
	{ lat: 32, lon: 88.94, elevation: 4537 },
	{ lat: 32, lon: 89.79, elevation: 4630 },
	{ lat: 32, lon: 90.64, elevation: 4755 },
	{ lat: 32, lon: 91.49, elevation: 4585 },
	{ lat: 32, lon: 92.34, elevation: 4953 },
	{ lat: 32, lon: 93.19, elevation: 4985 },
	{ lat: 32, lon: 94.04, elevation: 4789 },
	{ lat: 32, lon: 94.89, elevation: 5018 },
	{ lat: 32, lon: 95.74, elevation: 5083 },
	{ lat: 32, lon: 96.6, elevation: 4820 },
	{ lat: 32, lon: 97.45, elevation: 3997 },
	{ lat: 32, lon: 98.3, elevation: 4650 },
	{ lat: 32, lon: 99.15, elevation: 4033 },
	{ lat: 32, lon: 100.0, elevation: 3959 },
	{ lat: 32, lon: 100.85, elevation: 3981 },
	{ lat: 32, lon: 101.7, elevation: 4190 },
	{ lat: 32, lon: 102.55, elevation: 4319 },
	{ lat: 32, lon: 103.4, elevation: 2880 },
	{ lat: 32, lon: 104.26, elevation: 1716 },
	{ lat: 32, lon: 105.11, elevation: 691 },
	{ lat: 32, lon: 105.96, elevation: 874 },
	{ lat: 32, lon: 106.81, elevation: 994 },
	{ lat: 32, lon: 107.66, elevation: 1352 },
	{ lat: 32, lon: 108.51, elevation: 580 },
	{ lat: 32, lon: 109.36, elevation: 2144 },
	{ lat: 32, lon: 110.21, elevation: 743 },
	{ lat: 32, lon: 111.06, elevation: 611 },
	{ lat: 32, lon: 111.91, elevation: 146 },
	{ lat: 32, lon: 112.77, elevation: 111 },
	{ lat: 32, lon: 113.62, elevation: 205 },
	{ lat: 32, lon: 114.47, elevation: 94 },
	{ lat: 32, lon: 115.32, elevation: 66 },
	{ lat: 32, lon: 116.17, elevation: 45 },
	{ lat: 32, lon: 117.02, elevation: 68 },
	{ lat: 32, lon: 117.87, elevation: 37 },
	{ lat: 32, lon: 118.72, elevation: 2 },
	{ lat: 32, lon: 119.57, elevation: 13 },
	{ lat: 32, lon: 120.43, elevation: 4 },
	{ lat: 32, lon: 121.28, elevation: 4 },
	{ lat: 32, lon: 130.64, elevation: 169 },
	{ lat: 32, lon: 131.49, elevation: 5 },
	{ lat: 33, lon: -117.13, elevation: 109 },
	{ lat: 33, lon: -116.27, elevation: 761 },
	{ lat: 33, lon: -115.41, elevation: -32 },
	{ lat: 33, lon: -114.55, elevation: 198 },
	{ lat: 33, lon: -113.68, elevation: 231 },
	{ lat: 33, lon: -112.82, elevation: 183 },
	{ lat: 33, lon: -111.96, elevation: 376 },
	{ lat: 33, lon: -111.1, elevation: 816 },
	{ lat: 33, lon: -110.24, elevation: 1220 },
	{ lat: 33, lon: -109.38, elevation: 1230 },
	{ lat: 33, lon: -108.52, elevation: 1486 },
	{ lat: 33, lon: -107.66, elevation: 1879 },
	{ lat: 33, lon: -106.79, elevation: 1594 },
	{ lat: 33, lon: -105.93, elevation: 1529 },
	{ lat: 33, lon: -105.07, elevation: 1539 },
	{ lat: 33, lon: -104.21, elevation: 1077 },
	{ lat: 33, lon: -103.35, elevation: 1198 },
	{ lat: 33, lon: -102.49, elevation: 1024 },
	{ lat: 33, lon: -101.63, elevation: 891 },
	{ lat: 33, lon: -100.77, elevation: 652 },
	{ lat: 33, lon: -99.9, elevation: 483 },
	{ lat: 33, lon: -99.04, elevation: 385 },
	{ lat: 33, lon: -98.18, elevation: 304 },
	{ lat: 33, lon: -97.32, elevation: 214 },
	{ lat: 33, lon: -96.46, elevation: 153 },
	{ lat: 33, lon: -95.6, elevation: 160 },
	{ lat: 33, lon: -94.74, elevation: 133 },
	{ lat: 33, lon: -93.88, elevation: 80 },
	{ lat: 33, lon: -93.01, elevation: 85 },
	{ lat: 33, lon: -92.15, elevation: 27 },
	{ lat: 33, lon: -91.29, elevation: 39 },
	{ lat: 33, lon: -90.43, elevation: 31 },
	{ lat: 33, lon: -89.57, elevation: 129 },
	{ lat: 33, lon: -88.71, elevation: 109 },
	{ lat: 33, lon: -87.85, elevation: 119 },
	{ lat: 33, lon: -86.99, elevation: 126 },
	{ lat: 33, lon: -86.12, elevation: 264 },
	{ lat: 33, lon: -85.26, elevation: 218 },
	{ lat: 33, lon: -84.4, elevation: 270 },
	{ lat: 33, lon: -83.54, elevation: 177 },
	{ lat: 33, lon: -82.68, elevation: 107 },
	{ lat: 33, lon: -81.82, elevation: 81 },
	{ lat: 33, lon: -80.96, elevation: 37 },
	{ lat: 33, lon: -80.1, elevation: 14 },
	{ lat: 33, lon: -8.61, elevation: 75 },
	{ lat: 33, lon: -7.75, elevation: 355 },
	{ lat: 33, lon: -6.89, elevation: 713 },
	{ lat: 33, lon: -6.03, elevation: 884 },
	{ lat: 33, lon: -5.17, elevation: 2314 },
	{ lat: 33, lon: -4.31, elevation: 1246 },
	{ lat: 33, lon: -3.44, elevation: 1372 },
	{ lat: 33, lon: -2.58, elevation: 1312 },
	{ lat: 33, lon: -1.72, elevation: 1305 },
	{ lat: 33, lon: -0.86, elevation: 1288 },
	{ lat: 33, lon: 0.0, elevation: 1091 },
	{ lat: 33, lon: 0.86, elevation: 955 },
	{ lat: 33, lon: 1.72, elevation: 833 },
	{ lat: 33, lon: 2.58, elevation: 763 },
	{ lat: 33, lon: 3.44, elevation: 761 },
	{ lat: 33, lon: 4.31, elevation: 440 },
	{ lat: 33, lon: 5.17, elevation: 212 },
	{ lat: 33, lon: 6.03, elevation: 81 },
	{ lat: 33, lon: 6.89, elevation: 106 },
	{ lat: 33, lon: 7.75, elevation: 82 },
	{ lat: 33, lon: 8.61, elevation: 97 },
	{ lat: 33, lon: 9.47, elevation: 212 },
	{ lat: 33, lon: 10.33, elevation: 269 },
	{ lat: 33, lon: 11.2, elevation: 36 },
	{ lat: 33, lon: 35.31, elevation: 534 },
	{ lat: 33, lon: 36.17, elevation: 599 },
	{ lat: 33, lon: 37.03, elevation: 667 },
	{ lat: 33, lon: 37.89, elevation: 661 },
	{ lat: 33, lon: 38.76, elevation: 772 },
	{ lat: 33, lon: 39.62, elevation: 799 },
	{ lat: 33, lon: 40.48, elevation: 613 },
	{ lat: 33, lon: 41.34, elevation: 492 },
	{ lat: 33, lon: 42.2, elevation: 334 },
	{ lat: 33, lon: 43.06, elevation: 106 },
	{ lat: 33, lon: 43.92, elevation: 54 },
	{ lat: 33, lon: 44.78, elevation: 28 },
	{ lat: 33, lon: 45.65, elevation: 22 },
	{ lat: 33, lon: 46.51, elevation: 209 },
	{ lat: 33, lon: 47.37, elevation: 1030 },
	{ lat: 33, lon: 48.23, elevation: 926 },
	{ lat: 33, lon: 49.09, elevation: 1062 },
	{ lat: 33, lon: 49.95, elevation: 3008 },
	{ lat: 33, lon: 50.81, elevation: 2436 },
	{ lat: 33, lon: 51.67, elevation: 1620 },
	{ lat: 33, lon: 52.54, elevation: 2231 },
	{ lat: 33, lon: 53.4, elevation: 1151 },
	{ lat: 33, lon: 54.26, elevation: 923 },
	{ lat: 33, lon: 55.12, elevation: 1363 },
	{ lat: 33, lon: 55.98, elevation: 926 },
	{ lat: 33, lon: 56.84, elevation: 842 },
	{ lat: 33, lon: 57.7, elevation: 932 },
	{ lat: 33, lon: 58.56, elevation: 1522 },
	{ lat: 33, lon: 59.43, elevation: 1938 },
	{ lat: 33, lon: 60.29, elevation: 1768 },
	{ lat: 33, lon: 61.15, elevation: 907 },
	{ lat: 33, lon: 62.01, elevation: 1167 },
	{ lat: 33, lon: 62.87, elevation: 1115 },
	{ lat: 33, lon: 63.73, elevation: 2940 },
	{ lat: 33, lon: 64.59, elevation: 2411 },
	{ lat: 33, lon: 65.45, elevation: 1611 },
	{ lat: 33, lon: 66.32, elevation: 2152 },
	{ lat: 33, lon: 67.18, elevation: 2383 },
	{ lat: 33, lon: 68.04, elevation: 2034 },
	{ lat: 33, lon: 68.9, elevation: 2432 },
	{ lat: 33, lon: 69.76, elevation: 1408 },
	{ lat: 33, lon: 70.62, elevation: 365 },
	{ lat: 33, lon: 71.48, elevation: 366 },
	{ lat: 33, lon: 72.34, elevation: 428 },
	{ lat: 33, lon: 73.21, elevation: 500 },
	{ lat: 33, lon: 74.07, elevation: 454 },
	{ lat: 33, lon: 74.93, elevation: 1038 },
	{ lat: 33, lon: 75.79, elevation: 2512 },
	{ lat: 33, lon: 76.65, elevation: 4829 },
	{ lat: 33, lon: 77.51, elevation: 5154 },
	{ lat: 33, lon: 78.37, elevation: 5343 },
	{ lat: 33, lon: 79.23, elevation: 4209 },
	{ lat: 33, lon: 80.1, elevation: 5247 },
	{ lat: 33, lon: 80.96, elevation: 5399 },
	{ lat: 33, lon: 81.82, elevation: 4892 },
	{ lat: 33, lon: 82.68, elevation: 4544 },
	{ lat: 33, lon: 83.54, elevation: 4792 },
	{ lat: 33, lon: 84.4, elevation: 4869 },
	{ lat: 33, lon: 85.26, elevation: 4801 },
	{ lat: 33, lon: 86.12, elevation: 4863 },
	{ lat: 33, lon: 86.99, elevation: 5004 },
	{ lat: 33, lon: 87.85, elevation: 4939 },
	{ lat: 33, lon: 88.71, elevation: 4822 },
	{ lat: 33, lon: 89.57, elevation: 5043 },
	{ lat: 33, lon: 90.43, elevation: 5097 },
	{ lat: 33, lon: 91.29, elevation: 5095 },
	{ lat: 33, lon: 92.15, elevation: 5262 },
	{ lat: 33, lon: 93.01, elevation: 4833 },
	{ lat: 33, lon: 93.88, elevation: 4740 },
	{ lat: 33, lon: 94.74, elevation: 4749 },
	{ lat: 33, lon: 95.6, elevation: 4308 },
	{ lat: 33, lon: 96.46, elevation: 4428 },
	{ lat: 33, lon: 97.32, elevation: 3847 },
	{ lat: 33, lon: 98.18, elevation: 4326 },
	{ lat: 33, lon: 99.04, elevation: 4761 },
	{ lat: 33, lon: 99.9, elevation: 4345 },
	{ lat: 33, lon: 100.77, elevation: 4205 },
	{ lat: 33, lon: 101.63, elevation: 3474 },
	{ lat: 33, lon: 102.49, elevation: 3540 },
	{ lat: 33, lon: 103.35, elevation: 3841 },
	{ lat: 33, lon: 104.21, elevation: 2441 },
	{ lat: 33, lon: 105.07, elevation: 1765 },
	{ lat: 33, lon: 105.93, elevation: 1270 },
	{ lat: 33, lon: 106.79, elevation: 549 },
	{ lat: 33, lon: 107.66, elevation: 601 },
	{ lat: 33, lon: 108.52, elevation: 666 },
	{ lat: 33, lon: 109.38, elevation: 1696 },
	{ lat: 33, lon: 110.24, elevation: 557 },
	{ lat: 33, lon: 111.1, elevation: 387 },
	{ lat: 33, lon: 111.96, elevation: 168 },
	{ lat: 33, lon: 112.82, elevation: 123 },
	{ lat: 33, lon: 113.68, elevation: 91 },
	{ lat: 33, lon: 114.55, elevation: 45 },
	{ lat: 33, lon: 115.41, elevation: 37 },
	{ lat: 33, lon: 116.27, elevation: 25 },
	{ lat: 33, lon: 117.13, elevation: 23 },
	{ lat: 33, lon: 117.99, elevation: 34 },
	{ lat: 33, lon: 118.85, elevation: 16 },
	{ lat: 33, lon: 119.71, elevation: 1 },
	{ lat: 33, lon: 120.57, elevation: 1 },
	{ lat: 33, lon: 129.19, elevation: 0 },
	{ lat: 33, lon: 130.05, elevation: 791 },
	{ lat: 33, lon: 130.91, elevation: 432 },
	{ lat: 33, lon: 131.77, elevation: 318 },
	{ lat: 33, lon: 132.63, elevation: 404 },
	{ lat: 34, lon: -119.85, elevation: 237 },
	{ lat: 34, lon: -118.11, elevation: 64 },
	{ lat: 34, lon: -117.24, elevation: 527 },
	{ lat: 34, lon: -116.37, elevation: 1212 },
	{ lat: 34, lon: -115.5, elevation: 416 },
	{ lat: 34, lon: -114.62, elevation: 342 },
	{ lat: 34, lon: -113.75, elevation: 450 },
	{ lat: 34, lon: -112.88, elevation: 746 },
	{ lat: 34, lon: -112.01, elevation: 1079 },
	{ lat: 34, lon: -111.14, elevation: 1681 },
	{ lat: 34, lon: -110.27, elevation: 1523 },
	{ lat: 34, lon: -109.39, elevation: 2832 },
	{ lat: 34, lon: -108.52, elevation: 2406 },
	{ lat: 34, lon: -107.65, elevation: 2188 },
	{ lat: 34, lon: -106.78, elevation: 1549 },
	{ lat: 34, lon: -105.91, elevation: 1815 },
	{ lat: 34, lon: -105.04, elevation: 1547 },
	{ lat: 34, lon: -104.16, elevation: 1166 },
	{ lat: 34, lon: -103.29, elevation: 1290 },
	{ lat: 34, lon: -102.42, elevation: 1109 },
	{ lat: 34, lon: -101.55, elevation: 989 },
	{ lat: 34, lon: -100.68, elevation: 675 },
	{ lat: 34, lon: -99.81, elevation: 477 },
	{ lat: 34, lon: -98.93, elevation: 361 },
	{ lat: 34, lon: -98.06, elevation: 256 },
	{ lat: 34, lon: -97.19, elevation: 281 },
	{ lat: 34, lon: -96.32, elevation: 196 },
	{ lat: 34, lon: -95.45, elevation: 151 },
	{ lat: 34, lon: -94.58, elevation: 106 },
	{ lat: 34, lon: -93.7, elevation: 147 },
	{ lat: 34, lon: -92.83, elevation: 100 },
	{ lat: 34, lon: -91.96, elevation: 89 },
	{ lat: 34, lon: -91.09, elevation: 53 },
	{ lat: 34, lon: -90.22, elevation: 48 },
	{ lat: 34, lon: -89.35, elevation: 102 },
	{ lat: 34, lon: -88.47, elevation: 73 },
	{ lat: 34, lon: -87.6, elevation: 214 },
	{ lat: 34, lon: -86.73, elevation: 214 },
	{ lat: 34, lon: -85.86, elevation: 182 },
	{ lat: 34, lon: -84.99, elevation: 268 },
	{ lat: 34, lon: -84.12, elevation: 316 },
	{ lat: 34, lon: -83.24, elevation: 222 },
	{ lat: 34, lon: -82.37, elevation: 123 },
	{ lat: 34, lon: -81.5, elevation: 143 },
	{ lat: 34, lon: -80.63, elevation: 46 },
	{ lat: 34, lon: -79.76, elevation: 28 },
	{ lat: 34, lon: -78.89, elevation: 24 },
	{ lat: 34, lon: -78.01, elevation: 12 },
	{ lat: 34, lon: -6.54, elevation: 170 },
	{ lat: 34, lon: -5.67, elevation: 341 },
	{ lat: 34, lon: -4.79, elevation: 254 },
	{ lat: 34, lon: -3.92, elevation: 1131 },
	{ lat: 34, lon: -3.05, elevation: 949 },
	{ lat: 34, lon: -2.18, elevation: 1032 },
	{ lat: 34, lon: -1.31, elevation: 1177 },
	{ lat: 34, lon: -0.44, elevation: 1042 },
	{ lat: 34, lon: 0.44, elevation: 1048 },
	{ lat: 34, lon: 1.31, elevation: 1267 },
	{ lat: 34, lon: 2.18, elevation: 1356 },
	{ lat: 34, lon: 3.05, elevation: 840 },
	{ lat: 34, lon: 3.92, elevation: 649 },
	{ lat: 34, lon: 4.79, elevation: 365 },
	{ lat: 34, lon: 5.67, elevation: 93 },
	{ lat: 34, lon: 6.54, elevation: -22 },
	{ lat: 34, lon: 7.41, elevation: 11 },
	{ lat: 34, lon: 8.28, elevation: 22 },
	{ lat: 34, lon: 9.15, elevation: 51 },
	{ lat: 34, lon: 10.02, elevation: 5 },
	{ lat: 34, lon: 36.17, elevation: 1089 },
	{ lat: 34, lon: 37.05, elevation: 1283 },
	{ lat: 34, lon: 37.92, elevation: 730 },
	{ lat: 34, lon: 38.79, elevation: 684 },
	{ lat: 34, lon: 39.66, elevation: 581 },
	{ lat: 34, lon: 40.53, elevation: 415 },
	{ lat: 34, lon: 41.4, elevation: 402 },
	{ lat: 34, lon: 42.28, elevation: 192 },
	{ lat: 34, lon: 43.15, elevation: 44 },
	{ lat: 34, lon: 44.02, elevation: 53 },
	{ lat: 34, lon: 44.89, elevation: 55 },
	{ lat: 34, lon: 45.76, elevation: 669 },
	{ lat: 34, lon: 46.63, elevation: 1312 },
	{ lat: 34, lon: 47.51, elevation: 1835 },
	{ lat: 34, lon: 48.38, elevation: 3210 },
	{ lat: 34, lon: 49.25, elevation: 2061 },
	{ lat: 34, lon: 50.12, elevation: 2236 },
	{ lat: 34, lon: 50.99, elevation: 2402 },
	{ lat: 34, lon: 51.86, elevation: 1070 },
	{ lat: 34, lon: 52.74, elevation: 1329 },
	{ lat: 34, lon: 53.61, elevation: 719 },
	{ lat: 34, lon: 54.48, elevation: 1064 },
	{ lat: 34, lon: 55.35, elevation: 706 },
	{ lat: 34, lon: 56.22, elevation: 744 },
	{ lat: 34, lon: 57.09, elevation: 1310 },
	{ lat: 34, lon: 57.97, elevation: 1316 },
	{ lat: 34, lon: 58.84, elevation: 1512 },
	{ lat: 34, lon: 59.71, elevation: 953 },
	{ lat: 34, lon: 60.58, elevation: 596 },
	{ lat: 34, lon: 61.45, elevation: 1238 },
	{ lat: 34, lon: 62.32, elevation: 1798 },
	{ lat: 34, lon: 63.2, elevation: 2214 },
	{ lat: 34, lon: 64.07, elevation: 2637 },
	{ lat: 34, lon: 64.94, elevation: 2547 },
	{ lat: 34, lon: 65.81, elevation: 2831 },
	{ lat: 34, lon: 66.68, elevation: 3163 },
	{ lat: 34, lon: 67.55, elevation: 3497 },
	{ lat: 34, lon: 68.43, elevation: 3047 },
	{ lat: 34, lon: 69.3, elevation: 2762 },
	{ lat: 34, lon: 70.17, elevation: 3931 },
	{ lat: 34, lon: 71.04, elevation: 1448 },
	{ lat: 34, lon: 71.91, elevation: 304 },
	{ lat: 34, lon: 72.78, elevation: 1007 },
	{ lat: 34, lon: 73.66, elevation: 1327 },
	{ lat: 34, lon: 74.53, elevation: 1939 },
	{ lat: 34, lon: 75.4, elevation: 4377 },
	{ lat: 34, lon: 76.27, elevation: 4746 },
	{ lat: 34, lon: 77.14, elevation: 3935 },
	{ lat: 34, lon: 78.01, elevation: 5054 },
	{ lat: 34, lon: 78.89, elevation: 4944 },
	{ lat: 34, lon: 79.76, elevation: 5462 },
	{ lat: 34, lon: 80.63, elevation: 4982 },
	{ lat: 34, lon: 81.5, elevation: 4812 },
	{ lat: 34, lon: 82.37, elevation: 4937 },
	{ lat: 34, lon: 83.24, elevation: 4975 },
	{ lat: 34, lon: 84.12, elevation: 5792 },
	{ lat: 34, lon: 84.99, elevation: 4952 },
	{ lat: 34, lon: 85.86, elevation: 5068 },
	{ lat: 34, lon: 86.73, elevation: 5063 },
	{ lat: 34, lon: 87.6, elevation: 5024 },
	{ lat: 34, lon: 88.47, elevation: 5230 },
	{ lat: 34, lon: 89.35, elevation: 5413 },
	{ lat: 34, lon: 90.22, elevation: 5354 },
	{ lat: 34, lon: 91.09, elevation: 5163 },
	{ lat: 34, lon: 91.96, elevation: 4868 },
	{ lat: 34, lon: 92.83, elevation: 4488 },
	{ lat: 34, lon: 93.7, elevation: 4459 },
	{ lat: 34, lon: 94.58, elevation: 5014 },
	{ lat: 34, lon: 95.45, elevation: 4974 },
	{ lat: 34, lon: 96.32, elevation: 4250 },
	{ lat: 34, lon: 97.19, elevation: 4596 },
	{ lat: 34, lon: 98.06, elevation: 4763 },
	{ lat: 34, lon: 98.93, elevation: 4531 },
	{ lat: 34, lon: 99.81, elevation: 4111 },
	{ lat: 34, lon: 100.68, elevation: 4029 },
	{ lat: 34, lon: 101.55, elevation: 4375 },
	{ lat: 34, lon: 102.42, elevation: 3429 },
	{ lat: 34, lon: 103.29, elevation: 3234 },
	{ lat: 34, lon: 104.16, elevation: 2676 },
	{ lat: 34, lon: 105.04, elevation: 1788 },
	{ lat: 34, lon: 105.91, elevation: 1588 },
	{ lat: 34, lon: 106.78, elevation: 1603 },
	{ lat: 34, lon: 107.65, elevation: 1881 },
	{ lat: 34, lon: 108.52, elevation: 807 },
	{ lat: 34, lon: 109.39, elevation: 885 },
	{ lat: 34, lon: 110.27, elevation: 1075 },
	{ lat: 34, lon: 111.14, elevation: 1311 },
	{ lat: 34, lon: 112.01, elevation: 645 },
	{ lat: 34, lon: 112.88, elevation: 235 },
	{ lat: 34, lon: 113.75, elevation: 70 },
	{ lat: 34, lon: 114.62, elevation: 54 },
	{ lat: 34, lon: 115.5, elevation: 42 },
	{ lat: 34, lon: 116.37, elevation: 37 },
	{ lat: 34, lon: 117.24, elevation: 28 },
	{ lat: 34, lon: 118.11, elevation: 22 },
	{ lat: 34, lon: 118.98, elevation: 5 },
	{ lat: 34, lon: 119.85, elevation: 1 },
	{ lat: 34, lon: 131.19, elevation: 5 },
	{ lat: 34, lon: 132.06, elevation: 227 },
	{ lat: 34, lon: 132.93, elevation: 186 },
	{ lat: 34, lon: 133.8, elevation: 352 },
	{ lat: 34, lon: 135.54, elevation: 792 },
	{ lat: 35, lon: -120.0, elevation: 1403 },
	{ lat: 35, lon: -119.12, elevation: 482 },
	{ lat: 35, lon: -118.24, elevation: 916 },
	{ lat: 35, lon: -117.35, elevation: 655 },
	{ lat: 35, lon: -116.47, elevation: 468 },
	{ lat: 35, lon: -115.59, elevation: 865 },
	{ lat: 35, lon: -114.71, elevation: 303 },
	{ lat: 35, lon: -113.82, elevation: 1516 },
	{ lat: 35, lon: -112.94, elevation: 1940 },
	{ lat: 35, lon: -112.06, elevation: 1973 },
	{ lat: 35, lon: -111.18, elevation: 1831 },
	{ lat: 35, lon: -110.29, elevation: 1586 },
	{ lat: 35, lon: -109.41, elevation: 1923 },
	{ lat: 35, lon: -108.53, elevation: 2180 },
	{ lat: 35, lon: -107.65, elevation: 1959 },
	{ lat: 35, lon: -106.76, elevation: 1648 },
	{ lat: 35, lon: -105.88, elevation: 1931 },
	{ lat: 35, lon: -105.0, elevation: 1627 },
	{ lat: 35, lon: -104.12, elevation: 1639 },
	{ lat: 35, lon: -103.24, elevation: 1252 },
	{ lat: 35, lon: -102.35, elevation: 1196 },
	{ lat: 35, lon: -101.47, elevation: 1044 },
	{ lat: 35, lon: -100.59, elevation: 724 },
	{ lat: 35, lon: -99.71, elevation: 532 },
	{ lat: 35, lon: -98.82, elevation: 431 },
	{ lat: 35, lon: -97.94, elevation: 361 },
	{ lat: 35, lon: -97.06, elevation: 329 },
	{ lat: 35, lon: -96.18, elevation: 239 },
	{ lat: 35, lon: -95.29, elevation: 441 },
	{ lat: 35, lon: -94.41, elevation: 215 },
	{ lat: 35, lon: -93.53, elevation: 113 },
	{ lat: 35, lon: -92.65, elevation: 84 },
	{ lat: 35, lon: -91.76, elevation: 70 },
	{ lat: 35, lon: -90.88, elevation: 56 },
	{ lat: 35, lon: -90.0, elevation: 102 },
	{ lat: 35, lon: -89.12, elevation: 176 },
	{ lat: 35, lon: -88.24, elevation: 150 },
	{ lat: 35, lon: -87.35, elevation: 231 },
	{ lat: 35, lon: -86.47, elevation: 272 },
	{ lat: 35, lon: -85.59, elevation: 194 },
	{ lat: 35, lon: -84.71, elevation: 256 },
	{ lat: 35, lon: -83.82, elevation: 620 },
	{ lat: 35, lon: -82.94, elevation: 342 },
	{ lat: 35, lon: -82.06, elevation: 271 },
	{ lat: 35, lon: -81.18, elevation: 218 },
	{ lat: 35, lon: -80.29, elevation: 154 },
	{ lat: 35, lon: -79.41, elevation: 79 },
	{ lat: 35, lon: -78.53, elevation: 29 },
	{ lat: 35, lon: -77.65, elevation: 25 },
	{ lat: 35, lon: -76.76, elevation: 2 },
	{ lat: 35, lon: -6.18, elevation: 66 },
	{ lat: 35, lon: -5.29, elevation: 488 },
	{ lat: 35, lon: -4.41, elevation: 1333 },
	{ lat: 35, lon: -3.53, elevation: 428 },
	{ lat: 35, lon: -2.65, elevation: 457 },
	{ lat: 35, lon: -1.76, elevation: 477 },
	{ lat: 35, lon: -0.88, elevation: 657 },
	{ lat: 35, lon: 0.0, elevation: 685 },
	{ lat: 35, lon: 0.88, elevation: 1205 },
	{ lat: 35, lon: 1.76, elevation: 1259 },
	{ lat: 35, lon: 2.65, elevation: 940 },
	{ lat: 35, lon: 3.53, elevation: 1087 },
	{ lat: 35, lon: 4.41, elevation: 1257 },
	{ lat: 35, lon: 5.29, elevation: 246 },
	{ lat: 35, lon: 6.18, elevation: 1411 },
	{ lat: 35, lon: 7.06, elevation: 1134 },
	{ lat: 35, lon: 7.94, elevation: 1018 },
	{ lat: 35, lon: 8.82, elevation: 738 },
	{ lat: 35, lon: 9.71, elevation: 279 },
	{ lat: 35, lon: 10.59, elevation: 126 },
	{ lat: 35, lon: 32.65, elevation: 968 },
	{ lat: 35, lon: 33.53, elevation: 227 },
	{ lat: 35, lon: 36.18, elevation: 649 },
	{ lat: 35, lon: 37.06, elevation: 470 },
	{ lat: 35, lon: 37.94, elevation: 946 },
	{ lat: 35, lon: 38.82, elevation: 604 },
	{ lat: 35, lon: 39.71, elevation: 280 },
	{ lat: 35, lon: 40.59, elevation: 204 },
	{ lat: 35, lon: 41.47, elevation: 202 },
	{ lat: 35, lon: 42.35, elevation: 249 },
	{ lat: 35, lon: 43.24, elevation: 150 },
	{ lat: 35, lon: 44.12, elevation: 169 },
	{ lat: 35, lon: 45.0, elevation: 464 },
	{ lat: 35, lon: 45.88, elevation: 833 },
	{ lat: 35, lon: 46.76, elevation: 1573 },
	{ lat: 35, lon: 47.65, elevation: 2105 },
	{ lat: 35, lon: 48.53, elevation: 1694 },
	{ lat: 35, lon: 49.41, elevation: 1637 },
	{ lat: 35, lon: 50.29, elevation: 1141 },
	{ lat: 35, lon: 51.18, elevation: 806 },
	{ lat: 35, lon: 52.06, elevation: 769 },
	{ lat: 35, lon: 52.94, elevation: 766 },
	{ lat: 35, lon: 53.82, elevation: 936 },
	{ lat: 35, lon: 54.71, elevation: 717 },
	{ lat: 35, lon: 55.59, elevation: 707 },
	{ lat: 35, lon: 56.47, elevation: 709 },
	{ lat: 35, lon: 57.35, elevation: 916 },
	{ lat: 35, lon: 58.24, elevation: 809 },
	{ lat: 35, lon: 59.12, elevation: 1113 },
	{ lat: 35, lon: 60.0, elevation: 2395 },
	{ lat: 35, lon: 60.88, elevation: 805 },
	{ lat: 35, lon: 61.76, elevation: 895 },
	{ lat: 35, lon: 62.65, elevation: 1070 },
	{ lat: 35, lon: 63.53, elevation: 1239 },
	{ lat: 35, lon: 64.41, elevation: 2424 },
	{ lat: 35, lon: 65.29, elevation: 2462 },
	{ lat: 35, lon: 66.18, elevation: 3114 },
	{ lat: 35, lon: 67.06, elevation: 3453 },
	{ lat: 35, lon: 67.94, elevation: 2657 },
	{ lat: 35, lon: 68.82, elevation: 1872 },
	{ lat: 35, lon: 69.71, elevation: 2162 },
	{ lat: 35, lon: 70.59, elevation: 3153 },
	{ lat: 35, lon: 71.47, elevation: 1864 },
	{ lat: 35, lon: 72.35, elevation: 1362 },
	{ lat: 35, lon: 73.24, elevation: 3739 },
	{ lat: 35, lon: 74.12, elevation: 4032 },
	{ lat: 35, lon: 75.0, elevation: 4421 },
	{ lat: 35, lon: 75.88, elevation: 3769 },
	{ lat: 35, lon: 76.76, elevation: 4941 },
	{ lat: 35, lon: 77.65, elevation: 5603 },
	{ lat: 35, lon: 78.53, elevation: 5538 },
	{ lat: 35, lon: 79.41, elevation: 5128 },
	{ lat: 35, lon: 80.29, elevation: 4905 },
	{ lat: 35, lon: 81.18, elevation: 5080 },
	{ lat: 35, lon: 82.06, elevation: 5201 },
	{ lat: 35, lon: 82.94, elevation: 5104 },
	{ lat: 35, lon: 83.82, elevation: 5370 },
	{ lat: 35, lon: 84.71, elevation: 5013 },
	{ lat: 35, lon: 85.59, elevation: 5065 },
	{ lat: 35, lon: 86.47, elevation: 4892 },
	{ lat: 35, lon: 87.35, elevation: 4986 },
	{ lat: 35, lon: 88.24, elevation: 4902 },
	{ lat: 35, lon: 89.12, elevation: 5058 },
	{ lat: 35, lon: 90.0, elevation: 4926 },
	{ lat: 35, lon: 90.88, elevation: 5017 },
	{ lat: 35, lon: 91.76, elevation: 4795 },
	{ lat: 35, lon: 92.65, elevation: 4705 },
	{ lat: 35, lon: 93.53, elevation: 4612 },
	{ lat: 35, lon: 94.41, elevation: 4470 },
	{ lat: 35, lon: 95.29, elevation: 4600 },
	{ lat: 35, lon: 96.18, elevation: 4743 },
	{ lat: 35, lon: 97.06, elevation: 4341 },
	{ lat: 35, lon: 97.94, elevation: 4263 },
	{ lat: 35, lon: 98.82, elevation: 4599 },
	{ lat: 35, lon: 99.71, elevation: 4658 },
	{ lat: 35, lon: 100.59, elevation: 3873 },
	{ lat: 35, lon: 101.47, elevation: 3716 },
	{ lat: 35, lon: 102.35, elevation: 3227 },
	{ lat: 35, lon: 103.24, elevation: 3514 },
	{ lat: 35, lon: 104.12, elevation: 2608 },
	{ lat: 35, lon: 105.0, elevation: 2049 },
	{ lat: 35, lon: 105.88, elevation: 1646 },
	{ lat: 35, lon: 106.76, elevation: 1287 },
	{ lat: 35, lon: 107.65, elevation: 1301 },
	{ lat: 35, lon: 108.53, elevation: 1528 },
	{ lat: 35, lon: 109.41, elevation: 575 },
	{ lat: 35, lon: 110.29, elevation: 343 },
	{ lat: 35, lon: 111.18, elevation: 735 },
	{ lat: 35, lon: 112.06, elevation: 485 },
	{ lat: 35, lon: 112.94, elevation: 112 },
	{ lat: 35, lon: 113.82, elevation: 80 },
	{ lat: 35, lon: 114.71, elevation: 67 },
	{ lat: 35, lon: 115.59, elevation: 51 },
	{ lat: 35, lon: 116.47, elevation: 36 },
	{ lat: 35, lon: 117.35, elevation: 79 },
	{ lat: 35, lon: 118.24, elevation: 63 },
	{ lat: 35, lon: 119.12, elevation: 13 },
	{ lat: 35, lon: 127.06, elevation: 340 },
	{ lat: 35, lon: 127.94, elevation: 16 },
	{ lat: 35, lon: 132.35, elevation: 328 },
	{ lat: 35, lon: 133.24, elevation: 725 },
	{ lat: 35, lon: 134.12, elevation: 127 },
	{ lat: 35, lon: 135.0, elevation: 127 },
	{ lat: 35, lon: 135.88, elevation: 92 },
	{ lat: 35, lon: 137.65, elevation: 370 },
	{ lat: 35, lon: 138.53, elevation: 0 },
	{ lat: 36, lon: -121.04, elevation: 577 },
	{ lat: 36, lon: -120.15, elevation: 236 },
	{ lat: 36, lon: -119.26, elevation: 89 },
	{ lat: 36, lon: -118.36, elevation: 2628 },
	{ lat: 36, lon: -117.47, elevation: 1739 },
	{ lat: 36, lon: -116.58, elevation: 1074 },
	{ lat: 36, lon: -115.68, elevation: 1099 },
	{ lat: 36, lon: -114.79, elevation: 682 },
	{ lat: 36, lon: -113.9, elevation: 1440 },
	{ lat: 36, lon: -113.0, elevation: 1957 },
	{ lat: 36, lon: -112.11, elevation: 2071 },
	{ lat: 36, lon: -111.22, elevation: 1620 },
	{ lat: 36, lon: -110.32, elevation: 1902 },
	{ lat: 36, lon: -109.43, elevation: 1989 },
	{ lat: 36, lon: -108.54, elevation: 1741 },
	{ lat: 36, lon: -107.64, elevation: 1986 },
	{ lat: 36, lon: -106.75, elevation: 2690 },
	{ lat: 36, lon: -105.86, elevation: 2148 },
	{ lat: 36, lon: -104.96, elevation: 2258 },
	{ lat: 36, lon: -104.07, elevation: 1716 },
	{ lat: 36, lon: -103.18, elevation: 1389 },
	{ lat: 36, lon: -102.28, elevation: 1186 },
	{ lat: 36, lon: -101.39, elevation: 998 },
	{ lat: 36, lon: -100.5, elevation: 775 },
	{ lat: 36, lon: -99.6, elevation: 665 },
	{ lat: 36, lon: -98.71, elevation: 599 },
	{ lat: 36, lon: -97.82, elevation: 355 },
	{ lat: 36, lon: -96.92, elevation: 265 },
	{ lat: 36, lon: -96.03, elevation: 202 },
	{ lat: 36, lon: -95.14, elevation: 246 },
	{ lat: 36, lon: -94.24, elevation: 547 },
	{ lat: 36, lon: -93.35, elevation: 581 },
	{ lat: 36, lon: -92.46, elevation: 252 },
	{ lat: 36, lon: -91.56, elevation: 188 },
	{ lat: 36, lon: -90.67, elevation: 134 },
	{ lat: 36, lon: -89.78, elevation: 76 },
	{ lat: 36, lon: -88.88, elevation: 138 },
	{ lat: 36, lon: -87.99, elevation: 110 },
	{ lat: 36, lon: -87.1, elevation: 264 },
	{ lat: 36, lon: -86.2, elevation: 255 },
	{ lat: 36, lon: -85.31, elevation: 569 },
	{ lat: 36, lon: -84.42, elevation: 410 },
	{ lat: 36, lon: -83.52, elevation: 322 },
	{ lat: 36, lon: -82.63, elevation: 814 },
	{ lat: 36, lon: -81.74, elevation: 624 },
	{ lat: 36, lon: -80.84, elevation: 294 },
	{ lat: 36, lon: -79.95, elevation: 246 },
	{ lat: 36, lon: -79.06, elevation: 153 },
	{ lat: 36, lon: -78.16, elevation: 77 },
	{ lat: 36, lon: -77.27, elevation: 23 },
	{ lat: 36, lon: 0.45, elevation: 148 },
	{ lat: 36, lon: 1.34, elevation: 375 },
	{ lat: 36, lon: 2.23, elevation: 624 },
	{ lat: 36, lon: 3.13, elevation: 913 },
	{ lat: 36, lon: 4.02, elevation: 992 },
	{ lat: 36, lon: 4.91, elevation: 939 },
	{ lat: 36, lon: 5.81, elevation: 894 },
	{ lat: 36, lon: 6.7, elevation: 1380 },
	{ lat: 36, lon: 7.59, elevation: 911 },
	{ lat: 36, lon: 8.49, elevation: 515 },
	{ lat: 36, lon: 9.38, elevation: 568 },
	{ lat: 36, lon: 10.27, elevation: 65 },
	{ lat: 36, lon: 36.18, elevation: 563 },
	{ lat: 36, lon: 37.07, elevation: 305 },
	{ lat: 36, lon: 37.97, elevation: 367 },
	{ lat: 36, lon: 38.86, elevation: 270 },
	{ lat: 36, lon: 39.75, elevation: 325 },
	{ lat: 36, lon: 40.65, elevation: 286 },
	{ lat: 36, lon: 41.54, elevation: 302 },
	{ lat: 36, lon: 42.43, elevation: 223 },
	{ lat: 36, lon: 43.33, elevation: 261 },
	{ lat: 36, lon: 44.22, elevation: 592 },
	{ lat: 36, lon: 45.11, elevation: 865 },
	{ lat: 36, lon: 46.0, elevation: 1951 },
	{ lat: 36, lon: 46.9, elevation: 2108 },
	{ lat: 36, lon: 47.79, elevation: 1564 },
	{ lat: 36, lon: 48.68, elevation: 1777 },
	{ lat: 36, lon: 49.58, elevation: 1314 },
	{ lat: 36, lon: 50.47, elevation: 1181 },
	{ lat: 36, lon: 51.36, elevation: 2804 },
	{ lat: 36, lon: 52.26, elevation: 2111 },
	{ lat: 36, lon: 53.15, elevation: 1525 },
	{ lat: 36, lon: 54.04, elevation: 1276 },
	{ lat: 36, lon: 54.94, elevation: 1071 },
	{ lat: 36, lon: 55.83, elevation: 1188 },
	{ lat: 36, lon: 56.72, elevation: 881 },
	{ lat: 36, lon: 57.62, elevation: 1199 },
	{ lat: 36, lon: 58.51, elevation: 1122 },
	{ lat: 36, lon: 59.4, elevation: 1505 },
	{ lat: 36, lon: 60.3, elevation: 904 },
	{ lat: 36, lon: 61.19, elevation: 548 },
	{ lat: 36, lon: 62.08, elevation: 649 },
	{ lat: 36, lon: 62.98, elevation: 480 },
	{ lat: 36, lon: 63.87, elevation: 719 },
	{ lat: 36, lon: 64.76, elevation: 870 },
	{ lat: 36, lon: 65.66, elevation: 1702 },
	{ lat: 36, lon: 66.55, elevation: 1204 },
	{ lat: 36, lon: 67.44, elevation: 1910 },
	{ lat: 36, lon: 68.34, elevation: 2775 },
	{ lat: 36, lon: 69.23, elevation: 1728 },
	{ lat: 36, lon: 70.12, elevation: 4797 },
	{ lat: 36, lon: 71.02, elevation: 4773 },
	{ lat: 36, lon: 71.91, elevation: 3780 },
	{ lat: 36, lon: 72.8, elevation: 4993 },
	{ lat: 36, lon: 73.7, elevation: 4274 },
	{ lat: 36, lon: 74.59, elevation: 4501 },
	{ lat: 36, lon: 75.48, elevation: 4799 },
	{ lat: 36, lon: 76.38, elevation: 4538 },
	{ lat: 36, lon: 77.27, elevation: 5451 },
	{ lat: 36, lon: 78.16, elevation: 4797 },
	{ lat: 36, lon: 79.06, elevation: 4999 },
	{ lat: 36, lon: 79.95, elevation: 4653 },
	{ lat: 36, lon: 80.84, elevation: 4160 },
	{ lat: 36, lon: 81.74, elevation: 5148 },
	{ lat: 36, lon: 82.63, elevation: 5318 },
	{ lat: 36, lon: 83.52, elevation: 5333 },
	{ lat: 36, lon: 84.42, elevation: 5105 },
	{ lat: 36, lon: 85.31, elevation: 5065 },
	{ lat: 36, lon: 86.2, elevation: 4942 },
	{ lat: 36, lon: 87.1, elevation: 4880 },
	{ lat: 36, lon: 87.99, elevation: 4868 },
	{ lat: 36, lon: 88.88, elevation: 4866 },
	{ lat: 36, lon: 89.78, elevation: 4966 },
	{ lat: 36, lon: 90.67, elevation: 5185 },
	{ lat: 36, lon: 91.56, elevation: 4813 },
	{ lat: 36, lon: 92.46, elevation: 4798 },
	{ lat: 36, lon: 93.35, elevation: 4482 },
	{ lat: 36, lon: 94.24, elevation: 4893 },
	{ lat: 36, lon: 95.14, elevation: 4538 },
	{ lat: 36, lon: 96.03, elevation: 3872 },
	{ lat: 36, lon: 96.92, elevation: 4005 },
	{ lat: 36, lon: 97.82, elevation: 3069 },
	{ lat: 36, lon: 98.71, elevation: 4094 },
	{ lat: 36, lon: 99.6, elevation: 4692 },
	{ lat: 36, lon: 100.5, elevation: 2983 },
	{ lat: 36, lon: 101.39, elevation: 2286 },
	{ lat: 36, lon: 102.28, elevation: 2420 },
	{ lat: 36, lon: 103.18, elevation: 1770 },
	{ lat: 36, lon: 104.07, elevation: 1699 },
	{ lat: 36, lon: 104.96, elevation: 1770 },
	{ lat: 36, lon: 105.86, elevation: 2078 },
	{ lat: 36, lon: 106.75, elevation: 1658 },
	{ lat: 36, lon: 107.64, elevation: 1313 },
	{ lat: 36, lon: 108.54, elevation: 1353 },
	{ lat: 36, lon: 109.43, elevation: 1061 },
	{ lat: 36, lon: 110.32, elevation: 1194 },
	{ lat: 36, lon: 111.22, elevation: 890 },
	{ lat: 36, lon: 112.11, elevation: 1252 },
	{ lat: 36, lon: 113.0, elevation: 1047 },
	{ lat: 36, lon: 113.9, elevation: 363 },
	{ lat: 36, lon: 114.79, elevation: 53 },
	{ lat: 36, lon: 115.68, elevation: 41 },
	{ lat: 36, lon: 116.58, elevation: 59 },
	{ lat: 36, lon: 117.47, elevation: 180 },
	{ lat: 36, lon: 118.36, elevation: 285 },
	{ lat: 36, lon: 119.26, elevation: 68 },
	{ lat: 36, lon: 120.15, elevation: 18 },
	{ lat: 36, lon: 127.3, elevation: 431 },
	{ lat: 36, lon: 128.19, elevation: 393 },
	{ lat: 36, lon: 129.08, elevation: 126 },
	{ lat: 36, lon: 136.23, elevation: 47 },
	{ lat: 36, lon: 137.12, elevation: 1003 },
	{ lat: 36, lon: 138.01, elevation: 875 },
	{ lat: 36, lon: 138.91, elevation: 822 },
	{ lat: 36, lon: 139.8, elevation: 5 },
	{ lat: 37, lon: -122.11, elevation: 225 },
	{ lat: 37, lon: -121.21, elevation: 579 },
	{ lat: 37, lon: -120.3, elevation: 56 },
	{ lat: 37, lon: -119.4, elevation: 570 },
	{ lat: 37, lon: -118.49, elevation: 3609 },
	{ lat: 37, lon: -117.59, elevation: 2147 },
	{ lat: 37, lon: -116.68, elevation: 1223 },
	{ lat: 37, lon: -115.78, elevation: 1568 },
	{ lat: 37, lon: -114.87, elevation: 914 },
	{ lat: 37, lon: -113.97, elevation: 750 },
	{ lat: 37, lon: -113.07, elevation: 1443 },
	{ lat: 37, lon: -112.16, elevation: 1827 },
	{ lat: 37, lon: -111.26, elevation: 1157 },
	{ lat: 37, lon: -110.35, elevation: 1582 },
	{ lat: 37, lon: -109.45, elevation: 1571 },
	{ lat: 37, lon: -108.54, elevation: 2012 },
	{ lat: 37, lon: -107.64, elevation: 2144 },
	{ lat: 37, lon: -106.73, elevation: 2691 },
	{ lat: 37, lon: -105.83, elevation: 2325 },
	{ lat: 37, lon: -104.92, elevation: 2540 },
	{ lat: 37, lon: -104.02, elevation: 1870 },
	{ lat: 37, lon: -103.12, elevation: 1584 },
	{ lat: 37, lon: -102.21, elevation: 1116 },
	{ lat: 37, lon: -101.31, elevation: 947 },
	{ lat: 37, lon: -100.4, elevation: 700 },
	{ lat: 37, lon: -99.5, elevation: 564 },
	{ lat: 37, lon: -98.59, elevation: 424 },
	{ lat: 37, lon: -97.69, elevation: 364 },
	{ lat: 37, lon: -96.78, elevation: 397 },
	{ lat: 37, lon: -95.88, elevation: 262 },
	{ lat: 37, lon: -94.97, elevation: 251 },
	{ lat: 37, lon: -94.07, elevation: 374 },
	{ lat: 37, lon: -93.17, elevation: 405 },
	{ lat: 37, lon: -92.26, elevation: 340 },
	{ lat: 37, lon: -91.36, elevation: 311 },
	{ lat: 37, lon: -90.45, elevation: 126 },
	{ lat: 37, lon: -89.55, elevation: 97 },
	{ lat: 37, lon: -88.64, elevation: 131 },
	{ lat: 37, lon: -87.74, elevation: 172 },
	{ lat: 37, lon: -86.83, elevation: 179 },
	{ lat: 37, lon: -85.93, elevation: 237 },
	{ lat: 37, lon: -85.03, elevation: 299 },
	{ lat: 37, lon: -84.12, elevation: 356 },
	{ lat: 37, lon: -83.22, elevation: 618 },
	{ lat: 37, lon: -82.31, elevation: 717 },
	{ lat: 37, lon: -81.41, elevation: 851 },
	{ lat: 37, lon: -80.5, elevation: 666 },
	{ lat: 37, lon: -79.6, elevation: 281 },
	{ lat: 37, lon: -78.69, elevation: 145 },
	{ lat: 37, lon: -77.79, elevation: 100 },
	{ lat: 37, lon: -76.88, elevation: 25 },
	{ lat: 37, lon: -6.33, elevation: 2 },
	{ lat: 37, lon: -5.43, elevation: 345 },
	{ lat: 37, lon: -4.52, elevation: 560 },
	{ lat: 37, lon: -3.62, elevation: 735 },
	{ lat: 37, lon: -2.71, elevation: 453 },
	{ lat: 37, lon: 6.33, elevation: 684 },
	{ lat: 37, lon: 9.05, elevation: 25 },
	{ lat: 37, lon: 9.95, elevation: 5 },
	{ lat: 37, lon: 14.47, elevation: 167 },
	{ lat: 37, lon: 21.71, elevation: 166 },
	{ lat: 37, lon: 22.61, elevation: 226 },
	{ lat: 37, lon: 28.94, elevation: 590 },
	{ lat: 37, lon: 29.85, elevation: 1550 },
	{ lat: 37, lon: 30.75, elevation: 103 },
	{ lat: 37, lon: 31.66, elevation: 880 },
	{ lat: 37, lon: 32.56, elevation: 1839 },
	{ lat: 37, lon: 33.47, elevation: 1457 },
	{ lat: 37, lon: 34.37, elevation: 1444 },
	{ lat: 37, lon: 35.28, elevation: 22 },
	{ lat: 37, lon: 36.18, elevation: 150 },
	{ lat: 37, lon: 37.09, elevation: 773 },
	{ lat: 37, lon: 37.99, elevation: 370 },
	{ lat: 37, lon: 38.89, elevation: 404 },
	{ lat: 37, lon: 39.8, elevation: 466 },
	{ lat: 37, lon: 40.7, elevation: 457 },
	{ lat: 37, lon: 41.61, elevation: 415 },
	{ lat: 37, lon: 42.51, elevation: 397 },
	{ lat: 37, lon: 43.42, elevation: 1391 },
	{ lat: 37, lon: 44.32, elevation: 815 },
	{ lat: 37, lon: 45.23, elevation: 1388 },
	{ lat: 37, lon: 46.13, elevation: 1294 },
	{ lat: 37, lon: 47.04, elevation: 1798 },
	{ lat: 37, lon: 47.94, elevation: 1365 },
	{ lat: 37, lon: 48.84, elevation: 616 },
	{ lat: 37, lon: 49.75, elevation: 495 },
	{ lat: 37, lon: 50.65, elevation: -29 },
	{ lat: 37, lon: 51.56, elevation: 0 },
	{ lat: 37, lon: 52.46, elevation: 0 },
	{ lat: 37, lon: 53.37, elevation: -29 },
	{ lat: 37, lon: 54.27, elevation: -19 },
	{ lat: 37, lon: 55.18, elevation: 644 },
	{ lat: 37, lon: 56.08, elevation: 1247 },
	{ lat: 37, lon: 56.98, elevation: 996 },
	{ lat: 37, lon: 57.89, elevation: 2377 },
	{ lat: 37, lon: 58.79, elevation: 1851 },
	{ lat: 37, lon: 59.7, elevation: 1242 },
	{ lat: 37, lon: 60.6, elevation: 226 },
	{ lat: 37, lon: 61.51, elevation: 243 },
	{ lat: 37, lon: 62.41, elevation: 310 },
	{ lat: 37, lon: 63.32, elevation: 486 },
	{ lat: 37, lon: 64.22, elevation: 383 },
	{ lat: 37, lon: 65.13, elevation: 291 },
	{ lat: 37, lon: 66.03, elevation: 280 },
	{ lat: 37, lon: 66.93, elevation: 307 },
	{ lat: 37, lon: 67.84, elevation: 333 },
	{ lat: 37, lon: 68.74, elevation: 403 },
	{ lat: 37, lon: 69.65, elevation: 673 },
	{ lat: 37, lon: 70.55, elevation: 2110 },
	{ lat: 37, lon: 71.46, elevation: 2854 },
	{ lat: 37, lon: 72.36, elevation: 2763 },
	{ lat: 37, lon: 73.27, elevation: 3253 },
	{ lat: 37, lon: 74.17, elevation: 5036 },
	{ lat: 37, lon: 75.08, elevation: 5276 },
	{ lat: 37, lon: 75.98, elevation: 3109 },
	{ lat: 37, lon: 76.88, elevation: 3421 },
	{ lat: 37, lon: 77.79, elevation: 3543 },
	{ lat: 37, lon: 78.69, elevation: 2479 },
	{ lat: 37, lon: 79.6, elevation: 1765 },
	{ lat: 37, lon: 80.5, elevation: 1364 },
	{ lat: 37, lon: 81.41, elevation: 1371 },
	{ lat: 37, lon: 82.31, elevation: 1472 },
	{ lat: 37, lon: 83.22, elevation: 1714 },
	{ lat: 37, lon: 84.12, elevation: 2598 },
	{ lat: 37, lon: 85.03, elevation: 4469 },
	{ lat: 37, lon: 85.93, elevation: 4917 },
	{ lat: 37, lon: 86.83, elevation: 4304 },
	{ lat: 37, lon: 87.74, elevation: 4463 },
	{ lat: 37, lon: 88.64, elevation: 4320 },
	{ lat: 37, lon: 89.55, elevation: 4251 },
	{ lat: 37, lon: 90.45, elevation: 4243 },
	{ lat: 37, lon: 91.36, elevation: 4814 },
	{ lat: 37, lon: 92.26, elevation: 3374 },
	{ lat: 37, lon: 93.17, elevation: 2849 },
	{ lat: 37, lon: 94.07, elevation: 2693 },
	{ lat: 37, lon: 94.97, elevation: 2681 },
	{ lat: 37, lon: 95.88, elevation: 2690 },
	{ lat: 37, lon: 96.78, elevation: 2819 },
	{ lat: 37, lon: 97.69, elevation: 3076 },
	{ lat: 37, lon: 98.59, elevation: 3411 },
	{ lat: 37, lon: 99.5, elevation: 3289 },
	{ lat: 37, lon: 100.4, elevation: 3194 },
	{ lat: 37, lon: 101.31, elevation: 3554 },
	{ lat: 37, lon: 102.21, elevation: 3231 },
	{ lat: 37, lon: 103.12, elevation: 2460 },
	{ lat: 37, lon: 104.02, elevation: 1898 },
	{ lat: 37, lon: 104.92, elevation: 1715 },
	{ lat: 37, lon: 105.83, elevation: 1402 },
	{ lat: 37, lon: 106.73, elevation: 2001 },
	{ lat: 37, lon: 107.64, elevation: 1464 },
	{ lat: 37, lon: 108.54, elevation: 1597 },
	{ lat: 37, lon: 109.45, elevation: 1266 },
	{ lat: 37, lon: 110.35, elevation: 811 },
	{ lat: 37, lon: 111.26, elevation: 1504 },
	{ lat: 37, lon: 112.16, elevation: 1525 },
	{ lat: 37, lon: 113.07, elevation: 1227 },
	{ lat: 37, lon: 113.97, elevation: 549 },
	{ lat: 37, lon: 114.87, elevation: 36 },
	{ lat: 37, lon: 115.78, elevation: 38 },
	{ lat: 37, lon: 116.68, elevation: 22 },
	{ lat: 37, lon: 117.59, elevation: 15 },
	{ lat: 37, lon: 118.49, elevation: 15 },
	{ lat: 37, lon: 119.4, elevation: 4 },
	{ lat: 37, lon: 120.3, elevation: 101 },
	{ lat: 37, lon: 121.21, elevation: 100 },
	{ lat: 37, lon: 122.11, elevation: 4 },
	{ lat: 37, lon: 127.54, elevation: 118 },
	{ lat: 37, lon: 128.44, elevation: 421 },
	{ lat: 37, lon: 129.35, elevation: 146 },
	{ lat: 37, lon: 138.39, elevation: 1063 },
	{ lat: 37, lon: 139.3, elevation: 1633 },
	{ lat: 37, lon: 140.2, elevation: 469 },
	{ lat: 38, lon: -122.29, elevation: 24 },
	{ lat: 38, lon: -121.37, elevation: -2 },
	{ lat: 38, lon: -120.46, elevation: 489 },
	{ lat: 38, lon: -119.54, elevation: 2403 },
	{ lat: 38, lon: -118.63, elevation: 2280 },
	{ lat: 38, lon: -117.71, elevation: 1446 },
	{ lat: 38, lon: -116.79, elevation: 1671 },
	{ lat: 38, lon: -115.88, elevation: 2119 },
	{ lat: 38, lon: -114.96, elevation: 1489 },
	{ lat: 38, lon: -114.05, elevation: 2307 },
	{ lat: 38, lon: -113.13, elevation: 1797 },
	{ lat: 38, lon: -112.21, elevation: 2969 },
	{ lat: 38, lon: -111.3, elevation: 2366 },
	{ lat: 38, lon: -110.38, elevation: 1169 },
	{ lat: 38, lon: -109.47, elevation: 1996 },
	{ lat: 38, lon: -108.55, elevation: 2127 },
	{ lat: 38, lon: -107.63, elevation: 3200 },
	{ lat: 38, lon: -106.72, elevation: 2960 },
	{ lat: 38, lon: -105.8, elevation: 2321 },
	{ lat: 38, lon: -104.89, elevation: 1851 },
	{ lat: 38, lon: -103.97, elevation: 1396 },
	{ lat: 38, lon: -103.05, elevation: 1206 },
	{ lat: 38, lon: -102.14, elevation: 1064 },
	{ lat: 38, lon: -101.22, elevation: 945 },
	{ lat: 38, lon: -100.31, elevation: 822 },
	{ lat: 38, lon: -99.39, elevation: 677 },
	{ lat: 38, lon: -98.47, elevation: 556 },
	{ lat: 38, lon: -97.56, elevation: 439 },
	{ lat: 38, lon: -96.64, elevation: 448 },
	{ lat: 38, lon: -95.73, elevation: 343 },
	{ lat: 38, lon: -94.81, elevation: 261 },
	{ lat: 38, lon: -93.89, elevation: 217 },
	{ lat: 38, lon: -92.98, elevation: 286 },
	{ lat: 38, lon: -92.06, elevation: 352 },
	{ lat: 38, lon: -91.15, elevation: 267 },
	{ lat: 38, lon: -90.23, elevation: 121 },
	{ lat: 38, lon: -89.31, elevation: 140 },
	{ lat: 38, lon: -88.4, elevation: 137 },
	{ lat: 38, lon: -87.48, elevation: 116 },
	{ lat: 38, lon: -86.56, elevation: 125 },
	{ lat: 38, lon: -85.65, elevation: 135 },
	{ lat: 38, lon: -84.73, elevation: 277 },
	{ lat: 38, lon: -83.82, elevation: 272 },
	{ lat: 38, lon: -82.9, elevation: 270 },
	{ lat: 38, lon: -81.98, elevation: 274 },
	{ lat: 38, lon: -81.07, elevation: 642 },
	{ lat: 38, lon: -80.15, elevation: 840 },
	{ lat: 38, lon: -79.24, elevation: 591 },
	{ lat: 38, lon: -78.32, elevation: 113 },
	{ lat: 38, lon: -77.4, elevation: 77 },
	{ lat: 38, lon: -76.49, elevation: 11 },
	{ lat: 38, lon: -75.57, elevation: 0 },
	{ lat: 38, lon: -8.7, elevation: 166 },
	{ lat: 38, lon: -7.79, elevation: 141 },
	{ lat: 38, lon: -6.87, elevation: 476 },
	{ lat: 38, lon: -5.95, elevation: 341 },
	{ lat: 38, lon: -5.04, elevation: 502 },
	{ lat: 38, lon: -4.12, elevation: 263 },
	{ lat: 38, lon: -3.21, elevation: 561 },
	{ lat: 38, lon: -2.29, elevation: 1280 },
	{ lat: 38, lon: -1.37, elevation: 256 },
	{ lat: 38, lon: 13.28, elevation: 769 },
	{ lat: 38, lon: 14.2, elevation: 460 },
	{ lat: 38, lon: 15.11, elevation: 535 },
	{ lat: 38, lon: 16.03, elevation: 580 },
	{ lat: 38, lon: 21.53, elevation: 421 },
	{ lat: 38, lon: 22.44, elevation: 1417 },
	{ lat: 38, lon: 23.36, elevation: 31 },
	{ lat: 38, lon: 24.27, elevation: 142 },
	{ lat: 38, lon: 27.94, elevation: 1453 },
	{ lat: 38, lon: 28.85, elevation: 1002 },
	{ lat: 38, lon: 29.77, elevation: 1054 },
	{ lat: 38, lon: 30.69, elevation: 1634 },
	{ lat: 38, lon: 31.6, elevation: 1403 },
	{ lat: 38, lon: 32.52, elevation: 1099 },
	{ lat: 38, lon: 33.44, elevation: 1071 },
	{ lat: 38, lon: 34.35, elevation: 1181 },
	{ lat: 38, lon: 35.27, elevation: 2340 },
	{ lat: 38, lon: 36.18, elevation: 1647 },
	{ lat: 38, lon: 37.1, elevation: 1123 },
	{ lat: 38, lon: 38.02, elevation: 1375 },
	{ lat: 38, lon: 38.93, elevation: 731 },
	{ lat: 38, lon: 39.85, elevation: 783 },
	{ lat: 38, lon: 40.76, elevation: 637 },
	{ lat: 38, lon: 41.68, elevation: 896 },
	{ lat: 38, lon: 42.6, elevation: 1478 },
	{ lat: 38, lon: 43.51, elevation: 2279 },
	{ lat: 38, lon: 44.43, elevation: 2462 },
	{ lat: 38, lon: 45.34, elevation: 1267 },
	{ lat: 38, lon: 46.26, elevation: 1716 },
	{ lat: 38, lon: 47.18, elevation: 1597 },
	{ lat: 38, lon: 48.09, elevation: 2014 },
	{ lat: 38, lon: 49.01, elevation: -29 },
	{ lat: 38, lon: 49.92, elevation: -29 },
	{ lat: 38, lon: 50.84, elevation: 0 },
	{ lat: 38, lon: 51.76, elevation: 0 },
	{ lat: 38, lon: 52.67, elevation: 0 },
	{ lat: 38, lon: 53.59, elevation: -29 },
	{ lat: 38, lon: 54.5, elevation: 9 },
	{ lat: 38, lon: 55.42, elevation: 99 },
	{ lat: 38, lon: 56.34, elevation: 601 },
	{ lat: 38, lon: 57.25, elevation: 1523 },
	{ lat: 38, lon: 58.17, elevation: 330 },
	{ lat: 38, lon: 59.08, elevation: 118 },
	{ lat: 38, lon: 60.0, elevation: 138 },
	{ lat: 38, lon: 60.92, elevation: 166 },
	{ lat: 38, lon: 61.83, elevation: 198 },
	{ lat: 38, lon: 62.75, elevation: 203 },
	{ lat: 38, lon: 63.66, elevation: 214 },
	{ lat: 38, lon: 64.58, elevation: 230 },
	{ lat: 38, lon: 65.5, elevation: 284 },
	{ lat: 38, lon: 66.41, elevation: 1077 },
	{ lat: 38, lon: 67.33, elevation: 794 },
	{ lat: 38, lon: 68.24, elevation: 674 },
	{ lat: 38, lon: 69.16, elevation: 790 },
	{ lat: 38, lon: 70.08, elevation: 2501 },
	{ lat: 38, lon: 70.99, elevation: 4257 },
	{ lat: 38, lon: 71.91, elevation: 4062 },
	{ lat: 38, lon: 72.82, elevation: 5047 },
	{ lat: 38, lon: 73.74, elevation: 4605 },
	{ lat: 38, lon: 74.66, elevation: 3946 },
	{ lat: 38, lon: 75.57, elevation: 4271 },
	{ lat: 38, lon: 76.49, elevation: 2161 },
	{ lat: 38, lon: 77.4, elevation: 1297 },
	{ lat: 38, lon: 78.32, elevation: 1278 },
	{ lat: 38, lon: 79.24, elevation: 1246 },
	{ lat: 38, lon: 80.15, elevation: 1238 },
	{ lat: 38, lon: 81.07, elevation: 1233 },
	{ lat: 38, lon: 81.98, elevation: 1206 },
	{ lat: 38, lon: 82.9, elevation: 1240 },
	{ lat: 38, lon: 83.82, elevation: 1254 },
	{ lat: 38, lon: 84.73, elevation: 1265 },
	{ lat: 38, lon: 85.65, elevation: 1379 },
	{ lat: 38, lon: 86.56, elevation: 3506 },
	{ lat: 38, lon: 87.48, elevation: 4291 },
	{ lat: 38, lon: 88.4, elevation: 4376 },
	{ lat: 38, lon: 89.31, elevation: 3503 },
	{ lat: 38, lon: 90.23, elevation: 3341 },
	{ lat: 38, lon: 91.15, elevation: 2893 },
	{ lat: 38, lon: 92.06, elevation: 2694 },
	{ lat: 38, lon: 92.98, elevation: 2689 },
	{ lat: 38, lon: 93.89, elevation: 2773 },
	{ lat: 38, lon: 94.81, elevation: 3066 },
	{ lat: 38, lon: 95.73, elevation: 4668 },
	{ lat: 38, lon: 96.64, elevation: 4628 },
	{ lat: 38, lon: 97.56, elevation: 4320 },
	{ lat: 38, lon: 98.47, elevation: 4439 },
	{ lat: 38, lon: 99.39, elevation: 3951 },
	{ lat: 38, lon: 100.31, elevation: 4140 },
	{ lat: 38, lon: 101.22, elevation: 3258 },
	{ lat: 38, lon: 102.14, elevation: 2800 },
	{ lat: 38, lon: 103.05, elevation: 1566 },
	{ lat: 38, lon: 103.97, elevation: 1502 },
	{ lat: 38, lon: 104.89, elevation: 1398 },
	{ lat: 38, lon: 105.8, elevation: 1325 },
	{ lat: 38, lon: 106.72, elevation: 1354 },
	{ lat: 38, lon: 107.63, elevation: 1361 },
	{ lat: 38, lon: 108.55, elevation: 1267 },
	{ lat: 38, lon: 109.47, elevation: 1209 },
	{ lat: 38, lon: 110.38, elevation: 1069 },
	{ lat: 38, lon: 111.3, elevation: 1407 },
	{ lat: 38, lon: 112.21, elevation: 1290 },
	{ lat: 38, lon: 113.13, elevation: 1166 },
	{ lat: 38, lon: 114.05, elevation: 321 },
	{ lat: 38, lon: 114.96, elevation: 47 },
	{ lat: 38, lon: 115.88, elevation: 21 },
	{ lat: 38, lon: 116.79, elevation: 12 },
	{ lat: 38, lon: 117.71, elevation: 6 },
	{ lat: 38, lon: 118.63, elevation: 4 },
	{ lat: 38, lon: 125.95, elevation: 61 },
	{ lat: 38, lon: 126.87, elevation: 67 },
	{ lat: 38, lon: 127.79, elevation: 625 },
	{ lat: 38, lon: 128.7, elevation: 113 },
	{ lat: 38, lon: 139.69, elevation: 566 },
	{ lat: 38, lon: 140.61, elevation: 58 },
	{ lat: 39, lon: -123.26, elevation: 536 },
	{ lat: 39, lon: -122.33, elevation: 662 },
	{ lat: 39, lon: -121.4, elevation: 29 },
	{ lat: 39, lon: -120.47, elevation: 1554 },
	{ lat: 39, lon: -119.53, elevation: 1880 },
	{ lat: 39, lon: -118.6, elevation: 1313 },
	{ lat: 39, lon: -117.67, elevation: 1954 },
	{ lat: 39, lon: -116.74, elevation: 2222 },
	{ lat: 39, lon: -115.81, elevation: 1902 },
	{ lat: 39, lon: -114.88, elevation: 2477 },
	{ lat: 39, lon: -113.95, elevation: 1550 },
	{ lat: 39, lon: -113.02, elevation: 1410 },
	{ lat: 39, lon: -112.09, elevation: 2390 },
	{ lat: 39, lon: -111.16, elevation: 1947 },
	{ lat: 39, lon: -110.23, elevation: 1282 },
	{ lat: 39, lon: -109.3, elevation: 1357 },
	{ lat: 39, lon: -108.37, elevation: 1518 },
	{ lat: 39, lon: -107.44, elevation: 2872 },
	{ lat: 39, lon: -106.51, elevation: 3682 },
	{ lat: 39, lon: -105.58, elevation: 2655 },
	{ lat: 39, lon: -104.65, elevation: 2284 },
	{ lat: 39, lon: -103.72, elevation: 1671 },
	{ lat: 39, lon: -102.79, elevation: 1420 },
	{ lat: 39, lon: -101.86, elevation: 1121 },
	{ lat: 39, lon: -100.93, elevation: 943 },
	{ lat: 39, lon: -100.0, elevation: 758 },
	{ lat: 39, lon: -99.07, elevation: 569 },
	{ lat: 39, lon: -98.14, elevation: 417 },
	{ lat: 39, lon: -97.21, elevation: 391 },
	{ lat: 39, lon: -96.28, elevation: 323 },
	{ lat: 39, lon: -95.35, elevation: 318 },
	{ lat: 39, lon: -94.42, elevation: 243 },
	{ lat: 39, lon: -93.49, elevation: 204 },
	{ lat: 39, lon: -92.56, elevation: 195 },
	{ lat: 39, lon: -91.63, elevation: 234 },
	{ lat: 39, lon: -90.7, elevation: 131 },
	{ lat: 39, lon: -89.77, elevation: 186 },
	{ lat: 39, lon: -88.84, elevation: 186 },
	{ lat: 39, lon: -87.91, elevation: 156 },
	{ lat: 39, lon: -86.98, elevation: 150 },
	{ lat: 39, lon: -86.05, elevation: 204 },
	{ lat: 39, lon: -85.12, elevation: 264 },
	{ lat: 39, lon: -84.19, elevation: 278 },
	{ lat: 39, lon: -83.26, elevation: 318 },
	{ lat: 39, lon: -82.33, elevation: 191 },
	{ lat: 39, lon: -81.4, elevation: 243 },
	{ lat: 39, lon: -80.47, elevation: 413 },
	{ lat: 39, lon: -79.53, elevation: 790 },
	{ lat: 39, lon: -78.6, elevation: 711 },
	{ lat: 39, lon: -77.67, elevation: 125 },
	{ lat: 39, lon: -76.74, elevation: 49 },
	{ lat: 39, lon: -75.81, elevation: 18 },
	{ lat: 39, lon: -74.88, elevation: 11 },
	{ lat: 39, lon: -8.84, elevation: 3 },
	{ lat: 39, lon: -7.91, elevation: 220 },
	{ lat: 39, lon: -6.98, elevation: 208 },
	{ lat: 39, lon: -6.05, elevation: 239 },
	{ lat: 39, lon: -5.12, elevation: 402 },
	{ lat: 39, lon: -4.19, elevation: 587 },
	{ lat: 39, lon: -3.26, elevation: 722 },
	{ lat: 39, lon: -2.33, elevation: 914 },
	{ lat: 39, lon: -1.4, elevation: 1047 },
	{ lat: 39, lon: -0.47, elevation: 114 },
	{ lat: 39, lon: 1.4, elevation: 117 },
	{ lat: 39, lon: 8.84, elevation: 392 },
	{ lat: 39, lon: 16.28, elevation: 852 },
	{ lat: 39, lon: 21.86, elevation: 1242 },
	{ lat: 39, lon: 22.79, elevation: 1021 },
	{ lat: 39, lon: 26.51, elevation: 127 },
	{ lat: 39, lon: 27.44, elevation: 758 },
	{ lat: 39, lon: 28.37, elevation: 619 },
	{ lat: 39, lon: 29.3, elevation: 1089 },
	{ lat: 39, lon: 30.23, elevation: 1059 },
	{ lat: 39, lon: 31.16, elevation: 977 },
	{ lat: 39, lon: 32.09, elevation: 972 },
	{ lat: 39, lon: 33.02, elevation: 1097 },
	{ lat: 39, lon: 33.95, elevation: 1004 },
	{ lat: 39, lon: 34.88, elevation: 1261 },
	{ lat: 39, lon: 35.81, elevation: 1128 },
	{ lat: 39, lon: 36.74, elevation: 1632 },
	{ lat: 39, lon: 37.67, elevation: 1681 },
	{ lat: 39, lon: 38.6, elevation: 831 },
	{ lat: 39, lon: 39.53, elevation: 1171 },
	{ lat: 39, lon: 40.47, elevation: 1918 },
	{ lat: 39, lon: 41.4, elevation: 1950 },
	{ lat: 39, lon: 42.33, elevation: 1586 },
	{ lat: 39, lon: 43.26, elevation: 1716 },
	{ lat: 39, lon: 44.19, elevation: 2509 },
	{ lat: 39, lon: 45.12, elevation: 1078 },
	{ lat: 39, lon: 46.05, elevation: 2540 },
	{ lat: 39, lon: 46.98, elevation: 824 },
	{ lat: 39, lon: 47.91, elevation: 953 },
	{ lat: 39, lon: 48.84, elevation: -28 },
	{ lat: 39, lon: 49.77, elevation: -29 },
	{ lat: 39, lon: 50.7, elevation: 0 },
	{ lat: 39, lon: 51.63, elevation: 0 },
	{ lat: 39, lon: 52.56, elevation: -29 },
	{ lat: 39, lon: 53.49, elevation: -29 },
	{ lat: 39, lon: 54.42, elevation: -2 },
	{ lat: 39, lon: 55.35, elevation: 391 },
	{ lat: 39, lon: 56.28, elevation: 85 },
	{ lat: 39, lon: 57.21, elevation: 41 },
	{ lat: 39, lon: 58.14, elevation: 80 },
	{ lat: 39, lon: 59.07, elevation: 96 },
	{ lat: 39, lon: 60.0, elevation: 119 },
	{ lat: 39, lon: 60.93, elevation: 143 },
	{ lat: 39, lon: 61.86, elevation: 162 },
	{ lat: 39, lon: 62.79, elevation: 182 },
	{ lat: 39, lon: 63.72, elevation: 191 },
	{ lat: 39, lon: 64.65, elevation: 299 },
	{ lat: 39, lon: 65.58, elevation: 393 },
	{ lat: 39, lon: 66.51, elevation: 512 },
	{ lat: 39, lon: 67.44, elevation: 3694 },
	{ lat: 39, lon: 68.37, elevation: 3274 },
	{ lat: 39, lon: 69.3, elevation: 2051 },
	{ lat: 39, lon: 70.23, elevation: 1273 },
	{ lat: 39, lon: 71.16, elevation: 3015 },
	{ lat: 39, lon: 72.09, elevation: 6099 },
	{ lat: 39, lon: 73.02, elevation: 5211 },
	{ lat: 39, lon: 73.95, elevation: 4269 },
	{ lat: 39, lon: 74.88, elevation: 4510 },
	{ lat: 39, lon: 75.81, elevation: 1690 },
	{ lat: 39, lon: 76.74, elevation: 1204 },
	{ lat: 39, lon: 77.67, elevation: 1173 },
	{ lat: 39, lon: 78.6, elevation: 1198 },
	{ lat: 39, lon: 79.53, elevation: 1157 },
	{ lat: 39, lon: 80.47, elevation: 1140 },
	{ lat: 39, lon: 81.4, elevation: 1132 },
	{ lat: 39, lon: 82.33, elevation: 1116 },
	{ lat: 39, lon: 83.26, elevation: 1136 },
	{ lat: 39, lon: 84.19, elevation: 1119 },
	{ lat: 39, lon: 85.12, elevation: 1109 },
	{ lat: 39, lon: 86.05, elevation: 1063 },
	{ lat: 39, lon: 86.98, elevation: 998 },
	{ lat: 39, lon: 87.91, elevation: 869 },
	{ lat: 39, lon: 88.84, elevation: 1344 },
	{ lat: 39, lon: 89.77, elevation: 2327 },
	{ lat: 39, lon: 90.7, elevation: 3238 },
	{ lat: 39, lon: 91.63, elevation: 3529 },
	{ lat: 39, lon: 92.56, elevation: 3131 },
	{ lat: 39, lon: 93.49, elevation: 3174 },
	{ lat: 39, lon: 94.42, elevation: 2897 },
	{ lat: 39, lon: 95.35, elevation: 3762 },
	{ lat: 39, lon: 96.28, elevation: 3598 },
	{ lat: 39, lon: 97.21, elevation: 3393 },
	{ lat: 39, lon: 98.14, elevation: 3478 },
	{ lat: 39, lon: 99.07, elevation: 3860 },
	{ lat: 39, lon: 100.0, elevation: 1872 },
	{ lat: 39, lon: 100.93, elevation: 2682 },
	{ lat: 39, lon: 101.86, elevation: 1376 },
	{ lat: 39, lon: 102.79, elevation: 1470 },
	{ lat: 39, lon: 103.72, elevation: 1311 },
	{ lat: 39, lon: 104.65, elevation: 1342 },
	{ lat: 39, lon: 105.58, elevation: 1403 },
	{ lat: 39, lon: 106.51, elevation: 1096 },
	{ lat: 39, lon: 107.44, elevation: 1176 },
	{ lat: 39, lon: 108.37, elevation: 1421 },
	{ lat: 39, lon: 109.3, elevation: 1309 },
	{ lat: 39, lon: 110.23, elevation: 1253 },
	{ lat: 39, lon: 111.16, elevation: 1055 },
	{ lat: 39, lon: 112.09, elevation: 1817 },
	{ lat: 39, lon: 113.02, elevation: 1522 },
	{ lat: 39, lon: 113.95, elevation: 1378 },
	{ lat: 39, lon: 114.88, elevation: 257 },
	{ lat: 39, lon: 115.81, elevation: 14 },
	{ lat: 39, lon: 116.74, elevation: 4 },
	{ lat: 39, lon: 117.67, elevation: 5 },
	{ lat: 39, lon: 121.4, elevation: 12 },
	{ lat: 39, lon: 126.05, elevation: 24 },
	{ lat: 39, lon: 126.98, elevation: 570 },
	{ lat: 39, lon: 140.0, elevation: 254 },
	{ lat: 39, lon: 140.93, elevation: 347 },
	{ lat: 40, lon: -123.46, elevation: 545 },
	{ lat: 40, lon: -122.51, elevation: 286 },
	{ lat: 40, lon: -121.57, elevation: 1150 },
	{ lat: 40, lon: -120.63, elevation: 2337 },
	{ lat: 40, lon: -119.69, elevation: 1643 },
	{ lat: 40, lon: -118.74, elevation: 1575 },
	{ lat: 40, lon: -117.8, elevation: 1059 },
	{ lat: 40, lon: -116.86, elevation: 1890 },
	{ lat: 40, lon: -115.92, elevation: 1765 },
	{ lat: 40, lon: -114.97, elevation: 2032 },
	{ lat: 40, lon: -114.03, elevation: 1664 },
	{ lat: 40, lon: -113.09, elevation: 1350 },
	{ lat: 40, lon: -112.15, elevation: 2312 },
	{ lat: 40, lon: -111.2, elevation: 2238 },
	{ lat: 40, lon: -110.26, elevation: 2082 },
	{ lat: 40, lon: -109.32, elevation: 1596 },
	{ lat: 40, lon: -108.38, elevation: 1968 },
	{ lat: 40, lon: -107.43, elevation: 2552 },
	{ lat: 40, lon: -106.49, elevation: 2141 },
	{ lat: 40, lon: -105.55, elevation: 2944 },
	{ lat: 40, lon: -104.61, elevation: 1541 },
	{ lat: 40, lon: -103.66, elevation: 1374 },
	{ lat: 40, lon: -102.72, elevation: 1271 },
	{ lat: 40, lon: -101.78, elevation: 988 },
	{ lat: 40, lon: -100.84, elevation: 904 },
	{ lat: 40, lon: -99.9, elevation: 704 },
	{ lat: 40, lon: -98.95, elevation: 628 },
	{ lat: 40, lon: -98.01, elevation: 470 },
	{ lat: 40, lon: -97.07, elevation: 451 },
	{ lat: 40, lon: -96.13, elevation: 359 },
	{ lat: 40, lon: -95.18, elevation: 310 },
	{ lat: 40, lon: -94.24, elevation: 273 },
	{ lat: 40, lon: -93.3, elevation: 270 },
	{ lat: 40, lon: -92.36, elevation: 258 },
	{ lat: 40, lon: -91.41, elevation: 144 },
	{ lat: 40, lon: -90.47, elevation: 133 },
	{ lat: 40, lon: -89.53, elevation: 182 },
	{ lat: 40, lon: -88.59, elevation: 210 },
	{ lat: 40, lon: -87.64, elevation: 204 },
	{ lat: 40, lon: -86.7, elevation: 275 },
	{ lat: 40, lon: -85.76, elevation: 266 },
	{ lat: 40, lon: -84.82, elevation: 362 },
	{ lat: 40, lon: -83.87, elevation: 346 },
	{ lat: 40, lon: -82.93, elevation: 247 },
	{ lat: 40, lon: -81.99, elevation: 226 },
	{ lat: 40, lon: -81.05, elevation: 342 },
	{ lat: 40, lon: -80.1, elevation: 362 },
	{ lat: 40, lon: -79.16, elevation: 646 },
	{ lat: 40, lon: -78.22, elevation: 467 },
	{ lat: 40, lon: -77.28, elevation: 260 },
	{ lat: 40, lon: -76.34, elevation: 91 },
	{ lat: 40, lon: -75.39, elevation: 144 },
	{ lat: 40, lon: -74.45, elevation: 50 },
	{ lat: 40, lon: -8.48, elevation: 301 },
	{ lat: 40, lon: -7.54, elevation: 440 },
	{ lat: 40, lon: -6.6, elevation: 269 },
	{ lat: 40, lon: -5.65, elevation: 245 },
	{ lat: 40, lon: -4.71, elevation: 422 },
	{ lat: 40, lon: -3.77, elevation: 603 },
	{ lat: 40, lon: -2.83, elevation: 898 },
	{ lat: 40, lon: -1.88, elevation: 1056 },
	{ lat: 40, lon: -0.94, elevation: 1482 },
	{ lat: 40, lon: 0.0, elevation: 0 },
	{ lat: 40, lon: 8.48, elevation: 10 },
	{ lat: 40, lon: 9.42, elevation: 912 },
	{ lat: 40, lon: 16.02, elevation: 561 },
	{ lat: 40, lon: 20.73, elevation: 1704 },
	{ lat: 40, lon: 21.68, elevation: 633 },
	{ lat: 40, lon: 26.39, elevation: 193 },
	{ lat: 40, lon: 27.33, elevation: 661 },
	{ lat: 40, lon: 28.27, elevation: 145 },
	{ lat: 40, lon: 29.21, elevation: 1073 },
	{ lat: 40, lon: 30.16, elevation: 834 },
	{ lat: 40, lon: 31.1, elevation: 1259 },
	{ lat: 40, lon: 32.04, elevation: 1042 },
	{ lat: 40, lon: 32.98, elevation: 1224 },
	{ lat: 40, lon: 33.93, elevation: 1197 },
	{ lat: 40, lon: 34.87, elevation: 1241 },
	{ lat: 40, lon: 35.81, elevation: 1159 },
	{ lat: 40, lon: 36.75, elevation: 1609 },
	{ lat: 40, lon: 37.7, elevation: 1721 },
	{ lat: 40, lon: 38.64, elevation: 1534 },
	{ lat: 40, lon: 39.58, elevation: 1880 },
	{ lat: 40, lon: 40.52, elevation: 2038 },
	{ lat: 40, lon: 41.47, elevation: 1825 },
	{ lat: 40, lon: 42.41, elevation: 2101 },
	{ lat: 40, lon: 43.35, elevation: 2061 },
	{ lat: 40, lon: 44.29, elevation: 832 },
	{ lat: 40, lon: 45.24, elevation: 2308 },
	{ lat: 40, lon: 46.18, elevation: 2066 },
	{ lat: 40, lon: 47.12, elevation: 179 },
	{ lat: 40, lon: 48.06, elevation: -12 },
	{ lat: 40, lon: 49.01, elevation: 145 },
	{ lat: 40, lon: 49.95, elevation: -29 },
	{ lat: 40, lon: 50.89, elevation: -29 },
	{ lat: 40, lon: 51.83, elevation: 0 },
	{ lat: 40, lon: 52.77, elevation: -29 },
	{ lat: 40, lon: 53.72, elevation: 6 },
	{ lat: 40, lon: 54.66, elevation: 149 },
	{ lat: 40, lon: 55.6, elevation: 11 },
	{ lat: 40, lon: 56.54, elevation: 70 },
	{ lat: 40, lon: 57.49, elevation: 59 },
	{ lat: 40, lon: 58.43, elevation: 81 },
	{ lat: 40, lon: 59.37, elevation: 152 },
	{ lat: 40, lon: 60.31, elevation: 167 },
	{ lat: 40, lon: 61.26, elevation: 177 },
	{ lat: 40, lon: 62.2, elevation: 177 },
	{ lat: 40, lon: 63.14, elevation: 182 },
	{ lat: 40, lon: 64.08, elevation: 209 },
	{ lat: 40, lon: 65.03, elevation: 322 },
	{ lat: 40, lon: 65.97, elevation: 417 },
	{ lat: 40, lon: 66.91, elevation: 627 },
	{ lat: 40, lon: 67.85, elevation: 616 },
	{ lat: 40, lon: 68.8, elevation: 723 },
	{ lat: 40, lon: 69.74, elevation: 1154 },
	{ lat: 40, lon: 70.68, elevation: 1130 },
	{ lat: 40, lon: 71.62, elevation: 1808 },
	{ lat: 40, lon: 72.57, elevation: 3925 },
	{ lat: 40, lon: 73.51, elevation: 2558 },
	{ lat: 40, lon: 74.45, elevation: 3215 },
	{ lat: 40, lon: 75.39, elevation: 3282 },
	{ lat: 40, lon: 76.34, elevation: 2293 },
	{ lat: 40, lon: 77.28, elevation: 1779 },
	{ lat: 40, lon: 78.22, elevation: 1609 },
	{ lat: 40, lon: 79.16, elevation: 1093 },
	{ lat: 40, lon: 80.1, elevation: 1063 },
	{ lat: 40, lon: 81.05, elevation: 1055 },
	{ lat: 40, lon: 81.99, elevation: 1036 },
	{ lat: 40, lon: 82.93, elevation: 1004 },
	{ lat: 40, lon: 83.87, elevation: 994 },
	{ lat: 40, lon: 84.82, elevation: 946 },
	{ lat: 40, lon: 85.76, elevation: 1009 },
	{ lat: 40, lon: 86.7, elevation: 868 },
	{ lat: 40, lon: 87.64, elevation: 858 },
	{ lat: 40, lon: 88.59, elevation: 823 },
	{ lat: 40, lon: 89.53, elevation: 795 },
	{ lat: 40, lon: 90.47, elevation: 791 },
	{ lat: 40, lon: 91.41, elevation: 904 },
	{ lat: 40, lon: 92.36, elevation: 1093 },
	{ lat: 40, lon: 93.3, elevation: 1120 },
	{ lat: 40, lon: 94.24, elevation: 1344 },
	{ lat: 40, lon: 95.18, elevation: 1644 },
	{ lat: 40, lon: 96.13, elevation: 1884 },
	{ lat: 40, lon: 97.07, elevation: 2040 },
	{ lat: 40, lon: 98.01, elevation: 1947 },
	{ lat: 40, lon: 98.95, elevation: 1259 },
	{ lat: 40, lon: 99.9, elevation: 1559 },
	{ lat: 40, lon: 100.84, elevation: 1330 },
	{ lat: 40, lon: 101.78, elevation: 1301 },
	{ lat: 40, lon: 102.72, elevation: 1497 },
	{ lat: 40, lon: 103.66, elevation: 1728 },
	{ lat: 40, lon: 104.61, elevation: 1614 },
	{ lat: 40, lon: 105.55, elevation: 1137 },
	{ lat: 40, lon: 106.49, elevation: 1115 },
	{ lat: 40, lon: 107.43, elevation: 1336 },
	{ lat: 40, lon: 108.38, elevation: 1199 },
	{ lat: 40, lon: 109.32, elevation: 1401 },
	{ lat: 40, lon: 110.26, elevation: 1266 },
	{ lat: 40, lon: 111.2, elevation: 1291 },
	{ lat: 40, lon: 112.15, elevation: 1572 },
	{ lat: 40, lon: 113.09, elevation: 1349 },
	{ lat: 40, lon: 114.03, elevation: 871 },
	{ lat: 40, lon: 114.97, elevation: 1029 },
	{ lat: 40, lon: 115.92, elevation: 676 },
	{ lat: 40, lon: 116.86, elevation: 26 },
	{ lat: 40, lon: 117.8, elevation: 36 },
	{ lat: 40, lon: 118.74, elevation: 47 },
	{ lat: 40, lon: 119.69, elevation: 20 },
	{ lat: 40, lon: 122.51, elevation: 74 },
	{ lat: 40, lon: 123.46, elevation: 99 },
	{ lat: 40, lon: 124.4, elevation: 4 },
	{ lat: 40, lon: 125.34, elevation: 86 },
	{ lat: 40, lon: 126.28, elevation: 535 },
	{ lat: 40, lon: 127.23, elevation: 430 },
	{ lat: 40, lon: 128.17, elevation: 0 },
	{ lat: 40, lon: 140.42, elevation: 370 },
	{ lat: 40, lon: 141.36, elevation: 682 },
	{ lat: 41, lon: -123.51, elevation: 483 },
	{ lat: 41, lon: -122.55, elevation: 1431 },
	{ lat: 41, lon: -121.6, elevation: 912 },
	{ lat: 41, lon: -120.64, elevation: 1646 },
	{ lat: 41, lon: -119.68, elevation: 1569 },
	{ lat: 41, lon: -118.72, elevation: 1228 },
	{ lat: 41, lon: -117.77, elevation: 1741 },
	{ lat: 41, lon: -116.81, elevation: 1596 },
	{ lat: 41, lon: -115.85, elevation: 1908 },
	{ lat: 41, lon: -114.89, elevation: 1951 },
	{ lat: 41, lon: -113.94, elevation: 1297 },
	{ lat: 41, lon: -112.98, elevation: 1351 },
	{ lat: 41, lon: -112.02, elevation: 1282 },
	{ lat: 41, lon: -111.06, elevation: 2158 },
	{ lat: 41, lon: -110.11, elevation: 2441 },
	{ lat: 41, lon: -109.15, elevation: 2421 },
	{ lat: 41, lon: -108.19, elevation: 2025 },
	{ lat: 41, lon: -107.23, elevation: 2047 },
	{ lat: 41, lon: -106.28, elevation: 2700 },
	{ lat: 41, lon: -105.32, elevation: 2259 },
	{ lat: 41, lon: -104.36, elevation: 1636 },
	{ lat: 41, lon: -103.4, elevation: 1441 },
	{ lat: 41, lon: -102.45, elevation: 1140 },
	{ lat: 41, lon: -101.49, elevation: 1029 },
	{ lat: 41, lon: -100.53, elevation: 869 },
	{ lat: 41, lon: -99.57, elevation: 769 },
	{ lat: 41, lon: -98.62, elevation: 594 },
	{ lat: 41, lon: -97.66, elevation: 516 },
	{ lat: 41, lon: -96.7, elevation: 402 },
	{ lat: 41, lon: -95.74, elevation: 296 },
	{ lat: 41, lon: -94.79, elevation: 369 },
	{ lat: 41, lon: -93.83, elevation: 342 },
	{ lat: 41, lon: -92.87, elevation: 288 },
	{ lat: 41, lon: -91.91, elevation: 236 },
	{ lat: 41, lon: -90.96, elevation: 164 },
	{ lat: 41, lon: -90.0, elevation: 217 },
	{ lat: 41, lon: -89.04, elevation: 215 },
	{ lat: 41, lon: -88.09, elevation: 216 },
	{ lat: 41, lon: -87.13, elevation: 209 },
	{ lat: 41, lon: -86.17, elevation: 252 },
	{ lat: 41, lon: -85.21, elevation: 241 },
	{ lat: 41, lon: -84.26, elevation: 218 },
	{ lat: 41, lon: -83.3, elevation: 244 },
	{ lat: 41, lon: -82.34, elevation: 350 },
	{ lat: 41, lon: -81.38, elevation: 366 },
	{ lat: 41, lon: -80.43, elevation: 237 },
	{ lat: 41, lon: -79.47, elevation: 415 },
	{ lat: 41, lon: -78.51, elevation: 456 },
	{ lat: 41, lon: -77.55, elevation: 267 },
	{ lat: 41, lon: -76.6, elevation: 180 },
	{ lat: 41, lon: -75.64, elevation: 498 },
	{ lat: 41, lon: -74.68, elevation: 316 },
	{ lat: 41, lon: -73.72, elevation: 36 },
	{ lat: 41, lon: -8.62, elevation: 61 },
	{ lat: 41, lon: -7.66, elevation: 756 },
	{ lat: 41, lon: -6.7, elevation: 569 },
	{ lat: 41, lon: -5.74, elevation: 774 },
	{ lat: 41, lon: -4.79, elevation: 863 },
	{ lat: 41, lon: -3.83, elevation: 1750 },
	{ lat: 41, lon: -2.87, elevation: 941 },
	{ lat: 41, lon: -1.91, elevation: 1177 },
	{ lat: 41, lon: -0.96, elevation: 970 },
	{ lat: 41, lon: 0.0, elevation: 536 },
	{ lat: 41, lon: 9.57, elevation: 7 },
	{ lat: 41, lon: 14.36, elevation: 20 },
	{ lat: 41, lon: 15.32, elevation: 928 },
	{ lat: 41, lon: 16.28, elevation: 630 },
	{ lat: 41, lon: 20.11, elevation: 247 },
	{ lat: 41, lon: 21.06, elevation: 925 },
	{ lat: 41, lon: 22.02, elevation: 169 },
	{ lat: 41, lon: 22.98, elevation: 338 },
	{ lat: 41, lon: 23.94, elevation: 60 },
	{ lat: 41, lon: 24.89, elevation: 30 },
	{ lat: 41, lon: 25.85, elevation: 516 },
	{ lat: 41, lon: 26.81, elevation: 227 },
	{ lat: 41, lon: 28.72, elevation: 50 },
	{ lat: 41, lon: 29.68, elevation: 186 },
	{ lat: 41, lon: 30.64, elevation: 63 },
	{ lat: 41, lon: 31.6, elevation: 711 },
	{ lat: 41, lon: 32.55, elevation: 660 },
	{ lat: 41, lon: 33.51, elevation: 1865 },
	{ lat: 41, lon: 34.47, elevation: 1210 },
	{ lat: 41, lon: 35.43, elevation: 1423 },
	{ lat: 41, lon: 36.38, elevation: 840 },
	{ lat: 41, lon: 37.34, elevation: 456 },
	{ lat: 41, lon: 39.26, elevation: 530 },
	{ lat: 41, lon: 41.17, elevation: 2617 },
	{ lat: 41, lon: 42.13, elevation: 2398 },
	{ lat: 41, lon: 43.09, elevation: 2594 },
	{ lat: 41, lon: 44.04, elevation: 2503 },
	{ lat: 41, lon: 45.0, elevation: 1391 },
	{ lat: 41, lon: 45.96, elevation: 145 },
	{ lat: 41, lon: 46.91, elevation: 111 },
	{ lat: 41, lon: 47.87, elevation: 917 },
	{ lat: 41, lon: 48.83, elevation: 817 },
	{ lat: 41, lon: 49.79, elevation: -29 },
	{ lat: 41, lon: 50.74, elevation: 0 },
	{ lat: 41, lon: 51.7, elevation: 0 },
	{ lat: 41, lon: 52.66, elevation: -29 },
	{ lat: 41, lon: 53.62, elevation: -30 },
	{ lat: 41, lon: 54.57, elevation: -30 },
	{ lat: 41, lon: 55.53, elevation: 216 },
	{ lat: 41, lon: 56.49, elevation: 53 },
	{ lat: 41, lon: 57.45, elevation: 60 },
	{ lat: 41, lon: 58.4, elevation: 78 },
	{ lat: 41, lon: 59.36, elevation: 115 },
	{ lat: 41, lon: 60.32, elevation: 111 },
	{ lat: 41, lon: 61.28, elevation: 128 },
	{ lat: 41, lon: 62.23, elevation: 153 },
	{ lat: 41, lon: 63.19, elevation: 154 },
	{ lat: 41, lon: 64.15, elevation: 216 },
	{ lat: 41, lon: 65.11, elevation: 160 },
	{ lat: 41, lon: 66.06, elevation: 241 },
	{ lat: 41, lon: 67.02, elevation: 249 },
	{ lat: 41, lon: 67.98, elevation: 245 },
	{ lat: 41, lon: 68.94, elevation: 309 },
	{ lat: 41, lon: 69.89, elevation: 1463 },
	{ lat: 41, lon: 70.85, elevation: 1198 },
	{ lat: 41, lon: 71.81, elevation: 424 },
	{ lat: 41, lon: 72.77, elevation: 703 },
	{ lat: 41, lon: 73.72, elevation: 1799 },
	{ lat: 41, lon: 74.68, elevation: 3604 },
	{ lat: 41, lon: 75.64, elevation: 3451 },
	{ lat: 41, lon: 76.6, elevation: 3110 },
	{ lat: 41, lon: 77.55, elevation: 5129 },
	{ lat: 41, lon: 78.51, elevation: 2047 },
	{ lat: 41, lon: 79.47, elevation: 1967 },
	{ lat: 41, lon: 80.43, elevation: 1080 },
	{ lat: 41, lon: 81.38, elevation: 1010 },
	{ lat: 41, lon: 82.34, elevation: 978 },
	{ lat: 41, lon: 83.3, elevation: 955 },
	{ lat: 41, lon: 84.26, elevation: 933 },
	{ lat: 41, lon: 85.21, elevation: 913 },
	{ lat: 41, lon: 86.17, elevation: 892 },
	{ lat: 41, lon: 87.13, elevation: 866 },
	{ lat: 41, lon: 88.09, elevation: 1010 },
	{ lat: 41, lon: 89.04, elevation: 1087 },
	{ lat: 41, lon: 90.0, elevation: 1171 },
	{ lat: 41, lon: 90.96, elevation: 790 },
	{ lat: 41, lon: 91.91, elevation: 1075 },
	{ lat: 41, lon: 92.87, elevation: 1580 },
	{ lat: 41, lon: 93.83, elevation: 1324 },
	{ lat: 41, lon: 94.79, elevation: 1847 },
	{ lat: 41, lon: 95.74, elevation: 1672 },
	{ lat: 41, lon: 96.7, elevation: 1820 },
	{ lat: 41, lon: 97.66, elevation: 1470 },
	{ lat: 41, lon: 98.62, elevation: 1401 },
	{ lat: 41, lon: 99.57, elevation: 1246 },
	{ lat: 41, lon: 100.53, elevation: 1055 },
	{ lat: 41, lon: 101.49, elevation: 1001 },
	{ lat: 41, lon: 102.45, elevation: 1255 },
	{ lat: 41, lon: 103.4, elevation: 1389 },
	{ lat: 41, lon: 104.36, elevation: 1290 },
	{ lat: 41, lon: 105.32, elevation: 1286 },
	{ lat: 41, lon: 106.28, elevation: 1706 },
	{ lat: 41, lon: 107.23, elevation: 1034 },
	{ lat: 41, lon: 108.19, elevation: 1028 },
	{ lat: 41, lon: 109.15, elevation: 1069 },
	{ lat: 41, lon: 110.11, elevation: 1395 },
	{ lat: 41, lon: 111.06, elevation: 1926 },
	{ lat: 41, lon: 112.02, elevation: 1860 },
	{ lat: 41, lon: 112.98, elevation: 1535 },
	{ lat: 41, lon: 113.94, elevation: 1441 },
	{ lat: 41, lon: 114.89, elevation: 1105 },
	{ lat: 41, lon: 115.85, elevation: 1118 },
	{ lat: 41, lon: 116.81, elevation: 704 },
	{ lat: 41, lon: 117.77, elevation: 391 },
	{ lat: 41, lon: 118.72, elevation: 674 },
	{ lat: 41, lon: 119.68, elevation: 358 },
	{ lat: 41, lon: 120.64, elevation: 186 },
	{ lat: 41, lon: 121.6, elevation: 6 },
	{ lat: 41, lon: 122.55, elevation: 2 },
	{ lat: 41, lon: 123.51, elevation: 682 },
	{ lat: 41, lon: 124.47, elevation: 324 },
	{ lat: 41, lon: 125.43, elevation: 321 },
	{ lat: 41, lon: 126.38, elevation: 714 },
	{ lat: 41, lon: 127.34, elevation: 1220 },
	{ lat: 41, lon: 128.3, elevation: 1198 },
	{ lat: 41, lon: 129.26, elevation: 228 },
	{ lat: 42, lon: -123.57, elevation: 1216 },
	{ lat: 42, lon: -122.59, elevation: 1111 },
	{ lat: 42, lon: -121.62, elevation: 1248 },
	{ lat: 42, lon: -120.65, elevation: 1571 },
	{ lat: 42, lon: -119.68, elevation: 1834 },
	{ lat: 42, lon: -118.7, elevation: 1767 },
	{ lat: 42, lon: -117.73, elevation: 1349 },
	{ lat: 42, lon: -116.76, elevation: 1587 },
	{ lat: 42, lon: -115.78, elevation: 1919 },
	{ lat: 42, lon: -114.81, elevation: 1742 },
	{ lat: 42, lon: -113.84, elevation: 2139 },
	{ lat: 42, lon: -112.86, elevation: 1361 },
	{ lat: 42, lon: -111.89, elevation: 1374 },
	{ lat: 42, lon: -110.92, elevation: 1964 },
	{ lat: 42, lon: -109.95, elevation: 1952 },
	{ lat: 42, lon: -108.97, elevation: 2394 },
	{ lat: 42, lon: -108.0, elevation: 1983 },
	{ lat: 42, lon: -107.03, elevation: 2031 },
	{ lat: 42, lon: -106.05, elevation: 2054 },
	{ lat: 42, lon: -105.08, elevation: 1494 },
	{ lat: 42, lon: -104.11, elevation: 1246 },
	{ lat: 42, lon: -103.14, elevation: 1295 },
	{ lat: 42, lon: -102.16, elevation: 1199 },
	{ lat: 42, lon: -101.19, elevation: 1061 },
	{ lat: 42, lon: -100.22, elevation: 841 },
	{ lat: 42, lon: -99.24, elevation: 722 },
	{ lat: 42, lon: -98.27, elevation: 622 },
	{ lat: 42, lon: -97.3, elevation: 525 },
	{ lat: 42, lon: -96.32, elevation: 374 },
	{ lat: 42, lon: -95.35, elevation: 386 },
	{ lat: 42, lon: -94.38, elevation: 320 },
	{ lat: 42, lon: -93.41, elevation: 304 },
	{ lat: 42, lon: -92.43, elevation: 301 },
	{ lat: 42, lon: -91.46, elevation: 271 },
	{ lat: 42, lon: -90.49, elevation: 243 },
	{ lat: 42, lon: -89.51, elevation: 264 },
	{ lat: 42, lon: -88.54, elevation: 267 },
	{ lat: 42, lon: -87.57, elevation: 175 },
	{ lat: 42, lon: -86.59, elevation: 175 },
	{ lat: 42, lon: -85.62, elevation: 251 },
	{ lat: 42, lon: -84.65, elevation: 336 },
	{ lat: 42, lon: -83.68, elevation: 206 },
	{ lat: 42, lon: -82.7, elevation: 172 },
	{ lat: 42, lon: -81.73, elevation: 172 },
	{ lat: 42, lon: -80.76, elevation: 172 },
	{ lat: 42, lon: -79.78, elevation: 422 },
	{ lat: 42, lon: -78.81, elevation: 555 },
	{ lat: 42, lon: -77.84, elevation: 626 },
	{ lat: 42, lon: -76.86, elevation: 425 },
	{ lat: 42, lon: -75.89, elevation: 440 },
	{ lat: 42, lon: -74.92, elevation: 732 },
	{ lat: 42, lon: -73.95, elevation: 38 },
	{ lat: 42, lon: -72.97, elevation: 373 },
	{ lat: 42, lon: -72.0, elevation: 172 },
	{ lat: 42, lon: -71.03, elevation: 18 },
	{ lat: 42, lon: -70.05, elevation: 27 },
	{ lat: 42, lon: -8.76, elevation: 37 },
	{ lat: 42, lon: -7.78, elevation: 827 },
	{ lat: 42, lon: -6.81, elevation: 1479 },
	{ lat: 42, lon: -5.84, elevation: 709 },
	{ lat: 42, lon: -4.86, elevation: 753 },
	{ lat: 42, lon: -3.89, elevation: 863 },
	{ lat: 42, lon: -2.92, elevation: 1642 },
	{ lat: 42, lon: -1.95, elevation: 669 },
	{ lat: 42, lon: -0.97, elevation: 655 },
	{ lat: 42, lon: 0.0, elevation: 364 },
	{ lat: 42, lon: 0.97, elevation: 924 },
	{ lat: 42, lon: 1.95, elevation: 541 },
	{ lat: 42, lon: 2.92, elevation: 205 },
	{ lat: 42, lon: 8.76, elevation: 232 },
	{ lat: 42, lon: 12.65, elevation: 144 },
	{ lat: 42, lon: 13.62, elevation: 665 },
	{ lat: 42, lon: 14.59, elevation: 459 },
	{ lat: 42, lon: 19.46, elevation: 6 },
	{ lat: 42, lon: 20.43, elevation: 870 },
	{ lat: 42, lon: 21.41, elevation: 254 },
	{ lat: 42, lon: 22.38, elevation: 893 },
	{ lat: 42, lon: 23.35, elevation: 2230 },
	{ lat: 42, lon: 24.32, elevation: 977 },
	{ lat: 42, lon: 25.3, elevation: 248 },
	{ lat: 42, lon: 26.27, elevation: 722 },
	{ lat: 42, lon: 27.24, elevation: 433 },
	{ lat: 42, lon: 35.03, elevation: 27 },
	{ lat: 42, lon: 41.84, elevation: 91 },
	{ lat: 42, lon: 42.81, elevation: 460 },
	{ lat: 42, lon: 43.78, elevation: 675 },
	{ lat: 42, lon: 44.76, elevation: 590 },
	{ lat: 42, lon: 45.73, elevation: 525 },
	{ lat: 42, lon: 46.7, elevation: 2885 },
	{ lat: 42, lon: 47.68, elevation: 1322 },
	{ lat: 42, lon: 48.65, elevation: -29 },
	{ lat: 42, lon: 49.62, elevation: 0 },
	{ lat: 42, lon: 50.59, elevation: 0 },
	{ lat: 42, lon: 51.57, elevation: -29 },
	{ lat: 42, lon: 52.54, elevation: 85 },
	{ lat: 42, lon: 53.51, elevation: -30 },
	{ lat: 42, lon: 54.49, elevation: 209 },
	{ lat: 42, lon: 55.46, elevation: 174 },
	{ lat: 42, lon: 56.43, elevation: 91 },
	{ lat: 42, lon: 57.41, elevation: 2 },
	{ lat: 42, lon: 58.38, elevation: 62 },
	{ lat: 42, lon: 59.35, elevation: 72 },
	{ lat: 42, lon: 60.32, elevation: 92 },
	{ lat: 42, lon: 61.3, elevation: 93 },
	{ lat: 42, lon: 62.27, elevation: 121 },
	{ lat: 42, lon: 63.24, elevation: 58 },
	{ lat: 42, lon: 64.22, elevation: 184 },
	{ lat: 42, lon: 65.19, elevation: 293 },
	{ lat: 42, lon: 66.16, elevation: 206 },
	{ lat: 42, lon: 67.14, elevation: 242 },
	{ lat: 42, lon: 68.11, elevation: 215 },
	{ lat: 42, lon: 69.08, elevation: 463 },
	{ lat: 42, lon: 70.05, elevation: 2189 },
	{ lat: 42, lon: 71.03, elevation: 3224 },
	{ lat: 42, lon: 72.0, elevation: 3234 },
	{ lat: 42, lon: 72.97, elevation: 1834 },
	{ lat: 42, lon: 73.95, elevation: 3102 },
	{ lat: 42, lon: 74.92, elevation: 2189 },
	{ lat: 42, lon: 75.89, elevation: 3618 },
	{ lat: 42, lon: 76.86, elevation: 3264 },
	{ lat: 42, lon: 77.84, elevation: 3541 },
	{ lat: 42, lon: 78.81, elevation: 3824 },
	{ lat: 42, lon: 79.78, elevation: 3958 },
	{ lat: 42, lon: 80.76, elevation: 4081 },
	{ lat: 42, lon: 81.73, elevation: 2118 },
	{ lat: 42, lon: 82.7, elevation: 1395 },
	{ lat: 42, lon: 83.68, elevation: 1729 },
	{ lat: 42, lon: 84.65, elevation: 1043 },
	{ lat: 42, lon: 85.62, elevation: 2011 },
	{ lat: 42, lon: 86.59, elevation: 1055 },
	{ lat: 42, lon: 87.57, elevation: 1277 },
	{ lat: 42, lon: 88.54, elevation: 873 },
	{ lat: 42, lon: 89.51, elevation: 1076 },
	{ lat: 42, lon: 90.49, elevation: 1179 },
	{ lat: 42, lon: 91.46, elevation: 1042 },
	{ lat: 42, lon: 92.43, elevation: 851 },
	{ lat: 42, lon: 93.41, elevation: 835 },
	{ lat: 42, lon: 94.38, elevation: 1055 },
	{ lat: 42, lon: 95.35, elevation: 1725 },
	{ lat: 42, lon: 96.32, elevation: 2104 },
	{ lat: 42, lon: 97.3, elevation: 1646 },
	{ lat: 42, lon: 98.27, elevation: 1542 },
	{ lat: 42, lon: 99.24, elevation: 1266 },
	{ lat: 42, lon: 100.22, elevation: 958 },
	{ lat: 42, lon: 101.19, elevation: 929 },
	{ lat: 42, lon: 102.16, elevation: 1013 },
	{ lat: 42, lon: 103.14, elevation: 1261 },
	{ lat: 42, lon: 104.11, elevation: 1190 },
	{ lat: 42, lon: 105.08, elevation: 1175 },
	{ lat: 42, lon: 106.05, elevation: 965 },
	{ lat: 42, lon: 107.03, elevation: 1132 },
	{ lat: 42, lon: 108.0, elevation: 1303 },
	{ lat: 42, lon: 108.97, elevation: 1469 },
	{ lat: 42, lon: 109.95, elevation: 1452 },
	{ lat: 42, lon: 110.92, elevation: 1281 },
	{ lat: 42, lon: 111.89, elevation: 1453 },
	{ lat: 42, lon: 112.86, elevation: 1414 },
	{ lat: 42, lon: 113.84, elevation: 1582 },
	{ lat: 42, lon: 114.81, elevation: 1661 },
	{ lat: 42, lon: 115.78, elevation: 1390 },
	{ lat: 42, lon: 116.76, elevation: 1293 },
	{ lat: 42, lon: 117.73, elevation: 1144 },
	{ lat: 42, lon: 118.7, elevation: 1236 },
	{ lat: 42, lon: 119.68, elevation: 731 },
	{ lat: 42, lon: 120.65, elevation: 324 },
	{ lat: 42, lon: 121.62, elevation: 137 },
	{ lat: 42, lon: 122.59, elevation: 40 },
	{ lat: 42, lon: 123.57, elevation: 76 },
	{ lat: 42, lon: 124.54, elevation: 283 },
	{ lat: 42, lon: 125.51, elevation: 521 },
	{ lat: 42, lon: 126.49, elevation: 523 },
	{ lat: 42, lon: 127.46, elevation: 889 },
	{ lat: 42, lon: 128.43, elevation: 1282 },
	{ lat: 42, lon: 129.41, elevation: 1260 },
	{ lat: 43, lon: -123.63, elevation: 496 },
	{ lat: 43, lon: -122.64, elevation: 691 },
	{ lat: 43, lon: -121.65, elevation: 1374 },
	{ lat: 43, lon: -120.66, elevation: 1293 },
	{ lat: 43, lon: -119.67, elevation: 1646 },
	{ lat: 43, lon: -118.68, elevation: 1281 },
	{ lat: 43, lon: -117.69, elevation: 1128 },
	{ lat: 43, lon: -116.7, elevation: 2335 },
	{ lat: 43, lon: -115.71, elevation: 924 },
	{ lat: 43, lon: -114.73, elevation: 1095 },
	{ lat: 43, lon: -113.74, elevation: 1330 },
	{ lat: 43, lon: -112.75, elevation: 1324 },
	{ lat: 43, lon: -111.76, elevation: 1902 },
	{ lat: 43, lon: -110.77, elevation: 2133 },
	{ lat: 43, lon: -109.78, elevation: 2519 },
	{ lat: 43, lon: -108.79, elevation: 1702 },
	{ lat: 43, lon: -107.8, elevation: 1693 },
	{ lat: 43, lon: -106.81, elevation: 1733 },
	{ lat: 43, lon: -105.82, elevation: 1691 },
	{ lat: 43, lon: -104.84, elevation: 1482 },
	{ lat: 43, lon: -103.85, elevation: 1122 },
	{ lat: 43, lon: -102.86, elevation: 964 },
	{ lat: 43, lon: -101.87, elevation: 1047 },
	{ lat: 43, lon: -100.88, elevation: 871 },
	{ lat: 43, lon: -99.89, elevation: 710 },
	{ lat: 43, lon: -98.9, elevation: 587 },
	{ lat: 43, lon: -97.91, elevation: 420 },
	{ lat: 43, lon: -96.92, elevation: 375 },
	{ lat: 43, lon: -95.93, elevation: 417 },
	{ lat: 43, lon: -94.95, elevation: 428 },
	{ lat: 43, lon: -93.96, elevation: 354 },
	{ lat: 43, lon: -92.97, elevation: 315 },
	{ lat: 43, lon: -91.98, elevation: 318 },
	{ lat: 43, lon: -90.99, elevation: 295 },
	{ lat: 43, lon: -90.0, elevation: 335 },
	{ lat: 43, lon: -89.01, elevation: 265 },
	{ lat: 43, lon: -88.02, elevation: 232 },
	{ lat: 43, lon: -87.03, elevation: 175 },
	{ lat: 43, lon: -86.04, elevation: 187 },
	{ lat: 43, lon: -85.05, elevation: 255 },
	{ lat: 43, lon: -84.07, elevation: 232 },
	{ lat: 43, lon: -83.08, elevation: 253 },
	{ lat: 43, lon: -82.09, elevation: 208 },
	{ lat: 43, lon: -81.1, elevation: 275 },
	{ lat: 43, lon: -80.11, elevation: 211 },
	{ lat: 43, lon: -79.12, elevation: 175 },
	{ lat: 43, lon: -78.13, elevation: 268 },
	{ lat: 43, lon: -77.14, elevation: 174 },
	{ lat: 43, lon: -76.15, elevation: 133 },
	{ lat: 43, lon: -75.16, elevation: 400 },
	{ lat: 43, lon: -74.18, elevation: 252 },
	{ lat: 43, lon: -73.19, elevation: 286 },
	{ lat: 43, lon: -72.2, elevation: 377 },
	{ lat: 43, lon: -71.21, elevation: 100 },
	{ lat: 43, lon: -8.9, elevation: 304 },
	{ lat: 43, lon: -7.91, elevation: 717 },
	{ lat: 43, lon: -6.92, elevation: 887 },
	{ lat: 43, lon: -5.93, elevation: 1815 },
	{ lat: 43, lon: -4.95, elevation: 1512 },
	{ lat: 43, lon: -3.96, elevation: 834 },
	{ lat: 43, lon: -2.97, elevation: 676 },
	{ lat: 43, lon: -1.98, elevation: 641 },
	{ lat: 43, lon: -0.99, elevation: 1258 },
	{ lat: 43, lon: 0.0, elevation: 1264 },
	{ lat: 43, lon: 0.99, elevation: 897 },
	{ lat: 43, lon: 1.98, elevation: 488 },
	{ lat: 43, lon: 2.97, elevation: 36 },
	{ lat: 43, lon: 10.88, elevation: 167 },
	{ lat: 43, lon: 11.87, elevation: 444 },
	{ lat: 43, lon: 12.86, elevation: 826 },
	{ lat: 43, lon: 13.85, elevation: 160 },
	{ lat: 43, lon: 17.8, elevation: 316 },
	{ lat: 43, lon: 18.79, elevation: 1802 },
	{ lat: 43, lon: 19.78, elevation: 604 },
	{ lat: 43, lon: 20.77, elevation: 811 },
	{ lat: 43, lon: 21.76, elevation: 243 },
	{ lat: 43, lon: 22.75, elevation: 612 },
	{ lat: 43, lon: 23.74, elevation: 577 },
	{ lat: 43, lon: 24.73, elevation: 494 },
	{ lat: 43, lon: 25.71, elevation: 217 },
	{ lat: 43, lon: 26.7, elevation: 292 },
	{ lat: 43, lon: 27.69, elevation: 20 },
	{ lat: 43, lon: 41.54, elevation: 787 },
	{ lat: 43, lon: 42.53, elevation: 1632 },
	{ lat: 43, lon: 43.52, elevation: 3436 },
	{ lat: 43, lon: 44.51, elevation: 713 },
	{ lat: 43, lon: 45.49, elevation: 705 },
	{ lat: 43, lon: 46.48, elevation: 705 },
	{ lat: 43, lon: 47.47, elevation: -22 },
	{ lat: 43, lon: 48.46, elevation: 0 },
	{ lat: 43, lon: 49.45, elevation: 0 },
	{ lat: 43, lon: 50.44, elevation: -29 },
	{ lat: 43, lon: 51.43, elevation: -29 },
	{ lat: 43, lon: 52.42, elevation: 80 },
	{ lat: 43, lon: 53.41, elevation: 186 },
	{ lat: 43, lon: 54.4, elevation: -64 },
	{ lat: 43, lon: 55.38, elevation: 230 },
	{ lat: 43, lon: 56.37, elevation: 192 },
	{ lat: 43, lon: 57.36, elevation: 175 },
	{ lat: 43, lon: 58.35, elevation: 125 },
	{ lat: 43, lon: 59.34, elevation: 64 },
	{ lat: 43, lon: 60.33, elevation: 58 },
	{ lat: 43, lon: 61.32, elevation: 66 },
	{ lat: 43, lon: 62.31, elevation: 88 },
	{ lat: 43, lon: 63.3, elevation: 207 },
	{ lat: 43, lon: 64.29, elevation: 216 },
	{ lat: 43, lon: 65.27, elevation: 217 },
	{ lat: 43, lon: 66.26, elevation: 192 },
	{ lat: 43, lon: 67.25, elevation: 180 },
	{ lat: 43, lon: 68.24, elevation: 190 },
	{ lat: 43, lon: 69.23, elevation: 369 },
	{ lat: 43, lon: 70.22, elevation: 1133 },
	{ lat: 43, lon: 71.21, elevation: 532 },
	{ lat: 43, lon: 72.2, elevation: 655 },
	{ lat: 43, lon: 73.19, elevation: 637 },
	{ lat: 43, lon: 74.18, elevation: 620 },
	{ lat: 43, lon: 75.16, elevation: 1121 },
	{ lat: 43, lon: 76.15, elevation: 2710 },
	{ lat: 43, lon: 77.14, elevation: 3549 },
	{ lat: 43, lon: 78.13, elevation: 2016 },
	{ lat: 43, lon: 79.12, elevation: 1812 },
	{ lat: 43, lon: 80.11, elevation: 2134 },
	{ lat: 43, lon: 81.1, elevation: 1627 },
	{ lat: 43, lon: 82.09, elevation: 1857 },
	{ lat: 43, lon: 83.08, elevation: 3223 },
	{ lat: 43, lon: 84.07, elevation: 2503 },
	{ lat: 43, lon: 85.05, elevation: 2512 },
	{ lat: 43, lon: 86.04, elevation: 3543 },
	{ lat: 43, lon: 87.03, elevation: 4194 },
	{ lat: 43, lon: 88.02, elevation: 865 },
	{ lat: 43, lon: 89.01, elevation: 190 },
	{ lat: 43, lon: 90.0, elevation: 592 },
	{ lat: 43, lon: 90.99, elevation: 466 },
	{ lat: 43, lon: 91.98, elevation: 438 },
	{ lat: 43, lon: 92.97, elevation: 762 },
	{ lat: 43, lon: 93.96, elevation: 1765 },
	{ lat: 43, lon: 94.95, elevation: 2249 },
	{ lat: 43, lon: 95.93, elevation: 1004 },
	{ lat: 43, lon: 96.92, elevation: 1380 },
	{ lat: 43, lon: 97.91, elevation: 1420 },
	{ lat: 43, lon: 98.9, elevation: 1452 },
	{ lat: 43, lon: 99.89, elevation: 1313 },
	{ lat: 43, lon: 100.88, elevation: 1531 },
	{ lat: 43, lon: 101.87, elevation: 1659 },
	{ lat: 43, lon: 102.86, elevation: 1263 },
	{ lat: 43, lon: 103.85, elevation: 1419 },
	{ lat: 43, lon: 104.84, elevation: 1759 },
	{ lat: 43, lon: 105.82, elevation: 1437 },
	{ lat: 43, lon: 106.81, elevation: 1176 },
	{ lat: 43, lon: 107.8, elevation: 912 },
	{ lat: 43, lon: 108.79, elevation: 1133 },
	{ lat: 43, lon: 109.78, elevation: 1258 },
	{ lat: 43, lon: 110.77, elevation: 1134 },
	{ lat: 43, lon: 111.76, elevation: 1062 },
	{ lat: 43, lon: 112.75, elevation: 1037 },
	{ lat: 43, lon: 113.74, elevation: 1023 },
	{ lat: 43, lon: 114.73, elevation: 1171 },
	{ lat: 43, lon: 115.71, elevation: 1271 },
	{ lat: 43, lon: 116.7, elevation: 1331 },
	{ lat: 43, lon: 117.69, elevation: 1288 },
	{ lat: 43, lon: 118.68, elevation: 978 },
	{ lat: 43, lon: 119.67, elevation: 473 },
	{ lat: 43, lon: 120.66, elevation: 351 },
	{ lat: 43, lon: 121.65, elevation: 285 },
	{ lat: 43, lon: 122.64, elevation: 222 },
	{ lat: 43, lon: 123.63, elevation: 94 },
	{ lat: 43, lon: 124.62, elevation: 240 },
	{ lat: 43, lon: 125.6, elevation: 358 },
	{ lat: 43, lon: 126.59, elevation: 315 },
	{ lat: 43, lon: 127.58, elevation: 840 },
	{ lat: 43, lon: 128.57, elevation: 659 },
	{ lat: 43, lon: 129.56, elevation: 292 },
	{ lat: 43, lon: 130.55, elevation: 255 },
	{ lat: 43, lon: 131.54, elevation: 0 },
	{ lat: 43, lon: 132.53, elevation: 546 },
	{ lat: 43, lon: 133.52, elevation: 218 },
	{ lat: 43, lon: 141.43, elevation: 59 },
	{ lat: 43, lon: 142.42, elevation: 384 },
	{ lat: 43, lon: 143.41, elevation: 124 },
	{ lat: 43, lon: 144.4, elevation: 4 },
	{ lat: 44, lon: -123.69, elevation: 140 },
	{ lat: 44, lon: -122.68, elevation: 318 },
	{ lat: 44, lon: -121.68, elevation: 1955 },
	{ lat: 44, lon: -120.67, elevation: 1230 },
	{ lat: 44, lon: -119.66, elevation: 1507 },
	{ lat: 44, lon: -118.66, elevation: 1516 },
	{ lat: 44, lon: -117.65, elevation: 984 },
	{ lat: 44, lon: -116.65, elevation: 803 },
	{ lat: 44, lon: -115.64, elevation: 1871 },
	{ lat: 44, lon: -114.64, elevation: 2749 },
	{ lat: 44, lon: -113.63, elevation: 2832 },
	{ lat: 44, lon: -112.63, elevation: 1479 },
	{ lat: 44, lon: -111.62, elevation: 1531 },
	{ lat: 44, lon: -110.61, elevation: 2415 },
	{ lat: 44, lon: -109.61, elevation: 3475 },
	{ lat: 44, lon: -108.6, elevation: 1708 },
	{ lat: 44, lon: -107.6, elevation: 1445 },
	{ lat: 44, lon: -106.59, elevation: 1484 },
	{ lat: 44, lon: -105.59, elevation: 1494 },
	{ lat: 44, lon: -104.58, elevation: 1278 },
	{ lat: 44, lon: -103.58, elevation: 1709 },
	{ lat: 44, lon: -102.57, elevation: 841 },
	{ lat: 44, lon: -101.56, elevation: 699 },
	{ lat: 44, lon: -100.56, elevation: 658 },
	{ lat: 44, lon: -99.55, elevation: 502 },
	{ lat: 44, lon: -98.55, elevation: 480 },
	{ lat: 44, lon: -97.54, elevation: 467 },
	{ lat: 44, lon: -96.54, elevation: 504 },
	{ lat: 44, lon: -95.53, elevation: 458 },
	{ lat: 44, lon: -94.53, elevation: 319 },
	{ lat: 44, lon: -93.52, elevation: 332 },
	{ lat: 44, lon: -92.51, elevation: 306 },
	{ lat: 44, lon: -91.51, elevation: 356 },
	{ lat: 44, lon: -90.5, elevation: 293 },
	{ lat: 44, lon: -89.5, elevation: 300 },
	{ lat: 44, lon: -88.49, elevation: 226 },
	{ lat: 44, lon: -87.49, elevation: 175 },
	{ lat: 44, lon: -86.48, elevation: 179 },
	{ lat: 44, lon: -85.47, elevation: 364 },
	{ lat: 44, lon: -84.47, elevation: 245 },
	{ lat: 44, lon: -83.46, elevation: 175 },
	{ lat: 44, lon: -82.46, elevation: 175 },
	{ lat: 44, lon: -81.45, elevation: 295 },
	{ lat: 44, lon: -80.45, elevation: 487 },
	{ lat: 44, lon: -79.44, elevation: 268 },
	{ lat: 44, lon: -78.44, elevation: 171 },
	{ lat: 44, lon: -77.43, elevation: 102 },
	{ lat: 44, lon: -76.42, elevation: 73 },
	{ lat: 44, lon: -75.42, elevation: 302 },
	{ lat: 44, lon: -74.41, elevation: 529 },
	{ lat: 44, lon: -73.41, elevation: 28 },
	{ lat: 44, lon: -72.4, elevation: 559 },
	{ lat: 44, lon: -71.4, elevation: 430 },
	{ lat: 44, lon: -70.39, elevation: 130 },
	{ lat: 44, lon: -69.39, elevation: 26 },
	{ lat: 44, lon: -65.36, elevation: 110 },
	{ lat: 44, lon: -1.01, elevation: 100 },
	{ lat: 44, lon: 0.0, elevation: 150 },
	{ lat: 44, lon: 1.01, elevation: 134 },
	{ lat: 44, lon: 2.01, elevation: 277 },
	{ lat: 44, lon: 3.02, elevation: 540 },
	{ lat: 44, lon: 4.02, elevation: 137 },
	{ lat: 44, lon: 5.03, elevation: 55 },
	{ lat: 44, lon: 6.03, elevation: 764 },
	{ lat: 44, lon: 7.04, elevation: 1041 },
	{ lat: 44, lon: 8.04, elevation: 208 },
	{ lat: 44, lon: 11.06, elevation: 788 },
	{ lat: 44, lon: 12.07, elevation: 438 },
	{ lat: 44, lon: 15.08, elevation: 0 },
	{ lat: 44, lon: 16.09, elevation: 265 },
	{ lat: 44, lon: 17.09, elevation: 1371 },
	{ lat: 44, lon: 18.1, elevation: 579 },
	{ lat: 44, lon: 19.11, elevation: 811 },
	{ lat: 44, lon: 20.11, elevation: 640 },
	{ lat: 44, lon: 21.12, elevation: 558 },
	{ lat: 44, lon: 22.12, elevation: 261 },
	{ lat: 44, lon: 23.13, elevation: 60 },
	{ lat: 44, lon: 24.13, elevation: 121 },
	{ lat: 44, lon: 25.14, elevation: 88 },
	{ lat: 44, lon: 26.15, elevation: 16 },
	{ lat: 44, lon: 27.15, elevation: 122 },
	{ lat: 44, lon: 28.16, elevation: 82 },
	{ lat: 44, lon: 39.22, elevation: 18 },
	{ lat: 44, lon: 40.22, elevation: 1393 },
	{ lat: 44, lon: 41.23, elevation: 832 },
	{ lat: 44, lon: 42.23, elevation: 1266 },
	{ lat: 44, lon: 43.24, elevation: 525 },
	{ lat: 44, lon: 44.25, elevation: 193 },
	{ lat: 44, lon: 45.25, elevation: 95 },
	{ lat: 44, lon: 46.26, elevation: -6 },
	{ lat: 44, lon: 47.26, elevation: -27 },
	{ lat: 44, lon: 48.27, elevation: 0 },
	{ lat: 44, lon: 49.27, elevation: 0 },
	{ lat: 44, lon: 50.28, elevation: -29 },
	{ lat: 44, lon: 51.28, elevation: 10 },
	{ lat: 44, lon: 52.29, elevation: 293 },
	{ lat: 44, lon: 53.3, elevation: 10 },
	{ lat: 44, lon: 54.3, elevation: 229 },
	{ lat: 44, lon: 55.31, elevation: 172 },
	{ lat: 44, lon: 56.31, elevation: 146 },
	{ lat: 44, lon: 57.32, elevation: 122 },
	{ lat: 44, lon: 58.32, elevation: 163 },
	{ lat: 44, lon: 59.33, elevation: 35 },
	{ lat: 44, lon: 60.34, elevation: 44 },
	{ lat: 44, lon: 61.34, elevation: 64 },
	{ lat: 44, lon: 62.35, elevation: 73 },
	{ lat: 44, lon: 63.35, elevation: 95 },
	{ lat: 44, lon: 64.36, elevation: 115 },
	{ lat: 44, lon: 65.36, elevation: 147 },
	{ lat: 44, lon: 66.37, elevation: 149 },
	{ lat: 44, lon: 67.37, elevation: 202 },
	{ lat: 44, lon: 68.38, elevation: 532 },
	{ lat: 44, lon: 69.39, elevation: 318 },
	{ lat: 44, lon: 70.39, elevation: 326 },
	{ lat: 44, lon: 71.4, elevation: 375 },
	{ lat: 44, lon: 72.4, elevation: 401 },
	{ lat: 44, lon: 73.41, elevation: 408 },
	{ lat: 44, lon: 74.41, elevation: 880 },
	{ lat: 44, lon: 75.42, elevation: 753 },
	{ lat: 44, lon: 76.42, elevation: 534 },
	{ lat: 44, lon: 77.43, elevation: 567 },
	{ lat: 44, lon: 78.44, elevation: 852 },
	{ lat: 44, lon: 79.44, elevation: 535 },
	{ lat: 44, lon: 80.45, elevation: 587 },
	{ lat: 44, lon: 81.45, elevation: 775 },
	{ lat: 44, lon: 82.46, elevation: 1909 },
	{ lat: 44, lon: 83.46, elevation: 3138 },
	{ lat: 44, lon: 84.47, elevation: 2659 },
	{ lat: 44, lon: 85.47, elevation: 1202 },
	{ lat: 44, lon: 86.48, elevation: 879 },
	{ lat: 44, lon: 87.49, elevation: 545 },
	{ lat: 44, lon: 88.49, elevation: 1318 },
	{ lat: 44, lon: 89.5, elevation: 769 },
	{ lat: 44, lon: 90.5, elevation: 1007 },
	{ lat: 44, lon: 91.51, elevation: 1580 },
	{ lat: 44, lon: 92.51, elevation: 1825 },
	{ lat: 44, lon: 93.52, elevation: 1630 },
	{ lat: 44, lon: 94.53, elevation: 315 },
	{ lat: 44, lon: 95.53, elevation: 799 },
	{ lat: 44, lon: 96.54, elevation: 1107 },
	{ lat: 44, lon: 97.54, elevation: 1182 },
	{ lat: 44, lon: 98.55, elevation: 1364 },
	{ lat: 44, lon: 99.55, elevation: 1373 },
	{ lat: 44, lon: 100.56, elevation: 1310 },
	{ lat: 44, lon: 101.56, elevation: 999 },
	{ lat: 44, lon: 102.57, elevation: 1429 },
	{ lat: 44, lon: 103.58, elevation: 1449 },
	{ lat: 44, lon: 104.58, elevation: 1529 },
	{ lat: 44, lon: 105.59, elevation: 1363 },
	{ lat: 44, lon: 106.59, elevation: 1340 },
	{ lat: 44, lon: 107.6, elevation: 1127 },
	{ lat: 44, lon: 108.6, elevation: 982 },
	{ lat: 44, lon: 109.61, elevation: 838 },
	{ lat: 44, lon: 110.61, elevation: 1016 },
	{ lat: 44, lon: 111.62, elevation: 1057 },
	{ lat: 44, lon: 112.63, elevation: 1022 },
	{ lat: 44, lon: 113.63, elevation: 978 },
	{ lat: 44, lon: 114.64, elevation: 1116 },
	{ lat: 44, lon: 115.64, elevation: 1082 },
	{ lat: 44, lon: 116.65, elevation: 1185 },
	{ lat: 44, lon: 117.65, elevation: 1569 },
	{ lat: 44, lon: 118.66, elevation: 1170 },
	{ lat: 44, lon: 119.66, elevation: 637 },
	{ lat: 44, lon: 120.67, elevation: 290 },
	{ lat: 44, lon: 121.68, elevation: 203 },
	{ lat: 44, lon: 122.68, elevation: 153 },
	{ lat: 44, lon: 123.69, elevation: 151 },
	{ lat: 44, lon: 124.69, elevation: 226 },
	{ lat: 44, lon: 125.7, elevation: 195 },
	{ lat: 44, lon: 126.7, elevation: 222 },
	{ lat: 44, lon: 127.71, elevation: 592 },
	{ lat: 44, lon: 128.72, elevation: 518 },
	{ lat: 44, lon: 129.72, elevation: 742 },
	{ lat: 44, lon: 130.73, elevation: 555 },
	{ lat: 44, lon: 131.73, elevation: 48 },
	{ lat: 44, lon: 132.74, elevation: 237 },
	{ lat: 44, lon: 133.74, elevation: 462 },
	{ lat: 44, lon: 134.75, elevation: 335 },
	{ lat: 44, lon: 141.79, elevation: 143 },
	{ lat: 44, lon: 142.79, elevation: 1029 },
	{ lat: 44, lon: 143.8, elevation: 138 },
	{ lat: 44, lon: 145.81, elevation: 0 },
	{ lat: 45, lon: -123.75, elevation: 764 },
	{ lat: 45, lon: -122.73, elevation: 243 },
	{ lat: 45, lon: -121.7, elevation: 1109 },
	{ lat: 45, lon: -120.68, elevation: 964 },
	{ lat: 45, lon: -119.66, elevation: 1290 },
	{ lat: 45, lon: -118.64, elevation: 1626 },
	{ lat: 45, lon: -117.61, elevation: 1074 },
	{ lat: 45, lon: -116.59, elevation: 1529 },
	{ lat: 45, lon: -115.57, elevation: 2174 },
	{ lat: 45, lon: -114.55, elevation: 2189 },
	{ lat: 45, lon: -113.52, elevation: 2106 },
	{ lat: 45, lon: -112.5, elevation: 1892 },
	{ lat: 45, lon: -111.48, elevation: 2757 },
	{ lat: 45, lon: -110.45, elevation: 2310 },
	{ lat: 45, lon: -109.43, elevation: 2978 },
	{ lat: 45, lon: -108.41, elevation: 1338 },
	{ lat: 45, lon: -107.39, elevation: 1328 },
	{ lat: 45, lon: -106.36, elevation: 1234 },
	{ lat: 45, lon: -105.34, elevation: 1022 },
	{ lat: 45, lon: -104.32, elevation: 1104 },
	{ lat: 45, lon: -103.3, elevation: 922 },
	{ lat: 45, lon: -102.27, elevation: 814 },
	{ lat: 45, lon: -101.25, elevation: 729 },
	{ lat: 45, lon: -100.23, elevation: 560 },
	{ lat: 45, lon: -99.2, elevation: 523 },
	{ lat: 45, lon: -98.18, elevation: 391 },
	{ lat: 45, lon: -97.16, elevation: 527 },
	{ lat: 45, lon: -96.14, elevation: 319 },
	{ lat: 45, lon: -95.11, elevation: 334 },
	{ lat: 45, lon: -94.09, elevation: 320 },
	{ lat: 45, lon: -93.07, elevation: 269 },
	{ lat: 45, lon: -92.05, elevation: 307 },
	{ lat: 45, lon: -91.02, elevation: 342 },
	{ lat: 45, lon: -90.0, elevation: 411 },
	{ lat: 45, lon: -88.98, elevation: 415 },
	{ lat: 45, lon: -87.95, elevation: 219 },
	{ lat: 45, lon: -86.93, elevation: 175 },
	{ lat: 45, lon: -85.91, elevation: 175 },
	{ lat: 45, lon: -84.89, elevation: 426 },
	{ lat: 45, lon: -83.86, elevation: 252 },
	{ lat: 45, lon: -82.84, elevation: 175 },
	{ lat: 45, lon: -81.82, elevation: 175 },
	{ lat: 45, lon: -80.8, elevation: 175 },
	{ lat: 45, lon: -79.77, elevation: 206 },
	{ lat: 45, lon: -78.75, elevation: 324 },
	{ lat: 45, lon: -77.73, elevation: 363 },
	{ lat: 45, lon: -76.7, elevation: 275 },
	{ lat: 45, lon: -75.68, elevation: 99 },
	{ lat: 45, lon: -74.66, elevation: 44 },
	{ lat: 45, lon: -73.64, elevation: 91 },
	{ lat: 45, lon: -72.61, elevation: 197 },
	{ lat: 45, lon: -71.59, elevation: 555 },
	{ lat: 45, lon: -70.57, elevation: 460 },
	{ lat: 45, lon: -69.55, elevation: 160 },
	{ lat: 45, lon: -68.52, elevation: 40 },
	{ lat: 45, lon: -67.5, elevation: 137 },
	{ lat: 45, lon: -64.43, elevation: 200 },
	{ lat: 45, lon: -63.41, elevation: 61 },
	{ lat: 45, lon: -62.39, elevation: 72 },
	{ lat: 45, lon: -1.02, elevation: 28 },
	{ lat: 45, lon: 0.0, elevation: 36 },
	{ lat: 45, lon: 1.02, elevation: 225 },
	{ lat: 45, lon: 2.05, elevation: 615 },
	{ lat: 45, lon: 3.07, elevation: 949 },
	{ lat: 45, lon: 4.09, elevation: 1046 },
	{ lat: 45, lon: 5.11, elevation: 296 },
	{ lat: 45, lon: 6.14, elevation: 1965 },
	{ lat: 45, lon: 7.16, elevation: 1626 },
	{ lat: 45, lon: 8.18, elevation: 186 },
	{ lat: 45, lon: 9.2, elevation: 199 },
	{ lat: 45, lon: 10.23, elevation: 26 },
	{ lat: 45, lon: 11.25, elevation: 6 },
	{ lat: 45, lon: 12.27, elevation: -5 },
	{ lat: 45, lon: 15.34, elevation: 675 },
	{ lat: 45, lon: 16.36, elevation: 217 },
	{ lat: 45, lon: 17.39, elevation: 97 },
	{ lat: 45, lon: 18.41, elevation: 90 },
	{ lat: 45, lon: 19.43, elevation: 76 },
	{ lat: 45, lon: 20.45, elevation: 68 },
	{ lat: 45, lon: 21.48, elevation: 93 },
	{ lat: 45, lon: 22.5, elevation: 811 },
	{ lat: 45, lon: 23.52, elevation: 232 },
	{ lat: 45, lon: 24.55, elevation: 534 },
	{ lat: 45, lon: 25.57, elevation: 462 },
	{ lat: 45, lon: 26.59, elevation: 82 },
	{ lat: 45, lon: 27.61, elevation: 20 },
	{ lat: 45, lon: 28.64, elevation: 39 },
	{ lat: 45, lon: 33.75, elevation: 84 },
	{ lat: 45, lon: 34.77, elevation: 441 },
	{ lat: 45, lon: 37.84, elevation: 79 },
	{ lat: 45, lon: 38.86, elevation: 13 },
	{ lat: 45, lon: 39.89, elevation: 60 },
	{ lat: 45, lon: 40.91, elevation: 251 },
	{ lat: 45, lon: 41.93, elevation: 639 },
	{ lat: 45, lon: 42.95, elevation: 397 },
	{ lat: 45, lon: 43.98, elevation: 211 },
	{ lat: 45, lon: 45.0, elevation: 46 },
	{ lat: 45, lon: 46.02, elevation: -2 },
	{ lat: 45, lon: 47.05, elevation: -29 },
	{ lat: 45, lon: 48.07, elevation: -29 },
	{ lat: 45, lon: 49.09, elevation: -29 },
	{ lat: 45, lon: 50.11, elevation: -29 },
	{ lat: 45, lon: 51.14, elevation: -23 },
	{ lat: 45, lon: 52.16, elevation: -16 },
	{ lat: 45, lon: 53.18, elevation: -5 },
	{ lat: 45, lon: 54.2, elevation: 29 },
	{ lat: 45, lon: 55.23, elevation: 50 },
	{ lat: 45, lon: 56.25, elevation: 75 },
	{ lat: 45, lon: 57.27, elevation: 171 },
	{ lat: 45, lon: 58.3, elevation: 29 },
	{ lat: 45, lon: 59.32, elevation: 36 },
	{ lat: 45, lon: 60.34, elevation: 29 },
	{ lat: 45, lon: 61.36, elevation: 47 },
	{ lat: 45, lon: 62.39, elevation: 79 },
	{ lat: 45, lon: 63.41, elevation: 92 },
	{ lat: 45, lon: 64.43, elevation: 108 },
	{ lat: 45, lon: 65.45, elevation: 119 },
	{ lat: 45, lon: 66.48, elevation: 130 },
	{ lat: 45, lon: 67.5, elevation: 127 },
	{ lat: 45, lon: 68.52, elevation: 162 },
	{ lat: 45, lon: 69.55, elevation: 235 },
	{ lat: 45, lon: 70.57, elevation: 228 },
	{ lat: 45, lon: 71.59, elevation: 307 },
	{ lat: 45, lon: 72.61, elevation: 371 },
	{ lat: 45, lon: 73.64, elevation: 463 },
	{ lat: 45, lon: 74.66, elevation: 348 },
	{ lat: 45, lon: 75.68, elevation: 373 },
	{ lat: 45, lon: 76.7, elevation: 393 },
	{ lat: 45, lon: 77.73, elevation: 480 },
	{ lat: 45, lon: 78.75, elevation: 1356 },
	{ lat: 45, lon: 79.77, elevation: 3353 },
	{ lat: 45, lon: 80.8, elevation: 1654 },
	{ lat: 45, lon: 81.82, elevation: 749 },
	{ lat: 45, lon: 82.84, elevation: 196 },
	{ lat: 45, lon: 83.86, elevation: 257 },
	{ lat: 45, lon: 84.89, elevation: 289 },
	{ lat: 45, lon: 85.91, elevation: 337 },
	{ lat: 45, lon: 86.93, elevation: 427 },
	{ lat: 45, lon: 87.95, elevation: 591 },
	{ lat: 45, lon: 88.98, elevation: 611 },
	{ lat: 45, lon: 90.0, elevation: 1014 },
	{ lat: 45, lon: 91.02, elevation: 1301 },
	{ lat: 45, lon: 92.05, elevation: 1303 },
	{ lat: 45, lon: 93.07, elevation: 2301 },
	{ lat: 45, lon: 94.09, elevation: 1607 },
	{ lat: 45, lon: 95.11, elevation: 2726 },
	{ lat: 45, lon: 96.14, elevation: 1184 },
	{ lat: 45, lon: 97.16, elevation: 1681 },
	{ lat: 45, lon: 98.18, elevation: 2532 },
	{ lat: 45, lon: 99.2, elevation: 1829 },
	{ lat: 45, lon: 100.23, elevation: 3676 },
	{ lat: 45, lon: 101.25, elevation: 1431 },
	{ lat: 45, lon: 102.27, elevation: 1317 },
	{ lat: 45, lon: 103.3, elevation: 1266 },
	{ lat: 45, lon: 104.32, elevation: 1198 },
	{ lat: 45, lon: 105.34, elevation: 1303 },
	{ lat: 45, lon: 106.36, elevation: 1316 },
	{ lat: 45, lon: 107.39, elevation: 1090 },
	{ lat: 45, lon: 108.41, elevation: 1079 },
	{ lat: 45, lon: 109.43, elevation: 1007 },
	{ lat: 45, lon: 110.45, elevation: 970 },
	{ lat: 45, lon: 111.48, elevation: 959 },
	{ lat: 45, lon: 112.5, elevation: 1018 },
	{ lat: 45, lon: 113.52, elevation: 1251 },
	{ lat: 45, lon: 114.55, elevation: 1489 },
	{ lat: 45, lon: 115.57, elevation: 1058 },
	{ lat: 45, lon: 116.59, elevation: 924 },
	{ lat: 45, lon: 117.61, elevation: 1004 },
	{ lat: 45, lon: 118.64, elevation: 962 },
	{ lat: 45, lon: 119.66, elevation: 950 },
	{ lat: 45, lon: 120.68, elevation: 550 },
	{ lat: 45, lon: 121.7, elevation: 217 },
	{ lat: 45, lon: 122.73, elevation: 151 },
	{ lat: 45, lon: 123.75, elevation: 129 },
	{ lat: 45, lon: 124.77, elevation: 133 },
	{ lat: 45, lon: 125.8, elevation: 178 },
	{ lat: 45, lon: 126.82, elevation: 180 },
	{ lat: 45, lon: 127.84, elevation: 207 },
	{ lat: 45, lon: 128.86, elevation: 457 },
	{ lat: 45, lon: 129.89, elevation: 399 },
	{ lat: 45, lon: 130.91, elevation: 568 },
	{ lat: 45, lon: 131.93, elevation: 119 },
	{ lat: 45, lon: 132.95, elevation: 65 },
	{ lat: 45, lon: 133.98, elevation: 191 },
	{ lat: 45, lon: 135.0, elevation: 499 },
	{ lat: 45, lon: 136.02, elevation: 726 },
	{ lat: 45, lon: 142.16, elevation: 419 },
	{ lat: 46, lon: -123.82, elevation: 216 },
	{ lat: 46, lon: -122.77, elevation: 486 },
	{ lat: 46, lon: -121.73, elevation: 1282 },
	{ lat: 46, lon: -120.69, elevation: 1207 },
	{ lat: 46, lon: -119.65, elevation: 206 },
	{ lat: 46, lon: -118.61, elevation: 156 },
	{ lat: 46, lon: -117.57, elevation: 1028 },
	{ lat: 46, lon: -116.53, elevation: 1204 },
	{ lat: 46, lon: -115.49, elevation: 1528 },
	{ lat: 46, lon: -114.45, elevation: 2323 },
	{ lat: 46, lon: -113.41, elevation: 2846 },
	{ lat: 46, lon: -112.37, elevation: 1918 },
	{ lat: 46, lon: -111.33, elevation: 1619 },
	{ lat: 46, lon: -110.29, elevation: 3054 },
	{ lat: 46, lon: -109.25, elevation: 1253 },
	{ lat: 46, lon: -108.21, elevation: 1006 },
	{ lat: 46, lon: -107.17, elevation: 998 },
	{ lat: 46, lon: -106.13, elevation: 902 },
	{ lat: 46, lon: -105.09, elevation: 846 },
	{ lat: 46, lon: -104.05, elevation: 915 },
	{ lat: 46, lon: -103.01, elevation: 818 },
	{ lat: 46, lon: -101.97, elevation: 757 },
	{ lat: 46, lon: -100.92, elevation: 658 },
	{ lat: 46, lon: -99.88, elevation: 593 },
	{ lat: 46, lon: -98.84, elevation: 516 },
	{ lat: 46, lon: -97.8, elevation: 397 },
	{ lat: 46, lon: -96.76, elevation: 311 },
	{ lat: 46, lon: -95.72, elevation: 417 },
	{ lat: 46, lon: -94.68, elevation: 362 },
	{ lat: 46, lon: -93.64, elevation: 393 },
	{ lat: 46, lon: -92.6, elevation: 300 },
	{ lat: 46, lon: -91.56, elevation: 369 },
	{ lat: 46, lon: -90.52, elevation: 472 },
	{ lat: 46, lon: -89.48, elevation: 511 },
	{ lat: 46, lon: -88.44, elevation: 415 },
	{ lat: 46, lon: -87.4, elevation: 305 },
	{ lat: 46, lon: -86.36, elevation: 183 },
	{ lat: 46, lon: -85.32, elevation: 175 },
	{ lat: 46, lon: -84.28, elevation: 186 },
	{ lat: 46, lon: -83.24, elevation: 175 },
	{ lat: 46, lon: -82.2, elevation: 188 },
	{ lat: 46, lon: -81.16, elevation: 175 },
	{ lat: 46, lon: -80.12, elevation: 222 },
	{ lat: 46, lon: -79.08, elevation: 376 },
	{ lat: 46, lon: -78.03, elevation: 212 },
	{ lat: 46, lon: -76.99, elevation: 330 },
	{ lat: 46, lon: -75.95, elevation: 148 },
	{ lat: 46, lon: -74.91, elevation: 336 },
	{ lat: 46, lon: -73.87, elevation: 253 },
	{ lat: 46, lon: -72.83, elevation: 29 },
	{ lat: 46, lon: -71.79, elevation: 340 },
	{ lat: 46, lon: -70.75, elevation: 377 },
	{ lat: 46, lon: -69.71, elevation: 389 },
	{ lat: 46, lon: -68.67, elevation: 226 },
	{ lat: 46, lon: -67.63, elevation: 136 },
	{ lat: 46, lon: -66.59, elevation: 70 },
	{ lat: 46, lon: -65.55, elevation: 67 },
	{ lat: 46, lon: -64.51, elevation: 115 },
	{ lat: 46, lon: -61.39, elevation: 62 },
	{ lat: 46, lon: -60.35, elevation: 88 },
	{ lat: 46, lon: -1.04, elevation: 2 },
	{ lat: 46, lon: 0.0, elevation: 80 },
	{ lat: 46, lon: 1.04, elevation: 336 },
	{ lat: 46, lon: 2.08, elevation: 508 },
	{ lat: 46, lon: 3.12, elevation: 358 },
	{ lat: 46, lon: 4.16, elevation: 423 },
	{ lat: 46, lon: 5.2, elevation: 309 },
	{ lat: 46, lon: 6.24, elevation: 755 },
	{ lat: 46, lon: 7.28, elevation: 2580 },
	{ lat: 46, lon: 8.32, elevation: 217 },
	{ lat: 46, lon: 9.36, elevation: 1001 },
	{ lat: 46, lon: 10.4, elevation: 1973 },
	{ lat: 46, lon: 11.45, elevation: 1222 },
	{ lat: 46, lon: 12.49, elevation: 23 },
	{ lat: 46, lon: 13.53, elevation: 92 },
	{ lat: 46, lon: 14.57, elevation: 300 },
	{ lat: 46, lon: 15.61, elevation: 451 },
	{ lat: 46, lon: 16.65, elevation: 162 },
	{ lat: 46, lon: 17.69, elevation: 113 },
	{ lat: 46, lon: 18.73, elevation: 81 },
	{ lat: 46, lon: 19.77, elevation: 105 },
	{ lat: 46, lon: 20.81, elevation: 87 },
	{ lat: 46, lon: 21.85, elevation: 183 },
	{ lat: 46, lon: 22.89, elevation: 274 },
	{ lat: 46, lon: 23.93, elevation: 437 },
	{ lat: 46, lon: 24.97, elevation: 571 },
	{ lat: 46, lon: 26.01, elevation: 730 },
	{ lat: 46, lon: 27.05, elevation: 283 },
	{ lat: 46, lon: 28.09, elevation: 8 },
	{ lat: 46, lon: 29.13, elevation: 87 },
	{ lat: 46, lon: 30.17, elevation: 31 },
	{ lat: 46, lon: 34.34, elevation: 0 },
	{ lat: 46, lon: 38.5, elevation: 7 },
	{ lat: 46, lon: 39.54, elevation: 55 },
	{ lat: 46, lon: 40.58, elevation: 54 },
	{ lat: 46, lon: 41.62, elevation: 101 },
	{ lat: 46, lon: 42.66, elevation: 59 },
	{ lat: 46, lon: 43.7, elevation: 20 },
	{ lat: 46, lon: 44.74, elevation: 80 },
	{ lat: 46, lon: 45.78, elevation: -15 },
	{ lat: 46, lon: 46.82, elevation: -27 },
	{ lat: 46, lon: 47.86, elevation: -28 },
	{ lat: 46, lon: 48.9, elevation: -30 },
	{ lat: 46, lon: 49.94, elevation: -29 },
	{ lat: 46, lon: 50.98, elevation: -29 },
	{ lat: 46, lon: 52.02, elevation: -29 },
	{ lat: 46, lon: 53.06, elevation: -29 },
	{ lat: 46, lon: 54.1, elevation: -17 },
	{ lat: 46, lon: 55.14, elevation: -29 },
	{ lat: 46, lon: 56.18, elevation: 12 },
	{ lat: 46, lon: 57.23, elevation: 88 },
	{ lat: 46, lon: 58.27, elevation: 182 },
	{ lat: 46, lon: 59.31, elevation: 29 },
	{ lat: 46, lon: 60.35, elevation: 36 },
	{ lat: 46, lon: 61.39, elevation: 61 },
	{ lat: 46, lon: 62.43, elevation: 63 },
	{ lat: 46, lon: 63.47, elevation: 91 },
	{ lat: 46, lon: 64.51, elevation: 93 },
	{ lat: 46, lon: 65.55, elevation: 139 },
	{ lat: 46, lon: 66.59, elevation: 114 },
	{ lat: 46, lon: 67.63, elevation: 173 },
	{ lat: 46, lon: 68.67, elevation: 279 },
	{ lat: 46, lon: 69.71, elevation: 309 },
	{ lat: 46, lon: 70.75, elevation: 354 },
	{ lat: 46, lon: 71.79, elevation: 470 },
	{ lat: 46, lon: 72.83, elevation: 454 },
	{ lat: 46, lon: 73.87, elevation: 338 },
	{ lat: 46, lon: 74.91, elevation: 343 },
	{ lat: 46, lon: 75.95, elevation: 356 },
	{ lat: 46, lon: 76.99, elevation: 358 },
	{ lat: 46, lon: 78.03, elevation: 355 },
	{ lat: 46, lon: 79.08, elevation: 451 },
	{ lat: 46, lon: 80.12, elevation: 503 },
	{ lat: 46, lon: 81.16, elevation: 472 },
	{ lat: 46, lon: 82.2, elevation: 503 },
	{ lat: 46, lon: 83.24, elevation: 1744 },
	{ lat: 46, lon: 84.28, elevation: 1510 },
	{ lat: 46, lon: 85.32, elevation: 397 },
	{ lat: 46, lon: 86.36, elevation: 310 },
	{ lat: 46, lon: 87.4, elevation: 580 },
	{ lat: 46, lon: 88.44, elevation: 758 },
	{ lat: 46, lon: 89.48, elevation: 967 },
	{ lat: 46, lon: 90.52, elevation: 1072 },
	{ lat: 46, lon: 91.56, elevation: 1215 },
	{ lat: 46, lon: 92.6, elevation: 1594 },
	{ lat: 46, lon: 93.64, elevation: 2621 },
	{ lat: 46, lon: 94.68, elevation: 1220 },
	{ lat: 46, lon: 95.72, elevation: 1251 },
	{ lat: 46, lon: 96.76, elevation: 1707 },
	{ lat: 46, lon: 97.8, elevation: 1729 },
	{ lat: 46, lon: 98.84, elevation: 1649 },
	{ lat: 46, lon: 99.88, elevation: 1771 },
	{ lat: 46, lon: 100.92, elevation: 1863 },
	{ lat: 46, lon: 101.97, elevation: 2089 },
	{ lat: 46, lon: 103.01, elevation: 1598 },
	{ lat: 46, lon: 104.05, elevation: 1494 },
	{ lat: 46, lon: 105.09, elevation: 1370 },
	{ lat: 46, lon: 106.13, elevation: 1394 },
	{ lat: 46, lon: 107.17, elevation: 1447 },
	{ lat: 46, lon: 108.21, elevation: 1192 },
	{ lat: 46, lon: 109.25, elevation: 1101 },
	{ lat: 46, lon: 110.29, elevation: 1005 },
	{ lat: 46, lon: 111.33, elevation: 1103 },
	{ lat: 46, lon: 112.37, elevation: 948 },
	{ lat: 46, lon: 113.41, elevation: 1222 },
	{ lat: 46, lon: 114.45, elevation: 1003 },
	{ lat: 46, lon: 115.49, elevation: 1120 },
	{ lat: 46, lon: 116.53, elevation: 891 },
	{ lat: 46, lon: 117.57, elevation: 924 },
	{ lat: 46, lon: 118.61, elevation: 995 },
	{ lat: 46, lon: 119.65, elevation: 923 },
	{ lat: 46, lon: 120.69, elevation: 779 },
	{ lat: 46, lon: 121.73, elevation: 421 },
	{ lat: 46, lon: 122.77, elevation: 208 },
	{ lat: 46, lon: 123.82, elevation: 130 },
	{ lat: 46, lon: 124.86, elevation: 133 },
	{ lat: 46, lon: 125.9, elevation: 154 },
	{ lat: 46, lon: 126.94, elevation: 113 },
	{ lat: 46, lon: 127.98, elevation: 148 },
	{ lat: 46, lon: 129.02, elevation: 97 },
	{ lat: 46, lon: 130.06, elevation: 264 },
	{ lat: 46, lon: 131.1, elevation: 365 },
	{ lat: 46, lon: 132.14, elevation: 315 },
	{ lat: 46, lon: 133.18, elevation: 60 },
	{ lat: 46, lon: 134.22, elevation: 81 },
	{ lat: 46, lon: 135.26, elevation: 188 },
	{ lat: 46, lon: 136.3, elevation: 1155 },
	{ lat: 46, lon: 137.34, elevation: 559 },
	{ lat: 47, lon: -123.72, elevation: 141 },
	{ lat: 47, lon: -122.65, elevation: 43 },
	{ lat: 47, lon: -121.59, elevation: 1308 },
	{ lat: 47, lon: -120.53, elevation: 481 },
	{ lat: 47, lon: -119.47, elevation: 335 },
	{ lat: 47, lon: -118.41, elevation: 537 },
	{ lat: 47, lon: -117.35, elevation: 723 },
	{ lat: 47, lon: -116.28, elevation: 955 },
	{ lat: 47, lon: -115.22, elevation: 1609 },
	{ lat: 47, lon: -114.16, elevation: 1208 },
	{ lat: 47, lon: -113.1, elevation: 1315 },
	{ lat: 47, lon: -112.04, elevation: 1133 },
	{ lat: 47, lon: -110.97, elevation: 2144 },
	{ lat: 47, lon: -109.91, elevation: 1255 },
	{ lat: 47, lon: -108.85, elevation: 1100 },
	{ lat: 47, lon: -107.79, elevation: 848 },
	{ lat: 47, lon: -106.73, elevation: 869 },
	{ lat: 47, lon: -105.66, elevation: 931 },
	{ lat: 47, lon: -104.6, elevation: 766 },
	{ lat: 47, lon: -103.54, elevation: 775 },
	{ lat: 47, lon: -102.48, elevation: 652 },
	{ lat: 47, lon: -101.42, elevation: 643 },
	{ lat: 47, lon: -100.35, elevation: 578 },
	{ lat: 47, lon: -99.29, elevation: 555 },
	{ lat: 47, lon: -98.23, elevation: 440 },
	{ lat: 47, lon: -97.17, elevation: 284 },
	{ lat: 47, lon: -96.11, elevation: 369 },
	{ lat: 47, lon: -95.04, elevation: 453 },
	{ lat: 47, lon: -93.98, elevation: 404 },
	{ lat: 47, lon: -92.92, elevation: 385 },
	{ lat: 47, lon: -91.86, elevation: 359 },
	{ lat: 47, lon: -90.8, elevation: 179 },
	{ lat: 47, lon: -89.73, elevation: 179 },
	{ lat: 47, lon: -88.67, elevation: 387 },
	{ lat: 47, lon: -87.61, elevation: 179 },
	{ lat: 47, lon: -86.55, elevation: 0 },
	{ lat: 47, lon: -85.49, elevation: 179 },
	{ lat: 47, lon: -84.42, elevation: 351 },
	{ lat: 47, lon: -83.36, elevation: 453 },
	{ lat: 47, lon: -82.3, elevation: 473 },
	{ lat: 47, lon: -81.24, elevation: 448 },
	{ lat: 47, lon: -80.18, elevation: 362 },
	{ lat: 47, lon: -79.12, elevation: 292 },
	{ lat: 47, lon: -78.05, elevation: 332 },
	{ lat: 47, lon: -76.99, elevation: 466 },
	{ lat: 47, lon: -75.93, elevation: 405 },
	{ lat: 47, lon: -74.87, elevation: 447 },
	{ lat: 47, lon: -73.81, elevation: 401 },
	{ lat: 47, lon: -72.74, elevation: 325 },
	{ lat: 47, lon: -71.68, elevation: 541 },
	{ lat: 47, lon: -69.56, elevation: 333 },
	{ lat: 47, lon: -68.5, elevation: 273 },
	{ lat: 47, lon: -67.43, elevation: 251 },
	{ lat: 47, lon: -66.37, elevation: 387 },
	{ lat: 47, lon: -65.31, elevation: 28 },
	{ lat: 47, lon: -55.75, elevation: 67 },
	{ lat: 47, lon: -53.63, elevation: 62 },
	{ lat: 47, lon: -1.59, elevation: 21 },
	{ lat: 47, lon: -0.53, elevation: 123 },
	{ lat: 47, lon: 0.53, elevation: 55 },
	{ lat: 47, lon: 1.59, elevation: 143 },
	{ lat: 47, lon: 2.65, elevation: 181 },
	{ lat: 47, lon: 3.72, elevation: 208 },
	{ lat: 47, lon: 4.78, elevation: 320 },
	{ lat: 47, lon: 5.84, elevation: 255 },
	{ lat: 47, lon: 6.9, elevation: 623 },
	{ lat: 47, lon: 7.96, elevation: 1215 },
	{ lat: 47, lon: 9.03, elevation: 1990 },
	{ lat: 47, lon: 10.09, elevation: 2148 },
	{ lat: 47, lon: 11.15, elevation: 2164 },
	{ lat: 47, lon: 12.21, elevation: 2560 },
	{ lat: 47, lon: 13.27, elevation: 2424 },
	{ lat: 47, lon: 14.34, elevation: 1143 },
	{ lat: 47, lon: 15.4, elevation: 341 },
	{ lat: 47, lon: 16.46, elevation: 198 },
	{ lat: 47, lon: 17.52, elevation: 276 },
	{ lat: 47, lon: 18.58, elevation: 105 },
	{ lat: 47, lon: 19.65, elevation: 132 },
	{ lat: 47, lon: 20.71, elevation: 80 },
	{ lat: 47, lon: 21.77, elevation: 114 },
	{ lat: 47, lon: 22.83, elevation: 577 },
	{ lat: 47, lon: 23.89, elevation: 302 },
	{ lat: 47, lon: 24.96, elevation: 900 },
	{ lat: 47, lon: 26.02, elevation: 819 },
	{ lat: 47, lon: 27.08, elevation: 265 },
	{ lat: 47, lon: 28.14, elevation: 227 },
	{ lat: 47, lon: 29.2, elevation: 108 },
	{ lat: 47, lon: 30.27, elevation: 124 },
	{ lat: 47, lon: 31.33, elevation: 46 },
	{ lat: 47, lon: 32.39, elevation: 51 },
	{ lat: 47, lon: 33.45, elevation: 62 },
	{ lat: 47, lon: 34.51, elevation: 65 },
	{ lat: 47, lon: 35.58, elevation: 35 },
	{ lat: 47, lon: 36.64, elevation: 64 },
	{ lat: 47, lon: 39.82, elevation: 63 },
	{ lat: 47, lon: 40.88, elevation: 36 },
	{ lat: 47, lon: 41.95, elevation: 133 },
	{ lat: 47, lon: 43.01, elevation: 61 },
	{ lat: 47, lon: 44.07, elevation: 153 },
	{ lat: 47, lon: 45.13, elevation: 3 },
	{ lat: 47, lon: 46.19, elevation: -5 },
	{ lat: 47, lon: 47.26, elevation: -15 },
	{ lat: 47, lon: 48.32, elevation: -17 },
	{ lat: 47, lon: 49.38, elevation: -21 },
	{ lat: 47, lon: 50.44, elevation: -27 },
	{ lat: 47, lon: 51.5, elevation: -29 },
	{ lat: 47, lon: 52.57, elevation: -28 },
	{ lat: 47, lon: 53.63, elevation: -22 },
	{ lat: 47, lon: 54.69, elevation: 5 },
	{ lat: 47, lon: 55.75, elevation: 67 },
	{ lat: 47, lon: 56.81, elevation: 104 },
	{ lat: 47, lon: 57.88, elevation: 112 },
	{ lat: 47, lon: 58.94, elevation: 160 },
	{ lat: 47, lon: 60.0, elevation: 217 },
	{ lat: 47, lon: 61.06, elevation: 86 },
	{ lat: 47, lon: 62.12, elevation: 57 },
	{ lat: 47, lon: 63.19, elevation: 104 },
	{ lat: 47, lon: 64.25, elevation: 110 },
	{ lat: 47, lon: 65.31, elevation: 180 },
	{ lat: 47, lon: 66.37, elevation: 274 },
	{ lat: 47, lon: 67.43, elevation: 290 },
	{ lat: 47, lon: 68.5, elevation: 291 },
	{ lat: 47, lon: 69.56, elevation: 356 },
	{ lat: 47, lon: 70.62, elevation: 454 },
	{ lat: 47, lon: 71.68, elevation: 522 },
	{ lat: 47, lon: 72.74, elevation: 660 },
	{ lat: 47, lon: 73.81, elevation: 493 },
	{ lat: 47, lon: 74.87, elevation: 428 },
	{ lat: 47, lon: 75.93, elevation: 410 },
	{ lat: 47, lon: 76.99, elevation: 511 },
	{ lat: 47, lon: 78.05, elevation: 508 },
	{ lat: 47, lon: 79.12, elevation: 376 },
	{ lat: 47, lon: 80.18, elevation: 399 },
	{ lat: 47, lon: 81.24, elevation: 562 },
	{ lat: 47, lon: 82.3, elevation: 841 },
	{ lat: 47, lon: 83.36, elevation: 1403 },
	{ lat: 47, lon: 84.42, elevation: 1462 },
	{ lat: 47, lon: 85.49, elevation: 2618 },
	{ lat: 47, lon: 86.55, elevation: 1252 },
	{ lat: 47, lon: 87.61, elevation: 545 },
	{ lat: 47, lon: 88.67, elevation: 786 },
	{ lat: 47, lon: 89.73, elevation: 1511 },
	{ lat: 47, lon: 90.8, elevation: 2655 },
	{ lat: 47, lon: 91.86, elevation: 2156 },
	{ lat: 47, lon: 92.92, elevation: 1872 },
	{ lat: 47, lon: 93.98, elevation: 1466 },
	{ lat: 47, lon: 95.04, elevation: 1658 },
	{ lat: 47, lon: 96.11, elevation: 1517 },
	{ lat: 47, lon: 97.17, elevation: 2302 },
	{ lat: 47, lon: 98.23, elevation: 3005 },
	{ lat: 47, lon: 99.29, elevation: 2126 },
	{ lat: 47, lon: 100.35, elevation: 2549 },
	{ lat: 47, lon: 101.42, elevation: 2516 },
	{ lat: 47, lon: 102.48, elevation: 1737 },
	{ lat: 47, lon: 103.54, elevation: 1650 },
	{ lat: 47, lon: 104.6, elevation: 1367 },
	{ lat: 47, lon: 105.66, elevation: 1261 },
	{ lat: 47, lon: 106.73, elevation: 1432 },
	{ lat: 47, lon: 107.79, elevation: 1465 },
	{ lat: 47, lon: 108.85, elevation: 1184 },
	{ lat: 47, lon: 109.91, elevation: 1102 },
	{ lat: 47, lon: 110.97, elevation: 1283 },
	{ lat: 47, lon: 112.04, elevation: 1295 },
	{ lat: 47, lon: 113.1, elevation: 1110 },
	{ lat: 47, lon: 114.16, elevation: 963 },
	{ lat: 47, lon: 115.22, elevation: 988 },
	{ lat: 47, lon: 116.28, elevation: 668 },
	{ lat: 47, lon: 117.35, elevation: 684 },
	{ lat: 47, lon: 118.41, elevation: 865 },
	{ lat: 47, lon: 119.47, elevation: 933 },
	{ lat: 47, lon: 120.53, elevation: 1179 },
	{ lat: 47, lon: 121.59, elevation: 510 },
	{ lat: 47, lon: 122.65, elevation: 246 },
	{ lat: 47, lon: 123.72, elevation: 145 },
	{ lat: 47, lon: 124.78, elevation: 151 },
	{ lat: 47, lon: 125.84, elevation: 234 },
	{ lat: 47, lon: 126.9, elevation: 211 },
	{ lat: 47, lon: 127.96, elevation: 203 },
	{ lat: 47, lon: 129.03, elevation: 395 },
	{ lat: 47, lon: 130.09, elevation: 113 },
	{ lat: 47, lon: 131.15, elevation: 70 },
	{ lat: 47, lon: 132.21, elevation: 58 },
	{ lat: 47, lon: 133.27, elevation: 56 },
	{ lat: 47, lon: 134.34, elevation: 99 },
	{ lat: 47, lon: 135.4, elevation: 328 },
	{ lat: 47, lon: 136.46, elevation: 905 },
	{ lat: 47, lon: 137.52, elevation: 1012 },
	{ lat: 47, lon: 142.83, elevation: 739 },
	{ lat: 48, lon: -123.78, elevation: 1203 },
	{ lat: 48, lon: -121.62, elevation: 1343 },
	{ lat: 48, lon: -120.54, elevation: 1628 },
	{ lat: 48, lon: -119.46, elevation: 644 },
	{ lat: 48, lon: -118.38, elevation: 384 },
	{ lat: 48, lon: -117.3, elevation: 604 },
	{ lat: 48, lon: -116.22, elevation: 1204 },
	{ lat: 48, lon: -115.14, elevation: 1305 },
	{ lat: 48, lon: -114.05, elevation: 894 },
	{ lat: 48, lon: -112.97, elevation: 1768 },
	{ lat: 48, lon: -111.89, elevation: 1091 },
	{ lat: 48, lon: -110.81, elevation: 981 },
	{ lat: 48, lon: -109.73, elevation: 1049 },
	{ lat: 48, lon: -108.65, elevation: 1301 },
	{ lat: 48, lon: -107.57, elevation: 730 },
	{ lat: 48, lon: -106.49, elevation: 700 },
	{ lat: 48, lon: -105.41, elevation: 677 },
	{ lat: 48, lon: -104.32, elevation: 637 },
	{ lat: 48, lon: -103.24, elevation: 672 },
	{ lat: 48, lon: -102.16, elevation: 637 },
	{ lat: 48, lon: -101.08, elevation: 548 },
	{ lat: 48, lon: -100.0, elevation: 478 },
	{ lat: 48, lon: -98.92, elevation: 435 },
	{ lat: 48, lon: -97.84, elevation: 420 },
	{ lat: 48, lon: -96.76, elevation: 259 },
	{ lat: 48, lon: -95.68, elevation: 357 },
	{ lat: 48, lon: -94.59, elevation: 377 },
	{ lat: 48, lon: -93.51, elevation: 395 },
	{ lat: 48, lon: -92.43, elevation: 405 },
	{ lat: 48, lon: -91.35, elevation: 464 },
	{ lat: 48, lon: -90.27, elevation: 640 },
	{ lat: 48, lon: -89.19, elevation: 179 },
	{ lat: 48, lon: -88.11, elevation: 179 },
	{ lat: 48, lon: -87.03, elevation: 179 },
	{ lat: 48, lon: -85.95, elevation: 179 },
	{ lat: 48, lon: -84.86, elevation: 331 },
	{ lat: 48, lon: -83.78, elevation: 536 },
	{ lat: 48, lon: -82.7, elevation: 431 },
	{ lat: 48, lon: -81.62, elevation: 357 },
	{ lat: 48, lon: -80.54, elevation: 386 },
	{ lat: 48, lon: -79.46, elevation: 311 },
	{ lat: 48, lon: -78.38, elevation: 360 },
	{ lat: 48, lon: -77.3, elevation: 358 },
	{ lat: 48, lon: -76.22, elevation: 462 },
	{ lat: 48, lon: -75.14, elevation: 475 },
	{ lat: 48, lon: -74.05, elevation: 472 },
	{ lat: 48, lon: -72.97, elevation: 418 },
	{ lat: 48, lon: -71.89, elevation: 512 },
	{ lat: 48, lon: -70.81, elevation: 573 },
	{ lat: 48, lon: -68.65, elevation: 264 },
	{ lat: 48, lon: -67.57, elevation: 414 },
	{ lat: 48, lon: -66.49, elevation: 52 },
	{ lat: 48, lon: -65.41, elevation: 7 },
	{ lat: 48, lon: -58.92, elevation: 207 },
	{ lat: 48, lon: -57.84, elevation: 443 },
	{ lat: 48, lon: -56.76, elevation: 322 },
	{ lat: 48, lon: -55.68, elevation: 184 },
	{ lat: 48, lon: -54.59, elevation: 216 },
	{ lat: 48, lon: -3.78, elevation: 162 },
	{ lat: 48, lon: -2.7, elevation: 85 },
	{ lat: 48, lon: -1.62, elevation: 32 },
	{ lat: 48, lon: -0.54, elevation: 92 },
	{ lat: 48, lon: 0.54, elevation: 127 },
	{ lat: 48, lon: 1.62, elevation: 120 },
	{ lat: 48, lon: 2.7, elevation: 93 },
	{ lat: 48, lon: 3.78, elevation: 109 },
	{ lat: 48, lon: 4.86, elevation: 279 },
	{ lat: 48, lon: 5.95, elevation: 306 },
	{ lat: 48, lon: 7.03, elevation: 594 },
	{ lat: 48, lon: 8.11, elevation: 941 },
	{ lat: 48, lon: 9.19, elevation: 590 },
	{ lat: 48, lon: 10.27, elevation: 616 },
	{ lat: 48, lon: 11.35, elevation: 588 },
	{ lat: 48, lon: 12.43, elevation: 553 },
	{ lat: 48, lon: 13.51, elevation: 520 },
	{ lat: 48, lon: 14.59, elevation: 459 },
	{ lat: 48, lon: 15.68, elevation: 808 },
	{ lat: 48, lon: 16.76, elevation: 178 },
	{ lat: 48, lon: 17.84, elevation: 107 },
	{ lat: 48, lon: 18.92, elevation: 413 },
	{ lat: 48, lon: 20.0, elevation: 238 },
	{ lat: 48, lon: 21.08, elevation: 90 },
	{ lat: 48, lon: 22.16, elevation: 136 },
	{ lat: 48, lon: 23.24, elevation: 209 },
	{ lat: 48, lon: 24.32, elevation: 927 },
	{ lat: 48, lon: 25.41, elevation: 949 },
	{ lat: 48, lon: 26.49, elevation: 188 },
	{ lat: 48, lon: 27.57, elevation: 212 },
	{ lat: 48, lon: 28.65, elevation: 141 },
	{ lat: 48, lon: 29.73, elevation: 189 },
	{ lat: 48, lon: 30.81, elevation: 105 },
	{ lat: 48, lon: 31.89, elevation: 191 },
	{ lat: 48, lon: 32.97, elevation: 90 },
	{ lat: 48, lon: 34.05, elevation: 121 },
	{ lat: 48, lon: 35.14, elevation: 47 },
	{ lat: 48, lon: 36.22, elevation: 102 },
	{ lat: 48, lon: 37.3, elevation: 111 },
	{ lat: 48, lon: 38.38, elevation: 202 },
	{ lat: 48, lon: 39.46, elevation: 165 },
	{ lat: 48, lon: 40.54, elevation: 154 },
	{ lat: 48, lon: 41.62, elevation: 127 },
	{ lat: 48, lon: 42.7, elevation: 57 },
	{ lat: 48, lon: 43.78, elevation: 111 },
	{ lat: 48, lon: 44.86, elevation: 9 },
	{ lat: 48, lon: 45.95, elevation: 2 },
	{ lat: 48, lon: 47.03, elevation: -3 },
	{ lat: 48, lon: 48.11, elevation: -10 },
	{ lat: 48, lon: 49.19, elevation: -17 },
	{ lat: 48, lon: 50.27, elevation: -14 },
	{ lat: 48, lon: 51.35, elevation: -14 },
	{ lat: 48, lon: 52.43, elevation: -21 },
	{ lat: 48, lon: 53.51, elevation: -4 },
	{ lat: 48, lon: 54.59, elevation: 103 },
	{ lat: 48, lon: 55.68, elevation: 144 },
	{ lat: 48, lon: 56.76, elevation: 182 },
	{ lat: 48, lon: 57.84, elevation: 208 },
	{ lat: 48, lon: 58.92, elevation: 216 },
	{ lat: 48, lon: 60.0, elevation: 193 },
	{ lat: 48, lon: 61.08, elevation: 89 },
	{ lat: 48, lon: 62.16, elevation: 102 },
	{ lat: 48, lon: 63.24, elevation: 55 },
	{ lat: 48, lon: 64.32, elevation: 121 },
	{ lat: 48, lon: 65.41, elevation: 211 },
	{ lat: 48, lon: 66.49, elevation: 587 },
	{ lat: 48, lon: 67.57, elevation: 378 },
	{ lat: 48, lon: 68.65, elevation: 365 },
	{ lat: 48, lon: 69.73, elevation: 347 },
	{ lat: 48, lon: 70.81, elevation: 470 },
	{ lat: 48, lon: 71.89, elevation: 656 },
	{ lat: 48, lon: 72.97, elevation: 770 },
	{ lat: 48, lon: 74.05, elevation: 792 },
	{ lat: 48, lon: 75.14, elevation: 754 },
	{ lat: 48, lon: 76.22, elevation: 640 },
	{ lat: 48, lon: 77.3, elevation: 795 },
	{ lat: 48, lon: 78.38, elevation: 612 },
	{ lat: 48, lon: 79.46, elevation: 745 },
	{ lat: 48, lon: 80.54, elevation: 667 },
	{ lat: 48, lon: 81.62, elevation: 868 },
	{ lat: 48, lon: 82.7, elevation: 474 },
	{ lat: 48, lon: 83.78, elevation: 388 },
	{ lat: 48, lon: 84.86, elevation: 408 },
	{ lat: 48, lon: 85.95, elevation: 446 },
	{ lat: 48, lon: 87.03, elevation: 847 },
	{ lat: 48, lon: 88.11, elevation: 1508 },
	{ lat: 48, lon: 89.19, elevation: 2630 },
	{ lat: 48, lon: 90.27, elevation: 2634 },
	{ lat: 48, lon: 91.35, elevation: 2007 },
	{ lat: 48, lon: 92.43, elevation: 1171 },
	{ lat: 48, lon: 93.51, elevation: 1156 },
	{ lat: 48, lon: 94.59, elevation: 1974 },
	{ lat: 48, lon: 95.68, elevation: 1897 },
	{ lat: 48, lon: 96.76, elevation: 2711 },
	{ lat: 48, lon: 97.84, elevation: 2418 },
	{ lat: 48, lon: 98.92, elevation: 2620 },
	{ lat: 48, lon: 100.0, elevation: 2395 },
	{ lat: 48, lon: 101.08, elevation: 1755 },
	{ lat: 48, lon: 102.16, elevation: 1759 },
	{ lat: 48, lon: 103.24, elevation: 1564 },
	{ lat: 48, lon: 104.32, elevation: 1016 },
	{ lat: 48, lon: 105.41, elevation: 1309 },
	{ lat: 48, lon: 106.49, elevation: 1345 },
	{ lat: 48, lon: 107.57, elevation: 1747 },
	{ lat: 48, lon: 108.65, elevation: 1451 },
	{ lat: 48, lon: 109.73, elevation: 1403 },
	{ lat: 48, lon: 110.81, elevation: 1210 },
	{ lat: 48, lon: 111.89, elevation: 1080 },
	{ lat: 48, lon: 112.97, elevation: 862 },
	{ lat: 48, lon: 114.05, elevation: 772 },
	{ lat: 48, lon: 115.14, elevation: 859 },
	{ lat: 48, lon: 116.22, elevation: 659 },
	{ lat: 48, lon: 117.3, elevation: 594 },
	{ lat: 48, lon: 118.38, elevation: 623 },
	{ lat: 48, lon: 119.46, elevation: 788 },
	{ lat: 48, lon: 120.54, elevation: 1135 },
	{ lat: 48, lon: 121.62, elevation: 849 },
	{ lat: 48, lon: 122.7, elevation: 316 },
	{ lat: 48, lon: 123.78, elevation: 174 },
	{ lat: 48, lon: 124.86, elevation: 182 },
	{ lat: 48, lon: 125.95, elevation: 216 },
	{ lat: 48, lon: 127.03, elevation: 280 },
	{ lat: 48, lon: 128.11, elevation: 416 },
	{ lat: 48, lon: 129.19, elevation: 703 },
	{ lat: 48, lon: 130.27, elevation: 366 },
	{ lat: 48, lon: 131.35, elevation: 124 },
	{ lat: 48, lon: 132.43, elevation: 59 },
	{ lat: 48, lon: 133.51, elevation: 50 },
	{ lat: 48, lon: 134.59, elevation: 34 },
	{ lat: 48, lon: 135.68, elevation: 112 },
	{ lat: 48, lon: 136.76, elevation: 394 },
	{ lat: 48, lon: 137.84, elevation: 467 },
	{ lat: 48, lon: 138.92, elevation: 747 },
	{ lat: 49, lon: -124.95, elevation: 494 },
	{ lat: 49, lon: -123.85, elevation: 95 },
	{ lat: 49, lon: -122.75, elevation: 17 },
	{ lat: 49, lon: -121.65, elevation: 1338 },
	{ lat: 49, lon: -120.55, elevation: 1230 },
	{ lat: 49, lon: -119.45, elevation: 277 },
	{ lat: 49, lon: -118.35, elevation: 561 },
	{ lat: 49, lon: -117.25, elevation: 1558 },
	{ lat: 49, lon: -116.15, elevation: 1526 },
	{ lat: 49, lon: -115.05, elevation: 921 },
	{ lat: 49, lon: -113.94, elevation: 1702 },
	{ lat: 49, lon: -112.84, elevation: 1338 },
	{ lat: 49, lon: -111.74, elevation: 1025 },
	{ lat: 49, lon: -110.64, elevation: 827 },
	{ lat: 49, lon: -109.54, elevation: 854 },
	{ lat: 49, lon: -108.44, elevation: 909 },
	{ lat: 49, lon: -107.34, elevation: 775 },
	{ lat: 49, lon: -106.24, elevation: 900 },
	{ lat: 49, lon: -105.14, elevation: 787 },
	{ lat: 49, lon: -104.04, elevation: 670 },
	{ lat: 49, lon: -102.94, elevation: 575 },
	{ lat: 49, lon: -101.83, elevation: 536 },
	{ lat: 49, lon: -100.73, elevation: 465 },
	{ lat: 49, lon: -99.63, elevation: 554 },
	{ lat: 49, lon: -98.53, elevation: 467 },
	{ lat: 49, lon: -97.43, elevation: 241 },
	{ lat: 49, lon: -96.33, elevation: 311 },
	{ lat: 49, lon: -95.23, elevation: 320 },
	{ lat: 49, lon: -94.13, elevation: 359 },
	{ lat: 49, lon: -93.03, elevation: 420 },
	{ lat: 49, lon: -91.93, elevation: 425 },
	{ lat: 49, lon: -90.83, elevation: 458 },
	{ lat: 49, lon: -89.72, elevation: 461 },
	{ lat: 49, lon: -88.62, elevation: 315 },
	{ lat: 49, lon: -87.52, elevation: 415 },
	{ lat: 49, lon: -86.42, elevation: 236 },
	{ lat: 49, lon: -85.32, elevation: 345 },
	{ lat: 49, lon: -84.22, elevation: 329 },
	{ lat: 49, lon: -83.12, elevation: 263 },
	{ lat: 49, lon: -82.02, elevation: 269 },
	{ lat: 49, lon: -80.92, elevation: 265 },
	{ lat: 49, lon: -79.82, elevation: 305 },
	{ lat: 49, lon: -78.72, elevation: 347 },
	{ lat: 49, lon: -77.61, elevation: 278 },
	{ lat: 49, lon: -76.51, elevation: 385 },
	{ lat: 49, lon: -75.41, elevation: 383 },
	{ lat: 49, lon: -74.31, elevation: 439 },
	{ lat: 49, lon: -73.21, elevation: 312 },
	{ lat: 49, lon: -72.11, elevation: 197 },
	{ lat: 49, lon: -71.01, elevation: 542 },
	{ lat: 49, lon: -69.91, elevation: 642 },
	{ lat: 49, lon: -68.81, elevation: 88 },
	{ lat: 49, lon: -66.61, elevation: 238 },
	{ lat: 49, lon: -65.5, elevation: 723 },
	{ lat: 49, lon: -64.4, elevation: 60 },
	{ lat: 49, lon: -57.8, elevation: 240 },
	{ lat: 49, lon: -56.7, elevation: 491 },
	{ lat: 49, lon: -55.6, elevation: 101 },
	{ lat: 49, lon: -54.5, elevation: 102 },
	{ lat: 49, lon: -0.55, elevation: 189 },
	{ lat: 49, lon: 0.55, elevation: 180 },
	{ lat: 49, lon: 1.65, elevation: 23 },
	{ lat: 49, lon: 2.75, elevation: 105 },
	{ lat: 49, lon: 3.85, elevation: 235 },
	{ lat: 49, lon: 4.95, elevation: 153 },
	{ lat: 49, lon: 6.06, elevation: 264 },
	{ lat: 49, lon: 7.16, elevation: 272 },
	{ lat: 49, lon: 8.26, elevation: 105 },
	{ lat: 49, lon: 9.36, elevation: 323 },
	{ lat: 49, lon: 10.46, elevation: 497 },
	{ lat: 49, lon: 11.56, elevation: 497 },
	{ lat: 49, lon: 12.66, elevation: 422 },
	{ lat: 49, lon: 13.76, elevation: 978 },
	{ lat: 49, lon: 14.86, elevation: 435 },
	{ lat: 49, lon: 15.96, elevation: 360 },
	{ lat: 49, lon: 17.06, elevation: 235 },
	{ lat: 49, lon: 18.17, elevation: 231 },
	{ lat: 49, lon: 19.27, elevation: 714 },
	{ lat: 49, lon: 20.37, elevation: 584 },
	{ lat: 49, lon: 21.47, elevation: 371 },
	{ lat: 49, lon: 22.57, elevation: 761 },
	{ lat: 49, lon: 23.67, elevation: 696 },
	{ lat: 49, lon: 24.77, elevation: 341 },
	{ lat: 49, lon: 25.87, elevation: 306 },
	{ lat: 49, lon: 26.97, elevation: 296 },
	{ lat: 49, lon: 28.07, elevation: 315 },
	{ lat: 49, lon: 29.17, elevation: 267 },
	{ lat: 49, lon: 30.28, elevation: 246 },
	{ lat: 49, lon: 31.38, elevation: 148 },
	{ lat: 49, lon: 32.48, elevation: 216 },
	{ lat: 49, lon: 33.58, elevation: 62 },
	{ lat: 49, lon: 34.68, elevation: 89 },
	{ lat: 49, lon: 35.78, elevation: 145 },
	{ lat: 49, lon: 36.88, elevation: 149 },
	{ lat: 49, lon: 37.98, elevation: 92 },
	{ lat: 49, lon: 39.08, elevation: 159 },
	{ lat: 49, lon: 40.18, elevation: 117 },
	{ lat: 49, lon: 41.28, elevation: 105 },
	{ lat: 49, lon: 42.39, elevation: 76 },
	{ lat: 49, lon: 43.49, elevation: 141 },
	{ lat: 49, lon: 44.59, elevation: 136 },
	{ lat: 49, lon: 45.69, elevation: 24 },
	{ lat: 49, lon: 46.79, elevation: 19 },
	{ lat: 49, lon: 47.89, elevation: 38 },
	{ lat: 49, lon: 48.99, elevation: -7 },
	{ lat: 49, lon: 50.09, elevation: -7 },
	{ lat: 49, lon: 51.19, elevation: 0 },
	{ lat: 49, lon: 52.29, elevation: -2 },
	{ lat: 49, lon: 53.39, elevation: 20 },
	{ lat: 49, lon: 54.5, elevation: 120 },
	{ lat: 49, lon: 55.6, elevation: 229 },
	{ lat: 49, lon: 56.7, elevation: 237 },
	{ lat: 49, lon: 57.8, elevation: 294 },
	{ lat: 49, lon: 58.9, elevation: 380 },
	{ lat: 49, lon: 60.0, elevation: 208 },
	{ lat: 49, lon: 61.1, elevation: 187 },
	{ lat: 49, lon: 62.2, elevation: 85 },
	{ lat: 49, lon: 63.3, elevation: 116 },
	{ lat: 49, lon: 64.4, elevation: 180 },
	{ lat: 49, lon: 65.5, elevation: 193 },
	{ lat: 49, lon: 66.61, elevation: 399 },
	{ lat: 49, lon: 67.71, elevation: 546 },
	{ lat: 49, lon: 68.81, elevation: 456 },
	{ lat: 49, lon: 69.91, elevation: 451 },
	{ lat: 49, lon: 71.01, elevation: 545 },
	{ lat: 49, lon: 72.11, elevation: 570 },
	{ lat: 49, lon: 73.21, elevation: 859 },
	{ lat: 49, lon: 74.31, elevation: 921 },
	{ lat: 49, lon: 75.41, elevation: 920 },
	{ lat: 49, lon: 76.51, elevation: 969 },
	{ lat: 49, lon: 77.61, elevation: 869 },
	{ lat: 49, lon: 78.72, elevation: 879 },
	{ lat: 49, lon: 79.82, elevation: 594 },
	{ lat: 49, lon: 80.92, elevation: 590 },
	{ lat: 49, lon: 82.02, elevation: 670 },
	{ lat: 49, lon: 83.12, elevation: 721 },
	{ lat: 49, lon: 84.22, elevation: 1559 },
	{ lat: 49, lon: 85.32, elevation: 2074 },
	{ lat: 49, lon: 86.42, elevation: 2677 },
	{ lat: 49, lon: 87.52, elevation: 3081 },
	{ lat: 49, lon: 88.62, elevation: 2421 },
	{ lat: 49, lon: 89.72, elevation: 1956 },
	{ lat: 49, lon: 90.83, elevation: 1803 },
	{ lat: 49, lon: 91.93, elevation: 1868 },
	{ lat: 49, lon: 93.03, elevation: 1334 },
	{ lat: 49, lon: 94.13, elevation: 1511 },
	{ lat: 49, lon: 95.23, elevation: 1493 },
	{ lat: 49, lon: 96.33, elevation: 2056 },
	{ lat: 49, lon: 97.43, elevation: 2253 },
	{ lat: 49, lon: 98.53, elevation: 2292 },
	{ lat: 49, lon: 99.63, elevation: 1903 },
	{ lat: 49, lon: 100.73, elevation: 1307 },
	{ lat: 49, lon: 101.83, elevation: 1389 },
	{ lat: 49, lon: 102.94, elevation: 1710 },
	{ lat: 49, lon: 104.04, elevation: 1378 },
	{ lat: 49, lon: 105.14, elevation: 861 },
	{ lat: 49, lon: 106.24, elevation: 1369 },
	{ lat: 49, lon: 107.34, elevation: 1214 },
	{ lat: 49, lon: 108.44, elevation: 1447 },
	{ lat: 49, lon: 109.54, elevation: 1465 },
	{ lat: 49, lon: 110.64, elevation: 1223 },
	{ lat: 49, lon: 111.74, elevation: 1025 },
	{ lat: 49, lon: 112.84, elevation: 1106 },
	{ lat: 49, lon: 113.94, elevation: 951 },
	{ lat: 49, lon: 115.05, elevation: 804 },
	{ lat: 49, lon: 116.15, elevation: 798 },
	{ lat: 49, lon: 117.25, elevation: 540 },
	{ lat: 49, lon: 118.35, elevation: 669 },
	{ lat: 49, lon: 119.45, elevation: 743 },
	{ lat: 49, lon: 120.55, elevation: 757 },
	{ lat: 49, lon: 121.65, elevation: 807 },
	{ lat: 49, lon: 122.75, elevation: 566 },
	{ lat: 49, lon: 123.85, elevation: 357 },
	{ lat: 49, lon: 124.95, elevation: 255 },
	{ lat: 49, lon: 126.06, elevation: 290 },
	{ lat: 49, lon: 127.16, elevation: 403 },
	{ lat: 49, lon: 128.26, elevation: 413 },
	{ lat: 49, lon: 129.36, elevation: 298 },
	{ lat: 49, lon: 130.46, elevation: 93 },
	{ lat: 49, lon: 131.56, elevation: 242 },
	{ lat: 49, lon: 132.66, elevation: 169 },
	{ lat: 49, lon: 133.76, elevation: 61 },
	{ lat: 49, lon: 134.86, elevation: 155 },
	{ lat: 49, lon: 135.96, elevation: 27 },
	{ lat: 49, lon: 137.06, elevation: 461 },
	{ lat: 49, lon: 138.17, elevation: 604 },
	{ lat: 49, lon: 139.27, elevation: 828 },
	{ lat: 49, lon: 142.57, elevation: 483 },
	{ lat: 50, lon: -127.12, elevation: 203 },
	{ lat: 50, lon: -126.0, elevation: 1175 },
	{ lat: 50, lon: -123.75, elevation: 1734 },
	{ lat: 50, lon: -122.62, elevation: 1904 },
	{ lat: 50, lon: -121.5, elevation: 383 },
	{ lat: 50, lon: -120.38, elevation: 1128 },
	{ lat: 50, lon: -119.25, elevation: 1264 },
	{ lat: 50, lon: -118.12, elevation: 1232 },
	{ lat: 50, lon: -117.0, elevation: 1555 },
	{ lat: 50, lon: -115.88, elevation: 1156 },
	{ lat: 50, lon: -114.75, elevation: 2385 },
	{ lat: 50, lon: -113.62, elevation: 1013 },
	{ lat: 50, lon: -112.5, elevation: 828 },
	{ lat: 50, lon: -111.38, elevation: 755 },
	{ lat: 50, lon: -110.25, elevation: 786 },
	{ lat: 50, lon: -109.12, elevation: 749 },
	{ lat: 50, lon: -108.0, elevation: 868 },
	{ lat: 50, lon: -106.88, elevation: 720 },
	{ lat: 50, lon: -105.75, elevation: 699 },
	{ lat: 50, lon: -104.62, elevation: 574 },
	{ lat: 50, lon: -103.5, elevation: 640 },
	{ lat: 50, lon: -102.38, elevation: 676 },
	{ lat: 50, lon: -101.25, elevation: 497 },
	{ lat: 50, lon: -100.12, elevation: 469 },
	{ lat: 50, lon: -99.0, elevation: 308 },
	{ lat: 50, lon: -97.88, elevation: 245 },
	{ lat: 50, lon: -96.75, elevation: 240 },
	{ lat: 50, lon: -95.62, elevation: 304 },
	{ lat: 50, lon: -94.5, elevation: 332 },
	{ lat: 50, lon: -93.38, elevation: 332 },
	{ lat: 50, lon: -92.25, elevation: 396 },
	{ lat: 50, lon: -91.12, elevation: 425 },
	{ lat: 50, lon: -90.0, elevation: 456 },
	{ lat: 50, lon: -88.88, elevation: 258 },
	{ lat: 50, lon: -87.75, elevation: 316 },
	{ lat: 50, lon: -86.62, elevation: 331 },
	{ lat: 50, lon: -85.5, elevation: 229 },
	{ lat: 50, lon: -84.38, elevation: 140 },
	{ lat: 50, lon: -83.25, elevation: 207 },
	{ lat: 50, lon: -82.12, elevation: 193 },
	{ lat: 50, lon: -81.0, elevation: 237 },
	{ lat: 50, lon: -79.88, elevation: 295 },
	{ lat: 50, lon: -78.75, elevation: 259 },
	{ lat: 50, lon: -77.62, elevation: 264 },
	{ lat: 50, lon: -76.5, elevation: 274 },
	{ lat: 50, lon: -75.38, elevation: 345 },
	{ lat: 50, lon: -74.25, elevation: 416 },
	{ lat: 50, lon: -73.12, elevation: 430 },
	{ lat: 50, lon: -72.0, elevation: 540 },
	{ lat: 50, lon: -70.88, elevation: 409 },
	{ lat: 50, lon: -69.75, elevation: 458 },
	{ lat: 50, lon: -68.62, elevation: 203 },
	{ lat: 50, lon: -67.5, elevation: 479 },
	{ lat: 50, lon: -57.38, elevation: 700 },
	{ lat: 50, lon: -56.25, elevation: 282 },
	{ lat: 50, lon: 2.25, elevation: 126 },
	{ lat: 50, lon: 3.38, elevation: 125 },
	{ lat: 50, lon: 4.5, elevation: 370 },
	{ lat: 50, lon: 5.62, elevation: 517 },
	{ lat: 50, lon: 6.75, elevation: 336 },
	{ lat: 50, lon: 7.88, elevation: 310 },
	{ lat: 50, lon: 9.0, elevation: 140 },
	{ lat: 50, lon: 10.12, elevation: 223 },
	{ lat: 50, lon: 11.25, elevation: 471 },
	{ lat: 50, lon: 12.38, elevation: 549 },
	{ lat: 50, lon: 13.5, elevation: 481 },
	{ lat: 50, lon: 14.62, elevation: 320 },
	{ lat: 50, lon: 15.75, elevation: 229 },
	{ lat: 50, lon: 16.88, elevation: 388 },
	{ lat: 50, lon: 18.0, elevation: 267 },
	{ lat: 50, lon: 19.12, elevation: 230 },
	{ lat: 50, lon: 20.25, elevation: 238 },
	{ lat: 50, lon: 21.38, elevation: 334 },
	{ lat: 50, lon: 22.5, elevation: 215 },
	{ lat: 50, lon: 23.62, elevation: 339 },
	{ lat: 50, lon: 24.75, elevation: 238 },
	{ lat: 50, lon: 25.88, elevation: 304 },
	{ lat: 50, lon: 27.0, elevation: 279 },
	{ lat: 50, lon: 28.12, elevation: 251 },
	{ lat: 50, lon: 29.25, elevation: 213 },
	{ lat: 50, lon: 30.38, elevation: 188 },
	{ lat: 50, lon: 31.5, elevation: 87 },
	{ lat: 50, lon: 32.62, elevation: 109 },
	{ lat: 50, lon: 33.75, elevation: 117 },
	{ lat: 50, lon: 34.88, elevation: 135 },
	{ lat: 50, lon: 36.0, elevation: 104 },
	{ lat: 50, lon: 37.12, elevation: 164 },
	{ lat: 50, lon: 38.25, elevation: 168 },
	{ lat: 50, lon: 39.38, elevation: 126 },
	{ lat: 50, lon: 40.5, elevation: 203 },
	{ lat: 50, lon: 41.62, elevation: 168 },
	{ lat: 50, lon: 42.75, elevation: 119 },
	{ lat: 50, lon: 43.88, elevation: 166 },
	{ lat: 50, lon: 45.0, elevation: 105 },
	{ lat: 50, lon: 46.12, elevation: 27 },
	{ lat: 50, lon: 47.25, elevation: 38 },
	{ lat: 50, lon: 48.38, elevation: 19 },
	{ lat: 50, lon: 49.5, elevation: 7 },
	{ lat: 50, lon: 50.62, elevation: 9 },
	{ lat: 50, lon: 51.75, elevation: 10 },
	{ lat: 50, lon: 52.88, elevation: 26 },
	{ lat: 50, lon: 54.0, elevation: 92 },
	{ lat: 50, lon: 55.12, elevation: 218 },
	{ lat: 50, lon: 56.25, elevation: 180 },
	{ lat: 50, lon: 57.38, elevation: 233 },
	{ lat: 50, lon: 58.5, elevation: 327 },
	{ lat: 50, lon: 59.62, elevation: 313 },
	{ lat: 50, lon: 60.75, elevation: 238 },
	{ lat: 50, lon: 61.88, elevation: 168 },
	{ lat: 50, lon: 63.0, elevation: 131 },
	{ lat: 50, lon: 64.12, elevation: 102 },
	{ lat: 50, lon: 65.25, elevation: 162 },
	{ lat: 50, lon: 66.38, elevation: 248 },
	{ lat: 50, lon: 67.5, elevation: 384 },
	{ lat: 50, lon: 68.62, elevation: 361 },
	{ lat: 50, lon: 69.75, elevation: 377 },
	{ lat: 50, lon: 70.88, elevation: 380 },
	{ lat: 50, lon: 72.0, elevation: 534 },
	{ lat: 50, lon: 73.12, elevation: 547 },
	{ lat: 50, lon: 74.25, elevation: 598 },
	{ lat: 50, lon: 75.38, elevation: 743 },
	{ lat: 50, lon: 76.5, elevation: 593 },
	{ lat: 50, lon: 77.62, elevation: 458 },
	{ lat: 50, lon: 78.75, elevation: 316 },
	{ lat: 50, lon: 79.88, elevation: 306 },
	{ lat: 50, lon: 81.0, elevation: 426 },
	{ lat: 50, lon: 82.12, elevation: 454 },
	{ lat: 50, lon: 83.25, elevation: 686 },
	{ lat: 50, lon: 84.38, elevation: 757 },
	{ lat: 50, lon: 85.5, elevation: 1837 },
	{ lat: 50, lon: 86.62, elevation: 2407 },
	{ lat: 50, lon: 87.75, elevation: 2692 },
	{ lat: 50, lon: 88.88, elevation: 2012 },
	{ lat: 50, lon: 90.0, elevation: 1870 },
	{ lat: 50, lon: 91.12, elevation: 2006 },
	{ lat: 50, lon: 92.25, elevation: 838 },
	{ lat: 50, lon: 93.38, elevation: 875 },
	{ lat: 50, lon: 94.5, elevation: 1090 },
	{ lat: 50, lon: 95.62, elevation: 1378 },
	{ lat: 50, lon: 96.75, elevation: 1980 },
	{ lat: 50, lon: 97.88, elevation: 2173 },
	{ lat: 50, lon: 99.0, elevation: 2060 },
	{ lat: 50, lon: 100.12, elevation: 1882 },
	{ lat: 50, lon: 101.25, elevation: 1283 },
	{ lat: 50, lon: 102.38, elevation: 1000 },
	{ lat: 50, lon: 103.5, elevation: 1777 },
	{ lat: 50, lon: 104.62, elevation: 1268 },
	{ lat: 50, lon: 105.75, elevation: 643 },
	{ lat: 50, lon: 106.88, elevation: 939 },
	{ lat: 50, lon: 108.0, elevation: 1031 },
	{ lat: 50, lon: 109.12, elevation: 1239 },
	{ lat: 50, lon: 110.25, elevation: 1439 },
	{ lat: 50, lon: 111.38, elevation: 1697 },
	{ lat: 50, lon: 112.5, elevation: 1223 },
	{ lat: 50, lon: 113.62, elevation: 974 },
	{ lat: 50, lon: 114.75, elevation: 810 },
	{ lat: 50, lon: 115.88, elevation: 604 },
	{ lat: 50, lon: 117.0, elevation: 715 },
	{ lat: 50, lon: 118.12, elevation: 733 },
	{ lat: 50, lon: 119.25, elevation: 524 },
	{ lat: 50, lon: 120.38, elevation: 947 },
	{ lat: 50, lon: 121.5, elevation: 809 },
	{ lat: 50, lon: 122.62, elevation: 865 },
	{ lat: 50, lon: 123.75, elevation: 412 },
	{ lat: 50, lon: 124.88, elevation: 344 },
	{ lat: 50, lon: 126.0, elevation: 531 },
	{ lat: 50, lon: 127.12, elevation: 321 },
	{ lat: 50, lon: 128.25, elevation: 174 },
	{ lat: 50, lon: 129.38, elevation: 243 },
	{ lat: 50, lon: 130.5, elevation: 354 },
	{ lat: 50, lon: 131.62, elevation: 324 },
	{ lat: 50, lon: 132.75, elevation: 461 },
	{ lat: 50, lon: 133.88, elevation: 742 },
	{ lat: 50, lon: 135.0, elevation: 866 },
	{ lat: 50, lon: 136.12, elevation: 69 },
	{ lat: 50, lon: 137.25, elevation: 44 },
	{ lat: 50, lon: 138.38, elevation: 512 },
	{ lat: 50, lon: 139.5, elevation: 870 },
	{ lat: 50, lon: 142.88, elevation: 90 },
	{ lat: 51, lon: -127.09, elevation: 171 },
	{ lat: 51, lon: -125.94, elevation: 427 },
	{ lat: 51, lon: -124.79, elevation: 1327 },
	{ lat: 51, lon: -123.64, elevation: 1904 },
	{ lat: 51, lon: -122.49, elevation: 1998 },
	{ lat: 51, lon: -121.34, elevation: 1349 },
	{ lat: 51, lon: -120.19, elevation: 845 },
	{ lat: 51, lon: -119.04, elevation: 348 },
	{ lat: 51, lon: -117.89, elevation: 2080 },
	{ lat: 51, lon: -116.74, elevation: 1520 },
	{ lat: 51, lon: -115.59, elevation: 2433 },
	{ lat: 51, lon: -114.44, elevation: 1230 },
	{ lat: 51, lon: -113.29, elevation: 948 },
	{ lat: 51, lon: -112.14, elevation: 766 },
	{ lat: 51, lon: -110.99, elevation: 724 },
	{ lat: 51, lon: -109.84, elevation: 730 },
	{ lat: 51, lon: -108.69, elevation: 639 },
	{ lat: 51, lon: -107.54, elevation: 727 },
	{ lat: 51, lon: -106.39, elevation: 571 },
	{ lat: 51, lon: -105.24, elevation: 491 },
	{ lat: 51, lon: -104.09, elevation: 624 },
	{ lat: 51, lon: -102.94, elevation: 577 },
	{ lat: 51, lon: -101.79, elevation: 526 },
	{ lat: 51, lon: -100.64, elevation: 572 },
	{ lat: 51, lon: -99.49, elevation: 281 },
	{ lat: 51, lon: -98.34, elevation: 255 },
	{ lat: 51, lon: -97.19, elevation: 228 },
	{ lat: 51, lon: -96.04, elevation: 260 },
	{ lat: 51, lon: -94.89, elevation: 355 },
	{ lat: 51, lon: -93.74, elevation: 382 },
	{ lat: 51, lon: -92.59, elevation: 424 },
	{ lat: 51, lon: -91.44, elevation: 399 },
	{ lat: 51, lon: -90.29, elevation: 376 },
	{ lat: 51, lon: -89.14, elevation: 378 },
	{ lat: 51, lon: -87.99, elevation: 325 },
	{ lat: 51, lon: -86.84, elevation: 261 },
	{ lat: 51, lon: -85.69, elevation: 167 },
	{ lat: 51, lon: -84.54, elevation: 99 },
	{ lat: 51, lon: -83.39, elevation: 108 },
	{ lat: 51, lon: -82.24, elevation: 105 },
	{ lat: 51, lon: -81.09, elevation: 27 },
	{ lat: 51, lon: -79.94, elevation: 26 },
	{ lat: 51, lon: -78.79, elevation: 81 },
	{ lat: 51, lon: -77.64, elevation: 259 },
	{ lat: 51, lon: -76.49, elevation: 253 },
	{ lat: 51, lon: -75.34, elevation: 320 },
	{ lat: 51, lon: -74.19, elevation: 383 },
	{ lat: 51, lon: -73.04, elevation: 427 },
	{ lat: 51, lon: -71.88, elevation: 569 },
	{ lat: 51, lon: -70.73, elevation: 579 },
	{ lat: 51, lon: -69.58, elevation: 433 },
	{ lat: 51, lon: -68.43, elevation: 766 },
	{ lat: 51, lon: -67.28, elevation: 586 },
	{ lat: 51, lon: -66.13, elevation: 540 },
	{ lat: 51, lon: -64.98, elevation: 744 },
	{ lat: 51, lon: -63.83, elevation: 662 },
	{ lat: 51, lon: -62.68, elevation: 561 },
	{ lat: 51, lon: -61.53, elevation: 406 },
	{ lat: 51, lon: -60.38, elevation: 270 },
	{ lat: 51, lon: -59.23, elevation: 57 },
	{ lat: 51, lon: -56.93, elevation: 6 },
	{ lat: 51, lon: -4.03, elevation: 56 },
	{ lat: 51, lon: -2.88, elevation: 12 },
	{ lat: 51, lon: -1.73, elevation: 89 },
	{ lat: 51, lon: -0.58, elevation: 60 },
	{ lat: 51, lon: 0.58, elevation: 1 },
	{ lat: 51, lon: 2.88, elevation: 5 },
	{ lat: 51, lon: 4.03, elevation: 6 },
	{ lat: 51, lon: 5.18, elevation: 27 },
	{ lat: 51, lon: 6.33, elevation: 100 },
	{ lat: 51, lon: 7.48, elevation: 269 },
	{ lat: 51, lon: 8.63, elevation: 451 },
	{ lat: 51, lon: 9.78, elevation: 281 },
	{ lat: 51, lon: 10.93, elevation: 324 },
	{ lat: 51, lon: 12.08, elevation: 271 },
	{ lat: 51, lon: 13.23, elevation: 330 },
	{ lat: 51, lon: 14.38, elevation: 438 },
	{ lat: 51, lon: 15.53, elevation: 375 },
	{ lat: 51, lon: 16.68, elevation: 150 },
	{ lat: 51, lon: 17.83, elevation: 159 },
	{ lat: 51, lon: 18.98, elevation: 229 },
	{ lat: 51, lon: 20.13, elevation: 252 },
	{ lat: 51, lon: 21.28, elevation: 206 },
	{ lat: 51, lon: 22.43, elevation: 216 },
	{ lat: 51, lon: 23.58, elevation: 210 },
	{ lat: 51, lon: 24.73, elevation: 193 },
	{ lat: 51, lon: 25.88, elevation: 187 },
	{ lat: 51, lon: 27.03, elevation: 197 },
	{ lat: 51, lon: 28.18, elevation: 198 },
	{ lat: 51, lon: 29.33, elevation: 161 },
	{ lat: 51, lon: 30.48, elevation: 98 },
	{ lat: 51, lon: 31.63, elevation: 120 },
	{ lat: 51, lon: 32.78, elevation: 133 },
	{ lat: 51, lon: 33.93, elevation: 167 },
	{ lat: 51, lon: 35.08, elevation: 131 },
	{ lat: 51, lon: 36.23, elevation: 205 },
	{ lat: 51, lon: 37.38, elevation: 221 },
	{ lat: 51, lon: 38.53, elevation: 211 },
	{ lat: 51, lon: 39.68, elevation: 131 },
	{ lat: 51, lon: 40.83, elevation: 215 },
	{ lat: 51, lon: 41.98, elevation: 134 },
	{ lat: 51, lon: 43.13, elevation: 141 },
	{ lat: 51, lon: 44.28, elevation: 184 },
	{ lat: 51, lon: 45.43, elevation: 256 },
	{ lat: 51, lon: 46.58, elevation: 80 },
	{ lat: 51, lon: 47.73, elevation: 51 },
	{ lat: 51, lon: 48.88, elevation: 53 },
	{ lat: 51, lon: 50.03, elevation: 85 },
	{ lat: 51, lon: 51.18, elevation: 25 },
	{ lat: 51, lon: 52.33, elevation: 174 },
	{ lat: 51, lon: 53.48, elevation: 188 },
	{ lat: 51, lon: 54.63, elevation: 121 },
	{ lat: 51, lon: 55.78, elevation: 206 },
	{ lat: 51, lon: 56.93, elevation: 331 },
	{ lat: 51, lon: 58.08, elevation: 391 },
	{ lat: 51, lon: 59.23, elevation: 255 },
	{ lat: 51, lon: 60.38, elevation: 334 },
	{ lat: 51, lon: 61.53, elevation: 248 },
	{ lat: 51, lon: 62.68, elevation: 258 },
	{ lat: 51, lon: 63.83, elevation: 209 },
	{ lat: 51, lon: 64.98, elevation: 141 },
	{ lat: 51, lon: 66.13, elevation: 280 },
	{ lat: 51, lon: 67.28, elevation: 264 },
	{ lat: 51, lon: 68.43, elevation: 348 },
	{ lat: 51, lon: 69.58, elevation: 351 },
	{ lat: 51, lon: 70.73, elevation: 352 },
	{ lat: 51, lon: 71.88, elevation: 372 },
	{ lat: 51, lon: 73.04, elevation: 534 },
	{ lat: 51, lon: 74.19, elevation: 442 },
	{ lat: 51, lon: 75.34, elevation: 342 },
	{ lat: 51, lon: 76.49, elevation: 208 },
	{ lat: 51, lon: 77.64, elevation: 159 },
	{ lat: 51, lon: 78.79, elevation: 180 },
	{ lat: 51, lon: 79.94, elevation: 241 },
	{ lat: 51, lon: 81.09, elevation: 242 },
	{ lat: 51, lon: 82.24, elevation: 427 },
	{ lat: 51, lon: 83.39, elevation: 1431 },
	{ lat: 51, lon: 84.54, elevation: 1258 },
	{ lat: 51, lon: 85.69, elevation: 1470 },
	{ lat: 51, lon: 86.84, elevation: 1220 },
	{ lat: 51, lon: 87.99, elevation: 1320 },
	{ lat: 51, lon: 89.14, elevation: 2559 },
	{ lat: 51, lon: 90.29, elevation: 980 },
	{ lat: 51, lon: 91.44, elevation: 1313 },
	{ lat: 51, lon: 92.59, elevation: 1710 },
	{ lat: 51, lon: 93.74, elevation: 1710 },
	{ lat: 51, lon: 94.89, elevation: 1023 },
	{ lat: 51, lon: 96.04, elevation: 1591 },
	{ lat: 51, lon: 97.19, elevation: 1600 },
	{ lat: 51, lon: 98.34, elevation: 2447 },
	{ lat: 51, lon: 99.49, elevation: 1554 },
	{ lat: 51, lon: 100.64, elevation: 1642 },
	{ lat: 51, lon: 101.79, elevation: 1902 },
	{ lat: 51, lon: 102.94, elevation: 1684 },
	{ lat: 51, lon: 104.09, elevation: 1140 },
	{ lat: 51, lon: 105.24, elevation: 1486 },
	{ lat: 51, lon: 106.39, elevation: 546 },
	{ lat: 51, lon: 107.54, elevation: 724 },
	{ lat: 51, lon: 108.69, elevation: 832 },
	{ lat: 51, lon: 109.84, elevation: 1199 },
	{ lat: 51, lon: 110.99, elevation: 1024 },
	{ lat: 51, lon: 112.14, elevation: 1038 },
	{ lat: 51, lon: 113.29, elevation: 958 },
	{ lat: 51, lon: 114.44, elevation: 784 },
	{ lat: 51, lon: 115.59, elevation: 556 },
	{ lat: 51, lon: 116.74, elevation: 937 },
	{ lat: 51, lon: 117.89, elevation: 870 },
	{ lat: 51, lon: 119.04, elevation: 686 },
	{ lat: 51, lon: 120.19, elevation: 909 },
	{ lat: 51, lon: 121.34, elevation: 1071 },
	{ lat: 51, lon: 122.49, elevation: 1059 },
	{ lat: 51, lon: 123.64, elevation: 769 },
	{ lat: 51, lon: 124.79, elevation: 608 },
	{ lat: 51, lon: 125.94, elevation: 491 },
	{ lat: 51, lon: 127.09, elevation: 280 },
	{ lat: 51, lon: 128.24, elevation: 158 },
	{ lat: 51, lon: 129.39, elevation: 189 },
	{ lat: 51, lon: 130.54, elevation: 296 },
	{ lat: 51, lon: 131.69, elevation: 530 },
	{ lat: 51, lon: 132.84, elevation: 338 },
	{ lat: 51, lon: 133.99, elevation: 1040 },
	{ lat: 51, lon: 135.14, elevation: 952 },
	{ lat: 51, lon: 136.29, elevation: 395 },
	{ lat: 51, lon: 137.44, elevation: 27 },
	{ lat: 51, lon: 138.59, elevation: 548 },
	{ lat: 51, lon: 139.74, elevation: 746 },
	{ lat: 51, lon: 143.19, elevation: 551 },
	{ lat: 52, lon: -127.06, elevation: 1026 },
	{ lat: 52, lon: -125.88, elevation: 1607 },
	{ lat: 52, lon: -124.71, elevation: 1466 },
	{ lat: 52, lon: -123.53, elevation: 1062 },
	{ lat: 52, lon: -122.35, elevation: 874 },
	{ lat: 52, lon: -121.18, elevation: 954 },
	{ lat: 52, lon: -120.0, elevation: 1023 },
	{ lat: 52, lon: -118.82, elevation: 1884 },
	{ lat: 52, lon: -117.65, elevation: 1803 },
	{ lat: 52, lon: -116.47, elevation: 1350 },
	{ lat: 52, lon: -115.29, elevation: 1496 },
	{ lat: 52, lon: -114.12, elevation: 941 },
	{ lat: 52, lon: -112.94, elevation: 833 },
	{ lat: 52, lon: -111.76, elevation: 805 },
	{ lat: 52, lon: -110.59, elevation: 709 },
	{ lat: 52, lon: -109.41, elevation: 691 },
	{ lat: 52, lon: -108.24, elevation: 680 },
	{ lat: 52, lon: -107.06, elevation: 507 },
	{ lat: 52, lon: -105.88, elevation: 536 },
	{ lat: 52, lon: -104.71, elevation: 545 },
	{ lat: 52, lon: -103.53, elevation: 558 },
	{ lat: 52, lon: -102.35, elevation: 528 },
	{ lat: 52, lon: -101.18, elevation: 402 },
	{ lat: 52, lon: -100.0, elevation: 266 },
	{ lat: 52, lon: -98.82, elevation: 265 },
	{ lat: 52, lon: -97.65, elevation: 228 },
	{ lat: 52, lon: -96.47, elevation: 250 },
	{ lat: 52, lon: -95.29, elevation: 313 },
	{ lat: 52, lon: -94.12, elevation: 318 },
	{ lat: 52, lon: -92.94, elevation: 367 },
	{ lat: 52, lon: -91.76, elevation: 432 },
	{ lat: 52, lon: -90.59, elevation: 389 },
	{ lat: 52, lon: -89.41, elevation: 314 },
	{ lat: 52, lon: -88.24, elevation: 287 },
	{ lat: 52, lon: -87.06, elevation: 225 },
	{ lat: 52, lon: -85.88, elevation: 178 },
	{ lat: 52, lon: -84.71, elevation: 136 },
	{ lat: 52, lon: -83.53, elevation: 81 },
	{ lat: 52, lon: -82.35, elevation: 48 },
	{ lat: 52, lon: -81.18, elevation: 5 },
	{ lat: 52, lon: -77.65, elevation: 203 },
	{ lat: 52, lon: -76.47, elevation: 228 },
	{ lat: 52, lon: -75.29, elevation: 311 },
	{ lat: 52, lon: -74.12, elevation: 340 },
	{ lat: 52, lon: -72.94, elevation: 420 },
	{ lat: 52, lon: -71.76, elevation: 574 },
	{ lat: 52, lon: -70.59, elevation: 780 },
	{ lat: 52, lon: -69.41, elevation: 446 },
	{ lat: 52, lon: -68.24, elevation: 552 },
	{ lat: 52, lon: -67.06, elevation: 530 },
	{ lat: 52, lon: -65.88, elevation: 689 },
	{ lat: 52, lon: -64.71, elevation: 575 },
	{ lat: 52, lon: -63.53, elevation: 497 },
	{ lat: 52, lon: -62.35, elevation: 422 },
	{ lat: 52, lon: -61.18, elevation: 483 },
	{ lat: 52, lon: -60.0, elevation: 398 },
	{ lat: 52, lon: -58.82, elevation: 395 },
	{ lat: 52, lon: -57.65, elevation: 465 },
	{ lat: 52, lon: -56.47, elevation: 222 },
	{ lat: 52, lon: -55.29, elevation: 90 },
	{ lat: 52, lon: -9.41, elevation: 75 },
	{ lat: 52, lon: -8.24, elevation: 146 },
	{ lat: 52, lon: -4.71, elevation: 124 },
	{ lat: 52, lon: -3.53, elevation: 285 },
	{ lat: 52, lon: -2.35, elevation: 51 },
	{ lat: 52, lon: -1.18, elevation: 122 },
	{ lat: 52, lon: 0.0, elevation: 143 },
	{ lat: 52, lon: 1.18, elevation: 33 },
	{ lat: 52, lon: 4.71, elevation: 2 },
	{ lat: 52, lon: 5.88, elevation: 75 },
	{ lat: 52, lon: 7.06, elevation: 70 },
	{ lat: 52, lon: 8.24, elevation: 68 },
	{ lat: 52, lon: 9.41, elevation: 131 },
	{ lat: 52, lon: 10.59, elevation: 120 },
	{ lat: 52, lon: 11.76, elevation: 49 },
	{ lat: 52, lon: 12.94, elevation: 92 },
	{ lat: 52, lon: 14.12, elevation: 52 },
	{ lat: 52, lon: 15.29, elevation: 84 },
	{ lat: 52, lon: 16.47, elevation: 106 },
	{ lat: 52, lon: 17.65, elevation: 106 },
	{ lat: 52, lon: 18.82, elevation: 108 },
	{ lat: 52, lon: 20.0, elevation: 113 },
	{ lat: 52, lon: 21.18, elevation: 114 },
	{ lat: 52, lon: 22.35, elevation: 180 },
	{ lat: 52, lon: 23.53, elevation: 137 },
	{ lat: 52, lon: 24.71, elevation: 145 },
	{ lat: 52, lon: 25.88, elevation: 145 },
	{ lat: 52, lon: 27.06, elevation: 130 },
	{ lat: 52, lon: 28.24, elevation: 124 },
	{ lat: 52, lon: 29.41, elevation: 122 },
	{ lat: 52, lon: 30.59, elevation: 143 },
	{ lat: 52, lon: 31.76, elevation: 142 },
	{ lat: 52, lon: 32.94, elevation: 173 },
	{ lat: 52, lon: 34.12, elevation: 186 },
	{ lat: 52, lon: 35.29, elevation: 190 },
	{ lat: 52, lon: 36.47, elevation: 204 },
	{ lat: 52, lon: 37.65, elevation: 201 },
	{ lat: 52, lon: 38.82, elevation: 178 },
	{ lat: 52, lon: 40.0, elevation: 153 },
	{ lat: 52, lon: 41.18, elevation: 168 },
	{ lat: 52, lon: 42.35, elevation: 115 },
	{ lat: 52, lon: 43.53, elevation: 174 },
	{ lat: 52, lon: 44.71, elevation: 233 },
	{ lat: 52, lon: 45.88, elevation: 137 },
	{ lat: 52, lon: 47.06, elevation: 109 },
	{ lat: 52, lon: 48.24, elevation: 49 },
	{ lat: 52, lon: 49.41, elevation: 39 },
	{ lat: 52, lon: 50.59, elevation: 144 },
	{ lat: 52, lon: 51.76, elevation: 85 },
	{ lat: 52, lon: 52.94, elevation: 151 },
	{ lat: 52, lon: 54.12, elevation: 279 },
	{ lat: 52, lon: 55.29, elevation: 129 },
	{ lat: 52, lon: 56.47, elevation: 254 },
	{ lat: 52, lon: 57.65, elevation: 520 },
	{ lat: 52, lon: 58.82, elevation: 283 },
	{ lat: 52, lon: 60.0, elevation: 367 },
	{ lat: 52, lon: 61.18, elevation: 310 },
	{ lat: 52, lon: 62.35, elevation: 244 },
	{ lat: 52, lon: 63.53, elevation: 211 },
	{ lat: 52, lon: 64.71, elevation: 127 },
	{ lat: 52, lon: 65.88, elevation: 258 },
	{ lat: 52, lon: 67.06, elevation: 336 },
	{ lat: 52, lon: 68.24, elevation: 301 },
	{ lat: 52, lon: 69.41, elevation: 341 },
	{ lat: 52, lon: 70.59, elevation: 340 },
	{ lat: 52, lon: 71.76, elevation: 306 },
	{ lat: 52, lon: 72.94, elevation: 284 },
	{ lat: 52, lon: 74.12, elevation: 217 },
	{ lat: 52, lon: 75.29, elevation: 144 },
	{ lat: 52, lon: 76.47, elevation: 125 },
	{ lat: 52, lon: 77.65, elevation: 151 },
	{ lat: 52, lon: 78.82, elevation: 166 },
	{ lat: 52, lon: 80.0, elevation: 214 },
	{ lat: 52, lon: 81.18, elevation: 251 },
	{ lat: 52, lon: 82.35, elevation: 212 },
	{ lat: 52, lon: 83.53, elevation: 238 },
	{ lat: 52, lon: 84.71, elevation: 230 },
	{ lat: 52, lon: 85.88, elevation: 258 },
	{ lat: 52, lon: 87.06, elevation: 464 },
	{ lat: 52, lon: 88.24, elevation: 859 },
	{ lat: 52, lon: 89.41, elevation: 1898 },
	{ lat: 52, lon: 90.59, elevation: 1686 },
	{ lat: 52, lon: 91.76, elevation: 847 },
	{ lat: 52, lon: 92.94, elevation: 1185 },
	{ lat: 52, lon: 94.12, elevation: 1222 },
	{ lat: 52, lon: 95.29, elevation: 1758 },
	{ lat: 52, lon: 96.47, elevation: 1299 },
	{ lat: 52, lon: 97.65, elevation: 1734 },
	{ lat: 52, lon: 98.82, elevation: 2295 },
	{ lat: 52, lon: 100.0, elevation: 2023 },
	{ lat: 52, lon: 101.18, elevation: 2033 },
	{ lat: 52, lon: 102.35, elevation: 2227 },
	{ lat: 52, lon: 103.53, elevation: 910 },
	{ lat: 52, lon: 104.71, elevation: 548 },
	{ lat: 52, lon: 105.88, elevation: 449 },
	{ lat: 52, lon: 107.06, elevation: 684 },
	{ lat: 52, lon: 108.24, elevation: 555 },
	{ lat: 52, lon: 109.41, elevation: 990 },
	{ lat: 52, lon: 110.59, elevation: 818 },
	{ lat: 52, lon: 111.76, elevation: 1287 },
	{ lat: 52, lon: 112.94, elevation: 974 },
	{ lat: 52, lon: 114.12, elevation: 782 },
	{ lat: 52, lon: 115.29, elevation: 868 },
	{ lat: 52, lon: 116.47, elevation: 605 },
	{ lat: 52, lon: 117.65, elevation: 528 },
	{ lat: 52, lon: 118.82, elevation: 889 },
	{ lat: 52, lon: 120.0, elevation: 756 },
	{ lat: 52, lon: 121.18, elevation: 849 },
	{ lat: 52, lon: 122.35, elevation: 717 },
	{ lat: 52, lon: 123.53, elevation: 642 },
	{ lat: 52, lon: 124.71, elevation: 486 },
	{ lat: 52, lon: 125.88, elevation: 250 },
	{ lat: 52, lon: 127.06, elevation: 302 },
	{ lat: 52, lon: 128.24, elevation: 267 },
	{ lat: 52, lon: 129.41, elevation: 181 },
	{ lat: 52, lon: 130.59, elevation: 251 },
	{ lat: 52, lon: 131.76, elevation: 1025 },
	{ lat: 52, lon: 132.94, elevation: 1040 },
	{ lat: 52, lon: 134.12, elevation: 1007 },
	{ lat: 52, lon: 135.29, elevation: 509 },
	{ lat: 52, lon: 136.47, elevation: 68 },
	{ lat: 52, lon: 137.65, elevation: 329 },
	{ lat: 52, lon: 138.82, elevation: 538 },
	{ lat: 52, lon: 140.0, elevation: 11 },
	{ lat: 52, lon: 141.18, elevation: 222 },
	{ lat: 52, lon: 142.35, elevation: 144 },
	{ lat: 52, lon: 157.65, elevation: 176 },
	{ lat: 53, lon: -131.84, elevation: 40 },
	{ lat: 53, lon: -128.23, elevation: 714 },
	{ lat: 53, lon: -127.02, elevation: 1255 },
	{ lat: 53, lon: -125.82, elevation: 996 },
	{ lat: 53, lon: -124.62, elevation: 1317 },
	{ lat: 53, lon: -123.41, elevation: 974 },
	{ lat: 53, lon: -122.21, elevation: 970 },
	{ lat: 53, lon: -121.0, elevation: 1732 },
	{ lat: 53, lon: -119.8, elevation: 2011 },
	{ lat: 53, lon: -118.6, elevation: 2063 },
	{ lat: 53, lon: -117.39, elevation: 2130 },
	{ lat: 53, lon: -116.19, elevation: 1180 },
	{ lat: 53, lon: -114.98, elevation: 928 },
	{ lat: 53, lon: -113.78, elevation: 826 },
	{ lat: 53, lon: -112.58, elevation: 715 },
	{ lat: 53, lon: -111.37, elevation: 683 },
	{ lat: 53, lon: -110.17, elevation: 660 },
	{ lat: 53, lon: -108.96, elevation: 565 },
	{ lat: 53, lon: -107.76, elevation: 616 },
	{ lat: 53, lon: -106.56, elevation: 504 },
	{ lat: 53, lon: -105.35, elevation: 451 },
	{ lat: 53, lon: -104.15, elevation: 398 },
	{ lat: 53, lon: -102.94, elevation: 581 },
	{ lat: 53, lon: -101.74, elevation: 294 },
	{ lat: 53, lon: -100.54, elevation: 251 },
	{ lat: 53, lon: -99.33, elevation: 259 },
	{ lat: 53, lon: -98.13, elevation: 215 },
	{ lat: 53, lon: -96.92, elevation: 240 },
	{ lat: 53, lon: -95.72, elevation: 301 },
	{ lat: 53, lon: -94.52, elevation: 329 },
	{ lat: 53, lon: -93.31, elevation: 282 },
	{ lat: 53, lon: -92.11, elevation: 284 },
	{ lat: 53, lon: -90.9, elevation: 299 },
	{ lat: 53, lon: -89.7, elevation: 260 },
	{ lat: 53, lon: -88.49, elevation: 238 },
	{ lat: 53, lon: -87.29, elevation: 205 },
	{ lat: 53, lon: -86.09, elevation: 150 },
	{ lat: 53, lon: -84.88, elevation: 107 },
	{ lat: 53, lon: -83.68, elevation: 72 },
	{ lat: 53, lon: -82.47, elevation: 9 },
	{ lat: 53, lon: -81.27, elevation: 13 },
	{ lat: 53, lon: -78.86, elevation: 22 },
	{ lat: 53, lon: -77.66, elevation: 205 },
	{ lat: 53, lon: -76.45, elevation: 188 },
	{ lat: 53, lon: -75.25, elevation: 315 },
	{ lat: 53, lon: -74.05, elevation: 350 },
	{ lat: 53, lon: -72.84, elevation: 450 },
	{ lat: 53, lon: -71.64, elevation: 629 },
	{ lat: 53, lon: -70.43, elevation: 626 },
	{ lat: 53, lon: -69.23, elevation: 657 },
	{ lat: 53, lon: -68.03, elevation: 649 },
	{ lat: 53, lon: -66.82, elevation: 609 },
	{ lat: 53, lon: -65.62, elevation: 555 },
	{ lat: 53, lon: -64.41, elevation: 511 },
	{ lat: 53, lon: -63.21, elevation: 569 },
	{ lat: 53, lon: -62.01, elevation: 367 },
	{ lat: 53, lon: -60.8, elevation: 512 },
	{ lat: 53, lon: -59.6, elevation: 477 },
	{ lat: 53, lon: -58.39, elevation: 364 },
	{ lat: 53, lon: -57.19, elevation: 267 },
	{ lat: 53, lon: -9.03, elevation: 39 },
	{ lat: 53, lon: -7.83, elevation: 102 },
	{ lat: 53, lon: -6.62, elevation: 283 },
	{ lat: 53, lon: -4.21, elevation: 372 },
	{ lat: 53, lon: -3.01, elevation: 98 },
	{ lat: 53, lon: -1.81, elevation: 142 },
	{ lat: 53, lon: -0.6, elevation: 69 },
	{ lat: 53, lon: 5.42, elevation: -1 },
	{ lat: 53, lon: 6.62, elevation: 11 },
	{ lat: 53, lon: 7.83, elevation: 8 },
	{ lat: 53, lon: 9.03, elevation: 6 },
	{ lat: 53, lon: 10.23, elevation: 89 },
	{ lat: 53, lon: 11.44, elevation: 37 },
	{ lat: 53, lon: 12.64, elevation: 58 },
	{ lat: 53, lon: 13.85, elevation: 78 },
	{ lat: 53, lon: 15.05, elevation: 65 },
	{ lat: 53, lon: 16.25, elevation: 91 },
	{ lat: 53, lon: 17.46, elevation: 98 },
	{ lat: 53, lon: 18.66, elevation: 45 },
	{ lat: 53, lon: 19.87, elevation: 124 },
	{ lat: 53, lon: 21.07, elevation: 114 },
	{ lat: 53, lon: 22.27, elevation: 127 },
	{ lat: 53, lon: 23.48, elevation: 166 },
	{ lat: 53, lon: 24.68, elevation: 184 },
	{ lat: 53, lon: 25.89, elevation: 184 },
	{ lat: 53, lon: 27.09, elevation: 160 },
	{ lat: 53, lon: 28.29, elevation: 161 },
	{ lat: 53, lon: 29.5, elevation: 144 },
	{ lat: 53, lon: 30.7, elevation: 157 },
	{ lat: 53, lon: 31.91, elevation: 159 },
	{ lat: 53, lon: 33.11, elevation: 192 },
	{ lat: 53, lon: 34.31, elevation: 155 },
	{ lat: 53, lon: 35.52, elevation: 211 },
	{ lat: 53, lon: 36.72, elevation: 237 },
	{ lat: 53, lon: 37.93, elevation: 238 },
	{ lat: 53, lon: 39.13, elevation: 154 },
	{ lat: 53, lon: 40.33, elevation: 170 },
	{ lat: 53, lon: 41.54, elevation: 107 },
	{ lat: 53, lon: 42.74, elevation: 202 },
	{ lat: 53, lon: 43.95, elevation: 234 },
	{ lat: 53, lon: 45.15, elevation: 244 },
	{ lat: 53, lon: 46.35, elevation: 248 },
	{ lat: 53, lon: 47.56, elevation: 238 },
	{ lat: 53, lon: 48.76, elevation: 53 },
	{ lat: 53, lon: 49.97, elevation: 135 },
	{ lat: 53, lon: 51.17, elevation: 41 },
	{ lat: 53, lon: 52.37, elevation: 189 },
	{ lat: 53, lon: 53.58, elevation: 193 },
	{ lat: 53, lon: 54.78, elevation: 240 },
	{ lat: 53, lon: 55.99, elevation: 171 },
	{ lat: 53, lon: 57.19, elevation: 384 },
	{ lat: 53, lon: 58.39, elevation: 477 },
	{ lat: 53, lon: 59.6, elevation: 391 },
	{ lat: 53, lon: 60.8, elevation: 288 },
	{ lat: 53, lon: 62.01, elevation: 207 },
	{ lat: 53, lon: 63.21, elevation: 219 },
	{ lat: 53, lon: 64.41, elevation: 185 },
	{ lat: 53, lon: 65.62, elevation: 223 },
	{ lat: 53, lon: 66.82, elevation: 239 },
	{ lat: 53, lon: 68.03, elevation: 325 },
	{ lat: 53, lon: 69.23, elevation: 357 },
	{ lat: 53, lon: 70.43, elevation: 464 },
	{ lat: 53, lon: 71.64, elevation: 162 },
	{ lat: 53, lon: 72.84, elevation: 122 },
	{ lat: 53, lon: 74.05, elevation: 96 },
	{ lat: 53, lon: 75.25, elevation: 109 },
	{ lat: 53, lon: 76.45, elevation: 103 },
	{ lat: 53, lon: 77.66, elevation: 103 },
	{ lat: 53, lon: 78.86, elevation: 114 },
	{ lat: 53, lon: 80.07, elevation: 100 },
	{ lat: 53, lon: 81.27, elevation: 163 },
	{ lat: 53, lon: 82.47, elevation: 244 },
	{ lat: 53, lon: 83.68, elevation: 133 },
	{ lat: 53, lon: 84.88, elevation: 264 },
	{ lat: 53, lon: 86.09, elevation: 306 },
	{ lat: 53, lon: 87.29, elevation: 471 },
	{ lat: 53, lon: 88.49, elevation: 671 },
	{ lat: 53, lon: 89.7, elevation: 555 },
	{ lat: 53, lon: 90.9, elevation: 545 },
	{ lat: 53, lon: 92.11, elevation: 639 },
	{ lat: 53, lon: 93.31, elevation: 815 },
	{ lat: 53, lon: 94.52, elevation: 922 },
	{ lat: 53, lon: 95.72, elevation: 1147 },
	{ lat: 53, lon: 96.92, elevation: 1410 },
	{ lat: 53, lon: 98.13, elevation: 1552 },
	{ lat: 53, lon: 99.33, elevation: 2539 },
	{ lat: 53, lon: 100.54, elevation: 1529 },
	{ lat: 53, lon: 101.74, elevation: 587 },
	{ lat: 53, lon: 102.94, elevation: 581 },
	{ lat: 53, lon: 104.15, elevation: 641 },
	{ lat: 53, lon: 105.35, elevation: 701 },
	{ lat: 53, lon: 106.56, elevation: 1179 },
	{ lat: 53, lon: 107.76, elevation: 449 },
	{ lat: 53, lon: 108.96, elevation: 795 },
	{ lat: 53, lon: 110.17, elevation: 1399 },
	{ lat: 53, lon: 111.37, elevation: 1122 },
	{ lat: 53, lon: 112.58, elevation: 1137 },
	{ lat: 53, lon: 113.78, elevation: 1060 },
	{ lat: 53, lon: 114.98, elevation: 1039 },
	{ lat: 53, lon: 116.19, elevation: 1103 },
	{ lat: 53, lon: 117.39, elevation: 855 },
	{ lat: 53, lon: 118.6, elevation: 676 },
	{ lat: 53, lon: 119.8, elevation: 488 },
	{ lat: 53, lon: 121.0, elevation: 684 },
	{ lat: 53, lon: 122.21, elevation: 533 },
	{ lat: 53, lon: 123.41, elevation: 507 },
	{ lat: 53, lon: 124.62, elevation: 405 },
	{ lat: 53, lon: 125.82, elevation: 264 },
	{ lat: 53, lon: 127.02, elevation: 290 },
	{ lat: 53, lon: 128.23, elevation: 300 },
	{ lat: 53, lon: 129.43, elevation: 275 },
	{ lat: 53, lon: 130.64, elevation: 330 },
	{ lat: 53, lon: 131.84, elevation: 463 },
	{ lat: 53, lon: 133.04, elevation: 574 },
	{ lat: 53, lon: 134.25, elevation: 930 },
	{ lat: 53, lon: 135.45, elevation: 844 },
	{ lat: 53, lon: 136.66, elevation: 118 },
	{ lat: 53, lon: 137.86, elevation: 422 },
	{ lat: 53, lon: 139.06, elevation: 22 },
	{ lat: 53, lon: 140.27, elevation: 314 },
	{ lat: 53, lon: 142.68, elevation: 92 },
	{ lat: 53, lon: 157.12, elevation: 354 },
	{ lat: 53, lon: 158.33, elevation: 173 },
	{ lat: 53, lon: 172.78, elevation: 117 },
	{ lat: 54, lon: -131.92, elevation: 53 },
	{ lat: 54, lon: -129.45, elevation: 617 },
	{ lat: 54, lon: -128.22, elevation: 1393 },
	{ lat: 54, lon: -126.99, elevation: 828 },
	{ lat: 54, lon: -125.75, elevation: 798 },
	{ lat: 54, lon: -124.52, elevation: 718 },
	{ lat: 54, lon: -123.29, elevation: 805 },
	{ lat: 54, lon: -122.05, elevation: 683 },
	{ lat: 54, lon: -120.82, elevation: 1598 },
	{ lat: 54, lon: -119.59, elevation: 1784 },
	{ lat: 54, lon: -118.36, elevation: 1270 },
	{ lat: 54, lon: -117.12, elevation: 952 },
	{ lat: 54, lon: -115.89, elevation: 845 },
	{ lat: 54, lon: -114.66, elevation: 684 },
	{ lat: 54, lon: -113.42, elevation: 655 },
	{ lat: 54, lon: -112.19, elevation: 559 },
	{ lat: 54, lon: -110.96, elevation: 676 },
	{ lat: 54, lon: -109.73, elevation: 598 },
	{ lat: 54, lon: -108.49, elevation: 567 },
	{ lat: 54, lon: -107.26, elevation: 505 },
	{ lat: 54, lon: -106.03, elevation: 532 },
	{ lat: 54, lon: -104.79, elevation: 545 },
	{ lat: 54, lon: -103.56, elevation: 352 },
	{ lat: 54, lon: -102.33, elevation: 263 },
	{ lat: 54, lon: -101.1, elevation: 257 },
	{ lat: 54, lon: -99.86, elevation: 273 },
	{ lat: 54, lon: -98.63, elevation: 227 },
	{ lat: 54, lon: -97.4, elevation: 238 },
	{ lat: 54, lon: -96.16, elevation: 247 },
	{ lat: 54, lon: -94.93, elevation: 254 },
	{ lat: 54, lon: -93.7, elevation: 240 },
	{ lat: 54, lon: -92.47, elevation: 249 },
	{ lat: 54, lon: -91.23, elevation: 250 },
	{ lat: 54, lon: -90.0, elevation: 219 },
	{ lat: 54, lon: -88.77, elevation: 160 },
	{ lat: 54, lon: -87.53, elevation: 122 },
	{ lat: 54, lon: -86.3, elevation: 123 },
	{ lat: 54, lon: -85.07, elevation: 106 },
	{ lat: 54, lon: -83.84, elevation: 101 },
	{ lat: 54, lon: -82.6, elevation: 16 },
	{ lat: 54, lon: -78.9, elevation: 16 },
	{ lat: 54, lon: -77.67, elevation: 178 },
	{ lat: 54, lon: -76.44, elevation: 170 },
	{ lat: 54, lon: -75.21, elevation: 218 },
	{ lat: 54, lon: -73.97, elevation: 266 },
	{ lat: 54, lon: -72.74, elevation: 481 },
	{ lat: 54, lon: -71.51, elevation: 463 },
	{ lat: 54, lon: -70.27, elevation: 574 },
	{ lat: 54, lon: -69.04, elevation: 542 },
	{ lat: 54, lon: -67.81, elevation: 636 },
	{ lat: 54, lon: -66.58, elevation: 668 },
	{ lat: 54, lon: -65.34, elevation: 466 },
	{ lat: 54, lon: -64.11, elevation: 466 },
	{ lat: 54, lon: -62.88, elevation: 502 },
	{ lat: 54, lon: -61.64, elevation: 424 },
	{ lat: 54, lon: -60.41, elevation: 349 },
	{ lat: 54, lon: -59.18, elevation: 281 },
	{ lat: 54, lon: -57.95, elevation: 88 },
	{ lat: 54, lon: -8.63, elevation: 80 },
	{ lat: 54, lon: -7.4, elevation: 53 },
	{ lat: 54, lon: -6.16, elevation: 28 },
	{ lat: 54, lon: -2.47, elevation: 301 },
	{ lat: 54, lon: -1.23, elevation: 14 },
	{ lat: 54, lon: 9.86, elevation: 11 },
	{ lat: 54, lon: 11.1, elevation: 37 },
	{ lat: 54, lon: 12.33, elevation: 42 },
	{ lat: 54, lon: 13.56, elevation: 41 },
	{ lat: 54, lon: 14.79, elevation: 12 },
	{ lat: 54, lon: 16.03, elevation: 33 },
	{ lat: 54, lon: 17.26, elevation: 158 },
	{ lat: 54, lon: 18.49, elevation: 112 },
	{ lat: 54, lon: 19.73, elevation: 129 },
	{ lat: 54, lon: 20.96, elevation: 169 },
	{ lat: 54, lon: 22.19, elevation: 142 },
	{ lat: 54, lon: 23.42, elevation: 144 },
	{ lat: 54, lon: 24.66, elevation: 137 },
	{ lat: 54, lon: 25.89, elevation: 169 },
	{ lat: 54, lon: 27.12, elevation: 281 },
	{ lat: 54, lon: 28.36, elevation: 183 },
	{ lat: 54, lon: 29.59, elevation: 204 },
	{ lat: 54, lon: 30.82, elevation: 179 },
	{ lat: 54, lon: 32.05, elevation: 181 },
	{ lat: 54, lon: 33.29, elevation: 254 },
	{ lat: 54, lon: 34.52, elevation: 229 },
	{ lat: 54, lon: 35.75, elevation: 178 },
	{ lat: 54, lon: 36.99, elevation: 208 },
	{ lat: 54, lon: 38.22, elevation: 221 },
	{ lat: 54, lon: 39.45, elevation: 183 },
	{ lat: 54, lon: 40.68, elevation: 133 },
	{ lat: 54, lon: 41.92, elevation: 97 },
	{ lat: 54, lon: 43.15, elevation: 142 },
	{ lat: 54, lon: 44.38, elevation: 178 },
	{ lat: 54, lon: 45.62, elevation: 221 },
	{ lat: 54, lon: 46.85, elevation: 214 },
	{ lat: 54, lon: 48.08, elevation: 119 },
	{ lat: 54, lon: 49.32, elevation: 45 },
	{ lat: 54, lon: 50.55, elevation: 81 },
	{ lat: 54, lon: 51.78, elevation: 194 },
	{ lat: 54, lon: 53.01, elevation: 240 },
	{ lat: 54, lon: 54.25, elevation: 328 },
	{ lat: 54, lon: 55.48, elevation: 161 },
	{ lat: 54, lon: 56.71, elevation: 321 },
	{ lat: 54, lon: 57.95, elevation: 634 },
	{ lat: 54, lon: 59.18, elevation: 459 },
	{ lat: 54, lon: 60.41, elevation: 353 },
	{ lat: 54, lon: 61.64, elevation: 203 },
	{ lat: 54, lon: 62.88, elevation: 180 },
	{ lat: 54, lon: 64.11, elevation: 175 },
	{ lat: 54, lon: 65.34, elevation: 171 },
	{ lat: 54, lon: 66.58, elevation: 162 },
	{ lat: 54, lon: 67.81, elevation: 168 },
	{ lat: 54, lon: 69.04, elevation: 145 },
	{ lat: 54, lon: 70.27, elevation: 140 },
	{ lat: 54, lon: 71.51, elevation: 78 },
	{ lat: 54, lon: 72.74, elevation: 100 },
	{ lat: 54, lon: 73.97, elevation: 98 },
	{ lat: 54, lon: 75.21, elevation: 114 },
	{ lat: 54, lon: 76.44, elevation: 105 },
	{ lat: 54, lon: 77.67, elevation: 105 },
	{ lat: 54, lon: 78.9, elevation: 124 },
	{ lat: 54, lon: 80.14, elevation: 198 },
	{ lat: 54, lon: 81.37, elevation: 125 },
	{ lat: 54, lon: 82.6, elevation: 181 },
	{ lat: 54, lon: 83.84, elevation: 226 },
	{ lat: 54, lon: 85.07, elevation: 249 },
	{ lat: 54, lon: 86.3, elevation: 459 },
	{ lat: 54, lon: 87.53, elevation: 414 },
	{ lat: 54, lon: 88.77, elevation: 673 },
	{ lat: 54, lon: 90.0, elevation: 1035 },
	{ lat: 54, lon: 91.23, elevation: 532 },
	{ lat: 54, lon: 92.47, elevation: 317 },
	{ lat: 54, lon: 93.7, elevation: 453 },
	{ lat: 54, lon: 94.93, elevation: 1062 },
	{ lat: 54, lon: 96.16, elevation: 1284 },
	{ lat: 54, lon: 97.4, elevation: 1313 },
	{ lat: 54, lon: 98.63, elevation: 949 },
	{ lat: 54, lon: 99.86, elevation: 583 },
	{ lat: 54, lon: 101.1, elevation: 551 },
	{ lat: 54, lon: 102.33, elevation: 510 },
	{ lat: 54, lon: 103.56, elevation: 488 },
	{ lat: 54, lon: 104.79, elevation: 888 },
	{ lat: 54, lon: 106.03, elevation: 732 },
	{ lat: 54, lon: 107.26, elevation: 690 },
	{ lat: 54, lon: 108.49, elevation: 449 },
	{ lat: 54, lon: 109.73, elevation: 2100 },
	{ lat: 54, lon: 110.96, elevation: 1643 },
	{ lat: 54, lon: 112.19, elevation: 1377 },
	{ lat: 54, lon: 113.42, elevation: 1010 },
	{ lat: 54, lon: 114.66, elevation: 860 },
	{ lat: 54, lon: 115.89, elevation: 788 },
	{ lat: 54, lon: 117.12, elevation: 893 },
	{ lat: 54, lon: 118.36, elevation: 995 },
	{ lat: 54, lon: 119.59, elevation: 992 },
	{ lat: 54, lon: 120.82, elevation: 755 },
	{ lat: 54, lon: 122.05, elevation: 650 },
	{ lat: 54, lon: 123.29, elevation: 448 },
	{ lat: 54, lon: 124.52, elevation: 767 },
	{ lat: 54, lon: 125.75, elevation: 476 },
	{ lat: 54, lon: 126.99, elevation: 431 },
	{ lat: 54, lon: 128.22, elevation: 477 },
	{ lat: 54, lon: 129.45, elevation: 690 },
	{ lat: 54, lon: 130.68, elevation: 544 },
	{ lat: 54, lon: 131.92, elevation: 280 },
	{ lat: 54, lon: 133.15, elevation: 145 },
	{ lat: 54, lon: 134.38, elevation: 562 },
	{ lat: 54, lon: 135.62, elevation: 240 },
	{ lat: 54, lon: 139.32, elevation: 314 },
	{ lat: 54, lon: 156.58, elevation: 226 },
	{ lat: 54, lon: 157.81, elevation: 481 },
	{ lat: 54, lon: 159.04, elevation: 190 },
	{ lat: 55, lon: -163.58, elevation: 21 },
	{ lat: 55, lon: -132.0, elevation: 176 },
	{ lat: 55, lon: -130.74, elevation: 378 },
	{ lat: 55, lon: -129.47, elevation: 1092 },
	{ lat: 55, lon: -128.21, elevation: 1276 },
	{ lat: 55, lon: -126.95, elevation: 1787 },
	{ lat: 55, lon: -125.68, elevation: 1453 },
	{ lat: 55, lon: -124.42, elevation: 1326 },
	{ lat: 55, lon: -123.16, elevation: 828 },
	{ lat: 55, lon: -121.89, elevation: 1169 },
	{ lat: 55, lon: -120.63, elevation: 1175 },
	{ lat: 55, lon: -119.37, elevation: 677 },
	{ lat: 55, lon: -118.11, elevation: 645 },
	{ lat: 55, lon: -116.84, elevation: 746 },
	{ lat: 55, lon: -115.58, elevation: 940 },
	{ lat: 55, lon: -114.32, elevation: 598 },
	{ lat: 55, lon: -113.05, elevation: 625 },
	{ lat: 55, lon: -111.79, elevation: 588 },
	{ lat: 55, lon: -110.53, elevation: 679 },
	{ lat: 55, lon: -109.26, elevation: 638 },
	{ lat: 55, lon: -108.0, elevation: 448 },
	{ lat: 55, lon: -106.74, elevation: 482 },
	{ lat: 55, lon: -105.47, elevation: 374 },
	{ lat: 55, lon: -104.21, elevation: 409 },
	{ lat: 55, lon: -102.95, elevation: 310 },
	{ lat: 55, lon: -101.68, elevation: 332 },
	{ lat: 55, lon: -100.42, elevation: 296 },
	{ lat: 55, lon: -99.16, elevation: 254 },
	{ lat: 55, lon: -97.89, elevation: 185 },
	{ lat: 55, lon: -96.63, elevation: 208 },
	{ lat: 55, lon: -95.37, elevation: 212 },
	{ lat: 55, lon: -94.11, elevation: 183 },
	{ lat: 55, lon: -92.84, elevation: 170 },
	{ lat: 55, lon: -91.58, elevation: 115 },
	{ lat: 55, lon: -90.32, elevation: 119 },
	{ lat: 55, lon: -89.05, elevation: 94 },
	{ lat: 55, lon: -87.79, elevation: 107 },
	{ lat: 55, lon: -86.53, elevation: 103 },
	{ lat: 55, lon: -85.26, elevation: 47 },
	{ lat: 55, lon: -84.0, elevation: 32 },
	{ lat: 55, lon: -82.74, elevation: 17 },
	{ lat: 55, lon: -77.68, elevation: 166 },
	{ lat: 55, lon: -76.42, elevation: 234 },
	{ lat: 55, lon: -75.16, elevation: 359 },
	{ lat: 55, lon: -73.89, elevation: 422 },
	{ lat: 55, lon: -72.63, elevation: 406 },
	{ lat: 55, lon: -71.37, elevation: 430 },
	{ lat: 55, lon: -70.11, elevation: 537 },
	{ lat: 55, lon: -68.84, elevation: 587 },
	{ lat: 55, lon: -67.58, elevation: 614 },
	{ lat: 55, lon: -66.32, elevation: 481 },
	{ lat: 55, lon: -65.05, elevation: 570 },
	{ lat: 55, lon: -63.79, elevation: 507 },
	{ lat: 55, lon: -62.53, elevation: 594 },
	{ lat: 55, lon: -61.26, elevation: 76 },
	{ lat: 55, lon: -60.0, elevation: 115 },
	{ lat: 55, lon: -58.74, elevation: 14 },
	{ lat: 55, lon: -8.21, elevation: 375 },
	{ lat: 55, lon: -6.95, elevation: 100 },
	{ lat: 55, lon: -4.42, elevation: 114 },
	{ lat: 55, lon: -3.16, elevation: 28 },
	{ lat: 55, lon: -1.89, elevation: 105 },
	{ lat: 55, lon: 9.47, elevation: 61 },
	{ lat: 55, lon: 12.0, elevation: 66 },
	{ lat: 55, lon: 22.11, elevation: 26 },
	{ lat: 55, lon: 23.37, elevation: 79 },
	{ lat: 55, lon: 24.63, elevation: 104 },
	{ lat: 55, lon: 25.89, elevation: 146 },
	{ lat: 55, lon: 27.16, elevation: 176 },
	{ lat: 55, lon: 28.42, elevation: 167 },
	{ lat: 55, lon: 29.68, elevation: 144 },
	{ lat: 55, lon: 30.95, elevation: 184 },
	{ lat: 55, lon: 32.21, elevation: 230 },
	{ lat: 55, lon: 33.47, elevation: 227 },
	{ lat: 55, lon: 34.74, elevation: 197 },
	{ lat: 55, lon: 36.0, elevation: 189 },
	{ lat: 55, lon: 37.26, elevation: 183 },
	{ lat: 55, lon: 38.53, elevation: 158 },
	{ lat: 55, lon: 39.79, elevation: 120 },
	{ lat: 55, lon: 41.05, elevation: 119 },
	{ lat: 55, lon: 42.32, elevation: 148 },
	{ lat: 55, lon: 43.58, elevation: 176 },
	{ lat: 55, lon: 44.84, elevation: 173 },
	{ lat: 55, lon: 46.11, elevation: 122 },
	{ lat: 55, lon: 47.37, elevation: 194 },
	{ lat: 55, lon: 48.63, elevation: 121 },
	{ lat: 55, lon: 49.89, elevation: 93 },
	{ lat: 55, lon: 51.16, elevation: 117 },
	{ lat: 55, lon: 52.42, elevation: 299 },
	{ lat: 55, lon: 53.68, elevation: 154 },
	{ lat: 55, lon: 54.95, elevation: 110 },
	{ lat: 55, lon: 56.21, elevation: 117 },
	{ lat: 55, lon: 57.47, elevation: 448 },
	{ lat: 55, lon: 58.74, elevation: 754 },
	{ lat: 55, lon: 60.0, elevation: 396 },
	{ lat: 55, lon: 61.26, elevation: 286 },
	{ lat: 55, lon: 62.53, elevation: 171 },
	{ lat: 55, lon: 63.79, elevation: 158 },
	{ lat: 55, lon: 65.05, elevation: 71 },
	{ lat: 55, lon: 66.32, elevation: 142 },
	{ lat: 55, lon: 67.58, elevation: 144 },
	{ lat: 55, lon: 68.84, elevation: 121 },
	{ lat: 55, lon: 70.11, elevation: 131 },
	{ lat: 55, lon: 71.37, elevation: 120 },
	{ lat: 55, lon: 72.63, elevation: 96 },
	{ lat: 55, lon: 73.89, elevation: 111 },
	{ lat: 55, lon: 75.16, elevation: 107 },
	{ lat: 55, lon: 76.42, elevation: 102 },
	{ lat: 55, lon: 77.68, elevation: 101 },
	{ lat: 55, lon: 78.95, elevation: 113 },
	{ lat: 55, lon: 80.21, elevation: 147 },
	{ lat: 55, lon: 81.47, elevation: 156 },
	{ lat: 55, lon: 82.74, elevation: 99 },
	{ lat: 55, lon: 84.0, elevation: 243 },
	{ lat: 55, lon: 85.26, elevation: 158 },
	{ lat: 55, lon: 86.53, elevation: 241 },
	{ lat: 55, lon: 87.79, elevation: 529 },
	{ lat: 55, lon: 89.05, elevation: 889 },
	{ lat: 55, lon: 90.32, elevation: 351 },
	{ lat: 55, lon: 91.58, elevation: 505 },
	{ lat: 55, lon: 92.84, elevation: 736 },
	{ lat: 55, lon: 94.11, elevation: 507 },
	{ lat: 55, lon: 95.37, elevation: 824 },
	{ lat: 55, lon: 96.63, elevation: 744 },
	{ lat: 55, lon: 97.89, elevation: 477 },
	{ lat: 55, lon: 99.16, elevation: 522 },
	{ lat: 55, lon: 100.42, elevation: 565 },
	{ lat: 55, lon: 101.68, elevation: 512 },
	{ lat: 55, lon: 102.95, elevation: 564 },
	{ lat: 55, lon: 104.21, elevation: 790 },
	{ lat: 55, lon: 105.47, elevation: 914 },
	{ lat: 55, lon: 106.74, elevation: 823 },
	{ lat: 55, lon: 108.0, elevation: 792 },
	{ lat: 55, lon: 109.26, elevation: 449 },
	{ lat: 55, lon: 110.53, elevation: 1173 },
	{ lat: 55, lon: 111.79, elevation: 1976 },
	{ lat: 55, lon: 113.05, elevation: 1217 },
	{ lat: 55, lon: 114.32, elevation: 1279 },
	{ lat: 55, lon: 115.58, elevation: 685 },
	{ lat: 55, lon: 116.84, elevation: 702 },
	{ lat: 55, lon: 118.11, elevation: 1070 },
	{ lat: 55, lon: 119.37, elevation: 755 },
	{ lat: 55, lon: 120.63, elevation: 783 },
	{ lat: 55, lon: 121.89, elevation: 671 },
	{ lat: 55, lon: 123.16, elevation: 959 },
	{ lat: 55, lon: 124.42, elevation: 635 },
	{ lat: 55, lon: 125.68, elevation: 786 },
	{ lat: 55, lon: 126.95, elevation: 491 },
	{ lat: 55, lon: 128.21, elevation: 451 },
	{ lat: 55, lon: 129.47, elevation: 438 },
	{ lat: 55, lon: 130.74, elevation: 662 },
	{ lat: 55, lon: 132.0, elevation: 1503 },
	{ lat: 55, lon: 133.26, elevation: 293 },
	{ lat: 55, lon: 134.53, elevation: 857 },
	{ lat: 55, lon: 137.05, elevation: 196 },
	{ lat: 55, lon: 156.0, elevation: 75 },
	{ lat: 55, lon: 157.26, elevation: 591 },
	{ lat: 55, lon: 158.53, elevation: 203 },
	{ lat: 55, lon: 159.79, elevation: 252 },
	{ lat: 55, lon: 161.05, elevation: 744 },
	{ lat: 56, lon: -159.28, elevation: 107 },
	{ lat: 56, lon: -130.79, elevation: 451 },
	{ lat: 56, lon: -129.5, elevation: 833 },
	{ lat: 56, lon: -128.2, elevation: 1122 },
	{ lat: 56, lon: -126.91, elevation: 1188 },
	{ lat: 56, lon: -125.61, elevation: 1329 },
	{ lat: 56, lon: -124.32, elevation: 832 },
	{ lat: 56, lon: -123.02, elevation: 971 },
	{ lat: 56, lon: -121.73, elevation: 740 },
	{ lat: 56, lon: -120.43, elevation: 778 },
	{ lat: 56, lon: -119.14, elevation: 643 },
	{ lat: 56, lon: -117.84, elevation: 506 },
	{ lat: 56, lon: -116.55, elevation: 732 },
	{ lat: 56, lon: -115.25, elevation: 643 },
	{ lat: 56, lon: -113.96, elevation: 540 },
	{ lat: 56, lon: -112.66, elevation: 531 },
	{ lat: 56, lon: -111.37, elevation: 694 },
	{ lat: 56, lon: -110.07, elevation: 530 },
	{ lat: 56, lon: -108.78, elevation: 415 },
	{ lat: 56, lon: -107.48, elevation: 411 },
	{ lat: 56, lon: -106.19, elevation: 451 },
	{ lat: 56, lon: -104.89, elevation: 469 },
	{ lat: 56, lon: -103.6, elevation: 401 },
	{ lat: 56, lon: -102.3, elevation: 363 },
	{ lat: 56, lon: -101.01, elevation: 342 },
	{ lat: 56, lon: -99.71, elevation: 315 },
	{ lat: 56, lon: -98.42, elevation: 277 },
	{ lat: 56, lon: -97.12, elevation: 203 },
	{ lat: 56, lon: -95.83, elevation: 204 },
	{ lat: 56, lon: -94.53, elevation: 170 },
	{ lat: 56, lon: -93.24, elevation: 90 },
	{ lat: 56, lon: -91.94, elevation: 147 },
	{ lat: 56, lon: -90.65, elevation: 158 },
	{ lat: 56, lon: -89.35, elevation: 117 },
	{ lat: 56, lon: -88.06, elevation: 34 },
	{ lat: 56, lon: -78.99, elevation: 76 },
	{ lat: 56, lon: -76.4, elevation: 123 },
	{ lat: 56, lon: -75.11, elevation: 249 },
	{ lat: 56, lon: -73.81, elevation: 347 },
	{ lat: 56, lon: -72.52, elevation: 341 },
	{ lat: 56, lon: -71.22, elevation: 418 },
	{ lat: 56, lon: -69.93, elevation: 524 },
	{ lat: 56, lon: -68.63, elevation: 229 },
	{ lat: 56, lon: -67.34, elevation: 395 },
	{ lat: 56, lon: -66.04, elevation: 398 },
	{ lat: 56, lon: -64.75, elevation: 397 },
	{ lat: 56, lon: -63.45, elevation: 537 },
	{ lat: 56, lon: -62.16, elevation: 379 },
	{ lat: 56, lon: -60.86, elevation: 112 },
	{ lat: 56, lon: -5.18, elevation: 229 },
	{ lat: 56, lon: -3.88, elevation: 44 },
	{ lat: 56, lon: -2.59, elevation: 0 },
	{ lat: 56, lon: 9.06, elevation: 44 },
	{ lat: 56, lon: 12.95, elevation: 80 },
	{ lat: 56, lon: 14.24, elevation: 0 },
	{ lat: 56, lon: 22.01, elevation: 160 },
	{ lat: 56, lon: 23.31, elevation: 111 },
	{ lat: 56, lon: 24.6, elevation: 55 },
	{ lat: 56, lon: 25.9, elevation: 129 },
	{ lat: 56, lon: 27.19, elevation: 175 },
	{ lat: 56, lon: 28.49, elevation: 139 },
	{ lat: 56, lon: 29.78, elevation: 164 },
	{ lat: 56, lon: 31.08, elevation: 170 },
	{ lat: 56, lon: 32.37, elevation: 174 },
	{ lat: 56, lon: 33.67, elevation: 261 },
	{ lat: 56, lon: 34.96, elevation: 239 },
	{ lat: 56, lon: 36.26, elevation: 227 },
	{ lat: 56, lon: 37.55, elevation: 197 },
	{ lat: 56, lon: 38.85, elevation: 137 },
	{ lat: 56, lon: 40.14, elevation: 102 },
	{ lat: 56, lon: 41.44, elevation: 125 },
	{ lat: 56, lon: 42.73, elevation: 115 },
	{ lat: 56, lon: 44.03, elevation: 138 },
	{ lat: 56, lon: 45.32, elevation: 113 },
	{ lat: 56, lon: 46.62, elevation: 170 },
	{ lat: 56, lon: 47.91, elevation: 98 },
	{ lat: 56, lon: 49.21, elevation: 129 },
	{ lat: 56, lon: 50.5, elevation: 168 },
	{ lat: 56, lon: 51.8, elevation: 118 },
	{ lat: 56, lon: 53.09, elevation: 102 },
	{ lat: 56, lon: 54.39, elevation: 126 },
	{ lat: 56, lon: 55.68, elevation: 112 },
	{ lat: 56, lon: 56.98, elevation: 245 },
	{ lat: 56, lon: 58.27, elevation: 194 },
	{ lat: 56, lon: 59.57, elevation: 365 },
	{ lat: 56, lon: 60.86, elevation: 245 },
	{ lat: 56, lon: 62.16, elevation: 161 },
	{ lat: 56, lon: 63.45, elevation: 129 },
	{ lat: 56, lon: 64.75, elevation: 145 },
	{ lat: 56, lon: 66.04, elevation: 120 },
	{ lat: 56, lon: 67.34, elevation: 135 },
	{ lat: 56, lon: 68.63, elevation: 125 },
	{ lat: 56, lon: 69.93, elevation: 140 },
	{ lat: 56, lon: 71.22, elevation: 110 },
	{ lat: 56, lon: 72.52, elevation: 109 },
	{ lat: 56, lon: 73.81, elevation: 101 },
	{ lat: 56, lon: 75.11, elevation: 137 },
	{ lat: 56, lon: 76.4, elevation: 105 },
	{ lat: 56, lon: 77.7, elevation: 122 },
	{ lat: 56, lon: 78.99, elevation: 124 },
	{ lat: 56, lon: 80.29, elevation: 128 },
	{ lat: 56, lon: 81.58, elevation: 146 },
	{ lat: 56, lon: 82.88, elevation: 132 },
	{ lat: 56, lon: 84.17, elevation: 127 },
	{ lat: 56, lon: 85.47, elevation: 244 },
	{ lat: 56, lon: 86.76, elevation: 213 },
	{ lat: 56, lon: 88.06, elevation: 200 },
	{ lat: 56, lon: 89.35, elevation: 332 },
	{ lat: 56, lon: 90.65, elevation: 249 },
	{ lat: 56, lon: 91.94, elevation: 479 },
	{ lat: 56, lon: 93.24, elevation: 262 },
	{ lat: 56, lon: 94.53, elevation: 212 },
	{ lat: 56, lon: 95.83, elevation: 229 },
	{ lat: 56, lon: 97.12, elevation: 454 },
	{ lat: 56, lon: 98.42, elevation: 448 },
	{ lat: 56, lon: 99.71, elevation: 313 },
	{ lat: 56, lon: 101.01, elevation: 689 },
	{ lat: 56, lon: 102.3, elevation: 480 },
	{ lat: 56, lon: 103.6, elevation: 569 },
	{ lat: 56, lon: 104.89, elevation: 711 },
	{ lat: 56, lon: 106.19, elevation: 593 },
	{ lat: 56, lon: 107.48, elevation: 480 },
	{ lat: 56, lon: 108.78, elevation: 1368 },
	{ lat: 56, lon: 110.07, elevation: 826 },
	{ lat: 56, lon: 111.37, elevation: 472 },
	{ lat: 56, lon: 112.66, elevation: 1051 },
	{ lat: 56, lon: 113.96, elevation: 806 },
	{ lat: 56, lon: 115.25, elevation: 1104 },
	{ lat: 56, lon: 116.55, elevation: 1035 },
	{ lat: 56, lon: 117.84, elevation: 1317 },
	{ lat: 56, lon: 119.14, elevation: 1217 },
	{ lat: 56, lon: 120.43, elevation: 986 },
	{ lat: 56, lon: 121.73, elevation: 910 },
	{ lat: 56, lon: 123.02, elevation: 767 },
	{ lat: 56, lon: 124.32, elevation: 1016 },
	{ lat: 56, lon: 125.61, elevation: 948 },
	{ lat: 56, lon: 126.91, elevation: 790 },
	{ lat: 56, lon: 128.2, elevation: 649 },
	{ lat: 56, lon: 129.5, elevation: 1648 },
	{ lat: 56, lon: 130.79, elevation: 1150 },
	{ lat: 56, lon: 132.09, elevation: 1437 },
	{ lat: 56, lon: 133.38, elevation: 945 },
	{ lat: 56, lon: 134.68, elevation: 1057 },
	{ lat: 56, lon: 135.97, elevation: 856 },
	{ lat: 56, lon: 137.27, elevation: 136 },
	{ lat: 56, lon: 156.69, elevation: 131 },
	{ lat: 56, lon: 157.99, elevation: 550 },
	{ lat: 56, lon: 159.28, elevation: 654 },
	{ lat: 56, lon: 160.58, elevation: 3039 },
	{ lat: 56, lon: 161.87, elevation: 482 },
	{ lat: 57, lon: -157.42, elevation: 793 },
	{ lat: 57, lon: -134.83, elevation: 563 },
	{ lat: 57, lon: -133.51, elevation: 374 },
	{ lat: 57, lon: -132.18, elevation: 1767 },
	{ lat: 57, lon: -130.85, elevation: 1043 },
	{ lat: 57, lon: -129.52, elevation: 1271 },
	{ lat: 57, lon: -128.19, elevation: 1010 },
	{ lat: 57, lon: -126.86, elevation: 1141 },
	{ lat: 57, lon: -125.54, elevation: 1638 },
	{ lat: 57, lon: -124.21, elevation: 1539 },
	{ lat: 57, lon: -122.88, elevation: 1278 },
	{ lat: 57, lon: -121.55, elevation: 824 },
	{ lat: 57, lon: -120.22, elevation: 758 },
	{ lat: 57, lon: -118.89, elevation: 747 },
	{ lat: 57, lon: -117.56, elevation: 466 },
	{ lat: 57, lon: -116.24, elevation: 759 },
	{ lat: 57, lon: -114.91, elevation: 534 },
	{ lat: 57, lon: -113.58, elevation: 613 },
	{ lat: 57, lon: -112.25, elevation: 443 },
	{ lat: 57, lon: -110.92, elevation: 505 },
	{ lat: 57, lon: -109.59, elevation: 490 },
	{ lat: 57, lon: -108.27, elevation: 460 },
	{ lat: 57, lon: -106.94, elevation: 527 },
	{ lat: 57, lon: -105.61, elevation: 520 },
	{ lat: 57, lon: -104.28, elevation: 441 },
	{ lat: 57, lon: -102.95, elevation: 350 },
	{ lat: 57, lon: -101.62, elevation: 371 },
	{ lat: 57, lon: -100.3, elevation: 282 },
	{ lat: 57, lon: -98.97, elevation: 291 },
	{ lat: 57, lon: -97.64, elevation: 252 },
	{ lat: 57, lon: -96.31, elevation: 228 },
	{ lat: 57, lon: -94.98, elevation: 215 },
	{ lat: 57, lon: -93.65, elevation: 79 },
	{ lat: 57, lon: -92.32, elevation: 11 },
	{ lat: 57, lon: -91.0, elevation: 46 },
	{ lat: 57, lon: -76.38, elevation: 167 },
	{ lat: 57, lon: -75.06, elevation: 235 },
	{ lat: 57, lon: -73.73, elevation: 316 },
	{ lat: 57, lon: -72.4, elevation: 245 },
	{ lat: 57, lon: -71.07, elevation: 287 },
	{ lat: 57, lon: -69.74, elevation: 388 },
	{ lat: 57, lon: -68.41, elevation: 230 },
	{ lat: 57, lon: -67.08, elevation: 267 },
	{ lat: 57, lon: -65.76, elevation: 359 },
	{ lat: 57, lon: -64.43, elevation: 474 },
	{ lat: 57, lon: -63.1, elevation: 472 },
	{ lat: 57, lon: -61.77, elevation: 471 },
	{ lat: 57, lon: -4.65, elevation: 324 },
	{ lat: 57, lon: -3.32, elevation: 317 },
	{ lat: 57, lon: 8.63, elevation: 43 },
	{ lat: 57, lon: 9.96, elevation: 6 },
	{ lat: 57, lon: 12.62, elevation: 43 },
	{ lat: 57, lon: 13.95, elevation: 152 },
	{ lat: 57, lon: 15.28, elevation: 263 },
	{ lat: 57, lon: 21.92, elevation: 45 },
	{ lat: 57, lon: 23.25, elevation: 36 },
	{ lat: 57, lon: 24.58, elevation: 48 },
	{ lat: 57, lon: 25.9, elevation: 210 },
	{ lat: 57, lon: 27.23, elevation: 111 },
	{ lat: 57, lon: 28.56, elevation: 76 },
	{ lat: 57, lon: 29.89, elevation: 117 },
	{ lat: 57, lon: 31.22, elevation: 90 },
	{ lat: 57, lon: 32.55, elevation: 243 },
	{ lat: 57, lon: 33.87, elevation: 279 },
	{ lat: 57, lon: 35.2, elevation: 166 },
	{ lat: 57, lon: 36.53, elevation: 139 },
	{ lat: 57, lon: 37.86, elevation: 122 },
	{ lat: 57, lon: 39.19, elevation: 150 },
	{ lat: 57, lon: 40.52, elevation: 139 },
	{ lat: 57, lon: 41.85, elevation: 124 },
	{ lat: 57, lon: 43.17, elevation: 80 },
	{ lat: 57, lon: 44.5, elevation: 128 },
	{ lat: 57, lon: 45.83, elevation: 123 },
	{ lat: 57, lon: 47.16, elevation: 94 },
	{ lat: 57, lon: 48.49, elevation: 154 },
	{ lat: 57, lon: 49.82, elevation: 138 },
	{ lat: 57, lon: 51.14, elevation: 84 },
	{ lat: 57, lon: 52.47, elevation: 163 },
	{ lat: 57, lon: 53.8, elevation: 130 },
	{ lat: 57, lon: 55.13, elevation: 228 },
	{ lat: 57, lon: 56.46, elevation: 195 },
	{ lat: 57, lon: 57.79, elevation: 219 },
	{ lat: 57, lon: 59.11, elevation: 372 },
	{ lat: 57, lon: 60.44, elevation: 248 },
	{ lat: 57, lon: 61.77, elevation: 196 },
	{ lat: 57, lon: 63.1, elevation: 77 },
	{ lat: 57, lon: 64.43, elevation: 68 },
	{ lat: 57, lon: 65.76, elevation: 68 },
	{ lat: 57, lon: 67.08, elevation: 69 },
	{ lat: 57, lon: 68.41, elevation: 90 },
	{ lat: 57, lon: 69.74, elevation: 91 },
	{ lat: 57, lon: 71.07, elevation: 95 },
	{ lat: 57, lon: 72.4, elevation: 99 },
	{ lat: 57, lon: 73.73, elevation: 75 },
	{ lat: 57, lon: 75.06, elevation: 76 },
	{ lat: 57, lon: 76.38, elevation: 130 },
	{ lat: 57, lon: 77.71, elevation: 133 },
	{ lat: 57, lon: 79.04, elevation: 135 },
	{ lat: 57, lon: 80.37, elevation: 137 },
	{ lat: 57, lon: 81.7, elevation: 109 },
	{ lat: 57, lon: 83.03, elevation: 122 },
	{ lat: 57, lon: 84.35, elevation: 69 },
	{ lat: 57, lon: 85.68, elevation: 172 },
	{ lat: 57, lon: 87.01, elevation: 104 },
	{ lat: 57, lon: 88.34, elevation: 194 },
	{ lat: 57, lon: 89.67, elevation: 197 },
	{ lat: 57, lon: 91.0, elevation: 197 },
	{ lat: 57, lon: 92.32, elevation: 276 },
	{ lat: 57, lon: 93.65, elevation: 297 },
	{ lat: 57, lon: 94.98, elevation: 172 },
	{ lat: 57, lon: 96.31, elevation: 239 },
	{ lat: 57, lon: 97.64, elevation: 239 },
	{ lat: 57, lon: 98.97, elevation: 399 },
	{ lat: 57, lon: 100.3, elevation: 459 },
	{ lat: 57, lon: 101.62, elevation: 291 },
	{ lat: 57, lon: 102.95, elevation: 514 },
	{ lat: 57, lon: 104.28, elevation: 526 },
	{ lat: 57, lon: 105.61, elevation: 521 },
	{ lat: 57, lon: 106.94, elevation: 490 },
	{ lat: 57, lon: 108.27, elevation: 341 },
	{ lat: 57, lon: 109.59, elevation: 1045 },
	{ lat: 57, lon: 110.92, elevation: 1022 },
	{ lat: 57, lon: 112.25, elevation: 951 },
	{ lat: 57, lon: 113.58, elevation: 1295 },
	{ lat: 57, lon: 114.91, elevation: 1029 },
	{ lat: 57, lon: 116.24, elevation: 1166 },
	{ lat: 57, lon: 117.56, elevation: 1688 },
	{ lat: 57, lon: 118.89, elevation: 939 },
	{ lat: 57, lon: 120.22, elevation: 947 },
	{ lat: 57, lon: 121.55, elevation: 868 },
	{ lat: 57, lon: 122.88, elevation: 871 },
	{ lat: 57, lon: 124.21, elevation: 785 },
	{ lat: 57, lon: 125.54, elevation: 737 },
	{ lat: 57, lon: 126.86, elevation: 1256 },
	{ lat: 57, lon: 128.19, elevation: 1143 },
	{ lat: 57, lon: 129.52, elevation: 1020 },
	{ lat: 57, lon: 130.85, elevation: 1225 },
	{ lat: 57, lon: 132.18, elevation: 1116 },
	{ lat: 57, lon: 133.51, elevation: 554 },
	{ lat: 57, lon: 134.83, elevation: 535 },
	{ lat: 57, lon: 136.16, elevation: 525 },
	{ lat: 57, lon: 137.49, elevation: 1262 },
	{ lat: 57, lon: 157.42, elevation: 88 },
	{ lat: 57, lon: 158.75, elevation: 328 },
	{ lat: 57, lon: 160.07, elevation: 430 },
	{ lat: 57, lon: 161.4, elevation: 159 },
	{ lat: 57, lon: 162.73, elevation: 132 },
	{ lat: 58, lon: -156.73, elevation: 4 },
	{ lat: 58, lon: -155.36, elevation: 350 },
	{ lat: 58, lon: -136.2, elevation: 550 },
	{ lat: 58, lon: -133.46, elevation: 1147 },
	{ lat: 58, lon: -132.09, elevation: 752 },
	{ lat: 58, lon: -130.72, elevation: 447 },
	{ lat: 58, lon: -129.35, elevation: 1188 },
	{ lat: 58, lon: -127.98, elevation: 1739 },
	{ lat: 58, lon: -126.62, elevation: 1311 },
	{ lat: 58, lon: -125.25, elevation: 1862 },
	{ lat: 58, lon: -123.88, elevation: 1209 },
	{ lat: 58, lon: -122.51, elevation: 998 },
	{ lat: 58, lon: -121.14, elevation: 505 },
	{ lat: 58, lon: -119.77, elevation: 738 },
	{ lat: 58, lon: -118.4, elevation: 476 },
	{ lat: 58, lon: -117.03, elevation: 366 },
	{ lat: 58, lon: -115.67, elevation: 336 },
	{ lat: 58, lon: -114.3, elevation: 320 },
	{ lat: 58, lon: -112.93, elevation: 648 },
	{ lat: 58, lon: -111.56, elevation: 250 },
	{ lat: 58, lon: -110.19, elevation: 356 },
	{ lat: 58, lon: -108.82, elevation: 496 },
	{ lat: 58, lon: -107.45, elevation: 536 },
	{ lat: 58, lon: -106.08, elevation: 426 },
	{ lat: 58, lon: -104.71, elevation: 502 },
	{ lat: 58, lon: -103.35, elevation: 412 },
	{ lat: 58, lon: -101.98, elevation: 357 },
	{ lat: 58, lon: -100.61, elevation: 394 },
	{ lat: 58, lon: -99.24, elevation: 302 },
	{ lat: 58, lon: -97.87, elevation: 331 },
	{ lat: 58, lon: -96.5, elevation: 284 },
	{ lat: 58, lon: -95.13, elevation: 183 },
	{ lat: 58, lon: -93.76, elevation: 53 },
	{ lat: 58, lon: -75.97, elevation: 173 },
	{ lat: 58, lon: -74.6, elevation: 212 },
	{ lat: 58, lon: -73.23, elevation: 228 },
	{ lat: 58, lon: -71.86, elevation: 265 },
	{ lat: 58, lon: -70.49, elevation: 310 },
	{ lat: 58, lon: -69.13, elevation: 192 },
	{ lat: 58, lon: -67.76, elevation: 107 },
	{ lat: 58, lon: -66.39, elevation: 152 },
	{ lat: 58, lon: -65.02, elevation: 397 },
	{ lat: 58, lon: -63.65, elevation: 782 },
	{ lat: 58, lon: -4.79, elevation: 195 },
	{ lat: 58, lon: 11.63, elevation: 45 },
	{ lat: 58, lon: 13.0, elevation: 153 },
	{ lat: 58, lon: 14.37, elevation: 88 },
	{ lat: 58, lon: 15.74, elevation: 127 },
	{ lat: 58, lon: 25.32, elevation: 70 },
	{ lat: 58, lon: 26.69, elevation: 154 },
	{ lat: 58, lon: 28.06, elevation: 28 },
	{ lat: 58, lon: 29.43, elevation: 67 },
	{ lat: 58, lon: 30.8, elevation: 67 },
	{ lat: 58, lon: 32.17, elevation: 94 },
	{ lat: 58, lon: 33.54, elevation: 200 },
	{ lat: 58, lon: 34.9, elevation: 187 },
	{ lat: 58, lon: 36.27, elevation: 152 },
	{ lat: 58, lon: 37.64, elevation: 159 },
	{ lat: 58, lon: 39.01, elevation: 124 },
	{ lat: 58, lon: 40.38, elevation: 134 },
	{ lat: 58, lon: 41.75, elevation: 167 },
	{ lat: 58, lon: 43.12, elevation: 122 },
	{ lat: 58, lon: 44.49, elevation: 138 },
	{ lat: 58, lon: 45.86, elevation: 135 },
	{ lat: 58, lon: 47.22, elevation: 108 },
	{ lat: 58, lon: 48.59, elevation: 108 },
	{ lat: 58, lon: 49.96, elevation: 188 },
	{ lat: 58, lon: 51.33, elevation: 172 },
	{ lat: 58, lon: 52.7, elevation: 179 },
	{ lat: 58, lon: 54.07, elevation: 217 },
	{ lat: 58, lon: 55.44, elevation: 166 },
	{ lat: 58, lon: 56.81, elevation: 104 },
	{ lat: 58, lon: 58.17, elevation: 309 },
	{ lat: 58, lon: 59.54, elevation: 352 },
	{ lat: 58, lon: 60.91, elevation: 219 },
	{ lat: 58, lon: 62.28, elevation: 131 },
	{ lat: 58, lon: 63.65, elevation: 96 },
	{ lat: 58, lon: 65.02, elevation: 88 },
	{ lat: 58, lon: 66.39, elevation: 51 },
	{ lat: 58, lon: 67.76, elevation: 57 },
	{ lat: 58, lon: 69.13, elevation: 47 },
	{ lat: 58, lon: 70.49, elevation: 102 },
	{ lat: 58, lon: 71.86, elevation: 105 },
	{ lat: 58, lon: 73.23, elevation: 110 },
	{ lat: 58, lon: 74.6, elevation: 128 },
	{ lat: 58, lon: 75.97, elevation: 117 },
	{ lat: 58, lon: 77.34, elevation: 126 },
	{ lat: 58, lon: 78.71, elevation: 112 },
	{ lat: 58, lon: 80.08, elevation: 107 },
	{ lat: 58, lon: 81.44, elevation: 116 },
	{ lat: 58, lon: 82.81, elevation: 109 },
	{ lat: 58, lon: 84.18, elevation: 82 },
	{ lat: 58, lon: 85.55, elevation: 163 },
	{ lat: 58, lon: 86.92, elevation: 129 },
	{ lat: 58, lon: 88.29, elevation: 159 },
	{ lat: 58, lon: 89.66, elevation: 156 },
	{ lat: 58, lon: 91.03, elevation: 190 },
	{ lat: 58, lon: 92.4, elevation: 196 },
	{ lat: 58, lon: 93.76, elevation: 202 },
	{ lat: 58, lon: 95.13, elevation: 324 },
	{ lat: 58, lon: 96.5, elevation: 360 },
	{ lat: 58, lon: 97.87, elevation: 259 },
	{ lat: 58, lon: 99.24, elevation: 263 },
	{ lat: 58, lon: 100.61, elevation: 288 },
	{ lat: 58, lon: 101.98, elevation: 354 },
	{ lat: 58, lon: 103.35, elevation: 519 },
	{ lat: 58, lon: 104.71, elevation: 518 },
	{ lat: 58, lon: 106.08, elevation: 524 },
	{ lat: 58, lon: 107.45, elevation: 489 },
	{ lat: 58, lon: 108.82, elevation: 452 },
	{ lat: 58, lon: 110.19, elevation: 465 },
	{ lat: 58, lon: 111.56, elevation: 555 },
	{ lat: 58, lon: 112.93, elevation: 682 },
	{ lat: 58, lon: 114.3, elevation: 551 },
	{ lat: 58, lon: 115.67, elevation: 1272 },
	{ lat: 58, lon: 117.03, elevation: 1003 },
	{ lat: 58, lon: 118.4, elevation: 553 },
	{ lat: 58, lon: 119.77, elevation: 439 },
	{ lat: 58, lon: 121.14, elevation: 697 },
	{ lat: 58, lon: 122.51, elevation: 819 },
	{ lat: 58, lon: 123.88, elevation: 740 },
	{ lat: 58, lon: 125.25, elevation: 982 },
	{ lat: 58, lon: 126.62, elevation: 1071 },
	{ lat: 58, lon: 127.98, elevation: 856 },
	{ lat: 58, lon: 129.35, elevation: 1453 },
	{ lat: 58, lon: 130.72, elevation: 817 },
	{ lat: 58, lon: 132.09, elevation: 831 },
	{ lat: 58, lon: 133.46, elevation: 434 },
	{ lat: 58, lon: 134.83, elevation: 623 },
	{ lat: 58, lon: 136.2, elevation: 462 },
	{ lat: 58, lon: 137.57, elevation: 858 },
	{ lat: 58, lon: 138.94, elevation: 782 },
	{ lat: 58, lon: 140.3, elevation: 456 },
	{ lat: 58, lon: 159.47, elevation: 89 },
	{ lat: 58, lon: 160.84, elevation: 1445 },
	{ lat: 59, lon: -161.72, elevation: 89 },
	{ lat: 59, lon: -158.91, elevation: 28 },
	{ lat: 59, lon: -157.5, elevation: 44 },
	{ lat: 59, lon: -156.09, elevation: 100 },
	{ lat: 59, lon: -154.69, elevation: 856 },
	{ lat: 59, lon: -137.81, elevation: 802 },
	{ lat: 59, lon: -136.41, elevation: 324 },
	{ lat: 59, lon: -135.0, elevation: 400 },
	{ lat: 59, lon: -133.59, elevation: 1367 },
	{ lat: 59, lon: -132.19, elevation: 943 },
	{ lat: 59, lon: -130.78, elevation: 1220 },
	{ lat: 59, lon: -129.38, elevation: 1377 },
	{ lat: 59, lon: -127.97, elevation: 1589 },
	{ lat: 59, lon: -126.56, elevation: 1510 },
	{ lat: 59, lon: -125.16, elevation: 1072 },
	{ lat: 59, lon: -123.75, elevation: 653 },
	{ lat: 59, lon: -122.34, elevation: 368 },
	{ lat: 59, lon: -120.94, elevation: 653 },
	{ lat: 59, lon: -119.53, elevation: 415 },
	{ lat: 59, lon: -118.12, elevation: 352 },
	{ lat: 59, lon: -116.72, elevation: 511 },
	{ lat: 59, lon: -115.31, elevation: 837 },
	{ lat: 59, lon: -113.91, elevation: 502 },
	{ lat: 59, lon: -112.5, elevation: 249 },
	{ lat: 59, lon: -111.09, elevation: 248 },
	{ lat: 59, lon: -109.69, elevation: 230 },
	{ lat: 59, lon: -108.28, elevation: 344 },
	{ lat: 59, lon: -106.88, elevation: 431 },
	{ lat: 59, lon: -105.47, elevation: 319 },
	{ lat: 59, lon: -104.06, elevation: 364 },
	{ lat: 59, lon: -102.66, elevation: 414 },
	{ lat: 59, lon: -101.25, elevation: 373 },
	{ lat: 59, lon: -99.84, elevation: 294 },
	{ lat: 59, lon: -98.44, elevation: 293 },
	{ lat: 59, lon: -97.03, elevation: 214 },
	{ lat: 59, lon: -95.62, elevation: 103 },
	{ lat: 59, lon: -77.34, elevation: 68 },
	{ lat: 59, lon: -75.94, elevation: 158 },
	{ lat: 59, lon: -74.53, elevation: 164 },
	{ lat: 59, lon: -73.12, elevation: 191 },
	{ lat: 59, lon: -71.72, elevation: 233 },
	{ lat: 59, lon: -70.31, elevation: 175 },
	{ lat: 59, lon: -64.69, elevation: 718 },
	{ lat: 59, lon: -63.28, elevation: 405 },
	{ lat: 59, lon: 5.62, elevation: 37 },
	{ lat: 59, lon: 7.03, elevation: 1069 },
	{ lat: 59, lon: 8.44, elevation: 486 },
	{ lat: 59, lon: 11.25, elevation: 74 },
	{ lat: 59, lon: 12.66, elevation: 83 },
	{ lat: 59, lon: 14.06, elevation: 70 },
	{ lat: 59, lon: 15.47, elevation: 142 },
	{ lat: 59, lon: 16.88, elevation: 26 },
	{ lat: 59, lon: 22.5, elevation: 6 },
	{ lat: 59, lon: 23.91, elevation: 45 },
	{ lat: 59, lon: 25.31, elevation: 86 },
	{ lat: 59, lon: 26.72, elevation: 77 },
	{ lat: 59, lon: 28.12, elevation: 58 },
	{ lat: 59, lon: 29.53, elevation: 85 },
	{ lat: 59, lon: 30.94, elevation: 73 },
	{ lat: 59, lon: 32.34, elevation: 89 },
	{ lat: 59, lon: 33.75, elevation: 227 },
	{ lat: 59, lon: 35.16, elevation: 151 },
	{ lat: 59, lon: 36.56, elevation: 119 },
	{ lat: 59, lon: 37.97, elevation: 107 },
	{ lat: 59, lon: 39.38, elevation: 179 },
	{ lat: 59, lon: 40.78, elevation: 132 },
	{ lat: 59, lon: 42.19, elevation: 142 },
	{ lat: 59, lon: 43.59, elevation: 151 },
	{ lat: 59, lon: 45.0, elevation: 177 },
	{ lat: 59, lon: 46.41, elevation: 211 },
	{ lat: 59, lon: 47.81, elevation: 133 },
	{ lat: 59, lon: 49.22, elevation: 134 },
	{ lat: 59, lon: 50.62, elevation: 183 },
	{ lat: 59, lon: 52.03, elevation: 231 },
	{ lat: 59, lon: 53.44, elevation: 220 },
	{ lat: 59, lon: 54.84, elevation: 126 },
	{ lat: 59, lon: 56.25, elevation: 104 },
	{ lat: 59, lon: 57.66, elevation: 364 },
	{ lat: 59, lon: 59.06, elevation: 579 },
	{ lat: 59, lon: 60.47, elevation: 115 },
	{ lat: 59, lon: 61.88, elevation: 91 },
	{ lat: 59, lon: 63.28, elevation: 88 },
	{ lat: 59, lon: 64.69, elevation: 87 },
	{ lat: 59, lon: 66.09, elevation: 95 },
	{ lat: 59, lon: 67.5, elevation: 45 },
	{ lat: 59, lon: 68.91, elevation: 44 },
	{ lat: 59, lon: 70.31, elevation: 95 },
	{ lat: 59, lon: 71.72, elevation: 74 },
	{ lat: 59, lon: 73.12, elevation: 90 },
	{ lat: 59, lon: 74.53, elevation: 89 },
	{ lat: 59, lon: 75.94, elevation: 101 },
	{ lat: 59, lon: 77.34, elevation: 101 },
	{ lat: 59, lon: 78.75, elevation: 78 },
	{ lat: 59, lon: 80.16, elevation: 50 },
	{ lat: 59, lon: 81.56, elevation: 59 },
	{ lat: 59, lon: 82.97, elevation: 85 },
	{ lat: 59, lon: 84.38, elevation: 112 },
	{ lat: 59, lon: 85.78, elevation: 115 },
	{ lat: 59, lon: 87.19, elevation: 117 },
	{ lat: 59, lon: 88.59, elevation: 178 },
	{ lat: 59, lon: 90.0, elevation: 153 },
	{ lat: 59, lon: 91.41, elevation: 101 },
	{ lat: 59, lon: 92.81, elevation: 306 },
	{ lat: 59, lon: 94.22, elevation: 409 },
	{ lat: 59, lon: 95.62, elevation: 463 },
	{ lat: 59, lon: 97.03, elevation: 490 },
	{ lat: 59, lon: 98.44, elevation: 277 },
	{ lat: 59, lon: 99.84, elevation: 312 },
	{ lat: 59, lon: 101.25, elevation: 211 },
	{ lat: 59, lon: 102.66, elevation: 302 },
	{ lat: 59, lon: 104.06, elevation: 406 },
	{ lat: 59, lon: 105.47, elevation: 517 },
	{ lat: 59, lon: 106.88, elevation: 459 },
	{ lat: 59, lon: 108.28, elevation: 386 },
	{ lat: 59, lon: 109.69, elevation: 456 },
	{ lat: 59, lon: 111.09, elevation: 244 },
	{ lat: 59, lon: 112.5, elevation: 376 },
	{ lat: 59, lon: 113.91, elevation: 919 },
	{ lat: 59, lon: 115.31, elevation: 1147 },
	{ lat: 59, lon: 116.72, elevation: 1049 },
	{ lat: 59, lon: 118.12, elevation: 463 },
	{ lat: 59, lon: 119.53, elevation: 386 },
	{ lat: 59, lon: 120.94, elevation: 551 },
	{ lat: 59, lon: 122.34, elevation: 476 },
	{ lat: 59, lon: 123.75, elevation: 476 },
	{ lat: 59, lon: 125.16, elevation: 297 },
	{ lat: 59, lon: 126.56, elevation: 541 },
	{ lat: 59, lon: 127.97, elevation: 618 },
	{ lat: 59, lon: 129.38, elevation: 411 },
	{ lat: 59, lon: 130.78, elevation: 281 },
	{ lat: 59, lon: 132.19, elevation: 327 },
	{ lat: 59, lon: 133.59, elevation: 329 },
	{ lat: 59, lon: 135.0, elevation: 515 },
	{ lat: 59, lon: 136.41, elevation: 690 },
	{ lat: 59, lon: 137.81, elevation: 777 },
	{ lat: 59, lon: 139.22, elevation: 662 },
	{ lat: 59, lon: 140.62, elevation: 187 },
	{ lat: 59, lon: 142.03, elevation: 8 },
	{ lat: 59, lon: 151.88, elevation: 588 },
	{ lat: 59, lon: 160.31, elevation: 262 },
	{ lat: 59, lon: 161.72, elevation: 607 },
	{ lat: 59, lon: 164.53, elevation: 222 },
	{ lat: 60, lon: -166.99, elevation: 0 },
	{ lat: 60, lon: -164.1, elevation: 0 },
	{ lat: 60, lon: -162.65, elevation: 0 },
	{ lat: 60, lon: -161.2, elevation: 0 },
	{ lat: 60, lon: -159.76, elevation: 0 },
	{ lat: 60, lon: -158.31, elevation: 0 },
	{ lat: 60, lon: -156.87, elevation: 0 },
	{ lat: 60, lon: -155.42, elevation: 0 },
	{ lat: 60, lon: -153.98, elevation: 0 },
	{ lat: 60, lon: -151.08, elevation: 0 },
	{ lat: 60, lon: -149.64, elevation: 0 },
	{ lat: 60, lon: -143.86, elevation: 0 },
	{ lat: 60, lon: -140.96, elevation: 0 },
	{ lat: 60, lon: -138.07, elevation: 0 },
	{ lat: 60, lon: -136.63, elevation: 0 },
	{ lat: 60, lon: -135.18, elevation: 0 },
	{ lat: 60, lon: -133.73, elevation: 0 },
	{ lat: 60, lon: -132.29, elevation: 0 },
	{ lat: 60, lon: -130.84, elevation: 0 },
	{ lat: 60, lon: -129.4, elevation: 0 },
	{ lat: 60, lon: -127.95, elevation: 0 },
	{ lat: 60, lon: -126.51, elevation: 0 },
	{ lat: 60, lon: -125.06, elevation: 0 },
	{ lat: 60, lon: -123.61, elevation: 0 },
	{ lat: 60, lon: -122.17, elevation: 0 },
	{ lat: 60, lon: -120.72, elevation: 0 },
	{ lat: 60, lon: -119.28, elevation: 0 },
	{ lat: 60, lon: -117.83, elevation: 0 },
	{ lat: 60, lon: -116.39, elevation: 0 },
	{ lat: 60, lon: -114.94, elevation: 0 },
	{ lat: 60, lon: -113.49, elevation: 0 },
	{ lat: 60, lon: -112.05, elevation: 0 },
	{ lat: 60, lon: -110.6, elevation: 0 },
	{ lat: 60, lon: -109.16, elevation: 0 },
	{ lat: 60, lon: -107.71, elevation: 0 },
	{ lat: 60, lon: -106.27, elevation: 0 },
	{ lat: 60, lon: -104.82, elevation: 0 },
	{ lat: 60, lon: -103.37, elevation: 0 },
	{ lat: 60, lon: -101.93, elevation: 0 },
	{ lat: 60, lon: -100.48, elevation: 0 },
	{ lat: 60, lon: -99.04, elevation: 0 },
	{ lat: 60, lon: -97.59, elevation: 0 },
	{ lat: 60, lon: -96.14, elevation: 0 },
	{ lat: 60, lon: -75.9, elevation: 0 },
	{ lat: 60, lon: -74.46, elevation: 0 },
	{ lat: 60, lon: -73.01, elevation: 0 },
	{ lat: 60, lon: -71.57, elevation: 0 },
	{ lat: 60, lon: -44.1, elevation: 0 },
	{ lat: 60, lon: 5.06, elevation: 0 },
	{ lat: 60, lon: 6.51, elevation: 0 },
	{ lat: 60, lon: 7.95, elevation: 0 },
	{ lat: 60, lon: 9.4, elevation: 0 },
	{ lat: 60, lon: 10.84, elevation: 0 },
	{ lat: 60, lon: 12.29, elevation: 0 },
	{ lat: 60, lon: 13.73, elevation: 0 },
	{ lat: 60, lon: 15.18, elevation: 0 },
	{ lat: 60, lon: 16.63, elevation: 0 },
	{ lat: 60, lon: 18.07, elevation: 0 },
	{ lat: 60, lon: 23.86, elevation: 0 },
	{ lat: 60, lon: 31.08, elevation: 0 },
	{ lat: 60, lon: 32.53, elevation: 0 },
	{ lat: 60, lon: 33.98, elevation: 0 },
	{ lat: 60, lon: 35.42, elevation: 0 },
	{ lat: 60, lon: 36.87, elevation: 0 },
	{ lat: 60, lon: 38.31, elevation: 0 },
	{ lat: 60, lon: 39.76, elevation: 0 },
	{ lat: 60, lon: 41.2, elevation: 0 },
	{ lat: 60, lon: 42.65, elevation: 0 },
	{ lat: 60, lon: 44.1, elevation: 0 },
	{ lat: 60, lon: 45.54, elevation: 0 },
	{ lat: 60, lon: 46.99, elevation: 0 },
	{ lat: 60, lon: 48.43, elevation: 0 },
	{ lat: 60, lon: 49.88, elevation: 0 },
	{ lat: 60, lon: 51.33, elevation: 0 },
	{ lat: 60, lon: 52.77, elevation: 0 },
	{ lat: 60, lon: 54.22, elevation: 0 },
	{ lat: 60, lon: 55.66, elevation: 0 },
	{ lat: 60, lon: 57.11, elevation: 0 },
	{ lat: 60, lon: 58.55, elevation: 0 },
	{ lat: 60, lon: 60.0, elevation: 0 },
	{ lat: 60, lon: 61.45, elevation: 0 },
	{ lat: 60, lon: 62.89, elevation: 0 },
	{ lat: 60, lon: 64.34, elevation: 0 },
	{ lat: 60, lon: 65.78, elevation: 0 },
	{ lat: 60, lon: 67.23, elevation: 0 },
	{ lat: 60, lon: 68.67, elevation: 0 },
	{ lat: 60, lon: 70.12, elevation: 0 },
	{ lat: 60, lon: 71.57, elevation: 0 },
	{ lat: 60, lon: 73.01, elevation: 0 },
	{ lat: 60, lon: 74.46, elevation: 0 },
	{ lat: 60, lon: 75.9, elevation: 0 },
	{ lat: 60, lon: 77.35, elevation: 0 },
	{ lat: 60, lon: 78.8, elevation: 0 },
	{ lat: 60, lon: 80.24, elevation: 0 },
	{ lat: 60, lon: 81.69, elevation: 0 },
	{ lat: 60, lon: 83.13, elevation: 0 },
	{ lat: 60, lon: 84.58, elevation: 0 },
	{ lat: 60, lon: 86.02, elevation: 0 },
	{ lat: 60, lon: 87.47, elevation: 0 },
	{ lat: 60, lon: 88.92, elevation: 0 },
	{ lat: 60, lon: 90.36, elevation: 0 },
	{ lat: 60, lon: 91.81, elevation: 0 },
	{ lat: 60, lon: 93.25, elevation: 0 },
	{ lat: 60, lon: 94.7, elevation: 0 },
	{ lat: 60, lon: 96.14, elevation: 0 },
	{ lat: 60, lon: 97.59, elevation: 0 },
	{ lat: 60, lon: 99.04, elevation: 0 },
	{ lat: 60, lon: 100.48, elevation: 0 },
	{ lat: 60, lon: 101.93, elevation: 0 },
	{ lat: 60, lon: 103.37, elevation: 0 },
	{ lat: 60, lon: 104.82, elevation: 0 },
	{ lat: 60, lon: 106.27, elevation: 0 },
	{ lat: 60, lon: 107.71, elevation: 0 },
	{ lat: 60, lon: 109.16, elevation: 0 },
	{ lat: 60, lon: 110.6, elevation: 0 },
	{ lat: 60, lon: 112.05, elevation: 0 },
	{ lat: 60, lon: 113.49, elevation: 0 },
	{ lat: 60, lon: 114.94, elevation: 0 },
	{ lat: 60, lon: 116.39, elevation: 0 },
	{ lat: 60, lon: 117.83, elevation: 0 },
	{ lat: 60, lon: 119.28, elevation: 0 },
	{ lat: 60, lon: 120.72, elevation: 0 },
	{ lat: 60, lon: 122.17, elevation: 0 },
	{ lat: 60, lon: 123.61, elevation: 0 },
	{ lat: 60, lon: 125.06, elevation: 0 },
	{ lat: 60, lon: 126.51, elevation: 0 },
	{ lat: 60, lon: 127.95, elevation: 0 },
	{ lat: 60, lon: 129.4, elevation: 0 },
	{ lat: 60, lon: 130.84, elevation: 0 },
	{ lat: 60, lon: 132.29, elevation: 0 },
	{ lat: 60, lon: 133.73, elevation: 0 },
	{ lat: 60, lon: 135.18, elevation: 0 },
	{ lat: 60, lon: 136.63, elevation: 0 },
	{ lat: 60, lon: 138.07, elevation: 0 },
	{ lat: 60, lon: 139.52, elevation: 0 },
	{ lat: 60, lon: 140.96, elevation: 0 },
	{ lat: 60, lon: 142.41, elevation: 0 },
	{ lat: 60, lon: 143.86, elevation: 0 },
	{ lat: 60, lon: 145.3, elevation: 0 },
	{ lat: 60, lon: 146.75, elevation: 0 },
	{ lat: 60, lon: 148.19, elevation: 0 },
	{ lat: 60, lon: 149.64, elevation: 0 },
	{ lat: 60, lon: 151.08, elevation: 0 },
	{ lat: 60, lon: 152.53, elevation: 0 },
	{ lat: 60, lon: 153.98, elevation: 0 },
	{ lat: 60, lon: 162.65, elevation: 0 },
	{ lat: 60, lon: 164.1, elevation: 0 },
	{ lat: 61, lon: -165.06, elevation: 0 },
	{ lat: 61, lon: -163.57, elevation: 48 },
	{ lat: 61, lon: -162.07, elevation: 0 },
	{ lat: 61, lon: -160.58, elevation: 0 },
	{ lat: 61, lon: -159.09, elevation: 0 },
	{ lat: 61, lon: -157.59, elevation: 0 },
	{ lat: 61, lon: -156.1, elevation: 0 },
	{ lat: 61, lon: -154.61, elevation: 0 },
	{ lat: 61, lon: -153.11, elevation: 0 },
	{ lat: 61, lon: -151.62, elevation: 0 },
	{ lat: 61, lon: -148.63, elevation: 0 },
	{ lat: 61, lon: -147.14, elevation: 0 },
	{ lat: 61, lon: -145.64, elevation: 0 },
	{ lat: 61, lon: -144.15, elevation: 0 },
	{ lat: 61, lon: -142.66, elevation: 0 },
	{ lat: 61, lon: -141.16, elevation: 0 },
	{ lat: 61, lon: -139.67, elevation: 0 },
	{ lat: 61, lon: -138.17, elevation: 0 },
	{ lat: 61, lon: -136.68, elevation: 0 },
	{ lat: 61, lon: -135.19, elevation: 0 },
	{ lat: 61, lon: -133.69, elevation: 0 },
	{ lat: 61, lon: -132.2, elevation: 0 },
	{ lat: 61, lon: -130.71, elevation: 512 },
	{ lat: 61, lon: -129.21, elevation: 1473 },
	{ lat: 61, lon: -127.72, elevation: 571 },
	{ lat: 61, lon: -126.22, elevation: 782 },
	{ lat: 61, lon: -124.73, elevation: 956 },
	{ lat: 61, lon: -123.24, elevation: 987 },
	{ lat: 61, lon: -121.74, elevation: 983 },
	{ lat: 61, lon: -120.25, elevation: 1007 },
	{ lat: 61, lon: -118.76, elevation: 658 },
	{ lat: 61, lon: -117.26, elevation: 667 },
	{ lat: 61, lon: -115.77, elevation: 954 },
	{ lat: 61, lon: -114.27, elevation: 607 },
	{ lat: 61, lon: -112.78, elevation: 555 },
	{ lat: 61, lon: -111.29, elevation: 709 },
	{ lat: 61, lon: -109.79, elevation: 595 },
	{ lat: 61, lon: -108.3, elevation: 489 },
	{ lat: 61, lon: -106.8, elevation: 489 },
	{ lat: 61, lon: -105.31, elevation: 375 },
	{ lat: 61, lon: -103.82, elevation: 366 },
	{ lat: 61, lon: -102.32, elevation: 337 },
	{ lat: 61, lon: -100.83, elevation: 342 },
	{ lat: 61, lon: -99.34, elevation: 267 },
	{ lat: 61, lon: -97.84, elevation: 185 },
	{ lat: 61, lon: -96.35, elevation: 227 },
	{ lat: 61, lon: -94.85, elevation: 201 },
	{ lat: 61, lon: -76.93, elevation: 194 },
	{ lat: 61, lon: -75.44, elevation: 307 },
	{ lat: 61, lon: -73.94, elevation: 381 },
	{ lat: 61, lon: -72.45, elevation: 397 },
	{ lat: 61, lon: -70.95, elevation: 450 },
	{ lat: 61, lon: -47.05, elevation: 0 },
	{ lat: 61, lon: -44.07, elevation: 0 },
	{ lat: 61, lon: 5.23, elevation: 0 },
	{ lat: 61, lon: 6.72, elevation: 0 },
	{ lat: 61, lon: 8.22, elevation: 0 },
	{ lat: 61, lon: 9.71, elevation: 0 },
	{ lat: 61, lon: 11.2, elevation: 0 },
	{ lat: 61, lon: 12.7, elevation: 0 },
	{ lat: 61, lon: 14.19, elevation: 0 },
	{ lat: 61, lon: 15.68, elevation: 0 },
	{ lat: 61, lon: 17.18, elevation: 0 },
	{ lat: 61, lon: 21.66, elevation: 2 },
	{ lat: 61, lon: 23.15, elevation: 60 },
	{ lat: 61, lon: 24.65, elevation: 108 },
	{ lat: 61, lon: 26.14, elevation: 175 },
	{ lat: 61, lon: 27.63, elevation: 179 },
	{ lat: 61, lon: 29.13, elevation: 131 },
	{ lat: 61, lon: 30.62, elevation: 174 },
	{ lat: 61, lon: 32.12, elevation: 238 },
	{ lat: 61, lon: 33.61, elevation: 192 },
	{ lat: 61, lon: 35.1, elevation: 180 },
	{ lat: 61, lon: 36.6, elevation: 209 },
	{ lat: 61, lon: 38.09, elevation: 164 },
	{ lat: 61, lon: 39.59, elevation: 117 },
	{ lat: 61, lon: 41.08, elevation: 113 },
	{ lat: 61, lon: 42.57, elevation: 151 },
	{ lat: 61, lon: 44.07, elevation: 217 },
	{ lat: 61, lon: 45.56, elevation: 241 },
	{ lat: 61, lon: 47.05, elevation: 241 },
	{ lat: 61, lon: 48.55, elevation: 179 },
	{ lat: 61, lon: 50.04, elevation: 159 },
	{ lat: 61, lon: 51.54, elevation: 168 },
	{ lat: 61, lon: 53.03, elevation: 226 },
	{ lat: 61, lon: 54.52, elevation: 256 },
	{ lat: 61, lon: 56.02, elevation: 81 },
	{ lat: 61, lon: 57.51, elevation: 358 },
	{ lat: 61, lon: 59.0, elevation: 619 },
	{ lat: 61, lon: 60.5, elevation: 329 },
	{ lat: 61, lon: 61.99, elevation: 178 },
	{ lat: 61, lon: 63.49, elevation: 176 },
	{ lat: 61, lon: 64.98, elevation: 71 },
	{ lat: 61, lon: 66.47, elevation: 143 },
	{ lat: 61, lon: 67.97, elevation: 137 },
	{ lat: 61, lon: 69.46, elevation: 135 },
	{ lat: 61, lon: 70.95, elevation: 131 },
	{ lat: 61, lon: 72.45, elevation: 114 },
	{ lat: 61, lon: 73.94, elevation: 107 },
	{ lat: 61, lon: 75.44, elevation: 111 },
	{ lat: 61, lon: 76.93, elevation: 107 },
	{ lat: 61, lon: 78.42, elevation: 106 },
	{ lat: 61, lon: 79.92, elevation: 139 },
	{ lat: 61, lon: 81.41, elevation: 161 },
	{ lat: 61, lon: 82.9, elevation: 113 },
	{ lat: 61, lon: 84.4, elevation: 229 },
	{ lat: 61, lon: 85.89, elevation: 220 },
	{ lat: 61, lon: 87.39, elevation: 277 },
	{ lat: 61, lon: 88.88, elevation: 838 },
	{ lat: 61, lon: 90.37, elevation: 348 },
	{ lat: 61, lon: 91.87, elevation: 219 },
	{ lat: 61, lon: 93.36, elevation: 640 },
	{ lat: 61, lon: 94.85, elevation: 621 },
	{ lat: 61, lon: 96.35, elevation: 609 },
	{ lat: 61, lon: 97.84, elevation: 894 },
	{ lat: 61, lon: 99.34, elevation: 556 },
	{ lat: 61, lon: 100.83, elevation: 542 },
	{ lat: 61, lon: 102.32, elevation: 574 },
	{ lat: 61, lon: 103.82, elevation: 738 },
	{ lat: 61, lon: 105.31, elevation: 796 },
	{ lat: 61, lon: 106.8, elevation: 720 },
	{ lat: 61, lon: 108.3, elevation: 772 },
	{ lat: 61, lon: 109.79, elevation: 803 },
	{ lat: 61, lon: 111.29, elevation: 1421 },
	{ lat: 61, lon: 112.78, elevation: 1580 },
	{ lat: 61, lon: 114.27, elevation: 1484 },
	{ lat: 61, lon: 115.77, elevation: 837 },
	{ lat: 61, lon: 117.26, elevation: 981 },
	{ lat: 61, lon: 118.76, elevation: 797 },
	{ lat: 61, lon: 120.25, elevation: 688 },
	{ lat: 61, lon: 121.74, elevation: 603 },
	{ lat: 61, lon: 123.24, elevation: 1263 },
	{ lat: 61, lon: 124.73, elevation: 739 },
	{ lat: 61, lon: 126.22, elevation: 592 },
	{ lat: 61, lon: 127.72, elevation: 526 },
	{ lat: 61, lon: 129.21, elevation: 651 },
	{ lat: 61, lon: 130.71, elevation: 673 },
	{ lat: 61, lon: 132.2, elevation: 992 },
	{ lat: 61, lon: 133.69, elevation: 533 },
	{ lat: 61, lon: 135.19, elevation: 412 },
	{ lat: 61, lon: 136.68, elevation: 0 },
	{ lat: 61, lon: 138.17, elevation: 198 },
	{ lat: 61, lon: 139.67, elevation: 0 },
	{ lat: 61, lon: 141.16, elevation: 0 },
	{ lat: 61, lon: 142.66, elevation: 0 },
	{ lat: 61, lon: 144.15, elevation: 0 },
	{ lat: 61, lon: 145.64, elevation: 0 },
	{ lat: 61, lon: 147.14, elevation: 0 },
	{ lat: 61, lon: 148.63, elevation: 0 },
	{ lat: 61, lon: 150.12, elevation: 0 },
	{ lat: 61, lon: 151.62, elevation: 0 },
	{ lat: 61, lon: 153.11, elevation: 0 },
	{ lat: 61, lon: 154.61, elevation: 0 },
	{ lat: 61, lon: 156.1, elevation: 106 },
	{ lat: 61, lon: 160.58, elevation: 873 },
	{ lat: 61, lon: 163.57, elevation: 0 },
	{ lat: 61, lon: 165.06, elevation: 0 },
	{ lat: 61, lon: 166.56, elevation: 0 },
	{ lat: 61, lon: 168.05, elevation: 0 },
	{ lat: 61, lon: 169.54, elevation: 0 },
	{ lat: 61, lon: 171.04, elevation: 0 },
	{ lat: 62, lon: -164.55, elevation: 0 },
	{ lat: 62, lon: -163.0, elevation: 0 },
	{ lat: 62, lon: -161.46, elevation: 0 },
	{ lat: 62, lon: -159.91, elevation: 746 },
	{ lat: 62, lon: -158.37, elevation: 0 },
	{ lat: 62, lon: -156.82, elevation: 0 },
	{ lat: 62, lon: -155.28, elevation: 0 },
	{ lat: 62, lon: -153.73, elevation: 0 },
	{ lat: 62, lon: -152.19, elevation: 0 },
	{ lat: 62, lon: -150.64, elevation: 0 },
	{ lat: 62, lon: -149.1, elevation: 0 },
	{ lat: 62, lon: -147.55, elevation: 0 },
	{ lat: 62, lon: -146.01, elevation: 0 },
	{ lat: 62, lon: -144.46, elevation: 0 },
	{ lat: 62, lon: -142.92, elevation: 0 },
	{ lat: 62, lon: -141.37, elevation: 0 },
	{ lat: 62, lon: -139.83, elevation: 0 },
	{ lat: 62, lon: -138.28, elevation: 0 },
	{ lat: 62, lon: -136.74, elevation: 0 },
	{ lat: 62, lon: -135.19, elevation: 0 },
	{ lat: 62, lon: -133.65, elevation: 113 },
	{ lat: 62, lon: -132.1, elevation: 0 },
	{ lat: 62, lon: -130.56, elevation: 1544 },
	{ lat: 62, lon: -129.01, elevation: 666 },
	{ lat: 62, lon: -127.47, elevation: 1349 },
	{ lat: 62, lon: -125.92, elevation: 1062 },
	{ lat: 62, lon: -124.38, elevation: 1322 },
	{ lat: 62, lon: -122.83, elevation: 1236 },
	{ lat: 62, lon: -121.29, elevation: 655 },
	{ lat: 62, lon: -119.74, elevation: 641 },
	{ lat: 62, lon: -118.2, elevation: 603 },
	{ lat: 62, lon: -116.65, elevation: 738 },
	{ lat: 62, lon: -115.11, elevation: 653 },
	{ lat: 62, lon: -113.56, elevation: 596 },
	{ lat: 62, lon: -112.02, elevation: 675 },
	{ lat: 62, lon: -110.47, elevation: 442 },
	{ lat: 62, lon: -108.93, elevation: 415 },
	{ lat: 62, lon: -107.38, elevation: 406 },
	{ lat: 62, lon: -105.84, elevation: 446 },
	{ lat: 62, lon: -104.29, elevation: 492 },
	{ lat: 62, lon: -102.75, elevation: 402 },
	{ lat: 62, lon: -101.2, elevation: 333 },
	{ lat: 62, lon: -99.66, elevation: 327 },
	{ lat: 62, lon: -98.11, elevation: 244 },
	{ lat: 62, lon: -96.57, elevation: 188 },
	{ lat: 62, lon: -95.02, elevation: 167 },
	{ lat: 62, lon: -93.48, elevation: 84 },
	{ lat: 62, lon: -79.57, elevation: 13 },
	{ lat: 62, lon: -78.03, elevation: 0 },
	{ lat: 62, lon: -76.48, elevation: 79 },
	{ lat: 62, lon: -74.94, elevation: 288 },
	{ lat: 62, lon: -73.39, elevation: 339 },
	{ lat: 62, lon: -48.67, elevation: 0 },
	{ lat: 62, lon: -47.12, elevation: 0 },
	{ lat: 62, lon: -45.58, elevation: 0 },
	{ lat: 62, lon: -44.03, elevation: 0 },
	{ lat: 62, lon: -42.49, elevation: 0 },
	{ lat: 62, lon: 5.41, elevation: 0 },
	{ lat: 62, lon: 6.95, elevation: 0 },
	{ lat: 62, lon: 8.5, elevation: 16 },
	{ lat: 62, lon: 10.04, elevation: 58 },
	{ lat: 62, lon: 11.59, elevation: 0 },
	{ lat: 62, lon: 13.13, elevation: 135 },
	{ lat: 62, lon: 14.68, elevation: 10 },
	{ lat: 62, lon: 16.22, elevation: 0 },
	{ lat: 62, lon: 22.4, elevation: 121 },
	{ lat: 62, lon: 23.95, elevation: 51 },
	{ lat: 62, lon: 25.49, elevation: 102 },
	{ lat: 62, lon: 27.04, elevation: 174 },
	{ lat: 62, lon: 28.58, elevation: 146 },
	{ lat: 62, lon: 30.13, elevation: 165 },
	{ lat: 62, lon: 31.67, elevation: 200 },
	{ lat: 62, lon: 33.22, elevation: 274 },
	{ lat: 62, lon: 34.76, elevation: 245 },
	{ lat: 62, lon: 36.31, elevation: 268 },
	{ lat: 62, lon: 37.85, elevation: 145 },
	{ lat: 62, lon: 39.4, elevation: 164 },
	{ lat: 62, lon: 40.94, elevation: 136 },
	{ lat: 62, lon: 42.49, elevation: 88 },
	{ lat: 62, lon: 44.03, elevation: 132 },
	{ lat: 62, lon: 45.58, elevation: 81 },
	{ lat: 62, lon: 47.12, elevation: 192 },
	{ lat: 62, lon: 48.67, elevation: 178 },
	{ lat: 62, lon: 50.21, elevation: 150 },
	{ lat: 62, lon: 51.76, elevation: 89 },
	{ lat: 62, lon: 53.3, elevation: 151 },
	{ lat: 62, lon: 54.85, elevation: 163 },
	{ lat: 62, lon: 56.39, elevation: 211 },
	{ lat: 62, lon: 57.94, elevation: 251 },
	{ lat: 62, lon: 59.48, elevation: 354 },
	{ lat: 62, lon: 61.03, elevation: 239 },
	{ lat: 62, lon: 62.58, elevation: 150 },
	{ lat: 62, lon: 64.12, elevation: 124 },
	{ lat: 62, lon: 65.67, elevation: 62 },
	{ lat: 62, lon: 67.21, elevation: 134 },
	{ lat: 62, lon: 68.76, elevation: 130 },
	{ lat: 62, lon: 70.3, elevation: 134 },
	{ lat: 62, lon: 71.85, elevation: 103 },
	{ lat: 62, lon: 73.39, elevation: 96 },
	{ lat: 62, lon: 74.94, elevation: 136 },
	{ lat: 62, lon: 76.48, elevation: 104 },
	{ lat: 62, lon: 78.03, elevation: 122 },
	{ lat: 62, lon: 79.57, elevation: 133 },
	{ lat: 62, lon: 81.12, elevation: 135 },
	{ lat: 62, lon: 82.66, elevation: 132 },
	{ lat: 62, lon: 84.21, elevation: 134 },
	{ lat: 62, lon: 85.75, elevation: 204 },
	{ lat: 62, lon: 87.3, elevation: 186 },
	{ lat: 62, lon: 88.84, elevation: 285 },
	{ lat: 62, lon: 90.39, elevation: 245 },
	{ lat: 62, lon: 91.93, elevation: 472 },
	{ lat: 62, lon: 93.48, elevation: 390 },
	{ lat: 62, lon: 95.02, elevation: 292 },
	{ lat: 62, lon: 96.57, elevation: 426 },
	{ lat: 62, lon: 98.11, elevation: 279 },
	{ lat: 62, lon: 99.66, elevation: 293 },
	{ lat: 62, lon: 101.2, elevation: 391 },
	{ lat: 62, lon: 102.75, elevation: 451 },
	{ lat: 62, lon: 104.29, elevation: 566 },
	{ lat: 62, lon: 105.84, elevation: 373 },
	{ lat: 62, lon: 107.38, elevation: 478 },
	{ lat: 62, lon: 108.93, elevation: 1545 },
	{ lat: 62, lon: 110.47, elevation: 983 },
	{ lat: 62, lon: 112.02, elevation: 853 },
	{ lat: 62, lon: 113.56, elevation: 1969 },
	{ lat: 62, lon: 115.11, elevation: 1016 },
	{ lat: 62, lon: 116.65, elevation: 1258 },
	{ lat: 62, lon: 118.2, elevation: 1009 },
	{ lat: 62, lon: 119.74, elevation: 1244 },
	{ lat: 62, lon: 121.29, elevation: 949 },
	{ lat: 62, lon: 122.83, elevation: 813 },
	{ lat: 62, lon: 124.38, elevation: 1313 },
	{ lat: 62, lon: 125.92, elevation: 954 },
	{ lat: 62, lon: 127.47, elevation: 1013 },
	{ lat: 62, lon: 129.01, elevation: 1140 },
	{ lat: 62, lon: 130.56, elevation: 1327 },
	{ lat: 62, lon: 132.1, elevation: 1363 },
	{ lat: 62, lon: 133.65, elevation: 1412 },
	{ lat: 62, lon: 135.19, elevation: 700 },
	{ lat: 62, lon: 136.74, elevation: 504 },
	{ lat: 62, lon: 138.28, elevation: 0 },
	{ lat: 62, lon: 139.83, elevation: 0 },
	{ lat: 62, lon: 141.37, elevation: 0 },
	{ lat: 62, lon: 142.92, elevation: 0 },
	{ lat: 62, lon: 144.46, elevation: 0 },
	{ lat: 62, lon: 146.01, elevation: 0 },
	{ lat: 62, lon: 147.55, elevation: 0 },
	{ lat: 62, lon: 149.1, elevation: 0 },
	{ lat: 62, lon: 150.64, elevation: 0 },
	{ lat: 62, lon: 152.19, elevation: 0 },
	{ lat: 62, lon: 153.73, elevation: 0 },
	{ lat: 62, lon: 155.28, elevation: 0 },
	{ lat: 62, lon: 156.82, elevation: 197 },
	{ lat: 62, lon: 158.37, elevation: 771 },
	{ lat: 62, lon: 159.91, elevation: 81 },
	{ lat: 62, lon: 161.46, elevation: 545 },
	{ lat: 62, lon: 163.0, elevation: 0 },
	{ lat: 62, lon: 164.55, elevation: 0 },
	{ lat: 62, lon: 166.09, elevation: 0 },
	{ lat: 62, lon: 167.64, elevation: 0 },
	{ lat: 62, lon: 169.18, elevation: 0 },
	{ lat: 62, lon: 170.73, elevation: 0 },
	{ lat: 62, lon: 172.27, elevation: 0 },
	{ lat: 62, lon: 173.82, elevation: 0 },
	{ lat: 63, lon: -164.0, elevation: 0 },
	{ lat: 63, lon: -162.4, elevation: 0 },
	{ lat: 63, lon: -160.8, elevation: 0 },
	{ lat: 63, lon: -159.2, elevation: 0 },
	{ lat: 63, lon: -157.6, elevation: 527 },
	{ lat: 63, lon: -156.0, elevation: 0 },
	{ lat: 63, lon: -154.4, elevation: 45 },
	{ lat: 63, lon: -152.8, elevation: 0 },
	{ lat: 63, lon: -151.2, elevation: 0 },
	{ lat: 63, lon: -149.6, elevation: 0 },
	{ lat: 63, lon: -148.0, elevation: 0 },
	{ lat: 63, lon: -146.4, elevation: 0 },
	{ lat: 63, lon: -144.8, elevation: 0 },
	{ lat: 63, lon: -143.2, elevation: 0 },
	{ lat: 63, lon: -141.6, elevation: 0 },
	{ lat: 63, lon: -140.0, elevation: 0 },
	{ lat: 63, lon: -138.4, elevation: 0 },
	{ lat: 63, lon: -136.8, elevation: 0 },
	{ lat: 63, lon: -135.2, elevation: 51 },
	{ lat: 63, lon: -133.6, elevation: 124 },
	{ lat: 63, lon: -132.0, elevation: 1353 },
	{ lat: 63, lon: -130.4, elevation: 878 },
	{ lat: 63, lon: -128.8, elevation: 1388 },
	{ lat: 63, lon: -127.2, elevation: 1514 },
	{ lat: 63, lon: -125.6, elevation: 1600 },
	{ lat: 63, lon: -124.0, elevation: 1911 },
	{ lat: 63, lon: -122.4, elevation: 1119 },
	{ lat: 63, lon: -120.8, elevation: 708 },
	{ lat: 63, lon: -119.2, elevation: 889 },
	{ lat: 63, lon: -117.6, elevation: 477 },
	{ lat: 63, lon: -116.0, elevation: 733 },
	{ lat: 63, lon: -114.4, elevation: 610 },
	{ lat: 63, lon: -112.8, elevation: 515 },
	{ lat: 63, lon: -111.2, elevation: 436 },
	{ lat: 63, lon: -109.6, elevation: 500 },
	{ lat: 63, lon: -108.0, elevation: 480 },
	{ lat: 63, lon: -106.4, elevation: 576 },
	{ lat: 63, lon: -104.8, elevation: 466 },
	{ lat: 63, lon: -103.2, elevation: 362 },
	{ lat: 63, lon: -101.6, elevation: 405 },
	{ lat: 63, lon: -100.0, elevation: 305 },
	{ lat: 63, lon: -98.4, elevation: 287 },
	{ lat: 63, lon: -96.8, elevation: 251 },
	{ lat: 63, lon: -95.2, elevation: 220 },
	{ lat: 63, lon: -93.6, elevation: 75 },
	{ lat: 63, lon: -92.0, elevation: 8 },
	{ lat: 63, lon: -71.2, elevation: 363 },
	{ lat: 63, lon: -69.6, elevation: 484 },
	{ lat: 63, lon: -68.0, elevation: 278 },
	{ lat: 63, lon: -64.8, elevation: 635 },
	{ lat: 63, lon: -50.4, elevation: 0 },
	{ lat: 63, lon: -48.8, elevation: 0 },
	{ lat: 63, lon: -47.2, elevation: 0 },
	{ lat: 63, lon: -45.6, elevation: 0 },
	{ lat: 63, lon: -44.0, elevation: 0 },
	{ lat: 63, lon: -42.4, elevation: 0 },
	{ lat: 63, lon: 8.8, elevation: 1 },
	{ lat: 63, lon: 10.4, elevation: 0 },
	{ lat: 63, lon: 12.0, elevation: 0 },
	{ lat: 63, lon: 13.6, elevation: 173 },
	{ lat: 63, lon: 15.2, elevation: 243 },
	{ lat: 63, lon: 16.8, elevation: 11 },
	{ lat: 63, lon: 18.4, elevation: 0 },
	{ lat: 63, lon: 21.6, elevation: 52 },
	{ lat: 63, lon: 23.2, elevation: 74 },
	{ lat: 63, lon: 24.8, elevation: 66 },
	{ lat: 63, lon: 26.4, elevation: 116 },
	{ lat: 63, lon: 28.0, elevation: 84 },
	{ lat: 63, lon: 29.6, elevation: 124 },
	{ lat: 63, lon: 31.2, elevation: 84 },
	{ lat: 63, lon: 32.8, elevation: 263 },
	{ lat: 63, lon: 34.4, elevation: 221 },
	{ lat: 63, lon: 36.0, elevation: 141 },
	{ lat: 63, lon: 37.6, elevation: 129 },
	{ lat: 63, lon: 39.2, elevation: 117 },
	{ lat: 63, lon: 40.8, elevation: 123 },
	{ lat: 63, lon: 42.4, elevation: 111 },
	{ lat: 63, lon: 44.0, elevation: 143 },
	{ lat: 63, lon: 45.6, elevation: 86 },
	{ lat: 63, lon: 47.2, elevation: 83 },
	{ lat: 63, lon: 48.8, elevation: 138 },
	{ lat: 63, lon: 50.4, elevation: 69 },
	{ lat: 63, lon: 52.0, elevation: 179 },
	{ lat: 63, lon: 53.6, elevation: 154 },
	{ lat: 63, lon: 55.2, elevation: 248 },
	{ lat: 63, lon: 56.8, elevation: 200 },
	{ lat: 63, lon: 58.4, elevation: 317 },
	{ lat: 63, lon: 60.0, elevation: 380 },
	{ lat: 63, lon: 61.6, elevation: 271 },
	{ lat: 63, lon: 63.2, elevation: 94 },
	{ lat: 63, lon: 64.8, elevation: 59 },
	{ lat: 63, lon: 66.4, elevation: 64 },
	{ lat: 63, lon: 68.0, elevation: 89 },
	{ lat: 63, lon: 69.6, elevation: 88 },
	{ lat: 63, lon: 71.2, elevation: 94 },
	{ lat: 63, lon: 72.8, elevation: 83 },
	{ lat: 63, lon: 74.4, elevation: 110 },
	{ lat: 63, lon: 76.0, elevation: 122 },
	{ lat: 63, lon: 77.6, elevation: 132 },
	{ lat: 63, lon: 79.2, elevation: 136 },
	{ lat: 63, lon: 80.8, elevation: 132 },
	{ lat: 63, lon: 82.4, elevation: 102 },
	{ lat: 63, lon: 84.0, elevation: 113 },
	{ lat: 63, lon: 85.6, elevation: 202 },
	{ lat: 63, lon: 87.2, elevation: 132 },
	{ lat: 63, lon: 88.8, elevation: 174 },
	{ lat: 63, lon: 90.4, elevation: 204 },
	{ lat: 63, lon: 92.0, elevation: 263 },
	{ lat: 63, lon: 93.6, elevation: 273 },
	{ lat: 63, lon: 95.2, elevation: 178 },
	{ lat: 63, lon: 96.8, elevation: 248 },
	{ lat: 63, lon: 98.4, elevation: 293 },
	{ lat: 63, lon: 100.0, elevation: 481 },
	{ lat: 63, lon: 101.6, elevation: 291 },
	{ lat: 63, lon: 103.2, elevation: 539 },
	{ lat: 63, lon: 104.8, elevation: 553 },
	{ lat: 63, lon: 106.4, elevation: 528 },
	{ lat: 63, lon: 108.0, elevation: 356 },
	{ lat: 63, lon: 109.6, elevation: 1144 },
	{ lat: 63, lon: 111.2, elevation: 934 },
	{ lat: 63, lon: 112.8, elevation: 1129 },
	{ lat: 63, lon: 114.4, elevation: 980 },
	{ lat: 63, lon: 116.0, elevation: 1411 },
	{ lat: 63, lon: 117.6, elevation: 1549 },
	{ lat: 63, lon: 119.2, elevation: 1061 },
	{ lat: 63, lon: 120.8, elevation: 1046 },
	{ lat: 63, lon: 122.4, elevation: 888 },
	{ lat: 63, lon: 124.0, elevation: 840 },
	{ lat: 63, lon: 125.6, elevation: 651 },
	{ lat: 63, lon: 127.2, elevation: 924 },
	{ lat: 63, lon: 128.8, elevation: 1309 },
	{ lat: 63, lon: 130.4, elevation: 888 },
	{ lat: 63, lon: 132.0, elevation: 1085 },
	{ lat: 63, lon: 133.6, elevation: 611 },
	{ lat: 63, lon: 135.2, elevation: 387 },
	{ lat: 63, lon: 136.8, elevation: 1053 },
	{ lat: 63, lon: 138.4, elevation: 273 },
	{ lat: 63, lon: 140.0, elevation: 0 },
	{ lat: 63, lon: 141.6, elevation: 0 },
	{ lat: 63, lon: 143.2, elevation: 0 },
	{ lat: 63, lon: 144.8, elevation: 0 },
	{ lat: 63, lon: 146.4, elevation: 0 },
	{ lat: 63, lon: 148.0, elevation: 0 },
	{ lat: 63, lon: 149.6, elevation: 0 },
	{ lat: 63, lon: 151.2, elevation: 0 },
	{ lat: 63, lon: 152.8, elevation: 0 },
	{ lat: 63, lon: 154.4, elevation: 0 },
	{ lat: 63, lon: 156.0, elevation: 0 },
	{ lat: 63, lon: 157.6, elevation: 122 },
	{ lat: 63, lon: 159.2, elevation: 382 },
	{ lat: 63, lon: 160.8, elevation: 172 },
	{ lat: 63, lon: 162.4, elevation: 594 },
	{ lat: 63, lon: 164.0, elevation: 0 },
	{ lat: 63, lon: 165.6, elevation: 0 },
	{ lat: 63, lon: 167.2, elevation: 0 },
	{ lat: 63, lon: 168.8, elevation: 0 },
	{ lat: 63, lon: 170.4, elevation: 0 },
	{ lat: 63, lon: 172.0, elevation: 0 },
	{ lat: 63, lon: 173.6, elevation: 0 },
	{ lat: 63, lon: 175.2, elevation: 0 },
	{ lat: 63, lon: 176.8, elevation: 0 },
	{ lat: 63, lon: 178.4, elevation: 0 },
	{ lat: 64, lon: -160.18, elevation: 0 },
	{ lat: 64, lon: -158.53, elevation: 0 },
	{ lat: 64, lon: -156.88, elevation: 48 },
	{ lat: 64, lon: -155.23, elevation: 491 },
	{ lat: 64, lon: -153.58, elevation: 0 },
	{ lat: 64, lon: -151.93, elevation: 0 },
	{ lat: 64, lon: -150.28, elevation: 0 },
	{ lat: 64, lon: -148.62, elevation: 0 },
	{ lat: 64, lon: -146.97, elevation: 0 },
	{ lat: 64, lon: -145.32, elevation: 0 },
	{ lat: 64, lon: -143.67, elevation: 0 },
	{ lat: 64, lon: -142.02, elevation: 0 },
	{ lat: 64, lon: -140.37, elevation: 0 },
	{ lat: 64, lon: -138.72, elevation: 0 },
	{ lat: 64, lon: -137.06, elevation: 0 },
	{ lat: 64, lon: -135.41, elevation: 216 },
	{ lat: 64, lon: -133.76, elevation: 0 },
	{ lat: 64, lon: -132.11, elevation: 781 },
	{ lat: 64, lon: -130.46, elevation: 1143 },
	{ lat: 64, lon: -128.81, elevation: 898 },
	{ lat: 64, lon: -127.16, elevation: 1295 },
	{ lat: 64, lon: -125.5, elevation: 1236 },
	{ lat: 64, lon: -123.85, elevation: 1089 },
	{ lat: 64, lon: -122.2, elevation: 768 },
	{ lat: 64, lon: -120.55, elevation: 560 },
	{ lat: 64, lon: -118.9, elevation: 706 },
	{ lat: 64, lon: -117.25, elevation: 345 },
	{ lat: 64, lon: -115.6, elevation: 313 },
	{ lat: 64, lon: -113.94, elevation: 315 },
	{ lat: 64, lon: -112.29, elevation: 666 },
	{ lat: 64, lon: -110.64, elevation: 316 },
	{ lat: 64, lon: -108.99, elevation: 526 },
	{ lat: 64, lon: -107.34, elevation: 512 },
	{ lat: 64, lon: -105.69, elevation: 452 },
	{ lat: 64, lon: -104.04, elevation: 433 },
	{ lat: 64, lon: -102.39, elevation: 403 },
	{ lat: 64, lon: -100.73, elevation: 405 },
	{ lat: 64, lon: -99.08, elevation: 303 },
	{ lat: 64, lon: -97.43, elevation: 291 },
	{ lat: 64, lon: -95.78, elevation: 201 },
	{ lat: 64, lon: -94.13, elevation: 72 },
	{ lat: 64, lon: -92.48, elevation: 0 },
	{ lat: 64, lon: -90.83, elevation: 0 },
	{ lat: 64, lon: -89.17, elevation: 0 },
	{ lat: 64, lon: -85.87, elevation: 0 },
	{ lat: 64, lon: -84.22, elevation: 0 },
	{ lat: 64, lon: -82.57, elevation: 0 },
	{ lat: 64, lon: -77.61, elevation: 0 },
	{ lat: 64, lon: -72.66, elevation: 262 },
	{ lat: 64, lon: -71.01, elevation: 300 },
	{ lat: 64, lon: -69.36, elevation: 201 },
	{ lat: 64, lon: -67.71, elevation: 10 },
	{ lat: 64, lon: -66.06, elevation: 253 },
	{ lat: 64, lon: -51.19, elevation: 0 },
	{ lat: 64, lon: -49.54, elevation: 0 },
	{ lat: 64, lon: -47.89, elevation: 0 },
	{ lat: 64, lon: -46.24, elevation: 0 },
	{ lat: 64, lon: -44.59, elevation: 0 },
	{ lat: 64, lon: -42.94, elevation: 0 },
	{ lat: 64, lon: -41.28, elevation: 0 },
	{ lat: 64, lon: -21.47, elevation: 0 },
	{ lat: 64, lon: -19.82, elevation: 0 },
	{ lat: 64, lon: -18.17, elevation: 0 },
	{ lat: 64, lon: -16.51, elevation: 0 },
	{ lat: 64, lon: 9.91, elevation: 0 },
	{ lat: 64, lon: 11.56, elevation: 9 },
	{ lat: 64, lon: 13.21, elevation: 203 },
	{ lat: 64, lon: 14.86, elevation: 174 },
	{ lat: 64, lon: 16.51, elevation: 0 },
	{ lat: 64, lon: 18.17, elevation: 0 },
	{ lat: 64, lon: 19.82, elevation: 0 },
	{ lat: 64, lon: 24.77, elevation: 58 },
	{ lat: 64, lon: 26.42, elevation: 125 },
	{ lat: 64, lon: 28.07, elevation: 28 },
	{ lat: 64, lon: 29.72, elevation: 58 },
	{ lat: 64, lon: 31.38, elevation: 22 },
	{ lat: 64, lon: 33.03, elevation: 135 },
	{ lat: 64, lon: 34.68, elevation: 179 },
	{ lat: 64, lon: 39.63, elevation: 162 },
	{ lat: 64, lon: 41.28, elevation: 163 },
	{ lat: 64, lon: 42.94, elevation: 114 },
	{ lat: 64, lon: 44.59, elevation: 140 },
	{ lat: 64, lon: 46.24, elevation: 104 },
	{ lat: 64, lon: 47.89, elevation: 120 },
	{ lat: 64, lon: 49.54, elevation: 231 },
	{ lat: 64, lon: 51.19, elevation: 188 },
	{ lat: 64, lon: 52.84, elevation: 197 },
	{ lat: 64, lon: 54.5, elevation: 168 },
	{ lat: 64, lon: 56.15, elevation: 104 },
	{ lat: 64, lon: 57.8, elevation: 258 },
	{ lat: 64, lon: 59.45, elevation: 397 },
	{ lat: 64, lon: 61.1, elevation: 210 },
	{ lat: 64, lon: 62.75, elevation: 118 },
	{ lat: 64, lon: 64.4, elevation: 97 },
	{ lat: 64, lon: 66.06, elevation: 52 },
	{ lat: 64, lon: 67.71, elevation: 51 },
	{ lat: 64, lon: 69.36, elevation: 42 },
	{ lat: 64, lon: 71.01, elevation: 70 },
	{ lat: 64, lon: 72.66, elevation: 104 },
	{ lat: 64, lon: 74.31, elevation: 100 },
	{ lat: 64, lon: 75.96, elevation: 119 },
	{ lat: 64, lon: 77.61, elevation: 125 },
	{ lat: 64, lon: 79.27, elevation: 127 },
	{ lat: 64, lon: 80.92, elevation: 124 },
	{ lat: 64, lon: 82.57, elevation: 73 },
	{ lat: 64, lon: 84.22, elevation: 83 },
	{ lat: 64, lon: 85.87, elevation: 142 },
	{ lat: 64, lon: 87.52, elevation: 152 },
	{ lat: 64, lon: 89.17, elevation: 153 },
	{ lat: 64, lon: 90.83, elevation: 158 },
	{ lat: 64, lon: 92.48, elevation: 199 },
	{ lat: 64, lon: 94.13, elevation: 103 },
	{ lat: 64, lon: 95.78, elevation: 368 },
	{ lat: 64, lon: 97.43, elevation: 234 },
	{ lat: 64, lon: 99.08, elevation: 251 },
	{ lat: 64, lon: 100.73, elevation: 344 },
	{ lat: 64, lon: 102.39, elevation: 389 },
	{ lat: 64, lon: 104.04, elevation: 575 },
	{ lat: 64, lon: 105.69, elevation: 578 },
	{ lat: 64, lon: 107.34, elevation: 556 },
	{ lat: 64, lon: 108.99, elevation: 522 },
	{ lat: 64, lon: 110.64, elevation: 632 },
	{ lat: 64, lon: 112.29, elevation: 600 },
	{ lat: 64, lon: 113.94, elevation: 688 },
	{ lat: 64, lon: 115.6, elevation: 1078 },
	{ lat: 64, lon: 117.25, elevation: 1235 },
	{ lat: 64, lon: 118.9, elevation: 842 },
	{ lat: 64, lon: 120.55, elevation: 833 },
	{ lat: 64, lon: 122.2, elevation: 886 },
	{ lat: 64, lon: 123.85, elevation: 731 },
	{ lat: 64, lon: 125.5, elevation: 1016 },
	{ lat: 64, lon: 127.16, elevation: 693 },
	{ lat: 64, lon: 128.81, elevation: 987 },
	{ lat: 64, lon: 130.46, elevation: 773 },
	{ lat: 64, lon: 132.11, elevation: 766 },
	{ lat: 64, lon: 133.76, elevation: 481 },
	{ lat: 64, lon: 135.41, elevation: 282 },
	{ lat: 64, lon: 137.06, elevation: 518 },
	{ lat: 64, lon: 138.72, elevation: 973 },
	{ lat: 64, lon: 140.37, elevation: 553 },
	{ lat: 64, lon: 142.02, elevation: 0 },
	{ lat: 64, lon: 143.67, elevation: 0 },
	{ lat: 64, lon: 145.32, elevation: 0 },
	{ lat: 64, lon: 146.97, elevation: 0 },
	{ lat: 64, lon: 148.62, elevation: 0 },
	{ lat: 64, lon: 150.28, elevation: 0 },
	{ lat: 64, lon: 151.93, elevation: 0 },
	{ lat: 64, lon: 153.58, elevation: 0 },
	{ lat: 64, lon: 155.23, elevation: 0 },
	{ lat: 64, lon: 156.88, elevation: 0 },
	{ lat: 64, lon: 158.53, elevation: 45 },
	{ lat: 64, lon: 160.18, elevation: 203 },
	{ lat: 64, lon: 161.83, elevation: 34 },
	{ lat: 64, lon: 163.49, elevation: 0 },
	{ lat: 64, lon: 165.14, elevation: 0 },
	{ lat: 64, lon: 166.79, elevation: 0 },
	{ lat: 64, lon: 168.44, elevation: 0 },
	{ lat: 64, lon: 170.09, elevation: 0 },
	{ lat: 64, lon: 171.74, elevation: 0 },
	{ lat: 64, lon: 173.39, elevation: 0 },
	{ lat: 64, lon: 175.05, elevation: 0 },
	{ lat: 64, lon: 176.7, elevation: 0 },
	{ lat: 64, lon: 178.35, elevation: 0 },
	{ lat: 65, lon: -174.86, elevation: 0 },
	{ lat: 65, lon: -173.14, elevation: 0 },
	{ lat: 65, lon: -166.29, elevation: 0 },
	{ lat: 65, lon: -164.57, elevation: 0 },
	{ lat: 65, lon: -162.86, elevation: 0 },
	{ lat: 65, lon: -161.14, elevation: 54 },
	{ lat: 65, lon: -159.43, elevation: 338 },
	{ lat: 65, lon: -157.71, elevation: 12 },
	{ lat: 65, lon: -156.0, elevation: 102 },
	{ lat: 65, lon: -154.29, elevation: 134 },
	{ lat: 65, lon: -152.57, elevation: 0 },
	{ lat: 65, lon: -150.86, elevation: 0 },
	{ lat: 65, lon: -149.14, elevation: 0 },
	{ lat: 65, lon: -147.43, elevation: 0 },
	{ lat: 65, lon: -145.71, elevation: 0 },
	{ lat: 65, lon: -144.0, elevation: 0 },
	{ lat: 65, lon: -142.29, elevation: 0 },
	{ lat: 65, lon: -140.57, elevation: 0 },
	{ lat: 65, lon: -138.86, elevation: 0 },
	{ lat: 65, lon: -137.14, elevation: 536 },
	{ lat: 65, lon: -135.43, elevation: 834 },
	{ lat: 65, lon: -133.71, elevation: 1786 },
	{ lat: 65, lon: -132.0, elevation: 913 },
	{ lat: 65, lon: -130.29, elevation: 1036 },
	{ lat: 65, lon: -128.57, elevation: 1623 },
	{ lat: 65, lon: -126.86, elevation: 1038 },
	{ lat: 65, lon: -125.14, elevation: 1245 },
	{ lat: 65, lon: -123.43, elevation: 568 },
	{ lat: 65, lon: -121.71, elevation: 557 },
	{ lat: 65, lon: -120.0, elevation: 396 },
	{ lat: 65, lon: -118.29, elevation: 344 },
	{ lat: 65, lon: -116.57, elevation: 591 },
	{ lat: 65, lon: -114.86, elevation: 864 },
	{ lat: 65, lon: -113.14, elevation: 252 },
	{ lat: 65, lon: -111.43, elevation: 217 },
	{ lat: 65, lon: -109.71, elevation: 233 },
	{ lat: 65, lon: -108.0, elevation: 396 },
	{ lat: 65, lon: -106.29, elevation: 301 },
	{ lat: 65, lon: -104.57, elevation: 343 },
	{ lat: 65, lon: -102.86, elevation: 471 },
	{ lat: 65, lon: -101.14, elevation: 382 },
	{ lat: 65, lon: -99.43, elevation: 273 },
	{ lat: 65, lon: -97.71, elevation: 244 },
	{ lat: 65, lon: -96.0, elevation: 174 },
	{ lat: 65, lon: -94.29, elevation: 0 },
	{ lat: 65, lon: -92.57, elevation: 0 },
	{ lat: 65, lon: -90.86, elevation: 0 },
	{ lat: 65, lon: -89.14, elevation: 0 },
	{ lat: 65, lon: -87.43, elevation: 0 },
	{ lat: 65, lon: -85.71, elevation: 0 },
	{ lat: 65, lon: -84.0, elevation: 0 },
	{ lat: 65, lon: -77.14, elevation: 78 },
	{ lat: 65, lon: -75.43, elevation: 178 },
	{ lat: 65, lon: -73.71, elevation: 220 },
	{ lat: 65, lon: -72.0, elevation: 203 },
	{ lat: 65, lon: -70.29, elevation: 178 },
	{ lat: 65, lon: -68.57, elevation: 0 },
	{ lat: 65, lon: -66.86, elevation: 0 },
	{ lat: 65, lon: -51.43, elevation: 0 },
	{ lat: 65, lon: -49.71, elevation: 0 },
	{ lat: 65, lon: -48.0, elevation: 0 },
	{ lat: 65, lon: -46.29, elevation: 0 },
	{ lat: 65, lon: -44.57, elevation: 0 },
	{ lat: 65, lon: -42.86, elevation: 0 },
	{ lat: 65, lon: -41.14, elevation: 0 },
	{ lat: 65, lon: -22.29, elevation: 0 },
	{ lat: 65, lon: -20.57, elevation: 0 },
	{ lat: 65, lon: -18.86, elevation: 0 },
	{ lat: 65, lon: -17.14, elevation: 0 },
	{ lat: 65, lon: -15.43, elevation: 0 },
	{ lat: 65, lon: -13.71, elevation: 0 },
	{ lat: 65, lon: 12.0, elevation: 186 },
	{ lat: 65, lon: 13.71, elevation: 44 },
	{ lat: 65, lon: 15.43, elevation: 132 },
	{ lat: 65, lon: 17.14, elevation: 55 },
	{ lat: 65, lon: 18.86, elevation: 0 },
	{ lat: 65, lon: 20.57, elevation: 0 },
	{ lat: 65, lon: 25.71, elevation: 88 },
	{ lat: 65, lon: 27.43, elevation: 28 },
	{ lat: 65, lon: 29.14, elevation: 66 },
	{ lat: 65, lon: 30.86, elevation: 63 },
	{ lat: 65, lon: 32.57, elevation: 103 },
	{ lat: 65, lon: 34.29, elevation: 186 },
	{ lat: 65, lon: 37.71, elevation: 109 },
	{ lat: 65, lon: 41.14, elevation: 125 },
	{ lat: 65, lon: 42.86, elevation: 172 },
	{ lat: 65, lon: 44.57, elevation: 186 },
	{ lat: 65, lon: 46.29, elevation: 191 },
	{ lat: 65, lon: 48.0, elevation: 205 },
	{ lat: 65, lon: 49.71, elevation: 177 },
	{ lat: 65, lon: 51.43, elevation: 190 },
	{ lat: 65, lon: 53.14, elevation: 226 },
	{ lat: 65, lon: 54.86, elevation: 147 },
	{ lat: 65, lon: 56.57, elevation: 164 },
	{ lat: 65, lon: 58.29, elevation: 369 },
	{ lat: 65, lon: 60.0, elevation: 221 },
	{ lat: 65, lon: 61.71, elevation: 96 },
	{ lat: 65, lon: 63.43, elevation: 99 },
	{ lat: 65, lon: 65.14, elevation: 84 },
	{ lat: 65, lon: 66.86, elevation: 49 },
	{ lat: 65, lon: 68.57, elevation: 49 },
	{ lat: 65, lon: 70.29, elevation: 95 },
	{ lat: 65, lon: 72.0, elevation: 74 },
	{ lat: 65, lon: 73.71, elevation: 79 },
	{ lat: 65, lon: 75.43, elevation: 107 },
	{ lat: 65, lon: 77.14, elevation: 97 },
	{ lat: 65, lon: 78.86, elevation: 95 },
	{ lat: 65, lon: 80.57, elevation: 44 },
	{ lat: 65, lon: 82.29, elevation: 65 },
	{ lat: 65, lon: 84.0, elevation: 109 },
	{ lat: 65, lon: 85.71, elevation: 122 },
	{ lat: 65, lon: 87.43, elevation: 110 },
	{ lat: 65, lon: 89.14, elevation: 168 },
	{ lat: 65, lon: 90.86, elevation: 184 },
	{ lat: 65, lon: 92.57, elevation: 382 },
	{ lat: 65, lon: 94.29, elevation: 576 },
	{ lat: 65, lon: 96.0, elevation: 313 },
	{ lat: 65, lon: 97.71, elevation: 345 },
	{ lat: 65, lon: 99.43, elevation: 258 },
	{ lat: 65, lon: 101.14, elevation: 227 },
	{ lat: 65, lon: 102.86, elevation: 331 },
	{ lat: 65, lon: 104.57, elevation: 619 },
	{ lat: 65, lon: 106.29, elevation: 498 },
	{ lat: 65, lon: 108.0, elevation: 404 },
	{ lat: 65, lon: 109.71, elevation: 488 },
	{ lat: 65, lon: 111.43, elevation: 304 },
	{ lat: 65, lon: 113.14, elevation: 874 },
	{ lat: 65, lon: 114.86, elevation: 1074 },
	{ lat: 65, lon: 116.57, elevation: 899 },
	{ lat: 65, lon: 118.29, elevation: 366 },
	{ lat: 65, lon: 120.0, elevation: 355 },
	{ lat: 65, lon: 121.71, elevation: 189 },
	{ lat: 65, lon: 123.43, elevation: 798 },
	{ lat: 65, lon: 125.14, elevation: 296 },
	{ lat: 65, lon: 126.86, elevation: 512 },
	{ lat: 65, lon: 128.57, elevation: 404 },
	{ lat: 65, lon: 130.29, elevation: 247 },
	{ lat: 65, lon: 132.0, elevation: 270 },
	{ lat: 65, lon: 133.71, elevation: 561 },
	{ lat: 65, lon: 135.43, elevation: 369 },
	{ lat: 65, lon: 137.14, elevation: 712 },
	{ lat: 65, lon: 138.86, elevation: 599 },
	{ lat: 65, lon: 140.57, elevation: 368 },
	{ lat: 65, lon: 142.29, elevation: 0 },
	{ lat: 65, lon: 144.0, elevation: 0 },
	{ lat: 65, lon: 145.71, elevation: 0 },
	{ lat: 65, lon: 147.43, elevation: 0 },
	{ lat: 65, lon: 149.14, elevation: 0 },
	{ lat: 65, lon: 150.86, elevation: 0 },
	{ lat: 65, lon: 152.57, elevation: 159 },
	{ lat: 65, lon: 154.29, elevation: 0 },
	{ lat: 65, lon: 156.0, elevation: 0 },
	{ lat: 65, lon: 157.71, elevation: 0 },
	{ lat: 65, lon: 159.43, elevation: 0 },
	{ lat: 65, lon: 161.14, elevation: 435 },
	{ lat: 65, lon: 162.86, elevation: 29 },
	{ lat: 65, lon: 164.57, elevation: 262 },
	{ lat: 65, lon: 166.29, elevation: 0 },
	{ lat: 65, lon: 168.0, elevation: 0 },
	{ lat: 65, lon: 169.71, elevation: 0 },
	{ lat: 65, lon: 171.43, elevation: 0 },
	{ lat: 65, lon: 173.14, elevation: 0 },
	{ lat: 65, lon: 174.86, elevation: 0 },
	{ lat: 65, lon: 176.57, elevation: 0 },
	{ lat: 65, lon: 178.29, elevation: 0 },
	{ lat: 66, lon: -180.0, elevation: 0 },
	{ lat: 66, lon: -178.22, elevation: 0 },
	{ lat: 66, lon: -176.44, elevation: 0 },
	{ lat: 66, lon: -174.65, elevation: 0 },
	{ lat: 66, lon: -172.87, elevation: 0 },
	{ lat: 66, lon: -171.09, elevation: 0 },
	{ lat: 66, lon: -165.74, elevation: 0 },
	{ lat: 66, lon: -163.96, elevation: 4 },
	{ lat: 66, lon: -162.18, elevation: 0 },
	{ lat: 66, lon: -160.4, elevation: 525 },
	{ lat: 66, lon: -158.61, elevation: 95 },
	{ lat: 66, lon: -156.83, elevation: 179 },
	{ lat: 66, lon: -155.05, elevation: 373 },
	{ lat: 66, lon: -153.27, elevation: 834 },
	{ lat: 66, lon: -151.49, elevation: 214 },
	{ lat: 66, lon: -149.7, elevation: 903 },
	{ lat: 66, lon: -147.92, elevation: 290 },
	{ lat: 66, lon: -146.14, elevation: 0 },
	{ lat: 66, lon: -144.36, elevation: 0 },
	{ lat: 66, lon: -142.57, elevation: 0 },
	{ lat: 66, lon: -140.79, elevation: 422 },
	{ lat: 66, lon: -139.01, elevation: 1645 },
	{ lat: 66, lon: -137.23, elevation: 548 },
	{ lat: 66, lon: -135.45, elevation: 1923 },
	{ lat: 66, lon: -133.66, elevation: 1109 },
	{ lat: 66, lon: -131.88, elevation: 1253 },
	{ lat: 66, lon: -130.1, elevation: 1228 },
	{ lat: 66, lon: -128.32, elevation: 750 },
	{ lat: 66, lon: -126.53, elevation: 867 },
	{ lat: 66, lon: -124.75, elevation: 525 },
	{ lat: 66, lon: -122.97, elevation: 450 },
	{ lat: 66, lon: -121.19, elevation: 622 },
	{ lat: 66, lon: -119.41, elevation: 588 },
	{ lat: 66, lon: -117.62, elevation: 720 },
	{ lat: 66, lon: -115.84, elevation: 293 },
	{ lat: 66, lon: -114.06, elevation: 303 },
	{ lat: 66, lon: -112.28, elevation: 188 },
	{ lat: 66, lon: -110.5, elevation: 310 },
	{ lat: 66, lon: -108.71, elevation: 361 },
	{ lat: 66, lon: -106.93, elevation: 482 },
	{ lat: 66, lon: -105.15, elevation: 438 },
	{ lat: 66, lon: -103.37, elevation: 424 },
	{ lat: 66, lon: -101.58, elevation: 395 },
	{ lat: 66, lon: -99.8, elevation: 285 },
	{ lat: 66, lon: -98.02, elevation: 273 },
	{ lat: 66, lon: -96.24, elevation: 153 },
	{ lat: 66, lon: -94.46, elevation: 0 },
	{ lat: 66, lon: -92.67, elevation: 0 },
	{ lat: 66, lon: -90.89, elevation: 0 },
	{ lat: 66, lon: -89.11, elevation: 0 },
	{ lat: 66, lon: -87.33, elevation: 0 },
	{ lat: 66, lon: -73.07, elevation: 252 },
	{ lat: 66, lon: -71.29, elevation: 233 },
	{ lat: 66, lon: -69.5, elevation: 0 },
	{ lat: 66, lon: -67.72, elevation: 0 },
	{ lat: 66, lon: -65.94, elevation: 0 },
	{ lat: 66, lon: -64.16, elevation: 38 },
	{ lat: 66, lon: -62.38, elevation: 0 },
	{ lat: 66, lon: -51.68, elevation: 0 },
	{ lat: 66, lon: -49.9, elevation: 0 },
	{ lat: 66, lon: -48.12, elevation: 0 },
	{ lat: 66, lon: -46.34, elevation: 0 },
	{ lat: 66, lon: -44.55, elevation: 10 },
	{ lat: 66, lon: -42.77, elevation: 0 },
	{ lat: 66, lon: -40.99, elevation: 0 },
	{ lat: 66, lon: -39.21, elevation: 0 },
	{ lat: 66, lon: -37.43, elevation: 0 },
	{ lat: 66, lon: -23.17, elevation: 0 },
	{ lat: 66, lon: -21.39, elevation: 0 },
	{ lat: 66, lon: -17.82, elevation: 0 },
	{ lat: 66, lon: -16.04, elevation: 0 },
	{ lat: 66, lon: 14.26, elevation: 287 },
	{ lat: 66, lon: 16.04, elevation: 95 },
	{ lat: 66, lon: 17.82, elevation: 31 },
	{ lat: 66, lon: 19.6, elevation: 0 },
	{ lat: 66, lon: 21.39, elevation: 0 },
	{ lat: 66, lon: 23.17, elevation: 36 },
	{ lat: 66, lon: 24.95, elevation: 0 },
	{ lat: 66, lon: 26.73, elevation: 0 },
	{ lat: 66, lon: 28.51, elevation: 0 },
	{ lat: 66, lon: 30.3, elevation: 11 },
	{ lat: 66, lon: 32.08, elevation: 43 },
	{ lat: 66, lon: 33.86, elevation: 87 },
	{ lat: 66, lon: 40.99, elevation: 177 },
	{ lat: 66, lon: 42.77, elevation: 149 },
	{ lat: 66, lon: 44.55, elevation: 198 },
	{ lat: 66, lon: 46.34, elevation: 92 },
	{ lat: 66, lon: 48.12, elevation: 197 },
	{ lat: 66, lon: 49.9, elevation: 178 },
	{ lat: 66, lon: 51.68, elevation: 202 },
	{ lat: 66, lon: 53.47, elevation: 187 },
	{ lat: 66, lon: 55.25, elevation: 161 },
	{ lat: 66, lon: 57.03, elevation: 148 },
	{ lat: 66, lon: 58.81, elevation: 509 },
	{ lat: 66, lon: 60.59, elevation: 103 },
	{ lat: 66, lon: 62.38, elevation: 64 },
	{ lat: 66, lon: 64.16, elevation: 71 },
	{ lat: 66, lon: 65.94, elevation: 50 },
	{ lat: 66, lon: 67.72, elevation: 66 },
	{ lat: 66, lon: 69.5, elevation: 38 },
	{ lat: 66, lon: 71.29, elevation: 61 },
	{ lat: 66, lon: 73.07, elevation: 78 },
	{ lat: 66, lon: 74.85, elevation: 56 },
	{ lat: 66, lon: 76.63, elevation: 70 },
	{ lat: 66, lon: 78.42, elevation: 48 },
	{ lat: 66, lon: 80.2, elevation: 63 },
	{ lat: 66, lon: 81.98, elevation: 80 },
	{ lat: 66, lon: 83.76, elevation: 131 },
	{ lat: 66, lon: 85.54, elevation: 163 },
	{ lat: 66, lon: 87.33, elevation: 150 },
	{ lat: 66, lon: 89.11, elevation: 160 },
	{ lat: 66, lon: 90.89, elevation: 200 },
	{ lat: 66, lon: 92.67, elevation: 478 },
	{ lat: 66, lon: 94.46, elevation: 538 },
	{ lat: 66, lon: 96.24, elevation: 467 },
	{ lat: 66, lon: 98.02, elevation: 306 },
	{ lat: 66, lon: 99.8, elevation: 386 },
	{ lat: 66, lon: 101.58, elevation: 324 },
	{ lat: 66, lon: 103.37, elevation: 334 },
	{ lat: 66, lon: 105.15, elevation: 439 },
	{ lat: 66, lon: 106.93, elevation: 346 },
	{ lat: 66, lon: 108.71, elevation: 445 },
	{ lat: 66, lon: 110.5, elevation: 392 },
	{ lat: 66, lon: 112.28, elevation: 420 },
	{ lat: 66, lon: 114.06, elevation: 410 },
	{ lat: 66, lon: 115.84, elevation: 383 },
	{ lat: 66, lon: 117.62, elevation: 352 },
	{ lat: 66, lon: 119.41, elevation: 446 },
	{ lat: 66, lon: 121.19, elevation: 434 },
	{ lat: 66, lon: 122.97, elevation: 519 },
	{ lat: 66, lon: 124.75, elevation: 445 },
	{ lat: 66, lon: 126.53, elevation: 536 },
	{ lat: 66, lon: 128.32, elevation: 447 },
	{ lat: 66, lon: 130.1, elevation: 346 },
	{ lat: 66, lon: 131.88, elevation: 372 },
	{ lat: 66, lon: 133.66, elevation: 237 },
	{ lat: 66, lon: 135.45, elevation: 340 },
	{ lat: 66, lon: 137.23, elevation: 614 },
	{ lat: 66, lon: 139.01, elevation: 780 },
	{ lat: 66, lon: 140.79, elevation: 944 },
	{ lat: 66, lon: 142.57, elevation: 347 },
	{ lat: 66, lon: 144.36, elevation: 311 },
	{ lat: 66, lon: 146.14, elevation: 564 },
	{ lat: 66, lon: 147.92, elevation: 80 },
	{ lat: 66, lon: 149.7, elevation: 470 },
	{ lat: 66, lon: 151.49, elevation: 334 },
	{ lat: 66, lon: 153.27, elevation: 165 },
	{ lat: 66, lon: 155.05, elevation: 0 },
	{ lat: 66, lon: 156.83, elevation: 0 },
	{ lat: 66, lon: 158.61, elevation: 0 },
	{ lat: 66, lon: 160.4, elevation: 0 },
	{ lat: 66, lon: 162.18, elevation: 271 },
	{ lat: 66, lon: 163.96, elevation: 0 },
	{ lat: 66, lon: 165.74, elevation: 0 },
	{ lat: 66, lon: 167.52, elevation: 0 },
	{ lat: 66, lon: 169.31, elevation: 0 },
	{ lat: 66, lon: 171.09, elevation: 0 },
	{ lat: 66, lon: 172.87, elevation: 0 },
	{ lat: 66, lon: 174.65, elevation: 0 },
	{ lat: 66, lon: 176.44, elevation: 0 },
	{ lat: 66, lon: 178.22, elevation: 0 },
	{ lat: 66, lon: 180.0, elevation: 0 },
	{ lat: 67, lon: -180.0, elevation: 0 },
	{ lat: 67, lon: -178.14, elevation: 0 },
	{ lat: 67, lon: -176.29, elevation: 0 },
	{ lat: 67, lon: -172.58, elevation: 0 },
	{ lat: 67, lon: -161.44, elevation: 0 },
	{ lat: 67, lon: -159.59, elevation: 0 },
	{ lat: 67, lon: -157.73, elevation: 0 },
	{ lat: 67, lon: -155.88, elevation: 0 },
	{ lat: 67, lon: -154.02, elevation: 0 },
	{ lat: 67, lon: -152.16, elevation: 0 },
	{ lat: 67, lon: -150.31, elevation: 0 },
	{ lat: 67, lon: -148.45, elevation: 0 },
	{ lat: 67, lon: -146.6, elevation: 0 },
	{ lat: 67, lon: -144.74, elevation: 0 },
	{ lat: 67, lon: -142.89, elevation: 0 },
	{ lat: 67, lon: -141.03, elevation: 0 },
	{ lat: 67, lon: -139.18, elevation: 0 },
	{ lat: 67, lon: -137.32, elevation: 0 },
	{ lat: 67, lon: -135.46, elevation: 0 },
	{ lat: 67, lon: -133.61, elevation: 0 },
	{ lat: 67, lon: -131.75, elevation: 0 },
	{ lat: 67, lon: -129.9, elevation: 0 },
	{ lat: 67, lon: -128.04, elevation: 0 },
	{ lat: 67, lon: -126.19, elevation: 0 },
	{ lat: 67, lon: -124.33, elevation: 0 },
	{ lat: 67, lon: -122.47, elevation: 0 },
	{ lat: 67, lon: -120.62, elevation: 0 },
	{ lat: 67, lon: -118.76, elevation: 0 },
	{ lat: 67, lon: -116.91, elevation: 0 },
	{ lat: 67, lon: -115.05, elevation: 0 },
	{ lat: 67, lon: -113.2, elevation: 0 },
	{ lat: 67, lon: -111.34, elevation: 0 },
	{ lat: 67, lon: -109.48, elevation: 0 },
	{ lat: 67, lon: -105.77, elevation: 0 },
	{ lat: 67, lon: -103.92, elevation: 0 },
	{ lat: 67, lon: -102.06, elevation: 0 },
	{ lat: 67, lon: -100.21, elevation: 0 },
	{ lat: 67, lon: -98.35, elevation: 0 },
	{ lat: 67, lon: -96.49, elevation: 0 },
	{ lat: 67, lon: -94.64, elevation: 0 },
	{ lat: 67, lon: -92.78, elevation: 0 },
	{ lat: 67, lon: -90.93, elevation: 0 },
	{ lat: 67, lon: -89.07, elevation: 0 },
	{ lat: 67, lon: -87.22, elevation: 0 },
	{ lat: 67, lon: -85.36, elevation: 0 },
	{ lat: 67, lon: -83.51, elevation: 0 },
	{ lat: 67, lon: -81.65, elevation: 0 },
	{ lat: 67, lon: -72.37, elevation: 0 },
	{ lat: 67, lon: -70.52, elevation: 0 },
	{ lat: 67, lon: -68.66, elevation: 0 },
	{ lat: 67, lon: -66.8, elevation: 0 },
	{ lat: 67, lon: -64.95, elevation: 0 },
	{ lat: 67, lon: -63.09, elevation: 0 },
	{ lat: 67, lon: -51.96, elevation: 0 },
	{ lat: 67, lon: -50.1, elevation: 0 },
	{ lat: 67, lon: -48.25, elevation: 0 },
	{ lat: 67, lon: -46.39, elevation: 0 },
	{ lat: 67, lon: -44.54, elevation: 0 },
	{ lat: 67, lon: -42.68, elevation: 0 },
	{ lat: 67, lon: -40.82, elevation: 0 },
	{ lat: 67, lon: -38.97, elevation: 0 },
	{ lat: 67, lon: -37.11, elevation: 0 },
	{ lat: 67, lon: -35.26, elevation: 0 },
	{ lat: 67, lon: 14.85, elevation: 0 },
	{ lat: 67, lon: 16.7, elevation: 0 },
	{ lat: 67, lon: 18.56, elevation: 0 },
	{ lat: 67, lon: 20.41, elevation: 0 },
	{ lat: 67, lon: 22.27, elevation: 0 },
	{ lat: 67, lon: 24.12, elevation: 0 },
	{ lat: 67, lon: 25.98, elevation: 0 },
	{ lat: 67, lon: 27.84, elevation: 0 },
	{ lat: 67, lon: 29.69, elevation: 0 },
	{ lat: 67, lon: 31.55, elevation: 0 },
	{ lat: 67, lon: 33.4, elevation: 0 },
	{ lat: 67, lon: 35.26, elevation: 0 },
	{ lat: 67, lon: 37.11, elevation: 0 },
	{ lat: 67, lon: 38.97, elevation: 0 },
	{ lat: 67, lon: 40.82, elevation: 0 },
	{ lat: 67, lon: 44.54, elevation: 0 },
	{ lat: 67, lon: 48.25, elevation: 0 },
	{ lat: 67, lon: 50.1, elevation: 0 },
	{ lat: 67, lon: 51.96, elevation: 0 },
	{ lat: 67, lon: 53.81, elevation: 0 },
	{ lat: 67, lon: 55.67, elevation: 0 },
	{ lat: 67, lon: 57.53, elevation: 0 },
	{ lat: 67, lon: 59.38, elevation: 0 },
	{ lat: 67, lon: 61.24, elevation: 0 },
	{ lat: 67, lon: 63.09, elevation: 0 },
	{ lat: 67, lon: 64.95, elevation: 0 },
	{ lat: 67, lon: 66.8, elevation: 0 },
	{ lat: 67, lon: 68.66, elevation: 0 },
	{ lat: 67, lon: 70.52, elevation: 0 },
	{ lat: 67, lon: 74.23, elevation: 0 },
	{ lat: 67, lon: 76.08, elevation: 0 },
	{ lat: 67, lon: 77.94, elevation: 0 },
	{ lat: 67, lon: 79.79, elevation: 0 },
	{ lat: 67, lon: 81.65, elevation: 0 },
	{ lat: 67, lon: 83.51, elevation: 0 },
	{ lat: 67, lon: 85.36, elevation: 0 },
	{ lat: 67, lon: 87.22, elevation: 0 },
	{ lat: 67, lon: 89.07, elevation: 0 },
	{ lat: 67, lon: 90.93, elevation: 0 },
	{ lat: 67, lon: 92.78, elevation: 0 },
	{ lat: 67, lon: 94.64, elevation: 0 },
	{ lat: 67, lon: 96.49, elevation: 0 },
	{ lat: 67, lon: 98.35, elevation: 0 },
	{ lat: 67, lon: 100.21, elevation: 0 },
	{ lat: 67, lon: 102.06, elevation: 0 },
	{ lat: 67, lon: 103.92, elevation: 0 },
	{ lat: 67, lon: 105.77, elevation: 0 },
	{ lat: 67, lon: 107.63, elevation: 0 },
	{ lat: 67, lon: 109.48, elevation: 0 },
	{ lat: 67, lon: 111.34, elevation: 0 },
	{ lat: 67, lon: 113.2, elevation: 0 },
	{ lat: 67, lon: 115.05, elevation: 0 },
	{ lat: 67, lon: 116.91, elevation: 0 },
	{ lat: 67, lon: 118.76, elevation: 0 },
	{ lat: 67, lon: 120.62, elevation: 0 },
	{ lat: 67, lon: 122.47, elevation: 0 },
	{ lat: 67, lon: 124.33, elevation: 0 },
	{ lat: 67, lon: 126.19, elevation: 0 },
	{ lat: 67, lon: 128.04, elevation: 0 },
	{ lat: 67, lon: 129.9, elevation: 0 },
	{ lat: 67, lon: 131.75, elevation: 0 },
	{ lat: 67, lon: 133.61, elevation: 0 },
	{ lat: 67, lon: 135.46, elevation: 0 },
	{ lat: 67, lon: 137.32, elevation: 0 },
	{ lat: 67, lon: 139.18, elevation: 0 },
	{ lat: 67, lon: 141.03, elevation: 0 },
	{ lat: 67, lon: 142.89, elevation: 0 },
	{ lat: 67, lon: 144.74, elevation: 0 },
	{ lat: 67, lon: 146.6, elevation: 0 },
	{ lat: 67, lon: 148.45, elevation: 0 },
	{ lat: 67, lon: 150.31, elevation: 0 },
	{ lat: 67, lon: 152.16, elevation: 0 },
	{ lat: 67, lon: 154.02, elevation: 0 },
	{ lat: 67, lon: 155.88, elevation: 0 },
	{ lat: 67, lon: 157.73, elevation: 0 },
	{ lat: 67, lon: 159.59, elevation: 0 },
	{ lat: 67, lon: 161.44, elevation: 0 },
	{ lat: 67, lon: 163.3, elevation: 0 },
	{ lat: 67, lon: 165.15, elevation: 0 },
	{ lat: 67, lon: 167.01, elevation: 0 },
	{ lat: 67, lon: 168.87, elevation: 0 },
	{ lat: 67, lon: 170.72, elevation: 0 },
	{ lat: 67, lon: 172.58, elevation: 0 },
	{ lat: 67, lon: 174.43, elevation: 0 },
	{ lat: 67, lon: 176.29, elevation: 0 },
	{ lat: 67, lon: 178.14, elevation: 0 },
	{ lat: 67, lon: 180.0, elevation: 0 },
	{ lat: 68, lon: -180.0, elevation: 0 },
	{ lat: 68, lon: -178.06, elevation: 0 },
	{ lat: 68, lon: -164.52, elevation: 0 },
	{ lat: 68, lon: -162.58, elevation: 0 },
	{ lat: 68, lon: -160.65, elevation: 0 },
	{ lat: 68, lon: -158.71, elevation: 0 },
	{ lat: 68, lon: -156.77, elevation: 0 },
	{ lat: 68, lon: -154.84, elevation: 0 },
	{ lat: 68, lon: -152.9, elevation: 0 },
	{ lat: 68, lon: -150.97, elevation: 0 },
	{ lat: 68, lon: -149.03, elevation: 0 },
	{ lat: 68, lon: -147.1, elevation: 0 },
	{ lat: 68, lon: -145.16, elevation: 0 },
	{ lat: 68, lon: -143.23, elevation: 0 },
	{ lat: 68, lon: -141.29, elevation: 0 },
	{ lat: 68, lon: -139.35, elevation: 0 },
	{ lat: 68, lon: -137.42, elevation: 0 },
	{ lat: 68, lon: -135.48, elevation: 0 },
	{ lat: 68, lon: -133.55, elevation: 0 },
	{ lat: 68, lon: -131.61, elevation: 0 },
	{ lat: 68, lon: -129.68, elevation: 0 },
	{ lat: 68, lon: -127.74, elevation: 0 },
	{ lat: 68, lon: -125.81, elevation: 0 },
	{ lat: 68, lon: -123.87, elevation: 0 },
	{ lat: 68, lon: -121.94, elevation: 0 },
	{ lat: 68, lon: -120.0, elevation: 0 },
	{ lat: 68, lon: -118.06, elevation: 0 },
	{ lat: 68, lon: -116.13, elevation: 0 },
	{ lat: 68, lon: -106.45, elevation: 0 },
	{ lat: 68, lon: -104.52, elevation: 0 },
	{ lat: 68, lon: -96.77, elevation: 0 },
	{ lat: 68, lon: -94.84, elevation: 0 },
	{ lat: 68, lon: -92.9, elevation: 0 },
	{ lat: 68, lon: -90.97, elevation: 0 },
	{ lat: 68, lon: -89.03, elevation: 0 },
	{ lat: 68, lon: -85.16, elevation: 0 },
	{ lat: 68, lon: -83.23, elevation: 0 },
	{ lat: 68, lon: -75.48, elevation: 0 },
	{ lat: 68, lon: -73.55, elevation: 0 },
	{ lat: 68, lon: -71.61, elevation: 0 },
	{ lat: 68, lon: -69.68, elevation: 0 },
	{ lat: 68, lon: -67.74, elevation: 0 },
	{ lat: 68, lon: -52.26, elevation: 0 },
	{ lat: 68, lon: -50.32, elevation: 0 },
	{ lat: 68, lon: -48.39, elevation: 0 },
	{ lat: 68, lon: -46.45, elevation: 0 },
	{ lat: 68, lon: -44.52, elevation: 0 },
	{ lat: 68, lon: -42.58, elevation: 0 },
	{ lat: 68, lon: -40.65, elevation: 0 },
	{ lat: 68, lon: -38.71, elevation: 0 },
	{ lat: 68, lon: -36.77, elevation: 0 },
	{ lat: 68, lon: -34.84, elevation: 0 },
	{ lat: 68, lon: -32.9, elevation: 0 },
	{ lat: 68, lon: 15.48, elevation: 0 },
	{ lat: 68, lon: 17.42, elevation: 0 },
	{ lat: 68, lon: 19.35, elevation: 0 },
	{ lat: 68, lon: 21.29, elevation: 0 },
	{ lat: 68, lon: 23.23, elevation: 0 },
	{ lat: 68, lon: 25.16, elevation: 0 },
	{ lat: 68, lon: 27.1, elevation: 0 },
	{ lat: 68, lon: 29.03, elevation: 0 },
	{ lat: 68, lon: 30.97, elevation: 0 },
	{ lat: 68, lon: 32.9, elevation: 0 },
	{ lat: 68, lon: 34.84, elevation: 0 },
	{ lat: 68, lon: 36.77, elevation: 0 },
	{ lat: 68, lon: 38.71, elevation: 0 },
	{ lat: 68, lon: 44.52, elevation: 0 },
	{ lat: 68, lon: 46.45, elevation: 0 },
	{ lat: 68, lon: 50.32, elevation: 0 },
	{ lat: 68, lon: 52.26, elevation: 0 },
	{ lat: 68, lon: 54.19, elevation: 0 },
	{ lat: 68, lon: 56.13, elevation: 0 },
	{ lat: 68, lon: 58.06, elevation: 0 },
	{ lat: 68, lon: 60.0, elevation: 0 },
	{ lat: 68, lon: 61.94, elevation: 0 },
	{ lat: 68, lon: 63.87, elevation: 0 },
	{ lat: 68, lon: 65.81, elevation: 0 },
	{ lat: 68, lon: 67.74, elevation: 0 },
	{ lat: 68, lon: 69.68, elevation: 0 },
	{ lat: 68, lon: 71.61, elevation: 0 },
	{ lat: 68, lon: 75.48, elevation: 0 },
	{ lat: 68, lon: 79.35, elevation: 0 },
	{ lat: 68, lon: 81.29, elevation: 0 },
	{ lat: 68, lon: 83.23, elevation: 0 },
	{ lat: 68, lon: 85.16, elevation: 0 },
	{ lat: 68, lon: 87.1, elevation: 0 },
	{ lat: 68, lon: 89.03, elevation: 0 },
	{ lat: 68, lon: 90.97, elevation: 0 },
	{ lat: 68, lon: 92.9, elevation: 0 },
	{ lat: 68, lon: 94.84, elevation: 0 },
	{ lat: 68, lon: 96.77, elevation: 0 },
	{ lat: 68, lon: 98.71, elevation: 0 },
	{ lat: 68, lon: 100.65, elevation: 0 },
	{ lat: 68, lon: 102.58, elevation: 0 },
	{ lat: 68, lon: 104.52, elevation: 0 },
	{ lat: 68, lon: 106.45, elevation: 0 },
	{ lat: 68, lon: 108.39, elevation: 0 },
	{ lat: 68, lon: 110.32, elevation: 0 },
	{ lat: 68, lon: 112.26, elevation: 0 },
	{ lat: 68, lon: 114.19, elevation: 0 },
	{ lat: 68, lon: 116.13, elevation: 0 },
	{ lat: 68, lon: 118.06, elevation: 0 },
	{ lat: 68, lon: 120.0, elevation: 0 },
	{ lat: 68, lon: 121.94, elevation: 0 },
	{ lat: 68, lon: 123.87, elevation: 0 },
	{ lat: 68, lon: 125.81, elevation: 0 },
	{ lat: 68, lon: 127.74, elevation: 0 },
	{ lat: 68, lon: 129.68, elevation: 0 },
	{ lat: 68, lon: 131.61, elevation: 0 },
	{ lat: 68, lon: 133.55, elevation: 0 },
	{ lat: 68, lon: 135.48, elevation: 0 },
	{ lat: 68, lon: 137.42, elevation: 0 },
	{ lat: 68, lon: 139.35, elevation: 0 },
	{ lat: 68, lon: 141.29, elevation: 0 },
	{ lat: 68, lon: 143.23, elevation: 0 },
	{ lat: 68, lon: 145.16, elevation: 0 },
	{ lat: 68, lon: 147.1, elevation: 0 },
	{ lat: 68, lon: 149.03, elevation: 0 },
	{ lat: 68, lon: 150.97, elevation: 0 },
	{ lat: 68, lon: 152.9, elevation: 0 },
	{ lat: 68, lon: 154.84, elevation: 0 },
	{ lat: 68, lon: 156.77, elevation: 0 },
	{ lat: 68, lon: 158.71, elevation: 0 },
	{ lat: 68, lon: 160.65, elevation: 0 },
	{ lat: 68, lon: 162.58, elevation: 0 },
	{ lat: 68, lon: 164.52, elevation: 0 },
	{ lat: 68, lon: 166.45, elevation: 0 },
	{ lat: 68, lon: 168.39, elevation: 0 },
	{ lat: 68, lon: 170.32, elevation: 0 },
	{ lat: 68, lon: 172.26, elevation: 0 },
	{ lat: 68, lon: 174.19, elevation: 0 },
	{ lat: 68, lon: 176.13, elevation: 0 },
	{ lat: 68, lon: 178.06, elevation: 0 },
	{ lat: 68, lon: 180.0, elevation: 0 },
	{ lat: 69, lon: -163.82, elevation: 0 },
	{ lat: 69, lon: -161.8, elevation: 0 },
	{ lat: 69, lon: -159.78, elevation: 0 },
	{ lat: 69, lon: -157.75, elevation: 0 },
	{ lat: 69, lon: -155.73, elevation: 0 },
	{ lat: 69, lon: -153.71, elevation: 0 },
	{ lat: 69, lon: -151.69, elevation: 0 },
	{ lat: 69, lon: -149.66, elevation: 0 },
	{ lat: 69, lon: -147.64, elevation: 0 },
	{ lat: 69, lon: -145.62, elevation: 0 },
	{ lat: 69, lon: -143.6, elevation: 0 },
	{ lat: 69, lon: -141.57, elevation: 0 },
	{ lat: 69, lon: -139.55, elevation: 0 },
	{ lat: 69, lon: -137.53, elevation: 0 },
	{ lat: 69, lon: -135.51, elevation: 0 },
	{ lat: 69, lon: -133.48, elevation: 0 },
	{ lat: 69, lon: -131.46, elevation: 0 },
	{ lat: 69, lon: -129.44, elevation: 0 },
	{ lat: 69, lon: -127.42, elevation: 0 },
	{ lat: 69, lon: -125.39, elevation: 0 },
	{ lat: 69, lon: -123.37, elevation: 0 },
	{ lat: 69, lon: -121.35, elevation: 0 },
	{ lat: 69, lon: -119.33, elevation: 0 },
	{ lat: 69, lon: -113.26, elevation: 0 },
	{ lat: 69, lon: -111.24, elevation: 0 },
	{ lat: 69, lon: -109.21, elevation: 0 },
	{ lat: 69, lon: -103.15, elevation: 0 },
	{ lat: 69, lon: -99.1, elevation: 0 },
	{ lat: 69, lon: -97.08, elevation: 0 },
	{ lat: 69, lon: -93.03, elevation: 0 },
	{ lat: 69, lon: -91.01, elevation: 0 },
	{ lat: 69, lon: -88.99, elevation: 0 },
	{ lat: 69, lon: -82.92, elevation: 0 },
	{ lat: 69, lon: -78.88, elevation: 0 },
	{ lat: 69, lon: -74.83, elevation: 0 },
	{ lat: 69, lon: -72.81, elevation: 0 },
	{ lat: 69, lon: -70.79, elevation: 0 },
	{ lat: 69, lon: -68.76, elevation: 0 },
	{ lat: 69, lon: -48.54, elevation: 0 },
	{ lat: 69, lon: -46.52, elevation: 0 },
	{ lat: 69, lon: -44.49, elevation: 0 },
	{ lat: 69, lon: -42.47, elevation: 0 },
	{ lat: 69, lon: -40.45, elevation: 0 },
	{ lat: 69, lon: -38.43, elevation: 0 },
	{ lat: 69, lon: -36.4, elevation: 0 },
	{ lat: 69, lon: -34.38, elevation: 0 },
	{ lat: 69, lon: -32.36, elevation: 0 },
	{ lat: 69, lon: -30.34, elevation: 0 },
	{ lat: 69, lon: -28.31, elevation: 0 },
	{ lat: 69, lon: -26.29, elevation: 0 },
	{ lat: 69, lon: 18.2, elevation: 0 },
	{ lat: 69, lon: 20.22, elevation: 0 },
	{ lat: 69, lon: 22.25, elevation: 0 },
	{ lat: 69, lon: 24.27, elevation: 0 },
	{ lat: 69, lon: 26.29, elevation: 0 },
	{ lat: 69, lon: 28.31, elevation: 0 },
	{ lat: 69, lon: 30.34, elevation: 0 },
	{ lat: 69, lon: 32.36, elevation: 0 },
	{ lat: 69, lon: 34.38, elevation: 0 },
	{ lat: 69, lon: 36.4, elevation: 0 },
	{ lat: 69, lon: 48.54, elevation: 0 },
	{ lat: 69, lon: 62.7, elevation: 0 },
	{ lat: 69, lon: 64.72, elevation: 0 },
	{ lat: 69, lon: 68.76, elevation: 0 },
	{ lat: 69, lon: 70.79, elevation: 0 },
	{ lat: 69, lon: 78.88, elevation: 0 },
	{ lat: 69, lon: 80.9, elevation: 0 },
	{ lat: 69, lon: 82.92, elevation: 0 },
	{ lat: 69, lon: 84.94, elevation: 0 },
	{ lat: 69, lon: 86.97, elevation: 0 },
	{ lat: 69, lon: 88.99, elevation: 0 },
	{ lat: 69, lon: 91.01, elevation: 0 },
	{ lat: 69, lon: 93.03, elevation: 0 },
	{ lat: 69, lon: 95.06, elevation: 0 },
	{ lat: 69, lon: 97.08, elevation: 0 },
	{ lat: 69, lon: 99.1, elevation: 0 },
	{ lat: 69, lon: 101.12, elevation: 0 },
	{ lat: 69, lon: 103.15, elevation: 0 },
	{ lat: 69, lon: 105.17, elevation: 0 },
	{ lat: 69, lon: 107.19, elevation: 0 },
	{ lat: 69, lon: 109.21, elevation: 0 },
	{ lat: 69, lon: 111.24, elevation: 0 },
	{ lat: 69, lon: 113.26, elevation: 0 },
	{ lat: 69, lon: 115.28, elevation: 0 },
	{ lat: 69, lon: 117.3, elevation: 0 },
	{ lat: 69, lon: 119.33, elevation: 0 },
	{ lat: 69, lon: 121.35, elevation: 0 },
	{ lat: 69, lon: 123.37, elevation: 0 },
	{ lat: 69, lon: 125.39, elevation: 0 },
	{ lat: 69, lon: 127.42, elevation: 0 },
	{ lat: 69, lon: 129.44, elevation: 0 },
	{ lat: 69, lon: 131.46, elevation: 0 },
	{ lat: 69, lon: 133.48, elevation: 0 },
	{ lat: 69, lon: 135.51, elevation: 0 },
	{ lat: 69, lon: 137.53, elevation: 0 },
	{ lat: 69, lon: 139.55, elevation: 0 },
	{ lat: 69, lon: 141.57, elevation: 0 },
	{ lat: 69, lon: 143.6, elevation: 0 },
	{ lat: 69, lon: 145.62, elevation: 0 },
	{ lat: 69, lon: 147.64, elevation: 0 },
	{ lat: 69, lon: 149.66, elevation: 0 },
	{ lat: 69, lon: 151.69, elevation: 0 },
	{ lat: 69, lon: 153.71, elevation: 0 },
	{ lat: 69, lon: 155.73, elevation: 0 },
	{ lat: 69, lon: 157.75, elevation: 0 },
	{ lat: 69, lon: 159.78, elevation: 0 },
	{ lat: 69, lon: 161.8, elevation: 0 },
	{ lat: 69, lon: 163.82, elevation: 0 },
	{ lat: 69, lon: 165.84, elevation: 0 },
	{ lat: 69, lon: 167.87, elevation: 0 },
	{ lat: 69, lon: 171.91, elevation: 0 },
	{ lat: 69, lon: 173.93, elevation: 0 },
	{ lat: 69, lon: 175.96, elevation: 0 },
	{ lat: 69, lon: 177.98, elevation: 0 },
	{ lat: 70, lon: -160.94, elevation: 0 },
	{ lat: 70, lon: -158.82, elevation: 0 },
	{ lat: 70, lon: -156.71, elevation: 0 },
	{ lat: 70, lon: -154.59, elevation: 0 },
	{ lat: 70, lon: -152.47, elevation: 0 },
	{ lat: 70, lon: -150.35, elevation: 0 },
	{ lat: 70, lon: -148.24, elevation: 0 },
	{ lat: 70, lon: -146.12, elevation: 0 },
	{ lat: 70, lon: -144.0, elevation: 0 },
	{ lat: 70, lon: -127.06, elevation: 0 },
	{ lat: 70, lon: -124.94, elevation: 0 },
	{ lat: 70, lon: -116.47, elevation: 0 },
	{ lat: 70, lon: -114.35, elevation: 0 },
	{ lat: 70, lon: -112.24, elevation: 0 },
	{ lat: 70, lon: -110.12, elevation: 0 },
	{ lat: 70, lon: -108.0, elevation: 0 },
	{ lat: 70, lon: -105.88, elevation: 0 },
	{ lat: 70, lon: -103.76, elevation: 0 },
	{ lat: 70, lon: -101.65, elevation: 0 },
	{ lat: 70, lon: -95.29, elevation: 0 },
	{ lat: 70, lon: -93.18, elevation: 0 },
	{ lat: 70, lon: -86.82, elevation: 0 },
	{ lat: 70, lon: -82.59, elevation: 0 },
	{ lat: 70, lon: -76.24, elevation: 0 },
	{ lat: 70, lon: -74.12, elevation: 0 },
	{ lat: 70, lon: -72.0, elevation: 0 },
	{ lat: 70, lon: -69.88, elevation: 0 },
	{ lat: 70, lon: -67.76, elevation: 0 },
	{ lat: 70, lon: -52.94, elevation: 0 },
	{ lat: 70, lon: -50.82, elevation: 0 },
	{ lat: 70, lon: -48.71, elevation: 0 },
	{ lat: 70, lon: -46.59, elevation: 0 },
	{ lat: 70, lon: -44.47, elevation: 0 },
	{ lat: 70, lon: -42.35, elevation: 0 },
	{ lat: 70, lon: -40.24, elevation: 0 },
	{ lat: 70, lon: -38.12, elevation: 0 },
	{ lat: 70, lon: -36.0, elevation: 0 },
	{ lat: 70, lon: -33.88, elevation: 0 },
	{ lat: 70, lon: -31.76, elevation: 0 },
	{ lat: 70, lon: -29.65, elevation: 0 },
	{ lat: 70, lon: -25.41, elevation: 0 },
	{ lat: 70, lon: -23.29, elevation: 0 },
	{ lat: 70, lon: 19.06, elevation: 0 },
	{ lat: 70, lon: 25.41, elevation: 0 },
	{ lat: 70, lon: 27.53, elevation: 0 },
	{ lat: 70, lon: 59.29, elevation: 0 },
	{ lat: 70, lon: 67.76, elevation: 0 },
	{ lat: 70, lon: 69.88, elevation: 0 },
	{ lat: 70, lon: 72.0, elevation: 0 },
	{ lat: 70, lon: 74.12, elevation: 0 },
	{ lat: 70, lon: 76.24, elevation: 0 },
	{ lat: 70, lon: 78.35, elevation: 0 },
	{ lat: 70, lon: 80.47, elevation: 0 },
	{ lat: 70, lon: 82.59, elevation: 0 },
	{ lat: 70, lon: 84.71, elevation: 0 },
	{ lat: 70, lon: 86.82, elevation: 0 },
	{ lat: 70, lon: 88.94, elevation: 0 },
	{ lat: 70, lon: 91.06, elevation: 0 },
	{ lat: 70, lon: 93.18, elevation: 0 },
	{ lat: 70, lon: 95.29, elevation: 0 },
	{ lat: 70, lon: 97.41, elevation: 0 },
	{ lat: 70, lon: 99.53, elevation: 0 },
	{ lat: 70, lon: 101.65, elevation: 0 },
	{ lat: 70, lon: 103.76, elevation: 0 },
	{ lat: 70, lon: 105.88, elevation: 0 },
	{ lat: 70, lon: 108.0, elevation: 0 },
	{ lat: 70, lon: 110.12, elevation: 0 },
	{ lat: 70, lon: 112.24, elevation: 0 },
	{ lat: 70, lon: 114.35, elevation: 0 },
	{ lat: 70, lon: 116.47, elevation: 0 },
	{ lat: 70, lon: 118.59, elevation: 0 },
	{ lat: 70, lon: 120.71, elevation: 0 },
	{ lat: 70, lon: 122.82, elevation: 0 },
	{ lat: 70, lon: 124.94, elevation: 0 },
	{ lat: 70, lon: 127.06, elevation: 0 },
	{ lat: 70, lon: 129.18, elevation: 0 },
	{ lat: 70, lon: 131.29, elevation: 0 },
	{ lat: 70, lon: 133.41, elevation: 0 },
	{ lat: 70, lon: 135.53, elevation: 0 },
	{ lat: 70, lon: 137.65, elevation: 0 },
	{ lat: 70, lon: 139.76, elevation: 0 },
	{ lat: 70, lon: 141.88, elevation: 0 },
	{ lat: 70, lon: 144.0, elevation: 0 },
	{ lat: 70, lon: 146.12, elevation: 0 },
	{ lat: 70, lon: 148.24, elevation: 0 },
	{ lat: 70, lon: 150.35, elevation: 0 },
	{ lat: 70, lon: 152.47, elevation: 0 },
	{ lat: 70, lon: 154.59, elevation: 0 },
	{ lat: 70, lon: 156.71, elevation: 0 },
	{ lat: 70, lon: 158.82, elevation: 0 },
	{ lat: 70, lon: 171.53, elevation: 0 },
	{ lat: 71, lon: -180.0, elevation: 0 },
	{ lat: 71, lon: -117.39, elevation: 0 },
	{ lat: 71, lon: -115.16, elevation: 0 },
	{ lat: 71, lon: -112.92, elevation: 0 },
	{ lat: 71, lon: -110.68, elevation: 0 },
	{ lat: 71, lon: -108.45, elevation: 0 },
	{ lat: 71, lon: -106.21, elevation: 0 },
	{ lat: 71, lon: -95.03, elevation: 0 },
	{ lat: 71, lon: -86.09, elevation: 0 },
	{ lat: 71, lon: -83.85, elevation: 0 },
	{ lat: 71, lon: -81.61, elevation: 0 },
	{ lat: 71, lon: -79.38, elevation: 0 },
	{ lat: 71, lon: -77.14, elevation: 0 },
	{ lat: 71, lon: -74.91, elevation: 0 },
	{ lat: 71, lon: -72.67, elevation: 0 },
	{ lat: 71, lon: -50.31, elevation: 0 },
	{ lat: 71, lon: -48.07, elevation: 0 },
	{ lat: 71, lon: -45.84, elevation: 0 },
	{ lat: 71, lon: -43.6, elevation: 0 },
	{ lat: 71, lon: -41.37, elevation: 0 },
	{ lat: 71, lon: -39.13, elevation: 0 },
	{ lat: 71, lon: -36.89, elevation: 0 },
	{ lat: 71, lon: -34.66, elevation: 0 },
	{ lat: 71, lon: -32.42, elevation: 0 },
	{ lat: 71, lon: -30.19, elevation: 0 },
	{ lat: 71, lon: -27.95, elevation: 0 },
	{ lat: 71, lon: -23.48, elevation: 0 },
	{ lat: 71, lon: 25.71, elevation: 0 },
	{ lat: 71, lon: 27.95, elevation: 0 },
	{ lat: 71, lon: 54.78, elevation: 0 },
	{ lat: 71, lon: 68.2, elevation: 0 },
	{ lat: 71, lon: 70.43, elevation: 0 },
	{ lat: 71, lon: 74.91, elevation: 0 },
	{ lat: 71, lon: 77.14, elevation: 0 },
	{ lat: 71, lon: 79.38, elevation: 0 },
	{ lat: 71, lon: 81.61, elevation: 0 },
	{ lat: 71, lon: 83.85, elevation: 0 },
	{ lat: 71, lon: 86.09, elevation: 0 },
	{ lat: 71, lon: 88.32, elevation: 0 },
	{ lat: 71, lon: 90.56, elevation: 0 },
	{ lat: 71, lon: 92.8, elevation: 0 },
	{ lat: 71, lon: 95.03, elevation: 0 },
	{ lat: 71, lon: 97.27, elevation: 0 },
	{ lat: 71, lon: 99.5, elevation: 0 },
	{ lat: 71, lon: 101.74, elevation: 0 },
	{ lat: 71, lon: 103.98, elevation: 0 },
	{ lat: 71, lon: 106.21, elevation: 0 },
	{ lat: 71, lon: 108.45, elevation: 0 },
	{ lat: 71, lon: 110.68, elevation: 0 },
	{ lat: 71, lon: 112.92, elevation: 0 },
	{ lat: 71, lon: 115.16, elevation: 0 },
	{ lat: 71, lon: 117.39, elevation: 0 },
	{ lat: 71, lon: 119.63, elevation: 0 },
	{ lat: 71, lon: 121.86, elevation: 0 },
	{ lat: 71, lon: 124.1, elevation: 0 },
	{ lat: 71, lon: 126.34, elevation: 0 },
	{ lat: 71, lon: 128.57, elevation: 0 },
	{ lat: 71, lon: 133.04, elevation: 0 },
	{ lat: 71, lon: 135.28, elevation: 0 },
	{ lat: 71, lon: 137.52, elevation: 0 },
	{ lat: 71, lon: 139.75, elevation: 0 },
	{ lat: 71, lon: 141.99, elevation: 0 },
	{ lat: 71, lon: 144.22, elevation: 0 },
	{ lat: 71, lon: 146.46, elevation: 0 },
	{ lat: 71, lon: 148.7, elevation: 0 },
	{ lat: 71, lon: 150.93, elevation: 0 },
	{ lat: 71, lon: 155.4, elevation: 0 },
	{ lat: 71, lon: 157.64, elevation: 0 },
	{ lat: 71, lon: 180.0, elevation: 0 },
	{ lat: 72, lon: -123.53, elevation: 0 },
	{ lat: 72, lon: -121.18, elevation: 0 },
	{ lat: 72, lon: -118.82, elevation: 0 },
	{ lat: 72, lon: -116.47, elevation: 0 },
	{ lat: 72, lon: -114.12, elevation: 0 },
	{ lat: 72, lon: -111.76, elevation: 0 },
	{ lat: 72, lon: -109.41, elevation: 0 },
	{ lat: 72, lon: -107.06, elevation: 0 },
	{ lat: 72, lon: -100.0, elevation: 0 },
	{ lat: 72, lon: -97.65, elevation: 0 },
	{ lat: 72, lon: -88.24, elevation: 0 },
	{ lat: 72, lon: -85.88, elevation: 0 },
	{ lat: 72, lon: -83.53, elevation: 0 },
	{ lat: 72, lon: -81.18, elevation: 0 },
	{ lat: 72, lon: -78.82, elevation: 0 },
	{ lat: 72, lon: -76.47, elevation: 0 },
	{ lat: 72, lon: -52.94, elevation: 0 },
	{ lat: 72, lon: -50.59, elevation: 0 },
	{ lat: 72, lon: -48.24, elevation: 0 },
	{ lat: 72, lon: -45.88, elevation: 0 },
	{ lat: 72, lon: -43.53, elevation: 0 },
	{ lat: 72, lon: -41.18, elevation: 0 },
	{ lat: 72, lon: -38.82, elevation: 0 },
	{ lat: 72, lon: -36.47, elevation: 0 },
	{ lat: 72, lon: -34.12, elevation: 0 },
	{ lat: 72, lon: -31.76, elevation: 0 },
	{ lat: 72, lon: -29.41, elevation: 0 },
	{ lat: 72, lon: -27.06, elevation: 0 },
	{ lat: 72, lon: -24.71, elevation: 0 },
	{ lat: 72, lon: 52.94, elevation: 0 },
	{ lat: 72, lon: 55.29, elevation: 0 },
	{ lat: 72, lon: 69.41, elevation: 0 },
	{ lat: 72, lon: 71.76, elevation: 0 },
	{ lat: 72, lon: 76.47, elevation: 0 },
	{ lat: 72, lon: 78.82, elevation: 0 },
	{ lat: 72, lon: 83.53, elevation: 0 },
	{ lat: 72, lon: 85.88, elevation: 0 },
	{ lat: 72, lon: 88.24, elevation: 0 },
	{ lat: 72, lon: 90.59, elevation: 0 },
	{ lat: 72, lon: 92.94, elevation: 0 },
	{ lat: 72, lon: 95.29, elevation: 0 },
	{ lat: 72, lon: 97.65, elevation: 0 },
	{ lat: 72, lon: 100.0, elevation: 0 },
	{ lat: 72, lon: 102.35, elevation: 0 },
	{ lat: 72, lon: 104.71, elevation: 0 },
	{ lat: 72, lon: 107.06, elevation: 0 },
	{ lat: 72, lon: 109.41, elevation: 0 },
	{ lat: 72, lon: 111.76, elevation: 0 },
	{ lat: 72, lon: 114.12, elevation: 0 },
	{ lat: 72, lon: 116.47, elevation: 0 },
	{ lat: 72, lon: 118.82, elevation: 0 },
	{ lat: 72, lon: 121.18, elevation: 0 },
	{ lat: 72, lon: 123.53, elevation: 0 },
	{ lat: 72, lon: 125.88, elevation: 0 },
	{ lat: 72, lon: 128.24, elevation: 0 },
	{ lat: 72, lon: 140.0, elevation: 0 },
	{ lat: 72, lon: 142.35, elevation: 0 },
	{ lat: 72, lon: 144.71, elevation: 0 },
	{ lat: 72, lon: 147.06, elevation: 0 },
	{ lat: 72, lon: 149.41, elevation: 0 },
	{ lat: 73, lon: -122.9, elevation: 0 },
	{ lat: 73, lon: -120.41, elevation: 0 },
	{ lat: 73, lon: -117.93, elevation: 0 },
	{ lat: 73, lon: -115.45, elevation: 0 },
	{ lat: 73, lon: -112.97, elevation: 0 },
	{ lat: 73, lon: -110.48, elevation: 0 },
	{ lat: 73, lon: -108.0, elevation: 0 },
	{ lat: 73, lon: -105.52, elevation: 0 },
	{ lat: 73, lon: -98.07, elevation: 0 },
	{ lat: 73, lon: -95.59, elevation: 0 },
	{ lat: 73, lon: -93.1, elevation: 0 },
	{ lat: 73, lon: -88.14, elevation: 0 },
	{ lat: 73, lon: -83.17, elevation: 0 },
	{ lat: 73, lon: -80.69, elevation: 0 },
	{ lat: 73, lon: -78.21, elevation: 0 },
	{ lat: 73, lon: -53.38, elevation: 0 },
	{ lat: 73, lon: -50.9, elevation: 0 },
	{ lat: 73, lon: -48.41, elevation: 0 },
	{ lat: 73, lon: -45.93, elevation: 0 },
	{ lat: 73, lon: -43.45, elevation: 0 },
	{ lat: 73, lon: -40.97, elevation: 0 },
	{ lat: 73, lon: -38.48, elevation: 0 },
	{ lat: 73, lon: -36.0, elevation: 0 },
	{ lat: 73, lon: -33.52, elevation: 0 },
	{ lat: 73, lon: -31.03, elevation: 0 },
	{ lat: 73, lon: -28.55, elevation: 0 },
	{ lat: 73, lon: -26.07, elevation: 0 },
	{ lat: 73, lon: -23.59, elevation: 0 },
	{ lat: 73, lon: 53.38, elevation: 0 },
	{ lat: 73, lon: 55.86, elevation: 0 },
	{ lat: 73, lon: 83.17, elevation: 0 },
	{ lat: 73, lon: 85.66, elevation: 0 },
	{ lat: 73, lon: 88.14, elevation: 0 },
	{ lat: 73, lon: 90.62, elevation: 0 },
	{ lat: 73, lon: 93.1, elevation: 0 },
	{ lat: 73, lon: 95.59, elevation: 0 },
	{ lat: 73, lon: 98.07, elevation: 0 },
	{ lat: 73, lon: 100.55, elevation: 0 },
	{ lat: 73, lon: 103.03, elevation: 0 },
	{ lat: 73, lon: 105.52, elevation: 0 },
	{ lat: 73, lon: 108.0, elevation: 0 },
	{ lat: 73, lon: 110.48, elevation: 0 },
	{ lat: 73, lon: 112.97, elevation: 0 },
	{ lat: 73, lon: 115.45, elevation: 0 },
	{ lat: 73, lon: 117.93, elevation: 0 },
	{ lat: 73, lon: 125.38, elevation: 0 },
	{ lat: 73, lon: 127.86, elevation: 0 },
	{ lat: 74, lon: -121.76, elevation: 0 },
	{ lat: 74, lon: -97.94, elevation: 0 },
	{ lat: 74, lon: -92.65, elevation: 0 },
	{ lat: 74, lon: -90.0, elevation: 0 },
	{ lat: 74, lon: -55.59, elevation: 0 },
	{ lat: 74, lon: -52.94, elevation: 0 },
	{ lat: 74, lon: -50.29, elevation: 0 },
	{ lat: 74, lon: -47.65, elevation: 0 },
	{ lat: 74, lon: -45.0, elevation: 0 },
	{ lat: 74, lon: -42.35, elevation: 0 },
	{ lat: 74, lon: -39.71, elevation: 0 },
	{ lat: 74, lon: -37.06, elevation: 0 },
	{ lat: 74, lon: -34.41, elevation: 0 },
	{ lat: 74, lon: -31.76, elevation: 0 },
	{ lat: 74, lon: -29.12, elevation: 0 },
	{ lat: 74, lon: -26.47, elevation: 0 },
	{ lat: 74, lon: -23.82, elevation: 0 },
	{ lat: 74, lon: -21.18, elevation: 0 },
	{ lat: 74, lon: 55.59, elevation: 0 },
	{ lat: 74, lon: 58.24, elevation: 0 },
	{ lat: 74, lon: 87.35, elevation: 0 },
	{ lat: 74, lon: 90.0, elevation: 0 },
	{ lat: 74, lon: 92.65, elevation: 0 },
	{ lat: 74, lon: 95.29, elevation: 0 },
	{ lat: 74, lon: 97.94, elevation: 0 },
	{ lat: 74, lon: 100.59, elevation: 0 },
	{ lat: 74, lon: 103.24, elevation: 0 },
	{ lat: 74, lon: 105.88, elevation: 0 },
	{ lat: 74, lon: 108.53, elevation: 0 },
	{ lat: 74, lon: 111.18, elevation: 0 },
	{ lat: 75, lon: -109.69, elevation: 0 },
	{ lat: 75, lon: -106.88, elevation: 0 },
	{ lat: 75, lon: -95.62, elevation: 0 },
	{ lat: 75, lon: -90.0, elevation: 0 },
	{ lat: 75, lon: -87.19, elevation: 0 },
	{ lat: 75, lon: -84.38, elevation: 0 },
	{ lat: 75, lon: -81.56, elevation: 0 },
	{ lat: 75, lon: -56.25, elevation: 0 },
	{ lat: 75, lon: -53.44, elevation: 0 },
	{ lat: 75, lon: -50.62, elevation: 0 },
	{ lat: 75, lon: -47.81, elevation: 0 },
	{ lat: 75, lon: -45.0, elevation: 0 },
	{ lat: 75, lon: -42.19, elevation: 0 },
	{ lat: 75, lon: -39.38, elevation: 0 },
	{ lat: 75, lon: -36.56, elevation: 0 },
	{ lat: 75, lon: -33.75, elevation: 0 },
	{ lat: 75, lon: -30.94, elevation: 0 },
	{ lat: 75, lon: -28.12, elevation: 0 },
	{ lat: 75, lon: -25.31, elevation: 0 },
	{ lat: 75, lon: -22.5, elevation: 0 },
	{ lat: 75, lon: 59.06, elevation: 0 },
	{ lat: 75, lon: 90.0, elevation: 0 },
	{ lat: 75, lon: 92.81, elevation: 0 },
	{ lat: 75, lon: 95.62, elevation: 0 },
	{ lat: 75, lon: 98.44, elevation: 0 },
	{ lat: 75, lon: 101.25, elevation: 0 },
	{ lat: 75, lon: 104.06, elevation: 0 },
	{ lat: 75, lon: 106.88, elevation: 0 },
	{ lat: 75, lon: 109.69, elevation: 0 },
	{ lat: 75, lon: 112.5, elevation: 0 },
	{ lat: 75, lon: 137.81, elevation: 0 },
	{ lat: 75, lon: 140.62, elevation: 0 },
	{ lat: 75, lon: 143.44, elevation: 0 },
	{ lat: 75, lon: 149.06, elevation: 0 },
	{ lat: 76, lon: -122.52, elevation: 0 },
	{ lat: 76, lon: -119.5, elevation: 0 },
	{ lat: 76, lon: -113.45, elevation: 0 },
	{ lat: 76, lon: -98.32, elevation: 0 },
	{ lat: 76, lon: -92.27, elevation: 0 },
	{ lat: 76, lon: -58.99, elevation: 0 },
	{ lat: 76, lon: -55.97, elevation: 0 },
	{ lat: 76, lon: -52.94, elevation: 0 },
	{ lat: 76, lon: -49.92, elevation: 0 },
	{ lat: 76, lon: -46.89, elevation: 0 },
	{ lat: 76, lon: -43.87, elevation: 0 },
	{ lat: 76, lon: -40.84, elevation: 0 },
	{ lat: 76, lon: -37.82, elevation: 0 },
	{ lat: 76, lon: -34.79, elevation: 0 },
	{ lat: 76, lon: -31.76, elevation: 0 },
	{ lat: 76, lon: -28.74, elevation: 0 },
	{ lat: 76, lon: -25.71, elevation: 0 },
	{ lat: 76, lon: -22.69, elevation: 0 },
	{ lat: 76, lon: 62.02, elevation: 0 },
	{ lat: 76, lon: 65.04, elevation: 0 },
	{ lat: 76, lon: 95.29, elevation: 0 },
	{ lat: 76, lon: 98.32, elevation: 0 },
	{ lat: 76, lon: 101.34, elevation: 0 },
	{ lat: 76, lon: 104.37, elevation: 0 },
	{ lat: 76, lon: 107.39, elevation: 0 },
	{ lat: 76, lon: 110.42, elevation: 0 },
	{ lat: 76, lon: 113.45, elevation: 0 },
	{ lat: 77, lon: -118.38, elevation: 0 },
	{ lat: 77, lon: -95.68, elevation: 0 },
	{ lat: 77, lon: -85.95, elevation: 0 },
	{ lat: 77, lon: -82.7, elevation: 0 },
	{ lat: 77, lon: -79.46, elevation: 0 },
	{ lat: 77, lon: -69.73, elevation: 0 },
	{ lat: 77, lon: -66.49, elevation: 0 },
	{ lat: 77, lon: -63.24, elevation: 0 },
	{ lat: 77, lon: -60.0, elevation: 0 },
	{ lat: 77, lon: -56.76, elevation: 0 },
	{ lat: 77, lon: -53.51, elevation: 0 },
	{ lat: 77, lon: -50.27, elevation: 0 },
	{ lat: 77, lon: -47.03, elevation: 0 },
	{ lat: 77, lon: -43.78, elevation: 0 },
	{ lat: 77, lon: -40.54, elevation: 0 },
	{ lat: 77, lon: -37.3, elevation: 0 },
	{ lat: 77, lon: -34.05, elevation: 0 },
	{ lat: 77, lon: -30.81, elevation: 0 },
	{ lat: 77, lon: -27.57, elevation: 0 },
	{ lat: 77, lon: -24.32, elevation: 0 },
	{ lat: 77, lon: -21.08, elevation: 0 },
	{ lat: 77, lon: 95.68, elevation: 0 },
	{ lat: 77, lon: 102.16, elevation: 0 },
	{ lat: 77, lon: 105.41, elevation: 0 },
	{ lat: 78, lon: -95.29, elevation: 0 },
	{ lat: 78, lon: -84.71, elevation: 0 },
	{ lat: 78, lon: -81.18, elevation: 0 },
	{ lat: 78, lon: -77.65, elevation: 0 },
	{ lat: 78, lon: -70.59, elevation: 0 },
	{ lat: 78, lon: -67.06, elevation: 0 },
	{ lat: 78, lon: -63.53, elevation: 0 },
	{ lat: 78, lon: -60.0, elevation: 0 },
	{ lat: 78, lon: -56.47, elevation: 0 },
	{ lat: 78, lon: -52.94, elevation: 0 },
	{ lat: 78, lon: -49.41, elevation: 0 },
	{ lat: 78, lon: -45.88, elevation: 0 },
	{ lat: 78, lon: -42.35, elevation: 0 },
	{ lat: 78, lon: -38.82, elevation: 0 },
	{ lat: 78, lon: -35.29, elevation: 0 },
	{ lat: 78, lon: -31.76, elevation: 0 },
	{ lat: 78, lon: -28.24, elevation: 0 },
	{ lat: 78, lon: -24.71, elevation: 0 },
	{ lat: 78, lon: 14.12, elevation: 0 },
	{ lat: 78, lon: 17.65, elevation: 0 },
	{ lat: 78, lon: 21.18, elevation: 0 },
	{ lat: 79, lon: -103.4, elevation: 0 },
	{ lat: 79, lon: -91.91, elevation: 0 },
	{ lat: 79, lon: -84.26, elevation: 0 },
	{ lat: 79, lon: -80.43, elevation: 0 },
	{ lat: 79, lon: -76.6, elevation: 0 },
	{ lat: 79, lon: -65.11, elevation: 0 },
	{ lat: 79, lon: -61.28, elevation: 0 },
	{ lat: 79, lon: -57.45, elevation: 0 },
	{ lat: 79, lon: -53.62, elevation: 0 },
	{ lat: 79, lon: -49.79, elevation: 0 },
	{ lat: 79, lon: -45.96, elevation: 0 },
	{ lat: 79, lon: -42.13, elevation: 0 },
	{ lat: 79, lon: -38.3, elevation: 0 },
	{ lat: 79, lon: -34.47, elevation: 0 },
	{ lat: 79, lon: -30.64, elevation: 0 },
	{ lat: 79, lon: -26.81, elevation: 0 },
	{ lat: 79, lon: -22.98, elevation: 0 },
	{ lat: 79, lon: 15.32, elevation: 0 },
	{ lat: 79, lon: 19.15, elevation: 0 },
	{ lat: 79, lon: 95.74, elevation: 0 },
	{ lat: 79, lon: 99.57, elevation: 0 },
	{ lat: 79, lon: 103.4, elevation: 0 },
	{ lat: 80, lon: -99.53, elevation: 0 },
	{ lat: 80, lon: -95.29, elevation: 0 },
	{ lat: 80, lon: -91.06, elevation: 0 },
	{ lat: 80, lon: -78.35, elevation: 0 },
	{ lat: 80, lon: -74.12, elevation: 0 },
	{ lat: 80, lon: -61.41, elevation: 0 },
	{ lat: 80, lon: -57.18, elevation: 0 },
	{ lat: 80, lon: -52.94, elevation: 0 },
	{ lat: 80, lon: -48.71, elevation: 0 },
	{ lat: 80, lon: -44.47, elevation: 0 },
	{ lat: 80, lon: -40.24, elevation: 0 },
	{ lat: 80, lon: -36.0, elevation: 0 },
	{ lat: 80, lon: -31.76, elevation: 0 },
	{ lat: 80, lon: -27.53, elevation: 0 },
	{ lat: 80, lon: -23.29, elevation: 0 },
	{ lat: 80, lon: -19.06, elevation: 0 },
	{ lat: 80, lon: 19.06, elevation: 0 },
	{ lat: 80, lon: 23.29, elevation: 0 },
	{ lat: 80, lon: 95.29, elevation: 0 },
	{ lat: 81, lon: -86.49, elevation: 0 },
	{ lat: 81, lon: -81.82, elevation: 0 },
	{ lat: 81, lon: -77.14, elevation: 0 },
	{ lat: 81, lon: -72.47, elevation: 0 },
	{ lat: 81, lon: -67.79, elevation: 0 },
	{ lat: 81, lon: -63.12, elevation: 0 },
	{ lat: 81, lon: -58.44, elevation: 0 },
	{ lat: 81, lon: -53.77, elevation: 0 },
	{ lat: 81, lon: -49.09, elevation: 0 },
	{ lat: 81, lon: -44.42, elevation: 0 },
	{ lat: 81, lon: -39.74, elevation: 0 },
	{ lat: 81, lon: -35.06, elevation: 0 },
	{ lat: 81, lon: -30.39, elevation: 0 },
	{ lat: 81, lon: -25.71, elevation: 0 },
	{ lat: 81, lon: -21.04, elevation: 0 },
	{ lat: 81, lon: -16.36, elevation: 0 },
	{ lat: 81, lon: 58.44, elevation: 0 },
	{ lat: 81, lon: 95.84, elevation: 0 },
	{ lat: 82, lon: -84.71, elevation: 0 },
	{ lat: 82, lon: -79.41, elevation: 0 },
	{ lat: 82, lon: -74.12, elevation: 0 },
	{ lat: 82, lon: -68.82, elevation: 0 },
	{ lat: 82, lon: -63.53, elevation: 0 },
	{ lat: 82, lon: -58.24, elevation: 0 },
	{ lat: 82, lon: -52.94, elevation: 0 },
	{ lat: 82, lon: -47.65, elevation: 0 },
	{ lat: 82, lon: -42.35, elevation: 0 },
	{ lat: 82, lon: -37.06, elevation: 0 },
	{ lat: 82, lon: -31.76, elevation: 0 },
	{ lat: 82, lon: -26.47, elevation: 0 },
	{ lat: 82, lon: -21.18, elevation: 0 },
	{ lat: 83, lon: -76.27, elevation: 0 },
	{ lat: 83, lon: -70.17, elevation: 0 },
	{ lat: 83, lon: -45.76, elevation: 0 },
	{ lat: 83, lon: -39.66, elevation: 0 },
	{ lat: 83, lon: -33.56, elevation: 0 },
	{ lat: 83, lon: -27.46, elevation: 0 },
];
