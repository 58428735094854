import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { faMap, faChartLine, faClone, faTh, faInfoCircle, faFile, faGear } from '@fortawesome/free-solid-svg-icons';

// Add the imported icons to the library
library.add(faMap, faChartLine, faClone, faTh, faInfoCircle, faFile, faGear);

const map = icon({ prefix: 'fas', iconName: 'map' });
const chartLine = icon({ prefix: 'fas', iconName: 'chart-line' });
const clone = icon({ prefix: 'fas', iconName: 'clone' });
const th = icon({ prefix: 'fas', iconName: 'th' });
const infoCircle = icon({ prefix: 'fas', iconName: 'info-circle' });
const file = icon({ prefix: 'fas', iconName: 'file' });
const gear = icon({ prefix: 'fas', iconName: 'gear' });

import { getCookie } from '../cookies';
import { offCanvas } from '../offcanvas';
import { toggleTheme } from '../theme';
import { animateIcons } from '../icons-animation';

import './header.css';

import aerovalFullLogo from '../../assets/images/logo/full_logo.svg';
import aerovalLogo from '../../assets/images/logo/logo.svg';
import aerovalPages from '../../../public/config/pages.json';
import canvasConfig from '../../../public/config/canvas.json';

import { Aeroval } from '../../types/global';
declare var aeroval: Aeroval;

export function renderHeader(): HTMLElement {
	const header = document.createElement('header');

	// in older versions, set of default pages. Options to add or hide pages.
	// in newer versions, the set of pages is defined in cfg file
	aeroval.config.pages = aerovalPages;

	var innerHTML = `
			<div id="external-header"></div>
			<nav class="navbar navbar-expand-lg bg-light">
				<div class="container-fluid">
				<a class="navbar-brand" href="../../">
					<img id="header-logo" class="full-logo" src='${aerovalFullLogo}' style="height: 30px; width: 117.23px; padding-top: 0;" alt="logo_aeroval">
				</a>
				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="offcanvasMenu" aria-expanded="false">
					<span class="navbar-toggler-icon"></span>
				</button>
				<div class="collapse navbar-collapse" id="navbarCollapse">
					<ul id="header-list" class="navbar-nav me-auto">`

	for (let page in aeroval.config.pages) {
		innerHTML += `
				<li class="nav-item" style="display: ${aeroval.config.pages[page].display}">
					<a id="${page}-link" class="nav-link" href="${aeroval.config.pages[page].href}" title="${aeroval.config.pages[page].title}">${pageIcon(page)}${aeroval.config.pages[page].innerHTML}</a>
				</li>`
	}

	innerHTML += `
					</ul>
					<button id="toggle-settings" class="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-settings" aria-controls="offcanvas-settings">
						${gear.html}
					</button>
				</div>
			</div>
			</nav>
			<div id="offcanvas-content"></div>
			`;

	header.innerHTML = innerHTML
	return header;
}

/*on document ready*/
document.addEventListener('DOMContentLoaded', function () {
	// apply theme before rendering
	const themeCookie = getCookie('theme');
	if (themeCookie && themeCookie != '') {
		toggleTheme(themeCookie);
	}
	var offcanvasOptions = [];
	for (var canvasOption in canvasConfig) {
		if (!(canvasConfig as any)[canvasOption].onlyIndex) {
			offcanvasOptions.push(canvasOption)
		}
	}
	applyCAMSTheme();
	offCanvas(offcanvasOptions as [string | number]);
	highlightCurrentPage();
	enableScrollStatus();
	animateIcons();
});

export function highlightCurrentPage() {
	const pathname = new URL(window.location.href).pathname;
	const segments = pathname.split('/').filter((segment) => segment !== '');
	const page = segments[segments.length - 1];
	if (page) {
		// add active class to corresponding nav link
		const navLink = document.querySelector(`a#${page}-link`);
		if (navLink instanceof HTMLAnchorElement) {
			navLink.classList.add('active');
		}
	}
}

function enableScrollStatus() {
	window.addEventListener('scroll', function () {
		const navbar = document.querySelector('nav.navbar');
		if (navbar instanceof HTMLElement) {
			if (window.scrollY > 30) {
				navbar.classList.add('scrolled');
			} else {
				navbar.classList.remove('scrolled');
			}
		}
	});
}

function applyCAMSTheme() {
	if (window.location.host.includes('cams') || window.location.host.includes('atmosphere.copernicus')) {
		const navbar = document.querySelector('nav.navbar');
		if (navbar instanceof HTMLElement) {
			navbar.style.position = 'sticky';
		}

		if (window.location.host.includes('regional-evaluation.atmosphere.copernicus')) {
			const logo = document.getElementById("header-logo")
			if (logo instanceof HTMLImageElement) {
				logo.classList.remove('full-logo')
				logo.classList.add('simple-logo')
				logo.src = aerovalLogo
				logo.style.height = '35px'
				logo.style.width = 'auto'
				logo.insertAdjacentHTML('afterend', '<span class="brand">Regional Evaluation</span>');
			}
		} else if (window.location.host.includes('global-eqc-server.atmosphere.copernicus')) {
			const logo = document.getElementById("header-logo")
			if (logo instanceof HTMLImageElement) {
				logo.classList.remove('full-logo')
				logo.classList.add('simple-logo')
				logo.src = aerovalLogo
				logo.style.height = '35px'
				logo.style.width = 'auto'
				logo.insertAdjacentHTML('afterend', '<span class="brand">Global Evaluation</span>');
			}
		}

		const externalHeader = document.getElementById('external-header');
		if (externalHeader instanceof HTMLElement) {
			const xhr = new XMLHttpRequest();
			xhr.open('GET', '/custom-theme/cams/header.html');
			xhr.onreadystatechange = function () {
				if (xhr.readyState === XMLHttpRequest.DONE) {
					if (xhr.status === 200) {
						externalHeader.innerHTML = xhr.responseText;
					} else {
						console.error('Failed to load external header:', xhr.status);
					}
				}
			};
			xhr.send();
		}
	}
}

function pageIcon(page: string): string | undefined {
	var pageIcon: string | undefined = undefined;
	if (page === 'maps') {
		pageIcon = map.html[0]
	} else if (page === 'evaluation') {
		pageIcon = chartLine.html[0]
	} else if (page === 'intercomp') {
		pageIcon = clone.html[0]
	} else if (page === 'overall') {
		pageIcon = th.html[0]
	} else if (page === 'infos') {
		pageIcon = infoCircle.html[0]
	} else if (page === 'reports') {
		pageIcon = file.html[0]
	}
	return pageIcon
}