import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import SolidGauge from 'highcharts/modules/solid-gauge';
if (typeof Highcharts === 'object') {
	HighchartsMore(Highcharts);
	SolidGauge(Highcharts);
}

import { getKeyTime, getChartById } from '../../../utils';

import { Aeroval } from '../../../types/global';
declare var aeroval: Aeroval;

// disable accessibility
Highcharts.setOptions({
	accessibility: {
		enabled: false,
	},
});

export function plotGauges(data: Aeroval['config']['gauges']): void {
	for (let gauge in data) {
		const div = `gauge-${gauge}`;

		if (aeroval.statistics[gauge].unit == '%') {
			var unit = '(%)';
		} else {
			var unit = '';
		}

		const series = [
			{
				name: aeroval.statistics[gauge].name,
				data: [null],
				dataLabels: {
					format: "<div style='text-align:center'>" + "<span style='font-size:13px'>{y:.2f}</span><br/>" + '</div>',
					y: 1,
				},
			},
		] as any;

		Highcharts.chart({
			chart: {
				renderTo: div,
				type: 'solidgauge',
				backgroundColor: 'transparent',
			},
			exporting: {
				enabled: false,
			},
			tooltip: {
				enabled: false,
			},
			pane: {
				center: ['50%', '85%'],
				size: '110%',
				startAngle: -90,
				endAngle: 90,
				background: [
					{
						backgroundColor: 'transparent' as Highcharts.ColorString,
						innerRadius: '60%',
						outerRadius: '100%',
						shape: 'arc',
					},
				],
			},
			title: {
				text: '',
			},
			credits: {
				enabled: false,
			},
			yAxis: {
				stops: data[gauge],
				min: aeroval.statistics[gauge].scale[0],
				max: aeroval.statistics[gauge].scale[aeroval.statistics[gauge].scale.length - 1],
				lineWidth: 0,
				tickWidth: 0,
				minorTickInterval: undefined,
				tickAmount: 2,
				title: {
					text: `${aeroval.statistics[gauge].name} ${unit}`,
					y: -35,
				},
				labels: {
					y: 15,
				},
			},
			plotOptions: {
				solidgauge: {
					dataLabels: {
						borderWidth: 0,
						useHTML: true,
					},
				},
			},
			series,
		});
	}
}

export function updateGauges(gaugesConfig: Aeroval['config']['gauges'], data: Aeroval['data']['hm'], hover: any): void {
	if (data && typeof data[hover.var][hover.obs][hover.lay][hover.mod] != 'undefined') {
		//time selection
		const keyTime = getKeyTime(aeroval.time, aeroval.season);
		const subData = data[hover.var][hover.obs][hover.lay][hover.mod][hover.modVar][aeroval.region][keyTime];
		if (subData) {
			for (let key in gaugesConfig) {
				if (key == 'nrms' && subData['rms'] && subData['refdata_mean'] !== 0) {
					var value = (subData['rms'] as number) / (subData['refdata_mean'] as number);
				} else {
					value = subData[key] as number;
				}
				if (aeroval.statistics[key].unit == '%') {
					value = value * 100;
				}
				//update series
				const gaugeChart = getChartById(`gauge-${key}`);
				if (gaugeChart) {
					gaugeChart.series[0].setData([value], true);
				}
			}
		}
	}
}
