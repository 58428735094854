/*import interfaces*/
import { Aeroval } from '../../types/global';
import './index.css';

declare var aeroval: Aeroval;

export function togglePresentationMode(value: string): void {
	aeroval.settings.presentationMode = value;

	// change cursor
	if (value === 'false') {
		//document.body.style.cursor = 'default';
		document.body.classList.remove('laser-beam')
		// delete trail-container if it exists
		const trailContainer = document.getElementById('trail-container');
		if (trailContainer) {
			trailContainer.remove();
		}
	} else if (value === 'true') {
		//document.body.style.cursor = laserBeam;
		document.body.classList.add('laser-beam')

		// Create trail-container
		const trailElement = document.createElement('div');
		trailElement.id = 'trail-container'

		// Insert the new element into the content div
		if (!document.getElementById(trailElement.id)) {
			document.body.appendChild(trailElement);
		}

		activateTrail()
	}

}

function activateTrail() {

	const trailContainer = document.getElementById('trail-container');
	if (!trailContainer) return;

	const trailLength = 10;
	const trailElements = [] as any[];

	// Create trail elements
	for (let i = 0; i < trailLength; i++) {
		const trail = document.createElement('div');
		trail.classList.add('trail');
		trailContainer.appendChild(trail);
		trailElements.push(trail);
	}

	let mouseX = 0, mouseY = 0;

	document.addEventListener('mousemove', (e) => {
		mouseX = e.pageX;
		mouseY = e.pageY;
	});

	function updateTrail() {
		for (let i = trailElements.length - 1; i > 0; i--) {
			trailElements[i].style.left = trailElements[i - 1].style.left;
			trailElements[i].style.top = trailElements[i - 1].style.top;
			trailElements[i].style.opacity = 1 - i / trailLength;
		}
		trailElements[0].style.left = `${mouseX}px`;
		trailElements[0].style.top = `${mouseY}px`;
		requestAnimationFrame(updateTrail);
	}

	updateTrail();
}
